import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: {
    getPlatformSubscription: false,
    getUsageCharges: false,
    cancellingPlatformSubscription: false,
    getGlobalSubscriptionCharges: false,
  },
  platformSubscription: null,
  usageCharges: null,
  globalSubscriptionCharges: null,
}

export const platformSubscriptionSlice = createSlice({
  name: 'platformSubscription',
  initialState,
  reducers: {
    setPlatformSubscription: (state, { payload }) => {
      const alteredState = {
        ...state,
        platformSubscription: payload,
      }
      return alteredState
    },
    setGetPlatformSubscriptionLoading: (state, { payload }) => {
      const alteredState = {
        ...state,
        loading: { ...state.loading, getPlatformSubscription: payload },
      }
      return alteredState
    },
    setUsageCharges: (state, { payload }) => {
      const alteredState = {
        ...state,
        usageCharges: payload,
      }
      return alteredState
    },
    setGetUsageCharges: (state, { payload }) => {
      const alteredState = {
        ...state,
        loading: { ...state.loading, getUsageCharges: payload },
      }
      return alteredState
    },
    setGlobalSubscriptionCharges: (state, { payload }) => {
      const alteredState = {
        ...state,
        globalSubscriptionCharges: payload,
      }
      return alteredState
    },
    setGetGlobalSubscriptionCharges: (state, { payload }) => {
      const alteredState = {
        ...state,
        loading: { ...state.loading, getGlobalSubscriptionCharges: payload },
      }
      return alteredState
    },
    setCancellingPlatformSubscriptionLoading: (state, { payload }) => {
      const alteredState = {
        ...state,
        loading: { ...state.loading, cancellingPlatformSubscription: payload },
      }
      return alteredState
    },
    reset: () => initialState,
  },
})

export const usePlatformSubscriptionSelector = () =>
  useSelector((state) => state.platformSubscription)
export const platformSubscriptionActions = platformSubscriptionSlice.actions
export const platformSubscriptionReducer = platformSubscriptionSlice.reducer

import React, { useState, useEffect } from 'react'
import { Card, Col, Row, Skeleton, Avatar, List } from 'antd'
import { BarChartOutlined } from '@ant-design/icons'
import { config } from 'configs'
import { useHistory } from 'react-router-dom'
import { reportData } from './ReportData'

const ReportList = () => {
  const history = useHistory()

  const [activeCard, setActiveCard] = useState(null)
  const [loading, setLoading] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const [hoveredItem, setHoveredItem] = useState(null)

  const handleCardClick = (key) => {
    setLoading(true)
    setActiveCard(key)

    // Filter the report data based on the selected card
    const filteredReports = reportData.filter(
      (report) => report.ReportType === key.toLowerCase(),
    )
    setFilteredData(filteredReports)
    setLoading(false)
  }

  const onClickItem = (name, eventType, api) => {
    let apiUrl = api
    if (eventType !== null) {
      apiUrl += `?eventType=${eventType}`
    }
    history.push(apiUrl)
  }

  const CardsData = {
    financial: 'Financial Reports',
    community: 'Community Reports',
    component: 'Detailed Reports',
    platform: 'Platform Reports',
  }

  const hoverStyle = {
    backgroundColor: '#f0f0f0',
    cursor: 'pointer',
  }

  const cards = Object.entries(CardsData).reduce((acc, [key, value]) => {
    const isActive = key === activeCard // Check if the card is active

    // Conditional styling for the card
    const cardStyle = isActive ? { boxShadow: '0 4px 8px 0 #14B1E0' } : {}

    // Style for the card title
    const titleStyle = {
      marginTop: '20px',
      fontSize: '20px', // Increase font size
      textAlign: 'center', // Center align text
    }

    acc.push(
      <Col xs={24} sm={12} md={6} key={key}>
        <Card
          title={<div style={titleStyle}>{value}</div>} // Apply the title style
          bordered={false}
          style={cardStyle}
          onClick={() => handleCardClick(key)}
        ></Card>
      </Col>,
    )
    return acc
  }, [])

  useEffect(() => {
    const initialReports = reportData.filter(
      (report) => report.ReportType === 'financial',
    )
    setFilteredData(initialReports)
    handleCardClick('financial')
  }, [])

  return (
    <>
      <Card className="header-solid" bordered={false}>
        <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
          <Row gutter={[16, 16]}>{cards}</Row>
        </Skeleton>
      </Card>

      <Card className="header-solid" bordered={false}>
        <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
          <List
            itemLayout="horizontal"
            dataSource={filteredData}
            renderItem={(item) => (
              <List.Item
                style={hoveredItem === item ? hoverStyle : {}}
                onClick={() =>
                  onClickItem(item?.Name, item?.EventType, item?.Api)
                }
                onMouseEnter={() => setHoveredItem(item)}
                onMouseLeave={() => setHoveredItem(null)}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={<BarChartOutlined />}
                      style={{
                        width: '25px',
                        height: '25px',
                        lineHeight: '74px',
                        borderRadius: '8px',
                        padding: '16px',
                        backgroundColor: config.colors.primary,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    />
                  }
                  title={<h1 style={{ fontSize: '17px' }}>{item.Name}</h1>}
                  description={
                    <p style={{ fontSize: '13px' }}>{item.Description}</p>
                  }
                />
              </List.Item>
            )}
          />
        </Skeleton>
      </Card>
    </>
  )
}

export { ReportList }

import { REGEXS } from 'utils'
import * as Yup from 'yup'

const stringRegex = /^[A-Za-z ]+$/
const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
const phoneNumberValidation = /^(?:[0-9]{10}|[0-9-() ]{14,15})$/
const amountValidation = REGEXS.amountRegex

const schema = Yup.object().shape({
  askedAmount: Yup.string()
    .required('Required')
    .matches(amountValidation, 'Invalid amount'),
  firstName: Yup.string()
    .required('Required')
    .matches(stringRegex, 'Invalid First Name'),
  lastName: Yup.string()
    .required('Required')
    .matches(stringRegex, 'Invalid Last Name'),
  email: Yup.string().required('Required').matches(emailRegex, 'Invalid Email'),
  countryCode: Yup.string(),
  phoneNumber: Yup.string()
    .nullable()
    .required('Required')
    .matches(phoneNumberValidation, 'Invalid Phone number e.g 3456789123'),
  isAnonymous: Yup.boolean().required('Required'),
  includeEventCosts: Yup.boolean().required('Required'),
  repeatMonths: Yup.string().required('Required'),
  repeatnoOfMonths: Yup.string()
    .typeError('Required')
    .required('Required')
    .matches(REGEXS.numberRegex, 'Invalid amount'),
})

const donationValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { donationValidation }

const reportData = [
  //  Financial Reports
  {
    Name: 'Monthly Revenue  Forecast',
    Description:
      'Forecast Report for Monthly Revenue from Organization, Event, and Campaign Activities',
    EventType: null,
    ReportType: 'financial',
    Api: '/reports/monthly-revenue-forecast',
  },
  {
    Name: 'YTD Revenue',
    Description: 'Year to date revenue of your organization',
    EventType: 'all',
    ReportType: 'financial',
    Api: '/reports/ytd-revenue-for-organization',
  },
  {
    Name: 'Donor Contributions Report',
    Description: 'Year to date donations collected by your organization',
    EventType: 'all',
    ReportType: 'financial',
    Api: '/reports/ytd-donation-for-organization',
  },
  // {
  //   Name: 'Donor Contributions Report',
  //   Description: 'Year to date donations collected by your organization',
  //   EventType: 'all',
  //   ReportType: 'financial',
  //   Api: '/reports/ytd-donor-contribution',
  // },
  {
    Name: 'YTD Campaigns Donations',
    Description:
      'Year to date campaign donations collected by your organization',
    EventType: 'campaign',
    ReportType: 'financial',
    Api: '/reports/ytd-donation-for-organization',
  },
  {
    Name: 'YTD Event Donations',
    Description: 'Year to date event donations collected by your organization',
    EventType: 'event',
    ReportType: 'financial',
    Api: '/reports/ytd-donation-for-organization',
  },
  {
    Name: 'YTD Program Earning',
    Description: 'Year to date program earning collected by your organization',
    EventType: 'program',
    ReportType: 'financial',
    Api: '/reports/ytd-program-service-for-organization',
  },
  {
    Name: 'YTD Service Earning',
    Description: 'Year to date service earning collected by your organization',
    EventType: 'service',
    ReportType: 'financial',
    Api: '/reports/ytd-program-service-for-organization',
  },
  {
    Name: 'YTD Earnings Report',
    Description: 'Subscriber YTD Earnings Report',
    EventType: null,
    ReportType: 'financial',
    Api: '/reports/ytd-earnings-report',
  },
  {
    Name: 'Recurring Donations',
    Description: 'Subscriber Recurring Donations',
    EventType: null,
    ReportType: 'financial',
    Api: '/reports/recurring-donations',
  },
  // {
  //   Name: 'Fyp Bank Payout',
  //   Description: 'Your yealy bank payout reports',
  //   EventType: null,
  //   ReportType: 'financial',
  //   Api: '/reports/fyp-bank-payout',
  // },
  // {
  //   Name: 'Last Year Bank Payout',
  //   Description: 'Your last year bank payout reports',
  //   EventType: null,
  //   ReportType: 'financial',
  //   Api: '/reports/last-year-bank-payout',
  // },
  // community Report
  {
    Name: 'Master Community List',
    Description: 'Master Community List',
    EventType: null,
    ReportType: 'community',
    Api: '/reports/master-community-report-list',
  },
  {
    Name: 'Class Registrations Report',
    Description: 'Get your Class Registrations Report',
    EventType: null,
    ReportType: 'community',
    Api: '/reports/class-registration-report',
  },

  // component reports
  {
    Name: 'YTD Detailed Report',
    Description: 'YTD Component Event and Campaign Performance',
    EventType: null,
    ReportType: 'component',
    Api: '/reports/ytd-component-reports',
  },

  // Platform Reports
  {
    Name: 'Platform Charges',
    Description: 'Platform Charges report',
    EventType: null,
    ReportType: 'platform',
    Api: '/reports/platform-report',
  },
]

export { reportData }

import * as Yup from 'yup'

const schema = Yup.object().shape({
  Date: Yup.string().typeError('Invalid Date').required('Date is required'),
  RequestedTime: Yup.string().required('Requested Time is required'),
  MarriageLicenseNumber: Yup.string().required('Marriage License is required'),
  CourtMarriageLocation: Yup.string().required(
    'Court Marriage Location is required',
  ),
  CeremonyLocation: Yup.string().required('Ceremony Location is required'),
  BrideName: Yup.string().required('Bride Name is required'),
  BrideReligion: Yup.string().required('Bride Religion is required'),
  GroomName: Yup.string().required('Groom Name is required'),
  GroomReligion: Yup.string().required('Groom Religion is required'),
  NumberOfAttendees: Yup.number()
    .typeError('Number Of Attendees must be a number')
    .required('Number Of Attendees is required')
    .min(0, 'Number Of Attendees must be greater than or equal to zero'),
  WaliWakeelName: Yup.string().required('Wali/Wakeel Name is required'),
  WaliWakeelRelationship: Yup.string().required(
    'Wali/Wakeel Relationship is required',
  ),
  Witness1Name: Yup.string().required('Witness 1 Name is required'),
  Witness2Name: Yup.string().required('Witness 2 Name is required'),
  MahrMuqaddam: Yup.string().required('Mahr Muqaddam is required'),
  MahrMuakhar: Yup.string().required('Mahr Muakhar is required'),
  agreeToTerms: Yup.boolean().oneOf([true], 'Check the box to proceed'),
})

const marriageValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { marriageValidation }

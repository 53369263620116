import Api from 'api'
import { message } from 'antd'
import { sortByDate } from 'utils'
import { favouriteActions } from './favouriteReducer'

export const getFavouriteList = (payload, sortKey) => (dispatch) => {
  dispatch(favouriteActions.setLoading(true))
  Api.get(
    `favourites/?type=${payload.Type}&username=${payload.Username}&isSub=${payload.isSub}`,
  )
    .then((res) => {
      const sortBy = sortKey || 'CreatedDate'
      const sortedFav = sortByDate(res?.result, sortBy)

      if (payload.Type === 'volunteer') {
        dispatch(favouriteActions.setVolunteers(sortedFav))
      }
      if (payload.Type === 'program') {
        dispatch(favouriteActions.setPrograms(sortedFav))
      }
      if (payload.Type === 'service') {
        dispatch(favouriteActions.setServices(sortedFav))
      }
      dispatch(favouriteActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(favouriteActions.setLoading(false))
    })
}

export const getFavouriteOrganizations = (payload, sortKey) => (dispatch) => {
  dispatch(favouriteActions.setLoading(true))
  Api.get(
    `favourites/?type=${payload.Type}&username=${payload.Username}&isSub=${payload.isSub}`,
  )
    .then((res) => {
      const sortBy = sortKey || 'createdDate'
      const sortedFav = sortByDate(res?.result, sortBy)
      dispatch(favouriteActions.setOrganizations(sortedFav))
      dispatch(favouriteActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(favouriteActions.setLoading(false))
    })
}

export const getFavouriteBusinesses = (payload, sortKey) => (dispatch) => {
  dispatch(favouriteActions.setLoading(true))
  Api.get(
    `favourites/?type=${payload.Type}&username=${payload.Username}&isSub=${payload.isSub}`,
  )
    .then((res) => {
      const sortBy = sortKey || 'createdDate'
      const sortedFav = sortByDate(res?.result, sortBy)
      dispatch(favouriteActions.setBusinesses(sortedFav))
      dispatch(favouriteActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(favouriteActions.setBusinesses([]))
      dispatch(favouriteActions.setLoading(false))
    })
}

export const getFavouriteEvents = (payload, sortKey) => (dispatch) => {
  dispatch(favouriteActions.setLoading(true))
  Api.get(
    `favourites/?type=${payload.Type}&username=${payload.Username}&isSub=${payload.isSub}`,
  )
    .then((res) => {
      const sortedFav = sortByDate(res?.result, sortKey)
      dispatch(favouriteActions.setLoading(false))
      dispatch(favouriteActions.setEvents(sortedFav))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(favouriteActions.setLoading(false))
    })
}

export const getFavouriteAuctions = (payload, sortKey) => (dispatch) => {
  dispatch(favouriteActions.setLoading(true))
  Api.get(
    `favourites/?type=${payload.Type}&username=${payload.Username}&isSub=${payload.isSub}`,
  )
    .then((res) => {
      const sortBy = sortKey || 'CreatedDate'
      const sortedFav = sortByDate(res?.result, sortBy)
      dispatch(favouriteActions.setAuctions(sortedFav))
      dispatch(favouriteActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(favouriteActions.setAuctions([]))
      dispatch(favouriteActions.setLoading(false))
    })
}

export const addFavourite = (payload, actions) => (dispatch) => {
  dispatch(favouriteActions.setLoading(true))
  Api.put('favourites', payload)
    .then(() => {
      message.success('Added to Favorite!')
      if (actions) {
        actions()
      }
      dispatch(favouriteActions.setLoading(false))
    })
    .catch(() => {
      message.error('Error making favorite !')
      dispatch(favouriteActions.setLoading(false))
    })
}

export const removeFavourite = (id, actions) => (dispatch) => {
  dispatch(favouriteActions.setLoading(true))
  Api.get(`favourites/removeFav?Id=${id}`)
    .then(() => {
      message.success('Removed From Favorite!')
      if (actions) {
        actions()
      }
      dispatch(favouriteActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      message.error('Error removing favorite!')
      dispatch(favouriteActions.setLoading(false))
    })
}

export const getFavouriteFundraisers = (payload, sortKey) => (dispatch) => {
  dispatch(favouriteActions.setLoading(true))
  Api.get(
    `favourites/?type=${payload.Type}&username=${payload.Username}&isSub=${payload.isSub}`,
  )
    .then((res) => {
      const sortedFav = sortByDate(res?.result, sortKey)
      dispatch(favouriteActions.setLoading(false))
      dispatch(favouriteActions.setFundraisers(sortedFav))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(favouriteActions.setLoading(false))
    })
}

/* eslint-disable */
import React from 'react'
import { withRouter } from 'react-router'
import { menuItems } from 'configs'
import { useSelector } from 'react-redux'
import NavLogo from './navLogo'
import NavContent from './navContent'
import { useAuthSelector } from 'store/authSlice/authReducer'

const filterRoutesByRole = (mymenu, roles) => {
  const { organization } = useAuthSelector()
  const organizationId = organization?.id

  let newRoutes = mymenu.map((menu) => ({
    ...menu,
    children: menu.children.filter((child) => {
      if (child?.roles) {
        return child.roles.some((role) => roles.includes(role))
      }
      return true
    }),
  }))

  newRoutes = newRoutes.map((menu) => ({
    ...menu,
    children: menu.children.map((child) => {
      if (child.type === 'collapse') {
        return {
          ...child,
          children: child.children.filter((inside) =>
            inside.roles.some((role) => roles.includes(role)),
          ),
        }
      }
      return child
    }),
  }))

  // Replace the organization.id in Legacy Settings URL if applicable
  newRoutes.forEach((menu) => {
    menu.children.forEach((child) => {
      if (child.id === 'sidebar-subtitle-organization-detail') {
        // Check if organizationId is available before updating the URL
        if (organizationId) {
          // Update the URL with the organization ID using template literals
          child.url = `/settings/register-organization/${organizationId}`
        }
      }
    })
  })

  return newRoutes
}

const Navigation = withRouter((props) => {
  const user = useSelector((state) => state?.auth?.user)
  const roles = user?.roles || []

  return (
    <>
      <NavLogo
        collapsed={props.collapsed}
        collapseMenu={props.collapseMenu}
        windowWidth={props.windowWidth}
        onToggleNavigation={props.onToggleNavigation}
      />
      <hr />
      <NavContent
        collapsed={props.collapsed}
        navigation={filterRoutesByRole(menuItems.items, roles)}
        handleSidebarColor={props.handleSidebarColor}
        currentSelected={props.currentSelected}
        onClose={props.onClose}
      />
    </>
  )
})

export { Navigation }

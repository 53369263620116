/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Card, Button, Skeleton } from 'antd'
import { ArrowRightOutlined, CarryOutFilled } from '@ant-design/icons'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { GrAchievement } from 'react-icons/gr'
import { BsCreditCard2Back } from 'react-icons/bs'
import { ShareReport } from 'components/elements'
import { formatAmount } from 'utils'
import Api from 'api'
import { useHistory } from 'react-router-dom'

const AnnualCosts = () => {
  const history = useHistory()
  const [selected, setSelected] = useState()
  const [pdfData, setPdfData] = useState()

  const options = ['Current Year', 'Last Year']
  return (
    <Card
      id="AnnualCosts"
      className="mb-24 header-solid list-settings-sessions"
      bordered={false}
      title={[
        <React.Fragment key={0}>
          {selected === undefined ? (
            <div className="t-flex t-space-x-4">
              <IoMdArrowRoundBack
                fontSize="1.5rem"
                className="t-cursor-pointer t-min-w-[1.5rem]"
                id="back"
                onClick={() =>
                  history.push('/settings/manage-organization/my-reports')
                }
              />
              <h5 classN ame="font-semibold">
                Annual Costs
              </h5>
            </div>
          ) : (
            <div className="t-flex t-justify-between t-items-center">
              <div className="t-flex t-space-x-4">
                <IoMdArrowRoundBack
                  fontSize="1.5rem"
                  id="back"
                  className="t-cursor-pointer t-min-w-[1.5rem]"
                  onClick={() => {
                    setPdfData()
                    setSelected()
                  }}
                />
                <h5 className="font-semibold t-whitespace-normal">
                  Annual Costs - {options[selected]}
                </h5>
              </div>
              {pdfData != null && (
                <ShareReport
                  reportName={
                    selected === 0
                      ? 'Annual Costs - Current Year'
                      : 'Annual Costs - Last Year'
                  }
                  isSummary
                  report={pdfData}
                />
              )}
            </div>
          )}
        </React.Fragment>,
      ]}
    >
      {selected === undefined ? (
        <>
          {options.map((item, index) => (
            <>
              {index !== 0 && <hr className="gradient-line my-10" />}
              <div className="ant-row-flex ant-row-flex-middle">
                <div
                  className="text-center ant-col"
                  style={{ minWidth: '40px' }}
                >
                  <CarryOutFilled
                    className="text-gray-6"
                    style={{ fontSize: '18px' }}
                  />
                </div>
                <div className="ant-col pl-15">
                  <p className="mb-0">{item}</p>
                </div>
                <div
                  className="ml-auto ant-col ant-col-12"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    type="link"
                    id={`annual_cost_${index}_see_details`}
                    onClick={() => {
                      setPdfData()
                      setSelected(index)
                    }}
                  >
                    See Details <ArrowRightOutlined />
                  </Button>
                </div>
              </div>
            </>
          ))}
        </>
      ) : (
        <Single selected={selected} setPdfData={setPdfData} />
      )}
    </Card>
  )
}

function Single({ selected, setPdfData }) {
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()

  useEffect(() => {
    setLoading(true)
    Api.get(
      selected === 0
        ? 'adminReports/annualCostFy'
        : 'adminReports/annualCostLY',
    )
      .then((res) => {
        setReport(res.results)
        setPdfData(res.results)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      {report ? (
        <div className="t-space-y-4">
          <div className="t-flex t-items-center t-justify-between">
            <div className="t-flex t-items-center t-space-x-2">
              <GrAchievement
                fontSize="1.2rem"
                className="t-text-secondary-100"
              />
              <p>Total Charges To-Date:</p>
            </div>
            {formatAmount(report.totalCharge * 0.02)}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <div className="t-flex t-items-center t-space-x-2">
              <BsCreditCard2Back
                fontSize="1.2rem"
                className="t-text-secondary-100"
              />
              <p>Credit Card Payment Processing:</p>
            </div>
            {formatAmount(report.totalCreditCard * 0.02)}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <div className="t-flex t-items-center t-space-x-2">
              <BsCreditCard2Back
                fontSize="1.2rem"
                className="t-text-secondary-100"
              />
              <p>Direct Debit Payment Processing:</p>
            </div>
            {formatAmount(report.totalDebitCard * 0.02)}
          </div>
        </div>
      ) : (
        <div>No Data Found</div>
      )}
    </Skeleton>
  )
}

export { AnnualCosts }

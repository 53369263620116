import React, { useEffect, useState } from 'react'
import {
  Card,
  Row,
  Col,
  Descriptions,
  Skeleton,
  Button,
  Divider,
  Tooltip,
  Popconfirm,
  Spin,
  message,
} from 'antd'
import {
  EditFilled,
  CloseOutlined,
  CheckOutlined,
  UndoOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { getMyBusiness } from 'store/businessSlice/businessActions'
import { FiAlertCircle } from 'react-icons/fi'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { MdOutlineCreditCardOff } from 'react-icons/md'
import { FaEdit } from 'react-icons/fa'
import { config } from 'configs'
import { makeRequest } from 'api'
import { RiExchangeDollarLine } from 'react-icons/ri'
import { UpdateCreditCardModal } from './UpdateCreditCardModal'
import { UpdateTierModal } from './UpdateTierModal'

const MyBusiness = () => {
  const { user } = useAuthSelector()
  const [business, setBusiness] = useState(null)
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const [selectedAssociation, setSelectedAssociation] = useState(null)
  const [isUpdateCardModalOpen, setIsUpdateCardModalOpen] = useState(false)
  const [isUpdateTierModalOpen, setIsUpdateTierModalOpen] = useState(false)
  const [isCancelling, setIsCancelling] = useState(false)

  const getData = async () => {
    setLoading(true)
    const response = await getMyBusiness(user?.id)
    setBusiness(response)
    setLoading(false)
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formatPhoneNumber = (Number) => {
    const PhoneNumber = Number.replace(/^0+/, '')
    const firstThree = PhoneNumber.substring(0, 3)
    const secondThree = PhoneNumber.substring(3, 6)
    const lastFour = PhoneNumber.substring(6, PhoneNumber.length)
    const phone = `+1 (${firstThree}) ${secondThree}-${lastFour}`

    return phone
  }

  const goToPayment = (id) => {
    history.push(`/invitation/join-business-community/${id}`)
  }

  const handleCancelSubscription = async (id) => {
    const association = business?.Assocaitions?.find((item) => item?.Id === id)
    setSelectedAssociation(association)
    setIsCancelling(true)
    makeRequest('delete', `/businessListings/removeBusinessListing/${id}`)
      .then(() => {
        message.success('Membership cancelled successfully')
        setIsCancelling(false)
        setSelectedAssociation(null)
        getData()
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
        setIsCancelling(false)
        setSelectedAssociation(null)
      })
  }

  const handleUpdateCreditCard = (id) => {
    const association = business?.Assocaitions?.find((item) => item?.Id === id)
    setSelectedAssociation(association)
    setIsUpdateCardModalOpen(true)
  }

  const handleUpdateTier = (id) => {
    const association = business?.Assocaitions?.find((item) => item?.Id === id)
    setSelectedAssociation(association)
    setIsUpdateTierModalOpen(true)
  }

  const renderStatus = ({
    id,
    status,
    organizationName,
    isPaid,
    paymentStatus,
  }) => {
    switch (status) {
      case 'Approved':
        return (
          <>
            {isPaid ? (
              <>
                {paymentStatus === 'Completed' ? (
                  <div className="t-flex t-items-center">
                    <Button
                      shape="circle"
                      size="small"
                      className="btn-status border-success mr-10"
                    >
                      <CheckOutlined
                        className="text-success"
                        style={{ fontSize: '10px' }}
                      />
                    </Button>
                    <div className="t-flex t-items-center t-space-x-4">
                      <span style={{ verticalAlign: 'bottom' }}>
                        {organizationName} - Paid
                      </span>
                      <div className="t-flex t-items-center t-space-x-2">
                        <Popconfirm
                          title="Are you sure you want to cancel membership"
                          placement="top"
                          onConfirm={() => handleCancelSubscription(id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Tooltip title="Cancel Membership">
                            {isCancelling && selectedAssociation?.Id === id ? (
                              <Spin />
                            ) : (
                              <MdOutlineCreditCardOff
                                className="t-cursor-pointer"
                                fontSize="1.3rem"
                              />
                            )}
                          </Tooltip>
                        </Popconfirm>
                        <Tooltip title="Change Tier">
                          <RiExchangeDollarLine
                            className="t-cursor-pointer"
                            style={{ fontSize: '1.4rem' }}
                            onClick={() => handleUpdateTier(id)}
                          />
                        </Tooltip>
                        <Tooltip title="Change Card">
                          <FaEdit
                            className="t-cursor-pointer"
                            style={{ fontSize: '1.3rem' }}
                            onClick={() => handleUpdateCreditCard(id)}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="t-flex t-items-center">
                    <Button
                      shape="circle"
                      size="small"
                      className="btn-status border-muted mr-10"
                    >
                      <UndoOutlined
                        className="text-muted"
                        style={{ fontSize: '10px' }}
                      />
                    </Button>
                    <div className="t-flex t-items-center t-space-x-2">
                      <span style={{ verticalAlign: 'bottom' }}>
                        {organizationName} - Action Pending
                      </span>
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => goToPayment(id)}
                      >
                        Next
                      </Button>
                      {/* <Tooltip title="Click to setup payment">
                        <MdOutlinePayment
                          style={{ fontSize: '1.3rem', cursor: 'pointer' }}
                          onClick={() => goToPayment(id)}
                        />
                      </Tooltip> */}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="t-flex t-items-center">
                <Button
                  shape="circle"
                  size="small"
                  className="btn-status border-success mr-10"
                >
                  <CheckOutlined
                    className="text-success"
                    style={{ fontSize: '10px' }}
                  />
                </Button>
                <div className="t-flex t-items-center t-space-x-4">
                  <span style={{ verticalAlign: 'bottom' }}>
                    {organizationName} - Approved
                  </span>
                  <div className="t-flex t-items-center t-space-x-2">
                    <Popconfirm
                      title="Are you sure you want to cancel membership"
                      placement="top"
                      onConfirm={() => handleCancelSubscription(id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tooltip title="Cancel Membership">
                        {isCancelling && selectedAssociation?.Id === id ? (
                          <Spin />
                        ) : (
                          <MdOutlineCreditCardOff
                            className="t-cursor-pointer"
                            fontSize="1.3rem"
                          />
                        )}
                      </Tooltip>
                    </Popconfirm>
                  </div>
                </div>
              </div>
            )}
          </>
        )
      case 'Rejected':
        return (
          <div className="t-flex t-items-center">
            <Button
              shape="circle"
              size="small"
              className="btn-status t-border-1 t-border-yellow-400 mr-10"
            >
              <CloseOutlined
                className="text-warning"
                style={{ fontSize: '10px' }}
              />
            </Button>
            <span style={{ verticalAlign: 'bottom' }}>
              {organizationName} - Rejected
            </span>
          </div>
        )
      case 'Pending':
        return (
          <div className="t-flex t-items-center">
            <Button
              shape="circle"
              size="small"
              className="btn-status border-muted mr-10"
            >
              <UndoOutlined
                className="text-muted"
                style={{ fontSize: '10px' }}
              />
            </Button>
            <span style={{ verticalAlign: 'bottom' }}>
              {organizationName} - Pending
            </span>
          </div>
        )
      case 'Paused':
        return (
          <div className="t-flex t-items-center">
            <Button
              shape="circle"
              size="small"
              className="btn-status border-muted mr-10"
            >
              <UndoOutlined
                className="text-muted"
                style={{ fontSize: '10px' }}
              />
            </Button>
            <span style={{ verticalAlign: 'bottom' }}>
              {organizationName} - Paused
            </span>
          </div>
        )
      default:
        return (
          <div className="t-flex t-items-center">
            <Button
              shape="circle"
              size="small"
              className="btn-status border-muted mr-10"
            >
              <UndoOutlined
                className="text-muted"
                style={{ fontSize: '10px' }}
              />
            </Button>
            <span style={{ verticalAlign: 'bottom' }}>
              {organizationName} - Pending
            </span>
          </div>
        )
    }
  }

  const SecondaryAssociation = business?.Assocaitions
  return (
    <>
      <Card
        className="header-solid"
        bordered={false}
        title={
          <h1 className="t-font-bold t-text-lg t-text-secondary-100">
            Business Detail
          </h1>
        }
      >
        <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
          {!business ? (
            <div className="t-w-full t-h-96 t-flex t-justify-center t-items-center">
              No Business Added
            </div>
          ) : (
            <Card
              title={[
                <Row
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  className="t-space-y-2"
                >
                  <Col className="t-space-y-2">
                    <h6 className="mb-0 font-bold">{business?.Name}</h6>
                    {/* <div>{renderStatus(org?.OrgStatus)}</div> */}
                  </Col>
                  <div className="t-flex t-justify-end t-items-center t-space-x-2">
                    {business.isPaymentSetup === false && (
                      <Tooltip title="Click to setup payment (your business won't be listed without payment setup)">
                        <FiAlertCircle
                          className="t-cursor-pointer t-text-yellow-600"
                          style={{ fontSize: '1.5rem' }}
                          onClick={() =>
                            history.push(
                              `/settings/manage-organization/manage-payments-accounts`,
                            )
                          }
                        />
                      </Tooltip>
                    )}
                    <EditFilled
                      className="t-cursor-pointer t-text-secondary-100"
                      style={{ fontSize: '1.5rem' }}
                      onClick={() =>
                        history.push(
                          `/settings/register-business/${business?.Id}`,
                        )
                      }
                    />
                  </div>
                </Row>,
              ]}
              className="t-mb-5"
            >
              <Descriptions>
                <Descriptions.Item label="Email" span={3}>
                  {business?.UserEmail}
                </Descriptions.Item>
                <Descriptions.Item label="Phone Number" span={3}>
                  {business?.PhoneNumber &&
                    formatPhoneNumber(business?.PhoneNumber)}
                </Descriptions.Item>
                {business?.Address1 && (
                  <Descriptions.Item label="Street Address" span={3}>
                    {business?.Address1}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label="City" span={3}>
                  {business?.City}
                </Descriptions.Item>
                <Descriptions.Item label="ZipCode" span={3}>
                  {business?.ZipCode}
                </Descriptions.Item>
                {/* <Descriptions.Item label="Chargeable Services" span={3}>
                {business?.ChargableServices ? 'Yes' : 'No'}
              </Descriptions.Item> */}
                {business?.Website && (
                  <Descriptions.Item label="Website" span={3}>
                    {business?.Website}
                  </Descriptions.Item>
                )}
                <Descriptions.Item
                  label="Description"
                  span={3}
                  className="t-text-justify"
                >
                  {business?.Description}
                </Descriptions.Item>
              </Descriptions>
              <Divider />
              {business?.Assocaitions && (
                <>
                  <h6 className="mb-0 font-bold">Your Memberships:</h6>
                  <div className="t-my-2 t-space-y-4">
                    {SecondaryAssociation && (
                      <div className="t-flex t-flex-col">
                        <div className="t-flex t-flex-col t-space-y-1">
                          {SecondaryAssociation?.map((item) => (
                            <div>
                              {renderStatus({
                                id: item?.Id,
                                status: item?.Status,
                                organizationName: item?.OrganizationName,
                                isPaid: item?.IsMembershipProgramEnabled,
                                paymentStatus: item?.PaymentStatus,
                              })}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </Card>
          )}
        </Skeleton>
      </Card>
      {isUpdateCardModalOpen && (
        <UpdateCreditCardModal
          visible={isUpdateCardModalOpen}
          close={() => {
            setSelectedAssociation(null)
            setIsUpdateCardModalOpen(false)
          }}
          association={selectedAssociation}
          getData={getData}
        />
      )}
      {isUpdateTierModalOpen && (
        <UpdateTierModal
          visible={isUpdateTierModalOpen}
          close={() => {
            setSelectedAssociation(null)
            setIsUpdateTierModalOpen(false)
          }}
          item={selectedAssociation}
          getData={getData}
        />
      )}
    </>
  )
}

export { MyBusiness }

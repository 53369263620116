import Api, { makeRequest } from 'api'
import { uploadFile } from 'store/authSlice/authActions'
import { message } from 'antd'
import { getUserOrganization } from 'store/organizationSlice/organizationActions'
import { sortByAttendence, sortByDate, sortByZipcode } from 'utils'
import { eventActions } from './eventReducer'

export const addEvent = async (data, user, defaultFyler) => {
  let updatedData = data

  // for flyer file as it can 1 or more than 1
  if (updatedData.Flyer && updatedData.Flyer.length) {
    const FlyerFiles = []
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < updatedData.Flyer.length; i += 1) {
      const file = updatedData.Flyer[i]
      if (!file?.url && !file?.fileName) {
        const flyerFile = await uploadFile(file)
        if (flyerFile && flyerFile?.fileName) {
          FlyerFiles.push({
            fileName: flyerFile?.fileName,
            fileMimeType: flyerFile?.fileMimeType,
          })
        }
      } else {
        const index = file?.uid || i
        if (defaultFyler.length > index) {
          const defaultFile = defaultFyler[index]
          FlyerFiles.push(defaultFile)
        }
      }
    }
    /* eslint-disable no-await-in-loop */
    updatedData = {
      ...updatedData,
      Flyer: FlyerFiles,
    }
  }

  updatedData = {
    ...updatedData,
    CreatedBy: user.email,
    refLink: `${window.location.origin}/presenter/accept`,
    Username: user.id,
    isSelfPresent:
      updatedData.EventType === 'Campaign'
        ? true
        : updatedData.Presenter === 'Myself',
  }
  if (updatedData.isSelfPresent) {
    updatedData = {
      ...updatedData,
      Presenter: 'Myself',
      PresenterName: `${user.firstname} ${user.lastname}`,
      PresenterEmail: user.email,
      PresenterPhoneCode: user.phone,
      PresenterMobileNumber: user.phone,
    }
  }

  // Auction Item Image Upload
  if (updatedData.AuctionItemsList) {
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < updatedData.AuctionItemsList.length; i += 1) {
      const item = updatedData.AuctionItemsList[i]
      if (item?.itemImage?.originFileObj) {
        const itemFile = await uploadFile(item.itemImage.originFileObj)
        item.itemImage = {
          fileName: itemFile.fileName,
          fileMimeType: itemFile.fileMimeType,
        }
      }
      updatedData.AuctionItemsList[i] = item
    }
    /* eslint-disable no-await-in-loop */
  }
  delete updatedData.recaptcha
  const response = await makeRequest(
    'put',
    '/events',
    { organization: data?.OrganizationId },
    updatedData,
  )
  return response
}

export const updateEvent = async (data, user, defaultFyler) => {
  let updatedData = data
  // for Fyler file as it can 1 or more than 1
  if (updatedData.Flyer && updatedData.Flyer.length) {
    const FlyerFiles = []
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < updatedData.Flyer.length; i += 1) {
      const file = updatedData.Flyer[i]
      if (!file?.url && !file?.fileName) {
        const flyerFile = await uploadFile(file)
        if (flyerFile && flyerFile?.fileName) {
          FlyerFiles.push({
            fileName: flyerFile?.fileName,
            fileMimeType: flyerFile?.fileMimeType,
          })
        }
      } else {
        const index = file?.uid || i
        const defaultFile = defaultFyler[index]
        FlyerFiles.push(defaultFile)
      }
    }
    /* eslint-disable no-await-in-loop */
    updatedData = {
      ...updatedData,
      Flyer: FlyerFiles,
    }
  }

  updatedData = {
    ...updatedData,
    CreatedBy: user.email,
    refLink: `${window.location.origin}/presenter/accept`,
    Username: user.id,
    isSelfPresent:
      updatedData.EventType === 'Campaign'
        ? true
        : updatedData.Presenter === 'Myself',
  }
  const response = await Api.put('/events/updateEvent', updatedData)
  return response
}

export const getNearByEvents = (body, sortKey) => (dispatch) => {
  dispatch(eventActions.setLoading(true))
  Api.put('/donor/nearBy', body)
    .then((res) => {
      const sortCondition = sortKey === 'Attendence'
      const sortedData = sortCondition
        ? sortByAttendence(res?.results?.data, sortKey)
        : sortByDate(res?.results?.data, sortKey)
      dispatch(eventActions.setEvents(sortedData))
      dispatch(eventActions.setCampaigns(sortedData))
      dispatch(eventActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(eventActions.setLoading(false))
    })
}

export const getAllEvents = (loadingOff, zipCode) => (dispatch) => {
  Api.get(`/events/all`)
    .then((res) => {
      const sortedData = zipCode
        ? sortByZipcode(res?.results, 'zipCode', zipCode)
        : sortByDate(res?.results, 'StartDate')
      dispatch(eventActions.setAllEvents(sortedData))
      dispatch(eventActions.setAllCampaigns(sortedData))
      if (loadingOff) {
        loadingOff()
      }
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(eventActions.setAllEvents([]))
      loadingOff()
    })
}

export const getOrganizationEvents = (id) => (dispatch) => {
  dispatch(eventActions.setLoading(true))
  Api.get(`/events/orgEvents?id=${id}`)
    .then((res) => {
      const sortedData = sortByDate(res?.results, 'StartDate')
      dispatch(eventActions.setEvents(sortedData))
      dispatch(eventActions.setCampaigns(sortedData))
      dispatch(eventActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(eventActions.setEvents([]))
      dispatch(eventActions.setCampaigns([]))
      dispatch(eventActions.setLoading(false))
    })
}

export const getMyEvents = (sortKey) => (dispatch) => {
  dispatch(eventActions.setLoading(true))
  Api.get('/donor/campaigns')
    .then((res) => {
      const sortedData = sortKey
        ? sortByDate(res?.results, sortKey)
        : res?.results
      dispatch(eventActions.setEvents(sortedData))
      dispatch(eventActions.setCampaigns(sortedData))
      dispatch(eventActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(eventActions.setEvents([]))
      dispatch(eventActions.setCampaigns([]))
      dispatch(eventActions.setLoading(false))
    })
}

export const updateEventStatus = (eventId, status) => (dispatch) => {
  dispatch(eventActions.setLoading(true))
  if (status === 1) {
    Api.get(`events/deactive?id=${eventId}`)
      .then(() => {
        dispatch(eventActions.setLoading(false))
        message.success('Event Deactivated Successfully')
        dispatch(getMyEvents('StartDate'))
      })
      .catch((error) => {
        console.log('error', error)
        message.error('Something Went Wrong')
        dispatch(eventActions.setLoading(false))
      })
  } else if (status === 0) {
    Api.get(`events/active?id=${eventId}`)
      .then(() => {
        dispatch(eventActions.setLoading(false))
        message.success('Event Activated Successfully')
        dispatch(getMyEvents('StartDate'))
      })
      .catch((error) => {
        console.log('error', error)
        message.error('Something Went Wrong')
        dispatch(eventActions.setLoading(false))
      })
  }
}

export const getEventDetail = (id) => (dispatch) => {
  dispatch(eventActions.setLoading(true))
  Api.get(`/events?id=${id}`)
    .then((res) => {
      dispatch(eventActions.setEvent(res.results))
      dispatch(eventActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(eventActions.setLoading(false))
    })
}

export const getEventDetailWithoutLoading = (id) => (dispatch) => {
  Api.get(`/events?id=${id}`)
    .then((res) => {
      dispatch(eventActions.setEvent(res.results))
    })
    .catch((error) => {
      console.log('error', error)
    })
}

export const getEvent = async (id) => {
  const response = await Api.get(`/events?id=${id}`)
  return response.results
}

export const getEventDetails = (id) => (dispatch) => {
  dispatch(eventActions.setEventLoading(true))
  Api.get(`/events?id=${id}`)
    .then((res) => {
      dispatch(eventActions.setEvent(res.results))
      dispatch(eventActions.setEventLoading(false))
    })
    .catch(() => {
      dispatch(eventActions.setEvent(null))
      dispatch(eventActions.setEventLoading(false))
    })
}

export const getEventControlScreenDetails = (id) => (dispatch) => {
  /* eslint-disable */
  // get Event Status
  const getStatus = (id) => (dispatch) => {
    Api.get(`/presenter/stats?Id=${id}`)
      .then((res) => {
        dispatch(eventActions.setEventStatus(res?.results))
        dispatch(eventActions.setLoading(false))
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(eventActions.setLoading(false))
      })
  }
  // get Table Tasks
  const getTableTasks = (id) => (dispatch) => {
    Api.get(`/events/table-task?id=${id}`)
      .then((res) => {
        dispatch(eventActions.setEventTableTask(res?.results))
        dispatch(getStatus(id))
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(eventActions.setLoading(false))
      })
  }
  // get all Donations
  // dispatch(eventActions.setLoading(true));
  Api.get(`/presenter/allDonations?Id=${id}`)
    .then((res) => {
      if (res?.results) {
        const donations = res.results.sort(function compare(a, b) {
          var dateA = new Date(a.PaymentDate)
          var dateB = new Date(b.PaymentDate)
          return dateB - dateA
        })
        dispatch(eventActions.setEventDonations(donations))
        dispatch(getTableTasks(id))
      }
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(eventActions.setLoading(false))
    })

  Api.get(`/events/pendingCash?Id=${id}`)
    .then((res) => {
      dispatch(eventActions.setPendingCash(res?.results))
      dispatch(getTableTasks(id))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(eventActions.setLoading(false))
    })

  Api.get(`/events/seedDonations?Id=${id}`)
    .then((res) => {
      dispatch(eventActions.setSeedDonations(res?.results))
      dispatch(getTableTasks(id))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(eventActions.setLoading(false))
    })

  Api.get(`/events/getEventAuctionStatus/${id}`)
    .then((res) => {
      dispatch(eventActions.setAuctionStatus(res?.results))
      dispatch(eventActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(eventActions.setLoading(false))
    })
}

export const startEventApi = async (id, organizationId) => {
  const response = await makeRequest('get', `/events/start?Id=${id}`, {
    organization: organizationId,
  })
  return response
}

export const startFundrasingForEvent = async (id) => {
  const response = await Api.post(`/events/startFundraising?Id=${id}`)
  return response
}

export const stopFundrasingForEvent = async (id) => {
  const response = await Api.post(`/events/stopFundraising?Id=${id}`)
  return response
}

export const stopEventApi = async (id) => {
  const response = await Api.get(`/events/stop?Id=${id}`)
  return response
}

export const submitManualCheckEntry = async (data) => {
  const response = await Api.put('/presenter/submitCheckManualEntry', data)
  return response
}

export const updateAskedAmount = async (data) => {
  const response = await Api.put('/presenter/update-ask', data)
  return response
}

export const updateYoutubeLink = async (data) => {
  const response = await Api.put('/presenter/update-youtubelink', data)
  return response
}

export const updateStatus = async (data) => {
  const response = await Api.put('/presenter/changeCashStatus', data)
  return response
}

export const getSubscriberPaymentInformation =
  (organizationId) => (dispatch) => {
    Api.get(`/event/account-info?organizationId=${organizationId}`)
      .then((paymentInformation) => {
        if (paymentInformation) {
          dispatch(
            eventActions.setSubscriberStripeInfo({
              isConnected:
                paymentInformation.defaultCategory?.stripeID &&
                paymentInformation.defaultCategory?.stripeOnBoarded,
              stripeId: paymentInformation.defaultCategory?.stripeID,
            }),
          )

          dispatch(
            eventActions.setSubscriberPaypalInfo({
              isConnected: !!paymentInformation.defaultCategory?.paypalID,
              paypalId: paymentInformation.defaultCategory?.paypalID,
            }),
          )
        }
        dispatch(eventActions.setLoading(false))
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(eventActions.setLoading(false))
      })
  }

export const getSubscriberMarkupInfo = (orgId) => (dispatch) => {
  Api.get(`/admin/subsrciberMarkUpsByOrg/${orgId}`)
    .then((res) => {
      dispatch(
        eventActions.setSubscriberMarkupInfo(
          Number(res?.results?.StripeMarkup) || 1,
        ),
      )
      dispatch(eventActions.setLoading(false))
    })
    .catch(() => {
      dispatch(eventActions.setLoading(false))
    })
}

export const acceptEventInvite = (eventId, email) => (dispatch) => {
  dispatch(eventActions.setLoading(true))
  Api.get(`/events/accept-presenter?Id=${eventId}`)
    .then(() => {
      message.success('Invite Accepted Successfully')
      dispatch(eventActions.setLoading(false))
      dispatch(getAllPresenterEvents(email))
    })
    .catch(() => {
      dispatch(eventActions.setLoading(false))
    })
}

export const rejectEventInvite = (eventId, email) => (dispatch) => {
  dispatch(eventActions.setLoading(true))
  Api.get(`/events/reject-presenter?Id=${eventId}`)
    .then(() => {
      message.success('Invite Rejected Successfully')
      dispatch(eventActions.setLoading(false))
      dispatch(getAllPresenterEvents(email))
    })
    .catch(() => {
      dispatch(eventActions.setLoading(false))
    })
}

export const getDonorsEmail = async (email) => {
  const organizationResponse = await getUserOrganization(email)
  const response = await Api.get(
    `subscriber/donorList/${organizationResponse.id}`,
  )
  return response
}

export const getAllPresenterEvents = (email) => (dispatch) => {
  dispatch(eventActions.setLoading(true))
  Api.get(`/presenter/allEventByPresenter?email=${email}`)
    .then((res) => {
      const reponseResult = res?.results?.sort((a, b) => {
        const dateA = new Date(a?.StartDate)
        const dateB = new Date(b?.StartDate)
        return dateB - dateA
      })
      dispatch(eventActions.setEvents(reponseResult))
      dispatch(eventActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(eventActions.setLoading(false))
    })
}

export const addToAttendy = async (payload) => {
  const response = await Api.post('/events/markAttendence', payload)
  return response
}

export const getSoldItemDetails = (id, setLoading, setError) => (dispatch) => {
  setLoading(true)
  Api.get(`/events/getEventSoldItemDetail/${id}`)
    .then((res) => {
      const soldItem = res?.result
      dispatch(eventActions.setSoldItem(soldItem))
      dispatch(
        getEventSubscriberPaymentInformation(
          soldItem?.organizationId,
          setLoading,
        ),
      )
    })
    .catch((error) => {
      console.log('error', error)
      const errMessage =
        error?.response?.data?.message ||
        'Something went wrong in pledge detail'
      setError(errMessage)
      setLoading(false)
    })
}

const getEventSubscriberPaymentInformation =
  (organizationId, setLoading) => (dispatch) => {
    Api.get(`/event/account-info?organizationId=${organizationId}`)
      .then((paymentInformation) => {
        if (paymentInformation) {
          dispatch(
            eventActions.setSubscriberStripeInfo({
              isConnected:
                paymentInformation.defaultCategory?.stripeID &&
                paymentInformation.defaultCategory?.stripeOnBoarded,
              stripeId: paymentInformation.defaultCategory?.stripeID,
            }),
          )

          dispatch(
            eventActions.setSubscriberPaypalInfo({
              isConnected: !!paymentInformation.defaultCategory?.paypalID,
              paypalId: paymentInformation.defaultCategory?.paypalID,
            }),
          )
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
  }

export const endEventAuction =
  (eventId, organizationId, type, onClose) => (dispatch) => {
    Api.post(`events/EndEventAuction`, { eventId, organizationId, type })
      .then(() => {
        dispatch(getEventAuctionItems(eventId))
        Api.get(`/events?id=${eventId}`)
          .then((res) => {
            message.success('Auction Ended Succussfully')
            dispatch(eventActions.setEvent(res.results))
            onClose && onClose()
          })
          .catch((error) => {
            console.log('error', error)
            message.error('An error occured')
            onClose && onClose()
          })
      })
      .catch((error) => {
        console.log('error', error)
        message.error('An error occured')
        onClose && onClose()
      })
  }

export const getEventAuctionItems = (id, setLoading) => (dispatch) => {
  setLoading && setLoading(true)
  Api.get(`/events/getEventAuctionItems/${id}`)
    .then((res) => {
      const eventAuctionItems = res?.result

      const condition = eventAuctionItems && eventAuctionItems.length > 0
      dispatch(eventActions.setIsAuction(condition ? true : false))

      const notEndedAuctionItems = eventAuctionItems.filter(
        (item) => item.auctionEnded === false,
      )

      dispatch(eventActions.setEventAuctionItems(notEndedAuctionItems))
      setLoading && setLoading(false)
    })
    .catch((error) => {
      console.log('error', error)
      setLoading && setLoading(false)
    })
}

// export const getEventSoldItems = (id, setLoading) => (dispatch) => {
//   setLoading && setLoading(true)
//   Api.get(`/events/getEventSoldItems/${id}`)
//     .then((res) => {
//       const eventAuctionItems = res?.result
//       dispatch(eventActions.setEventSoldItems(eventAuctionItems))
//       setLoading && setLoading(false)
//     })
//     .catch((error) => {
//       console.log('error', error)
//       setLoading && setLoading(false)
//     })
// }

export const updateEventAuctionItemsView =
  (id, status, setLoading) => (dispatch) => {
    setLoading(true)
    Api.put(`events/updateEventAuctionItemsView`, { Id: id, Status: status })
      .then(() => {
        Api.get(`/events?id=${id}`)
          .then((res) => {
            dispatch(eventActions.setEvent(res.results))
            setLoading(false)
          })
          .catch((error) => {
            console.log('error', error)
            setLoading(false)
          })
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
  }

export const payAuctionStripe = async (payload) => {
  const response = await Api.post(`/payments/stripe/auctionPay`, payload)
  return response
}

export const payAuctionPaypal = async (payload) => {
  const response = await Api.post(`/payments/paypal/auctionPay`, payload)
  return response
}

export const getMyOrganizationEvents = async (id) => {
  const response = await Api.get(`/events/orgEvents?id=${id}`)
  return response?.results
}

export const getOrganizationTopKioskEvents = async (organizationId) => {
  const response = await Api.get(
    `/event/get-top-kiosk-events?id=${organizationId}`,
  )
  return response?.results
}

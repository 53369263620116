import React, { useEffect, useRef, useState } from 'react'
import { eventActions, useEventSelector } from 'store/eventSlice/eventReducer'
import { RiErrorWarningFill } from 'react-icons/ri'
import { awsconfig } from 'configs'
import {
  addPaypalDonation,
  generateBillingAggrementApprovalLink,
  savePaypalPaymentLog,
} from 'store/paymentSlice/paymentActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import {
  Row,
  Form,
  Checkbox,
  Button,
  Col,
  Typography,
  message,
  Input,
  Switch,
} from 'antd'
import {
  PayPalButtons,
  usePayPalScriptReducer,
  PayPalScriptProvider,
} from '@paypal/react-paypal-js'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { calculateComission, formatAmount, REGEXS } from 'utils'
import { TermAndConditionModal, AmountInput } from 'components/elements'

const { Title, Paragraph } = Typography

const amountValidation = REGEXS.amountRegex
const numberValidation = REGEXS.numberRegex

const schema = Yup.object().shape({
  askedAmount: Yup.string()
    .required('Required')
    .matches(amountValidation, 'Invalid amount'),
  agreeToTerms: Yup.string().required('Required'),
  splitDonation: Yup.string().required('Required'),
  splitnoOfMonths: Yup.string()
    .typeError('Required')
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
  repeatMonths: Yup.string().required('Required'),
  repeatnoOfMonths: Yup.string()
    .typeError('Required')
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
})

const paypalValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  askedAmount: null,
  agreeToTerms: false,
  splitDonation: false,
  splitnoOfMonths: null,
  repeatMonths: false,
  repeatnoOfMonths: null,
}

const Paypal = ({ refreshEventData }) => {
  const dispatch = useDispatch()
  const { user, location } = useAuthSelector()
  const { event, subscriberPaymentInfo, joinedEventDetails } =
    useEventSelector()
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()

  Form.useWatch('agreeToTerms', form)
  Form.useWatch('askedAmount', form)
  Form.useWatch('splitnoOfMonths', form)
  Form.useWatch('repeatnoOfMonths', form)

  const repeatMonths = form.getFieldValue('repeatnoOfMonths')
  const splitMonths = form.getFieldValue('splitnoOfMonths')

  useEffect(() => {
    form.setFieldsValue({
      askedAmount: event?.CurrentAsk,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.CurrentAsk])

  /* eslint-disable */
  const onFinish = async (values) => {
    try {
      if (!values.agreeToTerms) {
        return message.error('You must agree to the terms and conditions')
      }
      const totalDonation = values?.askedAmount

      let repeatPayment = {
        split: {
          splitDonation: Boolean(values.splitDonation),
          months: values.splitDonation ? values.splitnoOfMonths || null : null,
        },
        repeat: {
          repeatMonths: Boolean(values.repeatMonths),
          months: values.repeatMonths ? values.repeatnoOfMonths || null : null,
        },
      }
      // checking the allowed split months
      if (repeatPayment.split.splitDonation) {
        if (
          Number(repeatPayment.split.months) > Number(event?.splitPaymentMonths)
        ) {
          return message.error(
            `You can't split the donation for more than ${event?.splitPaymentMonths} months`,
          )
        }
      }
      // checking the allowed repeat months
      if (repeatPayment.repeat.repeatMonths) {
        if (
          Number(repeatPayment.repeat.months) >
          Number(event?.monthlyPaymentMonths)
        ) {
          return message.error(
            `You can't repeat the donation for more than ${event?.monthlyPaymentMonths} months`,
          )
        }
      }

      if (repeatPayment.repeat.repeatMonths) {
        repeatPayment.repeat.months = Number(repeatPayment.repeat.months) + 1
      }

      setLoading(true)
      const data = {
        amount: totalDonation,
        merchantAccount: subscriberPaymentInfo?.paypal?.paypalId,
        user,
        event,
        joinedEventDetails,
        location,
        commission: subscriberPaymentInfo.markup,
        repeatPayment,
      }
      // set the data to redux
      dispatch(eventActions.setPaypalBillingAggrementData(data))
      const response = await generateBillingAggrementApprovalLink(
        subscriberPaymentInfo?.paypal?.paypalId,
      )
      const link = response?.result?.links[0]?.href
      // const w = 700
      // const h = 650

      // const x = (screen.width - w) / 2
      // const y = (screen.height - h) / 4

      // window.open(
      //   link,
      //   'Paypal Billing Aggrement',
      //   `width=${w}, height=${h}, top=${y}, left=${x}`,
      // )
      window.open(link, '_self')
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('error', error)
    }
  }

  const isAgreed = !form.getFieldValue('agreeToTerms')

  Form.useWatch('repeatMonths', form)
  Form.useWatch('splitDonation', form)

  const askedAmountValue = form.getFieldValue('askedAmount')
  const includeEventCostValue = form.getFieldValue('includeEventCosts')

  const twoPercentage = (2 / 100) * Number(askedAmountValue)
  const totalDonation = includeEventCostValue
    ? askedAmountValue + twoPercentage
    : askedAmountValue

  const isSplit = form.getFieldValue('splitDonation')
  const isRepeat = form.getFieldValue('repeatMonths')

  const getDonateText = () => {
    if (isRepeat) {
      const totalRepeatDonationAmount = (repeatMonths || 0) * totalDonation
      const totalNumberofRepeatMonths = repeatMonths || 0
      const oneMonthAmount =
        totalRepeatDonationAmount / totalNumberofRepeatMonths
      return `You are planning donation that will repeat ${formatAmount(
        totalRepeatDonationAmount,
      )} in next ${totalNumberofRepeatMonths} months with a monthly payment of ${formatAmount(
        oneMonthAmount,
      )}. For keeping you posted your first donation will be made now of ${formatAmount(
        oneMonthAmount,
      )} and rest ${formatAmount(
        totalRepeatDonationAmount,
      )} in coming ${totalNumberofRepeatMonths} months. Please confirm by clicking below.`
    }
    if (isSplit) {
      const totalSplitDonationAmount = totalDonation
      const totalNumberofRepeatMonths = splitMonths || 0
      const oneMonthAmount = totalDonation / totalNumberofRepeatMonths
      return `You have planning donation that will split ${formatAmount(
        totalSplitDonationAmount,
      )} in next ${totalNumberofRepeatMonths} months with a monthly payment of ${formatAmount(
        oneMonthAmount,
      )}. Please confirm by clicking below.`
    }
    return `You are donating ${formatAmount(
      totalDonation,
    )}. Please confirm by clicking below.`
  }

  return (
    <div>
      {subscriberPaymentInfo?.paypal?.isConnected ? (
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="event"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row className="t-p-3">
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="askedAmount"
                label="Asked Amount"
                colon={false}
                rules={[paypalValidation]}
              >
                <AmountInput
                  placeholder="Enter amount here"
                  value={form.getFieldValue('askedAmount')}
                  onChange={(value) =>
                    form.setFieldsValue({ askedAmount: value })
                  }
                  disabled={!event?.EditAskedAmount}
                />
              </Form.Item>
            </Col>

            {event?.splitPaymentAllowed && (
              <>
                <Col
                  xl={isSplit ? 12 : 24}
                  md={isSplit ? 12 : 24}
                  xs={24}
                  sm={24}
                >
                  <Form.Item
                    name="splitDonation"
                    label="Split Payment"
                    colon={false}
                    rules={[paypalValidation]}
                  >
                    <Switch disabled={isRepeat} />
                  </Form.Item>
                </Col>
                {isSplit && (
                  <>
                    <Col
                      xl={isSplit ? 12 : 24}
                      md={isSplit ? 12 : 24}
                      xs={24}
                      sm={24}
                    >
                      <Form.Item
                        name="splitnoOfMonths"
                        label="Number of Months"
                        colon={false}
                        rules={[paypalValidation]}
                      >
                        <Input
                          placeholder="Number of Months"
                          disabled={isRepeat}
                          inputMode="numeric"
                          onChange={(e) =>
                            form.setFieldsValue({
                              splitnoOfMonths: e.target.value.replace(
                                /\D/g,
                                '',
                              ),
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Paragraph className="t-font-bold t-text-xs t-text-yellow-600 t-text-right">
                        Event creator allowed only {event?.splitPaymentMonths}{' '}
                        months
                      </Paragraph>
                    </Col>
                  </>
                )}
              </>
            )}
            {event?.monthlyPaymentAllowed && (
              <>
                <Col
                  xl={isRepeat ? 12 : 24}
                  md={isRepeat ? 12 : 24}
                  xs={24}
                  sm={24}
                >
                  <Form.Item
                    name="repeatMonths"
                    label="Months To Repeat"
                    colon={false}
                    rules={[paypalValidation]}
                  >
                    <Switch disabled={isSplit} />
                  </Form.Item>
                </Col>
                {isRepeat && (
                  <>
                    <Col
                      xl={isRepeat ? 12 : 24}
                      md={isRepeat ? 12 : 24}
                      xs={24}
                      sm={24}
                    >
                      <Form.Item
                        name="repeatnoOfMonths"
                        label="Number of Months"
                        colon={false}
                        rules={[paypalValidation]}
                      >
                        <Input
                          placeholder="Number of Months"
                          disabled={isSplit}
                          inputMode="numeric"
                          onChange={(e) =>
                            form.setFieldsValue({
                              repeatnoOfMonths: e.target.value.replace(
                                /\D/g,
                                '',
                              ),
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Paragraph className="t-font-bold t-text-xs t-text-yellow-600 t-text-right">
                        Event creator allowed only {event?.monthlyPaymentMonths}{' '}
                        months
                      </Paragraph>
                    </Col>
                  </>
                )}
              </>
            )}

            <Col xl={24} md={24} xs={24} sm={24}>
              <Paragraph className="t-text-justify">
                {getDonateText()}
              </Paragraph>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="agreeToTerms"
                colon={false}
                rules={[paypalValidation]}
              >
                <Checkbox
                  onChange={(e) =>
                    form.setFieldsValue({
                      agreeToTerms: e.target.checked,
                    })
                  }
                >
                  <TermAndConditionModal />
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24} className="text-right">
              {loading ? (
                <Button
                  loading={loading}
                  type="primary"
                  className="t-w-full md:t-w-auto"
                  htmlType="submit"
                >
                  Donate
                </Button>
              ) : (
                <>
                  {isRepeat || isSplit ? (
                    <Button
                      loading={loading}
                      type="primary"
                      className="t-w-full md:t-w-auto"
                      htmlType="submit"
                      disabled={isAgreed}
                    >
                      Schedule Payment
                    </Button>
                  ) : (
                    <>
                      <PaypalProvider
                        disabled={isAgreed}
                        amount={form
                          .getFieldValue('askedAmount')
                          ?.replaceAll(/,/g, '')}
                        setLoading={setLoading}
                        refreshEventData={refreshEventData}
                      />
                      <PaypalProvider
                        disabled={isAgreed}
                        amount={form
                          .getFieldValue('askedAmount')
                          ?.replaceAll(/,/g, '')}
                        setLoading={setLoading}
                        refreshEventData={refreshEventData}
                        isVenmo
                      />
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Form>
      ) : (
        <div className="t-h-96 t-flex t-items-center t-justify-center t-flex-col">
          <RiErrorWarningFill
            fontSize="2.5rem"
            className="t-text-primary-100"
          />
          <Title level={5}>
            Paypal payments are not configured for this event
          </Title>
          <Paragraph>You can use other payment methods</Paragraph>
        </div>
      )}
    </div>
  )
}

const PaypalProvider = (props) => {
  const { subscriberPaymentInfo } = useEventSelector()
  return (
    <PayPalScriptProvider
      options={{
        'client-id': awsconfig.paypal_client_id,
        'merchant-id': subscriberPaymentInfo?.paypal?.paypalId,
        components: 'buttons',
        currency: 'USD',
      }}
    >
      <PaypalSinglePaymentButton {...props} />
    </PayPalScriptProvider>
  )
}

const PaypalSinglePaymentButton = ({
  disabled,
  amount,
  setLoading,
  refreshEventData,
  isVenmo,
}) => {
  usePayPalScriptReducer()
  const { event, joinedEventDetails, subscriberPaymentInfo } =
    useEventSelector()
  const { user, location, geolocation } = useAuthSelector()

  const donationAmount = useRef(0)
  const comission = useRef(0)

  useEffect(() => {
    comission.current = calculateComission(
      Number(amount),
      subscriberPaymentInfo?.markup,
    )
    donationAmount.current = amount
  }, [amount])

  return (
    <PayPalButtons
      fundingSource={isVenmo ? 'venmo' : 'paypal'}
      style={{ layout: 'vertical', label: 'donate' }}
      disabled={disabled || !donationAmount.current || !comission.current}
      createOrder={(data, actions) =>
        actions.order
          .create({
            meta: {
              partner_attribution_id: awsconfig.paypal_bncode,
              'PayPal-Partner-Attribution-ID': awsconfig.paypal_bncode,
            },
            purchase_units: [
              {
                amount: {
                  value: Number(donationAmount.current),
                  breakdown: {
                    item_total: {
                      currency_code: 'USD',
                      value: Number(donationAmount.current),
                    },
                  },
                },
                payment_instruction: {
                  disbursement_mode: 'INSTANT',
                  platform_fees: [
                    {
                      amount: {
                        currency_code: 'USD',
                        value: Number(comission.current),
                      },
                    },
                  ],
                },
                items: [
                  {
                    name: event?.Title,
                    quantity: '1',
                    unit_amount: {
                      currency_code: 'USD',
                      value: Number(donationAmount.current),
                    },
                    category: 'DONATION',
                  },
                ],
              },
            ],
          })
          .then((orderId) => orderId)
          .catch((error) => {
            // console.log('error', error)
            // const jsonText = error?.message.substring(
            //   error?.message.indexOf('{'),
            //   error?.message.lastIndexOf('}') + 1,
            // )
            // const parsedText = JSON.parse(jsonText)
            // const errorMessage = parsedText?.message
            throw new Error(
              'Request is not well-formed, syntactically incorrect, or violates schema',
            )
          })
      }
      onApprove={(data, actions) =>
        actions.order
          .capture()
          .then(async (details) => {
            setLoading(true)
            const capturedAmount = details?.purchase_units[0]?.amount?.value
            const paymentId =
              details?.purchase_units[0]?.payments.captures[0].id
            try {
              const payload = {
                event,
                user,
                amount: capturedAmount,
                location,
                joinedEventDetails,
                geolocation,
                paymentId,
              }
              await addPaypalDonation(payload)
              refreshEventData()
              message.success('Your donation is successfully submitted')
              setLoading(false)
            } catch (err) {
              message.error(err?.message || 'Something went wrong')
              setLoading(false)
            }
          })
          .catch((error) => {
            throw new Error('Paypal failed to capture the payment')
          })
      }
      onError={async (error) => {
        message.error('Could not able to process your payment')
        const payload = {
          type: 'PAYPAL_DONATE',
          status: 'FAILURE',
          message: error?.message,
          action: 'Paypal payment for event donation',
        }
        await savePaypalPaymentLog(payload)
      }}
      onCancel={() => message.info('Payment is cancelled')}
    />
  )
}

export { Paypal }

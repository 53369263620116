import React, { useEffect } from 'react'
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Button,
  Switch,
  Skeleton,
  DatePicker,
  Select,
} from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { hardwareValidation } from 'validations'
// import { AmountInput } from 'components/elements'
import {
  addHardware,
  getHardware,
  getOrganizationSelectorOptions,
  updateHardware,
} from 'store/hardwareSlice/hardwareActions'
import { useHardwareSelector } from 'store/hardwareSlice/hardwareReducer'

const { TextArea } = Input

const initialValues = {
  OrganizationId: '',
  HardwareType: '',
  DeviceSerialNumber: '',
  MDMDeviceName: '',
  DeploymentDate: null,
  CustomerOwned: false,
  // Amount: '',
  // HealthCheck: '',
  // BatteryPercent: '',
  CardReaderSerialNumber: '',
  CardReaderIdentifier: '',
  CardReaderType: '',
  BluetoothCode: '',
  Notes: '',
}

const AddOrEditHardware = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { organizationId, hardwareId } = params
  const isEdit = organizationId && hardwareId
  const { loading, organizationOptions } = useHardwareSelector()
  const { fetchOrganizationOptions, addOrUpdate } = loading
  const [form] = Form.useForm()

  Form.useWatch('CustomerOwned', form)
  Form.useWatch('DeploymentDate', form)
  useEffect(() => {
    dispatch(getOrganizationSelectorOptions())
  }, [dispatch])

  useEffect(() => {
    if (isEdit) {
      dispatch(getHardware(organizationId, hardwareId, form))
    }
  }, [dispatch, isEdit, organizationId, hardwareId, form])

  const navigation = () => {
    form.resetFields()
    history.push('/hardware')
  }

  const onFinish = async (values) => {
    const {
      OrganizationId,
      HardwareType,
      DeviceSerialNumber,
      MDMDeviceName,
      DeploymentDate,
      CustomerOwned,
      // Amount,
      CardReaderSerialNumber,
      CardReaderIdentifier,
      CardReaderType,
      BluetoothCode,
      Notes,
    } = values

    const payload = {
      HardwareType,
      DeviceSerialNumber,
      MDMDeviceName,
      DeploymentDate: moment(DeploymentDate).format('YYYY-MM-DD'),
      CustomerOwned,
      // Amount,
      CardReaderSerialNumber,
      CardReaderIdentifier,
      CardReaderType,
      BluetoothCode,
      Notes,
    }
    if (isEdit) {
      payload.OrganizationId = organizationId
      payload.HardwareId = hardwareId
      dispatch(updateHardware(payload, navigation))
    } else {
      const OrganizationName = organizationOptions.find(
        (item) => item.value === OrganizationId,
      ).label
      payload.OrganizationId = OrganizationId
      payload.OrganizationName = OrganizationName
      dispatch(addHardware(payload, navigation))
    }
  }

  const buttonText = false
    ? isEdit
      ? 'Updating Hardware'
      : 'Adding Hardware'
    : hardwareId
    ? 'Update Hardware'
    : 'Add Hardware'

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0 t-text-2xl">
            {isEdit ? 'Update Hardware' : 'Add Hardware'}
          </h6>
          <p className="font-regular">Enter Information</p>
        </>,
      ]}
    >
      <Skeleton
        loading={fetchOrganizationOptions}
        active
        paragraph={{ rows: 18 }}
      >
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="Hardware Form"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={12} xl={12}>
              <Form.Item
                name="OrganizationId"
                label="Organization*"
                colon={false}
                rules={[hardwareValidation]}
              >
                <Select
                  placeholder="Select Organization"
                  style={{ width: '100%' }}
                  showSearch
                  optionFilterProp="children"
                  disabled={isEdit}
                >
                  {[...organizationOptions]
                    .filter((x) => x.status === 'Active')
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} xl={12}>
              <Form.Item
                name="HardwareType"
                label="Hardware Type*"
                colon={false}
                rules={[hardwareValidation]}
              >
                <Select
                  placeholder="Select Hardware Type "
                  style={{ width: '100%' }}
                >
                  {['Kiosk', 'Tv'].map((item, index) => (
                    <Select.Option value={item} key={index}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} xl={12}>
              <Form.Item
                name="DeviceSerialNumber"
                label="Device Serial Number*"
                colon={false}
                rules={[hardwareValidation]}
              >
                <Input placeholder="Enter Device Serial Number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} xl={12}>
              <Form.Item
                name="MDMDeviceName"
                label="MDM Device Name*"
                colon={false}
                rules={[hardwareValidation]}
              >
                <Input placeholder="Enter MDM Device Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} xl={12}>
              <Form.Item
                name="DeploymentDate"
                label="Deployment Date*"
                colon={false}
                rules={[hardwareValidation]}
              >
                <DatePicker
                  format="MM-DD-YYYY"
                  className="t-w-full"
                  disabledDate={(d) => !d || d.isAfter(moment())}
                  // onChange={(selectedDate) =>
                  //   !compareDateTimeWithNow(selectedDate, 'before') &&
                  //   form.setFieldsValue({
                  //     StartDate: moment(),
                  //   })
                  // }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} xl={12}>
              {/* <div className="horizontal-override"> */}
              <Form.Item
                name="CustomerOwned"
                label="Customer Owned"
                colon={false}
                rules={[hardwareValidation]}
              >
                <Switch checked={form.getFieldValue('CustomerOwned')} />
              </Form.Item>
              {/* </div> */}
            </Col>
            {/* <Col xs={24} sm={24} md={12} xl={12}>
              <Form.Item
                name="Amount"
                label="Amount*"
                colon={false}
                rules={[hardwareValidation]}
              >
                <AmountInput
                  placeholder="Amount"
                  onChange={(value) =>
                    form.setFieldsValue({
                      Amount: value,
                    })
                  }
                />
              </Form.Item>
            </Col> */}
            {/* <Col xs={24} sm={24} md={12} xl={12}>
              <Form.Item
                name="HealthCheck"
                label="Health Check"
                colon={false}
                rules={[hardwareValidation]}
              >
                <Input placeholder="Enter Health Check" />
              </Form.Item>
            </Col> */}
            {/* <Col xs={24} sm={24} md={12} xl={12}>
              <Form.Item
                name="BatteryPercent"
                label="Battery Percentage"
                colon={false}
                rules={[hardwareValidation]}
              >
                <Input placeholder="Enter Battery Percentage" />
              </Form.Item>
            </Col> */}
            <Col xs={24} sm={24} md={12} xl={12}>
              <Form.Item
                name="CardReaderSerialNumber"
                label="Card Reader Serial Number"
                colon={false}
                rules={[hardwareValidation]}
              >
                <Input placeholder="Enter Card Reader Serial Number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} xl={12}>
              <Form.Item
                name="CardReaderIdentifier"
                label="Card Reader Identifier"
                colon={false}
                rules={[hardwareValidation]}
              >
                <Input placeholder="Enter Card Reader Identifier" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} xl={12}>
              <Form.Item
                name="CardReaderType"
                label="Card Reader Type"
                colon={false}
                rules={[hardwareValidation]}
              >
                <Select
                  placeholder="Card Reader Type"
                  style={{ width: '100%' }}
                >
                  {['Stripe'].map((item, index) => (
                    <Select.Option value={item} key={index}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} xl={12}>
              <Form.Item
                name="BluetoothCode"
                label="BluetoothCode"
                colon={false}
                rules={[hardwareValidation]}
              >
                <Input placeholder="Enter Bluetooth Code" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="Notes"
                label="Notes"
                colon={false}
                rules={[hardwareValidation]}
              >
                <TextArea
                  placeholder="Notes"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
            <Col span={24} className="text-left t-space-x-2">
              <Button
                loading={addOrUpdate}
                type="primary"
                className="px-25"
                htmlType="submit"
              >
                {buttonText}
              </Button>
              <Button
                type="primary"
                className="px-25"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </Card>
  )
}

export { AddOrEditHardware }

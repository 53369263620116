import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: false,
  fundraiser: null,
  fundraisers: [],
  inactiveFundraisers: [],
  joinedFundraiserDetails: null,
  fundraiserDetail: {
    status: null,
    donations: [],
    pendingCash: [],
    seedDonations: [],
    tableTasks: [],
    auctionStatus: null,
    isAuction: false,
  },
  fundraiserAuctionItems: [],
  fundraiserSoldItems: [],
  subscriberPaymentInfo: {
    stripe: {
      isConnected: false,
      stripeId: null,
    },
    paypal: {
      isConnected: false,
      paypalId: null,
    },
    markup: 1,
  },
  soldItem: null,
}

export const fundraiserSlice = createSlice({
  name: 'fundraiser',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = { ...state, loading: payload }
      return alteredState
    },
    setFundraisers: (state, { payload }) => {
      const activeFundraisers = payload.filter((item) => item.activeInd === 1)
      const inactiveFundraisers = payload.filter((item) => item.activeInd === 0)
      const alteredState = {
        ...state,
        fundraisers: activeFundraisers,
        inactiveFundraisers,
      }
      return alteredState
    },
    setJoinedFundraiserDetails: (state, { payload }) => {
      const alteredState = {
        ...state,
        joinedFundraiserDetails: payload,
      }
      return alteredState
    },
    setFundraiser: (state, { payload }) => {
      const alteredState = { ...state, fundraiser: payload }
      return alteredState
    },
    setFundraiserAuctionItems: (state, { payload }) => {
      const alteredState = { ...state, fundraiserAuctionItems: payload }
      return alteredState
    },
    setFundraiserStatus: (state, { payload }) => {
      const alteredState = {
        ...state,
        fundraiserDetail: { ...state.fundraiserDetail, status: payload },
      }
      return alteredState
    },
    setSubscriberStripeInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        subscriberPaymentInfo: {
          ...state.subscriberPaymentInfo,
          stripe: {
            isConnected: payload.isConnected,
            stripeId: payload.stripeId,
          },
        },
      }
      return alteredState
    },
    setSubscriberPaypalInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        subscriberPaymentInfo: {
          ...state.subscriberPaymentInfo,
          paypal: {
            isConnected: payload.isConnected,
            paypalId: payload.paypalId,
          },
        },
      }
      return alteredState
    },
    setSubscriberMarkupInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        subscriberPaymentInfo: {
          ...state.subscriberPaymentInfo,
          markup: payload,
        },
      }
      return alteredState
    },
    setIsAuction: (state, { payload }) => {
      const alteredState = {
        ...state,
        fundraiserDetail: { ...state.fundraiserDetail, isAuction: payload },
      }
      return alteredState
    },
    setFundraiserTableTask: (state, { payload }) => {
      const alteredState = {
        ...state,
        fundraiserDetail: { ...state.fundraiserDetail, tableTasks: payload },
      }
      return alteredState
    },
    setPendingCash: (state, { payload }) => {
      const alteredState = {
        ...state,
        fundraiserDetail: { ...state.fundraiserDetail, pendingCash: payload },
      }
      return alteredState
    },
    setSeedDonations: (state, { payload }) => {
      const alteredState = {
        ...state,
        fundraiserDetail: { ...state.fundraiserDetail, seedDonations: payload },
      }
      return alteredState
    },
    setFundraiserDonations: (state, { payload }) => {
      const alteredState = {
        ...state,
        fundraiserDetail: { ...state.fundraiserDetail, donations: payload },
      }
      return alteredState
    },
    setAuctionStatus: (state, { payload }) => {
      const alteredState = {
        ...state,
        fundraiserDetail: { ...state.fundraiserDetail, auctionStatus: payload },
      }
      return alteredState
    },
    setSoldItem: (state, { payload }) => {
      const alteredState = { ...state, soldItem: payload }
      return alteredState
    },
    reset: () => initialState,
  },
})

export const useFundraiserSelector = () =>
  useSelector((state) => state.fundraiser)
export const fundraiserActions = fundraiserSlice.actions
export const fundraiserReducer = fundraiserSlice.reducer

import React, { useEffect, useState } from 'react'
import { Skeleton, Table } from 'antd'
import Api from 'api'
import { formatAmount, formateDate } from 'utils'

function PledgeCommittments({ id, setValue }) {
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()

  // const headerStyle = {
  //   backgroundColor: '#f5f5f5', // Light grey background
  //   color: '#333', // Darker text color
  //   fontSize: '13px', // Larger font size
  //   fontWeight: 'bold', // Bold font weight
  //   borderBottom: '2px solid #e8e8e8', // Bottom border
  //   boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Optional: Box shadow
  // }
  // const components = {
  //   header: {
  //     cell: (props) => (
  //       <th {...props} style={{ ...headerStyle, ...props.style }} />
  //     ),
  //   },
  // }

  useEffect(() => {
    setLoading(true)
    Api.get(`adminReports/getPledgersListByEvent?id=${id}`)
      .then((res) => {
        const data = res.results
        setReport(data)
        setValue(data)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  const TotalAmount = (dataArray) => {
    const total = dataArray.reduce((acc, obj) => acc + Number(obj.Amount), 0)
    return formatAmount(total)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'DonorName',
      key: 'DonorName',
    },
    {
      title: 'Phone Number',
      dataIndex: 'DonorPhoneNumber',
      key: 'DonorPhoneNumber',
    },
    {
      title: 'Email',
      dataIndex: 'DonorEmail',
      key: 'DonorEmail',
    },
    {
      title: 'Amount Pledged',
      dataIndex: 'Amount',
      key: 'Amount',
      render: (text) => formatAmount(text),
    },
    {
      title: 'Pledge Date',
      dataIndex: 'ScheduleDate',
      key: 'ScheduleDate',
      render: (text) => (text ? formateDate(text) : ''),
    },
    {
      title: 'Monthly Recurring',
      dataIndex: 'IsMonthly',
      key: 'IsMonthly',
      render: (text) => (text ? 'Yes' : 'No'),
    },
    {
      title: 'Split Payment',
      dataIndex: 'IsSplit',
      key: 'IsSplit',
      render: (text) => (text ? 'Yes' : 'No'),
    },
    {
      title: 'Status',
      key: 'Status',
      render: (record) =>
        record.PledgeStatus === 'Completed'
          ? 'Donated Successfully'
          : 'Coming Soon',
    },
  ]

  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      {report && report.length > 0 ? (
        <>
          <div className="t-flex t-items-center t-mb-8">
            <h1 className="t-font-semibold t-mx-2">Total amount of pledges:</h1>
            <p className="t-font-semibold">{TotalAmount(report)}</p>
          </div>
          <div>
            <Table
              dataSource={report}
              columns={columns}
              rowKey={(record) => record.id}
              // components={components}
              scroll={{ x: true }} // Enables horizontal scrolling
              pagination={false} // Disable default pagination
              // loading={loading}
            />
          </div>
        </>
      ) : (
        <div>No Data Found</div>
      )}
    </Skeleton>
  )
}

export default PledgeCommittments

import React, { useEffect, useState } from 'react'
import {
  Input,
  Form,
  Row,
  Col,
  Switch,
  Card,
  Button,
  message,
  Typography,
  Radio,
} from 'antd'
import {
  formatAmount,
  getDataFromDonationType,
  getEstimatedProcessingFee,
  getFormatedPhone,
  getFormattedPaymentAccountInfo,
  getIsEventPledgeConversion,
  isPaymentCategoriesAdded,
} from 'utils'
import { AmountInput, PaymentNotConfigured } from 'components/elements'
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { awsconfig } from 'configs'
import { loadStripe } from '@stripe/stripe-js'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { donationValidation } from 'validations'
import { BankDebit } from 'components/common/unAuthenticatedPayments'
import { Paypal } from './Paypal'
import { UserForm } from './UserForm'
import { TermsAndConditionsModal } from './TermsAndConditionsModal'

const { Title } = Typography

const initialValues = {
  defaultAmount: '',
  askedAmount: '',
  card: 'no-card-selected',
  bank: null,
  firstName: '',
  lastName: '',
  email: '',
  countryCode: '+1',
  phoneNumber: null,
  isAnonymous: false,
  includeEventCosts: false,
  repeatMonths: false,
  repeatnoOfMonths: null,
  PaymentMethod: 0,
  mailingAddress: '',
}

const WrappedDonateForm = ({
  donationType,
  donateDataForm,
  item,
  isPaymentCategoriesPresent,
  paymentCategories,
  subscriberPaymentInfo,
  setLoading,
  isLoading,
  onSubmit,
  onPaypalSubmit,
  selectedCategory,
  setSelectedCategory,
}) => {
  const { organization, event, defaultDonationValues } =
    getDataFromDonationType({
      item,
      donationType,
    })
  const { user } = useAuthSelector()
  const { joinedEventDetails } = useEventSelector()
  const [banks, setBanks] = useState([])

  const stripe = useStripe()
  const elements = useElements()
  Form.useWatch('card', donateDataForm)
  Form.useWatch('countryCode', donateDataForm)
  Form.useWatch('phoneNumber', donateDataForm)
  Form.useWatch('askedAmount', donateDataForm)
  Form.useWatch('PaymentCategory', donateDataForm)
  Form.useWatch('PaymentMethod', donateDataForm)

  const askedAmountValue = Form.useWatch('askedAmount', donateDataForm)
  const includeEventCostValue = Form.useWatch(
    'includeEventCosts',
    donateDataForm,
  )
  const paymentCategoryValue = Form.useWatch('PaymentCategory', donateDataForm)
  const paymentMethodValue = Form.useWatch('PaymentMethod', donateDataForm)

  Form.useWatch('repeatMonths', donateDataForm)
  Form.useWatch('repeatnoOfMonths', donateDataForm)

  // const repeatMonths = donateDataForm.getFieldValue('repeatnoOfMonths')
  const isRepeat = donateDataForm.getFieldValue('repeatMonths')

  Form.useWatch('bank', donateDataForm)
  const bank = donateDataForm.getFieldValue('bank')
  const isNoBankSelected = bank === null || bank === undefined
  const isNewBankSelected = bank === 'new-bank-selected'

  useEffect(() => {
    donateDataForm.setFieldValue('bank', null)
    setBanks([])
    // eslint-disable-next-line
  }, [selectedCategory])

  useEffect(() => {
    if (isPaymentCategoriesPresent) {
      if (selectedCategory === null) {
        donateDataForm.setFieldValue('PaymentCategory', 0)
      } else {
        donateDataForm.setFieldValue('PaymentCategory', selectedCategory)
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const firstFormItemInput = document.querySelector(
      '.ant-form-item-control input',
    )
    if (firstFormItemInput) {
      firstFormItemInput.focus()
    }
  }, [])

  /* eslint-disable consistent-return */
  const onFinish = async (data) => {
    const { PaymentMethod } = data
    const isBankAccount = PaymentMethod === 1
    let cardToken = null

    let bankDetails = null
    if (isBankAccount) {
      if (!data?.bank || data?.bank === 'no-bank-selected') {
        message.error('No Bank Account Selected')
        return false
      }
      bankDetails = banks.find((bankItem) => bankItem.id === data?.bank)
    } else {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      })
      if (error) {
        message.error(error?.message || 'Invalid Card Information')
        return false
      }
      cardToken = paymentMethod.id
    }

    setLoading(true)
    const typedAmount = data.askedAmount
    const includeEventCost = data.includeEventCosts
    const { markup } = subscriberPaymentInfo
    const processingAmount = getEstimatedProcessingFee(markup, typedAmount)
    const totalDonation = includeEventCost
      ? Number(typedAmount) + processingAmount
      : typedAmount
    const {
      countryCode,
      phoneNumber,
      mailingAddress,
      suite,
      zipCode,
      city,
      state,
      country,
    } = data

    const phone = getFormatedPhone(countryCode, phoneNumber)
    const completeAddress = {
      suite: suite || '',
      city: city || '',
      state: state || '',
      country: country || '',
      zipCode: zipCode || '',
    }

    const repeatPayment = {
      split: {
        splitDonation: false,
        months: null,
      },
      repeat: {
        repeatMonths: isMigratedDonation ? true : Boolean(data.repeatMonths),
        months: isMigratedDonation
          ? data.repeatnoOfMonths
          : data.repeatMonths
          ? data.repeatnoOfMonths || null
          : null,
      },
    }

    // checking the allowed repeat months
    if (repeatPayment.repeat.repeatMonths) {
      // Condition For Event
      if (
        isEventRelated &&
        Number(repeatPayment.repeat.months) >
          Number(event?.monthlyPaymentMonths)
      ) {
        setLoading(false)
        return message.error(
          `You can't repeat the donation for more than ${event?.monthlyPaymentMonths} months`,
        )
      }

      // Condition For Organization
      if (
        isOrganizationRelated &&
        Number(repeatPayment.repeat.months) > Number(36)
      ) {
        setLoading(false)
        return message.error(
          `You can't repeat the donation for more than ${36} months`,
        )
      }
    }

    const DummyPaymentCategory = data?.PaymentCategory
    let StripeAccountID
    if (DummyPaymentCategory !== undefined) {
      StripeAccountID = paymentCategories[DummyPaymentCategory]?.StripeId
    } else {
      StripeAccountID = subscriberPaymentInfo?.stripe?.stripeId
    }

    const selectedPaymentCategoryId = getSelectedPaymentCategoryId()
    const payload = {
      isBankAccount,
      cardToken,
      bank: bankDetails,
      paymentCategory: selectedPaymentCategoryId,
      stripeAccount: StripeAccountID,
      commission: subscriberPaymentInfo?.markup,
      amount: totalDonation,
      includeEventCost: includeEventCost || false,
      eventCostAmount: includeEventCost ? processingAmount.toFixed(2) : 0,
      typedAmount,
      user: {
        id: user ? user.id : null,
        email: data.email,
        firstname: data.firstName,
        lastname: data.lastName,
        phone,
        countryCode: data.countryCode,
        phoneNumber: data.phoneNumber?.replace(/\D/g, ''),
      },
      event: isEventRelated ? event : null,
      organization: isOrganizationRelated ? organization : null,
      location: null,
      joinedEventDetails: {
        isRemote: user ? joinedEventDetails?.isRemote : true,
        tableName: user ? joinedEventDetails?.tableName : null,
        donateAnonymously: data?.isAnonymous,
      },
      repeatPayment,
    }
    if (isShowMailingAddress) {
      payload.mailingAddress = mailingAddress
      payload.completeAddress = completeAddress
    }
    if (isMigratedDonation) {
      payload.recurringDonorId = item?.Id
    }
    if (isPledgeConversion) {
      payload.pledgeId = item?.Id
    }
    onSubmit(payload)
  }

  /* eslint-disable consistent-return */
  const onFinishWithoutPayment = async (data) => {
    setLoading(true)
    const typedAmount = data.askedAmount
    const totalDonation = typedAmount
    const {
      countryCode,
      phoneNumber,
      // mailingAddress,
      // suite,
      // zipCode,
      // city,
      // state,
      // country,
    } = data

    const phone = getFormatedPhone(countryCode, phoneNumber)
    // const completeAddress = {
    //   suite: suite || '',
    //   city: city || '',
    //   state: state || '',
    //   country: country || '',
    //   zipCode: zipCode || '',
    // }

    const repeatPayment = {
      split: {
        splitDonation: false,
        months: null,
      },
      repeat: {
        repeatMonths: Boolean(data.repeatMonths),
        months: null,
      },
    }

    const payload = {
      pledgeType: isPledge ? (isEventPledge ? 'event' : 'organization') : null,
      amount: totalDonation,
      // includeEventCost: false,
      // eventCostAmount: 0,
      typedAmount,
      user: {
        id: user ? user.id : null,
        email: data.email,
        firstname: data.firstName,
        lastname: data.lastName,
        phone,
        countryCode: data.countryCode,
        phoneNumber: data.phoneNumber?.replace(/\D/g, ''),
      },
      event: isEventRelated ? event : null,
      organization,
      location: null,
      joinedEventDetails: {
        isRemote: user ? joinedEventDetails?.isRemote : true,
        tableName: user ? joinedEventDetails?.tableName : null,
        donateAnonymously: data?.isAnonymous,
      },
      repeatPayment,
    }
    // if (isShowMailingAddress) {
    //   payload.mailingAddress = mailingAddress
    //   payload.completeAddress = completeAddress
    // }
    onSubmit(payload)
  }

  const onPaypalSuccess = async (values) => {
    setLoading(true)
    const data = donateDataForm.getFieldsValue()

    const typedAmount = data.askedAmount
    const includeEventCost = data.includeEventCosts

    const { markup } = subscriberPaymentInfo
    const processingAmount = getEstimatedProcessingFee(markup, typedAmount)
    const totalDonation = includeEventCost
      ? Number(typedAmount) + processingAmount
      : typedAmount

    const {
      countryCode,
      phoneNumber,
      mailingAddress,
      suite,
      zipCode,
      city,
      state,
      country,
    } = data

    const phone = getFormatedPhone(countryCode, phoneNumber)
    const completeAddress = {
      suite: suite || '',
      city: city || '',
      state: state || '',
      country: country || '',
      zipCode: zipCode || '',
    }

    const selectedPaymentCategoryId = getSelectedPaymentCategoryId()
    const payload = {
      paymentId: values.paymentId,
      merchantId: values.merchantId,
      paymentCategory: selectedPaymentCategoryId,
      amount: totalDonation,
      includeEventCost: includeEventCost || false,
      eventCostAmount: includeEventCost ? processingAmount.toFixed(2) : 0,
      typedAmount,
      user: {
        id: user ? user.id : null,
        email: data.email,
        firstname: data.firstName,
        lastname: data.lastName,
        phone,
        countryCode: data.countryCode,
        phoneNumber: data.phoneNumber?.replace(/\D/g, ''),
      },
      event: isEventRelated ? event : null,
      organization: isOrganizationRelated ? organization : null,
      location: null,
      joinedEventDetails: {
        isRemote: user ? joinedEventDetails?.isRemote : true,
        tableName: user ? joinedEventDetails?.tableName : null,
        donateAnonymously: data?.isAnonymous,
      },
    }
    if (isShowMailingAddress) {
      payload.mailingAddress = mailingAddress
      payload.completeAddress = completeAddress
    }
    if (isMigratedDonation) {
      payload.recurringDonorId = item?.Id
    }
    if (isPledgeConversion) {
      payload.pledgeId = item?.Id
    }
    onPaypalSubmit(payload)
  }

  const getTotal = () => {
    const { markup } = subscriberPaymentInfo
    const processingAmount = getEstimatedProcessingFee(markup, askedAmountValue)
    const totalDonation = includeEventCostValue
      ? Number(askedAmountValue) + processingAmount
      : askedAmountValue
    return totalDonation
  }

  const isStripeAvailable =
    paymentCategoryValue !== undefined
      ? paymentCategories[paymentCategoryValue]?.StripeId
      : subscriberPaymentInfo?.stripe?.isConnected

  const isPaypalAvailable =
    paymentCategoryValue !== undefined
      ? paymentCategories[paymentCategoryValue]?.PaypalId
      : subscriberPaymentInfo?.paypal?.isConnected

  useEffect(() => {
    if (isStripeAvailable) {
      donateDataForm.setFieldValue('PaymentMethod', 0)
    } else if (isPaypalAvailable) {
      donateDataForm.setFieldValue('PaymentMethod', 2)
    }
    // eslint-disable-next-line
  }, [isStripeAvailable, isPaypalAvailable])

  const getSelectedPaymentCategoryId = () => {
    const DummyPaymentCategory = paymentCategoryValue
    let SelectedPaymentCategory

    if (DummyPaymentCategory !== undefined) {
      SelectedPaymentCategory = paymentCategories[DummyPaymentCategory]?.Id
    } else {
      SelectedPaymentCategory = subscriberPaymentInfo?.Id
    }
    return SelectedPaymentCategory
  }

  const getStripeId = () => {
    const DummyPaymentCategory = paymentCategoryValue
    let StripeAccountID

    if (DummyPaymentCategory !== undefined) {
      StripeAccountID = paymentCategories[DummyPaymentCategory]?.StripeId
    } else {
      StripeAccountID = subscriberPaymentInfo?.stripe?.stripeId
    }
    return StripeAccountID
  }

  const getPaypalId = () => {
    const DummyPaymentCategory = paymentCategoryValue
    let PaypalAccountID

    if (DummyPaymentCategory !== undefined) {
      PaypalAccountID = paymentCategories[DummyPaymentCategory]?.PaypalId
    } else {
      PaypalAccountID = subscriberPaymentInfo?.paypal?.paypalId
    }
    return PaypalAccountID
  }

  const isStripeAvailableAndNotPaypalSelected =
    isStripeAvailable && paymentMethodValue !== 2

  const isCreditCardOrNotNewBankSelected =
    paymentMethodValue === 0 || (banks.length > 0 && !isNewBankSelected)

  const isBankAndBankPresentButNotSelected =
    paymentMethodValue === 1 && banks.length > 0 && isNoBankSelected

  const onDefaultValuecahnge = (values) => {
    donateDataForm.setFieldsValue({
      defaultAmount: values,
      askedAmount: values,
    })
  }

  const isShowMailingAddress =
    !event?.hasOwnProperty('ShowMailingAddress') || event?.ShowMailingAddress

  // Related to event
  const isEventDonation = donationType === 'event-donation'
  const isEventPledge = donationType === 'event-pledge'

  // Check pledge conversion type
  const isEventPledgeConversion =
    donationType === 'pledge-conversion' && getIsEventPledgeConversion(item)
  const isOrganizationPledgeConversion =
    donationType === 'pledge-conversion' &&
    item?.TransactionType === 'organization'
  const isPledgeConversion =
    isEventPledgeConversion || isOrganizationPledgeConversion

  const isEventDonationOrPledge =
    isEventDonation || isEventPledge || isEventPledgeConversion
  const isSeedDonation = donationType === 'seed-donation'

  // Related to organization
  const isOrganizationDonation = donationType === 'organization-donation'
  const isMigratedDonation = donationType === 'migrated-donation'
  const isOrganizationPledge = donationType === 'organization-pledge'

  const isPledge = isEventPledge || isOrganizationPledge
  const isEventRelated =
    isEventDonationOrPledge || isSeedDonation || isEventPledge
  const isOrganizationRelated =
    isOrganizationDonation ||
    isMigratedDonation ||
    isOrganizationPledgeConversion

  const isPaymentNeed = !isEventPledge && !isOrganizationPledge
  const isAmountNotEditable =
    (isEventDonation || isEventPledge || isEventPledgeConversion) &&
    event?.EditAskedAmount === false

  return (
    <Form
      form={donateDataForm}
      layout="vertical"
      key={0}
      onFinish={isPaymentNeed ? onFinish : onFinishWithoutPayment}
      name="donation"
      scrollToFirstError
      initialValues={initialValues}
    >
      <Row gutter={[24, 0]}>
        <Col xl={14} md={13} xs={24} sm={24}>
          <UserForm
            form={donateDataForm}
            isShowMailingAddress={isShowMailingAddress && !isPledge}
            itemType={donationType}
            item={item}
          />
        </Col>
        <Col xl={10} md={11} xs={24} sm={24}>
          <Card
            className="header-solid t-p-2 md:t-p-10 t-m-4 t-shadow-2xl t-rounded-lg"
            bordered={false}
          >
            <Col span={24} className="t-p-0">
              <Form.Item label="Select or enter an amount" colon={false}>
                <Radio.Group
                  className="space-between-radio"
                  value={donateDataForm.getFieldValue('defaultAmount')}
                  onChange={(e) => onDefaultValuecahnge(e.target.value)}
                >
                  {defaultDonationValues?.map((donationValue, index) => (
                    <Radio key={index} value={donationValue}>
                      ${donationValue}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col xl={24} md={24} xs={24} sm={24} className="t-p-0">
              <Form.Item
                name="askedAmount"
                label="Donation *"
                colon={false}
                rules={[donationValidation]}
              >
                <AmountInput
                  placeholder="Donation"
                  disabled={isAmountNotEditable}
                  onChange={(value) =>
                    donateDataForm.setFieldsValue({
                      askedAmount: value,
                      defaultAmount: value,
                    })
                  }
                />
              </Form.Item>
            </Col>

            {(((isEventDonation || isPledgeConversion) &&
              event?.monthlyPaymentAllowed) ||
              isOrganizationRelated) && (
              <Row>
                {!isMigratedDonation && (
                  <Col
                    xl={isRepeat ? 12 : 24}
                    md={isRepeat ? 12 : 24}
                    xs={24}
                    sm={24}
                  >
                    <Form.Item
                      name="repeatMonths"
                      label="Months To Repeat"
                      colon={false}
                      rules={[donationValidation]}
                    >
                      <Switch
                        checked={donateDataForm.getFieldValue('repeatMonths')}
                      />
                    </Form.Item>
                  </Col>
                )}
                {(isRepeat || isMigratedDonation) && (
                  <>
                    <Col
                      xl={isRepeat ? 12 : 24}
                      md={isRepeat ? 12 : 24}
                      xs={24}
                      sm={24}
                    >
                      <Form.Item
                        name="repeatnoOfMonths"
                        label="Number of Months"
                        colon={false}
                        rules={[donationValidation]}
                      >
                        <Input
                          placeholder="Number of Months"
                          // disabled={isSplit}
                          inputMode="numeric"
                          onChange={(e) =>
                            donateDataForm.setFieldsValue({
                              repeatnoOfMonths: e.target.value.replace(
                                /\D/g,
                                '',
                              ),
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    {/* <Col span={24}>
                      <Paragraph className="t-font-bold t-text-xs t-text-yellow-600 t-text-right">
                        Event creator allowed only {event?.monthlyPaymentMonths}{' '}
                        months
                      </Paragraph>
                    </Col> */}
                  </>
                )}
              </Row>
            )}

            {(isEventPledge && event?.monthlyPaymentAllowed) ||
              (isOrganizationPledge && (
                <Col xl={24} md={24} xs={24} sm={24}>
                  <div className="horizontal-override">
                    <Form.Item
                      name="repeatMonths"
                      label="Schedule Monthly Payment"
                      colon={false}
                      rules={[donationValidation]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Switch
                        checked={donateDataForm.getFieldValue('repeatMonths')}
                      />
                    </Form.Item>
                  </div>
                </Col>
              ))}

            {!isEventPledge && !isOrganizationPledge && (
              <Row>
                <Col xl={12} md={12} xs={12} sm={12}>
                  <Form.Item
                    name="isAnonymous"
                    label="Remain Anonymous"
                    colon={false}
                    rules={[donationValidation]}
                  >
                    <Switch
                      defaultChecked={donateDataForm.getFieldValue(
                        'isAnonymous',
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} xs={12} sm={12}>
                  <Form.Item
                    name="includeEventCosts"
                    label={
                      isEventRelated
                        ? event?.EventType === 'Fundraiser'
                          ? 'Include Event Fees'
                          : 'Include Campaign Fees'
                        : 'Include Processing Fees'
                    }
                    colon={false}
                    // rules={[donationValidation]}
                  >
                    <Switch
                      defaultChecked={donateDataForm.getFieldValue(
                        'includeEventCosts',
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Col xl={24} md={24} xs={24} sm={24} className="t-p-0">
              <Title level={5} className="text-right">
                {`Estimated Total: ${formatAmount(getTotal())}`}
              </Title>
            </Col>

            {isPaymentNeed ? (
              <>
                <Col
                  xl={24}
                  md={24}
                  xs={24}
                  sm={24}
                  className={`t-p-0 ${
                    isPaymentCategoriesPresent && paymentCategories?.length >= 2
                      ? 't-block'
                      : 't-hidden'
                  }`}
                >
                  <Form.Item
                    name="PaymentCategory"
                    label="Select Payment Category"
                    colon={false}
                  >
                    <Radio.Group
                      // value={form.getFieldValue('MasterRecord')}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      <Radio value={0}>
                        {paymentCategories?.length > 0 &&
                          paymentCategories[0]?.Name}
                      </Radio>
                      <Radio value={1}>
                        {paymentCategories?.length > 1 &&
                          paymentCategories[1]?.Name}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                {(isStripeAvailable || isPaypalAvailable) && (
                  <Col
                    span={24}
                    // className="t-p-0 t-mb-2"
                    className={`t-p-0 t-mb-2 ${
                      isStripeAvailable ? 't-block' : 't-hidden'
                    }`}
                  >
                    <Form.Item
                      name="PaymentMethod"
                      label="Select Payment Method"
                      colon={false}
                    >
                      <Radio.Group className="t-space-y-1">
                        {isStripeAvailable && (
                          <Radio value={0}>Credit Card</Radio>
                        )}
                        {isStripeAvailable && (
                          <Radio value={1}>Bank Account</Radio>
                        )}
                        {isPaypalAvailable && <Radio value={2}>Paypal</Radio>}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                )}

                {isStripeAvailable && (
                  <Col xl={24} md={24} xs={24} sm={24} className="t-mb-2 t-p-0">
                    {paymentMethodValue === 0 && (
                      <>
                        <h1 className="t-text-sm t-my-2 t-font-semibold">
                          Enter Card Number
                        </h1>
                        <div className="t-p-2 t-rounded-md t-border t-border-grey-900'">
                          <CardElement
                            options={{
                              style: {
                                base: {
                                  fontSize: '15px',
                                  fontSmoothing: 'antialiased',
                                },
                              },
                            }}
                          />
                        </div>
                      </>
                    )}
                    {paymentMethodValue === 1 && (
                      <BankDebit
                        banks={banks}
                        setBanks={setBanks}
                        form={donateDataForm}
                        stripeAccount={getStripeId()}
                      />
                    )}
                  </Col>
                )}

                {isStripeAvailableAndNotPaypalSelected && (
                  <>
                    {isCreditCardOrNotNewBankSelected && (
                      <Col span={24} className="text-right t-p-0 t-mb-2">
                        <Button
                          type="primary"
                          className="t-w-full"
                          htmlType="submit"
                          loading={isLoading}
                          disabled={
                            !stripe ||
                            !elements ||
                            isBankAndBankPresentButNotSelected
                          }
                        >
                          Donate
                        </Button>
                      </Col>
                    )}
                  </>
                )}
                {isPaypalAvailable && paymentMethodValue === 2 && (
                  <Paypal
                    form={donateDataForm}
                    itemType={donationType}
                    item={item}
                    subscriberPaymentInfo={subscriberPaymentInfo}
                    getPaypalId={getPaypalId}
                    getSelectedPaymentCategoryId={getSelectedPaymentCategoryId}
                    onSubmit={onPaypalSuccess}
                    donating={isLoading}
                    isEventRelated={isEventRelated}
                    isOrganizationRelated={isOrganizationRelated}
                  />
                )}
              </>
            ) : (
              <>
                <Col span={24} className="text-right t-p-0 t-mb-2">
                  <Button
                    type="primary"
                    className="t-w-full"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Pledge
                  </Button>
                </Col>
              </>
            )}

            {isStripeAvailable || isPaypalAvailable ? (
              <TermsAndConditionsModal />
            ) : (
              <Col xl={24} md={24} xs={24} sm={24}>
                <PaymentNotConfigured />
              </Col>
            )}
          </Card>
        </Col>
      </Row>
    </Form>
  )
}

function StripeProvider({
  donationType,
  item,
  isEventPayment,
  setLoading,
  isLoading,
  onSubmit,
  onPaypalSubmit,
}) {
  // get event conditionally from item and donationType
  const { organization, event, defaultDonationValues } =
    getDataFromDonationType({
      item,
      donationType,
    })

  const subscriberPaymentInfo = getFormattedPaymentAccountInfo(
    organization?.PaymentDefaultAccount,
    organization?.PaymentMarkup,
  )

  const [donateDataForm] = Form.useForm()
  const [firstLoad, setFirstLoad] = useState(true)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [stripePromise, setStripePromise] = useState(null)

  const isSetFirstDefaultValue =
    donationType === 'organization-donation' ||
    donationType === 'organization-pledge' ||
    donationType === 'event-donation' ||
    donationType === 'event-pledge' ||
    donationType === 'seed-donation'

  useEffect(() => {
    if (donationType === 'pledge-conversion') {
      donateDataForm.setFieldValue('askedAmount', item?.Amount)
      if (item?.IsMonthly) {
        donateDataForm.setFieldValue('repeatMonths', item.IsMonthly)
      }
    } else if (isSetFirstDefaultValue && defaultDonationValues?.length > 0) {
      donateDataForm.setFieldValue('askedAmount', defaultDonationValues[0])
      donateDataForm.setFieldValue('defaultAmount', defaultDonationValues[0])
    }
    // eslint-disable-next-line
  }, [])

  const isPaymentCategoriesPresent = isEventPayment
    ? isPaymentCategoriesAdded(
        event?.PaymentCategoryAdded,
        event?.paymentCategories,
      )
    : isPaymentCategoriesAdded(
        organization?.PaymentCategoryAdded,
        organization?.PaymentCategoriesDetails,
      )

  const paymentCategories = isEventPayment
    ? event?.paymentCategories
    : organization?.PaymentCategoriesDetails

  const getStripeAccountId = () => {
    let stripeAccount = null
    if (selectedCategory !== null) {
      stripeAccount = paymentCategories[selectedCategory]?.StripeId
    } else if (firstLoad && isPaymentCategoriesPresent) {
      stripeAccount = paymentCategories[0]?.StripeId
    } else {
      stripeAccount = subscriberPaymentInfo?.stripe?.stripeId
    }
    return stripeAccount
  }

  useEffect(() => {
    // debugger // eslint-disable-line
    const stripeAccount = getStripeAccountId()
    const newStripePromise = loadStripe(awsconfig.stripe_publish_key, {
      stripeAccount,
    })
    setStripePromise(newStripePromise)
    if (firstLoad) setFirstLoad(false)
    // eslint-disable-next-line
  }, [selectedCategory])

  return (
    <Elements stripe={stripePromise} key={selectedCategory}>
      <WrappedDonateForm
        donateDataForm={donateDataForm}
        donationType={donationType}
        item={item}
        isPaymentCategoriesPresent={isPaymentCategoriesPresent}
        paymentCategories={paymentCategories}
        subscriberPaymentInfo={subscriberPaymentInfo}
        setLoading={setLoading}
        isLoading={isLoading}
        onSubmit={onSubmit}
        onPaypalSubmit={onPaypalSubmit}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
    </Elements>
  )
}
const DonateForm = StripeProvider
export { DonateForm }

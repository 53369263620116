import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: {
    getStripeAccountInfo: false,
    getBusiness: true, // This is set to true to prevent the page from loading until the business is fetched
    getBusinessPaymentAccount: false,
    addBusinessStripePaymentProcessor: false,
    addBusinessPaypalPaymentProcessor: false,
    deleteBusinessStripePaymentProcessor: false,
    deleteBusinessPaypalPaymentProcessor: false,
    updateBusinessStripeAccount: false,
    linkBusinessPaypalAccount: false,
  },
  stripeAccountInfo: {
    details_submitted: false,
    charges_enabled: false,
    pending: [],
    errors: [],
    requirements: [],
  },
  business: null,
  businessPaymentAccount: {
    stripe: {
      isConnected: false,
      stripeId: null,
    },
    paypal: {
      isConnected: false,
      paypalId: null,
    },
  },
}

export const paymentAccountSlice = createSlice({
  name: 'paymentAccount',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const { type, value } = payload
      const alteredState = {
        ...state,
        loading: { ...state.loading, [type]: value },
      }
      return alteredState
    },
    setStripeAccountInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        stripeAccountInfo: payload,
      }
      return alteredState
    },
    resetStripeAccountInfo: (state) => {
      const alteredState = {
        ...state,
        stripeAccountInfo: {
          errors: [],
          requirements: [],
          pending: [],
        },
      }
      return alteredState
    },
    setBusiness: (state, { payload }) => {
      const alteredState = {
        ...state,
        business: payload,
      }
      return alteredState
    },
    setBusinessPaymentAccount: (state, { payload }) => {
      const alteredState = {
        ...state,
        businessPaymentAccount: payload,
      }
      return alteredState
    },
    resetBusinessPaymentAccount: (state) => {
      const alteredState = {
        ...state,
        businessPaymentAccount: {
          stripe: {
            isConnected: false,
            stripeId: null,
          },
          paypal: {
            isConnected: false,
            paypalId: null,
          },
        },
      }
      return alteredState
    },
    reset: () => initialState,
  },
})

export const usePaymentAccountSelector = () =>
  useSelector((state) => state.paymentAccount)
export const paymentAccountActions = paymentAccountSlice.actions
export const paymentAccountReducer = paymentAccountSlice.reducer

import React, { useEffect, useState } from 'react'
import {
  Card,
  Col,
  Tag,
  Skeleton,
  Switch,
  Button,
  Popconfirm,
  message,
  Table,
  Menu,
  Space,
  Dropdown,
} from 'antd'
// import { MdHomeWork } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import {
  approveRefund,
  getOrganizationReservation,
} from 'store/roomSlice/roomActions'
import { useRoomSelector } from 'store/roomSlice/roomReducer'
import { useAuthSelector } from 'store/authSlice/authReducer'
import moment from 'moment'
import { formatAmount, searchRentalsByOtherAttributes } from 'utils'
import { CiMenuKebab } from 'react-icons/ci'
// import { EllipsisOutlined } from '@ant-design/icons'
// import { AiFillEye } from 'react-icons/ai'
import { ReservationCalendar } from './ReservationCalender'
import { ConfirmationModal } from './ConfirmationModal'
import { ViewReservation } from './ViewReservation'

const MyReservations = ({ searchText }) => {
  const dispatch = useDispatch()
  const { organization } = useAuthSelector()
  const { loading, reservations } = useRoomSelector()

  const [calendarView, setCalendar] = useState(false)
  const [visible, setVisible] = useState(false)
  const [viewModal, setViewModal] = useState(false)
  // const [isLoading, setLoading] = useState(false)
  const [selectedReservation, setSelectedReservation] = useState(null)

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line
  }, [])

  const refreshData = () =>
    dispatch(getOrganizationReservation(organization?.id))

  const onTakeActionClick = (reservationId) => {
    const reservation = reservations.find((item) => item?.Id === reservationId)
    setSelectedReservation(reservation)
    setVisible(!visible)
  }

  const onViewReservation = (reservationId) => {
    const reservation = reservations.find((item) => item?.Id === reservationId)
    setSelectedReservation(reservation)
    setViewModal(!viewModal)
  }

  const onConfirmRefund = async (reservationId) => {
    // setLoading(true)
    try {
      await approveRefund(reservationId)
      message.success('Refund request approved')
      dispatch(getOrganizationReservation(organization?.id))
    } catch (error) {
      message.error('Error sending refund request')
    }
    // setLoading(true)
  }

  const tableData = reservations.map((item) => {
    const scheduleDate = moment(item?.Date)
    const currentDate = moment()
    const isDatePassed = scheduleDate.isAfter(currentDate)
    const isActionableDatePassed = isDatePassed
    const isRequestPending = item?.AcceptanceStatus === 'Pending'
    const isRequestRejected = item?.AcceptanceStatus === 'Rejected'
    const isRequestAccepted = item?.AcceptanceStatus === 'Accepted'
    const isChangeRequestPending = item?.AcceptanceStatus === 'ChangeRequested'
    const isRefundRequestPending = item?.RefundRequestStatus === 'Pending'
    const isRefundRequestApproved = item?.RefundRequestStatus === 'Approved'
    const isPaid = item?.PaymentStatus === 'Completed'
    const isPaymentProcessing = item?.PaymentStatus === 'Processing'

    return {
      key: item.Id, // Required by Ant Design table for unique row key
      RoomName: item?.RoomName,
      CreatorName: item?.CreatorName,
      TotalCost: formatAmount(item?.UpdatedCost || item?.TotalCost), // Assuming this is the total amount
      Status: {
        isRequestAccepted,
        isPaid,
        isRequestRejected,
        isRequestPending,
        isChangeRequestPending,
        isRefundRequestPending,
        isRefundRequestApproved,
        isPaymentProcessing,
        isActionableDatePassed,
        reservationId: item.Id,
      },
    }
  })

  function CustomMenu(item) {
    return (
      <Menu>
        {item?.Status?.isRequestPending &&
          item?.Status?.isActionableDatePassed && (
            <Menu.Item
              onClick={() => onTakeActionClick(item?.key)}
              type="primary"
              size="small"
              key="1"
            >
              Take Action
            </Menu.Item>
          )}
        {item?.Status?.isRefundRequestPending && (
          <Menu.Item key="2">
            <Popconfirm
              title="Do you want to approve the refund request?"
              okText="Approve"
              cancelText="Cancel"
              placement="left"
              onConfirm={() => onConfirmRefund(item?.key)} // Your confirm action here
            >
              <Button type="primary" size="small">
                Approve Refund Request
              </Button>
            </Popconfirm>
          </Menu.Item>
        )}
        <Menu.Item key="4" onClick={() => onViewReservation(item?.key)}>
          View Details
        </Menu.Item>
        {/* Add more conditions as per your logic */}
      </Menu>
    )
  }

  const columns = [
    {
      title: 'TITLE',
      dataIndex: 'RoomName',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'RESERVED BY',
      dataIndex: 'CreatorName',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'TOTAL AMOUNT',
      dataIndex: 'TotalCost',
      className: 'ant-table-row-cell-break-word align-center',
    },
    {
      title: 'STATUS',
      dataIndex: 'Status',
      className: 'ant-table-row-cell-break-word',
      render: (status) => (
        <div className="t-flex t-space-x-1 t-items-center">
          {status.isRequestAccepted &&
            !status.isPaid &&
            !status.isPaymentProcessing && (
              <Tag className="t-text-[#00bd9b] t-font-semibold t-bg-[#dbf2ee] t-px-2 t-py-1 t-rounded t-ml-[-20px] t-mr-2 t-border-none">
                Accepted
              </Tag>
            )}
          {status.isRequestRejected && (
            <Tag className="t-text-red-600 t-font-semibold t-bg-red-100 t-px-2 t-py-1 t-rounded t-ml-[-20px] t-mr-2 t-border-none">
              Rejected
            </Tag>
          )}
          {status.isPaid &&
            !status.isRefundRequestPending &&
            !status.isRefundRequestApproved && (
              <Tag className="t-text-[#00bd9b] t-font-semibold t-bg-[#dbf2ee] t-px-2 t-py-1 t-rounded t-ml-[-20px] t-mr-2 t-border-none">
                Paid
              </Tag>
            )}
          {status.isRefundRequestApproved && (
            <Tag className="t-text-blue-600 t-font-semibold t-bg-blue-100 t-px-2 t-py-1 t-rounded t-ml-[-20px] t-mr-2 t-border-none">
              Refund Approved
            </Tag>
          )}
          {status.isChangeRequestPending && (
            <Tag className="t-text-purple-600 t-font-semibold t-bg-purple-100 t-px-2 t-py-1 t-rounded t-ml-[-20px] t-mr-2 t-border-none">
              Change request sent
            </Tag>
          )}
          {status.isRequestPending && (
            <Tag className="t-text-yellow-600 t-font-semibold t-bg-yellow-100 t-px-2 t-py-1 t-rounded t-ml-[-20px] t-mr-2 t-border-none">
              In Progress
            </Tag>
          )}
          {status.isPaymentProcessing && (
            <Tag className="t-text-gray-600 t-font-semibold t-bg-gray-100 t-px-2 t-py-1 t-rounded t-ml-[-20px] t-mr-2 t-border-none">
              Payment Processing
            </Tag>
          )}
          {status.isRefundRequestPending && (
            <Tag className="t-text-gray-600 t-font-semibold t-bg-gray-100 t-px-2 t-py-1 t-rounded t-ml-[-20px] t-mr-2 t-border-none">
              Refund Pending
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: 'ACTION',
      dataIndex: 'status',
      className: 'ant-table-row-cell-break-word',
      render: (_, item) => (
        <Space size="middle">
          <Dropdown overlay={CustomMenu(item)} trigger={['click']}>
            <CiMenuKebab style={{ cursor: 'pointer' }} />
          </Dropdown>
        </Space>
      ),
    },
  ]

  const updatedRooms = searchRentalsByOtherAttributes(tableData, searchText)

  return (
    <Card
      // title={[
      //   <div>
      //     <h4 className="mb-0">My Reservations</h4>
      //     <p className="font-regular">Manage Reservations</p>
      //   </div>,
      // ]}
      className="t-mb-5 t-border-none t-shadow-none"
    >
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <div className="t-w-full t-flex t-justify-end t-mb-4 t-space-x-2 t-items-center">
          <h1 className="t-font-bold">Calender View</h1>
          <Switch
            title="View As Admin"
            checked={calendarView}
            defaultChecked={false}
            onChange={(checked) => setCalendar(checked)}
          />
        </div>
        {!calendarView ? (
          <Col span={24}>
            {updatedRooms?.length === 0 && (
              <Card className="t-h-96 t-w-full t-flex t-justify-center t-items-center">
                <div>No Reservations</div>
              </Card>
            )}

            <Table
              columns={columns}
              dataSource={updatedRooms || []}
              tableLayout="auto"
              scroll={{ x: 500 }}
              bordered={false}
              pagination={false}
              className="td-right"
            />
          </Col>
        ) : (
          <ReservationCalendar />
        )}
      </Skeleton>
      <ConfirmationModal
        visible={visible}
        reservation={selectedReservation}
        refresh={refreshData}
        setVisible={() => setVisible(!visible)}
      />
      <ViewReservation
        visible={viewModal}
        reservation={selectedReservation}
        setVisible={() => setViewModal(!viewModal)}
      />
    </Card>
  )
}

export { MyReservations }

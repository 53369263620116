import React from 'react'
import { Modal, Col, Row, Typography, Tag, Divider, message, Table } from 'antd'
import { formatAmount, formateDate } from 'utils'
import { FaClipboard } from 'react-icons/fa'

const { Title } = Typography

const columns = [
  {
    title: 'Question',
    dataIndex: 'question',
    render: (text) => <h1>{text}</h1>,
  },
  {
    title: 'Answer',
    dataIndex: 'answer',
    render: (text) => <h1>{text === '' ? '--' : text}</h1>,
  },
]

const ViewReservation = ({ visible, setVisible, reservation }) => {
  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value)
    message.success('Copied to Clipboard')
  }

  const isRequestPending = reservation?.AcceptanceStatus === 'Pending'
  const isRequestRejected = reservation?.AcceptanceStatus === 'Rejected'
  const isRequestAccepted = reservation?.AcceptanceStatus === 'Accepted'
  const isChangeRequestPending =
    reservation?.AcceptanceStatus === 'ChangeRequested'
  const isRefundRequestPending = reservation?.RefundRequestStatus === 'Pending'
  const isRefundRequestApproved =
    reservation?.RefundRequestStatus === 'Approved'
  const isPaid = reservation?.PaymentStatus === 'Completed'
  const isPaymentProcessing = reservation?.PaymentStatus === 'Processing'

  return (
    <Modal
      centered
      title="Reservation Detail"
      open={visible}
      onCancel={setVisible}
      destroyOnClose
      width={800}
      footer={null}
    >
      <Row gutter={[24, 0]}>
        <Col xl={24} md={24} xs={24} sm={24}>
          <Title level={5}>Donor Details</Title>
          <div className="t-flex t-flex-col">
            <h1>{`Donor name : ${reservation?.CreatorName}`}</h1>
            <h1 className="t-flex t-items-center t-space-x-2">
              {`Donor email : ${reservation?.CreatorEmail}`}{' '}
              <span className="t-ml-2">
                <FaClipboard
                  fontSize="1rem"
                  onClick={() => copyToClipboard(reservation?.CreatorEmail)}
                  className="t-text-secondary-100 t-cursor-pointer"
                />
              </span>
            </h1>
            <h1 className="t-flex t-items-center t-space-x-2">
              {`Donor phone number : ${reservation?.CreatorPhoneNumber}`}
              <span className="t-ml-2">
                <FaClipboard
                  fontSize="1rem"
                  onClick={() =>
                    copyToClipboard(reservation?.CreatorPhoneNumber)
                  }
                  className="t-text-secondary-100 t-cursor-pointer"
                />
              </span>
            </h1>
          </div>
        </Col>
        <Divider />
        <Col xl={24} md={24} xs={24} sm={24}>
          <Title level={5}>Reservation Timings</Title>
          <div className="t-flex t-flex-col">
            <h1>{`Room name : ${reservation?.RoomName}`}</h1>
            <div className="t-my-1">
              Durations :{' '}
              <span>
                {reservation?.Durations.map((item) => (
                  <Tag color="green">{`${item?.from} - ${item?.to}`}</Tag>
                ))}
              </span>
            </div>
            <h1>{`Is Recurring : ${
              reservation?.IsRecurring ? 'Yes' : 'No'
            }`}</h1>
            <div>Dates :</div>
            {reservation?.IsRecurring ? (
              <div>
                {reservation?.RecurringDates.map((item) => (
                  <Tag color="purple" className=" t-my-1">{`${formateDate(
                    item,
                  )}`}</Tag>
                ))}
              </div>
            ) : (
              <div>
                <Tag color="purple">{`${formateDate(reservation?.Date)}`}</Tag>
              </div>
            )}
          </div>
        </Col>
        <Divider />
        <Col xl={24} md={24} xs={24} sm={24}>
          <Title level={5}>Cost Details</Title>
          <div className="t-flex t-flex-col">
            <h1>{`Total hours : ${reservation?.TotalHours}`}</h1>
            <h1>{`Total cost : ${formatAmount(
              reservation?.UpdatedCost || reservation?.TotalCost,
            )}`}</h1>
          </div>
        </Col>
        <Divider />
        <Col xl={24} md={24} xs={24} sm={24}>
          <Title level={5}>Reservation Status</Title>
          <div className="t-flex t-flex-col">
            <div>
              {isRequestAccepted && !isPaid && !isPaymentProcessing && (
                <Tag className="t-text-[#00bd9b] t-bg-[#dbf2ee]">Accepted</Tag>
              )}
              {isRequestRejected && (
                <Tag className="t-text-red-600 t-bg-red-100">Rejected</Tag>
              )}
              {isPaid &&
                !isRefundRequestPending &&
                !isRefundRequestApproved && (
                  <Tag className="t-text-[#00bd9b] t-bg-[#dbf2ee]">Paid</Tag>
                )}
              {isRefundRequestApproved && (
                <Tag className="t-text-blue-600 t-bg-blue-100">
                  Refund Approved
                </Tag>
              )}
              {isChangeRequestPending && (
                <Tag className="t-text-purple-600 t-bg-purple-100">
                  Change request sent
                </Tag>
              )}
              {isRequestPending && (
                <Tag className="t-text-yellow-600 t-bg-yellow-100">
                  In Progress
                </Tag>
              )}
              {isPaymentProcessing && (
                <Tag className="t-text-gray-600 t-bg-gray-100">
                  Payment Processing
                </Tag>
              )}
              {isRefundRequestPending && (
                <Tag className="t-text-gray-600 t-bg-gray-100">
                  Refund Pending
                </Tag>
              )}
            </div>
          </div>
        </Col>
        <Divider />
        <Col xl={24} md={24} xs={24} sm={24} pb={10}>
          <Title level={5}>Questions & Answers</Title>
          <Table
            columns={columns}
            dataSource={reservation?.Question}
            bordered
            footer={() => ''}
            pagination={false}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export { ViewReservation }

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Typography, Checkbox, Tooltip } from 'antd'
import { FiPlus } from 'react-icons/fi'
import { DurationSelector } from './DurationSelector'
// import { MdDelete } from 'react-icons/md'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { RxTrash } from 'react-icons/rx'
const { Title } = Typography
const DayDurations = ({ value, onChange }) => {
  const [updatedItemValue, setUpdatedItemValue] = useState()
  const [editableIndex, setEditableIndex] = useState(null)
  const [showPlusButton, setShowPlusButton] = useState(true)

  const { id } = useParams()
  const addDuration = () => {
    const updatedItem = {
      ...value,
      open: true,
      durations: [...value.durations, { from: '12:00 AM', to: '12:30 AM' }],
    }
    setUpdatedItemValue(updatedItem)
    setEditableIndex(updatedItem?.durations?.length - 1)
    onChange(updatedItem)
  }

  const changeAvailability = () => {
    console.log(value.open)
    const updatedItem = {
      ...value,
      open: !value.open,
    }
    onChange(updatedItem)
  }

  const onDeleteClick = (index) => {
    const durationsCloned = [...value.durations]
    durationsCloned.splice(index, 1)
    const updatedItem = {
      ...value,
      durations: durationsCloned,
    }

    if (updatedItem.durations.length === 0) {
      updatedItem.open = false
    }
    setEditableIndex(updatedItem?.durations?.length - 1)
    onChange(updatedItem)
  }

  const onDurationChangeFinished = (updatedValue, index) => {
    const durationsCloned = [...value.durations]
    durationsCloned[index] = updatedValue
    const updatedItem = {
      ...value,
      durations: durationsCloned,
    }
    onChange(updatedItem)
  }

  const onChangeNonStop = () => {
    const updatedItem = {
      ...value,
      open: !value.nonstop,
      nonstop: !value.nonstop,
      durations: [],
    }
    onChange(updatedItem)
  }

  useEffect(() => {
    if (value?.durations?.length && editableIndex === null) {
      setEditableIndex(value.durations.length - 1)
    }
  }, [value?.durations, editableIndex])

  const handleShowPlusButton = (val) => {
    setShowPlusButton(val)
  }

  const handleMenuClick = (e) => {
    onChangeNonStop()
  }

  // useEffect(() => {
  //   // Trigger addDuration only once on initial mount and if it's not edit
  //   if (!id && !value.durations.length) {
  //     addDuration()
  //   }
  // }, [value.durations])

  return (
    <div className="t-flex t-space-x-2 t-p-2">
      <div
        className="t-flex t-space-x-2 t-mt-4 t-min-w-36"
        style={{
          minWidth: '9rem',
        }}
      >
        {/* <Checkbox
          onChange={changeAvailability}
          checked={value.open}
          className="t-font-bold t-text-secondary-100"
        /> */}
        <div className="">
          <Title
            level={5}
            className="t-text-black t-font-semibold t-capitalize"
          >
            {value.dayName}
          </Title>
        </div>
      </div>
      <div className="t-flex t-flex-row t-justify-between t-w-[100%]">
        <div className="">
          {value.nonstop ? (
            <div className="t-flex t-flex-row t-space-x-4 t-mt-4">
              <div>Day is available for whole day</div>
              <Tooltip title="Delete Duration">
                <RxTrash
                  className="t-text-black t-cursor-pointer t-px-2 t-text-4xl t-mt-[-4px]"
                  onClick={() => handleMenuClick(value.nonstop)}
                />
              </Tooltip>
              {/* <Tooltip title="Add Selective Duration">
                <Checkbox checked={value.nonstop} onClick={handleMenuClick} />
              </Tooltip> */}
            </div>
          ) : value.durations.length === 0 ? (
            <h3 className="t-mt-5">Unavailable</h3>
          ) : (
            value.durations.map((element, index) => (
              <div className="t-flex t-flex-row" key={index}>
                <div className="t-flex t-flex-col">
                  <DurationSelector
                    onDurationChange={(updatedDuration) =>
                      onDurationChangeFinished(updatedDuration, index)
                    }
                    indexValue={index}
                    value={element}
                    key={index}
                    onDeleteClick={() => onDeleteClick(index)}
                    updatedItem={updatedItemValue}
                    isEditable={editableIndex === index}
                    // disabledHours={getSelectedHours(value.durations)}
                    // disabledMinutes={getDisableMinutes}
                    // onShowPlusButton={handleShowPlusButton}
                    // addDuration={addDuration}
                    // day={value.dayName}
                    // schedule={updateSchedule}
                  />
                </div>
                {index === value.durations.length - 1 && (
                  <div className="t-px-2 t-flex t-flex-row t-mt-2">
                    <div>
                      {value?.durations?.length > 0 && (
                        <Tooltip title="Delete Duration">
                          <RxTrash
                            className="t-text-black t-cursor-pointer t-px-2 t-text-4xl"
                            onClick={() => onDeleteClick(index)}
                          />
                        </Tooltip>
                      )}
                    </div>
                    {/* <Tooltip title="Click for whole day">
                      <Checkbox
                        onClick={() => handleMenuClick(value)}
                        className="t-mt-2 t-px-2"
                        checked={value.nonstop}
                      />
                    </Tooltip> */}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
        {!value.nonstop && (
          <div className="t-flex t-flex-row t-right-0">
            <h5
              onClick={() => handleMenuClick(value.nonstop)}
              className="t-text-[#2e97de] t-cursor-pointer t-mt-5 t-mx-5"
            >
              Available whole day
            </h5>
            {value?.durations?.length < 4 && (
              <Tooltip title="Add New Duration">
                <FiPlus
                  onClick={addDuration}
                  className="t-text-black t-cursor-pointer t-px-2 t-text-4xl t-mt-4"
                />
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export { DayDurations }

import React from 'react'
import { Skeleton, Table } from 'antd'
import moment from 'moment'
import { formatAmount } from 'utils'

const PendingPledgesList = ({
  loading,
  data,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const formateData = (values) => {
    const formatedData = values?.map((item) => ({
      ...item,
      key: item.Id,
    }))
    return formatedData || []
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'DonorName',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Email',
      dataIndex: 'DonorEmail',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Phone Number',
      dataIndex: 'DonorPhoneNumber',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      className: 'ant-table-row-cell-break-word',
      render: (_, { Amount }) => `${formatAmount(Amount)}`,
    },
    {
      title: 'Scheduled Date',
      dataIndex: 'ScheduleDate',
      className: 'ant-table-row-cell-break-word',
      render: (_, { ScheduleDate }) =>
        moment(ScheduleDate).format('YYYY/MM/DD'),
    },
  ]

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
      <Table
        tableLayout="auto"
        scroll={{ x: 500 }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={formateData(data)}
        bordered={false}
        pagination={false}
      />
    </Skeleton>
  )
}

export { PendingPledgesList }

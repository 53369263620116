import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: {
    fetchProfile: false,
    fetchProfileList: false,
    fetchInUseList: false,
    addOrUpdate: false,
    deleting: false,
  },
  profile: null,
  profileList: [],
  inUseList: [],
}

export const organizationProfilesSlice = createSlice({
  name: 'organizationProfiles',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = {
        ...state,
        loading: { ...state.loading, ...payload },
      }
      return alteredState
    },
    setProfile: (state, { payload }) => {
      const alteredState = { ...state, profile: payload }
      return alteredState
    },
    setProfileList: (state, { payload }) => {
      const alteredState = {
        ...state,
        profileList: payload,
      }
      return alteredState
    },
    setInUseList: (state, { payload }) => {
      const alteredState = {
        ...state,
        inUseList: payload,
      }
      return alteredState
    },
    reset: () => initialState,
  },
})

export const useOrganizationProfileSelector = () =>
  useSelector((state) => state.organizationProfiles)
export const organizationProfilesActions = organizationProfilesSlice.actions
export const organizationProfilesReducer = organizationProfilesSlice.reducer

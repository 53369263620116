import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: false,
  eventLoading: false,
  event: null,
  pledge: null,
  soldItem: null,
  events: [],
  allEvents: [],
  inactiveEvents: [],
  inactiveCampaigns: [],
  campaigns: [],
  allCampaigns: [],
  eventDetail: {
    status: null,
    donations: [],
    pendingCash: [],
    seedDonations: [],
    tableTasks: [],
    auctionStatus: null,
    isAuction: false,
  },
  subscriberPaymentInfo: {
    stripe: {
      isConnected: false,
      stripeId: null,
    },
    paypal: {
      isConnected: false,
      paypalId: null,
    },
    markup: 1,
  },
  joinedEventDetails: null,
  paypalBillingAggrementSubmitData: null,
  eventAuctionItems: [],
  eventSoldItems: [],
}

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = { ...state, loading: payload }
      return alteredState
    },
    setEventLoading: (state, { payload }) => {
      const alteredState = { ...state, eventLoading: payload }
      return alteredState
    },
    setEvent: (state, { payload }) => {
      const alteredState = { ...state, event: payload }
      return alteredState
    },
    setPledge: (state, { payload }) => {
      const alteredState = { ...state, pledge: payload }
      return alteredState
    },
    setSoldItem: (state, { payload }) => {
      const alteredState = { ...state, soldItem: payload }
      return alteredState
    },
    setEvents: (state, { payload }) => {
      const filteredEvent = payload.filter(
        (item) => item.EventType === 'Fundraiser',
      )
      const activeEvents = filteredEvent.filter((item) => item.ActiveInd === 1)
      const inactiveEvents = filteredEvent.filter(
        (item) => item.ActiveInd === 0,
      )
      const alteredState = {
        ...state,
        events: activeEvents,
        inactiveEvents,
      }
      return alteredState
    },
    setAllEvents: (state, { payload }) => {
      const filteredEvent = payload.filter(
        (item) => item.EventType === 'Fundraiser',
      )
      const activeEvents = filteredEvent.filter((item) => item.ActiveInd === 1)
      const alteredState = {
        ...state,
        allEvents: activeEvents,
      }
      return alteredState
    },
    setCampaigns: (state, { payload }) => {
      const filteredEvent = payload.filter(
        (item) => item.EventType === 'Campaign',
      )
      const activeCampaigns = filteredEvent.filter(
        (item) => item.ActiveInd === 1 && item.Fundraising !== 'Stopped',
      )
      const inactiveCampaigns = filteredEvent.filter(
        (item) => item.ActiveInd === 0,
      )
      const alteredState = {
        ...state,
        campaigns: activeCampaigns,
        inactiveCampaigns,
      }
      return alteredState
    },
    setAllCampaigns: (state, { payload }) => {
      const filteredEvent = payload.filter(
        (item) => item.EventType === 'Campaign',
      )
      const activeCampaigns = filteredEvent.filter(
        (item) => item.ActiveInd === 1 && item.Fundraising !== 'Stopped',
      )
      const alteredState = {
        ...state,
        allCampaigns: activeCampaigns,
      }
      return alteredState
    },
    setEventStatus: (state, { payload }) => {
      const alteredState = {
        ...state,
        eventDetail: { ...state.eventDetail, status: payload },
      }
      return alteredState
    },
    setAuctionStatus: (state, { payload }) => {
      const alteredState = {
        ...state,
        eventDetail: { ...state.eventDetail, auctionStatus: payload },
      }
      return alteredState
    },
    setIsAuction: (state, { payload }) => {
      const alteredState = {
        ...state,
        eventDetail: { ...state.eventDetail, isAuction: payload },
      }
      return alteredState
    },
    setEventDonations: (state, { payload }) => {
      const alteredState = {
        ...state,
        eventDetail: { ...state.eventDetail, donations: payload },
      }
      return alteredState
    },
    setPendingCash: (state, { payload }) => {
      const alteredState = {
        ...state,
        eventDetail: { ...state.eventDetail, pendingCash: payload },
      }
      return alteredState
    },
    setSeedDonations: (state, { payload }) => {
      const alteredState = {
        ...state,
        eventDetail: { ...state.eventDetail, seedDonations: payload },
      }
      return alteredState
    },
    setEventTableTask: (state, { payload }) => {
      const alteredState = {
        ...state,
        eventDetail: { ...state.eventDetail, tableTasks: payload },
      }
      return alteredState
    },
    setJoinedEventDetails: (state, { payload }) => {
      const alteredState = {
        ...state,
        joinedEventDetails: payload,
      }
      return alteredState
    },
    setSubscriberStripeInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        subscriberPaymentInfo: {
          ...state.subscriberPaymentInfo,
          stripe: {
            isConnected: payload.isConnected,
            stripeId: payload.stripeId,
          },
        },
      }
      return alteredState
    },
    setSubscriberPaypalInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        subscriberPaymentInfo: {
          ...state.subscriberPaymentInfo,
          paypal: {
            isConnected: payload.isConnected,
            paypalId: payload.paypalId,
          },
        },
      }
      return alteredState
    },
    setSubscriberMarkupInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        subscriberPaymentInfo: {
          ...state.subscriberPaymentInfo,
          markup: payload,
        },
      }
      return alteredState
    },
    setPaypalBillingAggrementData: (state, { payload }) => {
      const alteredState = {
        ...state,
        paypalBillingAggrementSubmitData: payload,
      }
      return alteredState
    },
    setEventAuctionItems: (state, { payload }) => {
      const alteredState = { ...state, eventAuctionItems: payload }
      return alteredState
    },
    setEventSoldItems: (state, { payload }) => {
      const alteredState = { ...state, eventSoldItems: payload }
      return alteredState
    },
    reset: () => initialState,
  },
})

export const useEventSelector = () => useSelector((state) => state.event)
export const eventActions = eventSlice.actions
export const eventReducer = eventSlice.reducer

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Card, Button, Skeleton, Divider } from 'antd'
import { ArrowRightOutlined, CarryOutFilled } from '@ant-design/icons'
import { IoMdArrowRoundBack } from 'react-icons/io'
import Api from 'api'
import { ShareReport } from 'components/elements'
import PerformanceSummary from './PerformanceSummary'
import PerformanceDetails from './PerformanceDetails'
import EventStatisticsReport from './EventStatisticsReport'
import PledgeCommittments from './PledgeCommittments'
import DonorContributionsReport from './DonorContributionsReport'
import DonationStatistics from './DonationStatistics'

const EventReports = () => {
  const [loading, setLoading] = useState()
  const [events, setEvents] = useState()
  const [selected, setSelected] = useState()
  const [pdfData, setPdfData] = useState()
  const [action, setAction] = useState()
  //  inception is var used to tell if report is grandchild
  const [inception, setInception] = useState(false)

  useEffect(() => {
    setLoading(true)
    Api.get('adminReports/getEventsBySubscriber')
      .then((res) => {
        setEvents(res.results)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
  }, [])

  const data = [
    'Performance Summary',
    'Performance Details',
    'Event Statistics Report',
    'Pledge Committments',
    'Donor Contributions Report',
    'Donation Statistics',
  ]

  const myEvents = events?.filter((item) => item.EventType === 'Fundraiser')
  const myCampaigns = events?.filter((item) => item.EventType === 'Campaign')

  return (
    <Card
      id="LastYearEarningsReport"
      className="mb-24 header-solid list-settings-sessions"
      bordered={false}
      title={[
        <React.Fragment key={0}>
          {selected === undefined ? (
            <h5 className="font-semibold">My Events/Campaigns</h5>
          ) : (
            <div className="t-flex t-justify-between t-items-center">
              <div className="t-flex t-space-x-4">
                {!inception && (
                  <>
                    <IoMdArrowRoundBack
                      fontSize="1.5rem"
                      className="t-cursor-pointer t-min-w-[1.5rem]"
                      id="back"
                      onClick={() => {
                        setPdfData()
                        if (action === undefined) {
                          setSelected()
                        } else {
                          setAction()
                        }
                      }}
                    />
                    <h5 className="font-semibold">
                      <span className="t-capitalize t-whitespace-normal">
                        {action === undefined
                          ? selected?.name
                          : `${selected?.name} - ${data[action]}`}
                      </span>
                    </h5>
                  </>
                )}
              </div>
              {pdfData != null && !inception && (
                <ShareReport
                  reportName={`Event - ${data[action]}`}
                  isSummary={
                    data[action] !== 'Pledge Committments' &&
                    data[action] !== 'Donor Contributions Report'
                  }
                  report={pdfData}
                />
              )}
            </div>
          )}
        </React.Fragment>,
      ]}
    >
      {selected === undefined ? (
        <Skeleton loading={loading} paragraph={{ rows: 12 }}>
          <h1 className="font-semibold t-my-3">My Events</h1>
          {myEvents &&
            myEvents.length > 0 &&
            myEvents.map((item, index) => (
              <>
                {index !== 0 && <hr className="gradient-line my-10" />}
                <div className="ant-row-flex ant-row-flex-middle">
                  <div
                    className="text-center ant-col"
                    style={{ minWidth: '40px' }}
                  >
                    <CarryOutFilled
                      className="text-gray-6"
                      style={{ fontSize: '18px' }}
                    />
                  </div>
                  <div className="ant-col pl-15">
                    <p className="mb-0 t-capitalize">{item.Title}</p>
                  </div>
                  <div
                    className="ml-auto ant-col ant-col-24 ant-col-md-12"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      type="link"
                      id="back"
                      onClick={() =>
                        setSelected({
                          id: item.Id,
                          name: item.Title,
                          type: 'Event',
                        })
                      }
                    >
                      See More <ArrowRightOutlined />
                    </Button>
                  </div>
                </div>
              </>
            ))}
          <Divider />
          <h1 className="font-semibold t-my-3">My Campaigns</h1>
          {myCampaigns &&
            myCampaigns.length > 0 &&
            myCampaigns.map((item, index) => (
              <>
                {index !== 0 && <hr className="gradient-line my-10" />}
                <div className="ant-row-flex ant-row-flex-middle">
                  <div
                    className="text-center ant-col"
                    style={{ minWidth: '40px' }}
                  >
                    <CarryOutFilled
                      className="text-gray-6"
                      style={{ fontSize: '18px' }}
                    />
                  </div>
                  <div className="ant-col pl-15">
                    <p className="mb-0 t-capitalize">{item.Title}</p>
                  </div>
                  <div
                    className="ml-auto ant-col ant-col-24 ant-col-md-12"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      type="link"
                      onClick={() =>
                        setSelected({
                          id: item.Id,
                          name: item.Title,
                          type: 'Campaign',
                        })
                      }
                    >
                      See More <ArrowRightOutlined />
                    </Button>
                  </div>
                </div>
              </>
            ))}
        </Skeleton>
      ) : (
        <>
          {action !== undefined ? (
            <Single
              id={selected?.id}
              type={selected?.type}
              action={action}
              setPdfData={setPdfData}
              setInception={setInception}
            />
          ) : (
            <>
              {data.map((item, index) => (
                <>
                  {/* As index 3 PledgeCommittments should not be shown when type is campaign as no pledge option in campaign */}
                  {selected?.type === 'Campaign' && index === 3 ? null : (
                    <>
                      {index !== 0 && <hr className="gradient-line my-10" />}
                      <div className="ant-row-flex ant-row-flex-middle">
                        <div
                          className="text-center ant-col"
                          style={{ minWidth: '40px' }}
                        >
                          <CarryOutFilled
                            className="text-gray-6"
                            style={{ fontSize: '18px' }}
                          />
                        </div>
                        <div className="ant-col pl-15">
                          <p className="mb-0 t-capitalize">{item}</p>
                        </div>
                        <div
                          className="ml-auto ant-col ant-col-24 ant-col-md-12"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Button
                            type="link"
                            onClick={() => setAction(index)}
                            id={`event_reports_${index}_see_more`}
                          >
                            See More
                            <ArrowRightOutlined />
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ))}
            </>
          )}
        </>
      )}
    </Card>
  )
}

const Single = ({ id, type, action, setPdfData, setInception }) => {
  const data = [
    <PerformanceSummary id={id} type={type} setPdfData={setPdfData} />,
    <PerformanceDetails id={id} type={type} setPdfData={setPdfData} />,
    <EventStatisticsReport id={id} type={type} setPdfData={setPdfData} />,
    <PledgeCommittments id={id} setPdfData={setPdfData} />,
    <DonorContributionsReport
      id={id}
      setPdfData={setPdfData}
      setInception={setInception}
    />,
    <DonationStatistics id={id} type={type} setPdfData={setPdfData} />,
  ]
  return <>{data[action]}</>
}

export { EventReports }

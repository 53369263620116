import React, { useEffect, useState } from 'react'
import { Card, Input, Skeleton, Table, Tooltip, message } from 'antd'
import { MdOutlineSimCardDownload } from 'react-icons/md'
import { SearchOutlined } from '@ant-design/icons'
import { downloadCSV, formatAmount, formateDateTimeToLocal } from 'utils'
import { makeRequest } from 'api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { useAuthSelector } from 'store/authSlice/authReducer'

const Platform = () => {
  const history = useHistory()
  const { organization } = useAuthSelector()
  const [loading, setLoading] = useState(true)
  const [searchText, setSearchText] = useState(null)
  const [data, setData] = useState([])
  const [csvData, setCsvData] = useState([])

  const getData = () => {
    makeRequest(
      'get',
      '/reports/platform-subscription-charges',
      {
        organization: organization?.id,
      },
      { data },
    )
      .then((res) => {
        setData(res.results)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        message.error(error?.exceptionMessage)
        setData([])
        setLoading(false)
      })
  }

  useEffect(() => {
    const csvData1 = data?.map((item) => ({
      localPaymentDate: formateDateTimeToLocal(item.PaymentDate, 'date'),
      localPaymentTime: formateDateTimeToLocal(item.PaymentDate, 'time'),
      SubscriptionFee: item.SubscriptionFee || 0,
      KioskCharges: item.KioskCharges || 0,
      UsageCharges: item.UsageCharges || 0,
      Discount: item.Discount || 0,
      TotalAmount: item.TotalAmount || 0,
    }))
    setCsvData(csvData1)
  }, [data])

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [organization?.id])

  const csvColumns = [
    'localPaymentDate',
    'localPaymentTime',
    'SubscriptionFee',
    'KioskCharges',
    'UsageCharges',
    'Discount',
    'TotalAmount',
  ]
  const csvHeaderMapping = {
    localPaymentDate: 'Date',
    localPaymentTime: 'Time',
    SubscriptionFee: 'Access',
    KioskCharges: 'Equipment',
    UsageCharges: 'Communications',
    Discount: 'Discount',
    TotalAmount: 'Paid',
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'PaymentDate',
      className: 'ant-table-row-cell-break-word',
      render: (time) => formateDateTimeToLocal(time, 'datetime'),
      sorter: (a, b) => {
        const dateA = new Date(a.PaymentDate)
        const dateB = new Date(b.PaymentDate)
        return dateB - dateA
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Access',
      dataIndex: 'SubscriptionFee',
      className: 'ant-table-row-cell-break-word',
      render: (amount) => formatAmount(amount),
      sorter: (a, b) => {
        const dateA = new Date(a.SubscriptionFee)
        const dateB = new Date(b.SubscriptionFee)
        return dateB - dateA
      },
      align: 'center',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Equipment',
      dataIndex: 'KioskCharges',
      className: 'ant-table-row-cell-break-word',
      render: (amount) => formatAmount(amount),
      sorter: (a, b) => {
        const dateA = new Date(a.KioskCharges)
        const dateB = new Date(b.KioskCharges)
        return dateB - dateA
      },
      align: 'center',
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Communication ',
      dataIndex: 'UsageCharges',
      className: 'ant-table-row-cell-break-word text-right',
      render: (amount) => formatAmount(amount),
      align: 'center',
      sorter: (a, b) => {
        const dateA = new Date(a.UsageCharges)
        const dateB = new Date(b.UsageCharges)
        return dateB - dateA
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Discount',
      dataIndex: 'GrossAmount',
      className: 'ant-table-row-cell-break-word text-right',
      render: (amount) => formatAmount(amount),
      align: 'center',
      sorter: (a, b) => {
        const dateA = new Date(a.LastDonationDates)
        const dateB = new Date(b.LastDonationDates)
        return dateB - dateA
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Paid',
      dataIndex: 'TotalAmount',
      className: 'ant-table-row-cell-break-word',
      render: (amount) => formatAmount(amount),
      align: 'center',
    },
  ]

  const searchFYP = (rows, searchText1) => {
    if (searchText1) {
      return rows?.filter(
        ({
          SubscriptionFee,
          TotalAmount,
          UsageCharges,
          KioskCharges,
          Discount,
        }) => {
          const searchLower = searchText1.toLowerCase()
          return (
            SubscriptionFee?.toString().includes(searchLower) ||
            TotalAmount?.toString().includes(searchLower) ||
            UsageCharges?.toString().includes(searchLower) ||
            KioskCharges?.toString().includes(searchLower) ||
            Discount?.toString().includes(searchLower)
          )
        },
      )
    }
    return rows
  }

  useEffect(() => {
    setSearchText('')
    // eslint-disable-next-line
  }, [])

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={
        <div className="t-flex t-flex-wrap t-justify-between">
          <div className="t-flex t-space-x-4">
            <IoMdArrowRoundBack
              fontSize="1.5rem"
              className="t-cursor-pointer t-min-w-[1.5rem]"
              id="back"
              onClick={() => history.push('/reports')}
            />
            <h5 className="font-semibold">Platform Charges</h5>
          </div>
          <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
            <div className="header-control t-mt-0">
              <Input
                className="header-search t-w-36"
                placeholder="Search ..."
                prefix={<SearchOutlined />}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
            </div>
            <Tooltip title="Download">
              <MdOutlineSimCardDownload
                fontSize="1.7rem"
                id="download"
                className="t-text-secondary-100 t-cursor-pointer"
                onClick={() =>
                  downloadCSV(
                    csvData,
                    csvColumns,
                    csvHeaderMapping,
                    'Platform Charges Report',
                  )
                }
              />
            </Tooltip>
          </div>
        </div>
      }
    >
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <Table
          tableLayout="auto"
          scroll={{ x: 500 }}
          columns={columns}
          dataSource={searchFYP(data, searchText) || []}
          bordered={false}
          pagination={false}
          className="td-right"
        />
      </Skeleton>
    </Card>
  )
}

export { Platform }

import { Card, message, Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Api from 'api'
import { config } from 'configs'

const Request = () => {
  const { id, status } = useParams()
  const [loaded, setLoaded] = useState(true)
  const [success, setSuccess] = useState(true)

  useEffect(() => {
    setLoaded(true)
    if (id && status) {
      if (status === 'accepted') {
        Api.get(`/publicEvents/accept-presenter?Id=${id}`)
          .then(() => {
            message.success('Invite Accepted Successfully')
            setLoaded(false)
            setSuccess(true)
          })
          .catch((err) => {
            message.error(
              err?.response?.data?.error || config.defaultErrorMessage,
            )
            setLoaded(false)
            setSuccess(false)
          })
      } else if (status === 'rejected') {
        Api.get(`/publicEvents/reject-presenter?Id=${id}`)
          .then(() => {
            message.success('Invite Rejected Successfully')
            setLoaded(false)
            setSuccess(true)
          })
          .catch((err) => {
            message.error(
              err?.response?.data?.error || config.defaultErrorMessage,
            )
            setLoaded(false)
            setSuccess(false)
          })
      } else {
        message.error('Link is not valid')
        setLoaded(false)
        setSuccess(false)
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="t-p-6">
      <Skeleton loading={loaded} active paragraph={{ rows: 18 }}>
        <Card
          className="header-solid t-p-2 t-m-4"
          bordered={false}
          title={[
            <>
              <h6 className="mb-0">
                {success ? (
                  <div>
                    You have successfully {status} the invitation.{' '}
                    <a
                      href={`${window.location.origin}/events/${id}`}
                      className="t-font-bold t-text-secondary-100"
                      rel="noreferrer"
                    >
                      Click here
                    </a>{' '}
                    to go to the event
                  </div>
                ) : (
                  'Something went Wrong'
                )}
              </h6>
            </>,
          ]}
        />
      </Skeleton>
    </div>
  )
}

export { Request }

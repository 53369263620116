import { REGEXS } from 'utils'
import * as Yup from 'yup'

const amountValidation = REGEXS.amountRegex
const phoneNumberValidation = '^[0-9-() ]{14,15}$'

const schema = Yup.object().shape({
  SSN: Yup.string()
    .required('SSN is required')
    .length(4, 'SSN must be exactly 4 digits'),
  PrimaryResidence: Yup.string().required('Primary residence is required'),
  Age: Yup.number()
    .typeError('Age must be a number')
    .required('Age is required')
    .min(18, 'Age must be greater than or equal to 18'),
  MaritalStatus: Yup.string().required('Marital status is required'),
  HaveChildren: Yup.string().required('Have children is required'),
  NumberOfChildren: Yup.number()
    .typeError('Number of children must be a number')
    .required('Number of children is required')
    .min(0, 'Number of children must be greater than or equal to zero'),
  EmploymentStatus: Yup.string().required('Employment status is required'),
  JobTitle: Yup.string().required('Job title is required'),
  EmployerName: Yup.string().required('Employer name is required'),
  EmployerAddress: Yup.string().required('Employment address is required'),
  EmployerPhoneNumber: Yup.string()
    .matches(phoneNumberValidation, 'Invalid Phone number e.g 1234567891')
    .required('Employment phone number is required'),
  AnnualHouseholdIncome: Yup.string()
    .required('Annual household income is required')
    .matches(amountValidation, 'Invalid amount'),
  HealthInsuranceInformation: Yup.string().required(
    'Health insurance informationis required',
  ),
  HighestLevelOfEducationCompleted: Yup.string().required(
    'Highest level of education completed is required',
  ),
  CurrentTransportationMeans: Yup.string().required(
    'Current transportation means completed is required',
  ),
  MonthlyHouseholdExpenseDetails: Yup.string().required(
    'Monthly household expense details is required',
  ),
  AmountReceivedInLast12Months: Yup.string()
    .required('Amount received is required')
    .matches(amountValidation, 'Invalid amount'),
  AidCurrentlyReceived: Yup.string().required(
    'Aid currently received is required',
  ),
  JustificationForZakaatFunds: Yup.string().required(
    'Justification for zakaat funds is required',
  ),
})

const zakaatValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { zakaatValidation }

import * as Yup from 'yup'

const amountValidation = new RegExp('[0-9]|,')

const schema = Yup.object().shape({
  Title: Yup.string()
    .required('Title is required')
    .max(25, 'Title should less than 25 characters'),
  Description: Yup.string()
    .required('Description is required')
    .min(10, 'Description should be at least 10 characters')
    .max(500, 'Description should less than 500 characters'),
  Deliverable: Yup.string()
    .required('Deliverable is required')
    .min(10, 'Deliverable should be at least 10 characters')
    .max(100, 'Limited to max 100 characters'),
  Price: Yup.string()
    .required('Price is required')
    .matches(amountValidation, 'Invalid cost'),
  Details: Yup.string()
    .required('Details is required')
    .min(10, 'Details should be at least 10 characters'),
})

const businessServiceValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { businessServiceValidation }

/* eslint-disable */
import Api from 'api'
import { message } from 'antd'
import { awsconfig } from 'configs'

const subscribe = async () => {
  try {
    // Creating a new service worker.
    const swReg = await navigator.serviceWorker.register('./serviceWorker.js', {
      scope: '/',
    })

    // If notification have not been granted, then let's ask the user
    if (Notification.permission !== 'granted') {
      // This ask the user for permission to send notifications.
      try {
        const subscription = await swReg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(awsconfig.public_vapid_key),
        })

        Api.post('/subscriptions/saveSubscription', {
          Subscription: subscription,
        })
          .then((res) => {
            message.success(res.message || 'Successfully updated')
          })
          .catch((err) => {
            console.log('Error', err)
            message.error('Permision updating failed')
          })
      } catch (error) {
        console.log('error', error)
        console.log('Permision Denied')
      }
    }
  } catch (error) {
    throw new Error(error)
  }
}

function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export { subscribe }

import React from 'react'
import { config } from 'configs'
import { formatAmount, getEventImageUrl } from 'utils'
// import ReactPlayer from 'react-player'
import { Card, Progress } from 'antd'

const EventInformation = ({ event }) => (
  <Card
    className="header-solid t-p-1 md:t-p-2 t-m-4 t-shadow-2xl t-rounded-lg"
    bordered={false}
  >
    <div className="t-flex t-flex-col md:t-flex-row md:t-justify-center-center md:t-space-x-8">
      <div className="t-48 md:t-h-56 md:t-max-w-[80%] t-flex t-justify-center">
        <img
          src={getEventImageUrl(
            event?.Flyer,
            event?.organization?.BackgroundImage,
          )}
          alt="card"
          className="shadow-3 t-rounded-md t-h-full t-bg-cover t-bg-no-repeat"
          // style={{ objectFit: 'cover' }}
        />
      </div>
      {/* <ReactPlayer
        controls
        width="100%"
        url={event?.YoutubeLink}
        // className="t-rounded-md t-h-44 md:t-h-60 md:t-w-[70%]"
        className="t-rounded-md t-h-44 md:t-w-[80%]"
      /> */}

      <div className="t-flex t-flex-col t-space-y-6">
        <div className="t-pt-4 md:t-pt-0">
          <h1 className="t-text-secondary-100 t-font-bold t-text-2xl">
            {event?.OrganizationName}
          </h1>
          <h1 className="t-text-secondary-100 t-font-bold t-text-lg">
            {event?.Title}
          </h1>
        </div>

        {(event?.ShowDonatedAmountToDonor === undefined ||
          event?.ShowProgressBarToDonor === undefined ||
          event?.ShowDonatedAmountToDonor ||
          event?.ShowProgressBarToDonor) && (
          <div className="t-flex t-flex-col t-w-full">
            {(event?.ShowDonatedAmountToDonor === undefined ||
              event?.ShowDonatedAmountToDonor) && (
              <>
                <div>
                  <h6 className="t-text-sm md:t-text-md t-font-bold t-text-secondary-100">
                    {`${formatAmount(event?.totalRaised)} raised`}
                  </h6>
                </div>
                <div>
                  <h6 className="t-text-sm md:t-text-md t-font-bold t-text-green-500">
                    {`${formatAmount(event?.TargetAmount)} goal`}
                  </h6>
                </div>
              </>
            )}
            {(event?.ShowProgressBarToDonor === undefined ||
              event?.ShowProgressBarToDonor) && (
              <div className="t-w-full">
                <Progress
                  percent={
                    Number(
                      (event?.totalRaised * 100) / event?.TargetAmount,
                    ).toFixed(0) || 0
                  }
                  strokeColor={config.colors.primary}
                />
              </div>
            )}
          </div>
        )}

        <div>
          <h1 className="t-text-xs t-text-gray-500">{event?.Details}</h1>
        </div>
      </div>
    </div>
  </Card>
)

export { EventInformation }

import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: false,
  clearLoading: false,
  notifications: [],
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = { ...state, loading: payload }
      return alteredState
    },
    setClearLoading: (state, { payload }) => {
      const alteredState = { ...state, clearLoading: payload }
      return alteredState
    },
    setNotifications: (state, { payload }) => {
      const alteredState = {
        ...state,
        notifications: payload,
      }
      return alteredState
    },
    reset: () => initialState,
  },
})

export const useNotificationSelector = () =>
  useSelector((state) => state.notification)
export const notificationActions = notificationSlice.actions
export const notificationReducer = notificationSlice.reducer

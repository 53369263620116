import React, { useEffect } from 'react'
import { Button, Card, Col, Row, Skeleton, Avatar, Tooltip, Tag } from 'antd'
import { AreaChartOutlined, HomeOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { getOrganizationRooms } from 'store/roomSlice/roomActions'
import { useRoomSelector } from 'store/roomSlice/roomReducer'
import { formatAmount } from 'utils'
import { useHistory } from 'react-router-dom'
import { config } from 'configs'

const MyRentals = ({ id, urlData }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { loading, rooms } = useRoomSelector()

  useEffect(() => {
    dispatch(getOrganizationRooms(id))
    // eslint-disable-next-line
  }, [])

  const onClickCard = (roomId) => {
    history.push({
      pathname: `/rentals/${roomId}`,
      state: { urlData },
    })
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <Row gutter={[24, 24]}>
        {rooms.length === 0 && (
          <div className="t-h-96 t-flex t-justify-center t-items-center t-w-full">
            <div className="t-flex t-flex-col t-items-center t-space-y-2">
              <AreaChartOutlined
                style={{ fontSize: '2rem' }}
                className="t-text-secondary-100"
              />
              <p>No records found</p>
            </div>
          </div>
        )}
        {rooms.map((item, index) => (
          <Col span={24} lg={8} className="mb-24" key={index}>
            <Card
              bodyStyle={{ paddingTop: '14px' }}
              bordered={false}
              onClick={() => onClickCard(item?.Id)}
              className="card-project-2 header-solid t-cursor-pointer"
              title={[
                <Row gutter={[24, 24]} key={0}>
                  <Col>
                    <Avatar
                      src={<HomeOutlined />}
                      style={{
                        width: '25px',
                        height: '25px',
                        lineHeight: '74px',
                        borderRadius: '8px',
                        padding: '16px',
                        backgroundColor: config.colors.primary,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    />
                  </Col>
                  <Col>
                    <h6 className="font-semibold t-w-64 t-truncate">
                      {`${item?.Name} (${item?.Breadth}×${item?.Width})`}
                    </h6>
                    <div className="t-flex t-space-x-2">
                      <div className="card-tag t-font-thin t-text-secondary-100 t-text-sm">
                        <Tooltip title="Location Type">
                          {item?.OrganizationName}
                        </Tooltip>
                      </div>
                    </div>
                  </Col>
                </Row>,
              ]}
            >
              <p className="t-truncate">{item?.Instructions}</p>
              <hr />
              <div className="t-flex t-justify-between t-items-center t-mt-2">
                <Tag color="green">{`Room capacity is ${item?.Capacity}`}</Tag>
                <Button type="primary" size="small" className="t-rounded-full">
                  {`${formatAmount(item?.PerHourRate)}/hr`}
                </Button>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </Skeleton>
  )
}

export { MyRentals }

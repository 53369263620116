import React, { useState } from 'react'
import { Form, Row, Col, Card, message, Button } from 'antd'
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { awsconfig, config } from 'configs'
import { loadStripe } from '@stripe/stripe-js'
import { useAuthSelector } from 'store/authSlice/authReducer'
import {
  getOrganizatinPlatformSubscription,
  updatePlaformSubscriptionCard,
} from 'store/platformSubscriptionSlice/platformSubscriptionActions'
import { useDispatch } from 'react-redux'

const initialValues = {
  card: 'no-card-selected',
}

const WrappedSubscriptionForm = ({ organizationId, onCancel }) => {
  const dispatch = useDispatch()
  const { user, organization } = useAuthSelector()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  Form.useWatch('card', form)

  /* eslint-disable consistent-return */
  const onFinish = async () => {
    if (!user && !organization) {
      message.error('User or organization not found')
      return false
    }
    let cardToken = null
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    })
    if (error) {
      message.error(error?.message || 'Invalid Card Information')
      return false
    }
    cardToken = paymentMethod.id
    setLoading(true)
    try {
      const response = await updatePlaformSubscriptionCard(
        organizationId,
        cardToken,
      )
      message.success(
        response?.message || 'Successfully updated card information',
      )
      dispatch(
        getOrganizatinPlatformSubscription({
          organizationId: organization?.id,
        }),
      )
      onCancel()
    } catch (error1) {
      message.error(error1?.response?.data?.error || config.defaultErrorMessage)
    }
    setLoading(false)
  }

  return (
    <Card className="header-solid" bordered={false}>
      <Form
        form={form}
        layout="vertical"
        key={0}
        onFinish={onFinish}
        name="donation"
        scrollToFirstError
        initialValues={initialValues}
      >
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} xl={24}>
            <>
              <h1 className="t-text-sm t-my-2 t-font-semibold">
                Enter New Card Number
              </h1>
              <div className="t-p-2 t-rounded-md t-border t-border-grey-900'">
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: '15px',
                        fontSmoothing: 'antialiased',
                      },
                    },
                  }}
                />
              </div>
            </>
            <Col span={24} className="text-right t-p-0 t-my-2 t-space-x-2">
              <Button type="primary" onClick={() => onCancel()}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={!stripe || !elements}
              >
                Update Card
              </Button>
            </Col>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

function StripeProvider({ organizationId, onCancel }) {
  const stripePromise = loadStripe(awsconfig.stripe_publish_key)

  return (
    <Elements stripe={stripePromise}>
      <WrappedSubscriptionForm
        organizationId={organizationId}
        onCancel={onCancel}
      />
    </Elements>
  )
}
const ChangeCreditCard = StripeProvider
export { ChangeCreditCard }

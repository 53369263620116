import { message } from 'antd'
import Api, { makeRequest } from 'api'
import moment from 'moment'
import { uploadFile } from 'store/authSlice/authActions'
import {
  formatUSAPhoneNumber,
  numberWithCommas,
  sortByDate,
  sortByZipcode,
  sortScheduleByDays,
} from 'utils'
import { config } from 'configs'
import { programActions } from './programReducer'

export const addProgram = (payload, actions) => async (dispatch) => {
  dispatch(programActions.setLoading(true))
  let updatedData = payload
  const flyerFile = await uploadFile(updatedData.Flyer)
  updatedData = {
    ...updatedData,
    Flyer: {
      fileName: flyerFile.fileName,
      fileMimeType: flyerFile.fileMimeType,
    },
  }

  makeRequest(
    'post',
    `/component/program/`,
    {
      organization: payload?.OrganizationId,
    },
    updatedData,
  )
    .then((res) => {
      message.success(res.message || 'Program Added Successfully')
      dispatch(programActions.setLoading(false))
      if (actions) {
        actions()
      }
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(programActions.setLoading(false))
    })
}

export const updateProgram = (payload, actions) => async (dispatch) => {
  let updatedData = payload

  if (payload?.Flyer instanceof File) {
    const flyerFile = await uploadFile(updatedData.Flyer)
    updatedData = {
      ...updatedData,
      Flyer: {
        fileName: flyerFile.fileName,
        fileMimeType: flyerFile.fileMimeType,
      },
    }
  }

  const programType = payload?.ProgramType
  let url
  if (programType === 'Class') {
    url = '/component/program/class'
  } else {
    url = '/component/program/custom'
  }
  dispatch(programActions.setLoading(true))
  makeRequest(
    'put',
    `${url}`,
    {
      organization: payload?.OrganizationId,
    },
    updatedData,
  )
    .then((res) => {
      dispatch(programActions.setLoading(false))
      message.success(res.message || 'Program Updated Successfully')
      if (actions) {
        actions()
      }
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(programActions.setLoading(false))
    })
}

export const updateProgramStatus = (id, status, actions) => (dispatch) => {
  dispatch(programActions.setLoading(true))
  let value = 1
  if (status === 1) {
    value = 0
  }
  makeRequest('patch', `/component/program/${id}/status/${value}`)
    .then(() => {
      message.success(
        value === 0
          ? 'Program Deactivated Successfully'
          : 'Program Activated Successfully',
      )
      dispatch(programActions.setLoading(false))
      if (actions) {
        actions()
      }
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(programActions.setLoading(false))
    })
}

export const getProgram = (id, userId) => (dispatch) => {
  dispatch(programActions.setLoading(true))
  makeRequest('get', `/component/program/${id}?user=${userId}`)
    .then((res) => {
      if (res.results) {
        dispatch(programActions.setProgram(res.results))
      }
      dispatch(programActions.setLoading(false))
    })
    .catch((error) => {
      dispatch(programActions.setProgram(null))
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(programActions.setLoading(false))
    })
}

export const getProgramDetail =
  (id, form, setSchedule, actions) => (dispatch) => {
    dispatch(programActions.setLoading(true))
    makeRequest('get', `/component/program/${id}`)
      .then((res) => {
        if (res.results) {
          const program = res.results
          if (form) {
            form.setFieldsValue({
              Title: program.Title,
              ProgramType: program?.ProgramType,
              DeliveryLocation: program.DeliveryLocation,
              ProgramFees: program.ProgramFees,
              Description: program.Description,
              MaximumNumberOfRegistrations: numberWithCommas(
                program.MaximumNumberOfRegistrations,
              ),
              ShowOnLandingPage: program.ShowOnLandingPage
                ? program.ShowOnLandingPage
                : false,
              Flyer: program?.Flyer,
              AllowToEditFee: program?.AllowToEditFee ?? false,
              AllowExtraPayment: program?.AllowExtraPayment ?? false,
              IncludeProcessingFee: program?.IncludeProcessingFee ?? false,
              AllowSplitPayments: program?.AllowSplitPayments ?? false,
              NumberOfDefaultSplit: program?.NumberOfDefaultSplit ?? '',
              PaymentCategoryAdded: program?.PaymentCategoryAdded ?? false,
              PaymentCategories: program?.PaymentCategories
                ? program?.PaymentCategories
                : [],
              OrganizationProfile:
                program.OrganizationProfile && program.OrganizationProfile,
              Requirements: program.Requirements ?? '',
            })
            if (program?.DeliveryLocation === 'Hybrid') {
              form.setFieldsValue({
                mailingAddress: program?.Address,
                suite: program?.Suite,
                zipCode: program?.ZipCode,
                city: program?.City,
                state: program?.State,
                country: program?.Country,
              })
            }
            if (program?.ProgramType === 'Class') {
              if (setSchedule) {
                form.setFieldsValue({
                  AddAgeRange: program?.AddAgeRange,
                  MaximumAge: program?.MaximumAge,
                  MinimumAge: program?.MinimumAge,
                  StartDate: moment(program.StartDate),
                  EndDate: moment(program.EndDate),
                  FemaleAllowed: program?.FemaleAllowed,
                  MaleAllowed: program?.MaleAllowed,
                  AddTeacher: program?.AddTeacher,
                  TeacherFirstName: program?.TeacherFirstName,
                  TeacherLastName: program?.TeacherLastName,
                  TeacherPhoneCode: program?.TeacherPhoneCode,
                  TeacherMobileNumber:
                    program?.TeacherMobileNumber &&
                    formatUSAPhoneNumber(program?.TeacherMobileNumber),
                  TeacherEmail: program?.TeacherEmail,
                })
                const updatedSchedule = sortScheduleByDays(program.Schedule)
                setSchedule(updatedSchedule)
              }
            }
          }
          if (actions) actions(program?.ProgramType)
        }
        dispatch(programActions.setLoading(false))
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
        dispatch(programActions.setLoading(false))
      })
  }

export const getAllPrograms = (loadingOff, zipCode) => (dispatch) => {
  Api.get('/component/program/?type=all')
    .then((res) => {
      const sortedData = sortByZipcode(res?.results, 'OrgZipCode', zipCode)
      dispatch(programActions.setAllPrograms(sortedData))
      loadingOff()
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(programActions.setAllPrograms([]))
      loadingOff()
    })
}

export const getNearByPrograms = (positionData) => (dispatch) => {
  dispatch(programActions.setLoading(true))
  Api.get(
    `/component/program/?type=nearby&zipCode=${positionData?.zipCode}&lat=${positionData?.lat}&lon=${positionData?.lon}`,
  )
    .then((res) => {
      const sortedData = sortByDate(res?.results, 'CreatedDate')
      dispatch(programActions.setPrograms(sortedData))
      dispatch(programActions.setLoading(false))
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(programActions.setPrograms([]))
      dispatch(programActions.setLoading(false))
    })
}

export const getOrganizationPrograms = (id) => (dispatch) => {
  dispatch(programActions.setLoading(true))
  Api.get(`/component/program/?type=organization&organizationId=${id}`)
    .then((res) => {
      const sortedData = sortByDate(res?.results, 'CreatedDate')
      dispatch(programActions.setOrganizationPrograms(sortedData))
      dispatch(programActions.setLoading(false))
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(programActions.setOrganizationPrograms([]))
      dispatch(programActions.setLoading(false))
    })
}

export const registerForProgram = (payload, setLoading, actions) => () => {
  setLoading(true)
  Api.post('/component/program/register-with-stripe', payload)
    .then(() => {
      setLoading(false)
      if (actions) {
        actions()
      }
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || 'An Error Occured')
      setLoading(false)
    })
}

export const registerWithPaypalForProgram =
  (payload, setLoading, actions) => () => {
    setLoading(true)
    Api.post('/component/program/register-with-paypal', payload)
      .then(() => {
        setLoading(false)
        if (actions) {
          actions()
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.error || 'An Error Occured')
        setLoading(false)
      })
  }

export const getRegisterUsers = (id, setLoading) => (dispatch) => {
  setLoading(true)
  makeRequest('get', `/component/program/registered-users/${id}`)
    .then((res) => {
      if (res.results) {
        dispatch(programActions.setRegisteredUsers(res.results))
      } else {
        dispatch(programActions.setRegisteredUsers([]))
      }
      setLoading(false)
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(programActions.setRegisteredUsers([]))
      setLoading(false)
    })
}

// Disabaling this api as we have to decide which program will allow multiple registration and which will not
export const isUserAlreadyRegisteredInProgram = () => false
// export const isUserAlreadyRegisteredInProgram = async (id, email) => {
//   const response = await Api.get(`programServices/registeredList/${id}`)
//   const isExist = response.results.find((item) => item.DonorEmail === email)
//   return isExist
// }

export const getSubscriberPaymentInformation =
  (organizationId) => (dispatch) => {
    dispatch(programActions.setLoading(true))
    Api.get(`/event/account-info?organizationId=${organizationId}`)
      .then((paymentInformation) => {
        if (paymentInformation) {
          dispatch(
            programActions.setSubscriberStripeInfo({
              isConnected:
                paymentInformation.defaultCategory?.stripeID &&
                paymentInformation.defaultCategory?.stripeOnBoarded,
              stripeId: paymentInformation.defaultCategory?.stripeID,
            }),
          )

          dispatch(
            programActions.setSubscriberPaypalInfo({
              isConnected: !!paymentInformation.defaultCategory?.paypalID,
              paypalId: paymentInformation.defaultCategory?.paypalID,
            }),
          )
        }
        dispatch(programActions.setLoading(false))
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(programActions.setLoading(false))
      })
  }

export const getSubscriberMarkupInfo = (orgId) => (dispatch) => {
  dispatch(programActions.setLoading(true))
  Api.get(`/admin/subsrciberMarkUpsByOrg/${orgId}`)
    .then((res) => {
      dispatch(
        programActions.setSubscriberMarkupInfo(
          Number(res?.results?.StripeMarkup) || 1,
        ),
      )
      dispatch(programActions.setLoading(false))
    })
    .catch(() => {
      dispatch(programActions.setLoading(false))
    })
}

export const getProgramInvites = (email) => (dispatch) => {
  dispatch(programActions.setLoading(true))
  makeRequest('get', `/component/program/teacher-invitations/${email}`)
    .then((res) => {
      if (res.results) {
        dispatch(programActions.setPrograms(res.results))
      } else {
        dispatch(programActions.setPrograms([]))
      }
      dispatch(programActions.setLoading(false))
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(programActions.setPrograms([]))
      dispatch(programActions.setLoading(false))
    })
}

export const updateClassAcceptanceStatus =
  (programId, status, email) => (dispatch) => {
    dispatch(programActions.setLoading(true))
    makeRequest(
      'patch',
      `/component/program/teacher-acceptance/${programId}/${status}`,
    )
      .then(() => {
        message.success(
          `Teaching request ${status?.toLowerCase()} successfully`,
        )
        dispatch(programActions.setLoading(false))
        dispatch(getProgramInvites(email))
      })
      .catch(() => {
        dispatch(programActions.setLoading(false))
      })
  }

import React, { useEffect, useState } from 'react'
import { Avatar, Card, message, Pagination, Skeleton, Tag, Tooltip } from 'antd'
import { awsconfig, config } from 'configs'
import { formatAmount } from 'utils'
import Api from 'api'
import Apiv2 from 'api/apiv2'

const MyBids = ({ onBidClick, type, parentId }) => {
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [auctionItemsList, setAuctionItemsList] = useState()

  const fetchData = () => {
    if (parentId) {
      const data = { id: parentId, type }
      Api.post('auction/getMyEventBids', data)
        .then((res) => {
          const unsoldBids = res?.myBids.filter(
            (item) =>
              !(
                Number(item.currentBid) === Number(item.myBid) &&
                item.auctionEnded === true &&
                item.auctionStatus === 'Sold'
              ),
          )
          setAuctionItemsList(unsoldBids)
          setLoading(false)
        })
        .catch((error) => {
          console.log('error', error)
          message.error(
            error?.response?.data?.message || 'Something went wrong',
          )
          setLoading(false)
        })
    }
  }

  const fetchFundraiserData = () => {
    if (parentId) {
      Apiv2.get(`admin/fundraisers/${parentId}/bids`)
        .then((res) => {
          const unsoldBids = res?.myBids.filter(
            (item) =>
              !(
                Number(item.currentBid) === Number(item.myBid) &&
                item.auctionEnded === true &&
                item.auctionStatus === 'Sold'
              ),
          )
          setAuctionItemsList(unsoldBids)
          setLoading(false)
        })
        .catch((error) => {
          console.log('error', error)
          message.error(
            error?.response?.data?.message || 'Something went wrong',
          )
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    if (type === 'fundraiser') {
      fetchFundraiserData()
    } else {
      fetchData()
    }
    // eslint-disable-next-line
  }, [])

  const bidStatusTag = (item) => {
    const bidStatus =
      Number(item.currentBid) > Number(item.myBid) ? 'Outbid' : 'Winning'
    if (
      bidStatus === 'Winning' &&
      item.auctionEnded === true &&
      item.auctionStatus === 'Sold'
    ) {
      return (
        <Tag color="green" className="font-bold">
          Won
        </Tag>
      )
    }

    if (
      bidStatus === 'Outbid' &&
      item.auctionEnded === true &&
      item.auctionStatus === 'Sold'
    ) {
      return (
        <Tag color="red" className="font-bold">
          Lost
        </Tag>
      )
    }

    if (item.auctionEnded === true && item.auctionStatus === 'Ended') {
      return (
        <Tag color="default" className="font-bold">
          Ended
        </Tag>
      )
    }

    if (bidStatus === 'Outbid') {
      return (
        <div className="t-flex t-items-center t-space-x-1">
          <Tag color="red" className="font-bold">
            Outbid
          </Tag>
          <Tag
            color={config.colors.primary}
            className="font-bold t-cursor-pointer"
            onClick={() => onBidClick(item)}
          >
            Bid Again
          </Tag>
        </div>
      )
    }

    return (
      <Tag color="yellow" className="font-bold">
        Winning
      </Tag>
    )
  }

  const diplayPagination = auctionItemsList?.length > config.rowsPerPage
  return (
    <Card className="t-w-full">
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <div className="t-h-80 t-overflow-scroll md:t-overflow-auto">
          {auctionItemsList && auctionItemsList?.length === 0 && (
            <div className="t-flex t-justify-center t-items-center t-h-full t-w-full">
              No bids found.
            </div>
          )}
          {auctionItemsList &&
            auctionItemsList?.length > 0 &&
            auctionItemsList.map((item, index) => (
              <div
                key={index}
                className="t-w-full t-rounded-2xl t-h-12 t-p-3 t-bg-blue-100 t-text-secondary-100 t-mb-2 t-flex t-items-center t-shadow-md t-justify-between"
              >
                <div className="t-flex t-space-x-2 t-items-center">
                  <Avatar
                    src={
                      item.itemImage?.fileName &&
                      awsconfig.bucketUrl + item.itemImage?.fileName
                    }
                    alt="photo"
                    className="t-w-8 t-h-8 t-rounded-full mr-10"
                    size={48}
                    shape="square"
                  />
                  <div>
                    <h1 className="t-font-bold t-text-xs md:t-text-sm">
                      {item.title}
                    </h1>
                    <h2 className="t-font-bold t-text-gray-600 t-text-right t-text-xs">
                      <Tooltip title="My Bid">
                        {item?.currentBid && formatAmount(item?.myBid)}
                      </Tooltip>
                    </h2>
                  </div>
                </div>
                <div className="t-flex t-flex-col t-justify-center t-items-center">
                  {bidStatusTag(item)}
                </div>
              </div>
            ))}
        </div>
        {diplayPagination && (
          <div className="t-flex t-justify-center t-items-center t-mt-4">
            <Pagination
              showSizeChanger={false}
              defaultPageSize={config.rowsPerPage}
              current={page}
              total={auctionItemsList?.length || 0}
              onChange={(current) => setPage(current)}
            />
          </div>
        )}
      </Skeleton>
    </Card>
  )
}

export { MyBids }

import * as Yup from 'yup'
import { REGEXS } from 'utils'

const zipCodeRegex = REGEXS.zipcodeRegex
const taxIdRegex =
  '^((?!11-1111111)(?!22-2222222)(?!33-3333333)(?!44-4444444)(?!55-5555555)(?!66-6666666)(?!77-7777777)(?!88-8888888)(?!99-9999999)(?!12-3456789)(?!00-[0-9]{7})([0-9]{2}-[0-9]{7}))*$'
const websiteRegex = new RegExp(
  '^(https?:\\/\\/)?' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$',
  'i',
)
const phoneNumberValidation = '^[0-9-() ]{14,15}$'
const amountValidation = REGEXS.amountRegex

const schema = Yup.object().shape({
  Country: Yup.string().required('Country is required'),
  Name: Yup.string().required('Name is required'),
  OrganizationType: Yup.string().required('Organization type is required'),
  NonProfit: Yup.boolean().required('Non profit is required'),
  Designation: Yup.string().required('Designation is required'),
  TaxId: Yup.string()
    .matches(taxIdRegex, 'Invalid TaxId e.g 12-1234567')
    .required('Tax ID is required'),
  Address1: Yup.string(),
  Address2: Yup.string().required('Number and street is required'),
  City: Yup.string().required('City is required'),
  State: Yup.string().required('State is required'),
  ZipCode: Yup.string()
    .matches(zipCodeRegex, 'Invalid Zip code e.g 12345')
    .required('Zip code is required'),
  PhoneNumber: Yup.string()
    .matches(phoneNumberValidation, 'Invalid Phone number e.g 1234567891')
    .required('Phone number is required'),
  OrgDomain: Yup.string().required('OrgDomain is required'),
  Website: Yup.string().matches(websiteRegex, {
    message: 'Invalid website url e.g https://www.google.com',
    excludeEmptyString: true,
  }),
  Description: Yup.string()
    .required('Description is required')
    .min(10, 'Description should be atleast 10 character'),
  IdentityType: Yup.string().required('Identity Type is required'),
  W9Form: Yup.mixed()
    .typeError('Identity File is not a file')
    .required('W9Form is required'),
  FundedOutside: Yup.boolean().required('Foreign funded is required'),
  IdentityFile: Yup.mixed()
    .typeError('Identity File is not a file')
    .required('Identity File is required'),
  recaptcha: Yup.string()
    .typeError('Please complete recaptcha to proceed')
    .required('Please complete recaptcha to proceed'),
  BackgroundImage: Yup.mixed()
    .typeError('Background Image is not a file')
    .required('Background Image is required'),
  Logo: Yup.mixed()
    .typeError('Logo is not a file')
    .required('Logo is required'),
  ShowRentalsPublicly: Yup.boolean().required('Show rental is required'),
  ShowMailingAddress: Yup.boolean().required(
    'Show mailing address is required',
  ),
  DefaultDonationValue1: Yup.string()
    .required('Amount is required')
    .matches(amountValidation, 'Invalid amount'),
  DefaultDonationValue2: Yup.string()
    .required('Amount is required')
    .matches(amountValidation, 'Invalid amount'),
  DefaultDonationValue3: Yup.string()
    .required('Amount is required')
    .matches(amountValidation, 'Invalid amount'),
  DefaultDonationValue4: Yup.string()
    .required('Amount is required')
    .matches(amountValidation, 'Invalid amount'),
  KioskDonationTitle: Yup.string().required('Title is required'),
  KioskAmount1: Yup.string()
    .required('Amount is required')
    .matches(amountValidation, 'Invalid amount'),
  KioskAmount2: Yup.string()
    .required('Amount is required')
    .matches(amountValidation, 'Invalid amount'),
  KioskAmount3: Yup.string()
    .required('Amount is required')
    .matches(amountValidation, 'Invalid amount'),
  PaymentCategoryAdded: Yup.string().required(
    'Payment category added is required',
  ),
})

const organizationValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { organizationValidation }

import React, { useEffect, useState } from 'react'
import {
  Card,
  Row,
  Col,
  Descriptions,
  Button,
  Skeleton,
  Modal,
  Typography,
  Tooltip,
  Spin,
  message,
  Divider,
} from 'antd'
import {
  EditFilled,
  CloseOutlined,
  CheckOutlined,
  UndoOutlined,
} from '@ant-design/icons'
import { getUserOrganization } from 'store/organizationSlice/organizationActions'
import { downloadQRCodePDF, getOrganization } from 'store/authSlice/authActions'
import { useHistory } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useDispatch } from 'react-redux'
import QRCode from 'qrcode.react'
import { awsconfig, config } from 'configs'
import { CopyBlock, dracula } from 'react-code-blocks'
import { AiOutlineCopy } from 'react-icons/ai'
import { getWebAppUrl, htmlCode } from 'utils'
import { MdOutlineSimCardDownload } from 'react-icons/md'
import { ComponentViewHeader } from 'components/common/componentView/components'
import { OrganizationProfiles } from './OrganizationProfiles'

const { Title } = Typography

const renderStatus = (type) => {
  switch (type) {
    case 'Active':
      return (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-success mr-10"
          >
            <CheckOutlined
              className="text-success"
              style={{ fontSize: '10px' }}
            />
          </Button>
          <span style={{ verticalAlign: 'bottom' }}>Active</span>
        </>
      )
    case 'Disabled':
      return (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status t-border-1 t-border-yellow-400 mr-10"
          >
            <CloseOutlined
              className="text-warning"
              style={{ fontSize: '10px' }}
            />
          </Button>
          <span style={{ verticalAlign: 'bottom' }}>Disabled</span>
        </>
      )
    case 'Suspended':
      return (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-danger mr-10"
          >
            <CloseOutlined
              className="text-danger"
              style={{ fontSize: '10px' }}
            />
          </Button>
          <span style={{ verticalAlign: 'bottom' }}>Suspended</span>
        </>
      )
    case 'Pending':
      return (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-muted mr-10"
          >
            <UndoOutlined className="text-muted" style={{ fontSize: '10px' }} />
          </Button>
          <span style={{ verticalAlign: 'bottom' }}>Pending</span>
        </>
      )
    default:
      return (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-success mr-10"
          >
            <CheckOutlined
              className="text-success"
              style={{ fontSize: '10px' }}
            />
          </Button>
          <span style={{ verticalAlign: 'bottom' }}>Active</span>
        </>
      )
  }
}

const MyOrganization = () => {
  const { user } = useAuthSelector()
  const [org, setOrganization] = useState(null)
  const [loading, setLoading] = useState(false)
  const [embedURLModalVisible, setEmbedURLModalVisible] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const [downloadLoading, setDownloadLoading] = useState(false)

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const response = await getUserOrganization(user?.email)
      dispatch(getOrganization(user?.email))
      setOrganization(response)
      setLoading(false)
    }
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const formatPhoneNumber = (Number) => {
    const PhoneNumber = Number.replace(/^0+/, '')
    const firstThree = PhoneNumber.substring(0, 3)
    const secondThree = PhoneNumber.substring(3, 6)
    const lastFour = PhoneNumber.substring(6, PhoneNumber.length)
    const phone = `+1 (${firstThree}) ${secondThree}-${lastFour}`

    return phone
  }

  // const OrgDomain = "https://sherlockholmes.api.dev.extensia.cloud/"

  const OrgDomain = `https://${
    org?.OrgDomain
  }.${awsconfig.API.endpoints[0].api_url?.replace(/^https?:\/\//, '')}`

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    message.success('Copied to Clipboard')
  }

  const type = 'org'
  const onPrintClick = async () => {
    if (org) {
      try {
        setDownloadLoading(true)
        const eventsForPrint = {
          id: org.id,
          name: org.Name,
          description: org.Description,
          url: `${window.location.origin}/donate/${org?.id}`,
        }
        const payload = {
          data: [eventsForPrint],
          component: 'Organization',
          Organization: org.Name,
          logo: awsconfig.bucketUrl + org.Logo?.fileName,
        }
        await downloadQRCodePDF(payload, dispatch)
        message.destroy()
        message.success('QR code generated!')
        window.open(`${window.location.origin}/download/${type}`, '_blank')
        setDownloadLoading(false)
      } catch (error) {
        message.error(error?.response?.data?.message || 'Something went wrong')
        setDownloadLoading(false)
      }
    } else {
      message.error('Data for organization is missing')
      setDownloadLoading(false)
    }
  }

  // Function to determine the URL based on the current origin for Donation
  const getDonationURL = () => {
    const { origin } = window.location.origin

    if (origin === 'https://dev.extensia.cloud') {
      return `https://${org?.OrgDomain}.donate.dev.extensia.cloud`
    }
    if (origin === 'https://qa.extensia.cloud') {
      return `https://${org?.OrgDomain}.donate.qa.extensia.cloud`
    }
    if (origin === 'https://demo.extensia.cloud') {
      return `https://${org?.OrgDomain}.donate.demo.extensia.cloud`
    }
    if (origin === 'https://app.extensia.cloud') {
      return `https://${org?.OrgDomain}.donate.extensia.cloud`
    }
    if (origin === 'http://localhost:3000/') {
      return `https://${org?.OrgDomain}.donate.extensia.cloud`
    }

    // Default fallback
    return `https://${org?.OrgDomain}.donate.extensia.cloud`
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={
        <ComponentViewHeader
          heading="Organization Detail"
          subHeading="View your organization details"
          buttonLabel="Subscription Details"
          buttonLink="/settings/manage-subscription"
        />
      }
    >
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        {!org ? (
          <div className="t-w-full t-h-96 t-flex t-justify-center t-items-center">
            No Organization Added
          </div>
        ) : (
          <Card
            title={[
              <Row
                style={{ display: 'flex', justifyContent: 'space-between' }}
                className="t-space-y-2"
              >
                <Col className="t-space-y-2">
                  <h6 className="mb-0 font-bold">{org?.Name}</h6>
                  <div>{renderStatus(org?.OrgStatus)}</div>
                </Col>
                <div className="t-flex t-space-x-1">
                  <Tooltip title="Generate Qr Code for Org Donation Page">
                    {downloadLoading ? (
                      <Spin />
                    ) : (
                      <MdOutlineSimCardDownload
                        fontSize="1.7rem"
                        className="t-text-secondary-100 t-cursor-pointer"
                        onClick={() => onPrintClick()}
                      />
                    )}
                  </Tooltip>
                  <Tooltip title="Edit">
                    <EditFilled
                      className="t-cursor-pointer t-text-secondary-100"
                      style={{ fontSize: '1.7rem' }}
                      onClick={() =>
                        history.push('/settings/update-organization')
                      }
                    />
                  </Tooltip>
                </div>
              </Row>,
            ]}
            className="t-mb-5"
          >
            <Descriptions>
              <Descriptions.Item label="Type of Organization" span={3}>
                {org?.OrganizationType}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
                {org?.Email}
              </Descriptions.Item>
              <Descriptions.Item label="Phone Number" span={3}>
                {org?.PhoneNumber && formatPhoneNumber(org?.PhoneNumber)}
              </Descriptions.Item>
              {org?.Address1 && (
                <Descriptions.Item label="Street Address" span={3}>
                  {org?.Address1}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="City" span={3}>
                {org?.City}
              </Descriptions.Item>
              <Descriptions.Item label="ZipCode" span={3}>
                {org?.ZipCode}
              </Descriptions.Item>
              <Descriptions.Item label="Tax-ID" span={3}>
                {org?.TaxId}
              </Descriptions.Item>
              <Descriptions.Item label="Non-Profit" span={3}>
                {org?.NonProfit ? 'Yes' : 'No'}
              </Descriptions.Item>
              <Descriptions.Item label="Funded Outside" span={3}>
                {org?.FundedOutside ? 'Yes' : 'No'}
              </Descriptions.Item>
              <Descriptions.Item label="Website" span={3}>
                {org?.Website}
              </Descriptions.Item>
              <Descriptions.Item
                label="Description"
                span={3}
                className="t-text-justify"
              >
                {org?.Description}
              </Descriptions.Item>
              <Descriptions.Item label="Organization Domain" span={3}>
                {OrgDomain && (
                  <Tooltip title="Copy Organization Domain">
                    <AiOutlineCopy
                      className="t-cursor-pointer t-text-secondary-100 t-ml-2"
                      style={{ fontSize: '1.2rem' }}
                      onClick={() => setEmbedURLModalVisible(true)}
                    />
                  </Tooltip>
                )}
              </Descriptions.Item>
            </Descriptions>
            {org?.OrgDomain && (
              <Row className="t-flex t-items-center t-my-2">
                <QRCode
                  size={200}
                  bgColor={config.colors.tertiary}
                  fgColor="#000000"
                  value={OrgDomain}
                />
              </Row>
            )}
            {org?.OrgDomain && (
              <Descriptions>
                <Descriptions.Item label="Organization Donation Page" span={3}>
                  <Tooltip title="Copy Organization Domain">
                    <AiOutlineCopy
                      className="t-cursor-pointer t-text-secondary-100 t-ml-2"
                      style={{ fontSize: '1.2rem' }}
                      onClick={() =>
                        copyToClipboard(`${getWebAppUrl(org?.OrgDomain)}`)
                      }
                    />
                  </Tooltip>
                </Descriptions.Item>
              </Descriptions>
            )}
            <Row className="t-flex t-items-center t-my-2">
              <QRCode
                size={200}
                bgColor={config.colors.tertiary}
                fgColor="#000000"
                value={getDonationURL()}
              />
            </Row>
            <Modal
              centered
              title="Embed URL"
              width={600}
              open={embedURLModalVisible}
              onCancel={() => setEmbedURLModalVisible(false)}
              footer={null}
            >
              <div>
                <div className="t-my-2">
                  <Title level={4}>Copy Domain Link</Title>
                  <CopyBlock
                    language="hml"
                    text={OrgDomain && OrgDomain}
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </div>
                <div className="t-my-2">
                  <Title level={4}>Copy iframe code</Title>
                  <CopyBlock
                    language="html"
                    text={`
                    <iframe
                    style="width: 100%; height: 100%"
                    src=${OrgDomain && OrgDomain}
                    title=${org?.Name}
                    ></iframe>`}
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </div>
                <div className="t-my-2">
                  <Title level={4}>Copy code to paste in the website</Title>
                  <CopyBlock
                    language="html"
                    text={htmlCode(OrgDomain, 'View Organization')}
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </div>
              </div>
            </Modal>
            <Divider />
            <OrganizationProfiles />
          </Card>
        )}
      </Skeleton>
    </Card>
  )
}

export { MyOrganization }

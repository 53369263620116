import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, message } from 'antd'
import OTPInput from 'otp-input-react'

const ResetPasscode = ({
  loading,
  onSubmit,
  resetForm,
  hideResetButton = false,
}) => {
  const [form] = Form.useForm()

  Form.useWatch('passcode', form)
  Form.useWatch('confirmPasscode', form)
  const otp = form.getFieldValue('passcode')
  const confirmOtp = form.getFieldValue('confirmPasscode')
  const isValidOtp = !(otp && otp.length === 6)
  const isValidConfirmOtp = !(confirmOtp && confirmOtp.length === 6)
  // !(otp && otp.length === 6) || !(confirmOtp && confirmOtp.length === 6)
  const myRefname = useRef(null)
  const [storedOtp, setStoredOtp] = useState()

  useEffect(() => {
    if ((myRefname?.current && !isValidOtp) || !isValidConfirmOtp) {
      myRefname.current.click()
    }
  }, [isValidOtp, isValidConfirmOtp])

  const onFinish = (values) => {
    if (!storedOtp) {
      setStoredOtp(values.passcode)
    } else {
      console.log('values', values)
      const { confirmPasscode } = values
      if (storedOtp !== confirmPasscode) {
        message.error(
          'Passcodes do not match. Please make sure both passcodes are identical',
        )
        form.resetFields()
      } else {
        if (resetForm) {
          form.resetFields()
        }
        setStoredOtp()
        onSubmit({ passcode: values.confirmPasscode })
      }
    }
  }

  return (
    <Form form={form} name="otp" onFinish={onFinish} className="row-col">
      {!storedOtp ? (
        <div className="t-mb-4">
          <p className="t-pb-4 t-font-semibold">Enter Passcode :</p>
          <div className="t-flex t-flex-1 t-justify-center t-items-center">
            <Form.Item name="passcode" className="t-mb-0">
              <OTPInput
                autoFocus
                OTPLength={6}
                otpType="number"
                secure
                disabled={false}
                inputClassName="t-w-8 md:t-w-12 t-h-8 md:t-h-12 t-rounded-md t-border-2"
                autocomplete="one-time-code"
              />
            </Form.Item>
          </div>
        </div>
      ) : (
        <div className="t-mb-4">
          <p className="t-pb-4 t-font-semibold">Confirm Passcode :</p>
          <div className="t-flex t-flex-1 t-justify-center t-items-center">
            <Form.Item name="confirmPasscode" className="t-mb-0">
              <OTPInput
                OTPLength={6}
                otpType="number"
                secure
                disabled={false}
                inputClassName="t-w-8 md:t-w-12 t-h-8 md:t-h-12 t-rounded-md t-border-2"
                autocomplete="one-time-code"
              />
            </Form.Item>
          </div>
        </div>
      )}
      <Form.Item>
        <Button
          className="t-w-full t-mt-2"
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={!storedOtp ? isValidOtp : isValidConfirmOtp}
          ref={myRefname}
          style={{ visibility: hideResetButton ? 'hidden' : 'visible' }}
        >
          Reset
        </Button>
      </Form.Item>
    </Form>
  )
}

export { ResetPasscode }

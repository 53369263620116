import React, { useEffect, useState } from 'react'
import { Modal, Typography, Skeleton, Tooltip } from 'antd'
import { MdPreview } from 'react-icons/md'
import { SiConvertio } from 'react-icons/si'
import { formatAmount, formateDateTime } from 'utils'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { InfiniteScrollContainer, ShareReport } from 'components/elements'
import { useHistory, useLocation } from 'react-router-dom'

const { Title } = Typography

function YTDPledges() {
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const { search } = useLocation()

  useEffect(() => {
    document.body.style = visible
      ? 'overflow: hidden !important'
      : 'overflow: auto'
  }, [visible])

  useEffect(() => {
    if (search) {
      const urlSearchParams = new URLSearchParams(search)
      const { ytdPledges } = Object.fromEntries(urlSearchParams.entries())
      if (ytdPledges) {
        setVisible(true)
        history.replace('/settings/account')
      }
    }
    // eslint-disable-next-line
  }, [])

  const { contributionLoading, ytd } = useAuthSelector()
  const itemsFirstLoad = 6
  const [data, setData] = useState([])
  const [current, setCurrent] = useState([])
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setLoader(false)
      }, 1000)
    } else {
      setLoader(true)
    }
  }, [visible])

  useEffect(() => {
    if (ytd?.pledges?.length > 0) {
      const pendingPledges = ytd?.pledges.filter(
        (item) => item.PledgeStatus === 'Pending',
      )
      setData(pendingPledges)
      setCurrent(pendingPledges.slice(0, itemsFirstLoad))
    }
    // eslint-disable-next-line
  }, [ytd])

  return (
    <>
      <div>
        <MdPreview
          id="view-ytd-pledges"
          className="t-text-secondary-100"
          fontSize="2rem"
          onClick={() => setVisible(true)}
        />
      </div>
      {visible && (
        <Modal
          centered
          keyboard
          title="YTD Pledges"
          open={visible}
          onCancel={() => setVisible(false)}
          footer={[]}
        >
          <div className="t-w-full t-h-96">
            <div className="t-flex t-justify-between t-items-center t-mb-4">
              <Title level={5}>Pledges</Title>
              {!contributionLoading &&
                ytd?.pledges &&
                ytd?.pledges.length > 0 && (
                  <ShareReport
                    reportName="YTD Pledges"
                    report={ytd?.pledges.filter(
                      (item) => item.PledgeStatus === 'Pending',
                    )}
                  />
                )}
            </div>
            <Skeleton
              loading={contributionLoading || loader}
              active
              paragraph={{ rows: 6 }}
            >
              <div className="t-h-80 t-relative">
                {data.length === 0 && (
                  <div className="t-flex t-justify-center t-items-center t-h-full t-w-full">
                    No pledges recorded. Make a pledge today.
                  </div>
                )}
                {data.length > 0 && (
                  <InfiniteScrollContainer
                    data={data}
                    current={current}
                    setCurrent={setCurrent}
                    height="280px"
                    itemsFirstLoad={itemsFirstLoad}
                  >
                    <>
                      {current.map((item, index) => (
                        <div
                          key={index}
                          className="t-w-full t-rounded-2xl t-h-12 t-p-3 t-bg-yellow-100 t-text-secondary-100 t-mb-2 t-flex t-items-center t-shadow-md t-justify-between"
                        >
                          <div className="t-flex t-flex-col">
                            <div className="t-flex t-space-x-2 t-items-center">
                              {item?.eventName}
                            </div>
                            <h2 className="t-text-gray-800 t-italic t-text-xs md:t-text-xs t-whitespace-nowrap">
                              {`${formateDateTime(item?.ScheduleDate)}`}
                            </h2>
                          </div>
                          <div className="t-flex t-flex-col">
                            <div className="t-flex t-justify-end">
                              <Tooltip title="Convert Pledge">
                                <SiConvertio
                                  fontSize="1.2rem"
                                  className="t-text-secondary-100 t-cursor-pointer"
                                  onClick={() =>
                                    history.push(
                                      `/pledge-conversion/${item.Id}`,
                                    )
                                  }
                                />
                              </Tooltip>
                            </div>
                            <h2 className="t-font-bold t-text-secondary-100 t-text-right t-text-xs md:t-text-sm">
                              {`${formatAmount(item?.amount)}`}
                            </h2>
                          </div>
                        </div>
                      ))}
                    </>
                  </InfiniteScrollContainer>
                )}
              </div>
            </Skeleton>
          </div>
        </Modal>
      )}
    </>
  )
}

export { YTDPledges }

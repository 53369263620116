import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Descriptions,
  Button,
  Tooltip,
  Skeleton,
  Typography,
  Modal,
} from 'antd'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { MdEdit, MdLocationOn } from 'react-icons/md'
import {
  AiFillEye,
  AiFillEyeInvisible,
  // AiOutlineHeart,
  // AiFillHeart,
} from 'react-icons/ai'
import { HiDuplicate } from 'react-icons/hi'
import { DiRequirejs } from 'react-icons/di'
import { VscTypeHierarchySub } from 'react-icons/vsc'
import { FaMoneyBillWave } from 'react-icons/fa'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useDispatch } from 'react-redux'
// import {
//   addFavourite,
//   removeFavourite,
// } from 'store/favouriteSlice/favouriteActions'
import { useServiceSelector } from 'store/serviceSlice/serviceReducer'
import {
  updateServiceStatus,
  getSubscriberPaymentInformation,
  isUserAlreadyRegisteredInService,
  getService,
  getSubscriberMarkupInfo,
} from 'store/serviceSlice/serviceActions'
import { CopyBlock, dracula } from 'react-code-blocks'
import sample from 'assets/images/sample/sample1.png'
import { formatAmount, isMobile, mobileShareNavigator, htmlCode } from 'utils'
import { ShareModal, SVGIcon } from 'components'
import { awsconfig } from 'configs'
import { useFavouriteSelector } from 'store/favouriteSlice/favouriteReducer'
import { RegisteredUsers } from './RegisteredUsers'

const { Title } = Typography

const ServiceView = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const { state } = useLocation()

  const [isLoading, setLoading] = useState(false)
  const [isShare, setShare] = useState(false)
  const [isUserAlreadyRegistered, setAlreadyRegistered] = useState(false)
  const { loading, service } = useServiceSelector()
  const { loading: favLoading } = useFavouriteSelector()
  const { user, organization } = useAuthSelector()
  const [embedURLModalVisible, setEmbedURLModalVisible] = useState(false)

  console.log(service)
  const changeModal = () => setEmbedURLModalVisible(!embedURLModalVisible)

  const getServiceDetails = () => {
    dispatch(getService(params?.id, user?.id))
  }

  const checkRegisteredUserInService = async () => {
    setLoading(true)
    try {
      const isExist = await isUserAlreadyRegisteredInService(
        params?.id,
        user?.email,
      )
      if (isExist) {
        setAlreadyRegistered(true)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (service?.organization?.id) {
      dispatch(getSubscriberPaymentInformation(service?.organization?.id))
      dispatch(getSubscriberMarkupInfo(service?.organization?.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service?.organization?.id])

  useEffect(() => {
    if (user?.email) {
      getServiceDetails()
      checkRegisteredUserInService()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id, user?.email])

  // const handleAddFavourite = async (id) => {
  //   const payload = {
  //     ReferenceId: id,
  //     Username: user?.id,
  //     Type: 'service',
  //   }
  //   dispatch(addFavourite(payload, getServiceDetails))
  // }

  // const handleRemoveFavourite = async (id) => {
  //   dispatch(removeFavourite(id, getServiceDetails))
  // }

  const handleDuplicate = () => {
    history.push(
      `/settings/create-service?id=${service?.ServiceId}&duplicate=true`,
    )
  }

  const handleUpdate = () => {
    dispatch(
      updateServiceStatus(
        service?.ServiceId,
        service?.ActiveInd,
        history.push('/services'),
      ),
    )
  }

  const shareService = async () => {
    if (isMobile) {
      await mobileShareNavigator(
        service?.Title,
        `${window.location.origin}/registration/service/${service?.ServiceId}`,
      )
    } else {
      setShare(true)
    }
  }

  const serviceLoading = loading || favLoading || isLoading

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            title={[
              <div className="t-flex t-justify-between">
                <div className="t-flex t-items-center t-space-x-4">
                  <Button
                    type="primary"
                    className="t-rounded-full t-flex t-items-center"
                    onClick={() =>
                      state && state.urlData
                        ? history.push({
                            pathname: state.urlData.pathname,
                            state: { key: state.urlData.activeKey },
                          })
                        : history.goBack()
                    }
                    size="small"
                  >
                    <IoIosArrowBack />
                    Back
                  </Button>
                </div>
                <div className="t-flex t-items-center t-space-x-2 t-cursor-pointer">
                  {/* <div
                    className={`${
                      serviceLoading && 't-pointer-events-none t-opacity-50'
                    } ${!service && 't-pointer-events-none t-opacity-0'}`}
                  >
                    {service?.isFav ? (
                      <Tooltip title="Remove Favorite">
                        <AiFillHeart
                          fontSize="1.8rem"
                          id="make-unfav"
                          className="t-cursor-pointer t-text-red-500"
                          onClick={() => handleRemoveFavourite(service?.favId)}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Make Favorite">
                        <AiOutlineHeart
                          fontSize="1.8rem"
                          id="make-fav"
                          className="t-cursor-pointer t-text-secondary-100"
                          onClick={() => handleAddFavourite(service?.ServiceId)}
                        />
                      </Tooltip>
                    )}
                  </div> */}
                  {service?.OrganizationId === organization?.id && (
                    <>
                      <Tooltip title="Edit Service">
                        <MdEdit
                          fontSize="1.8rem"
                          id="edit"
                          className="t-text-secondary-100"
                          onClick={() =>
                            history.push(
                              `/settings/create-service/${service?.ServiceId}`,
                            )
                          }
                        />
                      </Tooltip>
                      <Tooltip title="Duplicate Service">
                        <HiDuplicate
                          fontSize="1.8rem"
                          id="duplicate"
                          className="t-text-secondary-100"
                          onClick={() => handleDuplicate()}
                        />
                      </Tooltip>
                      {service?.ActiveInd === 1 ? (
                        <Tooltip title="Make Inactive">
                          <AiFillEyeInvisible
                            fontSize="1.8rem"
                            id="make-inactive"
                            className="t-text-secondary-100"
                            onClick={() => handleUpdate()}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Make Active">
                          <AiFillEye
                            fontSize="1.8rem"
                            id="make-active"
                            className="t-text-secondary-100"
                            onClick={() => handleUpdate()}
                          />
                        </Tooltip>
                      )}
                    </>
                  )}
                </div>
              </div>,
            ]}
            bordered={false}
            className="header-solid card-product mb-24"
          >
            <Skeleton loading={serviceLoading} active paragraph={{ rows: 18 }}>
              {service ? (
                <>
                  <Row gutter={[24, 0]}>
                    <Col
                      span={24}
                      lg={12}
                      xl={10}
                      className="col-gallery mb-24"
                    >
                      <img
                        src={
                          service?.Flyer?.fileName
                            ? awsconfig.bucketUrl + service?.Flyer?.fileName
                            : service?.organization?.BackgroundImage
                            ? awsconfig.bucketUrl +
                              service?.organization?.BackgroundImage?.fileName
                            : sample
                        }
                        className="t-h-36 md:t-h-96 t-w-full t-rounded-lg shadow-3 t-object-cover"
                        alt="card-2"
                      />
                    </Col>
                    <Col span={24} xl={12} lg={{ offset: 1 }}>
                      <div className="t-mb-4">
                        <h1 className="t-text-secondary-100 t-text-2xl md:t-text-4xl t-font-bold">
                          {service?.Title}
                        </h1>
                        <h1 className="t-text-secondary-100 t-text-lg md:t-text-2xl">
                          {service?.organization?.Name}
                        </h1>
                        <Descriptions colon={false} className="t-my-4">
                          {service.ServiceType !== 'Zakaat' && (
                            <Descriptions.Item
                              label={<VscTypeHierarchySub fontSize="1.5rem" />}
                              span={3}
                            >
                              {service?.DeliveryLocation}
                            </Descriptions.Item>
                          )}
                          {service.DeliveryLocation &&
                            service?.DeliveryLocation !== 'Online' && (
                              <Descriptions.Item
                                label={
                                  <Tooltip title="Address">
                                    <MdLocationOn fontSize="1.5rem" />
                                  </Tooltip>
                                }
                                span={3}
                              >
                                {service?.DeliveryLocation === 'Hybrid'
                                  ? service?.Address1
                                    ? service?.Address1
                                    : null
                                  : service?.organization?.Address1
                                  ? service?.organization?.Address1
                                  : null}
                              </Descriptions.Item>
                            )}
                          <Descriptions.Item
                            label={<FaMoneyBillWave fontSize="1.5rem" />}
                            span={3}
                          >
                            {formatAmount(service?.ProgramFees)}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={<DiRequirejs fontSize="1.5rem" />}
                            span={3}
                          >
                            {service?.Requirements}
                          </Descriptions.Item>
                        </Descriptions>

                        <Typography.Title
                          level={4}
                          className="t-text-secondary-100"
                        >
                          Description
                        </Typography.Title>
                        <Typography.Paragraph className="t-text-justify">
                          {service?.Description}
                        </Typography.Paragraph>
                        <>
                          {isUserAlreadyRegistered ? (
                            <div className="t-mb-2">
                              <Button
                                type="primary"
                                style={{
                                  background: 'grey',
                                  borderColor: 'grey',
                                }}
                              >
                                Registered
                              </Button>
                            </div>
                          ) : (
                            <div className="t-my-4 t-space-x-2">
                              <Button
                                type="primary"
                                loading={isLoading}
                                onClick={() =>
                                  history.push(
                                    `/registration/service/${service?.ServiceId}`,
                                  )
                                }
                              >
                                Register
                              </Button>
                            </div>
                          )}
                        </>
                        {/* )} */}
                        <div className="t-flex t-flex-col">
                          <Button
                            type="primary"
                            onClick={shareService}
                            className="t-w-[10%] t-my-2"
                          >
                            Share
                          </Button>
                          <Button
                            type="primary"
                            onClick={changeModal}
                            className="t-w-[20%] t-my-2"
                          >
                            Embed Code
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {service?.OrganizationId === organization?.id && (
                    <Row gutter={[24, 0]} className="mt-24">
                      <Col span={24}>
                        <h6 className="t-font-bold t-text-base">
                          Registered User
                        </h6>
                        <RegisteredUsers serviceType={service?.ServiceType} />
                      </Col>
                    </Row>
                  )}
                </>
              ) : (
                <div className="ant-result ant-result-404">
                  <SVGIcon />
                  <div className="ant-result-title">Invalid URL</div>
                  <div className="ant-result-subtitle">
                    Sorry, the page you visited does not exist.
                  </div>
                  <div className="ant-result-extra">
                    <Button
                      onClick={() => history.push('/services')}
                      type="primary"
                      size="small"
                    >
                      Goto Services
                    </Button>
                  </div>
                </div>
              )}
            </Skeleton>
          </Card>
        </Col>
      </Row>
      <ShareModal
        url={`${window.location.origin}/registration/service/${service?.ServiceId}`}
        visible={isShare}
        onClose={() => setShare(false)}
      />
      <Modal
        centered
        title="Embed URL"
        width={600}
        open={embedURLModalVisible}
        onCancel={changeModal}
        footer={null}
      >
        <div>
          <div className="t-my-2">
            <Title level={4}>Copy iframe code</Title>
            <CopyBlock
              language="html"
              text={`
              <iframe
              style="width: 100%; height: 100%"
              src=${window.location.origin}/registration/service/${service?.ServiceId}
              title="Register through Extensia"
              ></iframe>`}
              theme={dracula}
              wrapLines
              codeBlock
            />
          </div>
          <div className="t-my-2">
            <Title level={4}>Copy code to paste in the website</Title>
            <CopyBlock
              language="html"
              text={htmlCode(
                `${window.location.origin}/registration/service/${service?.ServiceId}`,
                'Register',
              )}
              theme={dracula}
              wrapLines
              codeBlock
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export { ServiceView }

import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Form,
  Row,
  Col,
  Typography,
  message,
  Select,
  Tooltip,
} from 'antd'
import { REGEXS, formatAmount } from 'utils'
import { useAuthSelector } from 'store/authSlice/authReducer'
import * as Yup from 'yup'
import Apiv1 from 'api'
import { MdPersonAddAlt } from 'react-icons/md'
import { AddCommunityMemberModal } from 'components/common'
import { AmountInput } from 'components/elements'
import { useFundraiserSelector } from 'store/fundraiserSlice/fundraiserReducer'
// import { addPledge } from 'store/authSlice/authActions'

const { Title } = Typography

const amountValidation = REGEXS.amountRegex
const schema = Yup.object().shape({
  askedAmount: Yup.string()
    .required('Required')
    .matches(amountValidation, 'Invalid amount'),
  communityMember: Yup.string().required('Select a community member'),
  type: Yup.string().required('Type is required'),
})

const addCollectedAmountValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {}

const ManualPledges = () => {
  const [form] = Form.useForm()
  const [community, setCommunity] = useState([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [pledgeReminder, setPledgeReminder] = useState('Scheduled')
  const [messaginFrequency, setMessaginFrequency] = useState('Monthly')
  const [maxMessage, setMaxMessage] = useState(3)

  const { fundraiser } = useFundraiserSelector()
  const { organization } = useAuthSelector()
  Form.useWatch('askedAmount', form)

  const getDonors = () => {
    Apiv1.get(`subscriber/donorList/${organization.id}`)
      .then((res) => {
        const filterMembers = res?.result?.filter(
          (item) => item.DonorEmail && item.DonorPhoneNumber,
        )
        setCommunity(filterMembers)
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  useEffect(() => {
    getDonors()
    // eslint-disable-next-line
  }, [])
  const pledgeReminderChange = (e) => {
    setPledgeReminder(e)
  }
  const messagingFreqChange = (e) => {
    setMessaginFrequency(e)
  }
  const maxMessageChange = (e) => {
    setMaxMessage(e)
  }

  const onFinish = async (values) => {
    setLoading(true)
    const memeber = community.find(
      (item) => item.Id === values?.communityMember,
    )
    try {
      const data = {}
      const isEventPledge = true
      const askedAmountValue = Number(values.askedAmount.replaceAll(/,/g, ''))
      data.pledgeType = fundraiser.EventType
      data.amount = askedAmountValue
      data.isMonthly = false
      data.organizationId = organization.id
      data.transactionType = isEventPledge ? 'fundraiser' : 'organization'
      data.donorId = memeber.Id
      data.donorName = memeber?.DonorName
      data.donorPhoneNumber = memeber?.DonorPhoneNumber
      data.donorEmail = memeber?.DonorEmail
      data.PledgeReminders = pledgeReminder
      data.MessagingFrequency = messaginFrequency
      data.MaximumMessages = maxMessage
      data.fundraiserId = fundraiser.iD
      data.fundraiserName = fundraiser.title
      const response = await Apiv1.post('pledge/fundraiser', data)
      if (response) {
        form.resetFields()
        message.success('Pledge Added Successfully')
        setLoading(false)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(error?.response?.data?.error || 'Something Went Wrong')
    }
  }

  const askedAmountValue = form.getFieldValue('askedAmount')

  const askedAmountWithoutComma = askedAmountValue?.replaceAll(/,/g, '')
  //   const onDefaultValuecahnge = (values) => {
  //     form.setFieldsValue({
  //       defaultAmount: values,
  //       askedAmount: values,
  //     })
  //   }
  return (
    <Card className="t-w-full">
      <Form
        form={form}
        layout="vertical"
        key={0}
        onFinish={onFinish}
        name="organization"
        initialValues={initialValues}
        scrollToFirstError
      >
        <Row gutter={[24, 0]}>
          <Col span={24} className="t-flex t-justify-end t-items-center">
            <Tooltip title="Add New Community Member">
              <MdPersonAddAlt
                fontSize="1.5rem"
                className="t-text-secondary-100 t-cursor-pointer"
                onClick={() => setShowModal(true)}
              />
            </Tooltip>
          </Col>
          <Col span={24}>
            <Form.Item
              name="communityMember"
              label="Search Community Member (must have valid phone number and email)"
              colon={false}
              rules={[addCollectedAmountValidation]}
            >
              <Select
                style={{ width: '100%' }}
                showSearch
                optionFilterProp="children"
                defaultValue="Select a Community Member"
              >
                {community?.map((item, index) => (
                  <Select.Option value={item.Id} key={index}>
                    {item.DonorName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Pledge Reminders"
              colon={false}
              rules={[addCollectedAmountValidation]}
            >
              <Select onChange={pledgeReminderChange} value={pledgeReminder}>
                <Select.Option value="Scheduled">Scheduled</Select.Option>
                <Select.Option value="Manual">Manual</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Messaging Frequency"
              colon={false}
              rules={[addCollectedAmountValidation]}
            >
              <Select onChange={messagingFreqChange} value={messaginFrequency}>
                <Select.Option value="Weekly">Weekly</Select.Option>
                <Select.Option value="Monthly">Monthly</Select.Option>
                <Select.Option value="Quarterly">Quarterly</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Maximum # of messages to send"
              colon={false}
              rules={[addCollectedAmountValidation]}
            >
              <Select onChange={maxMessageChange} value={maxMessage}>
                <Select.Option value="1">1</Select.Option>
                <Select.Option value="2">2</Select.Option>
                <Select.Option value="3">3</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="askedAmount"
              label="Amount"
              colon={false}
              rules={[addCollectedAmountValidation]}
            >
              <AmountInput
                placeholder="Add Collected Amount"
                onChange={(value) =>
                  form.setFieldsValue({
                    askedAmount: value,
                  })
                }
              />
            </Form.Item>
          </Col>

          <Col xl={24} md={24} xs={24} sm={24}>
            <Row justify="end">
              <Title level={3}>
                {`Total Pledge = ${formatAmount(askedAmountWithoutComma)}`}
              </Title>
            </Row>
          </Col>
          <Col span={24} className="text-right">
            <Button
              loading={loading}
              type="primary"
              className="px-25"
              htmlType="submit"
            >
              {loading ? 'Pledge...' : 'Pledge'}
            </Button>
          </Col>
        </Row>
      </Form>
      <AddCommunityMemberModal
        isVisible={showModal}
        onClose={() => setShowModal(false)}
        getData={getDonors}
      />
    </Card>
  )
}
export { ManualPledges }

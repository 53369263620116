import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  // Modal,
  // Radio,
  message,
  Row,
  Select,
  Card,
  Skeleton,
} from 'antd'
import Api from 'api'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { useAuthSelector } from 'store/authSlice/authReducer'
import * as Yup from 'yup'
import { REGEXS } from 'utils'
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min'
import { IoIosArrowBack } from 'react-icons/io'

const amountValidation = REGEXS.amountRegex
const schema = Yup.object().shape({
  askedAmount: Yup.string()
    .required('Amount is Required')
    .matches(amountValidation, 'Invalid amount'),
  campaign: Yup.string().required('Select a Campaign'),
  event: Yup.string().required('Select an Event'),
})

const addCollectedAmountValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const PledgeModal = () => {
  const [pledgeType, setPledgeType] = useState('organization')
  const [pledgeLoading, setPledgeLoading] = useState(false)
  const [selectEventCampign, setSelectEventCampign] = useState(null)
  const [val, setVal] = useState('')
  const [pledgeReminder, setPledgeReminder] = useState('Scheduled')
  const [messaginFrequency, setMessaginFrequency] = useState('Monthly')
  const [maxMessage, setMaxMessage] = useState(3)
  const [pledgeMember, setPlegeMember] = useState()
  const [loading, setLoading] = useState(true)

  const { events, campaigns } = useEventSelector()
  const { organization } = useAuthSelector()
  const { id } = useParams()
  const history = useHistory()

  const [form] = Form.useForm()
  Form.useWatch('askedAmount', form)

  const initialValues = {
    DonorName: '',
    DonorEmail: '',
    DonorPhoneNumber: '',
    DonorZipCode: '',
  }

  const pledgeTypeChange = (e) => {
    setPledgeType(e)
  }

  const handleCampignEvent = (e) => {
    setSelectEventCampign(e)
  }

  const onChange = (e) => {
    setVal(e.target.value)
  }

  const pledgeReminderChange = (e) => {
    setPledgeReminder(e)
  }
  const messagingFreqChange = (e) => {
    setMessaginFrequency(e)
  }
  const maxMessageChange = (e) => {
    setMaxMessage(e)
  }

  const getDonors = () => {
    setLoading(true)
    Api.get(`subscriber/donorList/${organization.id}`)
      .then((res) => {
        setPlegeMember(res.result.find((x) => x.Id === id))
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        message.error('An Error Occured in fetching list')
        setLoading(false)
      })
  }

  useEffect(() => {
    getDonors()
    // eslint-disable-next-line
  }, [])

  const onFinish = async () => {
    setPledgeLoading(true)
    let payloadObj = {}
    try {
      if (pledgeType === 'campaign') {
        payloadObj = campaigns.find((x) => x.Id === selectEventCampign)
      } else if (pledgeType === 'event') {
        payloadObj = events.find((x) => x.Id === selectEventCampign)
      }
      const payload = {}
      const isEventPledge =
        payloadObj.EventType === 'Fundraiser' ||
        payloadObj.EventType === 'Campaign'
      const askedAmountValue = Number(val.replaceAll(/,/g, ''))
      payload.pledgeType = payloadObj.EventType
      payload.amount = askedAmountValue
      payload.isMonthly = false
      payload.organizationId = organization.id
      payload.transactionType = isEventPledge
        ? payloadObj?.EventType === 'Fundraiser'
          ? 'event'
          : 'campaign'
        : 'organization'
      payload.donorId = pledgeMember.Id
      payload.donorName = pledgeMember?.DonorName
      payload.donorPhoneNumber = pledgeMember?.DonorPhoneNumber
      payload.donorEmail = pledgeMember?.DonorEmail
      payload.PledgeReminders = pledgeReminder
      payload.MessagingFrequency = messaginFrequency
      payload.MaximumMessages = maxMessage
      if (isEventPledge) {
        payload.eventId = payloadObj?.Id
        payload.eventName = payloadObj?.Title
        // payload.ReminderType = 'Check'
      }
      const response = await Api.post('/pledge', payload)
      if (response) {
        form.resetFields()
        message.success('Pledge Added Successfully')
        setPledgeLoading(false)
      }
      setPledgeLoading(false)
    } catch (error) {
      console.log(error)
      setPledgeLoading(false)
      message.error(error?.response?.data?.error || 'Something Went Wrong')
    }
  }

  return (
    <>
      <Card
        className="header-solid"
        bordered={false}
        title={[
          <>
            <div className="t-flex t-items-center t-space-x-4">
              <Button
                type="primary"
                className="t-rounded-full t-flex t-items-center"
                onClick={() => history.goBack()}
                size="small"
              >
                <IoIosArrowBack />
                Back
              </Button>
            </div>
            <h6 className="mb-0 t-text-2xl t-mt-2">Add Pledge</h6>
            <p className="font-regular">Add Pledge Manually</p>
          </>,
        ]}
      >
        <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
          <Form
            form={form}
            layout="vertical"
            name="Edit Donor"
            initialValues={initialValues}
            scrollToFirstError
            onFinish={onFinish}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Select Pledge Type"
                  colon={false}
                  rules={[addCollectedAmountValidation]}
                >
                  <Select onChange={pledgeTypeChange} value={pledgeType}>
                    <Select.Option value="organization">
                      Organization
                    </Select.Option>
                    <Select.Option value="campaign">
                      Campaign Pledge
                    </Select.Option>
                    <Select.Option value="event">Event Pledge</Select.Option>
                  </Select>
                </Form.Item>
                {pledgeType === 'campaign' ? (
                  <Form.Item
                    name="campaign"
                    label="Select Campign"
                    colon={false}
                    rules={[addCollectedAmountValidation]}
                  >
                    <Select
                      showSearch
                      onChange={handleCampignEvent}
                      value={selectEventCampign}
                      placeholder="Select Campaign"
                    >
                      {campaigns
                        ?.filter(
                          (item) => item?.OrganizationId === organization?.id,
                        )
                        ?.map((item, index) => (
                          <Select.Option key={index} value={item.Id}>
                            {item.Title}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                ) : pledgeType === 'event' ? (
                  <Form.Item
                    name="event"
                    label="Select Event"
                    colon={false}
                    rules={[addCollectedAmountValidation]}
                  >
                    <Select
                      showSearch
                      onChange={handleCampignEvent}
                      value={selectEventCampign}
                      placeholder="Select Event"
                    >
                      {events
                        ?.filter(
                          (item) => item?.OrganizationId === organization?.id,
                        )
                        .map((item, index) => (
                          <Select.Option key={index} value={item.Id}>
                            {item.Title}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                ) : null}

                <Form.Item
                  label="Pledge Reminders"
                  colon={false}
                  rules={[addCollectedAmountValidation]}
                >
                  <Select
                    onChange={pledgeReminderChange}
                    value={pledgeReminder}
                  >
                    <Select.Option value="Scheduled">Scheduled</Select.Option>
                    <Select.Option value="Manual">Manual</Select.Option>
                  </Select>
                </Form.Item>

                {pledgeReminder === 'Scheduled' ? (
                  <>
                    <Form.Item
                      label="Messaging Frequency"
                      colon={false}
                      rules={[addCollectedAmountValidation]}
                    >
                      <Select
                        onChange={messagingFreqChange}
                        value={messaginFrequency}
                      >
                        <Select.Option value="Weekly">Weekly</Select.Option>
                        <Select.Option value="Monthly">Monthly</Select.Option>
                        <Select.Option value="Quarterly">
                          Quarterly
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Maximum # of messages to send"
                      colon={false}
                      rules={[addCollectedAmountValidation]}
                    >
                      <Select onChange={maxMessageChange} value={maxMessage}>
                        <Select.Option value="1">1</Select.Option>
                        <Select.Option value="2">2</Select.Option>
                        <Select.Option value="3">3</Select.Option>
                      </Select>
                    </Form.Item>
                  </>
                ) : null}

                <Form.Item
                  label="Donation *"
                  name="askedAmount"
                  colon={false}
                  rules={[addCollectedAmountValidation]}
                >
                  <Input
                    prefix="$"
                    onChange={onChange}
                    placeholder="Enter Donated Amount"
                  />
                </Form.Item>
                <Row gutter={[12, 0]}>
                  <Col xl={4} md={4} xs={24} sm={12}>
                    <Form.Item>
                      <Button
                        style={{ width: '100%' }}
                        type="primary"
                        htmlType="submit"
                        loading={pledgeLoading}
                      >
                        {pledgeLoading ? 'Pledge...' : 'Pledge'}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Card>
    </>
  )
}

export { PledgeModal }

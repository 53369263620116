import React, { useState, useEffect } from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Modal,
  Checkbox,
  Switch,
  message,
} from 'antd'
import * as Yup from 'yup'
import { REGEXS, formatAmount } from 'utils'
import { payFromSeedDonate } from 'store/paymentSlice/paymentActions'
import { useParams } from 'react-router-dom'
import { getEventControlScreenDetails } from 'store/eventSlice/eventActions'
import { useDispatch } from 'react-redux'
import { AmountInput, TermAndConditionModal } from 'components/elements'

const amountValidation = REGEXS.amountRegex
const schema = Yup.object().shape({
  donateAmount: Yup.string()
    .required('Required')
    .matches(amountValidation, 'Invalid amount'),
  agreeToTerms: Yup.string().required('Required'),
  paymentMethod: Yup.string().required('Required'),
  isAnonymously: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  phoneNumber: Yup.string().required('Required'),
})

const donateFromSeedValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  donateAmount: '',
  agreeToTerms: false,
  paymentMethod: null,
  isAnonymously: false,
  name: null,
  email: null,
  phoneNumber: null,
}

const DonateFromSeed = ({ isVisible, onClose }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { id } = useParams()

  Form.useWatch('donateAmount', form)
  Form.useWatch('paymentMethod', form)
  Form.useWatch('agreeToTerms', form)
  Form.useWatch('isAnonymously', form)
  Form.useWatch('email', form)
  Form.useWatch('name', form)
  Form.useWatch('phoneNumber', form)

  const donate = isVisible

  const isAgreed = !form.getFieldValue('agreeToTerms')
  const isAnonymously = !form.getFieldValue('isAnonymously')

  useEffect(() => {
    if (id) {
      dispatch(getEventControlScreenDetails(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      paymentMethod: donate?.PaymentMethod,
      name: donate?.DonorName,
      email: donate?.DonorEmail,
      phoneNumber: donate?.DonorPhoneNumber,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donate])

  const onFinish = async (values) => {
    console.log('Hello', values)
    const donateAmount = Number(values?.donateAmount.replaceAll(/,/g, ''))
    if (
      parseInt(donate?.RemainingAmount, 10) - parseInt(donateAmount, 10) <
      0
    ) {
      message.error('You does not have sufficient seed donations')
    } else {
      setLoading(true)
      try {
        const data = {
          Id: donate.Id,
          donateAmount,
          isDonatingAnonymously: !values.isAnonymously,
        }
        await payFromSeedDonate(data)
        setLoading(false)
        message.success('Donated Successfully')
        onClose()
        // update the data again
      } catch (error) {
        message.error('Error donating from seed ...')
        setLoading(false)
      }
    }
  }

  return (
    <Modal
      centered
      title="Donate From Seed Amount"
      visible={isVisible}
      onCancel={onClose}
      okText="Donate"
      footer={null}
    >
      <Row className="modal_height_mobile md:modal_height_desktop t-flex t-justify-center t-overflow-scroll no-scrollbar">
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="pay-cust"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row className="t-p-1">
            <Col span={24}>
              <Row justify="end">
                <h1 className="t-font-bold t-text-secondary-100">
                  {`Total Donation = ${formatAmount(donate?.Amount)}`}
                </h1>
              </Row>
              <Row justify="end">
                <h1 className="t-font-bold t-text-green-500">
                  {`Available Donation = ${formatAmount(
                    donate?.RemainingAmount,
                  )}`}
                </h1>
              </Row>
              <Row justify="end">
                <h1 className="t-font-bold t-text-red-500">
                  {`Utilized Donation = ${formatAmount(
                    donate?.Amount - donate?.RemainingAmount,
                  )}`}
                </h1>
              </Row>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="donateAmount"
                label="Donate Amount"
                colon={false}
                rules={[donateFromSeedValidation]}
              >
                <AmountInput
                  placeholder="Enter amount here"
                  onChange={(value) =>
                    form.setFieldsValue({
                      donateAmount: value,
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="paymentMethod"
                label="Payment Method"
                colon={false}
                rules={[donateFromSeedValidation]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="isAnonymously"
                label="Do you want to show donor detail on donation?"
                colon={false}
                rules={[donateFromSeedValidation]}
              >
                <Switch />
              </Form.Item>
            </Col>
            {!isAnonymously && (
              <>
                <Col span={24}>
                  <Form.Item
                    name="name"
                    label="Donor Name"
                    colon={false}
                    rules={[donateFromSeedValidation]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="email"
                    label="Donor Email"
                    colon={false}
                    rules={[donateFromSeedValidation]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="phoneNumber"
                    label="Donor Phone Number"
                    colon={false}
                    rules={[donateFromSeedValidation]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="agreeToTerms"
                colon={false}
                rules={[donateFromSeedValidation]}
              >
                <Checkbox
                  onChange={(e) =>
                    form.setFieldsValue({
                      agreeToTerms: e.target.checked,
                    })
                  }
                >
                  <TermAndConditionModal />
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              You can make payments to boost your fundraising. You can utilize
              available amount to make a great impact on the donors.
            </Col>
            <Col span={24} className="text-right t-mt-6">
              <Button
                type="primary"
                className="px-25"
                htmlType="submit"
                loading={loading}
                disabled={isAgreed}
              >
                Pay
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
    </Modal>
  )
}

export { DonateFromSeed }

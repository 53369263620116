/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Progress } from 'antd'
import { config } from 'configs'
import ApexCharts from 'react-apexcharts'
import { formatAmountWithOutDecimal } from 'utils'

const Fundraiser = ({ array, netGross }) => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: 'Community Members',
        type: 'bar',
        data: [], // Bar data
        color: '#06B6D4', // Bar color
      },
      {
        name: 'Community Members Trend',
        type: 'line',
        data: [], // Line data
        color: '#00E396', // Line color
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        width: [3, 3, 0],
        curve: 'smooth',
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          dataLabels: {
            position: 'top', // position of dataLabels on top of the bars
          },
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0], // Enable data labels only for total donations (bar series)
        offsetY: -20, // Adjust position to be above the bar
        style: {
          fontSize: '12px',
          colors: ['#304758'],
          fontWeight: 'bold',
        },
        formatter(val) {
          return `${formatAmountWithOutDecimal(val.toFixed(2))}`
        },
      },
      xaxis: {
        type: 'category',
        categories: [],
      },
      yaxis: {
        labels: {
          formatter: (value) => formatAmountWithOutDecimal(value.toFixed(2)),
        },
        title: {
          text: 'Amount (USD)',
        },
      },
      tooltip: {
        y: {
          formatter: (value) => formatAmountWithOutDecimal(value.toFixed(2)),
        },
      },
      colors: ['#06B6D4', '#00E396'],
    },
  })

  useEffect(() => {
    if (!array || array.length === 0) {
      return
    }

    // const currentDate = new Date()
    // const currentMonthIndex = currentDate.getMonth()

    // Create a fixed array of months
    const allMonths = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    const cumulativeData = allMonths.map((month, index) => {
      // if (index > currentMonthIndex) {
      //   return null // Skip future months
      // }
      const donationsForMonth = array.filter(
        (d) =>
          new Date(d.StartDate).toLocaleString('en-US', { month: 'short' }) ===
          month,
      )
      const totalRaisedAmount = donationsForMonth.reduce(
        (acc, d) =>
          acc + +(netGross ? d.totalRaisedNetAmount : d.totalRaisedGrossAmount),
        0,
      )
      return {
        month,
        totalRaisedAmount,
      }
    })

    // Filter out null values
    const validData = cumulativeData.filter((data) => data !== null)

    // Calculate the trend data (for the line)
    const trendData = validData.map((data) => data.totalRaisedAmount)

    setChartData((prevChartData) => ({
      ...prevChartData,
      series: [
        {
          name: 'Fundraisers',
          type: 'bar',
          data: validData.map((data) => data.totalRaisedAmount),
          color: '#06B6D4',
        },
        {
          name: 'Fundraisers Trend',
          type: 'line',
          data: trendData,
          color: '#00E396',
        },
      ],
      options: {
        ...prevChartData.options,
        xaxis: {
          type: 'category',
          categories: validData.map((data) => data.month),
        },
      },
    }))
  }, [array, netGross])

  function formatDate(dateString) {
    if (dateString === null) {
      return ' '
    }
    const options = { year: 'numeric', month: 'short' }
    const formattedDate = new Date(dateString).toLocaleDateString(
      'en-US',
      options,
    )
    return formattedDate
  }

  function formatAmount(amount) {
    const amountAsNumber = parseFloat(amount)
    const formattedAmount = amountAsNumber.toLocaleString()
    return `$${formattedAmount}`
  }

  return (
    <div className="">
      <div className="t-grid md:t-grid-cols-2 t-grid-cols-1 t-gap-4 t-mt-8 t-px-2">
        <div>
          {array.map((item, index) => {
            return (
              <div
                className="t-col-span-1 t-row-span-1 p-4 t-rounded-lg t-cursor-pointer t-bg-white t-border t-border-gray-300 t-text-black t-mb-4"
                key={index}
              >
                <div className="t-flex t-flex-row t-justify-between">
                  <h2 className="t-text-base t-pl-4 t-pt-4 t-font-bold t-text-gray-800">
                    {item.Title}
                  </h2>
                  <button className="t-bg-gray-300 t-px-8 t-py-1 t-shadow-sm t-shadow-gray-400 t-text-black t-text-sm t-mt-4 t-mb-1 t-mr-4">
                    {formatDate(item.StartDate)}{' '}
                    {item.EndDate === null ? ' ' : ' - '}{' '}
                    {formatDate(item.EndDate)}
                  </button>
                </div>
                <h1 className="t-text-2xl t-pl-4 t-pb-2 t-font-bold t-text-gray-800">
                  {formatAmount(
                    netGross
                      ? item.totalRaisedNetAmount || 0
                      : item.totalRaisedGrossAmount || 0,
                  )}{' '}
                  - Raised{' '}
                </h1>
                {(!item?.FundraiserType ||
                  item?.FundraiserType !== 'ClickTap') && (
                  <>
                    <div className="w-[90%] t-mx-4">
                      <Progress
                        percent={
                          Number(
                            ((netGross
                              ? item.totalRaisedNetAmount
                              : item.totalRaisedGrossAmount || 0) *
                              100) /
                              item.TargetAmount,
                          ).toFixed(0) || 0
                        }
                        strokeColor={config.colors.primary}
                      />
                    </div>
                    <div className="t-flex t-flex-row t-justify-between t-px-4 t-mt-2 t-pb-4">
                      <div></div>
                      <h2 className="t-text-base">
                        <strong>{formatAmount(item.TargetAmount || 0)}</strong>{' '}
                        - Goal
                      </h2>
                    </div>
                  </>
                )}
              </div>
            )
          })}
        </div>
        {/* Last Row */}
        <div className="t-rounded-lg t-mb-2 t-max-h-[510px] t-bg-white t-border t-border-gray-300">
          <h1 className="t-py-4 t-px-8 t-text-2xl t-font-bold t-text-gray-900">
            Donations For Last 12 Months
          </h1>
          <div className="t-mt-4 t-m-3">
            <ApexCharts
              options={chartData.options}
              series={chartData.series}
              type="line"
              height={400}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Fundraiser

import React, { useState } from 'react'
import { Card, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { ComponentViewHeader } from 'components/common/componentView/components'
import { RentalTab } from './components'

const RentalFacilities = () => {
  const [searchText, setSearchText] = useState('')

  return (
    <Card
      bordered={false}
      className="header-solid mb-24"
      title={
        <div className="t-flex t-flex-wrap t-justify-between">
          <ComponentViewHeader
            heading="Rental Facilities"
            subHeading="All Rentals"
            buttonLink="/settings/create-room"
            // extraButtons={[
            //   {
            //     label: 'My Rooms',
            //     link: '/settings/manage-organization/my-rooms',
            //   },
            //   {
            //     label: 'My Reservations',
            //     link: '/settings/manage-organization/my-reservations',
            //   },
            // ]}
          />
          <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
            <div className="header-control md:t-mt-0">
              <Input
                id="searchEvent"
                value={searchText}
                className="header-search t-w-48"
                placeholder="Search By Capacity"
                prefix={<SearchOutlined />}
                onChange={(e) => {
                  const val = e.target.value.replace(/\D/g, '')
                  setSearchText(val)
                }}
              />
            </div>
          </div>
        </div>
      }
    >
      <RentalTab searchText={searchText} />
    </Card>
  )
}

export { RentalFacilities }

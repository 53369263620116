import React, { useEffect } from 'react'
import { Input, Form, Col, Select, Typography } from 'antd'
import * as Yup from 'yup'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { numberWithCommas } from 'utils'

const { Paragraph } = Typography

const schema = Yup.object().shape({
  Name: Yup.string().required('Name is required'),
  Gender: Yup.string().typeError('Invalid Date').required('Gender is required'),
  Age: Yup.string().required('Age is required'),
  Instructions: Yup.string(),
})

const classValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const getGendersOptions = (program) => {
  const genders = []
  if (program?.MaleAllowed) {
    genders.push('Male')
  }
  if (program?.FemaleAllowed) {
    genders.push('Female')
  }
  return genders
}

const ClassForm = ({ form, program }) => {
  const { user } = useAuthSelector()

  useEffect(() => {
    if (user) {
      form.setFieldValue('Name', `${user.firstname} ${user.lastname}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleInputNumberChange = (name, value) => {
    const withoutCommas = value.replaceAll(/,/g, '')
    const val = withoutCommas.replace(/\D/g, '')
    form.setFieldsValue({ [name]: numberWithCommas(val) })
  }

  const getGenderInstructions = () => {
    if (program?.MaleAllowed && !program?.FemaleAllowed) {
      return 'This class is only open to male students'
    }
    if (!program?.MaleAllowed && program?.FemaleAllowed) {
      return 'This class is only open to female students'
    }
    if (program?.MaleAllowed && program?.FemaleAllowed) {
      return 'This class is open to both male and female students'
    }
    return 'This class is not open to both male and female students'
  }

  return (
    <>
      <Col span={24}>
        <h3 className="t-mb-4 t-text-lg t-font-bold">Student Information</h3>
      </Col>
      <Col xl={24} md={24} xs={24} sm={24}>
        <Form.Item
          name="Name"
          label="Name"
          colon={false}
          rules={[classValidation]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xl={24} md={24} xs={24} sm={24}>
        <Form.Item
          name="Age"
          label="Age"
          colon={false}
          rules={[classValidation]}
        >
          <Input
            placeholder="Age"
            inputMode="numeric"
            onChange={(e) => handleInputNumberChange('Age', e.target.value)}
          />
        </Form.Item>
      </Col>
      {program?.MinimumAge && program?.MaximumAge && (
        <Col span={24}>
          <Paragraph className="t-font-bold t-text-xs t-text-yellow-600 t-text-right">
            {`Allowed age range is ${program?.MinimumAge} - ${program?.MaximumAge}`}
          </Paragraph>
        </Col>
      )}
      <Col xl={24} md={24} xs={24} sm={24}>
        <Form.Item
          name="Gender"
          label="Gender"
          colon={false}
          rules={[classValidation]}
        >
          <Select placeholder="Select Gender" style={{ width: '100%' }}>
            {getGendersOptions(program).map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Paragraph className="t-font-bold t-text-xs t-text-yellow-600 t-text-right">
          {getGenderInstructions()}
        </Paragraph>
      </Col>
      <Col xl={24} md={24} xs={24} sm={24}>
        <Form.Item
          name="Instructions"
          label="Any Special Instructions?"
          colon={false}
          rules={[classValidation]}
        >
          <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
        </Form.Item>
      </Col>
    </>
  )
}

export { ClassForm }

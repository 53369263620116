import React from 'react'

const PaymentNotConfigured = () => (
  <div className="t-flex t-items-center t-justify-center t-h-full t-w-full">
    <div className="ant-result ant-result-404">
      <div className="ant-result-title">Payment Not Configured</div>
      <div className="ant-result-subtitle">
        Sorry for the inconvenience. Subscriber has not yet allowed payments.
      </div>
    </div>
  </div>
)

export { PaymentNotConfigured }

import React, { useEffect, useState } from 'react'
import { Skeleton, Table } from 'antd'
import Api from 'api'
import { formatAmount, sortByDate } from 'utils'
import moment from 'moment'
import { reportData } from '../../../ReportData'

function DonorContributionsReport({ id, setValue, type }) {
  const [loading, setLoading] = useState()
  const [currentData, setCurrentData] = useState([]) // Data to display in the table
  const [loadingPagination, setLoadingPagination] = useState(false)
  const pageSize = 10 // Number of rows to show per 'page'

  const [report, setReport] = useState()

  useEffect(() => {
    setLoading(true)
    Api.get(`adminReports/donorContribution/${type}/${id}`)
      .then((res) => {
        const sortedData = sortByDate(res?.results, 'PaymentDate')
        setReport(sortedData)
        setValue(sortedData)
        setCurrentData(sortedData.slice(0, pageSize))

        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  const columns = [
    {
      title: 'Donor Name',
      dataIndex: 'DonorName',
      key: 'DonorName',
      render: (DonorName, donation) => {
        let name = DonorName
        if (!name) {
          if (donation.DonorFirstName && donation.DonorLastName) {
            name = `${donation.DonorFirstName} ${donation.DonorLastName}`
          } else if (donation.DonorFirstName) {
            name = donation.DonorFirstName
          } else {
            name = '-'
          }
        }
        return name
      },
    },
    {
      title: 'Donor Email',
      dataIndex: 'DonorEmail',
      key: 'DonorEmail',
    },
    {
      title: 'Donor Phone Number',
      dataIndex: 'DonorPhoneNumber',
      key: 'DonorPhoneNumber',
    },
    {
      title: 'Donor Zip Code',
      dataIndex: 'DonorZipCode',
      key: 'DonorZipCode',
    },
    {
      title: 'Thank You Email Sent',
      dataIndex: 'PaymentDate',
      key: 'PaymentDate',
      render: (text) => moment(text).format('dddd[,] DD/MM/YYYY [at] hh:mm A'),
    },
    {
      title: 'Thank You SMS Sent',
      dataIndex: 'PaymentDate',
      key: 'PaymentDate',
      render: (text) => moment(text).format('dddd[,] DD/MM/YYYY [at] hh:mm A'),
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      render: (text) => formatAmount(text),
    },
    // Add more columns as needed
  ]

  const handleScroll = (e) => {
    const { target } = e
    if (
      target.scrollHeight - target.scrollTop === target.clientHeight &&
      !loadingPagination
    ) {
      setLoading(true)
      setTimeout(() => {
        const nextItems = reportData.slice(
          currentData.length,
          currentData.length + pageSize,
        )
        setCurrentData(currentData.concat(nextItems))
        setLoadingPagination(false)
      }, 500) // Simulate a network request delay
    }
  }
  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      {report && report.length > 0 ? (
        <>
          <>
            <div className="t-flex t-items-center t-mb-8">
              <h1 className="t-font-semibold t-mx-2">
                Total Number of Donations{' '}
              </h1>
              <p className="t-font-semibold">{report.length}</p>
            </div>
            <div>
              <>
                <div
                  onScroll={handleScroll}
                  style={{ overflowY: 'auto', height: '400px' }}
                >
                  {' '}
                  {/* Adjust height as needed */}
                  <Table
                    dataSource={report}
                    columns={columns}
                    rowKey={(record) => record.id}
                    // components={components}
                    scroll={{ x: true }} // Enables horizontal scrolling
                    pagination={false} // Disable default pagination
                    loading={loading}
                  />
                  {loadingPagination && <p>Loading more...</p>}
                </div>
              </>
            </div>
          </>
        </>
      ) : (
        <div>No Data Found</div>
      )}
    </Skeleton>
  )
}

export default DonorContributionsReport

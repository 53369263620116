import React, { useState, useEffect } from 'react'
import { Card, Descriptions, message, Popconfirm, Tooltip } from 'antd'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { config } from 'configs'
import { fetchUserDetail, logout, sendEmail } from 'store/authSlice/authActions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

const MyAccount = () => {
  const { user } = useAuthSelector()
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const checkUser = async (userId) => {
      if (user.id !== userId) {
        const move = () => {
          history.push('/')
        }
        dispatch(logout(move))
      }
    }
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (Object.entries(params).length > 0) {
      if (params.userId) {
        checkUser(params.userId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search, user])

  useEffect(() => {
    dispatch(fetchUserDetail())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onConfirm = async () => {
    setLoading(true)
    try {
      await sendEmail(user?.email, `${user.firstname} ${user.lastname}`)
      message.success('Email Sent Successfully')
      setLoading(false)
    } catch (error) {
      message.error('Error sending verification email')
      setLoading(false)
    }
  }

  const formatPhoneNumber = (Number) => {
    const PhoneNumber = Number.slice(-10)
    const code = Number.slice(0, -10)
    const firstThree = PhoneNumber.substring(0, 3)
    const secondThree = PhoneNumber.substring(3, 6)
    const lastFour = PhoneNumber.substring(6, PhoneNumber.length)
    const phone = `${code} (${firstThree}) ${secondThree}-${lastFour}`

    return phone
  }

  return (
    <Card
      title={[
        <>
          <h4 className="mb-0">My Account</h4>
          <p className="font-regular">Account information details</p>
        </>,
      ]}
      className="t-mb-5"
    >
      <div style={{ minHeight: 'calc(100vh - 320px)' }}>
        <Descriptions
          size="middle"
          labelStyle={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: config.colors.primary,
          }}
          contentStyle={{
            fontSize: '1rem',
            fontWeight: 'normal',
            color: 'black',
          }}
        >
          <Descriptions.Item label="First Name" span={3}>
            {user?.firstname}
          </Descriptions.Item>
          <Descriptions.Item label="Last Name" span={3}>
            {user?.lastname}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number" span={3}>
            {user?.phone && formatPhoneNumber(user?.phone)}
          </Descriptions.Item>
          <Descriptions.Item label="Email" span={3}>
            {user?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Email Verified" span={3}>
            {user?.email_verified ? (
              <AiFillCheckCircle
                fontSize="1.5rem"
                className="t-text-green-600"
              />
            ) : (
              <Popconfirm
                title="You want to verify your email？"
                okText="Send Verifcation Email"
                cancelText="No"
                loading={loading}
                onConfirm={onConfirm}
              >
                <Tooltip title="Click to Verify">
                  <AiFillCloseCircle
                    fontSize="1.5rem"
                    className="t-text-red-600 t-cursor-pointer"
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number Verified" span={3}>
            {user?.phone_number_verified ? (
              <AiFillCheckCircle
                fontSize="1.5rem"
                className="t-text-green-600"
              />
            ) : (
              <AiFillCloseCircle fontSize="1.5rem" className="t-text-red-600" />
            )}
          </Descriptions.Item>
        </Descriptions>
        <div className="t-space-x-4 t-mt-20">
          <h1 className="t-text-md">
            Do you have any feedback for the platform?{' '}
            <span
              className="t-text-secondary-100 t-underline t-font-bold t-cursor-pointer"
              onClick={() => history.push('/settings/contact-us')}
            >
              Click Here
            </span>
          </h1>
        </div>
      </div>
    </Card>
  )
}

export { MyAccount }

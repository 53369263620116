import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Dropdown, Menu, Skeleton, Tag, Tooltip } from 'antd'
import { IoIosArrowDropdownCircle } from 'react-icons/io'
import { useDispatch } from 'react-redux'
import { timeInAgo } from 'utils'
// import { GrView } from 'react-icons/gr'
// import { useHistory } from 'react-router-dom'
import {
  getBusinessServiceRequests,
  changeBusinessServiceRequestStatus,
  createCounterOfferServiceRequest,
} from 'store/businessSlice/businessActions'
import { useBusinessSelector } from 'store/businessSlice/businessReducer'
import { CounterOfferForm } from './CounterOfferForm'

const MyBusinessServiceRequests = () => {
  const dispatch = useDispatch()
  // const history = useHistory()
  const [requestId, setRequestId] = useState()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)

  const { loading, businessServiceRequests } = useBusinessSelector()

  useEffect(() => {
    dispatch(getBusinessServiceRequests())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onOfferClick = (Id) => {
    setRequestId(Id)
    setIsModalVisible(true)
    // dispatch(changeBusinessServiceRequestStatus(Id, 'Offered'))
  }

  const actions = () => {
    setRequestId()
    setIsModalVisible(false)
    setModalLoading(false)
  }

  const onOfferSubmit = (Price, OfferNotes) => {
    dispatch(
      createCounterOfferServiceRequest(
        requestId,
        Price,
        OfferNotes,
        setModalLoading,
        actions,
      ),
    )
  }
  // const onAcceptClick = (Id) => {
  //   dispatch(changeBusinessServiceRequestStatus(Id, 'Accepted'))
  // }

  const onRejectClick = (Id) => {
    dispatch(changeBusinessServiceRequestStatus(Id, 'Rejected'))
  }

  // const onViewProgramClick = (programId) => {
  //   dispatch(programActions.setProgram(null))
  //   history.push(`/programs/${programId}`)
  // }

  const customMenu = (Id, isPending) => (
    <>
      {isPending ? (
        <Menu>
          <Menu.Item id="counterOffer" key={1} onClick={() => onOfferClick(Id)}>
            Offer Quote
          </Menu.Item>
          <Menu.Item
            id="rejectInvite"
            key={3}
            onClick={() => onRejectClick(Id)}
          >
            Reject
          </Menu.Item>
        </Menu>
      ) : (
        <></>
      )}
    </>
  )

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0 t-text-2xl">My Business Service Requests</h6>
          <h6 className="font-regular">
            You have recieved following services requests
          </h6>
        </>,
      ]}
    >
      <div style={{ minHeight: 'calc(100vh - 250px)' }}>
        <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
          <Col span={24}>
            {businessServiceRequests.length === 0 && (
              <Card className="t-h-96 t-w-full t-flex t-justify-center t-items-center">
                <div>No Service</div>
              </Card>
            )}
            <div id="presenterEventList">
              {businessServiceRequests.map((item, index) => (
                <Card key={index} className="w-full t-my-2 t-p-2">
                  <Row gutter={[24, 0]} className="t-flex t-justify-between">
                    <div className="font-bold t-flex t-space-x-4">
                      <div>
                        You are requested for following service request{' '}
                        <span className="t-italic t-text-secondary-100">
                          {item?.Title}{' '}
                        </span>
                        by {item?.UserName ? item?.UserName : 'No Name Found'}
                      </div>
                      <Tooltip title="Request Status">
                        {item?.Status === 'Accepted' && (
                          <Tag color="green" className="font-bold">
                            Accepted
                          </Tag>
                        )}
                        {item?.Status === 'Offered' && (
                          <Tag color="blue" className="font-bold">
                            Offered
                          </Tag>
                        )}
                        {item?.Status === 'Rejected' && (
                          <Tag color="red" className="font-bold">
                            Rejected
                          </Tag>
                        )}
                        {(item?.Status === 'Pending' || !item?.Status) && (
                          <Tag color="gray" className="font-bold">
                            Pending
                          </Tag>
                        )}
                      </Tooltip>
                    </div>

                    <Dropdown
                      overlay={customMenu(item?.Id, item?.Status === 'Pending')}
                    >
                      {item?.Status === 'Pending' ? (
                        <IoIosArrowDropdownCircle
                          id="inviteMenu"
                          fontSize="1.5rem"
                          className="t-text-secondary-100 t-cursor-pointer"
                        />
                      ) : (
                        <>
                          {/* <Tooltip title="View">
                            <GrView
                              id="viewEvent"
                              fontSize="1.2rem"
                              className="t-text-secondary-100 t-cursor-pointer"
                              // onClick={() => onViewProgramClick(item.id)}
                            />
                          </Tooltip> */}
                        </>
                      )}
                    </Dropdown>
                  </Row>
                  <Row gutter={[24, 0]}>
                    <Tooltip title="Start Date">
                      {item?.StartDate && timeInAgo(item.StartDate)}
                    </Tooltip>
                  </Row>
                </Card>
              ))}
            </div>
          </Col>
        </Skeleton>
      </div>
      <CounterOfferForm
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        loading={modalLoading}
        submit={(Price, OfferNotes) => onOfferSubmit(Price, OfferNotes)}
      />
    </Card>
  )
}

export { MyBusinessServiceRequests }

import { Button, Col, Divider, Form, Input, Row } from 'antd'
import React, { useState } from 'react'
import { messageFormValidation } from 'validations/messageForm'

const MessageForm = ({ submit }) => {
  const [messageForm] = Form.useForm()
  const [type, setType] = useState('Email')

  const onFinish = (values) => {
    const data = { ...values, type }
    messageForm.resetFields()
    submit(data)
  }

  return (
    <Form form={messageForm} layout="vertical" key={0} onFinish={onFinish}>
      <Row className="t-p-1">
        <Col span={24}>
          <Form.Item
            name="subject"
            label="Subject"
            colon={false}
            rules={[messageFormValidation]}
          >
            <Input placeholder="Enter Subject" />
          </Form.Item>
          <Form.Item
            name="message"
            label="Message"
            colon={false}
            rules={[messageFormValidation]}
          >
            <Input.TextArea
              placeholder="Enter Message ..."
              autoSize={{ minRows: 3, maxRows: 3 }}
            />
          </Form.Item>
          {/* <Form.Item
            name="email"
            label="Return Email"
            colon={false}
            rules={[messageFormValidation]}
          >
            <Input placeholder="Enter Email" />
          </Form.Item> */}
        </Col>
        <Divider />
        <Col span={24} className="text-right">
          <div className="t-flex t-justify-end t-items-center t-space-x-2">
            <Button htmlType="submit" onClick={() => setType('Sms')}>
              Send SMS
            </Button>
            <Button htmlType="submit" onClick={() => setType('Email')}>
              Send Email
            </Button>
          </div>
          {/* <Button type="primary" className="px-25" htmlType="submit">
            Send
          </Button> */}
        </Col>
      </Row>
    </Form>
  )
}

export { MessageForm }

import React, { useState } from 'react'
import {
  message,
  Popconfirm,
  Skeleton,
  Space,
  Spin,
  Table,
  Tooltip,
} from 'antd'
import { MdDelete, MdInfoOutline } from 'react-icons/md'
import { FaBan } from 'react-icons/fa'
import Api, { makeRequest } from 'api'
import { useHistory } from 'react-router-dom'
import { ImProfile } from 'react-icons/im'
import { AiOutlinePauseCircle, AiOutlinePlayCircle } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { changeBusinessRequestStatus } from 'store/businessSlice/businessActions'
import { config } from 'configs'
import { BusinessInvitationList } from './BusinessInvitationList'

const BusinessEntitiesList = ({
  loading,
  data,
  getData,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [selected, setSelected] = useState(null)
  const [sendingReminder, setSendingReminder] = useState(false)
  const [sendingReminderToList, setSendingReminderToList] = useState(false)

  const formateData = (values) => {
    const formatedData = values?.map((item) => ({
      ...item,
      key: item.Id,
    }))
    return formatedData || []
  }

  const onDelete = (Id) => {
    Api.delete(`/businessListings/removeBusinessListing/${Id}`)
      .then(() => {
        message.success('Deleted successfully')
        getData()
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
      })
  }

  const onBan = (Id) => {
    Api.put(`/businessListings/banBusinessListing`, { Id })
      .then(() => {
        message.success('Banned successfully')
        getData()
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
      })
  }

  const onPauseClick = (Id) => {
    dispatch(changeBusinessRequestStatus(Id, 'Paused', getData))
  }

  const onUnpauseClick = (Id) => {
    dispatch(changeBusinessRequestStatus(Id, 'Approved', getData))
  }

  const onSendAnotherReminder = (Id) => {
    setSelected(Id)
    setSendingReminder(true)
    makeRequest('get', `/component/business/send-association-reminder/${Id}`)
      .then((res) => {
        message.success(res?.message || 'Reminder sent successfully')
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
      })
      .finally(() => {
        setSendingReminder(false)
        setSelected(null)
      })
  }

  const columns = [
    {
      title: 'Business Name',
      dataIndex: 'BusinessName',
      className: 'ant-table-row-cell-break-word',
    },
    // {
    //   title: 'Listing Type',
    //   dataIndex: 'Type',
    //   className: 'ant-table-row-cell-break-word',
    // },
    {
      title: 'Status',
      dataIndex: 'Status',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Member Dues',
      className: 'ant-table-row-cell-break-word',
      render: (_, { PaymentStatus }) => (
        <>{PaymentStatus === 'Completed' ? 'Paid' : 'Not Paid'}</>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, { Id, BusinessId, Status, Initiator }) => (
        <Space size="middle">
          {Initiator === 'Organization' &&
          (Status === 'Invited' || Status === 'Rejected') ? (
            <>
              {Status === 'Invited' && (
                <Tooltip title="Send Email Reminder">
                  {sendingReminder && selected === Id ? (
                    <Spin />
                  ) : (
                    <MdInfoOutline
                      fontSize="1.3rem"
                      id="send-reminder"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() => onSendAnotherReminder(Id)}
                    />
                  )}
                </Tooltip>
              )}
              <Tooltip title="Remove Business Invitation">
                <Popconfirm
                  title="Are you sure you want to remove this business invitaion?"
                  onConfirm={() => onDelete(Id)}
                >
                  <MdDelete
                    fontSize="1.3rem"
                    className="t-text-secondary-100 t-cursor-pointer"
                  />
                </Popconfirm>
              </Tooltip>
            </>
          ) : (
            <>
              {Status === 'Paused' ? (
                <Tooltip title="Unpause Business">
                  <AiOutlinePlayCircle
                    fontSize="1.4rem"
                    className="t-text-secondary-100 t-cursor-pointer"
                    onClick={() => onUnpauseClick(Id)}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Pause Business">
                  <AiOutlinePauseCircle
                    fontSize="1.4rem"
                    className="t-text-secondary-100 t-cursor-pointer"
                    onClick={() => onPauseClick(Id)}
                  />
                </Tooltip>
              )}
              <Tooltip title="Ban Business">
                <Popconfirm
                  title="Are you sure you want to ban this business?"
                  onConfirm={() => onBan(Id)}
                >
                  <FaBan
                    fontSize="1.2rem"
                    className="t-text-secondary-100 t-cursor-pointer"
                  />
                </Popconfirm>
              </Tooltip>
              <Tooltip title="Remove Business">
                <Popconfirm
                  title="Are you sure you want to remove this business?"
                  onConfirm={() => onDelete(Id)}
                >
                  <MdDelete
                    fontSize="1.3rem"
                    className="t-text-secondary-100 t-cursor-pointer"
                  />
                </Popconfirm>
              </Tooltip>
              <Tooltip title="View Profile">
                <ImProfile
                  fontSize="1.2rem"
                  className="t-text-secondary-100 t-cursor-pointer"
                  onClick={() => history.push(`/businesses/${BusinessId}`)}
                />
              </Tooltip>
            </>
          )}
        </Space>
      ),
    },
  ]

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const onSendReminderToSelectedList = () => {
    const list = data
      .filter(
        (item) =>
          selectedRowKeys.includes(item.Id) && item?.Status === 'Invited',
      )
      .map((item) => ({
        Id: item.Id,
      }))
    setSendingReminderToList(true)
    makeRequest(
      'post',
      `/component/business/send-association-reminder-to-list`,
      {},
      { list },
    )
      .then((res) => {
        message.success(res?.message || 'Reminder sent successfully')
        setSendingReminderToList(false)
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
        setSendingReminderToList(false)
      })
  }

  return (
    <Skeleton loading={loading?.get} active paragraph={{ rows: 12 }}>
      <div className="t-flex t-justify-end t-items-center t-space-x-2">
        {selectedRowKeys.length > 0 && (
          <Tooltip title="Send Reminder To Selected List">
            {sendingReminderToList ? (
              <Spin />
            ) : (
              <MdInfoOutline
                fontSize="1.7rem"
                id="download"
                className="t-text-secondary-100 t-cursor-pointer"
                onClick={() => onSendReminderToSelectedList()}
              />
            )}
          </Tooltip>
        )}
        <BusinessInvitationList getAssociation={getData} />
      </div>
      <Table
        tableLayout="auto"
        scroll={{ x: 500 }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={formateData(data)}
        bordered={false}
        pagination={false}
      />
    </Skeleton>
  )
}

export { BusinessEntitiesList }

import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: false,
  auction: null,
  auctions: [],
  allAuctions: [],
  inactive: [],
}

export const auctionSlice = createSlice({
  name: 'auction',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = { ...state, loading: payload }
      return alteredState
    },
    setAuction: (state, { payload }) => {
      const alteredState = { ...state, auction: payload }
      return alteredState
    },
    setAuctions: (state, { payload }) => {
      const filteredAuctions = payload.filter((item) => item.ActiveInd === 1)
      const inactiveAuctions = payload.filter((item) => item.ActiveInd === 0)
      const alteredState = {
        ...state,
        auctions: filteredAuctions,
        inactive: inactiveAuctions,
      }
      return alteredState
    },
    setAllAuctions: (state, { payload }) => {
      const filteredAuctions = payload.filter((item) => item.ActiveInd === 1)
      const alteredState = {
        ...state,
        allAuctions: filteredAuctions,
      }
      return alteredState
    },
    reset: () => initialState,
  },
})

export const useAuctionSelector = () => useSelector((state) => state.auction)
export const auctionActions = auctionSlice.actions
export const auctionReducer = auctionSlice.reducer

import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: false,
  service: null,
  services: [],
  organizationServices: [],
  allServices: [],
  inactive: [],
  registeredUsers: [],
  feedbackByUsers: [],
  subscriberPaymentInfo: {
    stripe: {
      isConnected: false,
      stripeId: null,
    },
    paypal: {
      isConnected: false,
      paypalId: null,
    },
    markup: 1,
  },
}

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = { ...state, loading: payload }
      return alteredState
    },
    setService: (state, { payload }) => {
      const alteredState = { ...state, service: payload }
      return alteredState
    },
    setServices: (state, { payload }) => {
      const activeServices = payload.filter((item) => item.ActiveInd === 1)
      const alteredState = {
        ...state,
        services: activeServices,
      }
      return alteredState
    },
    setOrganizationServices: (state, { payload }) => {
      const filteredServices = payload.filter((item) => item.ActiveInd === 1)
      const inactiveServices = payload.filter((item) => item.ActiveInd === 0)
      const alteredState = {
        ...state,
        organizationServices: filteredServices,
        inactive: inactiveServices,
      }
      return alteredState
    },
    setAllServices: (state, { payload }) => {
      const activeServices = payload.filter((item) => item.ActiveInd === 1)

      const alteredState = {
        ...state,
        allServices: activeServices,
      }
      return alteredState
    },
    setRegisteredUsers: (state, { payload }) => {
      const alteredState = { ...state, registeredUsers: payload }
      return alteredState
    },
    setFeedbackByUsers: (state, { payload }) => {
      const alteredState = { ...state, feedbackByVolunteers: payload }
      return alteredState
    },
    setSubscriberStripeInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        subscriberPaymentInfo: {
          ...state.subscriberPaymentInfo,
          stripe: {
            isConnected: payload.isConnected,
            stripeId: payload.stripeId,
          },
        },
      }
      return alteredState
    },
    setSubscriberPaypalInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        subscriberPaymentInfo: {
          ...state.subscriberPaymentInfo,
          paypal: {
            isConnected: payload.isConnected,
            paypalId: payload.paypalId,
          },
        },
      }
      return alteredState
    },
    setSubscriberMarkupInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        subscriberPaymentInfo: {
          ...state.subscriberPaymentInfo,
          markup: payload,
        },
      }
      return alteredState
    },
    reset: () => initialState,
  },
})

export const useServiceSelector = () => useSelector((state) => state.service)
export const serviceActions = serviceSlice.actions
export const serviceReducer = serviceSlice.reducer

import React, { useEffect, useState, useCallback } from 'react'
import {
  Card,
  Skeleton,
  Tooltip,
  message,
  Row,
  Col,
  Typography,
  Space,
  Table,
} from 'antd'
import { MdOutlineSimCardDownload } from 'react-icons/md'
// import { SearchOutlined } from '@ant-design/icons'
// import { searchCommunityMasterListRows } from 'utils'
import Api from 'api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { useAuthSelector } from 'store/authSlice/authReducer'
import TableSkeleton from '../../../../../../../components/common/skeleton/tableSkeleton'

const {
  downloadCSV,
  formateDateTimeToLocal,
} = require('../../../../../../../utils')

const { Title, Text } = Typography

const YTDEarningReport = () => {
  let GrandTotalEarning

  const history = useHistory()
  const { organization } = useAuthSelector()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [activeCard, setActiveCard] = useState(null) // New state for tracking active card
  const [tableData, setTableData] = useState([]) // State for the table data

  // const csvColumns = [
  //   'Grand Total Earnings',
  //   'Donations',
  //   'Programs & Services',
  //   'Rental Earnings',
  // ]

  const csvColumnsListMapping = {
    DonorName: 'Donor Name',
    DonorEmail: 'Donor Email',
    DonorPhoneNumber: 'Donor Phone Number',
    // LastDonationDateFormatted: 'Last Donation Date',
    GrossAmount: 'Gross	',
    NetAmount: 'Net',
    LastDonationDate: 'Date',
    LastDonationTime: 'Time',
  }

  const csvColumnsList = [
    'DonorName',
    'DonorEmail',
    'DonorPhoneNumber',
    'GrossAmount',
    'NetAmount',
    'LastDonationDate',
    'LastDonationTime',
  ]
  // Table columns definition
  const columns = [
    {
      title: 'Donor Name',
      dataIndex: 'DonorName',
      key: 'DonorName',
    },
    {
      title: 'Donor Email',
      dataIndex: 'DonorEmail',
      key: 'DonorEmail',
    },
    {
      title: 'Donor Phone Number',
      dataIndex: 'DonorPhoneNumber',
      key: 'DonorPhoneNumber',
    },
    {
      title: 'Gross',
      dataIndex: 'GrossAmount',
      key: 'GrossAmount',
      className: 'ant-table-row-cell-break-word text-right align-right',
      align: 'center',
    },
    {
      title: 'Net',
      dataIndex: 'NetAmount',
      key: 'NetAmount',
      className: 'ant-table-row-cell-break-word text-right align-right',
      align: 'center',
    },
    {
      title: 'Last Donation',
      dataIndex: 'LastDonationDates',
      key: 'LastDonationDates',
      render: (time) => formateDateTimeToLocal(time, 'datetime'),
      sorter: (a, b) => {
        const dateA = new Date(a.LastDonationDates)
        const dateB = new Date(b.LastDonationDates)
        return dateB - dateA
      },
      sortDirections: ['descend', 'ascend'],
    },
  ]

  const getData = () => {
    Api.get(`reports/ytd-earning/${organization?.id}`)
      .then((res) => {
        setData(res.response)
        setLoading(false)
        handleCardClick('Donations')
        processTableData('Donations', res.response)
      })
      .catch((error) => {
        console.log('error', error)
        message.error(
          error?.exceptionMessage || 'An error occured in fetching report',
        )
        setLoading(false)
      })
  }

  const processTableData = useCallback(
    (key, newData) => {
      const selectedData = newData
        ? newData?.data?.find((d) => d.name === key)?.value || {}
        : data?.data?.find((d) => d.name === key)?.value || {}

      const processedData = Object.values(selectedData).map((item, index) => ({
        key: index,
        GrossAmount: `$${new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        })
          .format(item.GrossAmount)
          .slice(1)}`,
        NetAmount: `$${new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        })
          .format(item.NetAmount)
          .slice(1)}`,
        DonorName: item.DonorName,
        DonorEmail: item.DonorEmail,
        DonorPhoneNumber: item.DonorPhoneNumber,
        LastDonationDates: item.LastDonationDate,
        LastDonationDate: formateDateTimeToLocal(item.LastDonationDate, 'date'),
        LastDonationTime: formateDateTimeToLocal(item.LastDonationDate, 'time'),
      }))
      setTableData(processedData)
    },
    [data.data],
  )

  const handleCardClick = (key) => {
    setActiveCard(key)
    processTableData(key)
  }

  const cards = Object.entries(data).reduce((acc, [key, value]) => {
    const isActive = key === activeCard // Check if the card is active

    // Conditional styling for the card
    const cardStyle = isActive
      ? { boxShadow: '0 4px 8px 0 rgba(20, 177, 224, 1)' }
      : {}

    if (key !== 'Grand Total Earnings' && key !== 'data') {
      acc.push(
        <Col xs={24} sm={12} md={8} key={key}>
          <Card
            title={key}
            bordered={false}
            style={cardStyle}
            onClick={() => handleCardClick(key)}
          >
            ${value.toLocaleString()}
          </Card>
        </Col>,
      )
    } else if (key === 'Grand Total Earnings') {
      GrandTotalEarning = (
        <div>
          <Space direction="vertical">
            <Title level={4}>
              Grand Total Earnings:{' '}
              <span style={{ marginLeft: 10 }}>
                {' '}
                <Text type="success">
                  {' '}
                  {parseFloat(value).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                  })}
                </Text>
              </span>
            </Title>
          </Space>
        </div>
      )
    }
    return acc
  }, [])

  // Function to process and set table data

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Card
        className="header-solid"
        bordered={false}
        title={
          <div className="t-flex t-flex-wrap t-justify-between">
            <div className="t-flex t-space-x-4">
              <IoMdArrowRoundBack
                fontSize="1.5rem"
                className="t-cursor-pointer t-min-w-[1.5rem]"
                id="back"
                onClick={() => history.push('/reports')}
              />
              <h5 className="font-semibold">YTD Earnings Report</h5>
            </div>
            <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
              <Tooltip title="Download">
                <MdOutlineSimCardDownload
                  fontSize="1.7rem"
                  id="download"
                  className="t-text-secondary-100 t-cursor-pointer"
                  onClick={() =>
                    downloadCSV(
                      tableData,
                      csvColumnsList,
                      csvColumnsListMapping,
                      `YTD ${activeCard} List`,
                    )
                  }
                />
              </Tooltip>
            </div>
          </div>
        }
      >
        {GrandTotalEarning}
        <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
          <Row gutter={[16, 16]}>{cards}</Row>
        </Skeleton>
      </Card>
      <div style={{ marginTop: 20 }}>
        <Card
          className="header-solid"
          bordered={false}
          title={
            <div className="t-flex t-flex-wrap t-justify-between">
              <div className="t-flex t-space-x-4">
                <IoMdArrowRoundBack
                  fontSize="1.5rem"
                  className="t-cursor-pointer t-min-w-[1.5rem]"
                  id="back"
                  onClick={() => history.push('/reports')}
                />
                <h5 className="font-semibold">YTD {activeCard} List </h5>
              </div>
              <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
                <Tooltip title="Download">
                  <MdOutlineSimCardDownload
                    fontSize="1.7rem"
                    id="download"
                    className="t-text-secondary-100 t-cursor-pointer"
                    onClick={() =>
                      downloadCSV(
                        tableData,
                        csvColumnsList,
                        csvColumnsListMapping,
                        `YTD ${activeCard} List`,
                      )
                    }
                  />
                </Tooltip>
              </div>
            </div>
          }
        >
          {loading ? (
            <TableSkeleton />
          ) : (
            <Table
              dataSource={tableData}
              columns={columns}
              pagination={false}
              className="td-right"
            />
          )}
        </Card>
      </div>
    </>
  )
}

export { YTDEarningReport }

import React from 'react'
import { Card } from 'antd'
import { ReportList } from './components'

const Reports = () => (
  <Card
    bordered={false}
    className="header-solid mb-24"
    title={
      <div className="t-flex t-flex-wrap t-justify-between">
        <div>
          <h6 className="font-semibold t-text-2xl">Reports</h6>
        </div>
      </div>
    }
  >
    <div style={{ minHeight: 'calc(100vh - 205px)' }}>
      <ReportList />
    </div>
  </Card>
)

export { Reports }

import React, { useEffect, useState } from 'react'
import {
  Input,
  Button,
  Card,
  Form,
  Row,
  Col,
  Select,
  Switch,
  Divider,
  message,
} from 'antd'
import { serviceValidation } from 'validations'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useServiceSelector } from 'store/serviceSlice/serviceReducer'
import {
  addService,
  getServiceDetail,
  updateService,
} from 'store/serviceSlice/serviceActions'
import { config } from 'configs'
import Api from 'api'
import { AmountInput, ImageUploader } from 'components/elements'
import { AutoCompleteAddress } from 'components/common'
import { useOrganizationProfileSelector } from 'store/organizationProfilesSlice/OrganizationProfilesReducer'
import { getProfileList } from 'store/organizationProfilesSlice/OrganizationProfilesActions'

const locationTypeOptions = [
  {
    id: 1,
    label: 'Onsite',
    value: 'Organization Address',
  },
  {
    id: 2,
    label: 'Online',
    value: 'Online',
  },
  {
    id: 3,
    label: 'Hybrid',
    value: 'Hybrid',
  },
]

const serviceSubTypeOptions = [
  {
    id: 1,
    label: 'Use Template - Marriage',
    value: 'Marriage',
  },
  {
    id: 2,
    label: 'Use Template - Funeral',
    value: 'Funeral',
  },
  {
    id: 2,
    label: 'Use Template - Zakaat',
    value: 'Zakaat',
  },
  {
    id: 3,
    label: 'Custom',
    value: 'Custom',
  },
]

const initialValues = {
  Title: '',
  Description: '',
  Requirements: '',
  ProgramFees: '',
  // DeliveryLocation: "",
  AllowedRegistration: '',
  Flyer: null,
  ShowOnLandingPage: false,

  IncludeProcessingFee: false,
  AllowRecurringPayments: false,
  PaymentCategoryAdded: false,
  PaymentCategories: [],
}

const CreateService = () => {
  const { user, organization } = useAuthSelector()
  const { loading } = useServiceSelector()
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const { id } = useParams()
  const [serviceType, setServiceType] = useState()
  const [categories, setCategories] = useState([])
  const { profileList } = useOrganizationProfileSelector()
  const formattedProfileList = profileList?.map((item) => ({
    value: item.ProfileId,
    label: item.ProfileTitle,
  }))

  Form.useWatch('ProgramFees', form)
  Form.useWatch('DeliveryLocation', form)
  Form.useWatch('ServiceType', form)
  Form.useWatch('Flyer', form)
  Form.useWatch('ShowOnLandingPage', form)

  const isHybrid = form.getFieldValue('DeliveryLocation') === 'Hybrid'
  const isMarriage = form.getFieldValue('ServiceType') === 'Marriage'
  const isFuneral = form.getFieldValue('ServiceType') === 'Funeral'
  const isZakaat = form.getFieldValue('ServiceType') === 'Zakaat'
  const isCustom = form.getFieldValue('ServiceType') === 'Custom'

  Form.useWatch('IncludeProcessingFee', form)
  const isIncludeProcessingFee = form.getFieldValue('IncludeProcessingFee')
  Form.useWatch('AllowRecurringPayments', form)
  const isAllowRecurringPayments = form.getFieldValue('AllowRecurringPayments')
  Form.useWatch('PaymentCategoryAdded', form)
  const isPaymentCategoryAdded = form.getFieldValue('PaymentCategoryAdded')
  Form.useWatch('PaymentCategories', form)
  const paymentCategoriesValues = Form.useWatch('PaymentCategories', form)
  Form.useWatch('OrganizationProfile', form)

  const getPaymentCategories = () => {
    Api.get(
      `paymentCategories/getOrganizationPaymentCategories/${organization?.id}`,
    )
      .then((res) => {
        const formatedCategories = res?.results
          ?.filter((item) => item.ActiveInd === 1)
          .map((item) => ({
            value: item.Id,
            label: item.Name,
          }))
        setCategories(formatedCategories)
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
      })
  }

  useEffect(() => {
    if (organization?.id) {
      getPaymentCategories()
      dispatch(getProfileList({ organizationId: organization.id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])

  const actions = (type) => {
    setServiceType(type)
  }

  useEffect(() => {
    if (id) {
      dispatch(getServiceDetail(id, form, actions))
    }
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (Object.entries(params).length > 0 && params.duplicate && params.id) {
      dispatch(getServiceDetail(params.id, form))
      history.replace('/settings/create-service')
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isMarriage && !id) {
      form.setFieldValue(
        'Requirements',
        'In order for the Islamic Marriage to take place, the bride needs to have a Wali or Wakeel (Islamic legal representative). This is usually the father, grandfather, brother, paternal uncle, or maternal uncle of the bride. If the bride does not have a Wali or Wakeel that can attend, please contact the imam for more information and type in not assigned in the text—box below.',
      )
      form.setFieldValue(
        'NoteHeading',
        'Application for Nikkah (Islamic Marriage)',
      )
      form.setFieldValue(
        'NoteDescription',
        'If you are looking for Imam to conduct your marriage ceremony, please fill in the below form. A representative will be in contact with you within three business days. Thanks so much for choosing us to provide you with this blessed service!. We does not perform the Nikah (Islamic marriage ceremony) unless the couple provides proof of a court marriage',
      )
      form.setFieldValue('RequestedTimeHelpText', '')
      form.setFieldValue('CeremonyLocationHelpText', '')
      form.setFieldValue('BrideReligionHelpText', '')
      form.setFieldValue('GroomReligionHelpText', '')
      form.setFieldValue('NumberOfAttendeesHelpText', '')
    }

    // eslint-disable-next-line
  }, [isMarriage])

  useEffect(() => {
    if (isFuneral || isCustom) {
      form.setFieldValue('NoteHeading', '')
      form.setFieldValue('NoteDescription', '')
    }
    // eslint-disable-next-line
  }, [isFuneral, isCustom])

  const navigation = () => {
    history.push('/services')
    form.resetFields()
  }

  const onFinish = (values) => {
    let data = {
      Title: values?.Title,
      ServiceType: id ? serviceType : values?.ServiceType,
      DeliveryLocation: values?.DeliveryLocation,
      ProgramFees: values?.ProgramFees,
      Description: values?.Description,
      ShowOnLandingPage: values?.ShowOnLandingPage,
      Flyer: values?.Flyer,
      IncludeProcessingFee: isShowPaymentRelatedOption
        ? values?.IncludeProcessingFee
        : false,
      AllowRecurringPayments: isShowPaymentRelatedOption
        ? values?.AllowRecurringPayments
        : false,
      NumberOfDefaultRecurring: values?.NumberOfDefaultRecurring,
      PaymentCategoryAdded: isShowPaymentRelatedOption
        ? values.PaymentCategoryAdded
        : false,
      PaymentCategories: values.PaymentCategories,
      OrganizationProfile: values.OrganizationProfile,
      Requirements: values?.Requirements,
      Username: user.id,
      OrganizationId: organization.id,
      OrganizationName: organization?.Name,
    }
    if (isMarriage) {
      data = {
        ...data,
        NoteHeading: values.NoteHeading,
        NoteDescription: values.NoteDescription,
        RequestedTimeHelpText: values.RequestedTimeHelpText,
        CeremonyLocationHelpText: values.CeremonyLocationHelpText,
        BrideReligionHelpText: values.BrideReligionHelpText,
        GroomReligionHelpText: values.GroomReligionHelpText,
        NumberOfAttendeesHelpText: values.NumberOfAttendeesHelpText,
      }
    }
    if (values.DeliveryLocation === 'Hybrid') {
      data = {
        ...data,
        // Latitude: location.lat,
        // Longitude: location.lng,
        Address: values.mailingAddress,
        ZipCode: values.zipCode,
        Suite: values.suite,
        City: values.city,
        State: values.state,
        Country: values.country,
      }
    }

    if (id) {
      dispatch(updateService({ ServiceId: id, ...data }, navigation))
    } else {
      dispatch(addService(data, navigation))
    }
  }

  const isPaid = parseFloat(form.getFieldValue('ProgramFees')) > 0
  const isShowPaymentRelatedOption = !isZakaat && isPaid
  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0">{id ? 'Update Service' : 'Add Service'}</h6>
          <p className="font-regular">Enter Information</p>
        </>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        key={0}
        onFinish={onFinish}
        name="organization"
        initialValues={initialValues}
        scrollToFirstError
      >
        <Row gutter={[24, 0]}>
          <Col xl={id ? 24 : 12} md={id ? 24 : 12} xs={24} sm={24}>
            <Form.Item
              name="Title"
              label="Title"
              colon={false}
              rules={[serviceValidation]}
            >
              <Input placeholder="Enter Title" />
            </Form.Item>
          </Col>
          {!id && (
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="ServiceType"
                label="Service Template"
                colon={false}
                rules={[serviceValidation]}
              >
                <Select
                  placeholder="Select Service Template"
                  style={{ width: '100%' }}
                >
                  {serviceSubTypeOptions.map((item) => (
                    <Select.Option key={item.id} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {!isZakaat && (
            <>
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="ProgramFees"
                  label="Service Dues"
                  colon={false}
                  rules={[serviceValidation]}
                >
                  <AmountInput
                    placeholder="Enter Service Dues"
                    value={form.getFieldValue('ProgramFees')}
                    onChange={(value) =>
                      form.setFieldsValue({ ProgramFees: value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="DeliveryLocation"
                  label="Delivery Location"
                  colon={false}
                  rules={[serviceValidation]}
                >
                  <Select
                    placeholder="Select Delivery Location"
                    style={{ width: '100%' }}
                  >
                    {locationTypeOptions.map((item) => (
                      <Select.Option key={item.id} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
          {isHybrid && (
            <AutoCompleteAddress
              form={form}
              validation
              isInitiallyValidated={id}
            />
          )}
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="Description"
              label="Description"
              colon={false}
              rules={[serviceValidation]}
            >
              <Input.TextArea
                placeholder="Description"
                autoSize={{ minRows: 2, maxRows: 3 }}
              />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="Requirements"
              label="Requirements"
              colon={false}
              rules={[serviceValidation]}
            >
              <Input.TextArea
                placeholder="Requirements"
                autoSize={{ minRows: 2, maxRows: 3 }}
              />
            </Form.Item>
          </Col>
          {isMarriage && (
            <>
              <Col xl={24} md={24} xs={24} sm={24}>
                <Form.Item
                  name="NoteHeading"
                  label="Note Heading"
                  colon={false}
                  rules={[serviceValidation]}
                >
                  <Input placeholder="Note Heading" />
                </Form.Item>
              </Col>

              <Col xl={24} md={24} xs={24} sm={24}>
                <Form.Item
                  name="NoteDescription"
                  label="Note Description"
                  colon={false}
                  rules={[serviceValidation]}
                >
                  <Input.TextArea
                    placeholder="Add note description"
                    autoSize={{ minRows: 3, maxRows: 3 }}
                  />
                </Form.Item>
              </Col>
              <Divider />
              <Col xl={24} md={24} xs={24} sm={24}>
                <p className="t-font-semibold t-text-md t-text-grey-200">
                  Provide help text for below fields which will be shown against
                  fields when user will be filling the form.
                </p>
              </Col>
              <Divider />
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="RequestedTimeHelpText"
                  label="Requested Time Help Text"
                  colon={false}
                  rules={[serviceValidation]}
                >
                  <Input placeholder="Enter Help Text" />
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="CeremonyLocationHelpText"
                  label="Ceremony Location Help Text"
                  colon={false}
                  rules={[serviceValidation]}
                >
                  <Input placeholder="Enter Help Text" />
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="BrideReligionHelpText"
                  label="Bride Religion Help Text"
                  colon={false}
                  rules={[serviceValidation]}
                >
                  <Input placeholder="Enter Help Text" />
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="GroomReligionHelpText"
                  label="Groom Religion Help Text"
                  colon={false}
                  rules={[serviceValidation]}
                >
                  <Input placeholder="Enter Help Text" />
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="NumberOfAttendeesHelpText"
                  label="Number Of Attendees Help Text"
                  colon={false}
                  rules={[serviceValidation]}
                >
                  <Input placeholder="Enter Help Text" />
                </Form.Item>
              </Col>
              <Divider />
            </>
          )}
          <Col span={24}>
            <Form.Item
              name="ShowOnLandingPage"
              label="Show on Landing Page"
              colon={false}
              rules={[serviceValidation]}
            >
              <Switch checked={form.getFieldValue('ShowOnLandingPage')} />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item name="Flyer" label="Banner Image" colon={false}>
              <ImageUploader
                ratio={2 / 1}
                defaultFile={
                  form.getFieldValue('Flyer') && form.getFieldValue('Flyer')
                }
                onFileChange={(file) =>
                  form.setFieldsValue({
                    Flyer: file?.originFileObj,
                  })
                }
              />
            </Form.Item>
          </Col>

          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="OrganizationProfile"
              label="Alternate Organization Profile"
              colon={false}
            >
              <Select
                allowClear
                style={{
                  width: '100%',
                }}
                placeholder="Select Organization Profile"
                options={formattedProfileList}
              />
            </Form.Item>
          </Col>

          {isShowPaymentRelatedOption && (
            <>
              <Col span={24}>
                <Form.Item
                  name="IncludeProcessingFee"
                  label="Require Payment Of Processing Fees"
                  colon={false}
                  rules={[serviceValidation]}
                >
                  <Switch checked={isIncludeProcessingFee} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="AllowRecurringPayments"
                  label="Allow Recurring Payments"
                  colon={false}
                  rules={[serviceValidation]}
                >
                  <Switch checked={isAllowRecurringPayments} />
                </Form.Item>
              </Col>
              {isAllowRecurringPayments && (
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="NumberOfDefaultRecurring"
                    label="Duration Of Recurring Payments"
                    colon={false}
                    rules={[serviceValidation]}
                  >
                    <Input
                      placeholder="Number of Months"
                      // disabled={isSplit}
                      inputMode="numeric"
                      onChange={(e) =>
                        form.setFieldsValue({
                          NumberOfDefaultRecurring: e.target.value.replace(
                            /\D/g,
                            '',
                          ),
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item
                  name="PaymentCategoryAdded"
                  label="Add Payment Category"
                  colon={false}
                  rules={[serviceValidation]}
                >
                  <Switch checked={isPaymentCategoryAdded} />
                </Form.Item>
              </Col>
              {isPaymentCategoryAdded && (
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="PaymentCategories"
                    label="Payment Categories"
                    colon={false}
                  >
                    <Select
                      mode="multiple"
                      style={{
                        width: '100%',
                      }}
                      placeholder="Select Payment Categories"
                      options={categories}
                      {...(paymentCategoriesValues?.length > 0 && {
                        open: false,
                      })}
                    />
                  </Form.Item>
                </Col>
              )}
            </>
          )}

          <Col span={24} className="text-left t-space-x-2">
            <Button
              loading={loading}
              type="primary"
              className="px-25"
              htmlType="submit"
            >
              {id ? 'Update Service' : 'Add Service'}
            </Button>
            <Button
              type="primary"
              className="px-25"
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export { CreateService }

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Card, Skeleton } from 'antd'
import Api from 'api'
import { formatAmount, formateDate } from 'utils'
import { InfiniteScrollContainer } from 'components/elements'

function PledgeCommittments({ id, setPdfData }) {
  const [loading, setLoading] = useState()
  const itemsFirstLoad = 2
  const [report, setReport] = useState()
  const [current, setCurrent] = useState([])

  useEffect(() => {
    setLoading(true)
    Api.get(`adminReports/getPledgersListByEvent?id=${id}`)
      .then((res) => {
        const data = res.results
        setReport(data)
        setCurrent(data.slice(0, itemsFirstLoad))
        setPdfData(data)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  const TotalAmount = (dataArray) => {
    const total = dataArray.reduce((acc, obj) => acc + Number(obj.Amount), 0)
    return formatAmount(total)
  }

  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      {report && report.length > 0 ? (
        <>
          <div className="t-flex t-items-center t-mb-8">
            <h1 className="t-font-semibold t-mx-2">Total amount of pledges:</h1>
            <p className="t-font-semibold">{TotalAmount(report)}</p>
          </div>
          <div>
            <InfiniteScrollContainer
              data={report}
              current={current}
              setCurrent={setCurrent}
              itemsFirstLoad={itemsFirstLoad}
            >
              <>
                {current.map((item, index) => (
                  <Card
                    className="mb-24 header-solid list-settings-sessions"
                    bordered={false}
                  >
                    <div className="t-space-y-4" key={index}>
                      <div className="t-flex t-items-center t-justify-between">
                        <p className="t-font-semibold">Name</p>
                        {item.DonorName}
                      </div>
                      <div className="t-flex t-items-center t-justify-between">
                        <p className="t-font-semibold">Phone Number</p>
                        {item.DonorPhoneNumber}
                      </div>
                      <div className="t-flex t-items-center t-justify-between">
                        <p className="t-font-semibold">Email</p>
                        {item.DonorEmail}
                      </div>
                      <div className="t-flex t-items-center t-justify-between">
                        <p className="t-font-semibold">Amount pledge</p>
                        {formatAmount(item.Amount)}
                      </div>
                      <div className="t-flex t-items-center t-justify-between">
                        <p className="t-font-semibold">Pledge Date</p>
                        {item.ScheduleDate && formateDate(item.ScheduleDate)}
                      </div>
                      <div className="t-flex t-items-center t-justify-between">
                        <p className="t-font-semibold">Monthly Recurring</p>
                        {item?.IsMonthly ? 'Yes' : 'No'}
                      </div>
                      <div className="t-flex t-items-center t-justify-between">
                        <p className="t-font-semibold">Split Payment</p>
                        {item?.IsSplit ? 'Yes' : 'No'}
                      </div>
                      {item?.PledgeStatus === 'Completed' ? (
                        <div className="t-flex t-items-center t-justify-between">
                          <p className="t-font-semibold">Status</p>
                          Donated Succesfully
                        </div>
                      ) : (
                        <div className="t-flex t-items-center t-justify-between">
                          <p className="t-font-semibold">Reminders Sent</p>
                          Coming Soon
                        </div>
                      )}
                    </div>
                  </Card>
                ))}
              </>
            </InfiniteScrollContainer>
          </div>
        </>
      ) : (
        <div>No Data Found</div>
      )}
    </Skeleton>
  )
}

export default PledgeCommittments

import React, { useEffect, useState } from 'react'
import {
  Skeleton,
  Button,
  Card,
  Col,
  Row,
  Tooltip,
  Checkbox,
  Affix,
  message,
  Tag,
} from 'antd'
import { searchEventCampaignRows } from 'utils'
import { awsconfig } from 'configs'
import { getMyEvents } from 'store/eventSlice/eventActions'
import { useDispatch } from 'react-redux'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { eventActions, useEventSelector } from 'store/eventSlice/eventReducer'
import { useHistory } from 'react-router-dom'
import { ApiOutlined } from '@ant-design/icons'
import { downloadQRCodePDF } from 'store/authSlice/authActions'
// import { convertFile } from 'store/reportSlice/reportActions'

import sample from 'assets/images/sample/sample1.png'

const MyCampaigns = ({ searchText, sortKey }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedIds, setSelectedIds] = useState([])

  const { user, organization } = useAuthSelector()
  const { loading, campaigns } = useEventSelector()

  useEffect(() => {
    dispatch(getMyEvents(sortKey))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortKey])

  const handleCampaignSelected = (event) => {
    dispatch(eventActions.setEvent(null))
    history.push(`campaigns/${event.Id}`)
  }
  // const goToJoinScreen = (e, event) => {
  //   e.preventDefault()
  //   e.stopPropagation()
  //   dispatch(eventActions.setJoinedEventDetails(null))
  //   dispatch(eventActions.setEvent(event))
  //   history.push(`/campaigns/${event?.Id}/join-event`)
  // }

  const goToControlScreen = (e, event) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(eventActions.setJoinedEventDetails(null))
    dispatch(eventActions.setEvent(event))
    history.push(`/campaigns/${event?.Id}/control-screen`)
  }

  // const goToEdit = (e, event) => {
  //   e.preventDefault()
  //   e.stopPropagation()
  //   history.push(`/settings/create-event/${event?.Id}`)
  // }

  const onCheckboxChange = (eventId) => {
    const isAlreadyPresent = selectedIds.find((id) => id === eventId)
    if (isAlreadyPresent) {
      const updatedIds = selectedIds.filter((id) => id !== eventId)
      setSelectedIds(updatedIds)
    } else {
      setSelectedIds([...selectedIds, eventId])
    }
  }

  const type = 'campign'
  const onPrintClick = async () => {
    message.loading('Generating QR code')
    const eventsForPrint = selectedIds.map((item) => ({
      id: item,
      name: campaigns.find((element) => element?.Id === item)?.Title,
      description: campaigns.find((element) => element?.Id === item)?.Details,
      url: `${window.location.origin}/donation/${item}`,
    }))
    const payload = {
      data: eventsForPrint,
      component: 'Campaign',
      Organization: organization?.Name,
      logo: awsconfig.bucketUrl + organization.Logo?.fileName,
    }
    try {
      await downloadQRCodePDF(payload, dispatch)
      message.success('QR code generated!')
      // history.push(`/download/${type}`)
      window.open(`${window.location.origin}/download/${type}`, '_blank')
    } catch (error) {
      console.error('Error generating QR code:', error)
      message.error('Failed to generate QR code. Please try again.')
    }
  }

  const myCampaigns = campaigns.filter(
    (item) => item?.OrganizationId === organization?.id,
  )

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div className="t-flex t-flex-col t-justify-between t-h-full">
        {selectedIds.length > 0 && (
          <Row className="t-w-full t-flex t-justify-end t-p-0 md:t-p-1 -t-mt-6 md:-t-mt-8 t-mb-2 md:t-mb-2">
            <Affix offsetTop={75}>
              <Button
                type="primary"
                shape="round"
                onClick={onPrintClick}
                size="medium"
                className="glass_bg t-text-secondary-100 hover:t-text-white"
              >
                Print QR Codes
              </Button>
            </Affix>
          </Row>
        )}

        <Row gutter={[24, 24]} className="t-h-full">
          {searchEventCampaignRows(myCampaigns, searchText).length === 0 && (
            <div className="t-h-96 t-flex t-justify-center t-items-center t-w-full">
              <div className="t-flex t-flex-col t-items-center t-space-y-2">
                <ApiOutlined
                  style={{ fontSize: '2rem' }}
                  className="t-text-secondary-100"
                />
                <p>No records found</p>
              </div>
            </div>
          )}
          {searchEventCampaignRows(myCampaigns, searchText).map(
            (item, index) => (
              <Col span={24} md={8} xl={8} key={index}>
                <Card
                  bordered
                  hoverable
                  cover={
                    <img
                      alt="example"
                      className="t-w-full t-h-48 t-object-cover t-rounded-md"
                      src={
                        item?.Flyer?.length
                          ? awsconfig.bucketUrl + item?.Flyer[0]?.fileName
                          : item?.Flyer?.fileName
                          ? awsconfig.bucketUrl + item?.Flyer?.fileName
                          : item?.OrganizationBackgroundImage
                          ? awsconfig.bucketUrl +
                            item?.OrganizationBackgroundImage?.fileName
                          : sample
                      }
                    />
                  }
                  onClick={() => handleCampaignSelected(item)}
                >
                  <div className="t-relative t-flex t-justify-end">
                    {item?.EventType === 'Campaign' &&
                      item?.CampaignSubType === 'Auction' && (
                        <Tag
                          color="purple"
                          className="t-absolute t-right-0 -t-top-[0.8rem]"
                        >
                          Auction
                        </Tag>
                      )}
                  </div>
                  <h5 className="t-text-2xl t-text-secondary-100 t-font-bold t-truncate">
                    <Tooltip title={item?.Title}>{item?.Title}</Tooltip>
                  </h5>
                  <div className="t-flex t-justify-between">
                    <div className="card-tag t-font-thin t-text-secondary-100">
                      {item?.OrganizationName}
                    </div>
                  </div>

                  <p className="t-line-clamp-1 t-text-sm">{item?.Details}</p>
                  <div className="t-mt-2 t-space-x-1 t-h-8">
                    <div
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        onCheckboxChange(item?.Id)
                      }}
                    >
                      <Checkbox
                        checked={selectedIds.includes(item?.Id)}
                        className="t-font-bold t-text-secondary-100"
                      >
                        Add to QR code sheet
                      </Checkbox>
                    </div>
                  </div>
                  <div className="t-flex t-justify-end t-space-x-1">
                    {/* <Button
                      type="primary"
                      size="small"
                      className="t-rounded-full"
                      onClick={(e) => goToEdit(e, item)}
                    >
                      Edit
                    </Button> */}
                    {(item?.OrganizationId === organization?.id ||
                      (item?.PresenterEmail === user?.email &&
                        item?.AcceptanceStatus === 'Accepted')) && (
                      <Button
                        type="primary"
                        size="small"
                        className="t-rounded-full"
                        onClick={(e) => goToControlScreen(e, item)}
                      >
                        Control
                      </Button>
                    )}
                    {/* {item.startEvent && (
                      <Button
                        type="primary"
                        size="small"
                        className="t-rounded-full"
                        onClick={(e) => goToJoinScreen(e, item)}
                      >
                        Join
                      </Button>
                    )} */}
                  </div>
                </Card>
              </Col>
            ),
          )}
        </Row>
      </div>
    </Skeleton>
  )
}

export { MyCampaigns }

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Card, Skeleton } from 'antd'
import { CarryOutFilled } from '@ant-design/icons'
import Api from 'api'
import { formatAmount } from 'utils'
import { ShareReport } from 'components/elements'
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'
import { IoMdArrowRoundBack } from 'react-icons/io'

const FYEarningsForecastReport = () => {
  const history = useHistory()
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()
  const [index, setIndex] = useState(1)

  useEffect(() => {
    setLoading(true)
    Api.get('adminReports/fyEarningForcastReport')
      .then((res) => {
        setReport(res.results)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
  }, [])

  return (
    <Card
      id="FYEarningsForecastReport"
      className="mb-24 header-solid list-settings-sessions"
      bordered={false}
      title={[
        <div className="t-flex t-justify-between t-items-center">
          <div className="t-flex t-space-x-4">
            <IoMdArrowRoundBack
              fontSize="1.5rem"
              className="t-cursor-pointer t-min-w-[1.5rem]"
              id="back"
              onClick={() =>
                history.push('/settings/manage-organization/my-reports')
              }
            />
            <h5 className="font-semibold">FY Revenue Forecast Report</h5>
          </div>
          {!loading && report && report.length > 0 && (
            <ShareReport
              reportName="FY Revenue Forecast Report"
              report={report}
            />
          )}
        </div>,
      ]}
    >
      <Skeleton loading={loading} paragraph={{ rows: 12 }}>
        {report && report.length > 0 ? (
          <>
            <div>
              <div className="t-flex t-justify-between t-mb-10">
                <h5 className="font-semibold t-ml-4">
                  {report[index - 1].month}
                </h5>
                <h5 className="font-semibold">
                  {formatAmount(report[index - 1].totalEarning)}
                </h5>
              </div>
              {[
                'event Pledges',
                'event Recurring',
                'campaigns Recurring',
                // 'campaigns Pledges',
              ].map((item, indexVal) => (
                <>
                  {indexVal !== 0 && <hr className="gradient-line my-10" />}
                  <div className="ant-row-flex ant-row-flex-middle">
                    <div
                      className="text-center ant-col"
                      style={{ minWidth: '40px' }}
                    >
                      <CarryOutFilled
                        className="text-gray-6"
                        style={{ fontSize: '18px' }}
                      />
                    </div>
                    <div className="ant-col pl-15">
                      <p className="mb-0 t-capitalize">{item}</p>
                    </div>
                    <div
                      className="ml-auto ant-col ant-col-24 ant-col-md-12"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <span className="ml-5">
                        {indexVal === 0
                          ? formatAmount(report[index - 1].eventPledges)
                          : indexVal === 1
                          ? formatAmount(report[index - 1].eventRecurring)
                          : formatAmount(report[index - 1].campaignsRecurring)}
                      </span>
                    </div>
                  </div>
                </>
              ))}
            </div>
            <div className="t-flex t-justify-center t-items-center t-mt-4 t-space-x-1">
              <AiFillLeftCircle
                fontSize="1.5rem"
                className={`${
                  index > 1
                    ? 't-text-secondary-100 t-cursor-pointer'
                    : 't-opacity-50'
                }`}
                onClick={() => index > 1 && setIndex(index - 1)}
              />
              <AiFillRightCircle
                fontSize="1.5rem"
                className={`${
                  index < report.length
                    ? 't-text-secondary-100 t-cursor-pointer'
                    : 't-opacity-50'
                }`}
                onClick={() => index < report.length && setIndex(index + 1)}
              />
            </div>
          </>
        ) : (
          <div>No Data Found</div>
        )}
      </Skeleton>
    </Card>
  )
}

export { FYEarningsForecastReport }

import React from 'react'
import { Skeleton, Table } from 'antd'

const TableSkeleton = () => {
  // Define the number of columns your table has
  const columns = [
    { title: 'Donor Name', dataIndex: 'DonorName', key: 'DonorName' },
    { title: 'Donor Email', dataIndex: 'DonorEmail', key: 'DonorEmail' },
    { title: 'Event Title', dataIndex: 'EventTitle', key: 'EventTitle' },
    { title: 'Donor Phone', dataIndex: 'DonorPhone', key: 'DonorPhone' },
    {
      title: 'Donated Amount',
      dataIndex: 'donatedAmount',
      key: 'donatedAmount',
    },
    { title: 'Action', key: 'action' },
  ]

  // Generate skeleton rows
  const skeletonData = Array.from({ length: 10 }).map((_, index) => ({
    key: index,
    DonorName: <Skeleton.Input style={{ width: 200 }} active size="small" />,
    DonorEmail: <Skeleton.Input style={{ width: 200 }} active size="small" />,
    EventTitle: <Skeleton.Input style={{ width: 200 }} active size="small" />,
    DonorPhone: <Skeleton.Input style={{ width: 200 }} active size="small" />,
    donatedAmount: (
      <Skeleton.Input style={{ width: 200 }} active size="small" />
    ),
    action: <Skeleton.Button active size="small" />,
  }))

  return (
    <Table columns={columns} dataSource={skeletonData} pagination={false} />
  )
}

export default TableSkeleton

import React from 'react'
import { Button, Col, Divider, Form, Input, Modal, Row } from 'antd'
import * as Yup from 'yup'
import { numberWithCommas } from 'utils'

const { TextArea } = Input

const amountValidation = new RegExp('[0-9]|,')
const schema = Yup.object().shape({
  Price: Yup.string()
    .required('Price is required')
    .matches(amountValidation, 'Invalid cost'),
  OfferNotes: Yup.string()
    .required('Description is required')
    .min(10, 'Description should be atleast 10 character'),
})

const counterOfferValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  Price: '',
  OfferNotes: '',
}
const CounterOfferForm = ({
  isModalVisible,
  setIsModalVisible,
  loading,
  submit,
}) => {
  const [form] = Form.useForm()
  Form.useWatch('Price', form)

  const onFinish = (values) => {
    const { OfferNotes } = values
    const Price = values.Price.replaceAll(/,/g, '')
    form.resetFields()
    submit(Price, OfferNotes)
  }

  const handleAmountChange = (name, value) => {
    const withoutCommas = value.replaceAll(/,/g, '')
    const val = withoutCommas.replace(/\D/g, '')
    form.setFieldsValue({ [name]: numberWithCommas(val) })
  }

  return (
    <Modal
      title="Quote Form"
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="counterOffer"
        scrollToFirstError
        initialValues={initialValues}
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item
              name="Price"
              label="Price"
              colon={false}
              rules={[counterOfferValidation]}
            >
              <Input
                inputMode="numeric"
                prefix="$"
                onChange={(e) => handleAmountChange('Price', e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="OfferNotes"
              label="Notes"
              colon={false}
              rules={[counterOfferValidation]}
            >
              <TextArea
                placeholder="Notes"
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>
          </Col>
          <Divider />
          <Col span={24} className="text-right">
            <Button
              type="primary"
              className="px-25"
              loading={loading}
              htmlType="submit"
            >
              Send Offer
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export { CounterOfferForm }

import React, { useEffect, useState } from 'react'
import { Card, Skeleton, Dropdown, Menu, message } from 'antd'
import { config } from 'configs'
import { formatAmount } from 'utils'
import moment from 'moment'
import { IoIosArrowDropdownCircle } from 'react-icons/io'
import { useDispatch } from 'react-redux'
import Api from 'api/apiv2'
import { InfiniteScrollContainer } from 'components/elements'
import { useFundraiserSelector } from 'store/fundraiserSlice/fundraiserReducer'
import { getFundraiserControlScreenDetails } from 'store/fundraiserSlice/fundraiserActions'

function PendingCash() {
  const dispatch = useDispatch()
  const { loading, fundraiserDetail } = useFundraiserSelector()
  const [data, setData] = useState([])
  const [current, setCurrent] = useState([])

  useEffect(() => {
    if (fundraiserDetail?.pendingCash?.length > 0) {
      setData(fundraiserDetail?.pendingCash)
      setCurrent(fundraiserDetail?.pendingCash?.slice(0, config.rowsPerPage))
    }
    // eslint-disable-next-line
  }, [fundraiserDetail])

  const onClick = async (id, status, fundraiserId) => {
    const data1 = {
      status,
    }
    Api.put(`donations/${id}/change-cash-status`, data1)
      .then(() => {
        dispatch(getFundraiserControlScreenDetails(fundraiserId))
        message.success('Cash Status Updated')
      })
      .catch((error) => {
        console.log('error', error)
        message.error(error?.response?.data?.message || 'Something went wrong')
      })
  }

  const customMenu = (id, fundraiserId) => (
    <Menu>
      <Menu.Item
        key={1}
        onClick={() => onClick(id, 'Completed', fundraiserId)}
        id="pending-cash-options-dropdown-accept"
      >
        Accept
      </Menu.Item>
      <Menu.Item
        key={2}
        onClick={() => onClick(id, 'Rejected', fundraiserId)}
        id="pending-cash-options-dropdown-reject"
      >
        Reject
      </Menu.Item>
    </Menu>
  )

  return (
    <Card className="t-w-full">
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <div className="t-h-80" id="pending-cash-list">
          {data.length === 0 && (
            <div className="t-flex t-justify-center t-items-center t-h-full t-w-full">
              No Pending Cash
            </div>
          )}
          {data.length > 0 && (
            <InfiniteScrollContainer
              data={data}
              current={current}
              setCurrent={setCurrent}
              height="265px"
              itemsFirstLoad={config.rowsPerPage}
            >
              <>
                {current.map((item, index) => (
                  <div
                    key={index}
                    className={`t-w-full t-rounded-2xl t-h-12 t-p-3 ${
                      true ? 't-bg-yellow-100' : 't-bg-red-100'
                    } t-text-secondary-100 t-mb-2 t-flex t-items-center t-shadow-md t-justify-between`}
                  >
                    <div className="t-flex t-space-x-2 t-items-center">
                      <div className="t-w-8 t-h-8 t-rounded-full t-flex t-items-center t-justify-center t-bg-primary-100 t-p-2 t-shadow-lg t-text-white t-font-bold">
                        {item.DonorName
                          ? item.DonorName.substring(0, 1).toUpperCase()
                          : ''}
                      </div>
                      <h1 className="t-font-bold t-text-xs md:t-text-sm">
                        {`${item.DonorName} wants to donate from Table-${item?.Table}`}
                      </h1>
                    </div>
                    <div className="t-flex t-flex-col">
                      <h2 className="t-font-bold t-text-secondary-100 t-text-right t-text-xs md:t-text-sm">
                        {formatAmount(item.Amount)}
                      </h2>
                      <h2 className="t-text-gray-800 t-italic t-text-xs md:t-text-sm t-whitespace-nowrap t-text-right">
                        {moment(moment.utc(item.PaymentDate)).local().fromNow()}
                      </h2>
                    </div>
                    <div>
                      <Dropdown
                        overlay={customMenu(item?.id, item?.Fundraiserid)}
                      >
                        <IoIosArrowDropdownCircle
                          fontSize="1.5rem"
                          id="pending-cash-options-dropdown"
                          className="t-text-secondary-100 t-cursor-pointer"
                        />
                      </Dropdown>
                    </div>
                  </div>
                ))}
              </>
            </InfiniteScrollContainer>
          )}
        </div>
      </Skeleton>
    </Card>
  )
}

export { PendingCash }

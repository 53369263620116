import { Button, Col, Divider, Form, Input, message, Row, Select } from 'antd'
import React, { useState } from 'react'
import Api from 'api'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { fundraiserValidation } from 'validations'
import MaskedInput from 'antd-mask-input'
import { MaskPattern } from 'utils'

const UpdatePresenter = ({ form, close }) => {
  const [updateform] = Form.useForm()
  const [presenterValidate, setPresenterValidate] = useState(false)
  const [presenterFound, setPresenterFound] = useState(false)

  const onFinish = (values) => {
    if (presenterValidate) {
      form.setFieldValue('presenterPhoneCode', values.presenterPhoneCode)
      form.setFieldValue('presenterMobileNumber', values.presenterMobileNumber)
      form.setFieldValue('presenterName', values.presenterName)
      form.setFieldValue('presenterEmail', values.presenterEmail)
      close()
    } else {
      message.error('Kindly Validate Emcee')
    }
  }

  const validatePresenter = () => {
    const { presenterPhoneCode, presenterMobileNumber } =
      updateform.getFieldsValue()
    if (presenterMobileNumber === '') {
      message.error('Please enter emcee mobile number.')
      return false
    }
    // removing - from string
    const FormatedPresenterMobileNumber = presenterMobileNumber?.replace(
      /\D/g,
      '',
    )
    // removing zero if it's the first charater in the string
    const trimPhoneNumber = FormatedPresenterMobileNumber?.replace(/^0+/, '')
    const phone = presenterPhoneCode + trimPhoneNumber
    Api.get(
      `/users/check-phone-number-with-info?phone=${encodeURIComponent(phone)}`,
    )
      .then(() => {
        setPresenterValidate(true)
        setPresenterFound(false)
        updateform.setFieldValue('presenterName', '')
        updateform.setFieldValue('presenterEmail', '')
      })
      .catch((err) => {
        const success = err?.response?.data?.message
        const data = err?.response?.data?.values
        if (success === 'User Found') {
          updateform.setFieldValue('presenterName', data.name)
          updateform.setFieldValue('presenterEmail', data.email)
          setPresenterFound(true)
          setPresenterValidate(true)
        } else {
          message.error('Something Went Wrong')
        }
      })
    return null
  }

  const handleInputChange = (value) => {
    updateform.setFieldsValue({ presenterMobileNumber: value })
  }

  return (
    <Form
      form={updateform}
      layout="vertical"
      key={0}
      name="update presenter"
      onFinish={onFinish}
    >
      <Row className="t-p-1">
        <Col span={24} className="t-flex t-justify-between t-items-center">
          <div className="t-flex t-font-bold">New Emcee Details</div>
          <Button type="primary" onClick={() => validatePresenter()}>
            Validate Emcee
          </Button>
        </Col>
        <Col>
          {!presenterValidate ? (
            <span className="font-bold t-flex t-space-x-2 t-items-center">
              <h1 className="t-text-orange-600 t-text-sm">
                Click to validate the below information
              </h1>
              <AiFillCloseCircle
                fontSize="1rem"
                className="t-text-orange-600"
              />
            </span>
          ) : presenterFound ? (
            <span className="font-bold t-flex t-space-x-2 t-items-center">
              <h1 className="t-font-bold t-text-sm">
                Emcee Found Successfully
              </h1>
              <AiFillCheckCircle fontSize="1rem" className="t-text-green-600" />
            </span>
          ) : (
            <span className="font-bold t-flex t-space-x-2 t-items-center">
              <h1 className="t-font-bold t-text-sm">
                Enter new emcee name and email address
              </h1>
              <AiFillCloseCircle
                fontSize="1rem"
                className="t-text-orange-600"
              />
            </span>
          )}
        </Col>
        <Divider />
        <Col span={24}>
          <Form.Item
            name="presenterPhoneCode"
            label="Select Country"
            colon={false}
            rules={[fundraiserValidation]}
          >
            <Select placeholder="Select Country Code" style={{ width: '100%' }}>
              <Select.Option value="+1">
                United States/Canada (+1)
              </Select.Option>
              <Select.Option value="+54">Argentina (+54)</Select.Option>
              <Select.Option value="+92">Pakistan (+92)</Select.Option>
              <Select.Option value="+55">Brazil (+55)</Select.Option>
              <Select.Option value="+58">
                Venezuela, Bolivarian Republic of Venezuela (+58)
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="presenterMobileNumber"
            label="Emcee Mobile Number"
            colon={false}
            rules={[fundraiserValidation]}
          >
            <MaskedInput
              inputMode="numeric"
              mask={MaskPattern}
              onChange={(e) => {
                handleInputChange(e.maskedValue)
              }}
            />
          </Form.Item>
        </Col>
        {presenterValidate === true && (
          <>
            <Col span={24}>
              <Form.Item
                name="presenterName"
                label="Emcee Name"
                colon={false}
                rules={[fundraiserValidation]}
              >
                <Input placeholder="Enter Name" disabled={presenterFound} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="presenterEmail"
                label="Emcee Email"
                colon={false}
                rules={[fundraiserValidation]}
              >
                <Input placeholder="Enter Email" disabled={presenterFound} />
              </Form.Item>
            </Col>
          </>
        )}
        <Divider />
        <Col span={24} className="text-right">
          <Button
            // loading={loading}
            type="primary"
            className="px-25"
            htmlType="submit"
          >
            Invite
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export { UpdatePresenter }

import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  message,
} from 'antd'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useAuthSelector } from 'store/authSlice/authReducer'
import Api, { makeRequest } from 'api'
import { organizationDeviceValidation } from 'validations/updateDevice'
import { config } from 'configs'
import { ResetPasscode } from '../register-organization/ResetPasscode'

const Devices = () => {
  const [activeKeys, setActiveKeys] = useState([])
  const [isPassCodeResetVisible, setIsPassCodeResetVisible] = useState(false)
  const [resetPasscodeLoading, setResetPasscodeLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const { organization } = useAuthSelector()
  const [form] = Form.useForm()

  const history = useHistory()

  const onFinish = async (values) => {
    setBtnLoading(true)
    const data = {
      KioskShowDonationButtons: values.KioskShowDonationButtons === 'Yes',
      KioskDonationTitle: values.KioskDonationTitle,
      KioskRequireEmailAddress: values.KioskRequireEmailAddress === 'Yes',
      KioskRequireStreetAddress: values.KioskRequireStreetAddress === 'Yes',
      KioskRequireName: values.KioskRequireName === 'Yes',
      KioskIncludeProcessFeesByDefault:
        values.KioskIncludeProcessFeesByDefault === 'Yes',
      KioskShowQuickPathFundraiser:
        values.KioskShowQuickPathFundraiser === 'Yes',
      KioskShowFundraisers: values.KioskShowFundraisers === 'Yes',
      RequireEmailAddress: values.RequireEmailAddress === 'Yes',
      RequireStreetAddress: values.RequireStreetAddress === 'Yes',
      DefaultDonationValues: [
        values.donationValue0?.replace('$', ''),
        values.donationValue1?.replace('$', ''),
        values.donationValue2?.replace('$', ''),
        values.donationValue3?.replace('$', ''),
      ],
      PaymentCategories: activeKeys,
      PaymentCategoryAdded: true,
    }
    try {
      const response = await makeRequest(
        'patch',
        '/webapp/organization/devices',
        { organizationId: organization.id },
        data,
      )
      message.success('Device updated successfully!', response)
      history.push('/settings/manage-organization/organization-detail')
      setBtnLoading(false)
    } catch (error) {
      message.error('Failed to update Device.')
    }
  }

  const onFinishFailed = (errorInfo) => {
    message.error('Failed', errorInfo)
  }

  const handleBack = () => {
    history.push(config.defaultRedirect.subscriber)
  }

  const getDeviceData = () => {
    setLoading(true)
    makeRequest('get', '/webapp/organization?type=devices', {
      organizationId: organization?.id,
    })
      .then((res) => {
        setLoading(false)
        if (res && res.item) {
          const donationValues = {}
          res.item.KioskAmounts.forEach((value, index) => {
            donationValues[`donationValue${index}`] = `$${value}`
          })
          form.setFieldsValue({
            KioskShowDonationButtons: res.item.KioskShowDonationButtons
              ? 'Yes'
              : 'No',
            KioskDonationTitle: res.item.KioskDonationTitle,
            KioskShowFundraisers: res.item.KioskShowFundraisers ? 'Yes' : 'No',
            KioskShowQuickPathFundraiser: res.item.KioskShowQuickPathFundraiser
              ? 'Yes'
              : 'No',
            KioskIncludeProcessFeesByDefault: res.item
              .KioskIncludeProcessFeesByDefault
              ? 'Yes'
              : 'No',
            KioskRequireName: res.item.KioskRequireName ? 'Yes' : 'No',
            KioskRequireStreetAddress: res.item.KioskRequireStreetAddress
              ? 'Yes'
              : 'No',
            KioskRequireEmailAddress: res.item.KioskRequireEmailAddress
              ? 'Yes'
              : 'No',
            ...donationValues,
            PaymentCategories: res.item.PaymentCategories || [],
          })
          setActiveKeys(res.item.PaymentCategories || [])
        } else {
          message.error('No data found or invalid response format')
        }
      })
      .catch((error) => {
        setLoading(false)
        console.error('Error fetching device data:', error)
        message.error('Something went wrong!')
      })
  }

  useEffect(() => {
    if (organization?.id) {
      getDeviceData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization?.id])

  const onPasscodeSubmit = (values) => {
    if (organization.id) {
      const payload = {
        passcode: values.passcode,
        organizationId: organization.id,
      }
      setResetPasscodeLoading(true)
      Api.put('/component/organization/update-kiosk-authcode', payload)
        .then((res) => {
          setResetPasscodeLoading(false)
          setIsPassCodeResetVisible(false)
          message.success(res.message || 'Passcode changed Successfully')
        })
        .catch((error) => {
          setResetPasscodeLoading(false)
          setIsPassCodeResetVisible(false)
          message.error(
            error?.response?.data?.message || 'Something went wrong',
          )
        })
    } else {
      message.error('Organization id is not found')
    }
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 16 }}>
      <div className="t-mx-2 lg:t-mr-7 lg:t-ml-3 t-mt-4">
        <Form
          name="basic"
          layout="vertical"
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 24,
          }}
          className="t-w-[80%] sm:t-w-[100%] t-m-auto ant-roboto-font"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="Display Donation Buttons On Kiosk"
            name="KioskShowDonationButtons"
            rules={[organizationDeviceValidation]}
          >
            <Select>
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Kiosk Title Text* "
            name="KioskDonationTitle"
            rules={[organizationDeviceValidation]}
          >
            <Input placeholder="Support Operations" />
          </Form.Item>
          <Form.Item
            label="Show Fundraisers"
            name="KioskShowFundraisers"
            rules={[organizationDeviceValidation]}
          >
            <Select>
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Show ClickTap Fundraisers"
            name="KioskShowQuickPathFundraiser"
            rules={[organizationDeviceValidation]}
          >
            <Select>
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Include Processing Fees By Default"
            name="KioskIncludeProcessFeesByDefault"
            rules={[organizationDeviceValidation]}
          >
            <Select>
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <div className="t-flex t-flex-row t-justify-between t-border-b t-border-b-gray-500 t-mt-8 t-mb-8">
            <h1 className="t-text-base t-font-semibold t-text-black t-pb-1 roboto-font">
              Payment Page Settings:
            </h1>
          </div>
          <Form.Item
            label="Require Name"
            name="KioskRequireName"
            rules={[organizationDeviceValidation]}
          >
            <Select>
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Require Street Address"
            name="KioskRequireStreetAddress"
            rules={[organizationDeviceValidation]}
          >
            <Select>
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Require Email Address"
            name="KioskRequireEmailAddress"
            rules={[organizationDeviceValidation]}
          >
            <Select>
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <div className="t-flex t-flex-row t-justify-between t-border-b t-border-b-gray-500 t-mt-8 t-mb-8">
            <h1 className="t-text-base t-font-semibold t-text-black t-pb-1 roboto-font">
              Kiosk Organization Donations Buttons:
            </h1>
          </div>
          <Row className="t-flex t-justify-center t-item-center t-mt-4">
            <Col span={8} className="t-mx-6">
              <Form.Item
                name="donationValue0"
                rules={[organizationDeviceValidation]}
              >
                <Input className="t-text-lg t-text-center t-py-8" />
              </Form.Item>
            </Col>
            <Col span={8} className="t-mx-6">
              <Form.Item
                name="donationValue1"
                rules={[organizationDeviceValidation]}
              >
                <Input className="t-text-lg t-text-center t-py-8" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="t-flex t-justify-center t-item-center t-mt-4">
            <Col span={8} className="t-mx-6">
              <Form.Item
                name="donationValue2"
                rules={[organizationDeviceValidation]}
              >
                <Input className="t-text-lg t-text-center t-py-8" />
              </Form.Item>
            </Col>
            <Col span={8} className="t-mx-6">
              <Form.Item
                name="donationValue3"
                rules={[organizationDeviceValidation]}
              >
                <Input className="t-text-lg t-text-center t-py-8" />
              </Form.Item>
            </Col>
          </Row>

          <div className="t-flex t-flex-row t-justify-between t-border-b t-border-b-gray-500 t-mt-8 t-mb-8">
            <h1 className="t-text-base t-font-semibold t-text-black t-pb-1 roboto-font">
              Reset Kiosk Passcode
            </h1>
          </div>
          <Col span={8}>
            <Button
              type="primary"
              classname="t-w-full md:t-w-auto"
              onClick={() => setIsPassCodeResetVisible(true)}
            >
              Reset Kiosk Passcode
            </Button>
            {isPassCodeResetVisible && (
              <Modal
                title="Reset Kiosk Passcode"
                open={isPassCodeResetVisible}
                onCancel={() => setIsPassCodeResetVisible(false)}
                footer={null}
              >
                <ResetPasscode
                  loading={resetPasscodeLoading}
                  onSubmit={onPasscodeSubmit}
                  resetForm
                  hideResetButton
                />
              </Modal>
            )}
          </Col>
          <Row className="t-flex t-justify-center lg:t-justify-end t-item-center t-mt-8">
            <Col span={8} className="t-mx-3">
              <Form.Item>
                <Button
                  htmlType="button"
                  className="t-w-[85%] t-text-[14px]"
                  onClick={handleBack}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={btnLoading}
                  className="t-w-[85%] t-text-[14px] t-bg-[#2e97de]"
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Skeleton>
  )
}

export { Devices }

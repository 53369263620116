import React from 'react'
import PerformanceSummary from './ReportsType/PerformanceSummary'
import PerformanceDetails from './ReportsType/PerformanceDetails'
import EventStatisticsReport from './ReportsType/EventStatisticsReport'
import PledgeCommittments from './ReportsType/PledgeCommittments'
import DonorContributionsReport from './ReportsType/DonorContributionsReport'
import DonationStatistics from './ReportsType/DonationStatistics'
import ProgramServiceDetail from './ReportsType/ProgramServiceDetail'
import RentalPerformanceDetail from './ReportsType/RentalPerformanceDetail'
import VPPerformanceDetail from './ReportsType/VolunteerPerformance'

const ConditionalRenderer = ({ condition, id, name, type, setValue }) => {
  switch (condition) {
    case 'summary':
      return <PerformanceSummary id={id} type={type} setValue={setValue} />
    case 'details':
      return <PerformanceDetails id={id} type={type} setValue={setValue} />
    case 'statistics':
      return <EventStatisticsReport id={id} type={type} setValue={setValue} />
    case 'pledges':
      return <PledgeCommittments id={id} setValue={setValue} />
    case 'donations':
      return (
        <DonorContributionsReport id={id} setValue={setValue} type="event" />
      )
    case 'fundraiserDonations':
      return (
        <DonorContributionsReport
          id={id}
          setValue={setValue}
          type="fundraiser"
        />
      )
    case 'donationStats':
      return <DonationStatistics id={id} type={type} setValue={setValue} />
    case 'program-service-detail':
      return (
        <ProgramServiceDetail
          id={id}
          name={name}
          type={type}
          setValue={setValue}
        />
      )
    case 'rental':
      return <RentalPerformanceDetail id={id} type={type} setValue={setValue} />
    case 'vp':
      return <VPPerformanceDetail id={id} type={type} setValue={setValue} />

    default:
      return null // Render nothing if condition doesn't match
  }
}

export default ConditionalRenderer

import React, { useEffect } from 'react'
import { Card, Divider, Typography, Col, Skeleton } from 'antd'
import { useDispatch } from 'react-redux'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { usePaymentAccountSelector } from 'store/paymentAccountSlice/paymentAccountReducer'
import { getUserBusiness } from 'store/paymentAccountSlice/paymentAccountActions'
import { UserMessageView } from 'components'
import { ConnectPaypal, ConnectStripe } from './components'

const { Title } = Typography

const BusinessSetupPayments = () => {
  const dispatch = useDispatch()
  const { user } = useAuthSelector()
  const { loading, business } = usePaymentAccountSelector()

  useEffect(() => {
    if (user?.id) {
      dispatch(getUserBusiness({ userId: user?.id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <Col>
          <Title level={4}>Manage Business Payment Accounts</Title>
          <p>Connect your payment account to start recieving payments</p>
        </Col>,
      ]}
    >
      {/* If Business is not found show error view with error message */}
      {!business && (
        <UserMessageView heading="Error" subHeading="Business not found" />
      )}
      {/* If Business found show business account view */}
      {business && (
        <div style={{ minHeight: 'calc(100vh - 220px)' }}>
          <Skeleton
            loading={loading.getBusiness || loading.getBusinessPaymentAccount}
            active
            paragraph={{ rows: 18 }}
          >
            <Col className="t-py-8">
              <ConnectStripe />
              <Divider />
              <ConnectPaypal />
            </Col>
          </Skeleton>
        </div>
      )}
    </Card>
  )
}

export { BusinessSetupPayments }

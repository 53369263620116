import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Dropdown, Menu } from 'antd'
import { HiOutlineDotsVertical } from 'react-icons/hi'
import { useLocation } from 'react-router-dom'
import { MyEvent } from './MyEvent'
import { MyCampaign } from './MyCampaign'
import { MyProgram } from './MyProgram'
import { MyService } from './MyService'
import { MyVolunteer } from './MyVolunteer'
import { MyRentals } from './MyRentals'
import { MyBusiness } from './MyBusiness'
import { MyAuction } from './MyAuction'

const MyOrganization = ({ id }) => {
  const location = useLocation()
  const { pathname, state, search } = location

  useEffect(() => {
    if (state && state.key) {
      setActiveKey(state.key)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (search) {
      const urlSearchParams = new URLSearchParams(search)
      const { key } = Object.fromEntries(urlSearchParams.entries())
      if (key) setActiveKey(key)
    }
    // eslint-disable-next-line
  }, [])

  const [activeKey, setActiveKey] = useState('1')

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <div onClick={() => setActiveKey('1')} id="org-events">
              Events
            </div>
          ),
        },
        {
          key: '2',
          label: (
            <div onClick={() => setActiveKey('2')} id="org-campaigns">
              Campaigns
            </div>
          ),
        },
        {
          key: '3',
          label: (
            <div onClick={() => setActiveKey('3')} id="org-programs">
              Programs
            </div>
          ),
        },
        {
          key: '4',
          label: (
            <div onClick={() => setActiveKey('4')} id="org-services">
              Services
            </div>
          ),
        },
        {
          key: '5',
          label: (
            <div onClick={() => setActiveKey('5')} id="org-volunteer">
              Volunteer Opp
            </div>
          ),
        },
        {
          key: '6',
          label: (
            <div onClick={() => setActiveKey('6')} id="org-rentals">
              Rentals
            </div>
          ),
        },
        {
          key: '7',
          label: (
            <div onClick={() => setActiveKey('7')} id="org-businesses">
              Businesses
            </div>
          ),
        },
        {
          key: '8',
          label: (
            <div onClick={() => setActiveKey('8')} id="org-auction">
              Auctions
            </div>
          ),
        },
      ]}
    />
  )

  return (
    <Card className="header-solid" bordered={false}>
      <div style={{ minHeight: 'calc(100vh - 220px)' }}>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <div className="t-my-2 t-mx-2 t-flex t-justify-between">
              <h1>
                {activeKey === '1' && (
                  <h1 className="t-text-secondary-100 t-font-bold t-text-2xl">
                    Events
                  </h1>
                )}
                {activeKey === '2' && (
                  <h1 className="t-text-secondary-100 t-font-bold t-text-2xl">
                    Campaigns
                  </h1>
                )}
                {activeKey === '3' && (
                  <h1 className="t-text-secondary-100 t-font-bold t-text-2xl">
                    Programs
                  </h1>
                )}
                {activeKey === '4' && (
                  <h1 className="t-text-secondary-100 t-font-bold t-text-2xl">
                    Services
                  </h1>
                )}
                {activeKey === '5' && (
                  <h1 className="t-text-secondary-100 t-font-bold t-text-2xl">
                    Volunteer Opps
                  </h1>
                )}
                {activeKey === '6' && (
                  <h1 className="t-text-secondary-100 t-font-bold t-text-2xl">
                    Rentals
                  </h1>
                )}
                {activeKey === '7' && (
                  <h1 className="t-text-secondary-100 t-font-bold t-text-2xl">
                    Businesses
                  </h1>
                )}
                {activeKey === '8' && (
                  <h1 className="t-text-secondary-100 t-font-bold t-text-2xl">
                    Auctions
                  </h1>
                )}
              </h1>
              <Dropdown overlay={menu} placement="bottomLeft" arrow>
                <HiOutlineDotsVertical
                  fontSize="1.5rem"
                  id="dropdown-menu"
                  className="t-cursor-pointer t-mx-1"
                />
              </Dropdown>
            </div>

            <div>
              {activeKey === '1' && (
                <MyEvent id={id} urlData={{ pathname, activeKey }} />
              )}
              {activeKey === '2' && (
                <MyCampaign id={id} urlData={{ pathname, activeKey }} />
              )}
              {activeKey === '3' && (
                <MyProgram id={id} urlData={{ pathname, activeKey }} />
              )}
              {activeKey === '4' && (
                <MyService id={id} urlData={{ pathname, activeKey }} />
              )}
              {activeKey === '5' && (
                <MyVolunteer id={id} urlData={{ pathname, activeKey }} />
              )}
              {activeKey === '6' && (
                <MyRentals id={id} urlData={{ pathname, activeKey }} />
              )}
              {activeKey === '7' && (
                <MyBusiness id={id} urlData={{ pathname, activeKey }} />
              )}
              {activeKey === '8' && (
                <MyAuction id={id} urlData={{ pathname, activeKey }} />
              )}
            </div>
          </Col>
          <hr className="my-12" />
        </Row>
      </div>
    </Card>
  )
}

export { MyOrganization }

import React, { useEffect, useState } from 'react'
import { Skeleton, Table } from 'antd'
import { useDispatch } from 'react-redux'
import { useProgramSelector } from 'store/programSlice/programReducer'
import { getRegisterUsers } from 'store/programSlice/programActions'
import { useParams } from 'react-router-dom'

const RegisteredUsers = () => {
  const dispatch = useDispatch()
  const { registeredUsers } = useProgramSelector()
  const [loading, setLoading] = useState(false)
  const params = useParams()

  useEffect(() => {
    dispatch(getRegisterUsers(params?.id, setLoading))
  }, [dispatch, params?.id])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'UserName',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Email',
      dataIndex: 'UserEmail',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Phone Number',
      dataIndex: 'UserPhoneNumber',
      className: 'ant-table-row-cell-break-word',
    },
  ]

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
      <Table
        tableLayout="auto"
        scroll={{ x: 500 }}
        columns={columns}
        dataSource={registeredUsers}
        bordered={false}
        pagination
      />
    </Skeleton>
  )
}

export { RegisteredUsers }

import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Statistic,
  message,
  // Typography,
} from 'antd'
import { MdOutlinePayment } from 'react-icons/md'
import { formatAmount } from 'utils'
import { makeRequest } from 'api'
import { config } from 'configs'

// const { Paragraph } = Typography
const initialValues = {
  tier: '',
}

const UpdateTierModal = ({ visible, close, item, getData }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  Form.useWatch('tier', form)
  const tierValue = form.getFieldValue('tier')
  const selectedTier = item?.MembershipTiers?.find((t) => t.Id === tierValue)

  const MembershipTiers = item?.MembershipTiers || []
  useEffect(() => {
    if (item?.TierId) {
      form.setFieldsValue({
        tier: item?.TierId,
      })
    }
    // eslint-disable-next-line
  }, [item?.TierId])

  // eslint-disable-next-line consistent-return
  const onFinish = async (values) => {
    const SelectedTier = item?.MembershipTiers?.find(
      (t) => t.Id === values?.tier,
    )
    const payload = {
      associationId: item?.Id,
      selectedTier: SelectedTier,
    }
    setLoading(true)
    try {
      const response = await makeRequest(
        'post',
        `/component/membership-program/update-membership-tier`,
        {},
        payload,
      )
      message.success(response?.message || 'Successfully updated tier')
      onClose()
      getData()
    } catch (error) {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
    }
    setLoading(false)
  }

  const onClose = () => {
    form.resetFields()
    close()
  }

  const getAmountToPay = () => {
    const alreadySelectedTier = MembershipTiers.find(
      (t) => t.Id === item?.TierId,
    )
    const nowSelectedTier = MembershipTiers.find((t) => t.Id === tierValue)
    if (alreadySelectedTier?.Id === nowSelectedTier?.Id) {
      return 'Current Selected Tier'
    }

    if (Number(alreadySelectedTier?.Amount) < Number(nowSelectedTier?.Amount)) {
      const amount =
        Number(nowSelectedTier?.Amount) - Number(alreadySelectedTier?.Amount)
      return formatAmount(amount)
    }
    return formatAmount(0)
  }

  const amountToPay = getAmountToPay()
  return (
    <Modal
      centered
      keyboard
      title="Change Tier"
      open={visible}
      onCancel={() => onClose()}
      footer={null}
      destroyOnClose
    >
      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="add-items"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]} className="t-mb-8 t-space-y-2">
            <Col xs={24} sm={24} md={24} xl={24}>
              <Card
                className="header-solid t-p-2 md:t-p-4 t-m-4 t-shadow-2xl t-rounded-lg widget-2 t-flex t-justify-center t-items-center"
                bordered={false}
              >
                <Statistic
                  title={
                    <>
                      <div className="icon">
                        <MdOutlinePayment />
                      </div>
                      <h6 className="t-text-secondary-100 t-text-2xl t-font-semi">
                        {selectedTier?.Title}
                      </h6>
                      <p>{selectedTier?.Description}</p>
                    </>
                  }
                  value={amountToPay}
                />
              </Card>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="tier"
                label="Select New Membership Level"
                // rules={[membershipProgramValidation]}
                className="t-p-0 t-my-2"
              >
                <Select placeholder="Select Tier" style={{ width: '100%' }}>
                  {MembershipTiers.map((tier, index) => (
                    <Select.Option value={tier.Id} key={index}>
                      {tier.Title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} className="text-right t-my-2 t-space-x-2">
              <Button type="primary" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Update Tier
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Modal>
  )
}
export { UpdateTierModal }

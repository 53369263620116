import { makeRequest } from 'api'
import { paymentAccountActions } from 'store/paymentAccountSlice/paymentAccountReducer'
import { message } from 'antd'
import { config } from 'configs'

export const getUserBusiness =
  ({ userId }) =>
  (dispatch) => {
    dispatch(
      paymentAccountActions.setLoading({
        type: 'getBusiness',
        value: true,
      }),
    )
    makeRequest('get', `/business?userId=${userId}`)
      .then((res) => {
        const business = res.results
        if (business) {
          dispatch(paymentAccountActions.setBusiness(business))
          dispatch(getBusinessPaymentAccount({ businessId: business.Id }))
        } else {
          dispatch(paymentAccountActions.setBusiness(null))
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
        dispatch(paymentAccountActions.setBusiness(null))
      })
      .finally(() => {
        dispatch(
          paymentAccountActions.setLoading({
            type: 'getBusiness',
            value: false,
          }),
        )
      })
  }

export const getBusinessPaymentAccount =
  ({ businessId }) =>
  (dispatch) => {
    dispatch(
      paymentAccountActions.setLoading({
        type: 'getBusinessPaymentAccount',
        value: true,
      }),
    )
    makeRequest('get', `/business-payment-account/get-account/${businessId}`)
      .then((res) => {
        if (res.result) {
          dispatch(paymentAccountActions.setBusinessPaymentAccount(res.result))
        } else {
          dispatch(paymentAccountActions.resetBusinessPaymentAccount())
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
        dispatch(paymentAccountActions.resetBusinessPaymentAccount())
      })
      .finally(() => {
        dispatch(
          paymentAccountActions.setLoading({
            type: 'getBusinessPaymentAccount',
            value: false,
          }),
        )
      })
  }

export const getStripeAccountInfo =
  ({ accountId }) =>
  (dispatch) => {
    dispatch(
      paymentAccountActions.setLoading({
        type: 'getStripeAccountInfo',
        value: true,
      }),
    )
    makeRequest('get', `/connect/stripe/get-stripe-account-info/${accountId}`)
      .then((res) => {
        const info = res.result
        if (info) {
          dispatch(paymentAccountActions.setStripeAccountInfo(info))
        } else {
          dispatch(paymentAccountActions.resetStripeAccountInfo())
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
        dispatch(paymentAccountActions.resetStripeAccountInfo())
      })
      .finally(() => {
        dispatch(
          paymentAccountActions.setLoading({
            type: 'getStripeAccountInfo',
            value: false,
          }),
        )
      })
  }

export const addBusinessAccountPaymentProcessor =
  ({ businessId, accountType, accountId, action }) =>
  (dispatch) => {
    const type =
      accountType === 'Stripe'
        ? 'addBusinessStripePaymentProcessor'
        : 'addBusinessPaypalPaymentProcessor'

    dispatch(
      paymentAccountActions.setLoading({
        type,
        value: true,
      }),
    )
    makeRequest(
      'put',
      `/business-payment-account/add-payment-processor`,
      {},
      { businessId, accountType, accountId },
    )
      .then((res) => {
        message.success(res.message || config.defaultSuccessMessage)
        dispatch(getBusinessPaymentAccount({ businessId }))
        if (action) action()
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
      })
      .finally(() => {
        dispatch(
          paymentAccountActions.setLoading({
            type,
            value: false,
          }),
        )
      })
  }

export const deleteBusinessAccountPaymentProcessor =
  ({ businessId, accountType }) =>
  (dispatch) => {
    const type =
      accountType === 'Stripe'
        ? 'deleteBusinessStripePaymentProcessor'
        : 'deleteBusinessPaypalPaymentProcessor'

    dispatch(
      paymentAccountActions.setLoading({
        type,
        value: true,
      }),
    )
    makeRequest(
      'put',
      `/business-payment-account/delete-payment-processor`,
      {},
      { businessId, accountType },
    )
      .then((res) => {
        message.success(res.message || config.defaultSuccessMessage)
        dispatch(getBusinessPaymentAccount({ businessId }))
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
      })
      .finally(() => {
        dispatch(
          paymentAccountActions.setLoading({
            type,
            value: false,
          }),
        )
      })
  }

export const updateBusinessStripeAccount =
  ({ accountId, connectorType }) =>
  (dispatch) => {
    dispatch(
      paymentAccountActions.setLoading({
        type: 'updateBusinessStripeAccount',
        value: true,
      }),
    )
    makeRequest(
      'post',
      `/connect/stripe/get-stripe-account-link`,
      {},
      { accountId, connectorType },
    )
      .then((res) => {
        const link = res?.result
        if (link) {
          window.open(link, '_self')
        } else {
          message.error('Unable to redirect for updating stripe account')
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
      })
      .finally(() => {
        dispatch(
          paymentAccountActions.setLoading({
            type: 'updateBusinessStripeAccount',
            value: false,
          }),
        )
      })
  }

export const LinkBusinessPaypalAccount =
  ({ id }) =>
  (dispatch) => {
    dispatch(
      paymentAccountActions.setLoading({
        type: 'linkBusinessPaypalAccount',
        value: true,
      }),
    )
    makeRequest(
      'post',
      `/connect/paypal/onboard-seller`,
      {},
      { id, connectorType: 'Business' },
    )
      .then((res) => {
        const link = res?.result?.links[1]?.href
        if (link) {
          window.open(link, '_self')
        } else {
          message.error('Unable to redirect for paypal account authorization')
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
      })
      .finally(() => {
        dispatch(
          paymentAccountActions.setLoading({
            type: 'linkBusinessPaypalAccount',
            value: false,
          }),
        )
      })
  }

/* eslint-disable react/no-danger */
import { Button } from 'antd'
import { awsconfig } from 'configs'
import React, { useEffect } from 'react'
import { BsCheckCircleFill } from 'react-icons/bs'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Confirmation = ({
  profileWebsite,
  orgWebsite,
  orgDomain,
  htmlContent,
  itemType = null,
  customMessage = null,
}) => {
  const history = useHistory()

  const addProtocolToURL = (url) => {
    let newUrl = url
    const protocolRegex = /^(https?:\/\/)/i

    if (!protocolRegex.test(url)) {
      // Protocol is missing, add "http://" by default
      newUrl = `http://${url}`
    }

    return newUrl
  }

  const Url = `https://${orgDomain}.${awsconfig.API.endpoints[0].api_url?.replace(
    /^https?:\/\//,
    '',
  )}`

  useEffect(() => {
    if (itemType === 'rental-request-sent') {
      setTimeout(() => {
        window.open(addProtocolToURL(orgWebsite), '_self')
      }, 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProtocolToURL, itemType])

  return (
    <div
      className={`t-h-full t-w-full t-flex t-flex-col ${
        !htmlContent && 't-justify-center'
      }`}
    >
      <div className="t-flex t-flex-col t-space-y-4 t-items-center t-justify-center t-py-4">
        <BsCheckCircleFill fontSize="4rem" className="t-text-green-500" />
        <h1 className="t-font-bold t-text-2xl t-text-secondary-100 t-text-center">
          {itemType === 'room-payment'
            ? 'Payment Successful'
            : !customMessage
            ? 'Registered Successfully'
            : ''}
          {customMessage}
        </h1>
        {/* <p className="t-font-semibold t-text-md t-text-grey-200 t-text-center">
          Thank you for your support. We apprecite your contribution.
        </p> */}
      </div>
      {htmlContent && (
        <div
          className="t-my-2 t-overflow-auto t-flex t-flex-col no-scrollbar"
          style={{
            height: '80vh',
          }}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      )}
      <div className="t-w-full t-flex t-justify-center t-items-center">
        <Button
          type="primary"
          onClick={() => {
            if (profileWebsite) {
              window.open(addProtocolToURL(profileWebsite), '_self')
            } else if (orgWebsite) {
              window.open(addProtocolToURL(orgWebsite), '_self')
            } else if (orgDomain) {
              window.open(Url, '_self')
            } else {
              history.push('/')
            }
          }}
        >
          Done
        </Button>
      </div>
    </div>
  )
}

export { Confirmation }

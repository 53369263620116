import React, { useEffect, useState } from 'react'
import { Card, Table, Tag, Skeleton, Space, Dropdown, Menu, Input } from 'antd'
import { useDispatch } from 'react-redux'
import { EllipsisOutlined, SearchOutlined } from '@ant-design/icons'
import { searchReservations } from 'utils'
import {
  adminActionOnReservation,
  getAllReservations,
} from 'store/roomSlice/roomActions'

const ManageReservationRequests = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')

  const [loading, setLoading] = useState(false)
  const [reservations, setReservations] = useState([])

  const handleAccept = async (reservation) => {
    try {
      setLoading(true)
      await adminActionOnReservation(reservation, 'Accepted')
      await fetchReservations()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const handleReject = async (reservation) => {
    try {
      setLoading(true)
      await adminActionOnReservation(reservation, 'Rejected')
      await fetchReservations()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const CustomMenu = (reservation) => (
    <Menu>
      <Menu.Item key={1} onClick={() => handleAccept(reservation)}>
        Accept
      </Menu.Item>
      <Menu.Item key={2} onClick={() => handleReject(reservation)}>
        Reject
      </Menu.Item>
    </Menu>
  )

  const columns = [
    {
      title: 'Id',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Organization Name',
      dataIndex: 'OrganizationName',
      key: 'OrganizationName',
    },
    {
      title: 'Room Name',
      dataIndex: 'RoomName',
      key: 'RoomName',
    },
    {
      title: 'Total Hours',
      dataIndex: 'TotalHours',
      key: 'TotalHours',
    },
    {
      title: 'Total Cost',
      dataIndex: 'TotalCost',
      key: 'TotalCost',
    },
    {
      title: 'Creator Email',
      dataIndex: 'CreatorEmail',
      key: 'CreatorEmail',
    },
    {
      title: 'AcceptanceStatus',
      dataIndex: 'AcceptanceStatus',
      key: 'AcceptanceStatus',
      render: (_, { AcceptanceStatus }) => (
        <>
          {AcceptanceStatus === 'Pending' && <Tag color="gray">Pending</Tag>}
          {AcceptanceStatus === 'Accepted' && <Tag color="green">Accepted</Tag>}
          {AcceptanceStatus === 'Rejected' && <Tag color="red">Rejected</Tag>}
        </>
      ),
      sorter: (a, b) => {
        if (a.OrgStatus > b.OrgStatus) {
          return -1
        }
        if (b.OrgStatus > a.OrgStatus) {
          return 1
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Actions',
      key: 'id',
      width: 300,
      align: 'center',
      render: (_, reservation) => (
        <Space size="middle">
          <Dropdown overlay={CustomMenu(reservation)}>
            <EllipsisOutlined />
          </Dropdown>
        </Space>
      ),
    },
  ]

  const fetchReservations = async () => {
    try {
      setLoading(true)
      const allReservations = await getAllReservations()
      setReservations(allReservations.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchReservations()
    // eslint-disable-next-line
  }, [dispatch])

  return (
    <>
      <Card
        bordered={false}
        className="header-solid mb-24"
        style={{ minHeight: '85vh' }}
        title={
          <div className="t-flex t-flex-wrap t-justify-between">
            <div>
              <h6 className="font-semibold t-text-2xl">Reservation Requests</h6>
              <p>All reservations from all organizations</p>
            </div>
            <div className="t-mt-4 sm:t-mt-0">
              <div className="header-control t-mt-0">
                <Input
                  className="header-search"
                  placeholder="Search ..."
                  value={searchText}
                  prefix={<SearchOutlined />}
                  onChange={(e) => {
                    setSearchText(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
        }
      >
        <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
          <Table
            size="middle"
            tableLayout="auto"
            scroll={{
              x: 'max-content',
            }}
            columns={columns}
            dataSource={searchReservations(reservations, searchText) || []}
            bordered={false}
            pagination={{
              pageSize: 10,
            }}
            style={{
              width: 'auto',
              minWidth: 'unset !important',
            }}
          />
        </Skeleton>
      </Card>
    </>
  )
}

export { ManageReservationRequests }

import * as Yup from 'yup'

const amountValidation = new RegExp('[0-9]|,')

const schema = Yup.object().shape({
  title: Yup.string().required('Title is Required'),
  quantity: Yup.string().required('Quantity is Required'),
  description: Yup.string()
    .required('Description is Required')
    .min(10, 'Description should be atleast 10 character'),
  startBid: Yup.string()
    .required('Satrt Bid is Required')
    .matches(amountValidation, 'Invalid cost'),
  winningBid: Yup.string()
    .required('Winning Bid is Required')
    .matches(amountValidation, 'Invalid cost'),
  itemImage: Yup.mixed()
    .typeError('Item Image is not a file')
    .required('Item Image is required'),
  endDate: Yup.date().typeError('Invalid Date').required('EndDate is required'),
})

const auctionItemValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { auctionItemValidation }

import React, { useEffect, useState } from 'react'
import { Button, message, Modal, Skeleton, Table } from 'antd'
import Api from 'api'

const columns = [
  {
    title: 'Donor Name',
    dataIndex: 'DonorName',
  },
  {
    title: 'Amount',
    dataIndex: 'Amount',
  },
]

const SendRemindersModal = ({ id, visible, onClose }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)

  const formateData = (values) => {
    const formatedData = values.map((item, index) => ({ ...item, key: index }))
    setData(formatedData)
  }

  useEffect(() => {
    setLoading(true)
    Api.get(`events/getPledgers/${id}`)
      .then((res) => {
        if (res.results.Pledges) formateData(res.results.Pledges)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        message.error('An Error Occured')
      })
  }, [id, visible])

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const onFinish = (type) => {
    const payload = data
      .filter((item) => selectedRowKeys.includes(item.key))
      .map((item) => ({
        Id: item.Id,
      }))
    Api.post(`/pledge/reminder`, { data: payload, type })
      .then(() => {
        message.success('Reminders sent Successfully')
        setSelectedRowKeys([])
        onClose()
      })
      .catch((error) => {
        console.log('error', error)
        message.error('An Error Occured')
      })
  }

  return (
    <Modal
      centered
      title="Send Pledge Reminders"
      open={visible}
      onCancel={onClose}
      footer={[]}
    >
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
        />
        <div className="t-flex t-justify-end t-items-center t-space-x-2">
          <Button onClick={() => onFinish('Sms')}>Send SMS</Button>
          <Button onClick={() => onFinish('Email')}>Send Email</Button>
        </div>
      </Skeleton>
    </Modal>
  )
}

export { SendRemindersModal }

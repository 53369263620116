import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  getEventControlScreenDetails,
  getEventDetailWithoutLoading,
} from 'store/eventSlice/eventActions'

const SECONDS = 25

const useDonations = (id) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const interval = setInterval(() => {
      if (id) {
        dispatch(getEventDetailWithoutLoading(id))
        dispatch(getEventControlScreenDetails(id))
      }
    }, SECONDS * 1000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return true
}

export { useDonations }

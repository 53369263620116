import * as Yup from 'yup'

const schema = Yup.object().shape({
  RatingComment: Yup.string().required('Required'),
})

const feedbackValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { feedbackValidation }

import React, { useState } from 'react'
import { Card, Input, Tooltip } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { RiFileUserLine } from 'react-icons/ri'
import { AiOutlineUserSwitch } from 'react-icons/ai'
import { MdOutlinePendingActions } from 'react-icons/md'
import { CommunityList } from './CommunityList'
import { RecurringDonors } from './RecurringDonors'
import { PendingPledges } from './PendingPledges'

const initialValue = {
  communityList: true,
  recurringDonnor: false,
  pendingPledges: false,
}

const MyDonors = () => {
  const [searchText, setSearchText] = useState(null)
  const [isViews, setViews] = useState(initialValue)

  const changeView = (key) => {
    switch (key) {
      case 'communityList':
        setViews({
          communityList: true,
          recurringDonnor: false,
          pendingPledges: false,
        })
        break
      case 'recurringDonnor':
        setViews({
          communityList: false,
          recurringDonnor: true,
          pendingPledges: false,
        })
        break
      case 'pendingPledges':
        setViews({
          communityList: false,
          recurringDonnor: false,
          pendingPledges: true,
        })
        break
      default:
        setViews({
          communityList: true,
          recurringDonnor: false,
          pendingPledges: false,
        })
        break
    }
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={
        <div className="t-flex t-flex-wrap t-justify-between">
          <div>
            <h6 className="font-semibold t-text-2xl">
              {isViews.communityList && 'Community List'}
              {isViews.recurringDonnor && 'Migrated Recurring Donors'}
              {isViews.pendingPledges && 'Pending Pledges'}
            </h6>
          </div>
          <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
            <div className="header-control t-mt-0">
              <Input
                className="header-search t-w-36"
                placeholder="Search ..."
                prefix={<SearchOutlined />}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
            </div>

            {!isViews.communityList && (
              <Tooltip title="View Community List">
                <RiFileUserLine
                  fontSize="2rem"
                  id="volunteers-inactive-not-clicked"
                  className="t-text-secondary-100 t-cursor-pointer"
                  onClick={() => changeView('communityList')}
                />
              </Tooltip>
            )}
            {!isViews.recurringDonnor && (
              <Tooltip title="View Recurring Donnor List">
                <AiOutlineUserSwitch
                  fontSize="2rem"
                  id="volunteers-inactive-not-clicked"
                  className="t-text-secondary-100 t-cursor-pointer"
                  onClick={() => changeView('recurringDonnor')}
                />
              </Tooltip>
            )}
            {!isViews.pendingPledges && (
              <Tooltip title="View Pending Pledges List">
                <MdOutlinePendingActions
                  fontSize="2rem"
                  id="volunteers-inactive-not-clicked"
                  className="t-text-secondary-100 t-cursor-pointer"
                  onClick={() => changeView('pendingPledges')}
                />
              </Tooltip>
            )}
          </div>
        </div>
      }
    >
      {isViews.communityList && <CommunityList searchText={searchText} />}
      {isViews.recurringDonnor && <RecurringDonors searchText={searchText} />}
      {isViews.pendingPledges && <PendingPledges searchText={searchText} />}
    </Card>
  )
}

export { MyDonors }

import React, { useEffect, useState } from 'react'
import {
  Card,
  Skeleton,
  Col,
  Tag,
  Button,
  Popconfirm,
  message,
  Dropdown,
  Space,
  Table,
  Menu,
} from 'antd'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useRoomSelector } from 'store/roomSlice/roomReducer'
import { getUserReservation, requestRefund } from 'store/roomSlice/roomActions'
import { formatAmount, formateDate } from 'utils'
import moment from 'moment'
import { CiMenuKebab } from 'react-icons/ci'
// import { PaymentModal } from './PaymentModal'
import { ViewReservation } from './ViewReservation'

const MyReservations = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { user } = useAuthSelector()

  const [isLoading, setLoading] = useState(false)
  // const [visible, setVisible] = useState(false)
  const [viewModal, setViewModal] = useState(false)
  const [selected, setSelected] = useState(null)
  const { loading, reservations } = useRoomSelector()

  useEffect(() => {
    dispatch(getUserReservation(user?.email))
    // eslint-disable-next-line
  }, [])

  // const onClickPay = (reservation) => {
  //   setSelected(reservation)
  //   setVisible(!visible)
  // }

  // const onPaid = () => {
  //   dispatch(getUserReservation(user?.email))
  // }

  const onConfirmRefund = async (reservation) => {
    setLoading(true)
    try {
      await requestRefund(reservation)
      message.success('Refund request sent')
      dispatch(getUserReservation(user?.email))
    } catch (error) {
      message.error('Error sending refund request')
    }
    setLoading(true)
  }

  const onViewReservation = (reservationId) => {
    const reservation = reservations.find((item) => item?.Id === reservationId)
    setSelected(reservation)
    setViewModal(!viewModal)
  }

  const tableData = reservations.map((item) => {
    let scheduleDate = moment(item?.Date)
    scheduleDate = moment([
      scheduleDate.year(),
      scheduleDate.month(),
      scheduleDate.date(),
    ])
    let currentDate = moment()
    currentDate = moment([
      currentDate.year(),
      currentDate.month(),
      currentDate.date(),
    ])
    const days = scheduleDate.diff(currentDate, 'days')
    const isEditableDatePassed = days > 0
    const isRequestPending = item?.AcceptanceStatus === 'Pending'
    const isRequestRejected = item?.AcceptanceStatus === 'Rejected'
    const isRequestAccepted = item?.AcceptanceStatus === 'Accepted'
    const isChangeRequestPending = item?.AcceptanceStatus === 'ChangeRequested'
    const isRefundRequestPending = item?.RefundRequestStatus === 'Pending'
    const isRefundRequestApproved = item?.RefundRequestStatus === 'Approved'
    const isPaid = item?.PaymentStatus === 'Completed'
    const isPaymentProcessing = item?.PaymentStatus === 'Processing'
    return {
      key: item.Id,
      RoomName: item?.RoomName,
      RoomId: item.RoomId,
      CreatorName: item?.CreatorName,
      OrganizationName: item.OrganizationName,
      Date: formateDate(item?.Date),
      TotalCost: formatAmount(item?.UpdatedCost || item?.TotalCost),
      Status: {
        isEditableDatePassed,
        isRequestAccepted,
        isPaid,
        isRequestRejected,
        isRequestPending,
        isChangeRequestPending,
        isRefundRequestPending,
        isRefundRequestApproved,
        isPaymentProcessing,
        reservationId: item.Id,
      },
    }
  })

  function CustomMenu(item) {
    return (
      <Menu>
        {item?.Status?.isRequestAccepted &&
          !item?.Status.isPaid &&
          !item?.Status?.isPaymentProcessing && (
            <Menu.Item
              onClick={() => history.push(`/payment/room/${item?.key}`)}
              type="primary"
              size="small"
              key="1"
            >
              Pay
            </Menu.Item>
          )}
        {!item?.Status?.isPaid &&
          item?.Status?.isEditableDatePassed &&
          !item.Status.isPaymentProcessing && (
            <Menu.Item
              key="2"
              onClick={() =>
                history.push(
                  `/rentals/${item?.RoomId}?reservationId=${item?.key}`,
                )
              }
            >
              Edit
            </Menu.Item>
          )}
        {item?.Status?.isPaid &&
          !item?.Status?.isRefundRequestPending &&
          !item.Status.isRefundRequestApproved && (
            <Menu.Item key="2">
              <Popconfirm
                title="Are you sure you want to cancel the reservation?"
                okText="Cancel and request a refund?"
                cancelText="No"
                placement="left"
                loading={isLoading}
                onConfirm={() => onConfirmRefund(item)}
              >
                <Button type="primary" size="small">
                  Cancel
                </Button>
              </Popconfirm>
            </Menu.Item>
          )}

        <Menu.Item key="4" onClick={() => onViewReservation(item?.key)}>
          View Details
        </Menu.Item>
      </Menu>
    )
  }

  const columns = [
    {
      title: 'TITLE',
      dataIndex: 'RoomName',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'ORGANIZATION',
      dataIndex: 'OrganizationName',
      className: 'ant-table-row-cell-break-word align-center',
    },
    {
      title: 'RESERVED DATE',
      dataIndex: 'Date',
      className: 'ant-table-row-cell-break-word align-center',
    },
    {
      title: 'TOTAL COST',
      dataIndex: 'TotalCost',
      className: 'ant-table-row-cell-break-word align-center',
    },
    {
      title: 'STATUS',
      dataIndex: 'Status',
      className: 'ant-table-row-cell-break-word',
      render: (status) => (
        <div className="t-flex t-space-x-1 t-items-center">
          {status.isRequestPending && (
            <Tag className="t-text-gray-600 t-font-semibold t-bg-gray-100 t-px-2 t-py-1 t-rounded t-ml-[-20px] t-mr-2 t-border-none">
              Pending
            </Tag>
          )}
          {status.isRequestAccepted &&
            !status.isPaid &&
            !status.isPaymentProcessing && (
              <Tag
                className="t-font-semibold t-px-2 t-py-1 t-rounded t-ml-[-20px] t-mr-2 t-border-none"
                color="warning"
              >
                Pay
              </Tag>
            )}
          {status.isRequestRejected && (
            <Tag className="t-text-red-600 t-font-semibold t-bg-red-100 t-px-2 t-py-1 t-rounded t-ml-[-20px] t-mr-2 t-border-none">
              Rejected
            </Tag>
          )}

          {status.isRefundRequestPending && (
            <Tag className="t-text-orange-600 t-font-semibold t-bg-orange-100 t-px-2 t-py-1 t-rounded t-ml-[-20px] t-mr-2 t-border-none">
              Refund requested
            </Tag>
          )}
          {status.isPaid &&
            !status.isRefundRequestPending &&
            !status.isRefundRequestApproved && (
              <Tag className="t-text-[#00bd9b] t-font-semibold t-bg-[#dbf2ee] t-px-2 t-py-1 t-rounded t-ml-[-20px] t-mr-2 t-border-none">
                Paid
              </Tag>
            )}
          {status.isRefundRequestApproved && (
            <Tag className="t-text-blue-600 t-font-semibold t-bg-blue-100 t-px-2 t-py-1 t-rounded t-ml-[-20px] t-mr-2 t-border-none">
              Refund Approved
            </Tag>
          )}
          {status.isChangeRequestPending && (
            <Tag className="t-text-purple-600 t-font-semibold t-bg-purple-100 t-px-2 t-py-1 t-rounded t-ml-[-20px] t-mr-2 t-border-none">
              Change request sent
            </Tag>
          )}
          {status.isPaymentProcessing && (
            <Tag className="t-text-[#00bd9b] t-font-semibold t-bg-[#dbf2ee] t-px-2 t-py-1 t-rounded t-ml-[-20px] t-mr-2 t-border-none">
              Payment Processing
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: 'ACTION',
      dataIndex: 'status',
      className: 'ant-table-row-cell-break-word',
      render: (_, item) => (
        <Space size="middle">
          <Dropdown overlay={CustomMenu(item)} trigger={['click']}>
            <CiMenuKebab style={{ cursor: 'pointer' }} />
          </Dropdown>
        </Space>
      ),
    },
  ]

  return (
    <Card className="t-mb-5 t-border-none t-shadow-none">
      <div style={{ minHeight: 'calc(100vh - 320px)' }}>
        <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={tableData || []}
              tableLayout="auto"
              scroll={{ x: 500 }}
              bordered={false}
              pagination={false}
              className="td-right"
            />
          </Col>
        </Skeleton>
      </div>
      {/* <PaymentModal
        visible={visible}
        close={() => setVisible(!visible)}
        reservation={selected}
        refresh={onPaid}
      /> */}
      <ViewReservation
        visible={viewModal}
        reservation={selected}
        setVisible={() => setViewModal(!viewModal)}
      />
    </Card>
  )
}

export { MyReservations }

import React, { useEffect, useState } from 'react'
import { Skeleton, Table } from 'antd'
import { useDispatch } from 'react-redux'
import { getRegisterUsers } from 'store/serviceSlice/serviceActions'
import { useServiceSelector } from 'store/serviceSlice/serviceReducer'
import { useParams } from 'react-router-dom'
import { formatAmountWithOutDecimal, formateDateTimeToLocal } from 'utils'

const RegisteredUsers = ({ serviceType }) => {
  const dispatch = useDispatch()
  const { registeredUsers } = useServiceSelector()
  const [loading, setLoading] = useState(false)

  const params = useParams()

  useEffect(() => {
    dispatch(getRegisterUsers(params?.id, setLoading))
  }, [dispatch, params?.id])

  const columns =
    serviceType === 'Zakaat'
      ? [
          {
            title: 'Service Name',
            dataIndex: 'ServiceName',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Name',
            dataIndex: 'UserName',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Email',
            dataIndex: 'UserEmail',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Phone Number',
            dataIndex: 'UserPhoneNumber',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Gender',
            dataIndex: 'Gender',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Maritual Status',
            dataIndex: 'MaritalStatus',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Children',
            dataIndex: 'HaveChildren',
            className: 'ant-table-row-cell-break-word',
            render: (val) => (val ? 'Yes' : 'No'),
          },
          {
            title: 'No of Children',
            dataIndex: 'NumberOfChildren',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'SSN',
            dataIndex: 'SSN',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Amount Received In Last 12 Months',
            dataIndex: 'AmountReceivedInLast12Months',
            className: 'ant-table-row-cell-break-word',
            render: (amount) => formatAmountWithOutDecimal(amount),
          },
          {
            title: 'Annual Household Income',
            dataIndex: 'AnnualHouseholdIncome',
            className: 'ant-table-row-cell-break-word',
            render: (amount) => formatAmountWithOutDecimal(amount),
          },
          {
            title: 'Highest Level Of Education Completed',
            dataIndex: 'HighestLevelOfEducationCompleted',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'HealthInsuranceInformation',
            dataIndex: 'HealthInsuranceInformation',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Primary Residence',
            dataIndex: 'PrimaryResidence',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Public Or Private Debts',
            dataIndex: 'PublicOrPrivateDebts',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'AidCurrentlyReceived',
            dataIndex: 'AidCurrentlyReceived',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Current Assets',
            dataIndex: 'CurrentAssets',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'CurrentTransportationMeans',
            dataIndex: 'CurrentTransportationMeans',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Job Title',
            dataIndex: 'JobTitle',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Employer Name',
            dataIndex: 'EmployerName',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Employment Status',
            dataIndex: 'EmploymentStatus',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Employer PhoneNumber',
            dataIndex: 'EmployerPhoneNumber',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Date',
            dataIndex: 'CreatedDate',
            className: 'ant-table-row-cell-break-word',
            render: (time) => formateDateTimeToLocal(time, 'datetime'),
          },
        ]
      : serviceType === 'Marriage'
      ? [
          {
            title: 'Service Name',
            dataIndex: 'ServiceName',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Name',
            dataIndex: 'UserName',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Email',
            dataIndex: 'UserEmail',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Phone Number',
            dataIndex: 'UserPhoneNumber',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Bride Name',
            dataIndex: 'BrideName',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Bride Religion',
            dataIndex: 'BrideReligion',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Groom Name',
            dataIndex: 'GroomName',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Groom Religion',
            dataIndex: 'GroomReligion',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Marriage License Number',
            dataIndex: 'MarriageLicenseNumber',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Wali Wakeel Name',
            dataIndex: 'WaliWakeelName',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Wali Wakeel Relationship',
            dataIndex: 'WaliWakeelRelationship',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Witness 1 Name',
            dataIndex: 'Witness1Name',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Witness 2 Name',
            dataIndex: 'Witness2Name',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Date',
            dataIndex: 'CreatedDate',
            className: 'ant-table-row-cell-break-word',
            render: (time) => formateDateTimeToLocal(time, 'datetime'),
          },
        ]
      : serviceType === 'Funeral'
      ? [
          {
            title: 'Service Name',
            dataIndex: 'ServiceName',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Name',
            dataIndex: 'UserName',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Email',
            dataIndex: 'UserEmail',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Phone Number',
            dataIndex: 'UserPhoneNumber',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Deceased Name',
            dataIndex: 'Name',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Graveyard Name',
            dataIndex: 'GraveyardName',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Date of Birth',
            dataIndex: 'DOB',
            className: 'ant-table-row-cell-break-word',
            render: (time) => formateDateTimeToLocal(time, 'datetime'),
          },
          {
            title: 'Date of Burial',
            dataIndex: 'GraveyardName',
            className: 'ant-table-row-cell-break-word',
            render: (time) => formateDateTimeToLocal(time, 'datetime'),
          },
          {
            title: 'Date of Death',
            dataIndex: 'DateOfDeath',
            className: 'ant-table-row-cell-break-word',
            render: (time) => formateDateTimeToLocal(time, 'datetime'),
          },
        ]
      : [
          {
            title: 'Service Name',
            dataIndex: 'ServiceName',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Name',
            dataIndex: 'UserName',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Email',
            dataIndex: 'UserEmail',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Phone Number',
            dataIndex: 'UserPhoneNumber',
            className: 'ant-table-row-cell-break-word',
          },
          {
            title: 'Date',
            dataIndex: 'CreatedDate',
            className: 'ant-table-row-cell-break-word',
            render: (time) => formateDateTimeToLocal(time, 'datetime'),
          },
        ]

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
      <Table
        tableLayout="auto"
        scroll={{
          x: 'max-content',
          // y: 240,
        }}
        style={{
          width: 'auto',
          minWidth: 'unset !important',
        }}
        columns={columns}
        dataSource={registeredUsers}
        bordered={false}
        pagination
      />
    </Skeleton>
  )
}

export { RegisteredUsers }

import React, { useEffect } from 'react'
import { Typography, Row, Button, Popconfirm } from 'antd'
import { AiFillCheckCircle } from 'react-icons/ai'
import { usePaymentAccountSelector } from 'store/paymentAccountSlice/paymentAccountReducer'
import { useDispatch } from 'react-redux'
import { DeleteFilled } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import {
  LinkBusinessPaypalAccount,
  addBusinessAccountPaymentProcessor,
  deleteBusinessAccountPaymentProcessor,
} from 'store/paymentAccountSlice/paymentAccountActions'

const { Title, Paragraph } = Typography

const ConnectPaypal = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { loading, business, businessPaymentAccount } =
    usePaymentAccountSelector()
  const { paypal } = businessPaymentAccount
  const businessId = business?.Id

  const action = () => {
    history.push('/settings/manage-organization/manage-payments-accounts')
  }

  const savePaypalAccountId = async (merchantId) => {
    dispatch(
      addBusinessAccountPaymentProcessor({
        businessId,
        accountType: 'Paypal',
        accountId: merchantId,
        action: action(),
      }),
    )
  }

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (Object.entries(params).length > 0) {
      if (params.merchantIdInPayPal) {
        savePaypalAccountId(params.merchantIdInPayPal)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search])

  const linkPaypal = async () => {
    dispatch(LinkBusinessPaypalAccount({ id: businessId }))
  }

  const unlinkPaypal = async () => {
    dispatch(
      deleteBusinessAccountPaymentProcessor({
        businessId,
        accountType: 'Paypal',
      }),
    )
  }

  return (
    <>
      <Row className="t-flex t-items-center t-space-x-3">
        <Title level={5}>Paypal Account</Title>
        {paypal?.isConnected && (
          <AiFillCheckCircle
            fontSize="1.7rem"
            className="t-text-green-600 t-cursor-pointer t-mb-2"
          />
        )}
      </Row>
      <Paragraph>
        You can accept transactions through Paypal. Click the button below to
        accept Paypal payments
      </Paragraph>
      <Row className="t-space-x-4 t-flex t-items-center">
        {paypal?.isConnected ? (
          <Popconfirm
            title="Are you sure to Disconnect Your Paypal Account？"
            placement="top"
            icon={
              <DeleteFilled
                className="t-text-red-500"
                style={{ fontSize: '1rem' }}
              />
            }
            onConfirm={unlinkPaypal}
            okText="Delete"
            cancelText="Cancel"
          >
            <Button
              type="primary"
              size="small"
              className="t-bg-red-500 t-border-0"
              loading={loading.deleteBusinessPaypalPaymentProcessor}
            >
              Disconnect Paypal Account
            </Button>
          </Popconfirm>
        ) : (
          <Button
            size="small"
            type="primary"
            onClick={linkPaypal}
            loading={
              loading.linkBusinessPaypalAccount ||
              loading.addBusinessPaypalPaymentProcessor
            }
          >
            Link Paypal Account
          </Button>
        )}
      </Row>
    </>
  )
}

export { ConnectPaypal }

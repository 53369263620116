import PG_LOGO from './logo/pg-logo.png'
import PG_BACKGROUND from './background/bg.png'
import SAMPLE1 from './sample/sample1.png'
import MASTERCARD_LOGO from './sample/mastercard-logo.png'
import VISA_LOGO from './sample/visa-logo.png'
import SUCCESS from './userMessageImage/success.jpg'

export const icons = {
  pg_logo: PG_LOGO,
  pg_background: PG_BACKGROUND,
  sample1: SAMPLE1,
  mastercard_logo: MASTERCARD_LOGO,
  visa_logo: VISA_LOGO,
  success: SUCCESS,
}

import { message } from 'antd'
import Api from 'api'
import { sortByDate } from 'utils'
import { notificationActions } from './notificationReducer'

export const getUserNotifications = () => (dispatch) => {
  dispatch(notificationActions.setLoading(true))
  Api.get('/userNotifications/getUserNotifications')
    .then((res) => {
      const sortedData = sortByDate(res?.results, 'CreatedDate')
      dispatch(notificationActions.setNotifications(sortedData || []))
      dispatch(notificationActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(notificationActions.setLoading(false))
    })
}

export const readUserNotification = (id, link, action) => (dispatch) => {
  Api.get(`/userNotifications/readUserNotifications/${id}`)
    .then(() => {
      dispatch(getUserNotifications())
      if (action) action(link)
    })
    .catch((error) => {
      console.log('error', error)
      message.error('An error occured')
    })
}

export const clearUserNotification = (id) => (dispatch) => {
  dispatch(notificationActions.setClearLoading(true))
  Api.get(`/userNotifications/clearUserNotifications/${id}`)
    .then(() => {
      dispatch(notificationActions.setClearLoading(false))
      dispatch(getUserNotifications())
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(notificationActions.setClearLoading(false))
      message.error('An error occured')
    })
}

import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import Api from 'api'
import { formatAmount } from 'utils'

function PerformanceDetails({ id, type, setValue }) {
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()

  useEffect(() => {
    setLoading(true)
    Api.get(`adminReports/performanceDetails/${id}`)
      .then((res) => {
        setReport(res.results)
        setValue(res.results)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  const isEvent = type && type === 'Event'
  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      {report ? (
        <div className="t-space-y-4">
          <div className="t-flex t-items-center t-justify-between">
            <p>Goal Amount</p>
            {formatAmount(report.goalAmount)}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Donated Amount</p>
            {`${formatAmount(report.total?.Donations)} (${
              report.onlineDonationsPercentage
            }% online)`}
          </div>
          {isEvent && (
            <div className="t-flex t-items-center t-justify-between">
              <p>Pledged Amount</p>
              {`${formatAmount(report.total?.Pledges)} (${
                report.onlinePledgesPercentage
              }% online)`}
            </div>
          )}
          {isEvent && (
            <div className="t-flex t-items-center t-justify-between">
              <p>Ratio of Donation to Pledges</p>
              {report.ratioOfDonationsToPedges}
            </div>
          )}
          <div className="t-flex t-items-center t-justify-between">
            <p>Number of Donations</p>
            {report.total?.NoOfDonations}
          </div>
          {isEvent && (
            <div className="t-flex t-items-center t-justify-between">
              <p>Number of Pledges</p>
              {report.total?.NoOfPledges}
            </div>
          )}
          <div className="t-flex t-items-center t-justify-between">
            <p>Number of Attendees</p>
            {report.noOfAttendies}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Event Cost</p>
            {formatAmount(
              report.total?.Donations && report.total?.Donations * 0.02,
            )}
          </div>
        </div>
      ) : (
        <div>No Data Found</div>
      )}
    </Skeleton>
  )
}

export default PerformanceDetails

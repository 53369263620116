import React from 'react'
import { SVG } from './SVG'

const InvalidURL = ({ title, decscription }) => (
  <div className="t-flex t-items-center t-justify-center t-h-full t-w-full">
    <div className="ant-result ant-result-404">
      <SVG />
      <div className="ant-result-title">{title || 'Invalid URL'}</div>
      <div className="ant-result-subtitle">
        {decscription || 'Sorry, the page you are accessing does not exit.'}
      </div>
    </div>
  </div>
)

export { InvalidURL }

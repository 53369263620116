import { Card, Skeleton, Button, message } from 'antd'
import { SVGIcon } from 'components'
import { config } from 'configs'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { makeRequest } from 'api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { MembershipProgramPayment } from './MembershipProgramPayment'

const AssociationInvitation = () => {
  const params = useParams()
  const history = useHistory()
  const { user } = useAuthSelector()

  const [invitaion, setInvitation] = useState()
  const [loading, setLoading] = useState(true)
  const [apiError, setApiError] = useState()
  const [updating, setUpdating] = useState(false)

  const { id } = params
  const fetchData = async () => {
    try {
      setLoading(true)
      // get business association request detail
      const itemDetail = await makeRequest(
        'get',
        `/component/business/association/${id}`,
      )
      if (itemDetail.results) {
        setInvitation(itemDetail.results)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setApiError(error?.response?.data?.error || config.defaultErrorMessage)
    }
  }

  useEffect(() => {
    if (id) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const updateAssociationStatus = async (status) => {
    const data = {
      id,
      status,
      userId: user?.id,
    }
    setUpdating(true)
    makeRequest(
      'put',
      `/component/business/update-association-status`,
      { organization: invitaion?.OrganizationId },
      data,
    )
      .then((res) => {
        message.success(res.message || 'Status updated successfully')
        fetchData()
        setUpdating(false)
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
        setUpdating(false)
      })
  }
  const handleAccept = () => {
    updateAssociationStatus('Approved')
  }

  const handleReject = () => {
    updateAssociationStatus('Rejected')
  }

  const isBusiness = user?.roles.includes(config?.roles?.business)
  const invitationMessage = `You have been invited to join ${
    invitaion?.OrganizationName
  }'s business community and offer
                your services to their community members.${
                  isBusiness
                    ? ''
                    : ' You are not registered as business. Click below to register as business and when your business will be approved you can accept this invitation.'
                }`

  const isValidUser =
    !apiError &&
    invitaion &&
    (invitaion?.UserEmail === user?.email ||
      invitaion?.UserPhoneNumber === user?.phone)

  const isInvitationValidAndInvited =
    !apiError &&
    isValidUser &&
    invitaion &&
    invitaion?.Initiator === 'Organization' &&
    invitaion?.Status === 'Invited'

  const isInvitationValidAndRejected =
    !apiError &&
    isValidUser &&
    invitaion &&
    invitaion?.Initiator === 'Organization' &&
    invitaion?.Status === 'Rejected'

  const isInvitationValidAndApprovedAndPayable =
    !apiError &&
    isValidUser &&
    invitaion &&
    invitaion?.Status === 'Approved' &&
    invitaion?.IsMembershipProgramEnabled &&
    invitaion?.PaymentStatus !== 'Completed'

  const isInvitationValidAndApprovedAndNotPayable =
    !apiError &&
    isValidUser &&
    invitaion &&
    invitaion?.Status === 'Approved' &&
    !invitaion?.IsMembershipProgramEnabled

  const isInvitationValidAndApprovedAndPayableAndPaid =
    !apiError &&
    isValidUser &&
    invitaion &&
    invitaion?.Status === 'Approved' &&
    invitaion?.IsMembershipProgramEnabled &&
    invitaion?.PaymentStatus === 'Completed'

  const isSubscriberStripeAccountPresentAndOnBoarded =
    invitaion?.SubscriberPaymentInfo?.defaultCategory?.stripeID &&
    invitaion?.SubscriberPaymentInfo?.defaultCategory?.stripeOnBoarded

  const isAtLeastOneMembershipTierPresent =
    invitaion?.MembershipTiers?.length > 0

  return (
    <Card
      className="header-solid t-m-1 md:t-m-0"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0">
            {invitaion?.Initiator === 'Business'
              ? 'Membership Payment'
              : 'You have been invited'}
          </h6>
        </>,
      ]}
    >
      <Skeleton
        loading={loading}
        active
        paragraph={{ rows: 12 }}
        className="t-w-full t-h-full"
      >
        {/* If and error occured in api calls */}
        {apiError && (
          <div className="ant-result ant-result-404">
            <SVGIcon />
            <div className="ant-result-title">Invalid Url</div>
            <div className="ant-result-subtitle">{apiError}</div>
          </div>
        )}
        {/* If user is the one invitation was sent to */}
        {!apiError && !isValidUser && (
          <div className="ant-result ant-result-404">
            <SVGIcon />
            <div className="ant-result-title">Invalid User</div>
            <div className="ant-result-subtitle">
              {`Invitation's email and phone don't match with authenticated user`}
            </div>
          </div>
        )}

        {/* If association data is fetched successfully */}

        {/* If Status is invited */}
        {isInvitationValidAndInvited && (
          <div className="t-space-y-1">
            <div>{invitationMessage}</div>
            {isBusiness ? (
              <div className="t-flex t-justify-end t-items-center t-space-x-1">
                <Button
                  loading={updating}
                  type="secondary"
                  onClick={handleReject}
                >
                  Reject
                </Button>
                <Button
                  loading={updating}
                  type="primary"
                  onClick={handleAccept}
                >
                  Accept
                </Button>
              </div>
            ) : (
              <div className="t-flex t-justify-end t-items-center t-space-x-1">
                <Button
                  type="primary"
                  onClick={() => history.push('/settings/register-business')}
                >
                  Register Business
                </Button>
              </div>
            )}
          </div>
        )}

        {/* If Status is rejected */}
        {isInvitationValidAndRejected && (
          <div className="ant-result ant-result-404">
            <SVGIcon />
            <div className="ant-result-title">Successfully Rejected</div>
            <div className="ant-result-subtitle">
              You have successfully rejected association invitaion for{' '}
              {invitaion?.OrganizationName}
            </div>
          </div>
        )}

        {/* If Status is approved and organization has not enabled membership program */}
        {isInvitationValidAndApprovedAndNotPayable && (
          <div className="ant-result ant-result-404">
            <SVGIcon />
            <div className="ant-result-title">Successfully Associated</div>
            <div className="ant-result-subtitle">
              You have successfully associated with{' '}
              {invitaion?.OrganizationName}
            </div>
          </div>
        )}

        {/* If Status is approved and is payable */}
        {isInvitationValidAndApprovedAndPayable &&
          invitaion?.IsMembershipProgramEnabled && (
            <>
              {isSubscriberStripeAccountPresentAndOnBoarded &&
              isAtLeastOneMembershipTierPresent ? (
                <MembershipProgramPayment item={invitaion} />
              ) : isSubscriberStripeAccountPresentAndOnBoarded ? (
                <div className="ant-result ant-result-404">
                  <SVGIcon />
                  <div className="ant-result-title">Tier not added</div>
                  <div className="ant-result-subtitle">
                    Organization has not added any membership tier
                  </div>
                </div>
              ) : (
                <div className="ant-result ant-result-404">
                  <SVGIcon />
                  <div className="ant-result-title">Payment not enabled</div>
                  <div className="ant-result-subtitle">
                    Organization has not added payment acount
                  </div>
                </div>
              )}
            </>
          )}

        {isInvitationValidAndApprovedAndPayableAndPaid && (
          <div className="ant-result ant-result-404">
            <SVGIcon />
            <div className="ant-result-title">Already Paid</div>
            <div className="ant-result-subtitle">
              You have successfully paid for {invitaion?.OrganizationName}`s
              membership program
            </div>
          </div>
        )}
      </Skeleton>
    </Card>
  )
}

export { AssociationInvitation }

import React, { useEffect, useState } from 'react'
import { Skeleton, Table } from 'antd'
import Api from 'api'
import { formatAmount, formateDate } from 'utils'

function RentalPerformanceDetail({ id, setValue, type }) {
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()

  useEffect(() => {
    setLoading(true)
    Api.get(`component/room-reservation/performance/${id}`)
      .then((res) => {
        const data = res.results
        setReport(data)
        setValue(data)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  const TotalAmount = (dataArray) => {
    const total = dataArray.reduce(
      (acc, obj) => acc + Number(obj.GrossAmount),
      0,
    )
    return formatAmount(total)
  }

  const baseColumns = [
    {
      title: 'Name',
      dataIndex: 'CreatorName',
      key: 'CreatorName',
    },
    {
      title: 'Phone Number',
      dataIndex: 'CreatorPhoneNumber',
      key: 'CreatorPhoneNumber',
    },
    {
      title: 'Email',
      dataIndex: 'CreatorEmail',
      key: 'CreatorEmail',
    },
    {
      title: 'Amount',
      dataIndex: 'GrossAmount',
      key: 'GrossAmount',
      render: formatAmount,
    },
    {
      title: 'Payment Date',
      dataIndex: 'PaymentDate',
      key: 'PaymentDate',
      render: formateDate,
    },
    {
      title: 'Duration From',
      key: 'Duration From',
      render: (record) => record.Durations[0].from,
    },
    {
      title: 'Duration To',
      key: 'Duration To',
      render: (record) => record.Durations[0].to,
    },
    {
      title: 'Payment Type',
      dataIndex: 'PaymentType',
      key: 'PaymentType',
    },
    {
      title: 'Payment Method',
      dataIndex: 'PaymentMethod',
      key: 'PaymentMethod',
    },
  ]

  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      {report && report.length > 0 ? (
        <>
          <div className="t-flex t-items-center t-mb-8">
            <h1 className="t-font-semibold t-mx-2">{`Total amount of ${type}:`}</h1>
            <p className="t-font-semibold">{TotalAmount(report)}</p>
          </div>
          <div>
            <Table
              dataSource={report}
              columns={baseColumns}
              rowKey={(record) => record.Id}
              // components={components}
              scroll={{ x: true }} // Enables horizontal scrolling
              pagination={false} // Disable default pagination
              // loading={loading}
            />
          </div>
        </>
      ) : (
        <div>No Data Found</div>
      )}
    </Skeleton>
  )
}

export default RentalPerformanceDetail

import { combineReducers } from 'redux'
import { authReducer } from 'store/authSlice/authReducer'
import { organizationReducer } from 'store/organizationSlice/organizationReducer'
import { favouriteReducer } from 'store/favouriteSlice/favouriteReducer'
import { eventReducer } from 'store/eventSlice/eventReducer'
import { programReducer } from 'store/programSlice/programReducer'
import { serviceReducer } from 'store/serviceSlice/serviceReducer'
import { volunteerReducer } from 'store/volunteerSlice/volunteerReducer'
import { roomReducer } from 'store/roomSlice/roomReducer'
import { notificationReducer } from 'store/notificationSlice/notificationReducer'
import { businessReducer } from 'store/businessSlice/businessReducer'
import { auctionReducer } from 'store/auctionSlice/auctionReducer'
import { platformSubscriptionReducer } from 'store/platformSubscriptionSlice/platformSubscriptionReducer'
import { paymentAccountReducer } from 'store/paymentAccountSlice/paymentAccountReducer'
import { hardwareReducer } from 'store/hardwareSlice/hardwareReducer'
import { fundraiserReducer } from 'store/fundraiserSlice/fundraiserReducer'
import { organizationProfilesReducer } from 'store/organizationProfilesSlice/OrganizationProfilesReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  organization: organizationReducer,
  favourite: favouriteReducer,
  event: eventReducer,
  fundraiser: fundraiserReducer,
  program: programReducer,
  service: serviceReducer,
  volunteer: volunteerReducer,
  room: roomReducer,
  notification: notificationReducer,
  business: businessReducer,
  auction: auctionReducer,
  platformSubscription: platformSubscriptionReducer,
  paymentAccount: paymentAccountReducer,
  hardware: hardwareReducer,
  organizationProfiles: organizationProfilesReducer,
})

export { rootReducer }

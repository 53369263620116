import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { sortByString, sortByZipcode } from 'utils'

const initialState = {
  loading: false,
  business: null,
  businesses: [],
  lastEvaluatedKey: null,
  allBusinesses: [],
  allLastEvaluatedKey: null,
  organizationBusinesses: [],
  associationRequests: [],
  businessServiceRequests: [],
  userServiceRequests: [],
  businessServiceRequest: null,
  businessPaymentInfo: {
    stripe: {
      isConnected: false,
      stripeId: null,
    },
    paypal: {
      isConnected: false,
      paypalId: null,
    },
    markup: 1,
  },
}

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = { ...state, loading: payload }
      return alteredState
    },
    setBusiness: (state, { payload }) => {
      const alteredState = { ...state, business: payload }
      return alteredState
    },
    setBusinesses: (state, { payload }) => {
      const { isFirstLoad, data, key } = payload
      const updatedBusinesses = isFirstLoad
        ? data
        : [...state.businesses, ...data]
      const sortedata = sortByString(updatedBusinesses, 'Name')
      const alteredState = {
        ...state,
        businesses: sortedata,
        lastEvaluatedKey: key,
      }
      return alteredState
    },
    setAllBusinesses: (state, { payload }) => {
      const { isFirstLoad, data, key, zipCode } = payload
      const updatedBusinesses = isFirstLoad
        ? data
        : [...state.updatedBusinesses, ...data]
      const sortedata = sortByZipcode(updatedBusinesses, 'ZipCode', zipCode)
      const alteredState = {
        ...state,
        allBusinesses: sortedata,
        allLastEvaluatedKey: key,
      }
      return alteredState
    },
    setOrganizationBusinesses: (state, { payload }) => {
      const alteredState = {
        ...state,
        organizationBusinesses: payload,
      }
      return alteredState
    },
    // updateOrgFav: (state, { payload }) => {
    //   const alteredState = {
    //     ...state,
    //     organization: {
    //       ...state.organization,
    //       isFav: payload?.isFav,
    //       favId: payload?.favId,
    //     },
    //   }
    //   return alteredState
    // },
    setBusinessesForAdmin: (state, { payload }) => {
      const alteredState = {
        ...state,
        businesses: payload,
      }
      return alteredState
    },
    setAssociationRequests: (state, { payload }) => {
      const alteredState = {
        ...state,
        associationRequests: payload,
      }
      return alteredState
    },
    setBusinessServiceRequests: (state, { payload }) => {
      const alteredState = {
        ...state,
        businessServiceRequests: payload,
      }
      return alteredState
    },
    setBusinessServiceRequest: (state, { payload }) => {
      const alteredState = {
        ...state,
        businessServiceRequest: payload,
      }
      return alteredState
    },
    setUserServiceRequests: (state, { payload }) => {
      const alteredState = {
        ...state,
        userServiceRequests: payload,
      }
      return alteredState
    },
    setBusinessStripeInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        businessPaymentInfo: {
          ...state.businessPaymentInfo,
          stripe: {
            isConnected: payload.isConnected,
            stripeId: payload.stripeId,
          },
        },
      }
      return alteredState
    },
    setBusinessPaypalInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        businessPaymentInfo: {
          ...state.businessPaymentInfo,
          paypal: {
            isConnected: payload.isConnected,
            paypalId: payload.paypalId,
          },
        },
      }
      return alteredState
    },
    setBusinessMarkupInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        businessPaymentInfo: {
          ...state.businessPaymentInfo,
          markup: payload,
        },
      }
      return alteredState
    },
    reset: () => initialState,
  },
})

export const useBusinessSelector = () => useSelector((state) => state.business)
export const businessActions = businessSlice.actions
export const businessReducer = businessSlice.reducer

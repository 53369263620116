import { REGEXS } from 'utils'
import * as Yup from 'yup'

const zipCodeRegex = '^[0-9]{5}(?:-[0-9]{4})?$'
const websiteRegex = new RegExp(
  '^(https?:\\/\\/)?' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$',
  'i',
)
const phoneNumberValidation = '^[0-9-() ]{14,15}$'

const numberValidation = new RegExp('[0-9]|,')
const amountValidation = REGEXS.amountRegex

const schema = Yup.object().shape({
  Title: Yup.string().required('Event title is required'),
  EventType: Yup.string().required('Event type is required'),
  zipCode: Yup.string()
    .required('Zip code is required')
    .matches(zipCodeRegex, 'Invalid Zip code e.g 12345'),
  TargetAmount: Yup.string()
    .required('Target amount is required')
    .matches(numberValidation, 'Invalid amount'),
  YoutubeLink: Yup.string().test(
    'youtube-link',
    'Invalid website url e.g https://www.youtube.com/watch?v=Ke90Tje7VS0',
    (value) => value === '' || websiteRegex.test(value),
  ),
  SpeakerName: Yup.string().required('Speaker name is required'),
  SpeakerBio: Yup.string()
    .required('Speaker bio is required')
    .min(10, 'Speaker bio should be atleast 10 character'),
  monthlyPaymentAllowed: Yup.boolean().required('Field is Required'),
  monthlyPaymentMonths: Yup.string()
    .required('Month is Required')
    .test((val) => parseInt(val, 10) >= 1 && parseInt(val, 10) <= 36)
    .matches(numberValidation, 'Invalid Month'),
  splitPaymentAllowed: Yup.boolean().required('Field is Required'),
  splitPaymentMonths: Yup.string()
    .required('Amount is Required')
    .test((val) => parseInt(val, 10) >= 1 && parseInt(val, 10) <= 36)
    .matches(numberValidation, 'Invalid amount'),
  EditAskedAmount: Yup.string().required('Edit asked amount is required'),
  ShowDonatedAmountToDonor: Yup.string().required(
    'Show donated amount is required',
  ),
  ShowProgressBarToDonor: Yup.string().required(
    'Show progress bar is required',
  ),
  ShowOnPage: Yup.string().required('Show on landing page is required'),
  ShowOnKiosk: Yup.string().required('Show on kiosk is required'),
  TopKioskEvent: Yup.string().required('Show on kiosk is required'),
  ShowMailingAddress: Yup.string().required('Show mailing address is required'),
  Details: Yup.string()
    .required('Event Description is required')
    .min(10, 'Description should be atleast 10 character'),
  Presenter: Yup.string().required('Presenter is required'),
  PresenterName: Yup.string().required('Presenter name is required'),
  PresenterPhoneCode: Yup.string().required('Presenter phone code is required'),
  PresenterMobileNumber: Yup.string()
    .required('Presenter phone number is required')
    .matches(phoneNumberValidation, 'Invalid Phone number e.g 1234567891'),
  PresenterEmail: Yup.string()
    .required('Presenter email is required')
    .email('Email is invalid'),
  OrganizationId: Yup.string().required('Organization id is required'),
  refLink: Yup.string().required('Ref link is required'),
  Flyer: Yup.mixed().typeError('Flyer is not a file'),
  Presentation: Yup.mixed().typeError('Presentation File is not a file'),
  IsPrivate: Yup.boolean(),
  StartDate: Yup.date()
    .typeError('Invalid Date')
    .required('StartDate is required'),
  EndDate: Yup.date().typeError('Invalid Date').required('EndDate is required'),
  recaptcha: Yup.string()
    .typeError('Please complete recaptcha to proceed')
    .required('Please complete recaptcha to proceed'),
  PledgeReminders: Yup.string().required('Field is Required'),
  MessagingFrequency: Yup.string().required('Field is Required'),
  MaximumMessages: Yup.string().required('Target amount is required'),
  PaymentCategoryAdded: Yup.string().required(
    'Payment category added is required',
  ),
  DefaultDonationValue1: Yup.string()
    .required('Amount is Required')
    .matches(amountValidation, 'Invalid amount'),
  DefaultDonationValue2: Yup.string()
    .required('Amount is Required')
    .matches(amountValidation, 'Invalid amount'),
  DefaultDonationValue3: Yup.string()
    .required('Amount is Required')
    .matches(amountValidation, 'Invalid amount'),
  DefaultDonationValue4: Yup.string()
    .required('Amount is Required')
    .matches(amountValidation, 'Invalid amount'),
})

const eventValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { eventValidation }

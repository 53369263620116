import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: false,
  organization: null,
  organizations: [],
  lastEvaluatedKey: null,
  allOrganizations: [],
  allLastEvaluatedKey: null,
  paymentCategoryData: {
    Name: '',
    Description: '',
    StripeId: null,
    PaypalId: null,
  },
}

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = { ...state, loading: payload }
      return alteredState
    },
    setOrganization: (state, { payload }) => {
      const alteredState = { ...state, organization: payload }
      return alteredState
    },
    setOrganizations: (state, { payload }) => {
      const { isFirstLoad, data, key } = payload
      const updatedOrganizations = isFirstLoad
        ? data
        : [...state.organizations, ...data]
      const alteredState = {
        ...state,
        organizations: updatedOrganizations,
        lastEvaluatedKey: key,
      }
      return alteredState
    },
    setAllOrganizations: (state, { payload }) => {
      const { isFirstLoad, data, key } = payload
      const updatedOrganizations = isFirstLoad
        ? data
        : [...state.allOrganizations, ...data]
      // const sortedata = sortByZipcode(updatedOrganizations, 'ZipCode', zipCode)
      const alteredState = {
        ...state,
        allOrganizations: updatedOrganizations,
        allLastEvaluatedKey: key,
      }
      return alteredState
    },
    updateOrgFav: (state, { payload }) => {
      const alteredState = {
        ...state,
        organization: {
          ...state.organization,
          isFav: payload?.isFav,
          favId: payload?.favId,
        },
      }
      return alteredState
    },
    setOrganizationsForAdmin: (state, { payload }) => {
      const alteredState = {
        ...state,
        organizations: payload,
      }
      return alteredState
    },
    setPaymentCategoryData: (state, { payload }) => {
      const alteredState = {
        ...state,
        paymentCategoryData: payload,
      }
      return alteredState
    },
    reset: () => initialState,
  },
})

export const useOrganizationSelector = () =>
  useSelector((state) => state.organization)
export const organizationActions = organizationSlice.actions
export const organizationReducer = organizationSlice.reducer

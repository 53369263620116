import React, { useEffect, useState } from 'react'
import { Card, Skeleton, List, Button, Popconfirm, Input, Tooltip } from 'antd'
import { IoHardwareChipSharp } from 'react-icons/io5'
import { ComponentViewHeader } from 'components/common/componentView/components'
import { useDispatch } from 'react-redux'
import { useHardwareSelector } from 'store/hardwareSlice/hardwareReducer'
import {
  deleteHardware,
  getHardwareList,
} from 'store/hardwareSlice/hardwareActions'
import { useHistory } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons'

const Hardware = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { loading, hardwareList, hardware } = useHardwareSelector()
  const { fetchHardwareList, deletingHardware } = loading
  const [searchText, setSearchText] = useState(null)

  const getData = () => {
    dispatch(getHardwareList())
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const searchHardwareRows = (rows) => {
    if (searchText) {
      return rows?.filter(
        ({ OrganizationId, OrganizationName, DeviceSerialNumber }) =>
          OrganizationId?.toLowerCase().includes(searchText.toLowerCase()) ||
          OrganizationName?.toLowerCase().includes(searchText.toLowerCase()) ||
          DeviceSerialNumber?.toLowerCase().includes(searchText.toLowerCase()),
      )
    }
    return rows
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <div className="t-flex t-flex-wrap t-justify-between">
          <ComponentViewHeader
            heading="Hardware"
            subHeading="Allocated Devices"
            buttonLabel="Add Device"
            buttonLink="/hardware/add"
          />
          <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
            <div className="header-control t-mt-0">
              <Input
                id="searchEvent"
                className="header-search t-w-36"
                placeholder="Search ..."
                prefix={<SearchOutlined />}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
            </div>
          </div>
        </div>,
      ]}
    >
      <Skeleton loading={fetchHardwareList} active paragraph={{ rows: 18 }}>
        <List
          itemLayout="horizontal"
          dataSource={searchHardwareRows(hardwareList) || []}
          split={false}
          className="conversations-list"
          renderItem={(item) => (
            <List.Item
              key={item.Id}
              actions={[
                <Button
                  type="primary"
                  size="small"
                  className="t-w-24 t-border-0"
                  onClick={() =>
                    history.push(
                      `/hardware/add/${item?.OrganizationId}/${item?.Id}`,
                    )
                  }
                >
                  Edit
                </Button>,
                <Popconfirm
                  title="Are you sure you want to delete hardware"
                  placement="top"
                  onConfirm={() => {
                    dispatch(deleteHardware(item, getData))
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="primary"
                    size="small"
                    className="t-w-24 t-border-0 t-bg-red-500"
                    loading={deletingHardware && hardware?.Id === item?.Id}
                  >
                    Delete
                  </Button>
                </Popconfirm>,
              ]}
            >
              <List.Item.Meta
                key={item.Id}
                avatar={
                  <IoHardwareChipSharp
                    fontSize="3rem"
                    className="t-text-secondary-100 t-cursor-pointer"
                  />
                }
                title={
                  <Tooltip title="Serial Number">
                    {item.DeviceSerialNumber}
                  </Tooltip>
                }
                description={
                  <Tooltip title="Organization Name">
                    {item.OrganizationName || 'Organization'}
                  </Tooltip>
                }
              />
            </List.Item>
          )}
        />
      </Skeleton>
    </Card>
  )
}

export { Hardware }

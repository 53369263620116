import React, { useEffect, useState } from 'react'
import {
  Form,
  Row,
  Col,
  Card,
  message,
  Button,
  Statistic,
  Typography,
  Select,
  Modal,
} from 'antd'
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { awsconfig, config } from 'configs'
import { loadStripe } from '@stripe/stripe-js'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { membershipProgramPayment } from 'store/authSlice/authActions'
import { addProtocolToURL, formatAmount } from 'utils'
import { MdOutlinePayment } from 'react-icons/md'
import { UserMessageView } from 'components/elements'
import { TermsAndConditions } from 'screens/auth/components'

const { Paragraph } = Typography
const initialValues = {
  card: 'no-card-selected',
  tier: '',
}

const WrappedSubscriptionForm = ({ item }) => {
  const { user } = useAuthSelector()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [successfullyPaid, setSuccessfullyPaid] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  Form.useWatch('card', form)
  Form.useWatch('tier', form)
  const tierValue = form.getFieldValue('tier')
  const selectedTier = item?.MembershipTiers?.find((t) => t.Id === tierValue)

  const MembershipTiers = item?.MembershipTiers || []
  useEffect(() => {
    if (MembershipTiers.length > 0) {
      form.setFieldsValue({
        tier: MembershipTiers[0].Id,
      })
    }
    // eslint-disable-next-line
  }, [MembershipTiers])

  /* eslint-disable consistent-return */
  const onFinish = async (values) => {
    if (!user) {
      message.error('User not found')
      return false
    }
    let cardToken = null
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    })
    if (error) {
      message.error(error?.message || 'Invalid Card Information')
      return false
    }
    cardToken = paymentMethod.id

    const StripeAccountID =
      item?.SubscriberPaymentInfo?.defaultCategory?.stripeID
    const SelectedTier = item?.MembershipTiers?.find(
      (t) => t.Id === values?.tier,
    )
    const payload = {
      cardToken,
      stripeAccount: StripeAccountID,
      commission: item?.SubscriberPaymentInfo?.markup,
      amount: Number(selectedTier?.Amount),
      user: {
        id: user?.id,
        email: user?.email,
        firstname: user?.firstname,
        lastname: user?.lastname,
        phone: user?.phone,
        // countryCode: data.countryCode,
        // phoneNumber: data.phoneNumber?.replace(/\D/g, ''),
      },
      location: null,
      item,
      selectedTier: SelectedTier,
    }
    setLoading(true)
    try {
      await membershipProgramPayment(payload)
      message.success(
        'You have successfully subscribed to the membership program',
      )
      setSuccessfullyPaid(true)
      setTimeout(() => {
        const organizationWebsiteUrl = item?.OrganizationWebsite
        if (organizationWebsiteUrl) {
          window.open(addProtocolToURL(organizationWebsiteUrl), '_self')
        }
      }, 5000)
    } catch (error1) {
      message.error(error1?.response?.data?.error || config.defaultErrorMessage)
    }
    setLoading(false)
  }

  return (
    <>
      {!successfullyPaid ? (
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="donation"
          scrollToFirstError
          initialValues={initialValues}
        >
          <Row gutter={[24, 0]} className="t-mb-8 t-space-y-2">
            <Col xs={24} sm={24} md={8} xl={8}>
              <Card
                className="header-solid t-p-2 md:t-p-10 t-m-4 t-shadow-2xl t-rounded-lg widget-2 t-h-full t-flex t-justify-center t-items-center"
                bordered={false}
              >
                <Statistic
                  title={
                    <>
                      <div className="icon">
                        <MdOutlinePayment />
                      </div>
                      <h6 className="t-text-secondary-100 t-text-2xl t-font-semi">
                        {selectedTier?.Title}
                      </h6>
                      <p>{selectedTier?.Description}</p>
                    </>
                  }
                  value={formatAmount(selectedTier?.Amount)}
                />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={16} xl={16}>
              <Card
                className="header-solid t-p-2 md:t-p-10 t-m-4 t-shadow-2xl t-rounded-lg t-h-full"
                bordered={false}
              >
                <Col xl={24} md={24} xs={24} sm={24}>
                  <Paragraph className="t-text-justify t-mt-2">
                    Please select suitable membership level with{' '}
                    <b>{item?.OrganizationName}</b> and proceed with your
                    payment.
                  </Paragraph>
                </Col>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <Form.Item
                    name="tier"
                    label="Select Membership Level"
                    // rules={[membershipProgramValidation]}
                    className="t-p-0 t-my-2"
                  >
                    <Select placeholder="Select Tier" style={{ width: '100%' }}>
                      {MembershipTiers.map((tier, index) => (
                        <Select.Option value={tier.Id} key={index}>
                          {tier.Title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <h1 className="t-text-sm t-my-2 t-font-semibold">
                    Enter Card Number
                  </h1>
                  <div className="t-p-2 t-rounded-md t-border t-border-grey-900'">
                    <CardElement
                      options={{
                        style: {
                          base: {
                            fontSize: '15px',
                            fontSmoothing: 'antialiased',
                          },
                        },
                      }}
                    />
                  </div>
                </Col>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <Paragraph className="t-text-justify t-mt-2">
                    Your card will be charged{' '}
                    <b>{formatAmount(selectedTier?.Amount)}</b>
                  </Paragraph>
                </Col>
                <Col span={24} className="text-right t-my-4">
                  <Button
                    type="primary"
                    className="t-w-full"
                    htmlType="submit"
                    loading={loading}
                    disabled={!stripe || !elements}
                  >
                    Submit
                  </Button>
                </Col>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <Paragraph className="t-text-justify t-mt-2">
                    <b>Note:</b> Please note that membership dues will be
                    automatically renewed at the start of each calendar year.{' '}
                    <span
                      className="font-bold text-dark hover:t-text-secondary-100 t-cursor-pointer"
                      onClick={() => setIsModalVisible(true)}
                    >
                      Terms and Conditions
                    </span>
                  </Paragraph>
                  <Modal
                    title="Terms Of Use Policy"
                    open={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    footer={null}
                  >
                    <TermsAndConditions />
                  </Modal>
                </Col>
              </Card>
            </Col>
          </Row>
        </Form>
      ) : (
        <UserMessageView
          backgroundImageName="success"
          heading="Thank You"
          subHeading="You have successfully paid for membership"
          redirectLink="/settings/manage-organization/business-detail"
          redirectLinkText="View Business Details"
        />
      )}
    </>
  )
}

function StripeProvider({ item }) {
  const subscriberPaymentInfo = item?.SubscriberPaymentInfo?.defaultCategory

  const stripePromise = loadStripe(awsconfig.stripe_publish_key, {
    stripeAccount: subscriberPaymentInfo?.stripeID,
  })

  return (
    <Elements stripe={stripePromise}>
      <WrappedSubscriptionForm item={item} />
    </Elements>
  )
}
const MembershipProgramPayment = StripeProvider
export { MembershipProgramPayment }

import React, { useEffect } from 'react'
import { Avatar, Card, List } from 'antd'
import { getUserNotifications } from 'store/notificationSlice/notificationActions'
import { useNotificationSelector } from 'store/notificationSlice/notificationReducer'
import { useDispatch } from 'react-redux'
import { FormatedNotifications } from 'components/elements'

const Notifications = () => {
  const dispatch = useDispatch()
  const { notifications } = useNotificationSelector()

  useEffect(() => {
    dispatch(getUserNotifications())
    // eslint-disable-next-line
  }, [])

  const data = FormatedNotifications(notifications) || []

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0">Notifications</h6>
        </>,
      ]}
    >
      <List
        min-width="100%"
        itemLayout="horizontal"
        className="ant-dropdown-menu ant-dropdown-menu-split header-notifications-dropdown"
        dataSource={data}
        locale={{ emptyText: 'No new notifications' }}
        renderItem={(item) => (
          <List.Item
            className={`${
              item.read === false && `t-bg-slate-200 t-rounded-md`
            }`}
          >
            <List.Item.Meta
              avatar={<Avatar shape="square" src={item.avatar} />}
              title={item.title}
              description={item.description}
            />
          </List.Item>
        )}
      />
    </Card>
  )
}

export { Notifications }

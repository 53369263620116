import React from 'react'
import { Popconfirm, Skeleton, Table, Tooltip, message } from 'antd'
import { MdDelete } from 'react-icons/md'
// import { FaEdit } from 'react-icons/fa'
import Api from 'api'
// import { EditDonorModal } from './EditDonorModal'

const RecurringDonorList = ({
  loading,
  data,
  getData,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  // const [showModal, setShowModal] = useState(false)
  // const [editData, setEditData] = useState()

  const formateData = (values) => {
    const formatedData = values?.map((item) => ({
      ...item,
      key: item.Id,
    }))
    return formatedData || []
  }

  const onDelete = (Id) => {
    Api.delete(`recurringDonors/donorList/donor/${Id}`)
      .then(() => {
        message.success('Deleted successfully')
        getData()
      })
      .catch((error) => {
        message.error('An error occurred')
        console.log('error', error)
      })
  }

  // const onEdit = (Id) => {
  //   const formatedData = data.find((item) => item.Id === Id)
  //   setEditData(formatedData)
  //   setShowModal(true)
  // }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'DonorName',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Email',
      dataIndex: 'DonorEmail',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Phone Number',
      dataIndex: 'DonorPhoneNumber',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Amount',
      dataIndex: 'RecurringAmount',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Months',
      dataIndex: 'RecurringMonths',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Outreach Count',
      dataIndex: 'OutreachCount',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Status',
      dataIndex: 'RecurringStatus',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, { Id }) => (
        <Tooltip title="Delete">
          <Popconfirm
            title="Are you sure you want to delete this recurring donor?"
            onConfirm={() => onDelete(Id)}
          >
            <MdDelete
              fontSize="1.3rem"
              className="t-text-secondary-100 t-cursor-pointer"
            />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ]

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
      <Table
        tableLayout="auto"
        scroll={{ x: 500 }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={formateData(data)}
        bordered={false}
        pagination={false}
      />
      {/* {showModal && (
        <EditDonorModal
          isVisible={showModal}
          onClose={() => {
            setEditData()
            setShowModal(false)
          }}
          editData={editData}
          setEditData={setEditData}
          getData={getData}
        />
      )} */}
    </Skeleton>
  )
}

export { RecurringDonorList }

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Layout, Card, message } from 'antd'
import { Splash } from 'screens'
import { useDispatch } from 'react-redux'
import {
  checkUserExist,
  sendOtp,
  signIn,
  signUp,
  isEmailAlreadyExist,
} from 'store/authSlice/authActions'
import { useHistory } from 'react-router-dom'
import { authActions, useAuthSelector } from 'store/authSlice/authReducer'
import { OTPForm, LoginForm, RegisterForm } from './components'
import { Icon } from 'components/elements'
const { Content } = Layout

const Auth = () => {
  const { loading, extras, user } = useAuthSelector()
  const { userPhone, userTemp } = extras
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (user) {
      // history.push(`/organizations`)
    } else {
      history.push(`${history.location.pathname}${history.location.search}`)
    }
  }, [user, history])

  const [isSplash, setIsSplash] = useState(true)
  const [authSetup, setAuthSetup] = useState('signin')

  const goTo = (option) => {
    setAuthSetup(option)
  }

  const navigate = (option) => {
    history.push(`${option}`)
  }

  const onSignInSumbit = (values) => {
    const { countryCode, phoneNumber } = values
    // removing zero if it's the first charater in the string
    const trimPhoneNumber = phoneNumber.replace(/^0+/, '')
    const phone = countryCode + trimPhoneNumber
    dispatch(checkUserExist(phone, goTo))
  }

  const onOtpSumbit = (values) => {
    const code = values.optCode
    dispatch(signIn(userTemp, code, navigate))
  }

  const onSignUpSumbit = async (values) => {
    dispatch(authActions.setLoading(true))
    const isExist = await isEmailAlreadyExist(values.email)
    dispatch(authActions.setLoading(false))
    if (isExist) {
      message.error('Email is already in use')
    } else {
      if (userPhone) {
        dispatch(
          signUp(
            userPhone,
            {
              family_name: values.lastName,
              given_name: values.firstName,
              email: values.email,
            },
            goTo,
          ),
        )
      }
    }
  }

  const resendOtp = () => {
    if (userPhone) {
      dispatch(sendOtp(userPhone, goTo))
    }
  }

  return (
    <>
      {isSplash ? (
        <Splash text="Elevate Your Cause!" setIsSplash={setIsSplash} />
      ) : (
        <div className="layout-default ant-layout layout-sign-up ">
          <Content className="p-0">
            <div className="t-relative t-h-[50vh]">
              <Icon
                src="pg_background"
                className="t-absolute t-object-cover t-w-full t-h-full t-rounded-xl"
                // className="t-absolute t-object-cover t-w-full t-h-[65%] md:t-h-[70%] t-rounded-xl"
              />
            </div>
            <Card
              className="card-signup header-solid ant-card pt-0"
              bordered="false"
              bodyStyle={{ paddingTop: '0px' }}
            >
              <div className="text-center">
                <h5 className="font-semibold" style={{ marginTop: '1rem' }}>
                  {authSetup === 'signup' ? 'Sign Up' : 'Log in / Register'}
                </h5>
                <h6 className="font-regular text-muted t-my-2" level={5}>
                  {authSetup === 'signup'
                    ? 'Please provide your info below'
                    : authSetup === 'otp'
                    ? 'We have texted you the OTP (One Time Password) to your registered number. Please enter the OTP below.'
                    : 'Please provide your mobile phone number'}
                </h6>
              </div>
              <>
                {authSetup === 'signup' ? (
                  <RegisterForm
                    loading={loading}
                    onSubmit={onSignUpSumbit}
                    goTo={goTo}
                  />
                ) : authSetup === 'otp' ? (
                  <OTPForm
                    loading={loading}
                    onSubmit={onOtpSumbit}
                    resendOtp={resendOtp}
                  />
                ) : (
                  <LoginForm loading={loading} onSubmit={onSignInSumbit} />
                )}
              </>
            </Card>
          </Content>
        </div>
      )}
    </>
  )
}

export { Auth }

import React, { useEffect, useState } from 'react'
import { Row, Button, message } from 'antd'
import { authActions, useAuthSelector } from 'store/authSlice/authReducer'
import {
  LinkPaypalAccount,
  savePaypalID,
  UnlinkPaymentCategoryPaypalAccount,
} from 'store/paymentSlice/paymentActions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DisconnectButton } from './DisconnectButton'

const DefaultPaypalButton = ({
  refreshPayment,
  OrganizationId,
  Id,
  Name,
  Type,
  PaypalId,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { user, organization } = useAuthSelector()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const storePaypalID = async (merchantId) => {
      try {
        dispatch(authActions.setLoading(true))
        const data = {
          paypalId: merchantId,
          organizationEmail: user.email,
          organizationId: organization?.id,
        }
        await savePaypalID(data)
        refreshPayment()
        dispatch(authActions.setLoading(false))
        history.push('/settings/manage-payments-accounts')
      } catch (error) {
        message.error('Error Connecting Paypal')
      }
    }
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (Object.entries(params).length > 0) {
      if (params.merchantIdInPayPal && !params.category_id) {
        storePaypalID(params.merchantIdInPayPal)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search, user])

  const linkPaypal = async () => {
    try {
      setLoading(true)
      const response = await LinkPaypalAccount()
      if (response) {
        window.open(response, '_self')
      } else {
        setLoading(false)
        message.error('Something Went Wrong')
      }
    } catch (error) {
      setLoading(false)
      message.error('Something Went Wrong')
    }
  }

  const unlinkPaypal = async () => {
    try {
      setLoading(true)
      await UnlinkPaymentCategoryPaypalAccount(Id)
      refreshPayment()
      setLoading(false)
      message.success('Paypal Account Disconnected')
    } catch (error) {
      message.error('Something Went Wrong')
      setLoading(false)
    }
  }

  return (
    <>
      <Row className="t-space-x-4 t-flex t-items-center">
        {PaypalId ? (
          <DisconnectButton
            loading={loading}
            handleDisconnent={unlinkPaypal}
            organizationId={OrganizationId}
            paymentCategoryId={Id}
            categoryName={Name}
            paymentType={Type}
            accountId={PaypalId}
          />
        ) : (
          <Button
            size="small"
            type="primary"
            onClick={linkPaypal}
            className="payment-setup-button t-bg-orange-500"
            loading={loading}
          >
            {!loading ? 'Connect' : 'Loading'}
          </Button>
        )}
      </Row>
    </>
  )
}

export { DefaultPaypalButton }

import React, { useEffect } from 'react'
import { Typography, Row, Button, Tag, Popconfirm } from 'antd'
import { AiFillCheckCircle } from 'react-icons/ai'
import { useAuthSelector } from 'store/authSlice/authReducer'
import {
  paymentAccountActions,
  usePaymentAccountSelector,
} from 'store/paymentAccountSlice/paymentAccountReducer'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { DeleteFilled } from '@ant-design/icons'
import { config } from 'configs'
import {
  addBusinessAccountPaymentProcessor,
  deleteBusinessAccountPaymentProcessor,
  getStripeAccountInfo,
  updateBusinessStripeAccount,
} from 'store/paymentAccountSlice/paymentAccountActions'
import { getStripeConnectOAuthLink } from 'utils'

const { Title, Paragraph } = Typography

const ConnectStripe = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { user } = useAuthSelector()
  const { loading, business, businessPaymentAccount, stripeAccountInfo } =
    usePaymentAccountSelector()
  const businessId = business?.Id
  const { stripe } = businessPaymentAccount
  const isAccountPresent = stripe?.stripeId

  const action = () => {
    history.push('/settings/manage-organization/manage-payments-accounts')
  }

  const saveStripeAccountId = async (ACCOUNT_ID) => {
    dispatch(
      addBusinessAccountPaymentProcessor({
        businessId,
        accountType: 'Stripe',
        accountId: ACCOUNT_ID,
        action: action(),
      }),
    )
  }

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (Object.entries(params).length > 0) {
      if (params.account_id) {
        saveStripeAccountId(params.account_id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search])

  useEffect(() => {
    if (isAccountPresent) {
      dispatch(getStripeAccountInfo({ accountId: stripe?.stripeId }))
    } else {
      dispatch(paymentAccountActions.resetStripeAccountInfo())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountPresent])

  const linkStripe = async () => {
    const link = getStripeConnectOAuthLink({ caller: 'ConnectBusiness' })
    window.open(link, '_self')
  }

  const updateStripe = async () => {
    if (isAccountPresent) {
      dispatch(
        updateBusinessStripeAccount({
          accountId: stripe?.stripeId,
          connectorType: 'Business',
        }),
      )
    }
  }

  const unlinkStripe = async () => {
    dispatch(
      deleteBusinessAccountPaymentProcessor({
        businessId,
        accountType: 'Stripe',
      }),
    )
  }

  // Check if stripe account has pending verification, failed verfication or requirements
  const isPending = stripeAccountInfo?.pending?.length > 0
  const isErrors = stripeAccountInfo?.errors?.length > 0
  const isRequirements = stripeAccountInfo?.requirements?.length > 0
  const isPendingOrErrorORRequirements = isPending || isErrors || isRequirements

  // Check if stripe account is onboarded successfully and ready to receive payments
  const detailsSubmitted = stripeAccountInfo?.details_submitted
  const chargesEnabled = stripeAccountInfo?.charges_enabled
  const isOnBoardedSuccessfully = detailsSubmitted && chargesEnabled
  const isReadyToReceivePayments =
    isOnBoardedSuccessfully && !isPendingOrErrorORRequirements

  const isAdmin = user?.roles?.includes(config.roles.admin)
  const isLoading =
    loading.getStripeAccountInfo || loading.addBusinessStripePaymentProcessor

  return (
    <>
      <Row className="t-flex t-items-center t-space-x-3">
        <Title level={5}>Stripe Account</Title>
        {isReadyToReceivePayments && (
          <AiFillCheckCircle
            fontSize="1.7rem"
            className="t-text-green-600 t-cursor-pointer t-mb-2"
          />
        )}
        {/* <IoIosWarning
          fontSize="1.7rem"
          className="t-text-orange-600 t-cursor-pointer t-mb-2"
        /> */}
      </Row>

      {/* Stripe Account is not linked */}
      {!isAccountPresent && (
        <>
          <Paragraph>
            You can accept credit cards payment through Stripe. Click the button
            below to connect your Stripe account
          </Paragraph>
          <Button
            size="small"
            type="primary"
            className="t-border-0"
            onClick={linkStripe}
            loading={isLoading}
          >
            Link Stripe Account
          </Button>
        </>
      )}

      {/* Stripe Account is present */}
      {isAccountPresent && (
        <>
          {/* Stripe Account is On Boarded Successfully */}
          {isReadyToReceivePayments && (
            <>
              <Paragraph>
                You can accept credit cards payment through Stripe. Click the
                button below to connect your Stripe account
              </Paragraph>

              <div className="t-space-x-4 t-flex t-items-center">
                {isAdmin && (
                  <Button
                    type="primary"
                    size="small"
                    className="t-bg-secondary-100 t-border-0"
                    loading={loading.getStripeAccountInfo}
                    onClick={() => {
                      const stripeAccountLink = `https://dashboard.stripe.com/b/${stripe?.stripeId}`
                      if (stripeAccountLink) {
                        window.open(stripeAccountLink, '_blank')
                      }
                    }}
                  >
                    View Stripe Account
                  </Button>
                )}

                <DisconnectStripeAccount
                  loading={
                    isLoading || loading.deleteBusinessStripePaymentProcessor
                  }
                  onDisconnet={unlinkStripe}
                />
              </div>
            </>
          )}

          {/* Stripe Account is not On Boarded Successfully */}
          {!isReadyToReceivePayments && (
            <>
              <Paragraph>
                Your connected stripe account have some missing/incorrect
                information. You can click below to start making the
                corrections. Stripe will inform you about the required
                information to update when you will open the link below.
              </Paragraph>
              {isPending && (
                <div className="t-my-4">
                  <Tag color="gray">Your account is under review</Tag>
                </div>
              )}
              {!isPending && !chargesEnabled && (
                <div className="t-my-4">
                  <Tag color="gray">
                    Live charges are not yet enabled on your account
                  </Tag>
                </div>
              )}
              {!isPending && isErrors && (
                <div className="t-my-4">
                  <Tag color="red">
                    {stripeAccountInfo?.errors?.[0]?.reason}
                  </Tag>
                </div>
              )}
              <div className="t-space-x-4 t-flex t-items-center">
                {!isPending && chargesEnabled && (
                  <Button
                    size="small"
                    type="primary"
                    className="t-bg-secondary-100 t-border-0"
                    onClick={updateStripe}
                    loading={isLoading || loading.updateBusinessStripeAccount}
                  >
                    Update Stripe Account
                  </Button>
                )}
                <DisconnectStripeAccount
                  loading={
                    isLoading || loading.deleteBusinessStripePaymentProcessor
                  }
                  onDisconnet={unlinkStripe}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}

const DisconnectStripeAccount = ({ loading, onDisconnet }) => (
  <Popconfirm
    title="Are you sure to Disconnect Your Stripe Account？"
    placement="top"
    icon={
      <DeleteFilled className="t-text-red-500" style={{ fontSize: '1rem' }} />
    }
    onConfirm={onDisconnet}
    okText="Delete"
    cancelText="Cancel"
  >
    <Button
      type="primary"
      size="small"
      className="t-bg-red-500 t-border-0"
      loading={loading}
    >
      Disconnect Stripe Account
    </Button>
  </Popconfirm>
)
export { ConnectStripe }

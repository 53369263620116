import { Button, Col, Divider, Form, Input, message, Row, Select } from 'antd'
import React, { useState } from 'react'
import Api from 'api'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { programValidation } from 'validations'
import MaskedInput from 'antd-mask-input'
import { MaskPattern } from 'utils'

const UpdateTeacher = ({ form, close }) => {
  const [updateform] = Form.useForm()
  const [presenterValidate, setPresenterValidate] = useState(false)
  const [presenterFound, setPresenterFound] = useState(false)

  const onFinish = (values) => {
    if (presenterValidate) {
      form.setFieldValue('TeacherPhoneCode', values.TeacherPhoneCode)
      form.setFieldValue('TeacherMobileNumber', values.TeacherMobileNumber)
      form.setFieldValue('TeacherFirstName', values.TeacherFirstName)
      form.setFieldValue('TeacherLastName', values.TeacherLastName)
      form.setFieldValue('TeacherEmail', values.TeacherEmail)
      close()
    } else {
      message.error('Kindly Validate Presenter')
    }
  }

  const validatePresenter = () => {
    const { TeacherPhoneCode, TeacherMobileNumber } =
      updateform.getFieldsValue()
    // removing - from string
    const FormatedTeacherMobileNumber = TeacherMobileNumber?.replace(/\D/g, '')
    // removing zero if it's the first charater in the string
    const trimPhoneNumber = FormatedTeacherMobileNumber?.replace(/^0+/, '')
    const phone = TeacherPhoneCode + trimPhoneNumber
    Api.get(
      `/users/check-phone-number-with-info?phone=${encodeURIComponent(phone)}`,
    )
      .then(() => {
        setPresenterValidate(true)
        setPresenterFound(false)
        updateform.setFieldValue('TeacherFirstName', '')
        updateform.setFieldValue('TeacherLastName', '')
        updateform.setFieldValue('TeacherEmail', '')
      })
      .catch((err) => {
        const success = err?.response?.data?.message
        const data = err?.response?.data?.values
        if (success === 'User Found') {
          updateform.setFieldValue('TeacherFirstName', data.firstname)
          updateform.setFieldValue('TeacherLastName', data.lastname)
          updateform.setFieldValue('TeacherEmail', data.email)
          setPresenterFound(true)
          setPresenterValidate(true)
        } else {
          message.error('Something Went Wrong')
        }
      })
  }

  const handleInputChange = (value) => {
    updateform.setFieldsValue({ TeacherMobileNumber: value })
  }
  return (
    <Form
      form={updateform}
      layout="vertical"
      key={0}
      name="Update Teacher"
      onFinish={onFinish}
    >
      <Row className="t-p-1">
        <Col span={24} className="t-flex t-justify-between t-items-center">
          <div className="t-flex t-font-bold">New Teacher Details</div>
          <Button type="primary" onClick={() => validatePresenter()}>
            Validate Teacher
          </Button>
        </Col>
        <Col>
          {!presenterValidate ? (
            <span className="font-bold t-flex t-space-x-2 t-items-center">
              <h1 className="t-text-orange-600 t-text-sm">
                Click to validate the below information
              </h1>
              <AiFillCloseCircle
                fontSize="1rem"
                className="t-text-orange-600"
              />
            </span>
          ) : presenterFound ? (
            <span className="font-bold t-flex t-space-x-2 t-items-center">
              <h1 className="t-font-bold t-text-sm">
                Teacher Found Successfully
              </h1>
              <AiFillCheckCircle fontSize="1rem" className="t-text-green-600" />
            </span>
          ) : (
            <span className="font-bold t-flex t-space-x-2 t-items-center">
              <h1 className="t-font-bold t-text-sm">
                Enter new teacher name and email address
              </h1>
              <AiFillCloseCircle
                fontSize="1rem"
                className="t-text-orange-600"
              />
            </span>
          )}
        </Col>
        <Divider />
        <Col span={24}>
          <Form.Item
            name="TeacherPhoneCode"
            label="Select Country"
            colon={false}
            rules={[programValidation]}
          >
            <Select placeholder="Select Country Code" style={{ width: '100%' }}>
              <Select.Option value="+1">
                United States/Canada (+1)
              </Select.Option>
              <Select.Option value="+54">Argentina (+54)</Select.Option>
              <Select.Option value="+92">Pakistan (+92)</Select.Option>
              <Select.Option value="+55">Brazil (+55)</Select.Option>
              <Select.Option value="+58">
                Venezuela, Bolivarian Republic of Venezuela (+58)
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="TeacherMobileNumber"
            label="Teacher Mobile Number"
            colon={false}
            rules={[programValidation]}
          >
            <MaskedInput
              inputMode="numeric"
              mask={MaskPattern}
              onChange={(e) => {
                handleInputChange(e.maskedValue)
              }}
            />
          </Form.Item>
        </Col>
        {presenterValidate === true && (
          <>
            <Col span={24}>
              <Form.Item
                name="TeacherFirstName"
                label="Teacher Name"
                colon={false}
                rules={[programValidation]}
              >
                <Input
                  placeholder="Enter first name"
                  disabled={presenterFound}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="TeacherLastName"
                label="Teacher Name"
                colon={false}
                rules={[programValidation]}
              >
                <Input
                  placeholder="Enter last name"
                  disabled={presenterFound}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="TeacherEmail"
                label="Teacher Email"
                colon={false}
                rules={[programValidation]}
              >
                <Input placeholder="Enter Email" disabled={presenterFound} />
              </Form.Item>
            </Col>
          </>
        )}
        <Divider />
        <Col span={24} className="text-right">
          <Button
            // loading={loading}
            type="primary"
            className="px-25"
            htmlType="submit"
          >
            Request
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export { UpdateTeacher }

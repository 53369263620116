import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getLocation } from 'store/authSlice/authActions'
import { authActions, useAuthSelector } from 'store/authSlice/authReducer'

const useZipCode = () => {
  const dispatch = useDispatch()
  const { customLocation } = useAuthSelector()
  const [lat, setLat] = useState(null)
  const [lng, setLng] = useState(null)

  const [isLoading, setLoading] = useState(false)
  const [isAcccessGranted, setGrantAccess] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const [message, setMessage] = useState(null)

  useEffect(() => {
    const getBrowserLocation = async () => {
      setLoading(true)
      if (!navigator.geolocation) {
        setMessage('Geolocation is not supported by your browser')
        setGrantAccess(false)
        setLoading(false)
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLat(position.coords.latitude)
            setLng(position.coords.longitude)
            setGrantAccess(true)
            setMessage('Location Fetched')
            setLoading(false)
            setSuccess(true)
          },
          (error) => {
            if (error.code === 1) {
              setGrantAccess(false)
            }
            setMessage('Unable To Retrieve Location Detail')
            setLoading(false)
          },
        )
      }
    }
    getBrowserLocation()
  }, [customLocation])

  useEffect(() => {
    if (customLocation === false) {
      if (!isLoading && isSuccess) {
        dispatch(getLocation(lat, lng))
      } else {
        dispatch(authActions.setLoaction({ data: null, customLocation: false }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess])

  return {
    isAcccessGranted,
    isLoading,
    message,
    lat,
    lng,
    isSuccess,
  }
}

export { useZipCode }

import React, { useState } from 'react'
import { Avatar, Col, Rate, Tooltip } from 'antd'
import { AiFillPhone } from 'react-icons/ai'
import { CgWebsite } from 'react-icons/cg'
import { ShareModal } from 'components/elements'
import {
  getBusinessBackgoundImageUrl,
  getBusinessProfilePictureUrl,
  isMobile,
  mobileShareNavigator,
} from 'utils'
import { BsShareFill } from 'react-icons/bs'
import { MyBusinessServices } from './MyBusinessServices'

const formatPhoneNumber = (Number) => {
  const PhoneNumber = Number.replace(/^0+/, '')
  const firstThree = PhoneNumber.substring(0, 3)
  const secondThree = PhoneNumber.substring(3, 6)
  const lastFour = PhoneNumber.substring(6, PhoneNumber.length)
  const phone = `+1 (${firstThree}) ${secondThree}-${lastFour}`

  return phone
}

const BusinessProfile = ({ business }) => {
  const [isShare, setShare] = useState(false)

  const handleShare = async () => {
    if (isMobile) {
      await mobileShareNavigator(
        business?.Name,
        `${window.location.origin}/businesses/${business.Id}`,
      )
    } else {
      setShare(true)
    }
  }

  const getAverageRating = (ratingList) => {
    if (ratingList && ratingList.length > 0) {
      const totalRatings = ratingList.reduce(
        (acc, ratingObj) => acc + ratingObj.Rating,
        0,
      )

      const averageRating = totalRatings / ratingList.length
      return averageRating
    }
    return 0
  }

  return (
    <>
      <Col span={24}>
        <div className="t-flex t-flex-col t-items-center">
          <img
            src={getBusinessBackgoundImageUrl(business?.BackgroundImage)}
            alt="card-2"
            className="t-h-48 md:t-h-80 t-w-full t-rounded-lg shadow-3 t-bg-cover t-bg-no-repeat t-object-cover t-opacity-80"
          />
          <div className="t-w-[90%] t-relative -t-top-12 glass_bg t-p-2 md:t-p-3">
            <div className="t-w-full t-flex t-justify-between">
              <Avatar.Group>
                <Avatar
                  size={70}
                  shape="square"
                  src={getBusinessProfilePictureUrl(business?.ProfilePicture)}
                />
                <div className="avatar-info t-ml-4">
                  <h4 className="font-semibold m-0 t-text-secondary-100 t-text-base md:t-text-2xl">
                    <Tooltip title="Organization Name">
                      {business?.Name}
                    </Tooltip>
                  </h4>
                  <p className="t-text-secondary-100">
                    <Tooltip title="Organization Type">
                      {business?.ServiceCoverage}
                      {/* {business?.ServiceCoverage === 'Global' ? (
                      <Tag color="orange">Global</Tag>
                    ) : (
                      <Tag color="purple">Local</Tag>
                    )} */}
                    </Tooltip>
                  </p>
                  <p className="t-text-secondary-100">
                    <Tooltip title="Organization Address">
                      {`
                        ${business.Address1 ? `${business.Address1}, ` : ''}
                        ${business.City ? `${business.City}, ` : ''}
                        ${business.State ? `${business.State}, ` : ''}
                        ${business.ZipCode ? `${business.ZipCode}` : ''}
                      `}
                    </Tooltip>
                  </p>
                  <div>
                    <Rate
                      disabled
                      defaultValue={getAverageRating(business?.Rating)}
                    />
                  </div>
                </div>
              </Avatar.Group>
              <div className="t-flex t-items-center t-space-x-2">
                <Tooltip
                  title={`Phone Number: ${
                    business?.PhoneNumber &&
                    formatPhoneNumber(business.PhoneNumber)
                  }`}
                >
                  <AiFillPhone
                    fontSize="1.2rem"
                    className="t-text-secondary-100 t-cursor-pointer"
                    onClick={() => window.open(`tel:${business?.PhoneNumber}`)}
                  />
                </Tooltip>
                <Tooltip title={`Website: ${business?.Website}`}>
                  <CgWebsite
                    fontSize="1.2rem"
                    className="t-text-secondary-100 t-cursor-pointer"
                    onClick={() => window.open(business?.Website)}
                  />
                </Tooltip>
                <Tooltip title="Share Business">
                  <BsShareFill
                    onClick={handleShare}
                    style={{ fontSize: '1.2rem' }}
                    className="t-text-secondary-100 t-text-center t-cursor-pointer"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col span={24} className="col-info">
        <MyBusinessServices business={business} />
      </Col>
      <ShareModal
        url={`${window.location.origin}/businesses/${business.Id}`}
        visible={isShare}
        onClose={() => setShare(false)}
      />
    </>
  )
}

export { BusinessProfile }

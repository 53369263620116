import React, { useEffect, useState } from 'react'
import { Typography, Row, Button, message } from 'antd'
import { AiFillCheckCircle } from 'react-icons/ai'
import { LinkPaymentCategoryPaypalAccount } from 'store/paymentSlice/paymentActions'
import { organizationActions } from 'store/organizationSlice/organizationReducer'
import { useDispatch } from 'react-redux'

const { Title, Paragraph } = Typography

const ConnectPaypal = ({ paypalId, getCategoryData, setCategoryData }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (Object.entries(params).length > 0) {
      if (params.merchantIdInPayPal) {
        setCategoryData('paypal', params.merchantIdInPayPal)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search])

  const linkPaypal = async () => {
    try {
      const data = getCategoryData()
      dispatch(organizationActions.setPaymentCategoryData(data))
      setLoading(true)
      const response = await LinkPaymentCategoryPaypalAccount(true)
      if (response) {
        window.open(response, '_self')
      } else {
        setLoading(false)
        message.error('Something Went Wrong')
      }
    } catch (error) {
      setLoading(false)
      message.error('Something Went Wrong')
    }
  }

  // const unlinkPaypal = async () => {
  //   try {
  //     setLoading(true)
  //     await UnlinkPaypalAccount(payment.paypal.id)
  //     refreshPayment()
  //     setLoading(false)
  //     message.success('Paypal Account Disconnected')
  //   } catch (error) {
  //     message.error('Something Went Wrong')
  //     setLoading(false)
  //   }
  // }

  return (
    <>
      <Row className="t-flex t-items-center t-space-x-3">
        <Title level={5}>Paypal Account</Title>
        {paypalId && (
          <AiFillCheckCircle
            fontSize="1.7rem"
            className="t-text-green-600 t-cursor-pointer t-mb-2"
          />
        )}
      </Row>
      <Paragraph>
        You can accept transactions through Paypal. Click the button below to
        accept Paypal payments
      </Paragraph>
      <Row className="t-space-x-4 t-flex t-items-center">
        {/* {payment?.paypal?.isConnected ? (
          <Popconfirm
            title="Are you sure to Disconnect Your Paypal Account？"
            placement="top"
            icon={
              <DeleteFilled
                className="t-text-red-500"
                style={{ fontSize: '1rem' }}
              />
            }
            loading={loading}
            onConfirm={unlinkPaypal}
            okText="Delete"
            cancelText="Cancel"
          >
            <Button
              type="primary"
              size="small"
              className="t-bg-red-500 t-border-0"
              loading={loading}
            >
              Disconnect Paypal Account
            </Button>
          </Popconfirm>
        ) : ( */}
        <Button
          size="small"
          type="primary"
          className={`${paypalId ? 't-opacity-70' : 't-opacity-100'}`}
          onClick={paypalId ? null : linkPaypal}
          loading={loading}
        >
          {paypalId ? 'Paypal Account Connected' : 'Link Paypal Account'}
        </Button>
        {/* )} */}
      </Row>
    </>
  )
}

export { ConnectPaypal }

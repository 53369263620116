import { Auth } from 'aws-amplify'

const awsconfig = {
  region: 'us-east-2',
  paymentHashUrl:
    'https://eq8wcmr7jb.execute-api.us-east-2.amazonaws.com/TestStage/getpaymenthash',
  signUrl:
    'https://eq8wcmr7jb.execute-api.us-east-2.amazonaws.com/TestStage/presignedurl',
  uploadUrl: 'https://s3.us-east-2.amazonaws.com/pledgegardentest',
  imageUrl: 'https://d2vns28tgp6o27.cloudfront.net/',
  userPoolId: 'us-east-2_QaDKl9bhJ',
  userPoolWebClientId: '1etp54mjn8n0l50hg6noel7k9n',
  aws_appsync_graphqlEndpoint:
    'https://oo2nnwzy2fh4znwgsa6cxmha3e.appsync-api.us-east-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-4kiftsd6cfdtrn3lyc72vhbefa',
  oauth: {
    domain: 'dev-pledgarden.auth.us-east-2.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'pledge://', // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
    redirectSignOut: 'pledge://', // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
  },
  bucketUrl: 'https://pledge-garden-qa-upload-content.s3.amazonaws.com/',
  API: {
    endpoints: [
      {
        name: 'pg-api-v1',
        api_url: 'https://api.qa.extensia.cloud',
        endpoint: 'https://api.qa.extensia.cloud/v1',
        endpointv2: 'https://api.qa.extensia.cloud/v2',
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
          idtoken: `${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        }),
      },
    ],
  },
  stripe_publish_key:
    'pk_test_51M2bubAytoqz9GfCr5T9It9BOzpgeyWwYhCsOUqnG4GKhqE5rPOgsoNoJwuHwqwn8KF2ol9nB9yMtdnxaD8wj0mc00gc0QbrrM',
  stripe_connect_client_id: 'ca_MmAZSSzg1mVaGmHhlLjknS36D5DDoJ37',
  paypal_client_id:
    'AYVjAfvolD9IOQrYun0mN2YC8WlWJIRlM5kWx2QvK23pvFti9kzYpYPnar0Jl4jLF4qgTtw14UZfrsOm',
  paypal_bncode: 'PledgeGarden_SP_PPCP',
  reCaptchaKey: '6Ld6NXkgAAAAAMLMVyXtaCAI3W28hgSpE8_fEP10',
  public_vapid_key:
    'BGHT_yJc1YI9fMpPVcDKITgRlOdlkQ1or2RZ7ObNKs5Rj9ph1KPGR6Klye31sTPBYk01GE5JZkqh-WcqKKuR-TQ',
  google_api_key: 'AIzaSyAu1COT1oscjAn-Jx8KyfAqwh2fNhrGXag',
  webapp_url: 'https://<org>.donate.qa.extensia.cloud',
}

export { awsconfig }

import React, { useState } from 'react'
import { Row, Col, Button, Modal, Input, Form, message } from 'antd'
import * as Yup from 'yup'
import { makeRequest } from 'api'
import { useAuthSelector } from 'store/authSlice/authReducer'

const emailRegex = /^([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)?$/gi
const phoneNumberValidation = /^(\+[0-9]{11,12})?$/gi
const zipCodeRegex = '^(?:[0-9]{5})?$'
const schema = Yup.object().shape({
  DonorName: Yup.string().required('Donor name is required'),
  DonorEmail: Yup.string().matches(emailRegex, 'Invalid Email'),
  DonorPhoneNumber: Yup.string().matches(
    phoneNumberValidation,
    'Invalid Phone number e.g +1234567891',
  ),
  DonorZipCode: Yup.string().matches(
    zipCodeRegex,
    'Invalid Zip code e.g 12345',
  ),
})

const editDonorValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  DonorName: '',
  DonorEmail: '',
  DonorPhoneNumber: '',
  DonorZipCode: '',
}

const AddCommunityMemberModal = ({ isVisible, onClose, getData }) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { organization } = useAuthSelector()

  const onFinish = (values) => {
    const { DonorEmail, DonorPhoneNumber } = values
    if (!DonorEmail && !DonorPhoneNumber) {
      message.error('Donor Email or Donor Phone Number is required')
      return false
    }
    setLoading(true)
    const data = {
      ...values,
      OrganizationId: organization.id,
      OrganizationName: organization.Name,
      OrganizationEmail: organization.Email,
    }
    makeRequest(
      'post',
      'subscriber/donorList/addDonor',
      {
        organization: organization.id,
      },
      { data },
    )
      .then((res) => {
        message.success(res.message || 'Added successfully')
        form.resetFields()
        setLoading(false)
        getData()
        onClose()
      })
      .catch((error) => {
        message.error(error?.response?.data?.error || 'An error occurred')
        console.log('error', error)
        setLoading(false)
      })
    return true
  }
  return (
    <Modal
      centered
      title="Add community member"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="Edit Donor"
        initialValues={initialValues}
        scrollToFirstError
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item
              name="DonorName"
              label="Name"
              colon={false}
              rules={[editDonorValidation]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="DonorEmail"
              label="Email"
              colon={false}
              rules={[editDonorValidation]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="DonorPhoneNumber"
              label="Mobile Number"
              colon={false}
              rules={[editDonorValidation]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="DonorZipCode"
              label="Zip/Post Code"
              colon={false}
              rules={[editDonorValidation]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                style={{ width: '100%' }}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Add
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export { AddCommunityMemberModal }

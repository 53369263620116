import { message, Spin, Tooltip } from 'antd'
import React, { useState } from 'react'
import { MdOutlineAttachEmail, MdOutlineSimCardDownload } from 'react-icons/md'
import { useAuthSelector } from 'store/authSlice/authReducer'
import {
  convertFile,
  generatePdfAndEmail,
} from 'store/reportSlice/reportActions'

const ShareReport = ({ reportName, isSummary, report }) => {
  const { user } = useAuthSelector()
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [emailLoading, setEmailLoading] = useState(false)

  const download = async (isSendEmail, setLoading) => {
    setLoading(true)
    const payload = {
      firstName: user?.firstname,
      lastName: user?.lastname,
      phoneNumber: user?.phone,
      email: user?.email,
      isSendEmail,
      reportName,
    }
    if (isSummary) {
      payload.summary = report
    } else {
      payload.records = report
    }
    try {
      const response = await generatePdfAndEmail(payload)
      if (response.message) {
        message.success('Email sent to your account')
      }
      if (response.result) {
        convertFile(response.result, `${reportName}.pdf`)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error('Something Went Wrong')
    }
  }
  return (
    <div className="t-flex t-justify-end t-items-center t-space-x-2">
      <Tooltip title="Download">
        {downloadLoading ? (
          <Spin />
        ) : (
          <MdOutlineSimCardDownload
            fontSize="1.7rem"
            className="t-text-secondary-100 t-cursor-pointer"
            onClick={() => download(false, setDownloadLoading)}
          />
        )}
      </Tooltip>
      <Tooltip title="Email">
        {emailLoading ? (
          <Spin />
        ) : (
          <MdOutlineAttachEmail
            fontSize="1.7rem"
            className="t-text-secondary-100 t-cursor-pointer"
            onClick={() => download(true, setEmailLoading)}
          />
        )}
      </Tooltip>
    </div>
  )
}

export { ShareReport }

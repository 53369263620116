import React, { useState, useEffect } from 'react'
import { Typography, Row, Button, Tag } from 'antd'
import { AiFillCheckCircle } from 'react-icons/ai'
import { IoIosWarning } from 'react-icons/io'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useHistory } from 'react-router-dom'
import { awsconfig, config } from 'configs'
import { useDispatch } from 'react-redux'
import { organizationActions } from 'store/organizationSlice/organizationReducer'

const { Title, Paragraph } = Typography

const ConnectStripe = ({ stripeId, getCategoryData, setCategoryData }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { user } = useAuthSelector()
  const [loading, setLoading] = useState(false)
  const [stripeErrors, setStripeErrors] = useState({
    errors: [],
    requirements: [],
    pending: [],
  })

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (Object.entries(params).length > 0) {
      if (params.account_id) {
        setCategoryData('stripe', params.account_id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  useEffect(() => {
    getStripeAccount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const getStripeAccount = async () => {
    try {
      setLoading(true)
      // const response = await getPaymentCategoryStripeAccountDetails(user?.email)
      setStripeErrors([])
      setLoading(false)
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }
  }

  const openStripeOAuth = async () => {
    const categoryData = getCategoryData()
    dispatch(organizationActions.setPaymentCategoryData(categoryData))
    const clientId = awsconfig.stripe_connect_client_id
    // Redirect URI should match in the Stripe dashboard
    const redirectUri = `${window.location.origin}${config.stripeRedirectUri}`
    const data = {
      caller: 'AddGeneralCategory',
    }
    const stringifiedData = JSON.stringify(data)
    const link = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&redirect_uri=${redirectUri}&state=${stringifiedData}`
    window.open(link, '_self')
  }

  // const unlinkStripe = async () => {
  //   try {
  //     setLoading(true)
  //     await UnlinkStripeAccount(payment.stripe.id, user.email)
  //     refreshPayment()
  //     setLoading(false)
  //     message.success('Stripe Account Disconnectd')
  //   } catch (error) {
  //     setLoading(false)
  //     message.error('Something Went Wrong')
  //   }
  // }

  const isErrors = stripeErrors?.errors?.length > 0
  const isRequirements = stripeErrors?.requirements?.length > 0
  const isPending = stripeErrors?.pending?.length > 0

  return (
    <>
      <Row className="t-flex t-items-center t-space-x-3">
        <Title level={5}>Stripe Account</Title>
        {stripeId ? (
          <AiFillCheckCircle
            fontSize="1.7rem"
            className="t-text-green-600 t-cursor-pointer t-mb-2"
          />
        ) : (
          <>
            {isErrors && (
              <IoIosWarning
                fontSize="1.7rem"
                className="t-text-orange-600 t-cursor-pointer t-mb-2"
              />
            )}
          </>
        )}
      </Row>
      {!isErrors && !isRequirements && (
        <>
          <Paragraph>
            You can accept credit cards payment through Stripe. Click the button
            below to connect your Stripe account
          </Paragraph>
          <Row className="t-space-x-4 t-flex t-items-center">
            {/* {payment?.stripe?.isConnected ? (
              <Popconfirm
                title="Are you sure to Disconnect Your Stripe Account？"
                placement="top"
                icon={
                  <DeleteFilled
                    className="t-text-red-500"
                    style={{ fontSize: '1rem' }}
                  />
                }
                loading={loading}
                onConfirm={unlinkStripe}
                okText="Delete"
                cancelText="Cancel"
              >
                <Button
                  type="primary"
                  size="small"
                  className="t-bg-red-500 t-border-0"
                  loading={loading}
                >
                  Disconnect Stripe Account
                </Button>
              </Popconfirm>
            ) : ( */}
            <>
              {isPending ? (
                <Tag color="gray">Your account is under review</Tag>
              ) : (
                <Button
                  size="small"
                  type="primary"
                  className={`${stripeId ? 't-opacity-70' : 't-opacity-100'}`}
                  onClick={stripeId ? null : openStripeOAuth}
                  loading={loading}
                  disabled={isErrors || isRequirements || isPending}
                >
                  {stripeId
                    ? 'Stripe Account Connected'
                    : 'Link Stripe Account'}
                </Button>
              )}
            </>
            {/* )} */}
          </Row>
        </>
      )}
      {(isErrors || isRequirements) && (
        <>
          <Paragraph>
            Your connected stripe account have some missing/incorrect
            information. You can click below to start making the corrections.
            Stripe will inform you about the required information to update when
            you will open the link below.
          </Paragraph>
          {isErrors && (
            <div className="t-flex t-flex-col t-space-y-2 t-my-2">
              <div>
                <Tag color="red">{stripeErrors?.errors?.[0]?.reason}</Tag>
              </div>
            </div>
          )}
          <div className="t-my-4">
            <Button
              size="small"
              type="primary"
              onClick={openStripeOAuth}
              loading={loading}
            >
              Click to update your stripe account
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export { ConnectStripe }

import React, { useEffect, useState } from 'react'
import { Card, Input, Skeleton, Table, Tooltip, message } from 'antd'
import { MdOutlineSimCardDownload } from 'react-icons/md'
import { SearchOutlined } from '@ant-design/icons'
import { downloadCSV, searchCommunityMasterListRows } from 'utils'
import Api from 'api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { useAuthSelector } from 'store/authSlice/authReducer'

const MasterCommunity = () => {
  const history = useHistory()
  const { organization } = useAuthSelector()
  const [loading, setLoading] = useState(true)
  // const [downloadLoading, setDownloadLoading] = useState(false)
  const [searchText, setSearchText] = useState(null)
  const [data, setData] = useState([])

  const getData = () => {
    Api.get(`subscriber/donorList/${organization?.id}`)
      .then((res) => {
        const list = res.result
        const donorPhoneNumbers = Object.keys(list)

        // Convert the object into an array of objects
        const arrayOfObjects = donorPhoneNumbers.map((phoneNumber) => {
          const donorInfo = list[phoneNumber]
          return {
            DonorName: donorInfo.DonorName,
            DonorEmail: donorInfo.DonorEmail,
            DonorPhoneNumber: donorInfo.DonorPhoneNumber,
            OrganizationName: donorInfo.OrganizationName,
          }
        })

        setData(arrayOfObjects)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        message.error(
          error?.exceptionMessage || 'An error occured in fetching report',
        )
        setLoading(false)
      })
  }
  const csvColumns = [
    'OrganizationName',
    'DonorName',
    'DonorEmail',
    'DonorPhoneNumber',
  ]
  const csvHeaderMapping = {
    DonorName: 'Donor Name',
    DonorEmail: 'Donor Email',
    DonorPhoneNumber: 'Donor Phone',
    OrganizationName: 'Organization Name',
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  const columns = [
    {
      title: 'Donor Name',
      dataIndex: 'DonorName',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Donor Email',
      dataIndex: 'DonorEmail',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Donor Phone Number',
      dataIndex: 'DonorPhoneNumber',
      className: 'ant-table-row-cell-break-word',
      sorter: (a, b) => {
        const dateA = a.DonorPhoneNumber
        const dateB = b.DonorPhoneNumber
        return dateB - dateA
      },
      sortDirections: ['descend', 'ascend'],
    },
  ]

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={
        <div className="t-flex t-flex-wrap t-justify-between">
          <div className="t-flex t-space-x-4">
            <IoMdArrowRoundBack
              fontSize="1.5rem"
              className="t-cursor-pointer t-min-w-[1.5rem]"
              id="back"
              onClick={() => history.push('/reports')}
            />
            <h5 className="font-semibold">Master Community List</h5>
          </div>
          <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
            <div className="header-control t-mt-0">
              <Input
                className="header-search t-w-36"
                placeholder="Search ..."
                prefix={<SearchOutlined />}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
            </div>
            <Tooltip title="Download">
              <MdOutlineSimCardDownload
                fontSize="1.7rem"
                id="download"
                className="t-text-secondary-100 t-cursor-pointer"
                onClick={() =>
                  downloadCSV(
                    data,
                    csvColumns,
                    csvHeaderMapping,
                    'Master-Community-List.csv',
                  )
                }
              />
            </Tooltip>
          </div>
        </div>
      }
    >
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <Table
          columns={columns}
          dataSource={searchCommunityMasterListRows(data, searchText) || []}
          pagination={false}
        />
      </Skeleton>
    </Card>
  )
}

export { MasterCommunity }

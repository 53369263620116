import React, { useEffect, useState } from 'react'
import {
  message,
  Skeleton,
  Form,
  Row,
  Col,
  Input,
  Button,
  Card,
  Typography,
} from 'antd'
import Api from 'api'
import { organizationMarkupValidation } from 'validations/organizationMarkup'
import { useRoles } from 'hooks'
import { IoIosArrowBack } from 'react-icons/io'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const { Title } = Typography

const UpdateGlobalMarkup = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(true)
  const [updateLoading, setUpdateLoading] = useState(false)
  const { isAdmin } = useRoles()
  const history = useHistory()

  useEffect(() => {
    Api.get('admins/get-global-organization-markups')
      .then((res) => {
        const data = res.results
        if (data) {
          form.setFieldsValue({
            SMSCost: data.SMSCost && data.SMSCost,
            EmailCost: data.EmailCost && data.EmailCost,
            PaymentMarkup: data.PaymentMarkup && data.PaymentMarkup,
            FreeSMSQuota: data.FreeSMSQuota && data.FreeSMSQuota,
            FreeEmailQuota: data.FreeEmailQuota && data.FreeEmailQuota,
            PlatformSubscriptionCharges:
              data.PlatformSubscriptionCharges &&
              data.PlatformSubscriptionCharges,
            KioskMonthlyDues: data.KioskMonthlyDues && data.KioskMonthlyDues,
          })
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        message.error('Something went wrong')
      })
    // eslint-disable-next-line
  }, [])

  const onFinish = (values) => {
    setUpdateLoading(true)
    Api.put('/admins/update-global-organization-markups', values)
      .then((res) => {
        message.success(res.message || 'Markup Updated Successfully')
        setUpdateLoading(false)
        history.push('/organizations')
      })
      .catch(() => {
        message.error('Something went wrong')
        setUpdateLoading(false)
      })
  }
  const handleBack = () => {
    history.goBack()
  }

  return (
    <>
      {isAdmin ? (
        <Card>
          <Button
            type="primary"
            className="t-rounded-full t-flex t-items-center"
            onClick={handleBack}
            size="small"
          >
            <IoIosArrowBack />
            Back
          </Button>
          <Title level={4} className="t-mt-2">
            Standard Costs
          </Title>
          <Skeleton loading={loading} paragraph={{ rows: 12 }}>
            <Form
              form={form}
              layout="vertical"
              key={0}
              onFinish={onFinish}
              name="global organization markup"
              initialValues={{
                SMSMarkup: '',
                EmailMarkup: '',
                PaymentMarkup: '',
              }}
              scrollToFirstError
            >
              <Row gutter={[24, 0]}>
                <Col xl={12} md={12} xs={12} sm={12}>
                  <Form.Item
                    name="SMSCost"
                    label="SMS Cost ($)"
                    colon={false}
                    rules={[organizationMarkupValidation]}
                  >
                    <Input placeholder="Enter sms cost amount" />
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} xs={12} sm={12}>
                  <Form.Item
                    name="FreeSMSQuota"
                    label="SMS Quota"
                    colon={false}
                    rules={[organizationMarkupValidation]}
                  >
                    <Input placeholder="Enter free sms quota" />
                  </Form.Item>
                </Col>

                <Col xl={12} md={12} xs={12} sm={12}>
                  <Form.Item
                    name="EmailCost"
                    label="Email Charges ($)"
                    colon={false}
                    rules={[organizationMarkupValidation]}
                  >
                    <Input placeholder="Enter email cost amount" />
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} xs={12} sm={12}>
                  <Form.Item
                    name="FreeEmailQuota"
                    label="Email Quota"
                    colon={false}
                    rules={[organizationMarkupValidation]}
                  >
                    <Input placeholder="Enter free email quota" />
                  </Form.Item>
                </Col>

                <Col xl={24} md={24} xs={24} sm={24}>
                  <Form.Item
                    name="PaymentMarkup"
                    label="Payment Markup (%)"
                    colon={false}
                    rules={[organizationMarkupValidation]}
                  >
                    <Input placeholder="Enter payment markup" />
                  </Form.Item>
                </Col>

                <Col xl={24} md={24} xs={24} sm={24}>
                  <Form.Item
                    name="PlatformSubscriptionCharges"
                    label="Platform Subscription Charges ($)"
                    colon={false}
                    rules={[organizationMarkupValidation]}
                  >
                    <Input placeholder="Enter platform subscription charges" />
                  </Form.Item>
                </Col>

                <Col xl={24} md={24} xs={24} sm={24}>
                  <Form.Item
                    name="KioskMonthlyDues"
                    label="Kiosk Monthly Dues ($)"
                    colon={false}
                    rules={[organizationMarkupValidation]}
                  >
                    <Input placeholder="Enter kiosk monthly dues" />
                  </Form.Item>
                </Col>

                <Col span={24} className="text-right">
                  <Button
                    loading={loading || updateLoading}
                    type="primary"
                    className="px-25"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Skeleton>
        </Card>
      ) : (
        <Card>
          <Title level={5} className="t-py-12 t-text-center">
            Feature is not available
          </Title>
        </Card>
      )}
    </>
  )
}

export { UpdateGlobalMarkup }

import * as Yup from 'yup'

const schema = Yup.object().shape({
  SMSMarkup: Yup.string().required('SMS Markup is required'),
  EmailMarkup: Yup.string().required('Email Markup is required'),
  PaymentMarkup: Yup.string().required('Payment Markup is required'),
  FreeSMSQuota: Yup.string().required('Sms Quota is required'),
  FreeEmailQuota: Yup.string().required('Email Quota is required'),
  PlatformSubscriptionCharges: Yup.string().required(
    'Platform Subscription Charges is required',
  ),
})

const orgMarkupValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { orgMarkupValidation }

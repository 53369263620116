import React, { useEffect, useRef, useState } from 'react'
import {
  Card,
  Row,
  Typography,
  Skeleton,
  Divider,
  message,
  Carousel,
  Menu,
  Tooltip,
  Dropdown,
} from 'antd'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { formatAmount } from 'utils'
import { awsconfig } from 'configs'
import { ShareModal } from 'components'
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
  BsThreeDotsVertical,
} from 'react-icons/bs'
import { IoIosArrowBack } from 'react-icons/io'
import sample from 'assets/images/sample/sample1.png'
import { useAuctionSelector } from 'store/auctionSlice/auctionReducer'
import { getAuctionDetails } from 'store/auctionSlice/auctionActions'
import { AuctionItemViewModal, MyBids, Won } from 'components/common/auction'

const { Paragraph } = Typography

const JoinAuction = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { state, search } = useLocation()
  const slider = useRef(null)
  const [isShare, setShare] = useState(false)
  const [auctionItemViewModal, setAuctionItemViewModal] = useState(false)
  const [auctionItemDetails, setAuctionItemDetails] = useState()
  const [activeKey, setActiveKey] = useState('1')
  const { loading, auction } = useAuctionSelector()

  const filterEventAuctionItems = auction?.AuctionItemsList?.filter(
    (item) => item.status === 'Live',
  )

  const fetchData = () => {
    dispatch(getAuctionDetails(params?.id))
  }

  useEffect(() => {
    if (params?.id) {
      fetchData()
    } else {
      message.error('Invalid URL')
      history.push('/auction')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id])

  useEffect(() => {
    if (search) {
      const urlSearchParams = new URLSearchParams(search)
      const { key } = Object.fromEntries(urlSearchParams.entries())
      if (key) setActiveKey(key)
    }
    // eslint-disable-next-line
  }, [])

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    function setScreenDimensions() {
      setScreenWidth(window.innerWidth)
    }

    let timeout
    setScreenDimensions()
    const handleResize = () => {
      clearTimeout(timeout)

      timeout = setTimeout(() => {
        setScreenDimensions()
      }, 400)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const goBackFromJoinScreen = () => {
    if (state && state.urlData) {
      history.push({
        pathname: state.urlData.pathname,
        state: { key: state.urlData.activeKey },
      })
    } else {
      history.push('/auction')
    }
  }

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: <div onClick={() => setActiveKey('1')}>My Bids</div>,
        },
        {
          key: '2',
          label: <div onClick={() => setActiveKey('2')}>Won</div>,
        },
      ]}
    />
  )

  return (
    <Card className="header-solid md:h-auto" bordered={false}>
      <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
        <Row className="t-relative">
          <div
            onClick={goBackFromJoinScreen}
            className="t-rounded-full glass_bg t-cursor-pointer t-fixed t-top-20 md:t-top-24 t-z-50 t-text-secondary-100 t-p-1"
          >
            <IoIosArrowBack fontSize="1.5rem" />
          </div>
        </Row>
        <Row span={24} lg={24} xl={24} className="col-gallery mb-10 md:mb-24">
          {auction?.AuctionItemsList?.length > 0 ? (
            <div className="t-relative t-w-full t-h-44 md:t-h-96 t-py-2 t-px-2">
              <BsFillArrowLeftCircleFill
                fontSize="1.2rem"
                onClick={() => slider.current.prev()}
                className="t-text-secondary-100 t-cursor-pointer t-absolute t-top-[35%] -t-left-[6px] t-hidden md:t-block"
              />
              <Carousel ref={slider} slidesPerRow={screenWidth < 768 ? 1 : 3}>
                {filterEventAuctionItems.map((item) => (
                  <div className="t-h-44 md:t-h-96 t-px-2">
                    <Tooltip title="View Item">
                      <Card
                        bordered
                        hoverable
                        cover={
                          <img
                            alt="example"
                            className="t-mt-2 t-w-full t-h-16 md:t-h-48 t-object-contain t-rounded-br-none t-rounded-bl-none t-rounded-tr-md t-rounded-tl-md md:t-rounded-md t-cursor-pointer"
                            src={
                              item?.itemImage?.fileName
                                ? awsconfig.bucketUrl +
                                  item?.itemImage?.fileName
                                : sample
                            }
                          />
                        }
                        onClick={() => {
                          setAuctionItemDetails(item)
                          setAuctionItemViewModal(true)
                        }}
                      >
                        <div className="t-flex t-justify-between t-items-center">
                          <h5 className="t-text-base md:t-text-2xl t-text-secondary-100 t-font-bold t-truncate">
                            {item.title}
                          </h5>
                        </div>

                        <div className="t-flex t-justify-between">
                          <div className="card-tag t-font-thin t-text-secondary-100">
                            {item.currentBid && formatAmount(item.currentBid)}
                          </div>
                        </div>
                        <p className="t-line-clamp-1 t-text-sm">
                          {item.description}
                        </p>
                      </Card>
                    </Tooltip>
                  </div>
                ))}
              </Carousel>
              <BsFillArrowRightCircleFill
                fontSize="1.2rem"
                onClick={() => slider.current.next()}
                className="t-text-secondary-100 t-cursor-pointer t-absolute t-top-[35%] -t-right-[6px] t-hidden md:t-block"
              />
            </div>
          ) : (
            <img
              src={
                auction?.Flyer?.fileName
                  ? awsconfig.bucketUrl + auction?.Flyer?.fileName
                  : auction?.organization?.BackgroundImage
                  ? awsconfig.bucketUrl +
                    auction?.organization?.BackgroundImage?.fileName
                  : sample
              }
              className="t-h-44 md:t-h-96 mb-24 t-w-full gallery-img shadow-3 img-contain"
              alt="card-2"
            />
          )}
        </Row>
        <Row>
          <Tooltip title="Title" className="t-cursor-pointer">
            <p className="t-text-lg md:t-text-3xl t-font-bold t-text-secondary-100">
              {auction?.Title}
            </p>
          </Tooltip>
        </Row>
        <Row>
          <Tooltip title="Details" className="t-cursor-pointer">
            <Paragraph className="t-text-justify">{auction?.Details}</Paragraph>
          </Tooltip>
        </Row>
        <Row className="t-w-full t-flex t-justify-between t-items-center">
          <Row className="t-flex t-items-center t-space-x-2">
            <p className="t-text-sm md:t-text-base t-font-bold t-text-secondary-100">
              By:
            </p>
            <h1>{auction?.OrganizationName}</h1>
          </Row>
          {/* <BsShareFill
            onClick={shareEvent}
            style={{ fontSize: '1.4rem' }}
            className="t-text-secondary-100 t-text-center t-cursor-pointer"
          /> */}
        </Row>
        <Divider />
        <>
          <Row className="t-w-full" animated={false}>
            <div className="t-w-full t-flex t-justify-between t-m-2 t-items-center">
              <h1 className="t-font-bold t-text-secondary-100 t-text-xl">
                {activeKey === '1' && 'My Bids'}
                {activeKey === '2' && 'Won'}
              </h1>
              <Dropdown overlay={menu} placement="bottomLeft" arrow>
                <BsThreeDotsVertical
                  fontSize="1.5rem"
                  id="control-screen-dropdown"
                  className="t-cursor-pointer t-mx-1"
                />
              </Dropdown>
            </div>
          </Row>
          <Row className="t-w-full" animated={false}>
            {activeKey === '1' && (
              <MyBids
                onBidClick={(item) => {
                  setAuctionItemDetails(item)
                  setAuctionItemViewModal(true)
                }}
                type="auction"
                parentId={params?.id}
              />
            )}
            {activeKey === '2' && <Won type="auction" parentId={params?.id} />}
          </Row>
        </>
        <AuctionItemViewModal
          visible={auctionItemViewModal}
          item={auctionItemDetails}
          close={() => {
            setAuctionItemDetails()
            setAuctionItemViewModal(false)
          }}
          fetchData={() => fetchData()}
          type="auction"
          parentId={params?.id}
        />
        <ShareModal
          url={`${window.location.origin}/donation/${params?.id}`}
          visible={isShare}
          onClose={() => setShare(false)}
        />
      </Skeleton>
    </Card>
  )
}

export { JoinAuction }

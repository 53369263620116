import React, { useEffect, useState } from 'react'
import { Card, Table, Tag, Skeleton, Space, Dropdown, Menu, Input } from 'antd'
import { useDispatch } from 'react-redux'
import { EllipsisOutlined, SearchOutlined } from '@ant-design/icons'
import { searchBusinesses } from 'utils'
import {
  getAllBusinessesForAdmin,
  updateBusinessStatusByAdmin,
} from 'store/businessSlice/businessActions'
import { useBusinessSelector } from 'store/businessSlice/businessReducer'

const AdminBusinessView = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const { loading, businesses } = useBusinessSelector()

  const handleActions = (key, data) => {
    let payload
    switch (key) {
      case 1:
        payload = {
          Id: data.Id,
          BusinessStatus: 'Approved',
        }
        dispatch(updateBusinessStatusByAdmin(payload))
        break
      case 2:
        payload = {
          Id: data.Id,
          BusinessStatus: 'Rejected',
        }
        dispatch(updateBusinessStatusByAdmin(payload))
        break
      default:
        break
    }
  }
  function CustomMenu(business) {
    return (
      <>
        <Menu>
          {business.BusinessStatus !== 'Approved' && (
            <Menu.Item key={1} onClick={() => handleActions(1, business)}>
              Approve
            </Menu.Item>
          )}
        </Menu>
        <Menu>
          {business.BusinessStatus !== 'Rejected' && (
            <Menu.Item key={2} onClick={() => handleActions(2, business)}>
              Reject
            </Menu.Item>
          )}
        </Menu>
      </>
    )
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      render: (text) => <div>{text}</div>,
      sorter: (a, b) => {
        if (a.Name > b.Name) {
          return -1
        }
        if (b.Name > a.Name) {
          return 1
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Email',
      dataIndex: 'UserEmail',
      key: 'UserEmail',
    },
    {
      title: 'Service Coverage',
      dataIndex: 'ServiceCoverage',
      key: 'ServiceCoverage',
    },
    {
      title: 'Status',
      dataIndex: 'BusinessStatus',
      key: 'BusinessStatus',
      render: (_, { BusinessStatus }) => (
        <>
          {BusinessStatus === 'Approved' && <Tag color="green">Approved</Tag>}
          {BusinessStatus === 'Rejected' && <Tag color="red">Rejected</Tag>}
          {/* {OrgStatus === 'Disabled' && <Tag color="orange">Disabled</Tag>} */}
          {BusinessStatus === 'Pending' && <Tag color="gray">Pending</Tag>}
          {/* {OrgStatus === 'Suspended' && <Tag color="red">Suspended</Tag>} */}
        </>
      ),
      sorter: (a, b) => {
        if (a.BusinessStatus > b.BusinessStatus) {
          return -1
        }
        if (b.BusinessStatus > a.BusinessStatus) {
          return 1
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'State',
      dataIndex: 'State',
      key: 'State',
    },
    {
      title: 'Zipcode',
      dataIndex: 'ZipCode',
      key: 'ZipCode',
    },
    {
      title: 'City',
      dataIndex: 'City',
      key: 'City',
    },
    {
      title: 'Address',
      dataIndex: 'Address1',
      key: 'Address1',
    },
    // {
    //   title: 'Note',
    //   dataIndex: 'Note',
    //   key: 'Note',
    //   render: (_, business) => (
    //     <Space size="middle">
    //       <Tooltip title={business.Note}>
    //         <GrNotes className="t-cursor-pointer" />
    //       </Tooltip>
    //     </Space>
    //   ),
    // },
    {
      title: 'Actions',
      key: 'id',
      // fixed: "right",
      width: 300,
      align: 'center',
      render: (_, business) => (
        <Space size="middle">
          <Dropdown overlay={CustomMenu(business)}>
            <EllipsisOutlined />
          </Dropdown>
        </Space>
      ),
    },
  ]

  useEffect(() => {
    dispatch(getAllBusinessesForAdmin())
  }, [dispatch])
  return (
    <>
      <Card
        bordered={false}
        className="header-solid mb-24"
        style={{ minHeight: '85vh' }}
        title={
          <div className="t-flex t-flex-wrap t-justify-between">
            <div>
              <h6 className="font-semibold t-text-2xl">Businesses</h6>
              <p>All businesses are listed here</p>
            </div>
            <div className="t-mt-4 sm:t-mt-0">
              <div className="header-control t-mt-0">
                <Input
                  className="header-search"
                  placeholder="Search ..."
                  value={searchText}
                  prefix={<SearchOutlined />}
                  onChange={(e) => {
                    setSearchText(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
        }
      >
        <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
          <Table
            size="middle"
            tableLayout="auto"
            scroll={{
              x: 'max-content',
              // y: 240,
            }}
            columns={columns}
            dataSource={searchBusinesses(businesses, searchText) || []}
            bordered={false}
            pagination={false}
            style={{
              width: 'auto',
              minWidth: 'unset !important',
            }}
          />
        </Skeleton>
      </Card>
    </>
  )
}

export { AdminBusinessView }

import React, { useEffect, useState } from 'react'
import {
  Row,
  Form,
  Checkbox,
  Button,
  Col,
  Input,
  Select,
  Typography,
  message,
  Modal,
  Popconfirm,
} from 'antd'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { loadStripe } from '@stripe/stripe-js'
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { AiFillDelete } from 'react-icons/ai'
import { awsconfig } from 'configs'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { deleteUserCard, getUserCards } from 'store/paymentSlice/paymentActions'
import { RiErrorWarningFill } from 'react-icons/ri'
import * as Yup from 'yup'
import { payAuctionStripe } from 'store/eventSlice/eventActions'
import { useHistory } from 'react-router-dom'
import { TermAndConditionModal } from 'components/elements'

const { Title, Paragraph } = Typography

const numberValidation = new RegExp('^[0-9]+$')

const schema = Yup.object().shape({
  askedAmount: Yup.string()
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
  card: Yup.string().required('Required'),
  agreeToTerms: Yup.string().required('Required'),
})

const creditCardValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  card: 'no-card-selected',
  askedAmount: null,
  agreeToTerms: false,
}

const CreditCardForm = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [cards, setCards] = useState([])
  const [manageCardModalVisible, setManageCardModalVisible] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const { soldItem, subscriberPaymentInfo } = useEventSelector()
  const isAuction = soldItem?.type === 'auction'
  const { user } = useAuthSelector()
  const [form] = Form.useForm()

  Form.useWatch('card', form)
  Form.useWatch('askedAmount', form)
  Form.useWatch('agreeToTerms', form)

  const getCustomerCards = async () => {
    setLoading(true)
    try {
      const userCards = await getUserCards(user)
      setCards(userCards)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCustomerCards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (soldItem?.winnerBid) {
      form.setFieldsValue({ askedAmount: soldItem?.winnerBid })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soldItem?.winnerBid])

  /* eslint-disable */
  const onFinish = async (values) => {
    const askedAmountValue = Number(values.askedAmount)
    try {
      if (!values.agreeToTerms) {
        return message.error('You must agree to the terms and conditions')
      }

      setLoading(true)
      // if new card is added
      if (!values.card || values.card === 'no-card-selected') {
        // elements are not loaded yet
        if (elements === null) {
          return
        }
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
        })
        // if there is an error
        if (error) {
          message.error(error?.message || 'Invalid Card Information')
          setLoading(false)
        } else {
          const data = {
            amount: askedAmountValue,
            cardToken: paymentMethod.id,
            stripeAccount: subscriberPaymentInfo?.stripe?.stripeId,
            card: null,
            user,
            commission: subscriberPaymentInfo.markup,
            isBankAccount: false,
            soldItem,
          }
          await payAuctionStripe(data)
          history.push(
            isAuction
              ? `/auction/${soldItem.auctionId}`
              : `/events/${soldItem?.eventId}`,
          )
          message.success('Your payment is successfully submitted')
          form.resetFields()
          elements.getElement(CardElement).clear()
          getCustomerCards()
          setLoading(false)
        }
      } else {
        const data = {
          amount: askedAmountValue,
          card: values.card,
          cardToken: null,
          stripeAccount: subscriberPaymentInfo?.stripe?.stripeId,
          user,
          commission: subscriberPaymentInfo.markup,
          isBankAccount: false,
          soldItem,
        }
        await payAuctionStripe(data)
        message.success('Your payment is successfully submitted')
        history.push(
          isAuction
            ? `/auction/${soldItem?.auctionId}`
            : `/events/${soldItem?.eventId}`,
        )
        form.resetFields()
        setLoading(false)
      }
    } catch (error) {
      console.log('error', error)
      getCustomerCards()
      message.error(error?.response?.data?.message || 'Something Went Wrong')
      setLoading(false)
    }
  }

  const isNoCard = form.getFieldValue('card') === 'no-card-selected'
  const isAgreed = !form.getFieldValue('agreeToTerms')

  const onDeleteCard = async (cardId) => {
    try {
      setLoading(true)
      await deleteUserCard(user, cardId)
      message.success('Card Deleted Successfully')
      getCustomerCards()
      setLoading(false)
    } catch (error) {
      message.error(
        error.resopnse.data.message || 'Error occured while deleting the card',
      )
      setLoading(false)
    }
  }

  return (
    <div>
      {subscriberPaymentInfo?.stripe?.isConnected ? (
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="auction payment"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row className="t-p-1">
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="askedAmount"
                label="Asked Amount"
                colon={false}
                rules={[creditCardValidation]}
              >
                <Input
                  disabled
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  placeholder="Enter amount here"
                />
              </Form.Item>
            </Col>
            {cards.length > 0 && (
              <Col xl={24} md={24} xs={24} sm={24}>
                <Form.Item
                  name="card"
                  label="Select Card"
                  colon={false}
                  rules={[creditCardValidation]}
                >
                  <Select placeholder="Select Card" style={{ width: '100%' }}>
                    <Select.Option value="no-card-selected">
                      No Card Selected
                    </Select.Option>
                    {cards.map((item, index) => (
                      <Select.Option
                        value={item.cardId}
                        key={index}
                        className="t-capitalize"
                      >
                        {`${item.brand} - **** **** **** ${item.last4}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {cards.length > 0 && (
              <Col span={24}>
                <Row className="t-flex t-justify-end ">
                  <h1
                    onClick={() => setManageCardModalVisible(true)}
                    className="t-text-secondary-100 t-underline t-cursor-pointer"
                  >
                    Manage Cards
                  </h1>
                </Row>
              </Col>
            )}
            {isNoCard && (
              <Col xl={24} md={24} xs={24} sm={24} className={`t-mb-6`}>
                <h1 className="t-text-sm t-my-2 t-font-semibold">
                  Enter New Card Info
                </h1>
                <div
                  className={`t-p-2 ${
                    isNoCard && 't-rounded-md t-border t-border-grey-900'
                  }`}
                >
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: '15px',
                          fontSmoothing: 'antialiased',
                        },
                      },
                    }}
                  />
                </div>
              </Col>
            )}

            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="agreeToTerms"
                colon={false}
                rules={[creditCardValidation]}
              >
                <Checkbox
                  onChange={(e) =>
                    form.setFieldsValue({
                      agreeToTerms: e.target.checked,
                    })
                  }
                >
                  <TermAndConditionModal />
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24} className="text-right">
              <Button
                type="primary"
                className="t-w-full md:t-w-auto"
                htmlType="submit"
                loading={loading}
                disabled={!stripe || !elements || isAgreed}
              >
                {!isNoCard ? 'Pay' : 'Save Card And Pay'}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <div className="t-h-72 t-flex t-items-center t-justify-center t-flex-col">
          <RiErrorWarningFill
            fontSize="2.5rem"
            className="t-text-secondary-100"
          />
          <Title level={5}>
            Credit card payments are not configured for this{' '}
            {isAuction ? 'auction' : 'event'}
          </Title>
          <Paragraph>You can use other payment methods</Paragraph>
        </div>
      )}
      <Modal
        centered
        title="Manage Cards"
        visible={manageCardModalVisible}
        onCancel={() => setManageCardModalVisible(false)}
        footer={null}
      >
        <Col span={24} className="t-p-4">
          <div className="t-flex t-justify-between">
            <h1>Following are the cards attached to your account</h1>
            <h1>Total Cards : {cards.length}</h1>
          </div>
          <div>
            {cards.map((item, index) => (
              <div
                key={index}
                className="t-flex t-justify-between t-capitalize t-border-2 t-rounded-2xl t-shadow-lg t-p-4 t-my-4"
              >
                <h1>{`${item.brand} - **** **** **** ${item.last4}`}</h1>
                <Popconfirm
                  title="Are you sure you want to delete this card？"
                  placement="top"
                  loading={loading}
                  onConfirm={() => onDeleteCard(item.cardId)}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <AiFillDelete
                    fontSize={'1.2rem'}
                    className="t-text-red-500 t-cursor-pointer"
                  />
                </Popconfirm>
              </div>
            ))}
          </div>
        </Col>
      </Modal>
    </div>
  )
}

const stripePromise = loadStripe(awsconfig.stripe_publish_key)

function StripeProvider() {
  return (
    <Elements stripe={stripePromise}>
      <CreditCardForm />
    </Elements>
  )
}

const CreditCard = StripeProvider

export { CreditCard }

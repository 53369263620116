import React from 'react'
import { Col, Space, Table, Tooltip } from 'antd'
import { IoMdAddCircleOutline } from 'react-icons/io'
import { FaEdit } from 'react-icons/fa'

const Services = ({ services, setAddServiceModal, editService }) => {
  // const deleteItem = (key) => {
  //   const updatedData = auctionItemsList.filter((item) => key !== item.key)
  //   setAuctionItemsList(updatedData)
  // }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'Title',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Deliverable',
      dataIndex: 'Deliverable',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, { Id }) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <FaEdit
              fontSize="1.2rem"
              className="t-text-secondary-100 t-cursor-pointer"
              onClick={() => {
                console.log('Id', Id)
                editService(Id)
              }}
            />
          </Tooltip>
          {/* <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure you want to delete this donor?"
              onConfirm={() => onDelete(Id)}
            >
              <MdDelete
                fontSize="1.3rem"
                className="t-text-secondary-100 t-cursor-pointer"
              />
            </Popconfirm>
          </Tooltip> */}
        </Space>
      ),
    },
  ]

  return (
    <>
      <Col span={24}>
        <div className="t-flex t-justify-between t-py-4">
          <h1 className="t-text-base t-font-semibold">Services List</h1>
          <div className="t-h-full t-flex t-items-center">
            {services.length < 5 ? (
              <Tooltip title="Add Service">
                <IoMdAddCircleOutline
                  fontSize="1.7rem"
                  className="t-text-secondary-100 t-cursor-pointer"
                  onClick={() => setAddServiceModal(true)}
                />
              </Tooltip>
            ) : (
              <p className="t-text-red-600">Maximum Item limit reached</p>
            )}
          </div>
        </div>
      </Col>
      <Col span={24}>
        <Table
          tableLayout="auto"
          scroll={{ x: 500 }}
          columns={columns}
          dataSource={services}
          bordered={false}
          pagination={false}
        />
      </Col>
    </>
  )
}

export default Services

import { message, Space, Spin, Typography } from 'antd'
import { Confirmation } from 'components/common/donations/components'
import React, { useEffect, useState } from 'react'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import {
  payWithBillingAggrement,
  createBillingAggrementAndPay,
} from 'store/paymentSlice/paymentActions'

const { Title } = Typography

const PaypalSubmission = () => {
  const [loading, setLoading] = useState(false)
  const [donated, setDonated] = useState(false)
  const [htmlContent, setHtmlContent] = useState()
  const { paypalBillingAggrementSubmitData } = useEventSelector()

  const makePayment = async (baToken) => {
    const data = {
      merchantId: paypalBillingAggrementSubmitData.merchantAccount,
      billingAgreementTokenId: baToken,
    }
    const response = await createBillingAggrementAndPay(data)
    const btoken = response.result.id
    const payload = {
      btoken,
      paypalBillingAggrementSubmitData,
    }
    const successPaid = await payWithBillingAggrement(payload)
    if (successPaid) {
      if (successPaid.htmlReceipt) {
        setHtmlContent(successPaid.htmlReceipt)
      }
      setDonated(true)
      message.success('Donation Submitted Successfully')
    }
  }

  const fetchDataFromURL = async () => {
    setLoading(true)
    try {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      if (Object.entries(params).length > 0) {
        if (params.ba_token) {
          await makePayment(params.ba_token)
        }
      }
      setLoading(false)
    } catch (error) {
      message.error("Can't Process The Payment")
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchDataFromURL()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="t-h-screen t-w-screen t-flex t-justify-center t-items-center">
      {loading ? (
        <div className="t-flex t-flex-col t-items-center t-space-y-2">
          <Space size="middle">
            <Spin size="large" />
          </Space>
          <Title level={4}>Processing Payment</Title>
        </div>
      ) : (
        <>
          {donated ? (
            <Confirmation
              orgWebsite={
                paypalBillingAggrementSubmitData?.organization
                  ? paypalBillingAggrementSubmitData?.organization?.Website
                  : paypalBillingAggrementSubmitData?.event?.organization
                      ?.Website
              }
              orgDomain={
                paypalBillingAggrementSubmitData?.organization
                  ? paypalBillingAggrementSubmitData?.organization?.OrgDomain
                  : paypalBillingAggrementSubmitData?.event?.organization
                      ?.OrgDomain
              }
              htmlContent={htmlContent}
            />
          ) : (
            <div className="t-flex t-flex-col t-items-center t-space-y-2">
              <Title level={4}>Payment Failed</Title>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export { PaypalSubmission }

import { Button, Col, Divider, Form, Input, Modal, Row } from 'antd'
import React from 'react'
import { messageFormValidation } from 'validations/messageForm'

const BusinessContactForm = ({ isModalVisible, setIsModalVisible, submit }) => {
  const [messageForm] = Form.useForm()

  const onFinish = (values) => {
    messageForm.resetFields()
    submit(values)
  }

  return (
    <Modal
      title="Business Contact Form"
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={null}
    >
      <Form form={messageForm} layout="vertical" key={0} onFinish={onFinish}>
        <Row className="t-p-1">
          <Col span={24}>
            <Form.Item
              name="subject"
              label="Subject"
              colon={false}
              rules={[messageFormValidation]}
            >
              <Input placeholder="Enter Subject" />
            </Form.Item>
            <Form.Item
              name="message"
              label="Message"
              colon={false}
              rules={[messageFormValidation]}
            >
              <Input.TextArea
                placeholder="Enter Message ..."
                autoSize={{ minRows: 3, maxRows: 3 }}
              />
            </Form.Item>
            {/* <Form.Item
            name="email"
            label="Return Email"
            colon={false}
            rules={[messageFormValidation]}
          >
            <Input placeholder="Enter Email" />
          </Form.Item> */}
          </Col>
          <Divider />
          <Col span={24} className="text-right">
            <Button type="primary" className="px-25" htmlType="submit">
              Send
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export { BusinessContactForm }

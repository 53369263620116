/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import Api from 'api'
import { formatAmount } from 'utils'

function DonationStatistics({ id, type }) {
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()

  useEffect(() => {
    setLoading(true)
    Api.get(`adminReports/donationStatistics/${id}`)
      .then((res) => {
        setReport(res.results)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  const isEvent = type && type === 'Event'
  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      {report ? (
        <div className="t-space-y-4">
          <div className="t-flex t-items-center t-justify-between">
            <p>Total Donations</p>
            {report.Donations}
          </div>
          {isEvent && (
            <div className="t-flex t-items-center t-justify-between">
              <p>Total Pledges</p>
              {report.TotalPledges}
            </div>
          )}
          <div className="t-flex t-items-center t-justify-between">
            <p>Total Donation Amount</p>
            {formatAmount(report.Total)}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Total Cash Amount</p>
            {formatAmount(report?.Cash)}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Total Checks Amount</p>
            {formatAmount(report.Checks)}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Total Credit Amount</p>
            {formatAmount(report.Credit)}
          </div>
          {isEvent && (
            <div className="t-flex t-items-center t-justify-between">
              <p>Total Pledges Amount</p>
              {formatAmount(report.Pledges)}
            </div>
          )}
        </div>
      ) : (
        <div>No Data Found</div>
      )}
    </Skeleton>
  )
}

export default DonationStatistics

/* eslint-disable */
import React from 'react'
import { InputNumber } from 'antd'

const AmountInput = ({ placeholder, value, onChange, disabled }) => {
  const intlFormat = new Intl.NumberFormat('en-US', {
    compactDisplay: 'short',
    maximumFractionDigits: 2,
  })

  const onChangeValue = (changedValue) => {
    if (
      changedValue &&
      changedValue.includes('.') &&
      changedValue.split('.')[1].length === 3
    ) {
      onChange(value)
    }
    onChange(changedValue || '')
  }

  const onHandleKeyChange = (event) => {
    const keys = [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '0',
      '.',
      'Backspace',
    ]
    if (!keys.includes(event.key)) {
      event.preventDefault()
    }
    const match = value?.match(/\./)
    if (match && event.key === '.') {
      event.preventDefault()
    }
  }

  const formatter = () =>
    value ? intlFormat.format(value).replace(/\D00$/, '') : ''

  return (
    <InputNumber
      placeholder={placeholder}
      prefix="$"
      onChange={onChangeValue}
      formatter={formatter}
      value={value}
      stringMode
      controls={false}
      className="t-w-full"
      onPressEnter={onHandleKeyChange}
      onKeyDown={onHandleKeyChange}
      style={{ borderRadius: '0.4rem' }}
      inputMode="numeric"
      disabled={disabled}
    />
  )
}

export { AmountInput }

import React, { useState } from 'react'
import { Col, Modal, Typography } from 'antd'
import { TermsAndConditions } from 'screens/auth/components'

const { Paragraph } = Typography

const TermsAndConditionsModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <Col xl={24} md={24} xs={24} sm={24}>
      <Paragraph className="t-text-justify t-mt-2">
        <b>Note:</b> You hereby authorize us to charge your account for this
        amount, understand that processing fees may vary based on type of
        payment account used, and agree to our{' '}
        <span
          className="font-bold text-dark hover:t-text-secondary-100 t-cursor-pointer"
          onClick={() => setIsModalVisible(true)}
        >
          Terms and Conditions
        </span>
        <Modal
          title="Terms Of Use Policy"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <TermsAndConditions />
        </Modal>
      </Paragraph>
    </Col>
  )
}

export { TermsAndConditionsModal }

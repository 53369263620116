import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Tooltip,
  Card,
  message,
  Skeleton,
  Switch,
  Table,
  Popconfirm,
} from 'antd'
import { awsconfig, config } from 'configs'
import { getEventAuctionItems } from 'store/eventSlice/eventActions'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { useDispatch } from 'react-redux'
import Api, { makeRequest } from 'api'
import { RiAuctionLine } from 'react-icons/ri'
import { EditFilled } from '@ant-design/icons'
import EditAuctiontemModal from './EditAuctiontemModal'

function AuctionItems() {
  const dispatch = useDispatch()
  const { event, eventAuctionItems } = useEventSelector()
  const [loading, setLoading] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [selected, setSelected] = useState()

  useEffect(() => {
    if (event) {
      dispatch(getEventAuctionItems(event.Id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event])

  const onClick = async (eventId, id, status) => {
    const data = {
      type: 'event',
      id: eventId,
      itemId: id,
      status,
    }
    Api.put('auction/updateAuctionItemStatus', data)
      .then(() => {
        dispatch(getEventAuctionItems(eventId, setLoading))
        message.success('Item Status Changed')
      })
      .catch((error) => {
        console.log('error', error)
        message.error(error?.response?.data?.message || 'Something went wrong')
      })
  }

  const ItemAuctionWinner = (bidHistory) => {
    const tempArr = []
    let winnerName
    let winnerEmail
    let winnerPhone
    let winnerBid

    if (bidHistory.length > 0) {
      for (let i = 0; i < bidHistory.length; i += 1) {
        const index = tempArr.findIndex(
          (ta) => bidHistory[i].email === ta.email,
        )
        if (index >= 0) {
          tempArr[index] = { ...bidHistory[i], status: 'lost' }
        } else {
          tempArr.push({ ...bidHistory[i], status: 'lost' })
        }
      }
      tempArr.sort((a, b) => Number(b.bid) - Number(a.bid))
      tempArr[0].status = 'won'

      winnerName = tempArr[0].name
      winnerEmail = tempArr[0].email
      winnerPhone = tempArr[0].phone
      winnerBid = tempArr[0].bid
    }
    return { histoy: tempArr, winnerName, winnerEmail, winnerPhone, winnerBid }
  }

  const onEndItemAuction = (item) => {
    const sortedWinnerData = ItemAuctionWinner(item.bidHistory)

    const data = {
      type: 'event',
      eventId: event?.Id,
      organizationId: event?.OrganizationId,
      organizationName: event?.OrganizationName,
      organizationEmail: event?.CreatedBy,
      itemId: item.id,
      description: item.description,
      title: item.title,
      itemImage: item.itemImage,
      paymentStatus: 'Pending',
      deliveryStatus: false,
      bidHistory: sortedWinnerData?.histoy,
      winnerName: sortedWinnerData?.winnerName,
      winnerEmail: sortedWinnerData?.winnerEmail,
      winnerPhone: sortedWinnerData?.winnerPhone,
      winnerBid: sortedWinnerData?.winnerBid,
    }
    makeRequest(
      'post',
      'auction/sellAuctionItem',
      {
        organization: event?.OrganizationId,
      },
      { data },
    )
      .then(() => {
        message.success('Item Sold')
        dispatch(getEventAuctionItems(event.Id))
      })
      .catch((error) => {
        console.log('error', error)
        message.error(error?.response?.data?.message || 'Something went wrong')
      })
  }

  const onEndItemAuctionWithoutSelling = (item) => {
    const data = {
      type: 'event',
      eventId: event?.Id,
      itemId: item.id,
    }
    Api.post('auction/endItemAuction', { data })
      .then(() => {
        message.success('Item Auction Ended')
        dispatch(getEventAuctionItems(event.Id))
      })
      .catch((error) => {
        console.log('error', error)
        message.error(error?.response?.data?.message || 'Something went wrong')
      })
  }

  const columns = [
    {
      // title: 'Image',
      dataIndex: 'itemImage',
      className: 'ant-table-row-cell-break-word',
      render: (_, { itemImage }) => (
        <Avatar
          src={itemImage?.fileName && awsconfig.bucketUrl + itemImage?.fileName}
          alt="photo"
          className="mr-10"
          size={48}
          shape="square"
        />
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      className: 'ant-table-row-cell-break-word',
    },
    // {
    //   title: 'Description',
    //   dataIndex: 'description',
    //   className: 'ant-table-row-cell-break-word',
    // },
    {
      title: 'Start Bid',
      dataIndex: 'startBid',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Winning Bid',
      dataIndex: 'winningBid',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Current Bid',
      dataIndex: 'currentBid',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Live',
      dataIndex: 'status',
      className: 'ant-table-row-cell-break-word',
      render: (_, { status, id }) => (
        <Switch
          loading={loading}
          checked={status === 'Live'}
          onChange={(value) =>
            onClick(event.Id, id, value ? 'Live' : 'Standby')
          }
        />
      ),
    },
    {
      // title: 'Live',
      dataIndex: 'id',
      className: 'ant-table-row-cell-break-word',
      render: (_, item) => {
        const bidFound = item.bidHistory && item.bidHistory.length > 0
        const winningBidNotMet =
          Number(item.winningBid) > Number(item.currentBid)
        return (
          <div className="t-flex t-items-center t-space-x-2">
            <Tooltip title="Edit">
              <EditFilled
                className="t-cursor-pointer t-text-secondary-100"
                style={{ fontSize: '1.5rem' }}
                onClick={() => {
                  setSelected(item)
                  setEditModal(true)
                }}
              />
            </Tooltip>
            <Tooltip title="End Item Auction">
              <Popconfirm
                title={
                  bidFound
                    ? winningBidNotMet
                      ? 'Current bid is less than winning bid, Do you want to sell this item or end auction without selling'
                      : 'Are you sure to end this item auction, it will be sold to highest bidder?'
                    : 'No bid at this item, Do you want to end auction without selling'
                }
                onConfirm={() =>
                  bidFound
                    ? onEndItemAuction(item)
                    : onEndItemAuctionWithoutSelling(item)
                }
                onCancel={() =>
                  bidFound &&
                  winningBidNotMet &&
                  onEndItemAuctionWithoutSelling(item)
                }
                okText={
                  bidFound
                    ? winningBidNotMet
                      ? 'Sell to Highest Bidder'
                      : 'Yes'
                    : 'Yes'
                }
                cancelText={
                  bidFound
                    ? winningBidNotMet
                      ? 'End Without Selling'
                      : 'No'
                    : 'No'
                }
              >
                <RiAuctionLine
                  fontSize="1.8rem"
                  className="t-text-secondary-100 t-cursor-pointer"
                />
              </Popconfirm>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  return (
    <Card className="t-w-full">
      <Skeleton loading={false} active paragraph={{ rows: 12 }}>
        <Table
          columns={columns}
          dataSource={eventAuctionItems || 0}
          tableLayout="auto"
          scroll={{ x: 500 }}
          bordered={false}
          pagination={{
            pageSize: config.rowsPerPage,
            showSizeChanger: false,
          }}
        />
      </Skeleton>
      {editModal && (
        <EditAuctiontemModal
          visible={editModal}
          close={() => {
            setSelected()
            setEditModal(false)
          }}
          selected={selected}
          eventId={event?.Id}
        />
      )}
    </Card>
  )
}

export { AuctionItems }

import React, { useEffect, useState } from 'react'
import { About } from './About'
import { Settings } from './Settings'
import { Devices } from './Devices'

const UpdateOrganization = () => {
  const [sec, setSec] = useState(1)
  const [isMobile, setIsMobile] = useState(false)

  const handleSection = (val) => {
    setSec(val)
  }
  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth < 1024) // 1024px is the lg breakpoint
    }

    updateIsMobile()
    window.addEventListener('resize', updateIsMobile)

    return () => window.removeEventListener('resize', updateIsMobile)
  }, [])

  return (
    <div className="t-flex t-flex-col t-max-w-[820px] t-rounded-md t-bg-white t-m-auto">
      {/* Left Div */}
      <div className="t-flex t-flex-row t-items-center t-justify-center t-h-20">
        <div
          onClick={() => handleSection(1)}
          className={`${
            sec === 1
              ? 't-border-b-[#2e97de] t-border-b-2'
              : 't-border-b-gray-700 t-border-b'
          } t-py-6 t-cursor-pointer t-w-[33.333%] t-bg-white`}
        >
          <h1
            className={`${
              sec === 1 ? 't-text-[#2e97de]' : 't-text-black'
            } t-text-lg t-font-medium t-text-center roboto-font`}
          >
            About
          </h1>
        </div>
        <div
          onClick={() => handleSection(2)}
          className={`${
            sec === 2
              ? 't-border-b-[#2e97de] t-border-b-2'
              : 't-border-b-gray-700 t-border-b'
          } t-py-6 t-cursor-pointer t-w-[33.333%] t-bg-white`}
        >
          <h1
            className={`${
              sec === 2 ? 't-text-[#2e97de]' : 't-text-black'
            } t-text-lg t-font-medium t-text-center roboto-font`}
          >
            Settings
          </h1>
        </div>
        <div
          onClick={() => handleSection(3)}
          className={`${
            sec === 3
              ? 't-border-b-[#2e97de] t-border-b-2'
              : 't-border-b-gray-700 t-border-b'
          } t-py-6 t-cursor-pointer t-w-[33.333%] t-border-b t-bg-white`}
        >
          <h1
            className={`${
              sec === 3 ? 't-text-[#2e97de]' : 't-text-black'
            } t-text-lg t-font-medium t-text-center roboto-font`}
          >
            Devices
          </h1>
        </div>
      </div>
      {/* <hr className="t-h-[2px] t-bg-gray-500 t-mt-2 t-w-[100%] md:t-w-[80%] lg:t-w-[60%] mx-auto " /> */}

      <div className="t-flex t-flex-col lg:t-flex-row">
        <div className="lg:t-w-[45%] t-w-[100%]">
          <div className="t-flex t-flex-row t-mt-12 lg:t-mt-24 t-items-center t-justify-center lg:t-justify-start">
            <h1 className="t-text-lg t-text-gray-900 lg:t-pl-12 roboto-font">
              {sec === 1
                ? 'Update Organizational Data'
                : sec === 2
                ? 'Customize Your Settings'
                : sec === 3
                ? 'Customize Device Settings'
                : ''}
            </h1>
          </div>
        </div>
        {/* Right Div */}
        <div
          className={`t-flex t-flex-col t-justify-center ${
            isMobile ? 't-items-center' : ''
          } lg:t-w-[55%] t-w-[100%] t-mt-4`}
        >
          {sec === 1 && <About />}
          {sec === 2 && <Settings />}
          {sec === 3 && <Devices />}
        </div>
      </div>
    </div>
  )
}

export { UpdateOrganization }

import React from 'react'
import { Button, Col, Card, Typography } from 'antd'
import { formatAmount, formateDate } from 'utils'
import { FaRegClock, FaRegEdit } from 'react-icons/fa'
import { HiOutlineCalendar } from 'react-icons/hi'

const { Text } = Typography

const ReservationDetail = ({
  reservation,
  isUpdate,
  onUpdateClick,
  onSave,
  isSaving,
}) => (
  <>
    {reservation && (
      <Col span={24} className="t-mt-4 t-mb-5">
        <Card className="t-w-full">
          <Col xl={24} md={24} xs={24} sm={24} className="t-mt-4">
            <div className="t-flex t-flex-row t-justify-between">
              <Text className="t-text-black t-font-semibold t-mb-4 t-text-lg t-mr-2">
                Room Reservation Details
                {/* <span className="t-font-bold t-text-base t-text-black">
                {formateDate(reservation?.Date)}
              </span> */}
              </Text>
              <div
                className="t-flex t-flex-row t-text-center t-items-center"
                onClick={onUpdateClick}
              >
                <Text className="t-text-[#2e97de] t-text-base t-mx-1 t-cursor-pointer">
                  Edit
                </Text>
                <FaRegEdit className="t-text-[#2e97de] t-text-base t-mx-1 t-cursor-pointer" />
                {/* <Button
              type="primary"
              loading={isSaving}
              size="small"
              onClick={onUpdateClick}
            >
              {isUpdate
                ? 'Click to close update reservation'
                : 'Click to open update reservation'}
            </Button> */}
              </div>
            </div>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <h1 className="t-text-base t-text-black">Durations</h1>
            <div className=" t-bg-[#f9f9fc] t-w-[100%] t-rounded">
              <div className="t-flex t-flex-row t-mt-4">
                <HiOutlineCalendar className="t-mx-4 t-mt-3 t-text-base" />
                <div className="t-flex t-flex-col">
                  <Text className="t-text-base t-mt-2">
                    {formateDate(reservation?.Date)}
                  </Text>
                </div>
              </div>
              <div className="t-flex t-flex-row t-mb-8 t-mt-2">
                <FaRegClock className="t-mx-4 t-text-base t-mt-2" />
                <div className="t-flex t-flex-col t-mb-4">
                  {reservation?.Durations?.map((item, index) => (
                    <Text
                      className="t-text-base t-mt-1"
                      key={index}
                    >{`${item?.from} - ${item?.to}`}</Text>
                  ))}
                </div>
              </div>
            </div>
          </Col>
          {reservation.IsRecurring && (
            <Col xl={24} md={24} xs={24} sm={24}>
              <h1 className="t-text-base t-text-black">Recurring Dates</h1>

              <div className=" t-bg-[#f9f9fc] t-w-[100%] t-rounded">
                <div className="t-flex t-flex-row t-mb-8 t-mt-2 t-pt-1 ">
                  <FaRegClock className="t-mx-4 t-text-base t-mt-2" />
                  <div className="t-flex t-flex-col t-mb-4">
                    {reservation?.RecurringDates?.map((item, index) => (
                      <Text
                        className="t-text-base t-mt-1"
                        key={index}
                      >{`${formateDate(item)}`}</Text>
                    ))}
                  </div>
                </div>
              </div>
            </Col>
          )}
          <Col xl={24} md={24} xs={24} sm={24}>
            <h1 className=" t-text-gray-900">
              {`You have made a reservation for ${
                reservation?.TotalHours
              } hours with a total cost of ${formatAmount(
                reservation?.TotalCost,
              )}`}
            </h1>
          </Col>
          {reservation?.AcceptanceStatus === 'ChangeRequested' && (
            <Col xl={24} md={24} xs={24} sm={24}>
              <h1 className=" t-text-red-500 t-mb-4">
                Room owner has made a change date request. Kindly see the
                details and make approriate changes and update the reservation
                to send the request to room owner for review.
              </h1>
            </Col>
          )}
          <Col
            xl={24}
            md={24}
            xs={24}
            sm={24}
            className="t-flex t-justify-end t-w-full t-space-x-2 t-mt-4"
          >
            {/* <Button
              type="primary"
              loading={isSaving}
              size="small"
              onClick={onUpdateClick}
            >
              {isUpdate
                ? 'Click to close update reservation'
                : 'Click to open update reservation'}
            </Button> */}
            {!isUpdate && (
              <Button
                loading={isSaving}
                type="primary"
                size="small"
                onClick={onSave}
              >
                Accept Change
              </Button>
            )}
          </Col>
        </Card>
      </Col>
    )}
  </>
)

export { ReservationDetail }

import React, { useEffect } from 'react'
import { Card, Table, Skeleton } from 'antd'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useDispatch } from 'react-redux'
import { getDonorFeedbacks } from 'store/authSlice/authActions'
import { formateDateTime } from 'utils'

const columns = [
  {
    title: 'Id',
    dataIndex: 'Id',
    className: 'ant-table-row-cell-break-word',
  },
  {
    title: 'Name',
    dataIndex: 'Name',
    className: 'ant-table-row-cell-break-word',
  },
  {
    title: 'Message',
    dataIndex: 'Message',
    className: 'ant-table-row-cell-break-word',
  },
  {
    title: 'Email',
    dataIndex: 'Email',
    className: 'ant-table-row-cell-break-word',
  },
  {
    title: 'Phone',
    dataIndex: 'Phone',
    className: 'ant-table-row-cell-break-word',
  },
  {
    title: 'Subject',
    dataIndex: 'Subject',
    className: 'ant-table-row-cell-break-word',
  },
  {
    title: 'Created Date',
    dataIndex: 'CreatedAt',
    className: 'ant-table-row-cell-break-word',
    render: (_, { CreatedAt }) => <>{formateDateTime(CreatedAt)}</>,
  },
]

const PlatformFeedbacks = () => {
  const { loading, donorFeedbacks } = useAuthSelector()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDonorFeedbacks())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[<h6 className="mb-0">Platform Feedback</h6>]}
    >
      <div style={{ minHeight: 'calc(100vh - 220px)' }}>
        <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
          <Table
            size="middle"
            tableLayout="auto"
            scroll={{
              x: 'max-content',
            }}
            pagination={{
              pageSize: 10,
            }}
            style={{
              width: 'auto',
              minWidth: 'unset !important',
            }}
            columns={columns}
            dataSource={donorFeedbacks}
            bordered={false}
          />
        </Skeleton>
      </div>
    </Card>
  )
}

export { PlatformFeedbacks }

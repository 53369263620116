import React, { useEffect, useRef, useState } from 'react'
import {
  Skeleton,
  Card,
  Row,
  Col,
  Typography,
  Divider,
  Dropdown,
  Menu,
  Carousel,
  message,
} from 'antd'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { awsconfig } from 'configs'
import { formatAmount } from 'utils'
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
  BsThreeDotsVertical,
} from 'react-icons/bs'
import { IoIosArrowBack } from 'react-icons/io'
import sample from 'assets/images/sample/sample1.png'
import { useAuctionSelector } from 'store/auctionSlice/auctionReducer'
import { getAuctionDetails } from 'store/auctionSlice/auctionActions'
import { SoldItems } from 'components/common/auction'
import { AuctionItems } from './components'

const { Title, Paragraph } = Typography

const AuctionControl = () => {
  const slider = useRef(null)
  const [activeKey, setActiveKey] = useState('1')
  // const [decidingModal, setDecidingModal] = useState(false)
  const { id } = useParams()
  const { loading, auction } = useAuctionSelector()
  const history = useHistory()
  const { state, search } = useLocation()
  const dispatch = useDispatch()

  const filterEventAuctionItems = auction?.AuctionItemsList?.filter(
    (item) => item.status === 'Live',
  )

  const fetchData = () => {
    dispatch(getAuctionDetails(id))
  }

  useEffect(() => {
    if (id) {
      fetchData()
    } else {
      message.error('Invalid URL')
      history.push('/auction')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (search) {
      const urlSearchParams = new URLSearchParams(search)
      const { key } = Object.fromEntries(urlSearchParams.entries())
      if (key) setActiveKey(key)
    }
    // eslint-disable-next-line
  }, [activeKey])

  const addToQueryParam = (key) => {
    const currentUrl = window.location.href
    const urlObject = new URL(currentUrl)
    const queryParams = new URLSearchParams(urlObject.search)

    if (queryParams.has('key')) {
      queryParams.set('key', key)
    } else {
      queryParams.append('key', key)
    }

    const newUrl = `${urlObject.origin}${
      urlObject.pathname
    }?${queryParams.toString()}`
    if (window.history.pushState) {
      window.history.pushState(null, null, newUrl)
    } else {
      window.location.replace(newUrl)
    }
    setActiveKey(key)
  }

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: <div onClick={() => addToQueryParam('1')}>Auction Items</div>,
        },
        {
          key: '2',
          label: <div onClick={() => addToQueryParam('2')}>Sold Items</div>,
        },
      ]}
    />
  )

  // const onEndEventAuction = () => {
  //   setDecidingModal(true)
  // }

  return (
    <Card className="t-pb-10">
      <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
        <Row className="t-relative">
          <div
            onClick={() =>
              state && state.urlData
                ? history.push({
                    pathname: state.urlData.pathname,
                    state: { key: state.urlData.activeKey },
                  })
                : history.push('/auction')
            }
            className="t-rounded-full glass_bg t-cursor-pointer t-fixed t-top-20 md:t-top-24 t-z-50 t-text-secondary-100 t-p-1"
          >
            <IoIosArrowBack fontSize="1.5rem" />
          </div>
        </Row>
        <Row>
          <Col span={24} className="t-pr-2">
            <Row
              justify="space-between"
              className="t-items-center t-mb-4 t-hidden md:t-flex"
            >
              <div className="t-ml-10 t-flex t-space-x-4 t-items-center">
                <h1 className="t-capitalize t-text-3xl t-font-bold t-text-secondary-100">
                  {auction?.Title}
                </h1>
                {/* {event?.startEvent && (
                  <div className="t-space-x-2 t-flex t-items-center t-border-2 t-px-2 t-py-1 t-border-green-400 t-rounded-full">
                    <div className="t-h-3 t-w-3 t-bg-green-400 t-rounded-full" />
                    <h1 className="t-text-secondary-100">Live</h1>
                  </div>
                )} */}
              </div>
            </Row>
            <Row className="t-mb-4 t-flex t-items-center t-visible md:t-hidden">
              <Row className="t-w-full t-flex t-justify-between t-items-center">
                <div className="t-flex t-space-x-4 t-items-center t-w-10/12">
                  <h1 className="t-ml-10 t-text-xl t-font-bold t-text-secondary-100">
                    {`${auction?.Title}`}
                  </h1>
                  {/* {event?.startEvent && (
                    <div className="t-space-x-2 t-flex t-items-center t-border-2 t-px-2 t-py-1 t-border-green-400 t-rounded-full">
                      <div className="t-h-2 t-w-2 t-bg-green-400 t-rounded-full" />
                      <h1 className="t-text-secondary-100 t-text-xs">Live</h1>
                    </div>
                  )} */}
                </div>
              </Row>
            </Row>
            <Row span={24} lg={24} xl={24} className="col-gallery mb-24">
              <Skeleton loading={false} active paragraph={{ rows: 18 }}>
                {filterEventAuctionItems.length > 0 ? (
                  <div className="t-relative t-w-full t-h-44 md:t-h-96 t-px-2">
                    <BsFillArrowLeftCircleFill
                      fontSize="1.2rem"
                      onClick={() => slider.current.prev()}
                      className="t-text-secondary-100 t-cursor-pointer t-absolute t-top-[35%] -t-left-[6px] t-hidden md:t-block"
                    />
                    <Carousel ref={slider} slidesPerRow={3}>
                      {filterEventAuctionItems.map((item) => (
                        <div className="t-h-44 md:t-h-96 t-px-2">
                          {/* <div className="t-h-44 md:t-h-96 t-flex t-justify-center t-items-center"> */}
                          <Card
                            bordered
                            hoverable
                            cover={
                              <img
                                alt="example"
                                className="t-mt-2 t-w-full t-h-16 md:t-h-48 t-object-contain t-rounded-br-none t-rounded-bl-none t-rounded-tr-md t-rounded-tl-md md:t-rounded-md"
                                src={
                                  item?.itemImage?.fileName
                                    ? awsconfig.bucketUrl +
                                      item?.itemImage?.fileName
                                    : sample
                                }
                              />
                            }
                            // className="t-w-[30%]"
                          >
                            <div className="t-flex t-justify-between t-items-center">
                              <h5 className="t-text-base md:t-text-2xl t-text-secondary-100 t-font-bold t-truncate">
                                {item.title}
                              </h5>
                            </div>

                            <div className="t-flex t-justify-between">
                              <div className="card-tag t-font-thin t-text-secondary-100">
                                {item.currentBid &&
                                  formatAmount(item.currentBid)}
                              </div>
                            </div>
                            <p className="t-line-clamp-1 t-text-sm">
                              {item.description}
                            </p>
                          </Card>
                        </div>
                      ))}
                    </Carousel>
                    <BsFillArrowRightCircleFill
                      fontSize="1.2rem"
                      onClick={() => slider.current.next()}
                      className="t-text-secondary-100 t-cursor-pointer t-absolute t-top-[35%] -t-right-[6px] t-hidden md:t-block"
                    />
                  </div>
                ) : (
                  <img
                    src={
                      auction?.Flyer?.fileName
                        ? awsconfig.bucketUrl + auction?.Flyer?.fileName
                        : auction?.organization?.BackgroundImage
                        ? awsconfig.bucketUrl +
                          auction?.organization?.BackgroundImage?.fileName
                        : sample
                    }
                    className="t-h-40 md:t-h-96 mb-24 t-w-full gallery-img shadow-3 img-contain"
                    alt="card-2"
                  />
                )}
                {/* {auction?.AuctionItemsList?.length > 0 && (
                  <>
                    <div className="t-w-full t-flex t-justify-end t-items-center t-space-x-2">
                      <Tooltip title="End Auction">
                        <GiFinishLine
                          fontSize="1.8rem"
                          className="t-text-secondary-100 t-cursor-pointer"
                          onClick={() => onEndEventAuction()}
                        />
                      </Tooltip>
                    </div>
                    <DecidingItems
                      visible={decidingModal}
                      onClose={() => setDecidingModal(false)}
                    />
                  </>
                )} */}
              </Skeleton>
            </Row>
            <Row>
              <Title level={4}>Description</Title>
            </Row>
            <Row>
              <Paragraph className="t-capitalize">{auction?.Details}</Paragraph>
            </Row>
            <Row span={24} justify="space-between" className="t-pr-4">
              <Col>
                <Row>
                  <Title level={4}>Organization</Title>
                </Row>
                <Row>
                  <Paragraph className="t-capitalize">
                    {auction?.OrganizationName}
                  </Paragraph>
                </Row>
              </Col>
            </Row>
            <Divider />
            <>
              <Divider />
              <Row className="t-w-full" animated={false}>
                <div className="t-w-full t-flex t-justify-between t-m-2 t-items-center">
                  <h1 className="t-font-bold t-text-secondary-100 t-text-xl">
                    {activeKey === '1' && 'Auction Items'}
                    {activeKey === '2' && 'Sold Items'}
                  </h1>
                  <Dropdown overlay={menu} placement="bottomLeft" arrow>
                    <BsThreeDotsVertical
                      fontSize="1.5rem"
                      id="control-screen-dropdown"
                      className="t-cursor-pointer t-mx-1"
                    />
                  </Dropdown>
                </div>
              </Row>
              <Row className="t-w-full" animated={false}>
                {activeKey === '1' && (
                  <AuctionItems auction={auction} fetchData={fetchData} />
                )}
                {activeKey === '2' && (
                  <SoldItems type="auction" parentId={id} />
                )}
              </Row>
            </>
          </Col>
        </Row>
      </Skeleton>
    </Card>
  )
}

export { AuctionControl }

import * as Yup from 'yup'

const schema = Yup.object().shape({
  OrganizationId: Yup.string().required('Organizations is required'),
  HardwareType: Yup.string().required('Hardware Type is required'),
  DeviceSerialNumber: Yup.string().required('Device Serial Number is required'),
  MDMDeviceName: Yup.string().required('MDM Device Name is required'),
  DeploymentDate: Yup.date()
    .typeError('Invalid Date')
    .required('Deployment Date is required'),
  CustomerOwned: Yup.boolean().required('Customer Owned is required'),
  // HealthCheck: Yup.string(),
  // BatteryPercent: Yup.string(),
  CardReaderSerialNumber: Yup.string(),
  CardReaderIdentifier: Yup.string(),
  CardReaderType: Yup.string(),
  BluetoothCode: Yup.string(),
  Notes: Yup.string(),
})

const hardwareValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { hardwareValidation }

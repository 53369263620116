import { REGEXS } from 'utils'
import * as Yup from 'yup'

const amountValidation = REGEXS.amountRegex

const schema = Yup.object().shape({
  askedAmount: Yup.string()
    .required('Required')
    .matches(amountValidation, 'Invalid amount'),
  extraAskedAmount: Yup.string()
    .required('Required')
    .matches(amountValidation, 'Invalid amount'),
  showExtraAmount: Yup.boolean().required('Required'),
  includeProcessingFees: Yup.boolean().required('Required'),
  isRecurringPayment: Yup.string().required('Required'),
  numberOfRecurring: Yup.string()
    .typeError('Required')
    .required('Required')
    .matches(REGEXS.numberRegex, 'Invalid months'),
  isSplitPayment: Yup.string().required('Required'),
  numberOfSplit: Yup.string()
    .typeError('Required')
    .required('Required')
    .matches(REGEXS.numberRegex, 'Invalid months'),
})

const paymentValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { paymentValidation }

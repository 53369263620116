/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Layout } from 'antd'
import { SVGIcon } from 'components/elements'

const { Content } = Layout

const Error404 = () => (
  <Layout className="layout-default layout-signin">
    <Content className="signin">
      <div className="ant-result ant-result-404">
        <SVGIcon />
        <div className="ant-result-title">404</div>
        <div className="ant-result-subtitle">
          Sorry, the page you visited does not exist.
        </div>
        <div className="ant-result-extra">
          <a
            href="/dashboard"
            className="ant-btn ant-btn-primary"
            style={{ lineHeight: '40px' }}
          >
            Back Home
          </a>
        </div>
      </div>
    </Content>
  </Layout>
)

export { Error404 }

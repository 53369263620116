import React, { useEffect, useState } from 'react'
import { Card, Col, Tag, Skeleton, Modal, Typography, Row, Space } from 'antd'
// import { MdModeEdit } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { awsconfig } from 'configs'
import sample from 'assets/images/sample/sample1.png'
import { useDispatch } from 'react-redux'
import { getOrganizationRooms } from 'store/roomSlice/roomActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useRoomSelector } from 'store/roomSlice/roomReducer'
import { htmlCode, mobileShareNavigator, isMobile, searchRentals } from 'utils'
import { CopyBlock, dracula } from 'react-code-blocks'
import { FaCode, FaRegEdit } from 'react-icons/fa'
import { FaShareFromSquare } from 'react-icons/fa6'
import { ShareModal } from '../../../../../../components/elements/ShareModal'

const { Title, Text } = Typography

const MyRooms = ({ searchText }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { organization } = useAuthSelector()
  const { loading, rooms } = useRoomSelector()
  const [embedURLModalVisible, setEmbedURLModalVisible] = useState(false)
  const [rentalId, setRentalId] = useState('')
  const [isShare, setShare] = useState(false)
  const changeModal = (id) => {
    setRentalId(id)
    setEmbedURLModalVisible(!embedURLModalVisible)
  }

  const shareVolunteer = async (name, id) => {
    setRentalId(id)
    if (isMobile) {
      await mobileShareNavigator(
        name,
        `${awsconfig.API.endpoints[0].api_url}/html/share-room/${id}`,
      )
    } else {
      setShare(true)
    }
  }

  useEffect(() => {
    dispatch(getOrganizationRooms(organization?.id))
    // eslint-disable-next-line
  }, [])

  const updatedRooms = searchRentals(rooms, searchText)
  return (
    <Card
      // title={[
      //   <div>
      //     <h4 className="mb-0">My Rooms</h4>
      //     <p className="font-regular">Manage Rooms</p>
      //   </div>,
      // ]}
      className="t-mb-5 t-border-none t-shadow-none"
    >
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <Row gutter={[24, 24]}>
          {(!updatedRooms || updatedRooms?.length === 0) && (
            <Card className="t-h-96 t-w-full t-flex t-justify-center t-items-center">
              <div>No Rooms </div>
            </Card>
          )}
          {updatedRooms?.map((item, index) => (
            <Col span={24} lg={8} className="mb-24" key={index}>
              <Card
                bodyStyle={{ paddingTop: '14px' }}
                bordered={false}
                className="card-project-2 header-solid t-cursor-pointer t-border-none t-shadow-none"
                cover={
                  <img
                    alt="example"
                    className="t-w-full t-h-48 t-object-cover t-rounded-md"
                    src={
                      item?.Image?.length
                        ? awsconfig.bucketUrl + item?.Image[0]?.fileName
                        : item?.Image?.fileName
                        ? awsconfig.bucketUrl + item?.Image?.fileName
                        : organization?.BackgroundImage?.fileName
                        ? awsconfig.bucketUrl +
                          organization?.BackgroundImage?.fileName
                        : sample
                    }
                  />
                }
              >
                <div className="t-absolute t-top-[45%] t-right-0">
                  {item.Available ? (
                    <Tag className=" t-bg-[#f9f9fc] t-text-[#00bd9b] t-border-none t-py-1 t-text-sm t-font-medium">
                      Open
                    </Tag>
                  ) : (
                    <Tag className=" t-bg-[#fdedeb] t-text-[#ea4b36] t-border-none t-py-1 t-text-sm t-font-medium">
                      Closed
                    </Tag>
                  )}
                  <Tag className=" t-bg-white t-text-cyan-600 t-border-none t-py-1 t-text-center t-text-sm t-font-medium">{`capacity: ${item?.Capacity}`}</Tag>
                </div>
                <Row gutter={[24, 24]} key={0}>
                  <Col>
                    <h6 className="font-semibold t-w-64 t-truncate t-text-xl">
                      {`${item?.Name}`}
                    </h6>
                  </Col>
                </Row>
                <Space>
                  <Space
                    wrap
                    className="t-border-r-2 t-text-black t-pr-[0.5rem]"
                    onClick={() => changeModal(item?.Id)}
                  >
                    <FaCode className="t-text-base t-cursor-pointer" />
                    <Text className="t-text-base t-cursor-pointer">
                      Embed Code
                    </Text>
                  </Space>
                  <Space
                    wrap
                    className="t-border-r-2 t-text-black t-pr-[0.5rem]"
                    onClick={() =>
                      history.push(`/settings/create-room/${item?.Id}`)
                    }
                  >
                    <FaRegEdit className="t-text-base t-cursor-pointer" />
                    <Text className="t-text-base t-cursor-pointer">Edit</Text>
                  </Space>
                  <Space
                    wrap
                    onClick={() => shareVolunteer(item?.Name, item?.Id)}
                  >
                    <FaShareFromSquare className="t-text-base t-cursor-pointer" />
                    <Text className="t-text-base t-cursor-pointer">Share</Text>
                  </Space>
                </Space>
              </Card>
            </Col>
          ))}
        </Row>
      </Skeleton>
      <ShareModal
        url={`${awsconfig.API.endpoints[0].api_url}/html/share-room/${rentalId}`}
        visible={isShare}
        onClose={() => setShare(false)}
      />
      <Modal
        centered
        title="Embed URL"
        width={600}
        open={embedURLModalVisible}
        onCancel={changeModal}
        footer={null}
      >
        <div>
          <div className="t-my-2">
            <Title level={4}>Copy iframe code</Title>
            <CopyBlock
              language="html"
              text={`
              <iframe
              style="width: 100%; height: 100%"
              src=${awsconfig.API.endpoints[0].api_url}/html/share-room/${rentalId}
              title="Register through Extensia"
              ></iframe>`}
              theme={dracula}
              wrapLines
              codeBlock
            />
          </div>
          <div className="t-my-2">
            <Title level={4}>Copy code to paste in the website</Title>
            <CopyBlock
              language="html"
              text={htmlCode(
                `${awsconfig.API.endpoints[0].api_url}/html/share-room/${rentalId}`,
                'Register',
              )}
              theme={dracula}
              wrapLines
              codeBlock
            />
          </div>
        </div>
      </Modal>
    </Card>
  )
}

export { MyRooms }

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Card, Skeleton } from 'antd'
import { CarryOutFilled } from '@ant-design/icons'
import Api from 'api'
import { config } from 'configs'
import { InfiniteScrollContainer, ShareReport } from 'components/elements'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { useHistory } from 'react-router-dom'

const MasterDonorListReport = () => {
  const history = useHistory()
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()
  const [current, setCurrent] = useState([])

  useEffect(() => {
    setLoading(true)
    Api.get('adminReports/masterDonorList')
      .then((res) => {
        const data = res.results
        setReport(data)
        setCurrent(data.slice(0, config.rowsPerPage))
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <Card
      id="MasterDonorListReport"
      className="mb-24 header-solid list-settings-sessions"
      bordered={false}
      title={[
        <div className="t-flex t-justify-between t-items-center">
          <div className="t-flex t-space-x-4">
            <IoMdArrowRoundBack
              fontSize="1.5rem"
              className="t-cursor-pointer t-min-w-[1.5rem]"
              id="back"
              onClick={() =>
                history.push('/settings/manage-organization/my-reports?key=2')
              }
            />
            <h5 className="font-semibold">Master Community List</h5>
          </div>
          {!loading && report && report.length > 0 && (
            <ShareReport
              reportName="Master Donor List Report"
              report={report}
            />
          )}
        </div>,
      ]}
    >
      <Skeleton loading={loading} paragraph={{ rows: 12 }}>
        {report && report.length > 0 ? (
          <InfiniteScrollContainer
            data={report}
            current={current}
            setCurrent={setCurrent}
          >
            <>
              {current.map((item, index) => (
                <>
                  {index !== 0 && <hr className="gradient-line my-10" />}
                  <div className="ant-row-flex ant-row-flex-middle">
                    <div
                      className="text-center ant-col"
                      style={{ minWidth: '40px' }}
                    >
                      <CarryOutFilled
                        className="text-gray-6"
                        style={{ fontSize: '18px' }}
                      />
                    </div>
                    <div className="ant-col pl-15">
                      <p className="mb-0">{item?.DonorName}</p>
                      {/* <p className="mb-0">{item?.DonorPhoneNumber}</p> */}
                    </div>
                    <div className="ml-auto ant-col ant-col-24 ant-col-md-12 t-flex t-flex-col t-items-end">
                      {/* <span className="ml-5">{formatAmount(item?.Amount)}</span> */}
                      <span className="ml-5">{item?.DonorEmail}</span>
                      <span className="ml-5">{item?.DonorPhoneNumber}</span>
                    </div>
                  </div>
                </>
              ))}
            </>
          </InfiniteScrollContainer>
        ) : (
          <div>No Data Found</div>
        )}
      </Skeleton>
    </Card>
  )
}

export { MasterDonorListReport }

import { message } from 'antd'
import Api, { makeRequest } from 'api'
import moment from 'moment'
import { uploadFile } from 'store/authSlice/authActions'
import { sortByDate, sortByZipcode } from 'utils'
import { volunteerActions } from './volunteerReducer'

export const getVolunteers = (sortKey) => (dispatch) => {
  dispatch(volunteerActions.setLoading(true))
  Api.get('/volunteer/all')
    .then((res) => {
      const sortedData = sortByDate(res?.results, sortKey)
      dispatch(volunteerActions.setVolunteers(sortedData))
      dispatch(volunteerActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(volunteerActions.setLoading(false))
    })
}

export const geAllVolunteer = (loadingOff, zipCode) => (dispatch) => {
  Api.get('/volunteer/all')
    .then((res) => {
      const sortedData = sortByZipcode(res?.results, 'ZipCode', zipCode)
      dispatch(volunteerActions.setAllVolunteers(sortedData))
      loadingOff()
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(volunteerActions.setAllVolunteers([]))
      loadingOff()
    })
}

export const geNearByVolunteer = (body, sortKey) => (dispatch) => {
  dispatch(volunteerActions.setLoading(true))
  Api.post('/volunteer/nearBy', body)
    .then((res) => {
      const sortedData = sortByDate(res?.results, sortKey)
      dispatch(volunteerActions.setVolunteers(sortedData))
      dispatch(volunteerActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(volunteerActions.setLoading(false))
    })
}

export const getVolunteerBySubscriber = (sortKey, id) => (dispatch) => {
  dispatch(volunteerActions.setLoading(true))
  Api.get(`/volunteer/getBySubscriber?id=${id}`)
    .then((res) => {
      const sortedData = sortByDate(res?.results, sortKey)
      dispatch(volunteerActions.setVolunteers(sortedData))
      dispatch(volunteerActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(volunteerActions.setLoading(false))
    })
}

export const getOrganizationVolunteer = (id) => (dispatch) => {
  dispatch(volunteerActions.setLoading(true))
  Api.get(`/volunteer/orgVolunteer?id=${id}`)
    .then((res) => {
      const sortedData = sortByDate(res?.results, 'createdDate')
      dispatch(volunteerActions.setVolunteers(sortedData))
      dispatch(volunteerActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(volunteerActions.setVolunteers([]))
      dispatch(volunteerActions.setLoading(false))
    })
}

export const getVolunteerDetail = (id, form, actions) => (dispatch) => {
  dispatch(volunteerActions.setLoading(true))
  Api.get(`/volunteer?id=${id}`)
    .then((res) => {
      if (res.results) {
        const volunteer = res.results
        if (form) {
          form.setFieldsValue({
            Title: volunteer?.Title,
            Description: volunteer?.Description,
            Schedule: volunteer.Schedule && moment(volunteer.Schedule),
            Notes: volunteer?.Notes,
            Flyer: volunteer?.Flyer,
            AllowedRegistration: volunteer?.AllowedRegistration,
            ShowOnPage: volunteer.ShowOnPage ? volunteer.ShowOnPage : false,
            mailingAddress: volunteer?.Address1
              ? volunteer?.Address1
              : `${volunteer?.Address2 && `${volunteer?.Address2},`} ${
                  volunteer.City
                }, ${volunteer.LocationState}, ${volunteer.Country}`,
            suite: volunteer?.Address2,
            zipCode: volunteer?.ZipCode,
            city: volunteer?.City,
            state: volunteer?.LocationState,
            country: volunteer?.Country,
          })
          if (actions) actions()
        }
      }
      dispatch(volunteerActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(volunteerActions.setLoading(false))
    })
}

export const getVolunteer = (id) => (dispatch) => {
  dispatch(volunteerActions.setLoading(true))
  Api.get(`/volunteer?id=${id}`)
    .then((res) => {
      dispatch(volunteerActions.setVolunteer(res.results))
      dispatch(volunteerActions.setLoading(false))
    })
    .catch(() => {
      dispatch(volunteerActions.setVolunteer(null))
      dispatch(volunteerActions.setLoading(false))
    })
}

export const addVolunteer = (payload, setLoading, actions) => async () => {
  setLoading(true)
  try {
    let updatedData = payload
    if (!updatedData?.Flyer?.fileName) {
      const flyerFile = await uploadFile(updatedData.Flyer)
      updatedData = {
        ...updatedData,
        Flyer: {
          fileName: flyerFile.fileName,
          fileMimeType: flyerFile.fileMimeType,
        },
      }
    }

    // Api.put('/volunteer', updatedData)
    makeRequest(
      'put',
      '/volunteer',
      {
        organization: payload?.OrganizationId,
      },
      updatedData,
    )
      .then(() => {
        setLoading(false)
        message.success('Volunteer Added Successfully')
        if (actions) {
          actions()
        }
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
        message.error('An Error Occured')
      })
  } catch (error) {
    console.log('error', error)
    setLoading(false)
  }
}

export const updateVolunteer = (payload, setLoading, actions) => async () => {
  setLoading(true)

  let updatedData = payload

  if (!updatedData?.Flyer?.fileName) {
    const flyerFile = await uploadFile(updatedData.Flyer)
    updatedData = {
      ...updatedData,
      Flyer: {
        fileName: flyerFile.fileName,
        fileMimeType: flyerFile.fileMimeType,
      },
    }
  }

  Api.put('volunteer/updateVolunteer', updatedData)
    .then(() => {
      setLoading(false)
      message.success('Volunteer Updated Successfully')
      if (actions) {
        actions()
      }
    })
    .catch((error) => {
      console.log('error', error)
      setLoading(false)
      message.error('An Error Occured')
    })
}

export const updateVolunteerStatus = (id, status, actions) => (dispatch) => {
  dispatch(volunteerActions.setLoading(true))
  if (status === 1) {
    Api.get(`volunteer/deactive?id=${id}`)
      .then(() => {
        dispatch(volunteerActions.setLoading(false))
        if (actions) {
          actions()
        }
        message.success('Volunteer Deactivated Successfully')
      })
      .catch((error) => {
        console.log('error', error)
        message.error('Something Went Wrong')
        dispatch(volunteerActions.setLoading(false))
      })
  } else if (status === 0) {
    Api.get(`volunteer/active?id=${id}`)
      .then(() => {
        dispatch(volunteerActions.setLoading(false))
        if (actions) {
          actions()
        }
        message.success('Volunteer Activated Successfully')
      })
      .catch((error) => {
        console.log('error', error)
        message.error('Something Went Wrong')
        dispatch(volunteerActions.setLoading(false))
      })
  }
}

export const registerForVolunteer = (payload, setLoading, actions) => () => {
  setLoading(true)
  // Api.put('volunteer/registerForVolunteer', payload)
  Api.post('component/volunteer/register', payload)
    .then(() => {
      setLoading(false)
      if (actions) {
        actions()
      }
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || 'An Error Occured')
      setLoading(false)
    })
}

export const getRegisterVolunteers = (id, setLoading) => (dispatch) => {
  setLoading(true)
  Api.get(`volunteer/volunteerList/${id}`)
    .then((res) => {
      dispatch(volunteerActions.setRegisteredVolunteers(res.results))
      setLoading(false)
    })
    .catch((error) => {
      console.log('error', error)
      setLoading(false)
      message.error('An Error Occured')
    })
}

export const getFeedbackByVolunteers = (id, setLoading) => (dispatch) => {
  setLoading(true)
  Api.get(`subscriber/feedback/${id}`)
    .then((res) => {
      dispatch(volunteerActions.setFeedbackByVolunteers(res.result))
      setLoading(false)
    })
    .catch((error) => {
      console.log('error', error)
      setLoading(false)
      message.error('An Error Occured')
    })
}

export const isUserAlreadyRegisteredInVolunteer = async (id, email) => {
  const response = await Api.get(`volunteer/volunteerList/${id}`)
  const isExist = response.results.find((item) => item.email === email)
  return isExist
}

import React, { useState, useEffect } from 'react'
import {
  Card,
  Col,
  Row,
  Skeleton,
  // Avatar, List
} from 'antd'
import ConditionalRenderer from './ConditionalRender'

// import { BarChartOutlined } from '@ant-design/icons'
// import { config } from 'configs'
// import { useHistory } from 'react-router-dom'

const LandingPageList = () => {
  //   const history = useHistory()

  const [activeCard, setActiveCard] = useState(null)
  const [loading, setLoading] = useState(false)
  //   const [filteredData, setFilteredData] = useState([])
  //   const [hoveredItem, setHoveredItem] = useState(null)

  const handleCardClick = (key) => {
    setLoading(true)
    setActiveCard(key)

    console.log(key, '======key======')

    // Filter the report data based on the selected card
    // const filteredReports = reportData.filter(
    //   (report) => report.ReportType === key.toLowerCase(),
    // )
    // setFilteredData([])
    setLoading(false)
  }

  //   const onClickItem = (name, eventType, api) => {
  //     let apiUrl = api
  //     if (eventType !== null) {
  //       apiUrl += `?eventType=${eventType}`
  //     }
  //     history.push(apiUrl)
  //   }

  const CardsData = {
    todayDonation: "Today's Donations",
    monthlyEarning: 'Monthly Earnings',
    communityMember: 'Community Members',
    fundraiser: 'Fundraisers',
    pledge: 'Pledges',
  }

  //   const hoverStyle = {
  //     backgroundColor: '#f0f0f0',
  //     cursor: 'pointer',
  //   }

  const cards = Object.entries(CardsData).reduce((acc, [key, value]) => {
    const isActive = key === activeCard // Check if the card is active

    // Conditional styling for the card
    const cardStyle = isActive ? { boxShadow: '0 4px 8px 0 #14B1E0' } : {}

    // Style for the card title
    const titleStyle = {
      marginTop: '20px',
      fontSize: '20px', // Increase font size
      textAlign: 'center', // Center align text
    }

    acc.push(
      <Col xs={24} sm={12} md={6} lg={6} key={key}>
        <Card
          //   title={} // Apply the title style
          bordered={false}
          style={cardStyle}
          onClick={() => handleCardClick(key)}
        >
          <div style={titleStyle}>{value}</div>
        </Card>
      </Col>,
    )
    return acc
  }, [])

  useEffect(() => {
    // const initialReports = [reportData].filter(
    //   (report) => report.ReportType === 'todayDonation',
    // )
    // setFilteredData([])
    handleCardClick('todayDonation')
  }, [])

  return (
    <>
      {/* <Card className="header-solid" bordered={false}> */}
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <Row gutter={[16, 16]}>{cards}</Row>
      </Skeleton>

      <ConditionalRenderer condition={activeCard} />
    </>
  )
}

export { LandingPageList }

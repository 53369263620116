import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Skeleton, Tag, Typography } from 'antd'
import { AreaChartOutlined } from '@ant-design/icons'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { formatAmount, searchRentals } from 'utils'
import { useHistory } from 'react-router-dom'
import { awsconfig } from 'configs'
import Api from 'api'
import sample from 'assets/images/sample/sample1.png'
import { IoLocationOutline } from 'react-icons/io5'

const { Title, Text } = Typography

const RentalList = ({ searchText }) => {
  const history = useHistory()
  const { organization } = useAuthSelector()
  const [renLoading, setLoading] = useState(true)
  const [orgRoom, setOrgRoom] = useState([])

  const orgRooms = () => {
    setLoading(true)
    Api.get(`/room/organizationRooms?id=${organization?.id}`)
      .then((res) => {
        if (res.data) {
          setOrgRoom(res?.data?.filter((x) => x.Available === true))
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (organization?.id) {
      orgRooms()
    }
    // eslint-disable-next-line
  }, [organization?.id])

  const onClickCard = (roomId) => {
    history.push(`/rentals/${roomId}`)
  }

  const updatedRooms = searchRentals(orgRoom, searchText)

  return (
    <Skeleton loading={renLoading} active paragraph={{ rows: 18 }}>
      <Row gutter={[24, 24]}>
        {Array.isArray(updatedRooms) && updatedRooms?.length === 0 && (
          <div className="t-h-96 t-flex t-justify-center t-items-center t-w-full">
            <div className="t-flex t-flex-col t-items-center t-space-y-2">
              <AreaChartOutlined
                style={{ fontSize: '2rem' }}
                className="t-text-secondary-100"
              />
              <p>No records found</p>
            </div>
          </div>
        )}
        {Array.isArray(updatedRooms) &&
          updatedRooms?.map((item, index) => (
            <Col span={24} lg={8} className="mb-24" key={index}>
              <Card
                bodyStyle={{ paddingTop: '14px' }}
                bordered={false}
                onClick={() => onClickCard(item?.Id)}
                className="card-project-2 header-solid t-cursor-pointer t-border-none t-shadow-none"
                cover={
                  <img
                    alt="example"
                    className="t-w-full t-h-48 t-object-cover t-rounded-md"
                    src={
                      item?.Image?.length
                        ? awsconfig.bucketUrl + item?.Image[0]?.fileName
                        : item?.Image?.fileName
                        ? awsconfig.bucketUrl + item?.Image?.fileName
                        : item?.OrganizationBackgroundImage
                        ? awsconfig.bucketUrl +
                          item?.OrganizationBackgroundImage?.fileName
                        : sample
                    }
                  />
                }
              >
                <Tag className="t-absolute t-top-[45%] t-right-0 t-bg-white t-text-cyan-600 t-border-none t-py-1 t-text-center t-text-sm t-font-medium">{`capacity: ${item?.Capacity}`}</Tag>
                <Row gutter={[24, 24]} key={0}>
                  {/* <Col>
                      <Avatar
                        src={<HomeOutlined />}
                        style={{
                          width: '25px',
                          height: '25px',
                          lineHeight: '74px',
                          borderRadius: '8px',
                          padding: '16px',
                          backgroundColor: config.colors.primary,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    </Col> */}
                  <Col>
                    <h6 className="font-semibold t-w-64 t-truncate t-text-xl">
                      {`${item?.Name}`}
                    </h6>
                    <div className="t-flex t-space-x-2">
                      <div className="t-flex t-flex-row card-tag t-font-medium t-text-black t-text-base t-items-center t-text-center">
                        <IoLocationOutline />
                        {item?.OrganizationName}
                      </div>
                    </div>
                  </Col>
                </Row>
                <p className="t-font-light t-text-base t-text-gray-500">
                  {item?.Instructions}
                </p>

                <div className="t-flex t-flex-wrap t-items-center t-mt-2 t-space-y-2">
                  <Title
                    level={5}
                    className="t-font-semibold t-text-xl t-text-[#2e97de] t-mb-0"
                  >{`${formatAmount(item?.PerHourRate)}/Hr`}</Title>
                  <Text className="t-ml-[10px] t-text-base t-text-gray-500">
                    Incl. all taxes and fees
                  </Text>
                </div>
              </Card>
            </Col>
          ))}
      </Row>
    </Skeleton>
  )
}

export { RentalList }

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Select, Typography } from 'antd'
// import { TiDelete } from 'react-icons/ti'
import moment from 'moment'
import { ClockCircleOutlined } from '@ant-design/icons'
import { FiPlus } from 'react-icons/fi'
const { Title } = Typography

const DurationSelector = ({
  onDurationChange,
  durations,
  indexValue,
  isEditable,
  addDuration,
  isAlwaysOpen,
  alreadyReservedDurations,
}) => {
  const times = [
    '12:00 AM',
    '12:30 AM',
    '1:00 AM',
    '1:30 AM',
    '2:00 AM',
    '2:30 AM',
    '3:00 AM',
    '3:30 AM',
    '4:00 AM',
    '4:30 AM',
    '5:00 AM',
    '5:30 AM',
    '6:00 AM',
    '6:30 AM',
    '7:00 AM',
    '7:30 AM',
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
    '11:59 PM',
  ]

  const [filteredTimes, setFilteredTimes] = useState(times)

  useEffect(() => {
    if (indexValue !== null && indexValue > 0 && durations) {
      let excludedTimes = []
      durations.forEach((duration) => {
        const fromIndex = times.indexOf(duration.from)
        const toIndex = times.indexOf(duration.to)

        if (fromIndex !== -1 && toIndex !== -1) {
          excludedTimes.push(...times.slice(0, toIndex))
        }
      })

      excludedTimes = [...new Set(excludedTimes)]

      const newFilteredTimes = times.filter(
        (time) => !excludedTimes.includes(time),
      )

      // Update the filteredTimes state only if it has changed
      if (JSON.stringify(newFilteredTimes) !== JSON.stringify(filteredTimes)) {
        setFilteredTimes(newFilteredTimes)
      }
    }
  }, [indexValue, durations, times, filteredTimes])

  const generateTimeSlots = (start, end, interval) => {
    const startTime = moment(start, 'h:mm A')
    const endTime = moment(end, 'h:mm A')

    // Generate half-hour intervals
    let slots = []
    let currentTime = startTime.clone() // Use clone to avoid mutating the original moment object

    while (currentTime.isSameOrBefore(endTime)) {
      slots.push(currentTime.format('h:mm A'))
      currentTime.add(interval, 'minutes')
    }

    return slots
  }

  useEffect(() => {
    let finalSlots = []
    durations.map((duration) => {
      const rangeStart = moment(duration?.from, 'h:mm A')
      const rangeEnd = moment(duration?.to, 'h:mm A')

      const slots = generateTimeSlots(rangeStart, rangeEnd, 30)
      finalSlots = [...finalSlots, ...slots]
    })
    if (!isAlwaysOpen) {
      setFilteredTimes(finalSlots)
    }
  }, [durations])

  const initialSelectedTime =
    indexValue === 0
      ? isAlwaysOpen
        ? times[0]
        : durations[0]?.from
      : durations[indexValue - 1]?.to
  const [selectedTimeFrom, setSelectedTimeFrom] = useState(initialSelectedTime)
  const handleChangeFrom = (value) => {
    setSelectedTimeFrom(value)
    const nextTimeSlot = getNextTimeSlot(value)
    setSelectedTimeTo(nextTimeSlot)
    onTimeChange('from', value)
  }

  const getNextTimeSlot = (time) => {
    const index = times.indexOf(time)
    if (index !== -1 && index < times.length - 1) {
      return times[index + 1]
    }
    return time
  }

  const nextTimeSlot = getNextTimeSlot(selectedTimeFrom)
  const [selectedTimeTo, setSelectedTimeTo] = useState(
    nextTimeSlot || durations[0]?.to,
  )

  const handleChangeTo = (value) => {
    setSelectedTimeTo(value)
    onTimeChange('to', value)
  }
  const onTimeChange = (type, time) => {
    const timeMoment = moment(time, 'h:mm A')
    let updatedToTime

    if (type === 'from') {
      updatedToTime = timeMoment
        .add(time === '11:30 PM' ? 29 : 30, 'minutes')
        .format('h:mm A')
      setSelectedTimeTo(updatedToTime)
    } else {
      updatedToTime = selectedTimeTo
    }

    const duration = {
      from: type === 'from' ? time : selectedTimeFrom,
      to: type === 'to' ? time : updatedToTime,
    }
    onDurationChange(duration)
  }
  useEffect(() => {
    if (durations && indexValue > 0) {
      const previousEndTime = durations[indexValue - 1]?.to
      const startTimeMoment = moment(previousEndTime, 'h:mm A')
      const endTimeMoment = startTimeMoment.clone().add(30, 'minutes')
      const endTime = endTimeMoment.format('h:mm A')

      const duration = {
        from: previousEndTime,
        to:
          endTime === previousEndTime
            ? endTimeMoment.add(30, 'minutes').format('h:mm A')
            : endTime,
      }
      onDurationChange(duration)
    } else if (indexValue === 0) {
      const duration = {
        from: '12:00 AM',
        to: '12:30 AM',
      }
      onDurationChange(duration)
    }
  }, [indexValue])

  const filteredElevenFiftyNine = filteredTimes.filter(
    (time) => !time.includes('11:59 PM'),
  )
  if (!isAlwaysOpen) filteredElevenFiftyNine.splice(-1)

  return (
    <div className="t-flex t-space-x-2 t-items-center t-relative t-my-2">
      <div className="t-flex t-flex-row t-space-x-12">
        <div className="t-flex t-flex-col">
          <Title level={5}>From</Title>
          <Select
            value={selectedTimeFrom}
            onChange={(value) => handleChangeFrom(value)}
            className="t-w-36 t-mt-2"
            suffixIcon={<ClockCircleOutlined />}
            // disabled={!isEditable}
          >
            {filteredElevenFiftyNine.map((time) => (
              <Select.Option
                key={time}
                value={time}
                className="t-p-1"
                disabled={alreadyReservedDurations.find((a) => a.from === time)}
              >
                {time}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="t-flex t-flex-col">
          <Title level={5}>To</Title>
          <Select
            value={selectedTimeTo}
            onChange={handleChangeTo}
            className="t-w-36 t-mt-2"
            suffixIcon={<ClockCircleOutlined />}
            // disabled={!isEditable}
          >
            {filteredTimes
              .filter((time) => {
                const timeMoment = moment(time, 'h:mm A')
                const selectedTimeFromMoment = moment(
                  selectedTimeFrom,
                  'h:mm A',
                )
                return timeMoment.isAfter(selectedTimeFromMoment)
              })
              .map((time) => (
                <Select.Option
                  key={time}
                  value={time}
                  className="t-p-1"
                  disabled={alreadyReservedDurations.find((a) => a.to === time)}
                >
                  {time}
                </Select.Option>
              ))}
          </Select>
        </div>
        {/* <TimePicker
          className="t-w-40"
          format="hh:mm a"
          minuteStep={30}
          size="small"
          value={formatter(value.to)}
          onChange={onTimeChange}
          allowClear={false}
          order={false}
          disabledTime={disabledRangeTime}
          onOpenChange={onOpenChange}
        /> */}
      </div>
    </div>
  )
}

export { DurationSelector }

import { Button, Upload, message } from 'antd'
import ImgCrop from 'antd-img-crop'
import { awsconfig, config } from 'configs'
import React, { useEffect, useState } from 'react'
import { MdOutlineFileUpload, MdDelete } from 'react-icons/md'
import { imageSizeValidation } from 'utils'

const CustomizeImageUploader = ({
  defaultFile,
  onFileChange,
  ratio = 1 / 1,
}) => {
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    if (defaultFile && defaultFile.fileName) {
      setFileList([
        {
          uid: '1',
          name: defaultFile?.fileName || 'default.jpg',
          status: 'done',
          url: `${awsconfig.bucketUrl}${defaultFile?.fileName}`,
          type: defaultFile?.fileMimeType || 'image/jpeg',
        },
      ])
    }
  }, [defaultFile])

  const onChange = ({ fileList: newFileList }) => {
    const file = newFileList.length > 0 ? newFileList[0] : null

    // Adding Size Limitation
    if (file && file?.size) {
      const isValidFileSize = imageSizeValidation(file)
      if (!isValidFileSize.isValid) {
        message.destroy()
        message.error(
          config.imageMaxSizeLimitErrorMessage ||
            'Image Size limit is not Valid',
        )
        return false
      }
    }

    setFileList(newFileList)
    onFileChange(file)
    return true
  }

  const handleFileDelete = () => {
    setFileList([])
    onFileChange(null)
  }

  return (
    <>
      <ImgCrop rotate quality={1} aspect={ratio}>
        <Upload
          listType="text"
          fileList={fileList}
          onChange={onChange}
          maxCount={1}
          accept="image/*"
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          showUploadList={false} // Hide the default upload list
          className="uploader-class"
        >
          {fileList.length === 0 ? (
            <Button className="t-flex t-justify-center t-items-center t-shadow-none t-w-[100%] roboto-font">
              <MdOutlineFileUpload className="t-text-2xl t-mr-2" />
              Upload
            </Button>
          ) : (
            <>
              <Button className="t-flex t-justify-center t-items-center t-shadow-none t-w-[100%] roboto-font">
                <MdDelete className="t-text-2xl" onClick={handleFileDelete} />
                {fileList[0].name}
              </Button>
            </>
          )}
        </Upload>
      </ImgCrop>
    </>
  )
}

export { CustomizeImageUploader }

import React, { useEffect, useState } from 'react'
import { useRoles } from 'hooks'
import {
  MyReservations,
  MyRooms,
} from 'screens/main/settings/manage-organization/components'
import { RentalList } from '../RentalList'

const RentalTab = ({ searchText }) => {
  const [sec, setSec] = useState()
  const { isSubscriber, isDonor } = useRoles()

  const handleSection = (val) => {
    setSec(val)
  }

  useEffect(() => {
    if (isSubscriber) {
      setSec(2)
    } else if (isDonor) {
      setSec(1)
    } else {
      setSec(1)
    }
  }, [isSubscriber, isDonor])

  return (
    <>
      <div className="t-flex t-flex-row t-h-16 md:t-justify-start t-justify-center t-border-b-2 t-border-b-gray-100 t-mb-6 t-mt-[-30px]">
        {!isSubscriber && (
          <div
            onClick={() => handleSection(1)}
            className={`${
              sec === 1 ? 't-border-b-[#2e97de] t-border-b-2' : ''
            } t-pt-6 t-cursor-pointer t-w-[20%] t-bg-white`}
          >
            <h1
              className={`${
                sec === 1 ? 't-text-[#2e97de]' : 't-text-black'
              } t-text-base t-font-medium t-text-center roboto-font`}
            >
              Rental Listing
            </h1>
          </div>
        )}
        {isSubscriber && (
          <div
            onClick={() => handleSection(2)}
            className={`${
              sec === 2 ? 't-border-b-[#2e97de] t-border-b-2' : ''
            } t-pt-6 t-cursor-pointer t-w-[20%] t-bg-white`}
          >
            <h1
              className={`${
                sec === 2 ? 't-text-[#2e97de]' : 't-text-black'
              } t-text-base t-font-medium t-text-center roboto-font`}
            >
              Rooms
            </h1>
          </div>
        )}
        <div
          onClick={() => handleSection(3)}
          className={`${
            sec === 3 ? 't-border-b-[#2e97de] t-border-b-2' : ''
          } t-pt-6 t-cursor-pointer t-w-[20%] t-border-b t-bg-white`}
        >
          <h1
            className={`${
              sec === 3 ? 't-text-[#2e97de]' : 't-text-black'
            } t-text-base t-font-medium t-text-center roboto-font`}
          >
            Reservations
          </h1>
        </div>
      </div>
      {sec === 1 && (
        <div style={{ minHeight: 'calc(100vh - 205px)' }}>
          <RentalList searchText={searchText} />
        </div>
      )}
      {sec === 2 && <MyRooms searchText={searchText} />}
      {sec === 3 && <MyReservations searchText={searchText} />}
    </>
  )
}

export { RentalTab }

import React from 'react'
import { Modal, Col, Row, Typography, Tag, Divider, message } from 'antd'
import { formatAmount, formateDate } from 'utils'
import { FaClipboard } from 'react-icons/fa'

const { Title } = Typography

const ViewReservation = ({ visible, setVisible, reservation }) => {
  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value)
    message.success('Copied to Clipboard')
  }

  return (
    <Modal
      centered
      title="Reservation Detail"
      open={visible}
      onCancel={setVisible}
      destroyOnClose
      width={400}
      footer={null}
    >
      <Row gutter={[24, 0]}>
        <Col xl={24} md={24} xs={24} sm={24}>
          <Title level={5}>Donor Details</Title>
          <div className="t-flex t-flex-col">
            <h1>{`Donor name : ${reservation?.CreatorName}`}</h1>
            <h1 className="t-flex t-items-center t-space-x-2">
              {`Donor email : ${reservation?.CreatorEmail}`}{' '}
              <span className="t-ml-2">
                <FaClipboard
                  fontSize="1rem"
                  onClick={() => copyToClipboard(reservation?.CreatorEmail)}
                  className="t-text-secondary-100 t-cursor-pointer"
                />
              </span>
            </h1>
            <h1 className="t-flex t-items-center t-space-x-2">
              {`Donor phone number : ${reservation?.CreatorPhoneNumber}`}
              <span className="t-ml-2">
                <FaClipboard
                  fontSize="1rem"
                  onClick={() =>
                    copyToClipboard(reservation?.CreatorPhoneNumber)
                  }
                  className="t-text-secondary-100 t-cursor-pointer"
                />
              </span>
            </h1>
          </div>
        </Col>
        <Divider />
        <Col xl={24} md={24} xs={24} sm={24}>
          <Title level={5}>Reservation Timings</Title>
          <div className="t-flex t-flex-col">
            <h1>{`Room name : ${reservation?.RoomName}`}</h1>
            <div className="t-my-1">
              Durations :{' '}
              <span>
                {reservation?.Durations.map((item) => (
                  <Tag color="green">{`${item?.from} - ${item?.to}`}</Tag>
                ))}
              </span>
            </div>
            <h1>{`Is Recurring : ${
              reservation?.IsRecurring ? 'Yes' : 'No'
            }`}</h1>
            <div>Dates :</div>
            {reservation?.IsRecurring ? (
              <div>
                {reservation?.RecurringDates.map((item) => (
                  <Tag color="purple" className=" t-my-1">{`${formateDate(
                    item,
                  )}`}</Tag>
                ))}
              </div>
            ) : (
              <div>
                <Tag color="purple">{`${formateDate(reservation?.Date)}`}</Tag>
              </div>
            )}
          </div>
        </Col>
        <Divider />
        <Col xl={24} md={24} xs={24} sm={24}>
          <Title level={5}>Cost Details</Title>
          <div className="t-flex t-flex-col">
            <h1>{`Total hours : ${reservation?.TotalHours}`}</h1>
            <h1>{`Total cost : ${formatAmount(
              reservation?.UpdatedCost || reservation?.TotalCost,
            )}`}</h1>
          </div>
        </Col>
        <Divider />
        <Col xl={24} md={24} xs={24} sm={24}>
          <Title level={5}>Reservation Status</Title>
          <div className="t-flex t-flex-col">
            <div>
              <Tag color="blue">{reservation?.AcceptanceStatus}</Tag>
            </div>
          </div>
        </Col>
      </Row>
    </Modal>
  )
}

export { ViewReservation }

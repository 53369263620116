import React, { useState, useEffect } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { MdEdit, MdLocationOn } from 'react-icons/md'
import { HiDuplicate } from 'react-icons/hi'
import {
  compareDateTimeWithNow,
  formateDateTime,
  isMobile,
  mobileShareNavigator,
} from 'utils'
import { useAuthSelector } from 'store/authSlice/authReducer'
// import {
//   addFavourite,
//   removeFavourite,
// } from 'store/favouriteSlice/favouriteActions'
import { useDispatch } from 'react-redux'
import { awsconfig } from 'configs'
import {
  // AiOutlineHeart,
  AiFillEye,
  AiFillEyeInvisible,
  // AiFillHeart,
} from 'react-icons/ai'
import {
  Row,
  Col,
  Card,
  Descriptions,
  Button,
  Typography,
  Tooltip,
  Skeleton,
} from 'antd'
import { ShareModal, SVGIcon } from 'components'
import sample from 'assets/images/sample/sample1.png'
import { useFavouriteSelector } from 'store/favouriteSlice/favouriteReducer'
import { BsFillCalendarCheckFill, BsFillCalendarXFill } from 'react-icons/bs'
import { useAuctionSelector } from 'store/auctionSlice/auctionReducer'
import {
  getAuctionDetails,
  updateAuctionStatus,
} from 'store/auctionSlice/auctionActions'

const { Title, Paragraph } = Typography

const AuctionView = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const { state } = useLocation()

  const [isShare, setShare] = useState(false)

  const { loading, auction } = useAuctionSelector()
  const { user, organization } = useAuthSelector()
  const { loading: favLoading } = useFavouriteSelector()

  // const positionData = {
  //   lat: location?.lat,
  //   lon: location?.lng,
  //   zipCode: location?.zipcode,
  // }

  const getAuctionById = () => {
    dispatch(getAuctionDetails(params?.id))
  }

  useEffect(() => {
    if (user?.email) {
      getAuctionById()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email, params?.id])

  const handleDuplicate = () => {
    history.push(`/settings/create-auction?id=${params?.id}&duplicate=true`)
  }

  const nagigate = () => {
    history.push('/auction')
  }

  const updateAuction = () => {
    dispatch(updateAuctionStatus(params?.id, auction?.ActiveInd, nagigate))
  }

  const startEventControlling = () => {
    history.push(`/auction/${params?.id}/control-screen`)
  }

  const joinEvent = () => {
    history.push(`/auction/${params?.id}/join-auction`)
  }

  // const handleAddFavourite = async (id) => {
  //   const payload = {
  //     ReferenceId: id,
  //     Username: user?.id,
  //     Type: 'auction',
  //   }
  //   dispatch(addFavourite(payload, getAuctionById))
  // }

  // const handleRemoveFavourite = async (id) => {
  //   dispatch(removeFavourite(id, getAuctionById))
  // }

  const shareCampaign = async () => {
    if (isMobile) {
      await mobileShareNavigator(
        auction?.Title,
        `${window.location.origin}/events/${params?.id}/join-auction`,
      )
    } else {
      setShare(true)
    }
  }

  const isCreator = auction?.OrganizationId === organization?.id
  const isStartDateApproched =
    auction?.StartDate && compareDateTimeWithNow(auction.StartDate, 'after')

  const auctionLoading = loading || favLoading

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            title={[
              <div className="t-flex t-justify-between">
                <div className="t-flex t-items-center t-space-x-4">
                  <Button
                    type="primary"
                    className="t-rounded-full t-flex t-items-center"
                    onClick={() =>
                      state && state.urlData
                        ? history.push({
                            pathname: state.urlData.pathname,
                            state: { key: state.urlData.activeKey },
                          })
                        : history.goBack()
                    }
                    size="small"
                  >
                    <IoIosArrowBack />
                    Back
                  </Button>
                </div>
                <div className="t-flex t-items-center t-space-x-2 t-cursor-pointer">
                  {/* <div
                    className={`${
                      auctionLoading && 't-pointer-events-none t-opacity-50'
                    } ${!auction && 't-pointer-events-none t-opacity-0'}`}
                  >
                    {auction?.isFav ? (
                      <Tooltip title="Remove Favorite">
                        <AiFillHeart
                          fontSize="1.8rem"
                          className="t-cursor-pointer t-text-red-500"
                          onClick={() => handleRemoveFavourite(auction?.favId)}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Make Favorite">
                        <AiOutlineHeart
                          fontSize="1.8rem"
                          className="t-cursor-pointer t-text-secondary-100"
                          onClick={() => handleAddFavourite(params?.id)}
                        />
                      </Tooltip>
                    )}
                  </div> */}
                  {isCreator && (
                    <>
                      <MdEdit
                        fontSize="1.5rem"
                        className="t-text-secondary-100"
                        onClick={() =>
                          history.push(
                            `/settings/create-auction/${auction?.Id}`,
                          )
                        }
                      />
                      <HiDuplicate
                        fontSize="1.5rem"
                        className="t-text-secondary-100"
                        onClick={handleDuplicate}
                      />
                      <>
                        {auction?.ActiveInd === 1 ? (
                          <AiFillEyeInvisible
                            fontSize="1.5rem"
                            className="t-text-secondary-100"
                            onClick={updateAuction}
                          />
                        ) : (
                          <AiFillEye
                            fontSize="1.5rem"
                            className="t-text-secondary-100"
                            onClick={updateAuction}
                          />
                        )}
                      </>
                    </>
                  )}
                </div>
              </div>,
            ]}
            bordered={false}
            className="header-solid card-product mb-24"
          >
            <Skeleton loading={auctionLoading} active paragraph={{ rows: 18 }}>
              {auction ? (
                <>
                  <Row
                    gutter={[24, 0]}
                    style={{ minHeight: 'calc(100vh - 220px)' }}
                  >
                    <Col
                      span={24}
                      lg={12}
                      xl={10}
                      className="col-gallery mb-24"
                    >
                      <img
                        src={
                          auction?.Flyer?.fileName
                            ? awsconfig.bucketUrl + auction?.Flyer?.fileName
                            : auction?.organization?.BackgroundImage
                            ? awsconfig.bucketUrl +
                              auction?.organization?.BackgroundImage?.fileName
                            : sample
                        }
                        className="t-h-96 t-w-full gallery-img shadow-3 img-contain"
                        alt="card-2"
                      />
                    </Col>
                    <Col span={24} xl={12} lg={{ offset: 1 }} className="t-p-4">
                      <div className="t-mb-4">
                        <Title level={1} className="t-text-secondary-100">
                          {auction?.Title}
                        </Title>
                        <Title level={4} className="t-text-secondary-100">
                          {auction?.OrganizationName}
                        </Title>
                        <Descriptions colon={false} className="t-my-4">
                          <Descriptions.Item
                            label={
                              <Tooltip title="Start Date">
                                <BsFillCalendarCheckFill className="t-text-secondary-100 t-ml-1" />
                              </Tooltip>
                            }
                            span={3}
                          >
                            <Tooltip title="MM-DD-YYYY hh:mm A">
                              {formateDateTime(auction?.StartDate)}
                            </Tooltip>
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <Tooltip title="End Date">
                                <BsFillCalendarXFill className="t-text-secondary-100 t-ml-1" />
                              </Tooltip>
                            }
                            span={3}
                          >
                            <Tooltip title="MM-DD-YYYY hh:mm A">
                              {formateDateTime(auction?.EndDate)}
                            </Tooltip>
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <Tooltip title="Zip code">
                                <MdLocationOn
                                  fontSize="1.2rem"
                                  className="t-text-secondary-100"
                                />
                              </Tooltip>
                            }
                            span={3}
                          >
                            {auction?.ZipCode}
                          </Descriptions.Item>
                        </Descriptions>
                        <Title level={4} className="t-text-secondary-100">
                          Description
                        </Title>
                        <Paragraph className="t-text-justify">
                          {auction?.Details}
                        </Paragraph>
                        <div className="t-flex t-space-x-2 t-flex-wrap">
                          {isCreator && (
                            <Button
                              type="primary"
                              className="t-my-1"
                              onClick={() => startEventControlling()}
                            >
                              Control
                            </Button>
                          )}
                          {isStartDateApproched && (
                            <Button
                              type="primary"
                              className="t-my-1"
                              onClick={joinEvent}
                            >
                              Join
                            </Button>
                          )}

                          <Button
                            type="primary"
                            className="t-my-1"
                            onClick={shareCampaign}
                          >
                            Share
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <div className="ant-result ant-result-404">
                  <SVGIcon />
                  <div className="ant-result-title">Invalid URL</div>
                  <div className="ant-result-subtitle">
                    Sorry, the page you visited does not exist.
                  </div>
                  <div className="ant-result-extra">
                    <Button
                      onClick={() => history.push('/campaign')}
                      type="primary"
                      size="small"
                    >
                      Goto Campaigns
                    </Button>
                  </div>
                </div>
              )}
            </Skeleton>
          </Card>
        </Col>
        <ShareModal
          url={`${window.location.origin}/events/${auction?.Id}/join-auction`}
          visible={isShare}
          onClose={() => setShare(false)}
        />
      </Row>
    </>
  )
}

export { AuctionView }

import * as Yup from 'yup'

const schema = Yup.object().shape({
  ProfileTitle: Yup.string().required('Profile Title is required'),
  OrganizationName: Yup.string().required('Organization Name is required'),
  OrganizationEmail: Yup.string()
    .required('Organization Email is required')
    .email('Organization Email is invalid'),
  OrganizationWebsite: Yup.string()
    .required('Organization Website is required')
    .url('Organization Website URL is invalid'),
  Description: Yup.string()
    .required('Description is required')
    .min(10, 'Description should be atleast 10 character'),
  Logo: Yup.mixed()
    .typeError('Logo is not a file')
    .required('Logo is required'),
})

const organizationProfileValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { organizationProfileValidation }

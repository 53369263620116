import React from 'react'
import { Button, Modal } from 'antd'

const DownloadInstructionModal = ({ isVisible, onClose }) => {
  const data = [
    {
      name: 'Donor Name:',
      instruction: 'Include First and Last Name. Example: John Smith',
    },
    {
      name: 'Donor Email:',
      instruction:
        'Enter a valid email address. Example: JohnSmith@Extensia.cloud.',
    },
    {
      name: 'Donor Phone Number:',
      instruction:
        'Enter mobile phone number with country code. Phone format: 14045551212 (no spaces or other characters).',
    },
    {
      name: 'Donor ZipCode:',
      instruction: 'Enter zip or postal code. Example: 30009.',
    },
  ]
  return (
    <Modal
      centered
      title="Upload Instructions"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <div>
        <div className="t-text-xs t-mb-4">
          <b>Note:</b> Follow below instruction for each field while uploading
          sample file just downloaded. Format your community records using the
          guidance below. Note that Name is mandatory. While one of Email or
          Phone Number is required.
        </div>
        {data.map((item) => (
          <div>
            <div className="t-font-bold t-text-secondary-100 t-text-sm t-ml-1">
              {item.name}
            </div>
            <div className="t-w-full t-rounded-2xl t-min-h-12 t-p-3 t-bg-yellow-100 t-text-secondary-100 t-mb-2 t-flex t-flex-col t-shadow-md t-justify-between">
              <div>{item.instruction}</div>
            </div>
          </div>
        ))}
        <div className="t-flex t-justify-end t-items-center t-mt-2">
          <Button
            type="primary"
            className="px-25"
            htmlType="button"
            onClick={() => onClose()}
          >
            close
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export { DownloadInstructionModal }

import React, { useState } from 'react'
import { Button, Card, Form, Row, Col, Typography, message, Select } from 'antd'
import { REGEXS, formatAmount } from 'utils'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { addCashDonation } from 'store/paymentSlice/paymentActions'
import * as Yup from 'yup'
import { AmountInput } from 'components/elements'

const { Title } = Typography

const amountValidation = REGEXS.amountRegex
const schema = Yup.object().shape({
  askedAmount: Yup.string()
    .required('Required')
    .matches(amountValidation, 'Invalid amount'),
  type: Yup.string().required('Type is required'),
})

const addCollectedAmountValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {}

const AddCollectedAmount = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const { event } = useEventSelector()
  const { user, organization } = useAuthSelector()

  Form.useWatch('askedAmount', form)

  const onFinish = async (values) => {
    setLoading(true)
    try {
      const data = {}

      const askedAmountValue = Number(values.askedAmount.replaceAll(/,/g, ''))
      data.amount = askedAmountValue
      data.contributeToEventCost = false
      data.DonorEmail = `dummy_${organization?.Email}`
      data.CreatedBy = `dummy_${organization?.Email}`
      data.DonorPhoneNumber = user?.phone
      data.DonorName = `Manual Entry (${organization?.Name})`

      data.EventId = event.Id
      data.EventName = event.Title
      data.transactionType =
        event.EventType === 'Fundraiser' ? 'event' : 'campaign'

      data.DonorZipCode = organization?.ZipCode
      data.isAttendingOnline = false
      data.Table = null
      data.isDonatingAnonymously = false
      data.isManualEntry = true
      data.PaymentType = values.type

      await addCashDonation(data)

      form.resetFields()
      message.success('Cash Added Successfully')
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(error?.response?.data?.error || 'Something Went Wrong')
    }
  }

  const askedAmountValue = form.getFieldValue('askedAmount')

  const askedAmountWithoutComma = askedAmountValue?.replaceAll(/,/g, '')

  return (
    <Card className="t-w-full">
      <Form
        form={form}
        layout="vertical"
        key={0}
        onFinish={onFinish}
        name="organization"
        initialValues={initialValues}
        scrollToFirstError
      >
        <Row gutter={[24, 0]}>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="askedAmount"
              label="Amount"
              colon={false}
              rules={[addCollectedAmountValidation]}
            >
              <AmountInput
                placeholder="Add Collected Amount"
                onChange={(value) =>
                  form.setFieldsValue({
                    askedAmount: value,
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="type"
              label="Type"
              colon={false}
              rules={[addCollectedAmountValidation]}
            >
              <Select placeholder="Type" style={{ width: '100%' }}>
                {['Cash', 'Cheque', 'Zelle'].map((item, index) => (
                  <Select.Option value={item} key={index}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Row justify="end">
              <Title level={3}>
                {`Total Donation = ${formatAmount(askedAmountWithoutComma)}`}
              </Title>
            </Row>
          </Col>
          <Col span={24} className="text-left">
            <Button
              loading={loading}
              type="primary"
              className="px-25"
              htmlType="submit"
            >
              {loading ? 'Adding ...' : 'Add'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export { AddCollectedAmount }

import React, { useEffect, useState } from 'react'
import {
  Input,
  Button,
  Card,
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  Checkbox,
  Typography,
  Modal,
  Divider,
  message,
  Switch,
} from 'antd'
import { programValidation } from 'validations'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  addProgram,
  updateProgram,
  getProgramDetail,
} from 'store/programSlice/programActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useProgramSelector } from 'store/programSlice/programReducer'
import {
  compareDateTimeWithNow,
  disabledTime,
  MaskPattern,
  numberWithCommas,
} from 'utils'
import { AmountInput, ImageUploader } from 'components/elements'
import Api from 'api'
import moment from 'moment'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import MaskedInput from 'antd-mask-input'
import { AutoCompleteAddress } from 'components/common'
import { config } from 'configs'
import { getProfileList } from 'store/organizationProfilesSlice/OrganizationProfilesActions'
import { useOrganizationProfileSelector } from 'store/organizationProfilesSlice/OrganizationProfilesReducer'
import { DayDurations } from './DayDurations'
import { UpdateTeacher } from './UpdateTeacher'

const { Title, Paragraph } = Typography

const initialValues = {
  Title: '',
  Description: '',
  Requirements: '',
  ProgramFees: '',
  // DeliveryLocation: "",
  MaximumNumberOfRegistrations: '',
  ProgramType: 'Custom',
  Flyer: null,
  ShowOnLandingPage: false,

  AllowToEditFee: false,
  AllowExtraPayment: false,
  IncludeProcessingFee: false,
  AllowSplitPayments: false,
  PaymentCategoryAdded: false,
  PaymentCategories: [],

  MaleAllowed: true,
  FemaleAllowed: true,
  AddAgeRange: false,

  AddTeacher: false,
  TeacherFirstName: '',
  TeacherLastName: '',
  TeacherPhoneCode: '',
  TeacherMobileNumber: '',
  TeacherEmail: '',
}

const programSubTypeOptions = [
  {
    id: 1,
    label: 'Use Template - Custom',
    value: 'Custom',
  },
  {
    id: 2,
    label: 'Use Template - Class',
    value: 'Class',
  },
]

const roomScheduleInitialState = {
  monday: { dayName: 'monday', durations: [], open: false, nonstop: false },
  tuesday: { dayName: 'tuesday', durations: [], open: false, nonstop: false },
  wednesday: {
    dayName: 'wednesday',
    durations: [],
    open: false,
    nonstop: false,
  },
  thursday: { dayName: 'thursday', durations: [], open: false, nonstop: false },
  friday: { dayName: 'friday', durations: [], open: false, nonstop: false },
  saturday: {
    dayName: 'saturday',
    durations: [],
    open: false,
    nonstop: false,
  },
  sunday: { dayName: 'sunday', durations: [], open: false, nonstop: false },
}

const isValidSchedule = (schedule) => {
  const filteredSchedule = []
  Object.keys(schedule).forEach((key) => {
    const value = schedule[key]
    if (value.open) {
      filteredSchedule.push({
        day: key,
        ...value,
      })
    }
  })
  const filteredValues = filteredSchedule.filter(
    ({ open, nonstop, durations }) =>
      !(open && (nonstop || durations.length > 0)),
  )
  return filteredValues.length > 0 || filteredSchedule.length === 0
}

const CreateProgram = () => {
  const { user, organization } = useAuthSelector()
  const { loading } = useProgramSelector()
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const params = useParams()
  const isEdit = params.id
  const [programType, setProgramType] = useState()
  const [schedule, setSchedule] = useState({ ...roomScheduleInitialState })
  const [presenterValidate, setPresenterValidate] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [presenterFound, setPresenterFound] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const { profileList } = useOrganizationProfileSelector()
  const formattedProfileList = profileList?.map((item) => ({
    value: item.ProfileId,
    label: item.ProfileTitle,
  }))

  Form.useWatch('ProgramType', form)
  Form.useWatch('MaleAllowed', form)
  Form.useWatch('FemaleAllowed', form)
  Form.useWatch('ProgramFees', form)
  Form.useWatch('DeliveryLocation', form)
  Form.useWatch('Flyer', form)
  Form.useWatch('ShowOnLandingPage', form)
  Form.useWatch('AddAgeRange', form)
  const isAddAgeRange = form.getFieldValue('AddAgeRange') === true
  Form.useWatch('AddTeacher', form)
  const isAddTeacher = form.getFieldValue('AddTeacher') === true

  const isHybrid = form.getFieldValue('DeliveryLocation') === 'Hybrid'
  const isMaleAllowed = form.getFieldValue('MaleAllowed')
  const isFemaleAllowed = form.getFieldValue('FemaleAllowed')
  const isClass = form.getFieldValue('ProgramType') === 'Class'
  const isProgram = form.getFieldValue('ProgramType') === 'Custom'

  Form.useWatch('AllowToEditFee', form)
  const isAllowEditFee = form.getFieldValue('AllowToEditFee')
  Form.useWatch('AllowExtraPayment', form)
  const isAllowExtraPayment = form.getFieldValue('AllowExtraPayment')

  Form.useWatch('IncludeProcessingFee', form)
  const isIncludeProcessingFee = form.getFieldValue('IncludeProcessingFee')
  Form.useWatch('AllowSplitPayments', form)
  const isAllowSplitPayments = form.getFieldValue('AllowSplitPayments')
  Form.useWatch('PaymentCategoryAdded', form)
  const isPaymentCategoryAdded = form.getFieldValue('PaymentCategoryAdded')
  Form.useWatch('PaymentCategories', form)
  const paymentCategoriesValues = Form.useWatch('PaymentCategories', form)
  Form.useWatch('OrganizationProfile', form)

  const getPaymentCategories = () => {
    Api.get(
      `paymentCategories/getOrganizationPaymentCategories/${organization?.id}`,
    )
      .then((res) => {
        const formatedCategories = res?.results
          ?.filter((item) => item.ActiveInd === 1)
          .map((item) => ({
            value: item.Id,
            label: item.Name,
          }))
        setCategories(formatedCategories)
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
      })
  }

  useEffect(() => {
    if (organization?.id) {
      getPaymentCategories()
      dispatch(getProfileList({ organizationId: organization.id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])

  const actions = (type) => {
    setProgramType(type)
  }

  useEffect(() => {
    if (params.id) {
      dispatch(getProgramDetail(params.id, form, setSchedule, actions))
      setPresenterValidate(true)
    }
    // eslint-disable-next-line
  }, [params.id])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const paramsValies = Object.fromEntries(urlSearchParams.entries())
    if (
      Object.entries(paramsValies).length > 0 &&
      paramsValies.duplicate &&
      paramsValies.id
    ) {
      dispatch(getProgramDetail(paramsValies.id, form, setSchedule))
      history.replace('/settings/create-program')
    }
    // eslint-disable-next-line
  }, [])

  const resetForm = () => {
    form.resetFields()
  }

  const navigation = () => {
    resetForm()
    history.push('/programs')
  }

  // eslint-disable-next-line consistent-return
  const onFinish = (values) => {
    // eslint-disable-next-line
    if (parseInt(values.MaximumNumberOfRegistrations) >= 1) {
      let data = {
        Title: values?.Title,
        ProgramType: isEdit ? programType : values?.ProgramType,
        DeliveryLocation: values?.DeliveryLocation,
        ProgramFees: values.ProgramFees,
        Description: values?.Description,
        MaximumNumberOfRegistrations:
          values?.MaximumNumberOfRegistrations?.replaceAll(/,/g, ''),
        ShowOnLandingPage: values?.ShowOnLandingPage,
        Flyer: values?.Flyer,
        AllowToEditFee: isShowPaymentRelatedOption
          ? values?.AllowToEditFee
          : false,
        AllowExtraPayment: isShowPaymentRelatedOption
          ? values?.AllowExtraPayment
          : false,
        IncludeProcessingFee: isShowPaymentRelatedOption
          ? values?.IncludeProcessingFee
          : false,
        AllowSplitPayments: isShowPaymentRelatedOption
          ? values?.AllowSplitPayments
          : false,
        NumberOfDefaultSplit: values?.NumberOfDefaultSplit,
        PaymentCategoryAdded: isShowPaymentRelatedOption
          ? values.PaymentCategoryAdded
          : false,
        PaymentCategories: values.PaymentCategories,
        OrganizationProfile: values.OrganizationProfile,
        Username: user.id,
        OrganizationId: organization.id,
        OrganizationName: organization?.Name,
      }
      if (isProgram) {
        data = {
          ...data,
          Requirements: values?.Requirements,
        }
      }
      if (isClass) {
        if (!values?.MaleAllowed && !values?.FemaleAllowed) {
          message.error('Please select at least one gender allowed')
          return false
        }
        if (isAddTeacher && !presenterValidate) {
          message.error('Kindly Validate Teacher')
          return false
        }
        data = {
          ...data,
          AddAgeRange: values?.AddAgeRange,
          MinimumAge: values?.MinimumAge,
          MaximumAge: values?.MaximumAge,
          StartDate: values?.StartDate,
          EndDate: values?.EndDate,
          MaleAllowed: values?.MaleAllowed,
          FemaleAllowed: values?.FemaleAllowed,
          Schedule: schedule,
          AddTeacher: values?.AddTeacher,
          TeacherEmail: values?.TeacherEmail,
          TeacherFirstName: values?.TeacherFirstName,
          TeacherLastName: values?.TeacherLastName,
          TeacherMobileNumber: values.TeacherMobileNumber?.replace(/\D/g, ''),
          TeacherPhoneCode: values?.TeacherPhoneCode,
        }
      }
      if (values.DeliveryLocation === 'Hybrid') {
        data = {
          ...data,
          // Latitude: location.lat,
          // Longitude: location.lng,
          Address: values.mailingAddress,
          ZipCode: values.zipCode,
          Suite: values.suite,
          City: values.city,
          State: values.state,
          Country: values.country,
        }
      }
      if (params.id) {
        dispatch(updateProgram({ ProgramId: params.id, ...data }, navigation))
      } else {
        dispatch(addProgram(data, navigation))
      }
    } else {
      message.error('Maximum registrations must not be zero')
    }
  }

  const handleInputNumberChange = (name, value) => {
    const withoutCommas = value.replaceAll(/,/g, '')
    const val = withoutCommas.replace(/\D/g, '')
    form.setFieldsValue({ [name]: numberWithCommas(val) })
  }

  const onChangeSchedule = (value) => {
    const scheduleClone = schedule
    scheduleClone[value.dayName] = value
    setSchedule({ ...scheduleClone })
  }

  const onClearAllClick = () => {
    setSchedule({ ...roomScheduleInitialState })
  }

  const onApplyToAll = (value) => {
    const scheduleClone = { ...schedule }
    Object.keys(scheduleClone).map((dayName) => {
      scheduleClone[dayName] = { ...value, dayName }
      return dayName
    })
    setSchedule({ ...scheduleClone })
  }

  const validateTeacher = () => {
    const { TeacherPhoneCode, TeacherMobileNumber } = form.getFieldsValue()
    if (!TeacherPhoneCode || !TeacherMobileNumber) {
      message.error('Please enter teacher phone number to validate')
    } else {
      // removing - from string
      const FormatedTeacherMobileNumber = TeacherMobileNumber?.replace(
        /\D/g,
        '',
      )
      // removing zero if it's the first charater in the string
      const trimPhoneNumber = FormatedTeacherMobileNumber?.replace(/^0+/, '')
      const phone = TeacherPhoneCode + trimPhoneNumber
      setLoading(true)
      Api.get(
        `/users/check-phone-number-with-info?phone=${encodeURIComponent(
          phone,
        )}`,
      )
        .then(() => {
          setPresenterValidate(true)
          setPresenterFound(false)
          form.setFieldValue('TeacherFirstName', '')
          form.setFieldValue('TeacherLastName', '')
          form.setFieldValue('TeacherEmail', '')
          setLoading(false)
        })
        .catch((err) => {
          const success = err?.response?.data?.message
          const data = err?.response?.data?.values
          if (success === 'User Found') {
            form.setFieldValue('TeacherFirstName', data.firstname)
            form.setFieldValue('TeacherLastName', data.lastname)
            form.setFieldValue('TeacherEmail', data.email)
            setPresenterFound(true)
            setPresenterValidate(true)
            setLoading(false)
          } else {
            message.error('Something Went Wrong')
          }
        })
    }
  }

  const handleInputChange = (value) => {
    form.setFieldsValue({ TeacherMobileNumber: value })
  }

  const isPaid = parseFloat(form.getFieldValue('ProgramFees')) > 0
  const isShowPaymentRelatedOption = isPaid
  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0">
            {params.id ? 'Update Program' : 'Add New Program'}
          </h6>
          <p className="font-regular">Enter Program Information</p>
        </>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        key={0}
        onFinish={onFinish}
        name="organization"
        initialValues={initialValues}
        scrollToFirstError
      >
        <Row gutter={[24, 0]}>
          <Col xl={isEdit ? 24 : 12} md={isEdit ? 24 : 12} xs={24} sm={24}>
            <Form.Item
              name="Title"
              label="Title"
              colon={false}
              rules={[programValidation]}
            >
              <Input placeholder="Enter Title" />
            </Form.Item>
          </Col>
          {!isEdit && (
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="ProgramType"
                label="Program Type"
                colon={false}
                rules={[programValidation]}
              >
                <Select
                  placeholder="Select Program Type"
                  style={{ width: '100%' }}
                >
                  {programSubTypeOptions.map((item) => (
                    <Select.Option key={item.id} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="DeliveryLocation"
              label="Delivery Location"
              colon={false}
              rules={[programValidation]}
            >
              <Select
                placeholder="Select Delivery Location"
                style={{ width: '100%' }}
              >
                <Select.Option value="Onsite">Onsite</Select.Option>
                <Select.Option value="Online">Online</Select.Option>
                <Select.Option value="Hybrid">Hybrid</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {isHybrid && (
            <AutoCompleteAddress
              form={form}
              validation
              isInitiallyValidated={params.id}
            />
          )}
          {isProgram && (
            <>
              <Col xl={24} md={24} xs={24} sm={24}>
                <Form.Item
                  name="Requirements"
                  label="Requirements"
                  colon={false}
                  rules={[programValidation]}
                >
                  <Input.TextArea
                    placeholder="Requirements"
                    autoSize={{ minRows: 2, maxRows: 3 }}
                  />
                </Form.Item>
              </Col>
            </>
          )}
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="ProgramFees"
              label="Programs Dues"
              colon={false}
              rules={[programValidation]}
            >
              <AmountInput
                placeholder="Enter Programs Dues"
                value={form.getFieldValue('ProgramFees')}
                onChange={(value) =>
                  form.setFieldsValue({ ProgramFees: value })
                }
              />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="Description"
              label="Description"
              colon={false}
              rules={[programValidation]}
            >
              <Input.TextArea
                placeholder="Description"
                autoSize={{ minRows: 2, maxRows: 3 }}
              />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="MaximumNumberOfRegistrations"
              label="Maximum # of Registrations"
              colon={false}
              rules={[programValidation]}
            >
              <Input
                inputMode="numeric"
                placeholder="Maximum # of Registrations"
                onChange={(e) =>
                  handleInputNumberChange(
                    'MaximumNumberOfRegistrations',
                    e.target.value,
                  )
                }
              />
            </Form.Item>
          </Col>
          {isClass && (
            <>
              <Col span={24}>
                <Form.Item
                  name="AddAgeRange"
                  label="Define Age Range"
                  colon={false}
                  rules={[programValidation]}
                >
                  <Switch checked={form.getFieldValue('AddAgeRange')} />
                </Form.Item>
              </Col>
              {isAddAgeRange && (
                <>
                  <Col xl={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      name="MinimumAge"
                      label="Minimum age"
                      colon={false}
                      rules={[programValidation]}
                    >
                      <Input
                        inputMode="numeric"
                        placeholder="Minimum age"
                        type="number"
                        onChange={(e) =>
                          handleInputNumberChange('MinimumAge', e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      name="MaximumAge"
                      label="Maximum age"
                      colon={false}
                      rules={[programValidation]}
                    >
                      <Input
                        inputMode="numeric"
                        placeholder="Maximum age"
                        type="number"
                        onChange={(e) =>
                          handleInputNumberChange('MaximumAge', e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="StartDate"
                  label="Start Date"
                  colon={false}
                  rules={[programValidation]}
                >
                  <DatePicker
                    format="MM-DD-YYYY"
                    className="t-w-full"
                    disabledDate={(d) =>
                      !d || d.isBefore(moment().subtract(1, 'day'))
                    }
                    disabledTime={disabledTime}
                    onChange={(selectedDate) =>
                      !compareDateTimeWithNow(selectedDate, 'before') &&
                      form.setFieldsValue({
                        StartDate: moment(),
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="EndDate"
                  label="End Date"
                  colon={false}
                  rules={[programValidation]}
                >
                  <DatePicker
                    format="MM-DD-YYYY"
                    className="t-w-full"
                    disabledDate={(d) =>
                      !d || d.isBefore(moment().subtract(1, 'day'))
                    }
                    disabledTime={disabledTime}
                    onChange={(selectedDate) =>
                      !compareDateTimeWithNow(selectedDate, 'before') &&
                      form.setFieldsValue({
                        EndDate: moment(),
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xl={24} md={24} xs={24} sm={24}>
                <h1 className="t-text-md t-my-1">Genders Allowed</h1>
              </Col>
              <Col xl={12} md={12} xs={24} sm={24} className="t-my-1">
                <Form.Item
                  name="MaleAllowed"
                  label="Male"
                  colon={false}
                  rules={[programValidation]}
                >
                  <Checkbox
                    checked={isMaleAllowed}
                    onChange={(e) =>
                      form.setFieldsValue({
                        MaleAllowed: e.target.checked,
                      })
                    }
                  >
                    Male
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={24} className="t-my-1">
                <Form.Item
                  name="FemaleAllowed"
                  label="Female"
                  colon={false}
                  rules={[programValidation]}
                >
                  <Checkbox
                    checked={isFemaleAllowed}
                    onChange={(e) =>
                      form.setFieldsValue({
                        FemaleAllowed: e.target.checked,
                      })
                    }
                  >
                    Female
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="AddTeacher"
                  label="Add Teacher"
                  colon={false}
                  rules={[programValidation]}
                >
                  <Switch checked={form.getFieldValue('AddTeacher')} />
                </Form.Item>
              </Col>
              {isAddTeacher && (
                <>
                  <Divider />
                  <Col span={24}>
                    <h1 className="t-text-md t-text-secondary-100 t-font-bold">
                      You can add phone number and click on validate teacher to
                      check if the user is already registered in your
                      organization. If phone number does not exist you need to
                      put name and email for the teacher.
                    </h1>
                  </Col>
                  <Divider />
                  <Col
                    span={24}
                    className="t-flex t-justify-between t-items-center"
                  >
                    <div className="t-flex t-font-bold">
                      Teacher Details
                      {!params.id && (
                        <>
                          {!presenterValidate ? (
                            <span className="t-ml-6 font-bold t-flex t-space-x-2 t-items-center">
                              <h1 className="t-text-orange-600 t-text-sm">
                                Enter teacher information
                              </h1>
                              <AiFillCloseCircle
                                fontSize="1.2rem"
                                className="t-text-orange-600"
                              />
                            </span>
                          ) : presenterFound ? (
                            <span className="t-ml-6 font-bold t-flex t-space-x-2 t-items-center">
                              <h1 className="t-font-bold t-text-sm">
                                Teacher Found
                              </h1>
                              <AiFillCheckCircle
                                fontSize="1.2rem"
                                className="t-text-green-600"
                              />
                            </span>
                          ) : (
                            <span className="t-ml-6 font-bold t-flex t-space-x-2 t-items-center">
                              <h1 className="t-font-bold t-text-sm">
                                Teacher Not Found
                              </h1>
                              <AiFillCloseCircle
                                fontSize="1.2rem"
                                className="t-text-orange-600"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </div>
                    {params.id ? (
                      <>
                        <Button
                          type="primary"
                          onClick={() => setIsModalVisible(true)}
                        >
                          Select New Teacher
                        </Button>
                        <Modal
                          title="Enter New Teacher Information"
                          open={isModalVisible}
                          onCancel={() => setIsModalVisible(false)}
                          footer={null}
                        >
                          <UpdateTeacher
                            form={form}
                            close={() => {
                              setIsModalVisible(false)
                              setPresenterValidate(true)
                            }}
                            handleInputChange={handleInputChange}
                          />
                        </Modal>
                      </>
                    ) : (
                      <Button
                        type="primary"
                        loading={isLoading}
                        onClick={validateTeacher}
                      >
                        Validate Teacher
                      </Button>
                    )}
                  </Col>
                  <Col xl={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      name="TeacherPhoneCode"
                      label="Select Country"
                      colon={false}
                      rules={[programValidation]}
                    >
                      <Select
                        placeholder="Select Country Code"
                        style={{ width: '100%' }}
                        disabled={params.id}
                      >
                        <Select.Option value="+1">
                          United States/Canada (+1)
                        </Select.Option>
                        <Select.Option value="+54">
                          Argentina (+54)
                        </Select.Option>
                        <Select.Option value="+92">
                          Pakistan (+92)
                        </Select.Option>
                        <Select.Option value="+55">Brazil (+55)</Select.Option>
                        <Select.Option value="+58">
                          Venezuela, Bolivarian Republic of Venezuela (+58)
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xl={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      name="TeacherMobileNumber"
                      label="Teacher Mobile Number"
                      colon={false}
                      rules={[programValidation]}
                    >
                      <MaskedInput
                        disabled={params.id}
                        inputMode="numeric"
                        mask={MaskPattern}
                        onChange={(e) => {
                          handleInputChange(e.maskedValue)
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {(presenterValidate || params.id) && (
                    <>
                      <Col xl={12} md={12} xs={24} sm={24}>
                        <Form.Item
                          name="TeacherFirstName"
                          label="Teacher first name"
                          colon={false}
                          rules={[programValidation]}
                        >
                          <Input
                            placeholder="Enter first name"
                            disabled={presenterFound}
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={12} md={12} xs={24} sm={24}>
                        <Form.Item
                          name="TeacherLastName"
                          label="Teacher last name"
                          colon={false}
                          rules={[programValidation]}
                        >
                          <Input
                            placeholder="Enter last name"
                            disabled={presenterFound}
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} md={24} xs={24} sm={24}>
                        <Form.Item
                          name="TeacherEmail"
                          label="Teacher Email"
                          colon={false}
                          rules={[programValidation]}
                        >
                          <Input
                            placeholder="Enter Email"
                            disabled={presenterFound}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </>
              )}
              <Col xl={24} md={24} xs={24} sm={24}>
                <div className="t-flex t-justify-between">
                  <div>
                    <Title level={4} className="t-text-secondary-100">
                      Class availability
                    </Title>
                    <Paragraph>
                      You need to define class availability for each day of week
                      for this class
                    </Paragraph>
                  </div>
                  <Button type="primary" onClick={onClearAllClick}>
                    Clear All
                  </Button>
                </div>

                <div className="t-space-y-2 t-w-full t-pt-4 t-overflow-scroll">
                  <div className="t-flex t-space-x-2 t-items-center t-px-2">
                    <div
                      className="t-flex t-space-x-2 t-items-center t-min-w-36"
                      style={{
                        minWidth: '9rem',
                      }}
                    >
                      <Title
                        level={5}
                        className="t-font-bold t-text-secondary-100"
                      >
                        Day
                      </Title>
                    </div>
                    <Title
                      level={5}
                      className="t-font-bold t-text-secondary-100"
                    >
                      Available Time slots
                    </Title>
                  </div>
                  <Divider />
                  {Object.keys(schedule).map((day, index) => (
                    <DayDurations
                      key={index}
                      value={schedule[day]}
                      onChange={onChangeSchedule}
                      onApplyAll={() => onApplyToAll(schedule[day])}
                    />
                  ))}
                </div>
              </Col>
            </>
          )}
          <Col span={24}>
            <Form.Item
              name="ShowOnLandingPage"
              label="Show on Landing Page"
              colon={false}
              rules={[programValidation]}
            >
              <Switch checked={form.getFieldValue('ShowOnLandingPage')} />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item name="Flyer" label="Banner Image" colon={false}>
              <ImageUploader
                ratio={2 / 1}
                defaultFile={
                  form.getFieldValue('Flyer') && form.getFieldValue('Flyer')
                }
                onFileChange={(file) =>
                  form.setFieldsValue({
                    Flyer: file?.originFileObj,
                  })
                }
              />
            </Form.Item>
          </Col>

          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="OrganizationProfile"
              label="Alternate Organization Profile"
              colon={false}
            >
              <Select
                allowClear
                style={{
                  width: '100%',
                }}
                placeholder="Select Organization Profile"
                options={formattedProfileList}
              />
            </Form.Item>
          </Col>

          {isShowPaymentRelatedOption && (
            <>
              <Col span={24}>
                <Form.Item
                  name="AllowToEditFee"
                  label="Allow user to edit fee"
                  colon={false}
                  rules={[programValidation]}
                >
                  <Switch checked={isAllowEditFee} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="AllowExtraPayment"
                  label="Allow Extra Payment"
                  colon={false}
                  rules={[programValidation]}
                >
                  <Switch checked={isAllowExtraPayment} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="IncludeProcessingFee"
                  label="Require Payment Of Processing Fees"
                  colon={false}
                  rules={[programValidation]}
                >
                  <Switch checked={isIncludeProcessingFee} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="AllowSplitPayments"
                  label="Allow Split Payments"
                  colon={false}
                  rules={[programValidation]}
                >
                  <Switch checked={isAllowSplitPayments} />
                </Form.Item>
              </Col>
              {isAllowSplitPayments && (
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="NumberOfDefaultSplit"
                    label="Duration Of Split Payments"
                    colon={false}
                    rules={[programValidation]}
                  >
                    <Input
                      placeholder="Number of Months"
                      // disabled={isSplit}
                      inputMode="numeric"
                      onChange={(e) =>
                        form.setFieldsValue({
                          NumberOfDefaultSplit: e.target.value.replace(
                            /\D/g,
                            '',
                          ),
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              )}

              <Col span={24}>
                <Form.Item
                  name="PaymentCategoryAdded"
                  label="Add Payment Category"
                  colon={false}
                  rules={[programValidation]}
                >
                  <Switch checked={isPaymentCategoryAdded} />
                </Form.Item>
              </Col>
              {isPaymentCategoryAdded && (
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="PaymentCategories"
                    label="Payment Categories"
                    colon={false}
                  >
                    <Select
                      mode="multiple"
                      style={{
                        width: '100%',
                      }}
                      placeholder="Select Payment Categories"
                      options={categories}
                      {...(paymentCategoriesValues?.length > 0 && {
                        open: false,
                      })}
                    />
                  </Form.Item>
                </Col>
              )}
            </>
          )}

          <Col span={24} className="text-left t-space-x-2">
            <Button
              loading={loading}
              disabled={isClass ? isValidSchedule(schedule) : false}
              type="primary"
              className="px-25"
              htmlType="submit"
            >
              {params.id ? 'Update Program' : 'Add New Program'}
            </Button>
            <Button
              type="primary"
              className="px-25"
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
          </Col>
          {isClass && isValidSchedule(schedule) && (
            <Col span={24} className="t-my-2 t-text-red-500">
              Availibility schedule in not valid
            </Col>
          )}
        </Row>
      </Form>
    </Card>
  )
}

export { CreateProgram }

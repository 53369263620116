import React from 'react'
import { Avatar, Tooltip } from 'antd'
import { BiLinkExternal } from 'react-icons/bi'
import { useHistory } from 'react-router-dom'
import { timeInAgo } from 'utils'
import { TbPresentationOff } from 'react-icons/tb'
import { AiOutlineNotification } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import {
  clearUserNotification,
  readUserNotification,
} from 'store/notificationSlice/notificationActions'
import { CgRemoveR } from 'react-icons/cg'
import { useNotificationSelector } from 'store/notificationSlice/notificationReducer'

const clockicon = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6V10C9 10.2652 9.10536 10.5196 9.29289 10.7071L12.1213 13.5355C12.5118 13.9261 13.145 13.9261 13.5355 13.5355C13.9261 13.145 13.9261 12.5118 13.5355 12.1213L11 9.58579V6Z"
      fill="#111827"
    ></path>
  </svg>,
]

const formateTitle = (item) => {
  switch (item.Type) {
    case 'Event Reject':
      return `${item.Data.Presenter} ${item.Message}`
    case 'Pledge Reminder':
      return `${item.Message}`
    case 'Pledge Conversation Reminder':
      return `You pledged ${item.Data.Amount} for "${item.Data.EventName}". ${item.Message}`
    case 'Listing Request':
      return `${item.Message} ${item.Data.UserName}`
    case 'Listing Request Approved':
      return `${item.Data.ApprovedBy} ${item.Message}`
    case 'Listing Request Rejected':
      return `${item.Data.ApprovedBy} ${item.Message}`
    case 'Business Service Request':
      return `${item.Message} ${item.Data.UserName}`
    case 'Business Service Paid':
      return `${item.Data.UserName} ${item.Message} ${item.Data.ServiceTitle}`
    case 'Business Counter Offer':
      return `${item.Message} ${item.Data.ServiceTitle}`
    case 'Auction Outbid':
      return `${item.Message}`
    case 'Auction Win':
      return `${item.Message}`
    case 'Auction Pending Review':
      return `${item.Message}`
    default:
      return `${item.Message}`
  }
}

const formateLink = (item) => {
  switch (item.Type) {
    case 'Event Rejected':
      return `/settings/create-event/${item.Data.EventId}`
    case 'Pledge Reminder':
      return `/settings/account?key=2&ytdPledges=true`
    case 'Pledge Conversation Reminder':
      return `/pledge-conversion/${item.Data.PledgeId}`
    case 'Listing Request':
      return `/settings/manage-organization/listing-requests`
    case 'Listing Request Approved' || 'Listing Request Rejected':
      return `/settings/account`
    case 'Business Service Request':
      return `/settings/manage-organization/my-business-service-requests`
    case 'Business Service Paid':
      return `/settings/manage-organization/my-business-service-requests`
    case 'Business Counter Offer':
      return `/settings/invites?key=3`
    case 'Auction Outbid':
      return item.Data.ParentType === 'auction'
        ? `/auction/${item.Data.ParentId}/join-auction`
        : `/events/${item.Data.ParentId}/join-event?key=2`
    case 'Auction Win':
      return `/events/auction-item-payment/${item.Data.SoldItemId}`
    case 'Auction Pending Review':
      return item.Data.ParentType === 'auction'
        ? `/auction/${item.Data.ParentId}/control-screen`
        : `/events/${item.Data.ParentId}/control-screen?key=7`
    default:
      return `/settings/notifications`
  }
}

const formateAvatar = (item) => {
  switch (item.Type) {
    case 'Event Rejected':
      return <TbPresentationOff className="t-text-secondary-100" />
    case 'Pledge Reminder':
      return <AiOutlineNotification className="t-text-secondary-100" />
    case 'Pledge Conversation Reminder':
      return <AiOutlineNotification className="t-text-secondary-100" />
    case 'Listing Request':
      return <AiOutlineNotification className="t-text-secondary-100" />
    case 'Listing Request Approved' || 'Listing Request Rejected':
      return <AiOutlineNotification className="t-text-secondary-100" />
    case 'Business Service Request' || 'Business Service Paid':
      return <AiOutlineNotification className="t-text-secondary-100" />
    case 'Business Counter Offer':
      return <AiOutlineNotification className="t-text-secondary-100" />
    case 'Auction Outbid':
      return <AiOutlineNotification className="t-text-secondary-100" />
    case 'Auction Win':
      return <AiOutlineNotification className="t-text-secondary-100" />
    case 'Auction Pending Review':
      return <AiOutlineNotification className="t-text-secondary-100" />
    default:
      return <AiOutlineNotification className="t-text-secondary-100" />
  }
}

const FormatedNotifications = (notifications) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const action = (link) => {
    history.push(link)
  }
  const handleAction = (id, link) => {
    dispatch(readUserNotification(id, link, action))
  }

  const handleClear = (id) => {
    dispatch(clearUserNotification(id))
  }

  const { clearLoading } = useNotificationSelector()
  const formatData = notifications?.map((item) => {
    const title = formateTitle(item)
    const link = formateLink(item)
    const read = item.Read
    return {
      title,
      description: (
        <div className="t-flex t-justify-between t-items-center">
          <div className="t-flex t-items-center t-space-x-2">
            {clockicon} {timeInAgo(item.CreatedDate)}
          </div>
          <div className="t-flex t-justify-end t-items-center">
            <Tooltip title="Clear Notification">
              {/* <Popconfirm
                title="Are you sure you want to delete this notification?"
                onConfirm={() => handleClear(item.Id)}
              > */}
              <CgRemoveR
                fontSize="2.5rem"
                className={`t-text-secondary-100 t-cursor-pointer ${
                  clearLoading && 't-opacity-25'
                }`}
                onClick={() => clearLoading === false && handleClear(item.Id)}
              />
              {/* </Popconfirm> */}
            </Tooltip>
            <Tooltip title="Take Action">
              <BiLinkExternal
                fontSize="2.5rem"
                className="t-text-secondary-100 t-cursor-pointer"
                onClick={() => handleAction(item.Id, link)}
              />
            </Tooltip>
          </div>
        </div>
      ),
      avatar: <Avatar shape="square">{formateAvatar(item)}</Avatar>,
      read,
    }
  })
  return formatData
}

export { FormatedNotifications }

import React, { useEffect, useState } from 'react'
import { Col, Modal, Skeleton, Spin, Table, Tooltip, message } from 'antd'
import { useDispatch } from 'react-redux'
import { useVolunteerSelector } from 'store/volunteerSlice/volunteerReducer'
import { getRegisterVolunteers } from 'store/volunteerSlice/volunteerActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { MdOutlineMessage } from 'react-icons/md'
import { makeRequest } from 'api'
import { MessageForm } from './MessageForm'

function RegisteredVolunteer({ id, name }) {
  const dispatch = useDispatch()
  const { organization } = useAuthSelector()
  const { registeredVolunteers } = useVolunteerSelector()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [loading, setLoading] = useState(false)
  const [messageLoading, setMessageLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    dispatch(getRegisterVolunteers(id, setLoading))
  }, [dispatch, id])

  const formateData = (values) => {
    const formatedData = values?.map((item) => ({
      ...item,
      key: item.Id,
    }))
    return formatedData || []
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      className: 'ant-table-row-cell-break-word',
    },
  ]

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const sendCustom = (data) => {
    setIsModalVisible(false)
    const list = registeredVolunteers?.filter((item) =>
      selectedRowKeys.includes(item.Id),
    )
    const dataList = list.length > 0 ? list : registeredVolunteers
    if (organization && organization.CreatedBy) {
      const payload = {
        dataList,
        email: organization.CreatedBy,
        volunteerOpp: name,
        ...data,
      }
      setMessageLoading(true)
      makeRequest(
        'post',
        `volunteer/sendCustomMessage`,
        {
          // Change it later from parent component organizationId
          organization: organization?.id,
        },
        { payload },
      )
        .then((res) => {
          message.success(res.message)
          setSelectedRowKeys([])
          setMessageLoading(false)
        })
        .catch((error) => {
          message.error(error?.response?.data?.error || 'Something went wrong')
          setMessageLoading(false)
        })
    } else {
      message.error('Cound not get Organization data')
    }
  }

  return (
    <Col span={24}>
      <div className="t-flex t-flex-wrap t-justify-between t-items-center">
        <h6 className="t-font-bold t-text-base">Registered User</h6>
        <Tooltip title="Send Message">
          {messageLoading ? (
            <Spin />
          ) : (
            <MdOutlineMessage
              fontSize="1.5rem"
              id="send-global-message"
              className="t-text-secondary-100 t-cursor-pointer"
              onClick={() => setIsModalVisible(true)}
            />
          )}
        </Tooltip>
        <Modal
          title="Message Form"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <MessageForm submit={(data) => sendCustom(data)} />
        </Modal>
      </div>
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <Table
          tableLayout="auto"
          scroll={{ x: 500 }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={formateData(registeredVolunteers)}
          bordered={false}
          pagination
        />
      </Skeleton>
    </Col>
  )
}

export { RegisteredVolunteer }

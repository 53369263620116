import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Skeleton, Avatar, Tooltip, Tag } from 'antd'
import { BookFilled } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { fetchMoreData, searchProgramServiceRows } from 'utils'
import { useHistory } from 'react-router-dom'
import {
  programActions,
  useProgramSelector,
} from 'store/programSlice/programReducer'
import { getOrganizationPrograms } from 'store/programSlice/programActions'
import InfiniteScroll from 'react-infinite-scroll-component'
import { config } from 'configs'

const MyProgram = ({ id, searchText = null, urlData }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { loading, organizationPrograms } = useProgramSelector()
  const [current, setCurrent] = useState([])

  const searchedData = searchProgramServiceRows(
    organizationPrograms,
    searchText,
  )

  useEffect(() => {
    setCurrent(searchedData.slice(0, config.itemFirstLoad))
    // eslint-disable-next-line
  }, [organizationPrograms, searchText])

  useEffect(() => {
    dispatch(getOrganizationPrograms(id))
    // eslint-disable-next-line
  }, [])

  const handleProgramSelected = (e, program) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(programActions.setProgram(program))
    history.push({
      pathname: `/programs/${program.ProgramId}`,
      state: { urlData },
    })
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div
        className="t-flex t-flex-col t-justify-between t-h-full"
        style={{ minHeight: 'calc(100vh - 250px)' }}
      >
        <InfiniteScroll
          dataLength={current.length}
          next={() => fetchMoreData(current, setCurrent, searchedData)}
          hasMore={current.length < searchedData.length}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              {/* <Spin /> */}
            </div>
          }
        >
          <Row gutter={[24, 24]}>
            {current.length === 0 && (
              <div
                className="t-flex t-justify-center t-items-center t-w-full"
                style={{ minHeight: 'calc(100vh - 300px)' }}
              >
                <div className="t-flex t-flex-col t-items-center t-space-y-2">
                  <BookFilled
                    style={{ fontSize: '2rem' }}
                    className="t-text-secondary-100"
                  />
                  <p>No Programs</p>
                </div>
              </div>
            )}
            {current.map((item, index) => (
              <Col span={24} lg={8} className="mb-24" key={index}>
                <Card
                  bodyStyle={{ paddingTop: '14px' }}
                  bordered={false}
                  className="card-project-2 header-solid t-cursor-pointer"
                  title={[
                    <Row gutter={[24, 24]} key={0}>
                      <Col>
                        <Avatar
                          src={<BookFilled />}
                          style={{
                            width: '25px',
                            height: '25px',
                            lineHeight: '74px',
                            borderRadius: '8px',
                            padding: '16px',
                            backgroundColor: config.colors.primary,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        />
                      </Col>
                      <Col>
                        <h6 className="font-semibold t-w-64 t-truncate">
                          {item.Title ? item.Title : 'No Title Found'}
                        </h6>
                        <div className="t-flex t-justify-between">
                          <div className="card-tag t-font-thin t-text-secondary-100 t-text-sm">
                            <Tooltip title="Delivery Type">
                              <div className="card-tag t-font-thin t-text-secondary-100 t-text-sm">
                                {item?.DeliveryLocation}
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </Col>
                    </Row>,
                  ]}
                  onClick={(e) => handleProgramSelected(e, item)}
                >
                  <p className="t-truncate">
                    {item.Description
                      ? item.Description
                      : 'No Description Found'}
                  </p>
                  <div className="t-w-full t-flex t-justify-end">
                    {item?.ProgramType === 'Class' ? (
                      <Tag color="orange">Class</Tag>
                    ) : (
                      <Tag color="purple">Custom</Tag>
                    )}
                  </div>
                  <hr />
                  <div className="t-flex t-justify-end t-mt-2">
                    <Button
                      type="link"
                      size="small"
                      className="t-rounded-full"
                      onClick={(e) => handleProgramSelected(e, item)}
                    >
                      See More
                    </Button>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    </Skeleton>
  )
}

export { MyProgram }

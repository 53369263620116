import React, { useEffect, useMemo, useState } from 'react'
import { Card, Skeleton, Tooltip, message, Select, Table } from 'antd'
import { MdOutlineSimCardDownload } from 'react-icons/md'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { useAuthSelector } from 'store/authSlice/authReducer'
// import { FilterOutlined } from '@ant-design/icons'
import Api from 'api'
import Apiv2 from 'api/apiv2'
import {
  csvComponentReportMapping,
  downloadCSV,
  flattenObject,
  formatAmount,
  formateDateTimeToLocal,
} from 'utils'
import ConditionalRenderer from './ConditionalRender'
const { Option } = Select

const ComponentPerformanceForOrganization = () => {
  const history = useHistory()
  const { organization } = useAuthSelector()
  const [loading, setLoading] = useState(true)
  const [firstDropdownValue, setFirstDropdownValue] = useState()
  const [secondDropdownData, setSecondDropdownData] = useState([])
  const [secondDropdownValue, setSecondDropdownValue] = useState(null)
  const [secondDropdownValueId, setSecondDropdownValueId] = useState(null)
  const [thirdDropdownValue, setThirdDropdownValue] = useState()
  const [showSecondDropdownTooltip, setShowSecondDropdownTooltip] =
    useState(false)
  const [showFilterResults, setShowFilterResults] = useState(false)
  const [filterCampaingTitle, setFilterCampaingTitle] = useState(null)

  const [fundraiserData, setFundraiserData] = useState([])
  const [eventData, setEventData] = useState([])
  const [campaignData, setCampaignData] = useState([])
  const [classData, setClassData] = useState([])
  const [programsData, setProgramsData] = useState([])
  const [servicesData, setServicesData] = useState([])
  const [VPData, setVPData] = useState([])
  const [rentalData, setRentalsData] = useState([])
  const [eventName, setEventName] = useState('')
  const [reportTypeName, setReportTypeName] = useState('')
  const [showDownloadOption, setShowDownloadOption] = useState(true)
  const [csvData, setCsvData] = useState([])
  const [orgDonations, setOrgDonations] = useState({})
  const fetchOrganizationData = async (organizationId) => {
    try {
      setLoading(true)
      // Parallelize independent API calls
      const [
        fundraisersResponse,
        eventsResponse,
        programsResponse,
        classesResponse,
        servicesResponse,
        roomsResponse,
        volunteersResponse,
        organizationResponse,
      ] = await Promise.all([
        Apiv2.get(`admin/fundraisers?organizationId=${organizationId}`),
        Api.get(`adminReports/getEventsBySubscriber`),
        Api.get(
          `programServices/organizationProgramOrServices?organizationId=${organizationId}&type=program&programType=program`,
        ),
        Api.get(
          `programServices/organizationProgramOrServices?organizationId=${organizationId}&type=program&programType=class`,
        ),
        Api.get(
          `programServices/organizationProgramOrServices?organizationId=${organizationId}&type=service`,
        ),
        Api.get(`room/organizationRooms?id=${organizationId}`),
        Api.get(`volunteer/getVolunteerByOrganization/${organizationId}`),
        Api.get(
          `reports/ytd-donation-for-organization/${organizationId}?eventType=all`,
        ),
      ])

      if (fundraisersResponse.data.length > 0) {
        setFundraiserData(fundraisersResponse.data)
      }
      // Process and set data for events and campaigns
      const partitionedData = partitionEventsAndCampaigns(
        eventsResponse.results,
      )
      if (partitionedData?.events?.length > 0) {
        setEventData(partitionedData.events)
      }
      if (partitionedData?.campaigns?.length > 0) {
        setFirstDropdownValue('campaign')
        setThirdDropdownValue('summary')
        setCampaignData(partitionedData.campaigns)
        setSecondDropdownData(partitionedData.campaigns)
        setSecondDropdownValue(partitionedData.campaigns[0].Title)
        setEventName(partitionedData.campaigns[0].Title)
        setReportTypeName(`Performance Summary Report`)
        setSecondDropdownValueId(partitionedData.events[0].Id)
      }

      // Set data for programs, classes, services, rooms, and volunteers
      setProgramsData(programsResponse.results)
      setClassData(classesResponse.results)
      setServicesData(servicesResponse.results)
      setRentalsData(convertRoomData(roomsResponse.data))
      setVPData(volunteersResponse.results)
      const donationArray = Object.values(organizationResponse.results).map(
        (donation) => ({
          GrossAmount: donation.GrossAmount,
          NetAmount: donation.NetAmount,
          LastDonationDates: donation.LastDonationDate,
          LastDonationDate: formateDateTimeToLocal(
            donation.LastDonationDate,
            'date',
          ),
          LastDonationTime: formateDateTimeToLocal(
            donation.LastDonationDate,
            'time',
          ),
          DonorName: donation.DonorName,
          DonorEmail: donation.DonorEmail,
          DonorPhoneNumber: donation.DonorPhoneNumber,
          status: donation.status,
          LastDonationDateFormatted: donation.LastDonationDateFormatted,
        }),
      )
      // Set the array of donation objects as orgDonations
      setOrgDonations(donationArray)
    } catch (error) {
      handleError(error)
    } finally {
      setLoading(false)
    }
  }

  const partitionEventsAndCampaigns = (data) =>
    data.reduce(
      (acc, item) => {
        if (item.EventType === 'Fundraiser') acc.events.push(item)
        else if (item.EventType === 'Campaign') acc.campaigns.push(item)
        return acc
      },
      { events: [], campaigns: [] },
    )

  const convertRoomData = (data) =>
    data.map((item) => ({ Title: item.Name, id: item.Id }))

  const handleError = (error) => {
    console.error('error', error)
    message.error(error?.exceptionMessage)
  }

  useEffect(() => {
    fetchOrganizationData(organization?.id)
    // eslint-disable-next-line
  }, [])

  const onFirstDropdownChange = (value) => {
    setSecondDropdownData([])
    setSecondDropdownValue(null)
    setSecondDropdownValueId(null)
    setThirdDropdownValue(null)
    setShowFilterResults(true)
    setShowDownloadOption(false)

    setFirstDropdownValue(value)
    // Reset other dropdowns
    setSecondDropdownValue(null)
    setThirdDropdownValue(null)
    setFilterCampaingTitle(value.charAt(0).toUpperCase() + value.slice(1))
    if (value === 'fundraiser') {
      setSecondDropdownData(fundraiserData)
    } else if (value === 'campaign') {
      setSecondDropdownData(campaignData)
    } else if (value === 'event') {
      setSecondDropdownData(eventData)
    } else if (value === 'programs') {
      setSecondDropdownData(programsData)
    } else if (value === 'class') {
      setSecondDropdownData(classData)
    } else if (value === 'services') {
      setSecondDropdownData(servicesData)
    } else if (value === 'rentals') {
      setSecondDropdownData(rentalData)
    } else if (value === 'vp') {
      setSecondDropdownData(VPData)
    } else {
      setSecondDropdownData([])
    }
  }
  const [selectedSecondDropdownValue, setSelectedSecondDropdownValue] =
    useState(null)
  const onSecondDropdownChange = (value, id) => {
    setShowFilterResults(true)
    setSecondDropdownValue(value)
    setSecondDropdownValueId(id.key)
    setEventName(id.children)

    // Reset third dropdown
    // setThirdDropdownValue(null)
    setSelectedSecondDropdownValue(value)
    setShowSecondDropdownTooltip(false)
  }

  const setThirdMemoValue = useMemo(
    () => (newValue) => {
      // Update third dropdown value only if it's null
      if (!thirdDropdownValue) {
        setThirdDropdownValue(newValue)
      }
    },
    // eslint-disable-next-line
    [thirdDropdownValue, selectedSecondDropdownValue],
  )
  const onThirdDropdownChange = (value, id) => {
    setThirdMemoValue(value)
    setThirdDropdownValue(value)
    setReportTypeName(id.children)
    setShowFilterResults(false)
    setShowDownloadOption(true)
  }

  const handleSecondDropdownSearch = (value) => {
    if (value !== '') {
      setShowSecondDropdownTooltip(false)
    }
  }
  const donwloadCSV = () => {
    let vpCsvData
    let isVp = false
    const { fileName, csvColumns, csvHeaderMapping } =
      csvComponentReportMapping(thirdDropdownValue)

    const filterData =
      thirdDropdownValue === 'pledges' ||
      thirdDropdownValue === 'donations' ||
      thirdDropdownValue === 'fundraiserDonations' ||
      thirdDropdownValue === 'vp' ||
      thirdDropdownValue === 'rental'
        ? csvData
        : flattenObject(csvData)

    if (thirdDropdownValue === 'vp') {
      isVp = true
      vpCsvData = csvData.map((entry) => ({
        phoneNumber: entry?.phoneNumber,
        lastName: entry?.lastName,
        ZipCode: entry?.item?.ZipCode,
        Schedule: entry?.item?.Schedule,
        email: entry?.email,
        firstName: entry?.firstName,
        title: entry?.title,
      }))
    }

    downloadCSV(
      thirdDropdownValue === 'pledges' ||
        thirdDropdownValue === 'donations' ||
        thirdDropdownValue === 'fundraiserDonations' ||
        thirdDropdownValue === 'vp' ||
        thirdDropdownValue === 'rental'
        ? isVp
          ? vpCsvData
          : csvData
        : [filterData],
      csvColumns,
      csvHeaderMapping,
      fileName,
    )
  }

  const columns = [
    {
      title: 'Donor Name',
      dataIndex: 'DonorName',
      key: 'DonorName',
    },
    {
      title: 'Donor Email',
      dataIndex: 'DonorEmail',
      key: 'DonorEmail',
    },
    {
      title: 'Donor Phone Number',
      dataIndex: 'DonorPhoneNumber',
      key: 'DonorPhoneNumber',
    },
    {
      title: 'Gross',
      dataIndex: 'GrossAmount',
      key: 'GrossAmount',
      className: 'ant-table-row-cell-break-word text-right',
      render: (text) => formatAmount(text),
    },
    {
      title: 'Net',
      dataIndex: 'NetAmount',
      key: 'NetAmount',
      className: 'ant-table-row-cell-break-word text-right',
      render: (text) => formatAmount(text),
    },
    {
      title: 'Date',
      dataIndex: 'LastDonationDates',
      key: 'LastDonationDates',
      render: (date) => formateDateTimeToLocal(date, 'date'),
    },
    {
      title: 'Time',
      dataIndex: 'LastDonationDates',
      key: 'LastDonationDates',
      render: (time) => formateDateTimeToLocal(time, 'time'),
      sorter: (a, b) => {
        const timeA = new Date(a.LastDonationDates)
        const timeB = new Date(b.LastDonationDates)
        return timeB - timeA
      },
      sortDirections: ['descend', 'ascend'],
    },
  ]

  const csvColumns = [
    'DonorName',
    'DonorEmail',
    'DonorPhoneNumber',
    'GrossAmount',
    'NetAmount',
    'LastDonationDate',
    'LastDonationTime',
  ]
  const csvHeaderMapping = {
    DonorName: 'Name',
    DonorEmail: 'Email',
    DonorPhoneNumber: 'Phone Number',
    GrossAmount: 'Gross',
    NetAmount: 'Net',
    LastDonationDate: 'Date',
    LastDonationTime: 'Time',
  }

  return (
    <>
      <div
        style={{
          //   background: 'linear-gradient(135deg, #a1c4fd 0%, #c2e9fb 100%)', // This is a blue gradient, you can customize the colors
          textAlign: 'left',
          padding: '20px',
          borderRadius: '4px 4px 0 0',
          boxShadow: '0 2px 8px #f0f1f2', // This adds a subtle shadow; you can remove it if you prefer flat design
        }}
      >
        <div className="t-flex t-space-x-4">
          <IoMdArrowRoundBack
            fontSize="1.5rem"
            className="t-cursor-pointer t-min-w-[1.5rem] t-mt-2"
            id="back"
            onClick={() => history.push('/reports')}
          />
          <h2
            style={{
              color: '#003366',
              margin: 0,
              padding: 0,
              fontWeight: 'bold',
              fontSize: 'x-large',
            }}
          >
            Detailed Report
          </h2>
        </div>
      </div>
      <Card className="header-solid" bordered={false}>
        <div className="t-pl-6 t-mb-4 t-w-full">
          <h3 className="t-text-lg t-font-semibold">Choose Your Report</h3>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            marginBottom: 20,
            marginLeft: '2%',
          }}
        >
          {/* First Dropdown with Label */}
          <div
            className="t-flex sm:t-flex-row t-flex-col"
            style={{
              alignItems: 'center',
              marginRight: 30,
              marginTop: 8,
            }}
          >
            <span style={{ marginRight: 5, width: '100%' }}>
              <b>Select Type:</b>
            </span>
            <Select
              placeholder="Select Component"
              onChange={onFirstDropdownChange}
              style={{ width: '100%' }}
              value={firstDropdownValue}
              dropdownMatchSelectWidth={false} // This allows the dropdown to have a different width
              dropdownStyle={{ minWidth: 200 }} // Set a minimum width for the dropdown menu content
              disabled={loading}
            >
              <Option key={1} value="OrgDonation">
                Org Donations
              </Option>
              <Option key={9} value="fundraiser">
                Fundraisers{' '}
              </Option>
              <Option key={2} value="event">
                Events{' '}
              </Option>
              <Option key={3} value="campaign">
                Campaign
              </Option>
              <Option key={4} value="programs">
                Programs
              </Option>
              <Option key={5} value="class">
                Classes
              </Option>
              <Option key={6} value="services">
                Services
              </Option>
              <Option key={7} value="vp">
                Volunteer Opportunties
              </Option>
              <Option key={8} value="rentals">
                Rentals
              </Option>
            </Select>
          </div>

          {/* Second Dropdown (Conditionally Rendered) */}
          {firstDropdownValue !== 'OrgDonation' && (
            <div
              className="t-flex sm:t-flex-row t-flex-col"
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: 10,
                marginTop: 8,
              }}
            >
              <span style={{ marginRight: 5, width: '100%' }}>
                <b>
                  Select{' '}
                  {filterCampaingTitle === 'Vp'
                    ? 'Volunteer Opportunities'
                    : filterCampaingTitle}
                  :
                </b>
              </span>
              <Tooltip
                title="You can search in this dropdown"
                visible={showSecondDropdownTooltip}
              >
                <Select
                  showSearch
                  placeholder={`Select a ${
                    filterCampaingTitle === 'Vp'
                      ? 'Volunteer Opportunities'
                      : filterCampaingTitle
                  } Title`}
                  onChange={onSecondDropdownChange}
                  onSearch={handleSecondDropdownSearch}
                  value={secondDropdownValue}
                  style={{ width: '100%' }}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ minWidth: 300 }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {secondDropdownData.map((item) => (
                    <Option
                      key={
                        firstDropdownValue === 'programs' ||
                        firstDropdownValue === 'class'
                          ? item?.ProgramId
                          : firstDropdownValue === 'services'
                          ? item?.ServiceId
                          : firstDropdownValue === 'fundraiser'
                          ? item?.iD
                          : item?.Id ?? item.id
                      }
                      value={item?.Title || item?.title}
                    >
                      {item?.Title || item?.title}
                    </Option>
                  ))}
                </Select>
              </Tooltip>
            </div>
          )}

          {/* Third Dropdown with Label */}
          {secondDropdownValue && (
            <div
              className="t-flex sm:t-flex-row t-flex-col"
              style={{
                alignItems: 'center',
                marginRight: 30,
                marginTop: 8,
              }}
            >
              <span style={{ marginRight: 5, width: '100%' }}>
                <b>Select Report Type:</b>
              </span>
              <Select
                placeholder="Select Report Type"
                onChange={onThirdDropdownChange}
                value={thirdDropdownValue}
                style={{ width: '100%' }}
                dropdownMatchSelectWidth={false} // This allows the dropdown to have a different width
                dropdownStyle={{ minWidth: 300 }} // Set a minimum width for the dropdown menu content
              >
                {firstDropdownValue === 'rentals' ? (
                  <Option key={1} value="rental">
                    {`Performance ${
                      firstDropdownValue.charAt(0).toUpperCase() +
                      firstDropdownValue.slice(1)
                    } Detail`}
                  </Option>
                ) : firstDropdownValue === 'vp' ? (
                  <Option key={1} value="vp">
                    Performance Detail Volunteer Opportunties
                  </Option>
                ) : firstDropdownValue === 'programs' ||
                  firstDropdownValue === 'class' ||
                  firstDropdownValue === 'services' ? (
                  <Option key={1} value="program-service-detail">
                    {`Performance ${
                      firstDropdownValue.charAt(0).toUpperCase() +
                      firstDropdownValue.slice(1)
                    } Detail`}
                  </Option>
                ) : firstDropdownValue === 'fundraiser' ? (
                  <Option key={1} value="fundraiserDonations">
                    Donor Contributions Report
                  </Option>
                ) : (
                  <>
                    <Option key={1} value="summary">
                      Performance Summary Report
                    </Option>
                    <Option key={2} value="details">
                      Performance Details Report
                    </Option>
                    <Option key={3} value="statistics">
                      Event Statistics Report
                    </Option>
                    {firstDropdownValue === 'event' && (
                      <Option key={4} value="pledges">
                        Pledge Commitments Report
                      </Option>
                    )}
                    <Option key={5} value="donations">
                      Donor Contributions Report
                    </Option>
                    <Option key={6} value="donationStats">
                      Donation Statistics Report
                    </Option>
                  </>
                )}
              </Select>
            </div>
          )}
        </div>
      </Card>
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <Card
          style={{ marginTop: 30 }}
          className="header-solid"
          bordered={false}
          title={
            <div className="t-flex t-flex-wrap t-justify-between">
              <div className="t-flex t-space-x-4">
                <IoMdArrowRoundBack
                  fontSize="1.5rem"
                  className="t-cursor-pointer t-min-w-[1.5rem]"
                  id="back"
                  onClick={() => history.push('/reports')}
                />
                {showFilterResults ? (
                  ''
                ) : (
                  <h5 className="font-semibold">
                    {eventName
                      ? `${eventName ?? ''} - ${reportTypeName ?? ''}`
                      : null}
                  </h5>
                )}
              </div>
              {showDownloadOption && (
                <div className="t-flex t-justify-end t-items-center t-space-x-2">
                  <Tooltip title="Download CSV">
                    <MdOutlineSimCardDownload
                      fontSize="1.7rem"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={donwloadCSV}
                    />
                  </Tooltip>
                </div>
              )}

              {firstDropdownValue === 'OrgDonation' && (
                <div className="t-flex t-justify-end t-items-center t-space-x-2">
                  <Tooltip title="Download CSV">
                    <MdOutlineSimCardDownload
                      fontSize="1.7rem"
                      id="download"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() =>
                        downloadCSV(
                          orgDonations,
                          csvColumns,
                          csvHeaderMapping,
                          'Org Donation Report',
                        )
                      }
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          }
        >
          <ConditionalRenderer
            key={selectedSecondDropdownValue}
            condition={thirdDropdownValue}
            id={secondDropdownValueId}
            name={secondDropdownValue}
            type={
              firstDropdownValue?.charAt(0).toUpperCase() +
              firstDropdownValue?.slice(1)
            }
            setValue={setCsvData}
          />
          {/* {showFilterResults ? (
            <Result
              icon={<FilterOutlined />}
              title="Choose Filters to Display the Report"
            />
          ) : (
           
          )} */}
          {firstDropdownValue === 'OrgDonation' ? (
            <Table
              dataSource={orgDonations}
              columns={columns}
              loading={loading}
              pagination={false}
            />
          ) : null}
        </Card>
      </Skeleton>
    </>
  )
}

export { ComponentPerformanceForOrganization }

import React, { useEffect } from 'react'
import { Card, Col, Row, Dropdown, Menu, Skeleton, Tag, Tooltip } from 'antd'
import { IoIosArrowDropdownCircle } from 'react-icons/io'
import { useDispatch } from 'react-redux'
// import { GrView } from 'react-icons/gr'
import { useHistory } from 'react-router-dom'
import {
  changeBusinessServiceRequestStatus,
  getUserServiceRequests,
} from 'store/businessSlice/businessActions'
import { useBusinessSelector } from 'store/businessSlice/businessReducer'
import { config } from 'configs'
import { formatAmount, timeInAgo } from 'utils'

const UserServiceRequests = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { loading, userServiceRequests } = useBusinessSelector()

  useEffect(() => {
    dispatch(getUserServiceRequests())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onAcceptClick = (Id) => {
    dispatch(changeBusinessServiceRequestStatus(Id, 'Accepted'))
  }

  const onRejectClick = (Id) => {
    dispatch(changeBusinessServiceRequestStatus(Id, 'Rejected'))
  }

  // const onViewProgramClick = (programId) => {
  //   dispatch(programActions.setProgram(null))
  //   history.push(`/programs/${programId}`)
  // }

  const customMenu = (Id) => (
    <Menu>
      <Menu.Item id="counterOffer" key={1} onClick={() => onAcceptClick(Id)}>
        Accept Offer
      </Menu.Item>
      <Menu.Item id="rejectInvite" key={2} onClick={() => onRejectClick(Id)}>
        Reject Offer
      </Menu.Item>
    </Menu>
  )

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0 t-text-2xl">My Service Requests</h6>
          <h6 className="font-regular">
            You have requested for following services
          </h6>
        </>,
      ]}
    >
      <div style={{ minHeight: 'calc(100vh - 250px)' }}>
        <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
          <Col span={24}>
            {userServiceRequests.length === 0 && (
              <Card className="t-h-96 t-w-full t-flex t-justify-center t-items-center">
                <div>No Service Request</div>
              </Card>
            )}
            <div id="presenterEventList">
              {userServiceRequests.map((item, index) => (
                <Card key={index} className="w-full t-my-2 t-p-2">
                  <Row gutter={[24, 0]} className="t-flex t-justify-between">
                    <div className="font-bold t-flex t-space-x-2">
                      <div>
                        You requested for following service{' '}
                        <span className="t-italic t-text-secondary-100">
                          {item?.Title}
                        </span>
                      </div>
                      <Tooltip
                        title={
                          item?.Status === 'Offered'
                            ? `You have been asked for ${formatAmount(
                                item?.Price,
                              )}`
                            : 'Request Status'
                        }
                      >
                        {item?.Status === 'Accepted' && (
                          <Tag color="blue" className="font-bold">
                            Accepted
                          </Tag>
                        )}
                        {item?.Status === 'Offered' && (
                          <Tag color="black" className="font-bold">
                            Offered
                          </Tag>
                        )}
                        {item?.Status === 'Rejected' && (
                          <Tag color="red" className="font-bold">
                            Rejected
                          </Tag>
                        )}
                        {(item?.Status === 'Pending' || !item?.Status) && (
                          <Tag color="gray" className="font-bold">
                            Pending
                          </Tag>
                        )}
                      </Tooltip>
                    </div>

                    <Dropdown overlay={customMenu(item?.Id)}>
                      {item?.Status === 'Offered' ? (
                        <IoIosArrowDropdownCircle
                          id="inviteMenu"
                          fontSize="1.5rem"
                          className="t-text-secondary-100 t-cursor-pointer"
                        />
                      ) : (
                        <>
                          {/* <Tooltip title="View">
                            <GrView
                              id="viewEvent"
                              fontSize="1.2rem"
                              className="t-text-secondary-100 t-cursor-pointer"
                              // onClick={() => onViewProgramClick(item.id)}
                            />
                          </Tooltip> */}
                          {item?.Status === 'Accepted' && (
                            <>
                              {item.PaymentStatus &&
                              item.PaymentStatus === 'Completed' ? (
                                <Tag color="green" className="font-bold">
                                  Paid
                                </Tag>
                              ) : (
                                <Tag
                                  color={config.colors.primary}
                                  className="font-bold t-cursor-pointer"
                                  onClick={() =>
                                    history.push(
                                      `/payment/business-service/${item.Id}`,
                                    )
                                  }
                                >
                                  Pay
                                </Tag>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Dropdown>
                  </Row>
                  <Row gutter={[24, 0]}>
                    <div>
                      {/* {item?.Status === 'Offered' && (
                        <div className="font-bold">
                          You have been asked for {formatAmount(item?.Price)}{' '}
                          for {item?.Title} with{' '}
                          <Tooltip title={item?.OfferNotes}>
                            <b>Note</b>
                          </Tooltip>
                        </div>
                      )} */}
                      {item?.CreatedDate && timeInAgo(item.CreatedDate)}
                    </div>
                  </Row>
                </Card>
              ))}
            </div>
          </Col>
        </Skeleton>
      </div>
    </Card>
  )
}

export { UserServiceRequests }

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Stepper from '../Stepper/Stepper'
import ApexCharts from 'react-apexcharts'
import { formatAmountWithOutDecimal } from 'utils'

const TodayDonations = ({ donationData, netGross }) => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: 'Web Donations',
        type: 'line',
        data: [], // Line data
        color: '#00E396', // Line color
      },
      {
        name: 'Kiosk Donations',
        type: 'line',
        data: [],
        color: '#FEB019',
      },
      {
        name: 'Total Donations',
        type: 'bar',
        data: [], // Bar data
        color: '#06B6D4', // Bar color
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        width: [3, 3, 0],
        curve: 'smooth',
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          dataLabels: {
            position: 'top', // position of dataLabels on top of the bars
          },
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0], // Enable data labels only for total donations (bar series)
        offsetY: -20, // Adjust position to be above the bar
        style: {
          fontSize: '12px',
          colors: ['#304758'],
          fontWeight: 'bold',
        },
        formatter(val) {
          return `${formatAmountWithOutDecimal(val.toFixed(2))}`
        },
      },
      xaxis: {
        type: 'category',
        categories: [],
      },
      yaxis: {
        labels: {
          formatter: (value) => formatAmountWithOutDecimal(value),
        },
        title: {
          text: 'Amount (USD)',
        },
      },
      tooltip: {
        y: {
          formatter: (value) => formatAmountWithOutDecimal(value),
        },
      },
      colors: ['#06B6D4', '#00E396', '#FEB019'],
    },
  })

  useEffect(() => {
    if (
      !donationData ||
      !donationData?.donations?.sevenDays ||
      donationData?.donations?.sevenDays?.list?.length === 0
    ) {
      return
    }

    const today = new Date()
    const allDays = []

    // Generate days of the week starting from the current day
    for (let i = 0; i < 7; i++) {
      const nextDay = new Date(today)
      nextDay.setDate(today.getDate() + i)
      allDays.push(nextDay.toLocaleString('en-US', { weekday: 'short' }))
    }

    // Initialize arrays to store data for each day
    const webDataByDay = Array(7).fill(0)
    const kioskDataByDay = Array(7).fill(0)

    const cumulativeData = allDays.map((day, index) => {
      const donationsForDay = donationData?.donations?.sevenDays?.list.filter(
        (d) =>
          new Date(d.PaymentDate).toLocaleString('en-US', {
            weekday: 'short',
          }) === day,
      )
      const totalRaisedAmount = donationsForDay.reduce(
        (acc, d) => acc + +(netGross ? d.NetAmount : d.GrossAmount),
        0,
      )
      donationsForDay.forEach((donation) => {
        if (donation.PaymentMethod === 'Contactless') {
          kioskDataByDay[index] += +(netGross
            ? donation.NetAmount
            : donation.GrossAmount)
        } else {
          webDataByDay[index] += +(netGross
            ? donation.NetAmount
            : donation.GrossAmount)
        }
      })

      return {
        day,
        totalRaisedAmount,
        // PaymentMethod: paymentMethod,
      }
    })

    // Update state with dynamic data
    setChartData((prevChartData) => ({
      ...prevChartData,
      series: [
        {
          name: 'Web Donations',
          type: 'line',
          data: webDataByDay,
          color: '#00E396',
        },
        {
          name: 'Kiosk Donations',
          type: 'line',
          data: kioskDataByDay,
          color: '#FEB019',
        },
        {
          name: 'Total Donations',
          type: 'bar',
          data: cumulativeData.map((data) => data.totalRaisedAmount),
          color: '#06B6D4',
        },
      ],
      options: {
        ...prevChartData.options,
        xaxis: {
          type: 'category',
          categories: allDays,
        },
      },
    }))
  }, [donationData, netGross])

  const [chartData1, setChartData1] = useState({
    series: [
      {
        name: 'Web Donations',
        type: 'line',
        data: [], // Line data
        color: '#00E396', // Line color
      },
      {
        name: 'Kiosk Donations',
        type: 'line',
        data: [], // Line data
        color: '#FEB019', // Line color
      },
      {
        name: 'Total YTD Donations',
        type: 'bar',
        data: [], // Bar data
        color: '#06B6D4', // Bar color
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        width: [3, 3, 0],
        curve: 'smooth',
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          dataLabels: {
            position: 'top', // position of dataLabels on top of the bars
          },
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0], // Enable data labels only for total donations (bar series)
        offsetY: -20, // Adjust position to be above the bar
        style: {
          fontSize: '12px',
          colors: ['#304758'],
          fontWeight: 'bold',
        },
        formatter(val) {
          return `${formatAmountWithOutDecimal(val.toFixed(2))}`
        },
      },
      xaxis: {
        type: 'category',
        categories: [],
      },
      yaxis: {
        labels: {
          formatter: (value) => formatAmountWithOutDecimal(value),
        },
        title: {
          text: 'Amount (USD)',
        },
      },
      tooltip: {
        y: {
          formatter: (value) => formatAmountWithOutDecimal(value),
        },
      },
      colors: ['#06B6D4', '#00E396', '#FEB019'],
    },
  })

  useEffect(() => {
    if (
      !donationData ||
      !donationData?.donations?.ytd?.list ||
      donationData?.donations?.ytd?.list?.length === 0
    ) {
      return
    }

    const currentDate = new Date()
    const currentMonthIndex = currentDate.getMonth()

    const allMonth = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    // Initialize arrays to store data for each month
    const webDataByMonth = Array(currentMonthIndex + 1).fill(0)
    const kioskDataByMonth = Array(currentMonthIndex + 1).fill(0)

    const cumulativeData = allMonth
      .map((month, index) => {
        if (index > currentMonthIndex) {
          return null // Skip future months
        }

        const donationsForMonth = donationData?.donations?.ytd?.list?.filter(
          (d) => {
            const donationMonth = new Date(d.PaymentDate).getMonth() // Extract month as a zero-based value
            return donationMonth === index // Compare with the index of the current month
          },
        )

        const totalRaisedAmount = donationsForMonth.reduce(
          (acc, d) => acc + +(netGross ? d.NetAmount : d.GrossAmount),
          0,
        )

        donationsForMonth.forEach((donation) => {
          if (donation.PaymentMethod === 'Contactless') {
            kioskDataByMonth[index] += +(netGross
              ? donation.NetAmount
              : donation.GrossAmount)
          } else {
            webDataByMonth[index] += +(netGross
              ? donation.NetAmount
              : donation.GrossAmount)
          }
        })

        return {
          month: month.slice(0, 3),
          totalRaisedAmount,
        }
      })
      .filter((data) => data !== null)

    setChartData1((prevChartData) => ({
      ...prevChartData,
      series: [
        {
          name: 'Web Donations',
          type: 'line',
          data: webDataByMonth,
          color: '#00E396',
        },
        {
          name: 'Kiosk Donations',
          type: 'line',
          data: kioskDataByMonth,
          color: '#FEB019',
        },
        {
          name: 'Total YTD Donations',
          type: 'bar',
          data: cumulativeData.map((data) => data.totalRaisedAmount),
          color: '#06B6D4',
        },
      ],
      options: {
        ...prevChartData.options,
        xaxis: {
          type: 'category',
          categories: cumulativeData.map((data) => data.month),
        },
        plotOptions: {
          bar: {
            columnWidth:
              currentMonthIndex + 1 >= 12
                ? '100%'
                : currentMonthIndex + 1 >= 9
                ? '80%'
                : currentMonthIndex + 1 >= 6
                ? '50%'
                : currentMonthIndex + 1 >= 3
                ? '20%'
                : '20%',
          },
        },
      },
    }))
  }, [donationData, netGross])

  return (
    <div className="">
      <div className="t-grid t-grid-cols-1 md:t-grid-cols-2 t-gap-4 t-mt-8 t-px-2">
        <div className="t-rounded-lg t-justify-between t-bg-white t-border t-border-gray-300">
          <h1 className="t-pt-4 t-pb-2 t-px-8 t-text-2xl t-font-bold t-text-gray-900">
            Most Recent Transactions
          </h1>
          <div className="t-pl-2 sm:t-pl-4 md:t-pl-6 t-pt-10 t-pb-10">
            {donationData?.lastDonations?.length <= 0 ? (
              <div className="t-text-center t-font-normal t-text-lg">
                Donation Not Available!
              </div>
            ) : (
              donationData?.lastDonations?.map((donation, index) => (
                <Stepper donation={donation} key={index} netGross={netGross} />
              ))
            )}
          </div>
        </div>
        <div className="t-rounded-lg t-max-h-[545px] t-bg-white t-border t-border-gray-300">
          <h1 className="t-py-4 t-px-8 t-text-2xl t-font-bold t-text-gray-900">
            Last 7 Days
          </h1>
          <div className="t-mt-4 t-m-3">
            <ApexCharts
              options={chartData.options}
              series={chartData.series}
              type="line"
              height={400}
            />
          </div>
        </div>
      </div>

      {/* Last div */}
      <div className="t-border t-border-gray-300 t-rounded-lg t-mt-8 t-mb-6 t-max-h-[520px] t-mx-2 t-bg-white">
        <h1 className="t-py-4 t-px-8 t-text-2xl t-font-bold t-text-gray-900">
          YTD Donations
        </h1>
        <div className="t-m-3">
          <ApexCharts
            options={chartData1.options}
            series={chartData1.series}
            type="line"
            height={400}
          />
        </div>
      </div>
    </div>
  )
}

export default TodayDonations

import React, { useState, useEffect } from 'react'
import { Button, Form, Select, Checkbox, Modal } from 'antd'
import { siginValidation } from 'validations'
import MaskedInput from 'antd-mask-input'
import { MaskPattern } from 'utils'
import { TermsAndConditions } from './TermsAndConditions'

const LoginForm = ({ loading, onSubmit }) => {
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [termsChecked, setTermsChecked] = useState(true)

  useEffect(() => {
    form.setFieldsValue({ termsAndCondition: true })
  }, [form])

  const handleInputChange = (value) => {
    form.setFieldsValue({ phoneNumber: value })
  }

  const onFinish = (values) => {
    onSubmit({
      ...values,
      phoneNumber: values?.phoneNumber?.replace(/\D/g, ''),
    })
  }

  const handleTermsChange = (e) => {
    setTermsChecked(e.target.checked)
    form.setFieldsValue({ termsAndCondition: e.target.checked })
  }

  return (
    <Form
      form={form}
      name="signIn"
      initialValues={{ countryCode: '+1', termsAndCondition: true }} // Set the default value to true
      onFinish={onFinish}
      className="row-col"
    >
      <Form.Item name="countryCode" rules={[siginValidation]}>
        <Select placeholder="Select Country Code" style={{ width: '100%' }}>
          <Select.Option value="+1">United States/Canada (+1)</Select.Option>
          <Select.Option value="+54">Argentina (+54)</Select.Option>
          <Select.Option value="+92">Pakistan (+92)</Select.Option>
          <Select.Option value="+55">Brazil (+55)</Select.Option>
          <Select.Option value="+58">
            Venezuela, Bolivarian Republic of Venezuela (+58)
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="phoneNumber" rules={[siginValidation]}>
        <MaskedInput
          inputMode="numeric"
          mask={MaskPattern}
          onChange={(e) => {
            handleInputChange(e.maskedValue)
          }}
        />
      </Form.Item>
      <p className="text-right t-font-semibold">
        * Message And Data Rates May Apply
      </p>
      <Form.Item
        name="termsAndCondition"
        valuePropName="checked"
        // initialValue={true}
        rules={[siginValidation]}
      >
        <Checkbox checked={termsChecked} onChange={handleTermsChange}>
          <h6 className="t-text-sm font-regular text-muted t-my-2" level={5}>
            You hereby provide your consent to our
            {'  '}
            <span
              className="font-bold t-text-secondary-100 hover:t-underline t-cursor-pointer"
              onClick={() => setIsModalVisible(true)}
            >
              Terms and Conditions
            </span>
          </h6>
        </Checkbox>
        <Modal
          title={<p className="t-text-secondary-100">Terms Of Use Policy</p>}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <TermsAndConditions />
        </Modal>
      </Form.Item>
      <Form.Item>
        <Button
          style={{ width: '100%' }}
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Continue
        </Button>
      </Form.Item>
    </Form>
  )
}

export { LoginForm }

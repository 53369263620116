import * as Yup from 'yup'

const numberValidation = new RegExp('[0-9]|,')

const schema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  amount: Yup.string()
    .required('Amount is required')
    .matches(numberValidation, 'Invalid amount')
    .test(
      'is-greater-than-zero',
      'Amount must be greater than zero.',
      (value) => {
        const parsedValue = parseFloat(value.replace(/,/g, ''))
        return parsedValue > 0
      },
    ),
  enableRecurring: Yup.boolean().required('Field is Required'),
  intervalValue: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required('Duration is Required'),
  paymentCategory: Yup.string().nullable(),
  recurringFrequency: Yup.string().nullable(),
  description: Yup.string()
    .required('Description is required')
    .min(10, 'Description should be atleast 10 character'),
  organizationId: Yup.string().required('Organization id is required'),
  organizationName: Yup.string().required('Organization name is required'),
  createdBy: Yup.string().required('Field is required'),
  startDate: Yup.date()
    .typeError('Invalid Date')
    .required('Start Date is required'),
  endDate: Yup.date().typeError('Invalid Date').nullable(),
  recaptcha: Yup.string()
    .typeError('Please complete recaptcha to proceed')
    .required('Please complete recaptcha to proceed'),
})

const schemaEdit = schema.shape({
  endDate: Yup.date()
    .min(new Date(), 'End date must be after today')
    .typeError('Invalid Date')
    .nullable(),
})

const clickTapFundraiserValidationEdit = {
  async validator({ field }, value) {
    await schemaEdit.validateSyncAt(field, { [field]: value })
  },
}

const clickTapFundraiserValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { clickTapFundraiserValidation, clickTapFundraiserValidationEdit }

import React from 'react'
import { Input, Form, Col, Select, Radio, Switch } from 'antd'
import { zakaatValidation } from 'validations'
import { AmountInput } from 'components/elements'
import { MaskedInput } from 'antd-mask-input'
import { MaskPattern } from 'utils'

const ZakaatForm = ({ form }) => {
  Form.useWatch('HaveChildren', form)
  Form.useWatch('EmploymentStatus', form)
  const haveChildrenValue = form.getFieldValue('HaveChildren')
  const isNotUnemployed =
    form.getFieldValue('EmploymentStatus') &&
    form.getFieldValue('EmploymentStatus') !== 'Unemployed'
  return (
    <>
      <Col span={24}>
        <Form.Item
          name="SSN"
          label="Last four of SSN*"
          colon={false}
          rules={[zakaatValidation]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="PrimaryResidence"
          label="Primary Residence*"
          colon={false}
          rules={[zakaatValidation]}
        >
          <Select placeholder="Select ..." style={{ width: '100%' }}>
            {['Own', 'Rent', 'Hotel/Motel', 'Shelter', 'Homeless', 'Other'].map(
              (item, index) => (
                <Select.Option value={item} key={index}>
                  {item}
                </Select.Option>
              ),
            )}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="Age"
          label="Age*"
          colon={false}
          rules={[zakaatValidation]}
        >
          <Input type="number" inputMode="numeric" pattern="[0-9]*" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="Gender" label="Gender*" colon={false}>
          <Radio.Group>
            <Radio value="Male">Male</Radio>
            <Radio value="Female">Female</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="MaritalStatus"
          label="Marital Status*"
          colon={false}
          rules={[zakaatValidation]}
        >
          <Select placeholder="Select ..." style={{ width: '100%' }}>
            {['Single', 'Married', 'Separated', 'Divorced', 'Widowed'].map(
              (item, index) => (
                <Select.Option value={item} key={index}>
                  {item}
                </Select.Option>
              ),
            )}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <div className="horizontal-override">
          <Form.Item
            name="HaveChildren"
            label="Have Children"
            colon={false}
            rules={[zakaatValidation]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Switch checked={haveChildrenValue} />
          </Form.Item>
        </div>
      </Col>
      {haveChildrenValue && (
        <>
          <Col span={24}>
            <Form.Item
              name="NumberOfChildren"
              label="Number of Children*"
              colon={false}
              rules={[zakaatValidation]}
            >
              <Input type="number" inputMode="numeric" pattern="[0-9]*" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="LiveWithParent*"
              label="Are Children Living With You?"
              colon={false}
            >
              <Radio.Group defaultValue="Yes">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </>
      )}
      <Col span={24}>
        <Form.Item
          name="EmploymentStatus"
          label="Employment Status*"
          colon={false}
          rules={[zakaatValidation]}
        >
          <Select placeholder="Select ..." style={{ width: '100%' }}>
            {[
              'Employed-Fulltime',
              'Employed-Parttime',
              'Self Employed',
              'Unemployed',
            ].map((item, index) => (
              <Select.Option value={item} key={index}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      {isNotUnemployed && (
        <>
          <Col span={24}>
            <Form.Item
              name="JobTitle"
              label="Job Title*"
              colon={false}
              rules={[zakaatValidation]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="EmployerName"
              label="Employer Name*"
              colon={false}
              rules={[zakaatValidation]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="EmployerAddress"
              label="Employer Address*"
              colon={false}
              rules={[zakaatValidation]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="EmployerPhoneNumber"
              label="Employer PhoneNumber*"
              colon={false}
              rules={[zakaatValidation]}
            >
              <MaskedInput
                inputMode="numeric"
                mask={MaskPattern}
                onChange={(e) => {
                  form.setFieldsValue({ EmployerPhoneNumber: e.maskedValue })
                }}
              />
            </Form.Item>
          </Col>
        </>
      )}
      <Col span={24}>
        <Form.Item
          name="AnnualHouseholdIncome"
          label="Annual Household Income*"
          colon={false}
          rules={[zakaatValidation]}
        >
          <AmountInput
            onChange={(value) =>
              form.setFieldsValue({
                AnnualHouseholdIncome: value,
              })
            }
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="HealthInsuranceInformation"
          label="Health Insurance Information*"
          colon={false}
          rules={[zakaatValidation]}
        >
          <Select placeholder="Select ..." style={{ width: '100%' }}>
            {[
              'Employer Offered Insurance',
              'Self-Insured',
              'Medicare/MedicAid',
              'Uninsured',
            ].map((item, index) => (
              <Select.Option value={item} key={index}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="HighestLevelOfEducationCompleted"
          label="Highest Level of Education Completed*"
          colon={false}
          rules={[zakaatValidation]}
        >
          <Select placeholder="Select ..." style={{ width: '100%' }}>
            {[
              'College Graduate',
              'High School Grad or GED',
              'Vocational/Trade School Courses Completed',
              'Little Or No Education',
            ].map((item, index) => (
              <Select.Option value={item} key={index}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="CurrentTransportationMeans"
          label="Current Transportation Means*"
          colon={false}
          rules={[zakaatValidation]}
        >
          <Select placeholder="Select ..." style={{ width: '100%' }}>
            {[
              'Automobile',
              'Motorcycle/Cycle',
              'Public Transportation',
              'Taxi',
              'None',
            ].map((item, index) => (
              <Select.Option value={item} key={index}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="MonthlyHouseholdExpenseDetails"
          label="Monthly Household Expense Details*"
          colon={false}
          rules={[zakaatValidation]}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="CurrentAssets"
          label="List All Current Assets"
          colon={false}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="PublicOrPrivateDebts"
          label="List all public or private debts"
          colon={false}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="AmountReceivedInLast12Months"
          label="Zakaat amount or other assistance received in the last 12 months*"
          colon={false}
          rules={[zakaatValidation]}
        >
          <AmountInput
            onChange={(value) =>
              form.setFieldsValue({
                AmountReceivedInLast12Months: value,
              })
            }
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="AidCurrentlyReceived"
          label="Identify any public aid currently received*"
          colon={false}
          rules={[zakaatValidation]}
        >
          <Select placeholder="Select ..." style={{ width: '100%' }}>
            {[
              'LINK/Food Stamps',
              'Medicare/MedicAid',
              'Social Security',
              'Temp Family Assistance',
              'Subsidized/Public Housing',
              'Other',
              'None',
            ].map((item, index) => (
              <Select.Option value={item} key={index}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="JustificationForZakaatFunds"
          label="Please provide justification for requesting Zakaat funds*"
          colon={false}
          rules={[zakaatValidation]}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
        </Form.Item>
      </Col>
    </>
  )
}

export { ZakaatForm }

import React from 'react'
import TodayDonation from './TodayDonation'
import Pledges from './Pledges'
import MonthlyEarnings from './MonthlyEarning'
import Fundraisers from './Fundraisers'
import CommunityMember from './CommunityMember'

const ConditionalRenderer = ({ condition }) => {
  switch (condition) {
    case 'todayDonation':
      return <TodayDonation />
    case 'monthlyEarning':
      return <MonthlyEarnings />
    case 'communityMember':
      return <CommunityMember />
    case 'fundraiser':
      return <Fundraisers />
    case 'pledge':
      return <Pledges />

    default:
      return null // Render nothing if condition doesn't match
  }
}

export default ConditionalRenderer

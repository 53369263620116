/* eslint-disable */
import React, { useState } from 'react'
import { Row } from 'antd'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { config } from 'configs'
import moment from 'moment'
import { useRoomSelector } from 'store/roomSlice/roomReducer'
import { ViewReservation } from './ViewReservation'

const localizer = momentLocalizer(moment)

const createEvents = (data) => {
  const myevents = []
  for (let i = 0; i < data.length; i++) {
    const reservationDetail = data[i]
    let scheduleDate = moment(reservationDetail?.Date)
    if (reservationDetail?.IsRecurring) {
      for (let k = 0; k < reservationDetail?.RecurringDates.length; k++) {
        scheduleDate = moment(reservationDetail?.RecurringDates[k])

        for (let j = 0; j < reservationDetail?.Durations?.length; j++) {
          const durationDetail = reservationDetail?.Durations[j]
          const fromParts = durationDetail.from.split(':')
          let fromHour = parseInt(fromParts[0])
          const fromMinute = parseInt(fromParts[1])
          const fromAMPM = durationDetail.from.toLowerCase().includes('am')
            ? 'am'
            : 'pm'

          const toParts = durationDetail.to.split(':')
          let toHour = parseInt(toParts[0])
          const toMinute = parseInt(toParts[1])
          const toAMPM = durationDetail.to.toLowerCase().includes('am')
            ? 'am'
            : 'pm'

          if (fromHour === 12 && fromAMPM === 'am') {
            fromHour = 0
          } else if (fromHour !== 12 && fromAMPM === 'pm') {
            fromHour += 12
          }

          if (toHour === 12 && toAMPM === 'am') {
            toHour = 0
          } else if (toHour !== 12 && toAMPM === 'pm') {
            toHour += 12
          }

          const eventDetail = {
            id: reservationDetail?.Id,
            title: `${reservationDetail?.CreatorName} booked ${
              reservationDetail?.RoomName
            } (${reservationDetail?.PaymentStatus || 'Not Paid'})`,
            allDay: false,
            start: new Date(
              scheduleDate.year(),
              scheduleDate.month(),
              scheduleDate.date(),
              fromHour,
              fromMinute,
              0,
            ),
            end: new Date(
              scheduleDate.year(),
              scheduleDate.month(),
              scheduleDate.date(),
              toHour,
              toMinute,
              0,
            ),
          }
          myevents.push(eventDetail)
        }
      }
    } else {
      for (let j = 0; j < reservationDetail?.Durations?.length; j++) {
        const durationDetail = reservationDetail?.Durations[j]
        const fromParts = durationDetail.from.split(':')
        let fromHour = parseInt(fromParts[0])
        const fromMinute = parseInt(fromParts[1])
        const fromAMPM = durationDetail.from.toLowerCase().includes('am')
          ? 'am'
          : 'pm'

        const toParts = durationDetail.to.split(':')
        let toHour = parseInt(toParts[0])
        const toMinute = parseInt(toParts[1])
        const toAMPM = durationDetail.to.toLowerCase().includes('am')
          ? 'am'
          : 'pm'

        // Adjust 'from' hour
        if (fromHour === 12 && fromAMPM === 'am') {
          fromHour = 0
        } else if (fromHour !== 12 && fromAMPM === 'pm') {
          fromHour += 12
        }

        // Adjust 'to' hour
        if (toHour === 12 && toAMPM === 'am') {
          toHour = 0
        } else if (toHour !== 12 && toAMPM === 'pm') {
          toHour += 12
        }

        const eventDetail = {
          id: reservationDetail?.Id,
          title: `${reservationDetail?.CreatorName} booked ${
            reservationDetail?.RoomName
          } (${reservationDetail?.PaymentStatus || 'Not Paid'})`,
          allDay: false,
          start: new Date(
            scheduleDate.year(),
            scheduleDate.month(),
            scheduleDate.date(),
            fromHour,
            fromMinute,
            0,
          ),
          end: new Date(
            scheduleDate.year(),
            scheduleDate.month(),
            scheduleDate.date(),
            toHour,
            toMinute,
            0,
          ),
        }
        myevents.push(eventDetail)
      }
    }
  }
  return myevents
}

const ReservationCalendar = () => {
  const { reservations } = useRoomSelector()
  const acceptedReservations = reservations.filter(
    (item) =>
      item.AcceptanceStatus === 'Accepted' &&
      item.RefundRequestStatus !== 'Approved',
  )
  const formattedBookings = createEvents(acceptedReservations)

  const [viewModal, setViewModal] = useState(false)
  const [selectedReservation, setSelectedReservation] = useState(null)

  return (
    <Row gutter={[24, 0]}>
      <Calendar
        localizer={localizer}
        events={formattedBookings}
        onSelectEvent={(event) => {
          const selected = reservations.find((item) => item.Id === event.id)
          if (selected) {
            setSelectedReservation(selected)
            setViewModal(true)
          }
        }}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 600 }}
        className="t-w-full t-p-4"
        eventPropGetter={() => {
          const newStyle = {
            backgroundColor: config.colors.primary,
            color: 'white',
            borderRadius: '6px',
            border: 'none',
          }
          return {
            className: '',
            style: newStyle,
          }
        }}
      />
      <ViewReservation
        visible={viewModal}
        reservation={selectedReservation}
        setVisible={() => {
          setViewModal(!viewModal)
          setSelectedReservation(null)
        }}
      />
    </Row>
  )
}

export { ReservationCalendar }

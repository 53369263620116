import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Input,
  Popconfirm,
  Skeleton,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
  message,
} from 'antd'
import {
  getOrganizationInfo,
  getOrganizationTiers,
  updateOrganizationMembershipProgram,
  updateOrganizationMembershipProgramDescription,
} from 'store/organizationSlice/organizationActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { config } from 'configs'
import { formatAmount } from 'utils'
import { FaEdit } from 'react-icons/fa'
import { BsInfoCircleFill } from 'react-icons/bs'
import { IoMdAddCircleOutline } from 'react-icons/io'
import { UserMessageView } from 'components/elements'
import { MdDelete } from 'react-icons/md'
import { makeRequest } from 'api'
import { AddTierModal } from './AddTierModal'

const { TextArea } = Input

const OrganizationSubscriptionProgram = () => {
  const { user, organization } = useAuthSelector()
  const [fetchingOrganization, setFetchingOrganization] = useState(true)
  const [organizationData, setOrganizationData] = useState()
  const [isUpdatingOrganization, setIsUpdatingOrganization] = useState(false)
  const [fetchingTier, setFetchingTiers] = useState(true)
  const [tier, setTiers] = useState([])
  const [addTierModal, setAddTierModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [membershipDescription, setMembershipDescription] = useState()
  const [isEdit, setIsEdit] = useState(false)
  const [isUpdatingDescription, setIsUpdatingDescription] = useState(false)
  const [isDeletingId, setIsDeletingId] = useState()

  const switchValue = !!organizationData?.IsMembershipProgramEnabled

  const getOrganizationData = async ({ saveDescription = false } = {}) => {
    setFetchingOrganization(true)
    try {
      const { item } = await getOrganizationInfo(organization?.id)
      if (item) {
        setOrganizationData(item)
        if (saveDescription && item?.MembershipDescription) {
          setMembershipDescription(item?.MembershipDescription)
        }
      }
      setFetchingOrganization(false)
    } catch (error) {
      message.error(error?.response?.data?.message || 'Something went wrong')
      setFetchingOrganization(false)
    }
  }

  useEffect(() => {
    if (organization?.id) {
      getOrganizationData({ saveDescription: true })
    }
    // eslint-disable-next-line
  }, [user, organization])

  useEffect(() => {
    if (switchValue === true) {
      fetchOrganizationTiers()
    }
    // eslint-disable-next-line
  }, [switchValue])

  const changeMemebershipStatus = async (value) => {
    updateOrganizationMembershipProgram({
      organizationId: organizationData?.id,
      status: value,
      setLoading: setIsUpdatingOrganization,
      action: getOrganizationData,
    })
  }

  const fetchOrganizationTiers = async () => {
    setFetchingTiers(true)
    try {
      const response = await getOrganizationTiers(organization?.id)
      if (response?.results) {
        setTiers(response?.results)
      }
      setFetchingTiers(false)
    } catch (error) {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      setFetchingTiers(false)
    }
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'Title',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Annual Amount',
      dataIndex: 'Amount',
      className: 'ant-table-row-cell-break-word',
      render: (_, { Amount }) => formatAmount(Amount),
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, { Id }) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <FaEdit
              fontSize="1.2rem"
              className="t-text-secondary-100 t-cursor-pointer"
              onClick={() => onEdit(Id)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            {isDeletingId === Id ? (
              <Spin />
            ) : (
              <Popconfirm
                title="Are you sure you want to delete this tier?"
                onConfirm={() => onDeleteTier(Id)}
              >
                <MdDelete
                  fontSize="1.3rem"
                  className="t-text-secondary-100 t-cursor-pointer"
                />
              </Popconfirm>
            )}
          </Tooltip>
        </Space>
      ),
    },
  ]

  const onEdit = (Id) => {
    const formatedData = tier.find((item) => item.Id === Id)
    setSelectedItem(formatedData)
    setAddTierModal(true)
  }

  const onDeleteTier = (Id) => {
    setIsDeletingId(Id)
    const url = `/component/organization/delete-membership-program-tier/${Id}`
    makeRequest('put', url)
      .then(() => {
        setIsDeletingId()
        message.success('Tier Deleted Successfully')
        fetchOrganizationTiers()
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
        setIsDeletingId()
      })
  }

  const updateDescriptionAction = () => {
    setIsEdit(false)
    getOrganizationData({ saveDescription: true })
  }

  const onUpdateDescription = async () => {
    if (membershipDescription?.length > 0) {
      updateOrganizationMembershipProgramDescription({
        organizationId: organizationData?.id,
        description: membershipDescription,
        setLoading: setIsUpdatingDescription,
        action: updateDescriptionAction,
      })
    } else {
      message.destroy()
      message.error('Description is required')
    }
  }

  const defaultCategory = organizationData?.PaymentDefaultAccount
  const isStripeConnected = !!defaultCategory?.stripeID
  const isPaypalConnected = !!defaultCategory?.paypalID
  const isDefaultAccountConnected = isStripeConnected || isPaypalConnected
  const isB2BProgramEnabled = !!switchValue
  return (
    <Card
      className="header-solid"
      bordered={false}
      title={
        <div className="t-flex t-items-center t-space-x-2">
          <h6 className="mb-0 t-text-secondary-100 t-font-bold t-text-xl">
            B2B membership program
          </h6>
          <Tooltip
            title={
              <>
                <div>Membership Program Steps:</div>
                <div>Step 1 - Add Program Description</div>
                <div>Step 2 - Define Membership Tiers</div>
                <div>Step 3 - Upload / Invite Businesses</div>
              </>
            }
          >
            <BsInfoCircleFill className="t-text-secondary-100 t-cursor-pointer" />
          </Tooltip>
          <Switch
            title="Is Membership Program Enabled"
            loading={isUpdatingOrganization || fetchingOrganization}
            disabled={!isDefaultAccountConnected && !isB2BProgramEnabled}
            checkedChildren="On"
            unCheckedChildren="Off"
            checked={isB2BProgramEnabled}
            onChange={(value) => changeMemebershipStatus(value)}
          />
        </div>
      }
    >
      <Skeleton
        loading={fetchingOrganization || isUpdatingOrganization}
        active
        paragraph={{ rows: 8 }}
      >
        {isDefaultAccountConnected ? (
          <>
            {isB2BProgramEnabled && (
              <>
                <Card
                  className="header-solid t-mb-2"
                  bordered={false}
                  title={
                    <div className="t-flex t-items-center t-space-x-2">
                      <h6 className="mb-0 t-text-secondary-100 t-font-bold">
                        Membership Description
                      </h6>
                      <>
                        {!isEdit && (
                          <FaEdit
                            className="t-cursor-pointer t-text-secondary-100"
                            style={{ fontSize: '1.3rem' }}
                            onClick={() => setIsEdit(true)}
                          />
                        )}
                      </>
                    </div>
                  }
                  bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
                >
                  <>
                    {isEdit ? (
                      <>
                        <TextArea
                          placeholder="Enter Description"
                          autoSize={{ minRows: 2, maxRows: 3 }}
                          value={membershipDescription || ''}
                          onChange={(e) => {
                            const { value } = e.target
                            if (value?.length < 500) {
                              setMembershipDescription(value)
                            } else {
                              message.destroy()
                              message.error('500 Characters limit reached')
                            }
                          }}
                        />
                        <div className="t-flex t-justify-end t-items-center t-space-x-1 t-mt-2">
                          <Button
                            className="px-25"
                            onClick={() => {
                              setMembershipDescription(
                                organizationData?.MembershipDescription,
                              )
                              setIsEdit(false)
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            loading={isUpdatingDescription}
                            type="primary"
                            className="px-25"
                            onClick={() => onUpdateDescription()}
                          >
                            Update
                          </Button>
                        </div>
                      </>
                    ) : (
                      <p className="text-dark">
                        {membershipDescription || 'No description added yet'}
                      </p>
                    )}
                  </>
                </Card>
                <Card
                  className="header-solid"
                  bordered={false}
                  title={
                    <div className="t-flex t-items-center t-space-x-2">
                      <h6 className="mb-0 t-text-secondary-100 t-font-bold">
                        Membership Tiers
                      </h6>
                      <>
                        {switchValue && (
                          <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
                            {tier?.length < 10 ? (
                              <>
                                {membershipDescription ? (
                                  <Tooltip title="Add Membership Tier">
                                    <IoMdAddCircleOutline
                                      fontSize="1.7rem"
                                      className="t-text-secondary-100 t-cursor-pointer"
                                      onClick={() => setAddTierModal(true)}
                                    />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Add membership description first">
                                    <IoMdAddCircleOutline
                                      fontSize="1.7rem"
                                      className="t-text-secondary-100 t-opacity-50"
                                    />
                                  </Tooltip>
                                )}
                              </>
                            ) : (
                              <Tooltip title="Maximum Tier limit reached">
                                <IoMdAddCircleOutline
                                  fontSize="1.7rem"
                                  className="t-text-secondary-100 t-opacity-50"
                                />
                              </Tooltip>
                            )}
                          </div>
                        )}
                      </>
                    </div>
                  }
                >
                  <Skeleton
                    loading={fetchingTier}
                    active
                    paragraph={{ rows: 8 }}
                  >
                    <Table
                      columns={columns}
                      dataSource={tier}
                      tableLayout="auto"
                      scroll={{ x: 500 }}
                      bordered={false}
                      pagination={false}
                    />
                  </Skeleton>
                </Card>
                <AddTierModal
                  visible={addTierModal}
                  close={() => {
                    fetchOrganizationTiers()
                    setAddTierModal(false)
                  }}
                  organizationId={organizationData?.id}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  items={tier}
                />
              </>
            )}
          </>
        ) : (
          <div className="t-h-full t-w-full t-flex t-justify-center t-items-center">
            <UserMessageView
              heading="Prerequisite"
              subHeading="Payment must be configured"
              redirectLink="/settings/manage-payments-accounts"
              redirectLinkText="Setup Payments"
            />
          </div>
        )}
      </Skeleton>
    </Card>
  )
}

export { OrganizationSubscriptionProgram }

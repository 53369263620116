import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  Descriptions,
  Button,
  Modal,
  Skeleton,
  Tooltip,
  Typography,
} from 'antd'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useDispatch } from 'react-redux'
// import {
//   addFavourite,
//   removeFavourite,
// } from 'store/favouriteSlice/favouriteActions'
import { useVolunteerSelector } from 'store/volunteerSlice/volunteerReducer'
import { MdLocationOn, MdEdit } from 'react-icons/md'
import { HiDuplicate } from 'react-icons/hi'
import {
  AiFillSchedule,
  // AiOutlineHeart,
  AiFillEye,
  AiFillEyeInvisible,
  // AiFillHeart,
} from 'react-icons/ai'
import {
  getVolunteer,
  isUserAlreadyRegisteredInVolunteer,
  updateVolunteerStatus,
} from 'store/volunteerSlice/volunteerActions'
import {
  formateDateTime,
  htmlCode,
  isMobile,
  mobileShareNavigator,
} from 'utils'
import sample from 'assets/images/sample/sample1.png'
import { ShareModal, SVGIcon } from 'components'
import { awsconfig } from 'configs'
import { useFavouriteSelector } from 'store/favouriteSlice/favouriteReducer'
import { CopyBlock, dracula } from 'react-code-blocks'
import { RegisteredVolunteer } from './RegisteredVolunteer'
import { FeedbackByVolunteer } from './FeedbackByVolunteer'
import Feedback from './Feedback'
const { Title } = Typography
const VolunteerView = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { state } = useLocation()

  const { loading, volunteer } = useVolunteerSelector()
  const { loading: favLoading } = useFavouriteSelector()
  const { user, organization } = useAuthSelector()
  const [isShare, setShare] = useState(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isUserAlreadyRegistered, setAlreadyRegistered] = useState(false)
  const [embedURLModalVisible, setEmbedURLModalVisible] = useState(false)

  const changeModal = () => setEmbedURLModalVisible(!embedURLModalVisible)
  const params = useParams()
  const checkRegisteredUserInVolunteer = async () => {
    setLoading(true)
    try {
      const isExist = await isUserAlreadyRegisteredInVolunteer(
        params?.id,
        user?.email,
      )
      if (isExist) {
        setAlreadyRegistered(true)
      }
      setLoading(false)
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }
  }

  const getVolunteerDetails = () => {
    dispatch(getVolunteer(params?.id))
  }

  useEffect(() => {
    if (user?.email) {
      checkRegisteredUserInVolunteer()
      getVolunteerDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email, params?.id])

  // const handleAddFavourite = async (id) => {
  //   const payload = {
  //     ReferenceId: id,
  //     Username: user?.id,
  //     Type: 'volunteer',
  //   }
  //   dispatch(addFavourite(payload, getVolunteerDetails))
  // }

  // const handleRemoveFavourite = async (id) => {
  //   dispatch(removeFavourite(id, getVolunteerDetails))
  // }

  const handleDuplicate = () => {
    history.push(
      `/settings/create-volunteer?id=${volunteer?.id}&duplicate=true`,
    )
  }

  const handleUpdateEvent = () => {
    dispatch(
      updateVolunteerStatus(
        volunteer?.id,
        volunteer?.ActiveInd,
        history.push('/volunteer'),
      ),
    )
  }

  // const handleRegister = (id) => {
  //   setLoading(true)
  //   const payload = {
  //     email: user?.email,
  //     phoneNumber: user?.phone,
  //     firstName: user?.firstname,
  //     lastName: user?.lastname,
  //     volunteerId: id,
  //     username: user?.id,
  //     title: volunteer?.Title,
  //     subscriber: volunteer?.CreatedBy,
  //     volunteer,
  //   }
  //   const callAction = () => {
  //     setShowFeedbackModal(true)
  //     checkRegisteredUserInVolunteer()
  //     setLoading(false)
  //   }
  //   dispatch(registerForVolunteer(payload, callAction))
  // }

  const shareVolunteer = async () => {
    if (isMobile) {
      await mobileShareNavigator(
        volunteer?.Title,
        `${window.location.origin}/registration/volunteer/${volunteer?.id}`,
      )
    } else {
      setShare(true)
    }
  }

  const volunteerLoading = loading || favLoading
  const isRegistrationFull =
    volunteer?.registeredUser >= volunteer?.AllowedRegistration
  return (
    <Row gutter={[24, 0]}>
      <Col span={24}>
        <Card
          title={[
            <div className="t-flex t-justify-between">
              <div className="t-flex t-items-center t-space-x-4">
                <Button
                  type="primary"
                  className="t-rounded-full t-flex t-items-center"
                  onClick={() =>
                    state && state.urlData
                      ? history.push({
                          pathname: state.urlData.pathname,
                          state: { key: state.urlData.activeKey },
                        })
                      : history.goBack()
                  }
                  size="small"
                >
                  <IoIosArrowRoundBack />
                  Back
                </Button>
              </div>
              <div className="t-flex t-items-center t-space-x-2 t-cursor-pointer">
                {/* <div
                  className={`${
                    volunteerLoading && 't-pointer-events-none t-opacity-50'
                  } ${!volunteer && 't-pointer-events-none t-opacity-0'}`}
                >
                  {volunteer?.isFav ? (
                    <Tooltip title="Remove Favorite">
                      <AiFillHeart
                        fontSize="1.8rem"
                        id="volunteer-remove-favourite"
                        className="t-cursor-pointer t-text-red-500"
                        onClick={() => handleRemoveFavourite(volunteer?.favId)}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Make Favorite">
                      <AiOutlineHeart
                        fontSize="1.8rem"
                        id="volunteer-make-favourite"
                        className="t-cursor-pointer t-text-secondary-100"
                        onClick={() => handleAddFavourite(volunteer?.id)}
                      />
                    </Tooltip>
                  )}
                </div> */}
                {volunteer?.OrganizationId === organization?.id && (
                  <>
                    <Tooltip title="Edit Program">
                      <MdEdit
                        fontSize="1.5rem"
                        className="t-text-secondary-100"
                        onClick={() =>
                          history.push(
                            `/settings/create-volunteer/${volunteer?.id}`,
                          )
                        }
                      />
                    </Tooltip>
                    <Tooltip title="Duplicate Volunteer">
                      <HiDuplicate
                        fontSize="1.5rem"
                        className="t-text-secondary-100"
                        onClick={() => handleDuplicate()}
                      />
                    </Tooltip>
                    {volunteer?.ActiveInd === 1 ? (
                      <Tooltip title="Make Inactive">
                        <AiFillEyeInvisible
                          fontSize="1.5rem"
                          className="t-text-secondary-100"
                          onClick={() => handleUpdateEvent()}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Make Active">
                        <AiFillEye
                          fontSize="1.5rem"
                          className="t-text-secondary-100"
                          onClick={() => handleUpdateEvent()}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
            </div>,
          ]}
          bordered={false}
          className="header-solid card-product mb-24"
        >
          <Skeleton loading={volunteerLoading} active paragraph={{ rows: 18 }}>
            {volunteer ? (
              <>
                <Row gutter={[24, 0]}>
                  <Col span={24} lg={12} xl={10} className="col-gallery mb-24">
                    <img
                      src={
                        volunteer?.Flyer?.fileName
                          ? awsconfig.bucketUrl + volunteer?.Flyer?.fileName
                          : volunteer?.organization?.BackgroundImage
                          ? awsconfig.bucketUrl +
                            volunteer?.organization?.BackgroundImage?.fileName
                          : sample
                      }
                      alt="card-2"
                      className="gallery-img shadow-3 img-contain"
                    />
                  </Col>
                  <Col span={24} xl={12} lg={{ offset: 1 }}>
                    <div className="t-mb-4">
                      <h1 className="t-text-secondary-100 t-text-2xl md:t-text-4xl t-font-bold">
                        {volunteer?.Title}
                      </h1>
                      <h1 className="t-text-secondary-100 t-text-lg md:t-text-2xl">
                        {volunteer?.organization?.Name}
                      </h1>

                      <Descriptions colon={false} className="t-my-4">
                        <Descriptions.Item
                          label={
                            <Tooltip title="Schedule">
                              <AiFillSchedule fontSize="1.5rem" />
                            </Tooltip>
                          }
                          span={3}
                        >
                          {volunteer?.Schedule ? (
                            <Tooltip title="MM-DD-YYYY hh:mm A">
                              {formateDateTime(volunteer?.Schedule)}
                            </Tooltip>
                          ) : (
                            'No Schedule Found'
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <Tooltip title="Address">
                              <MdLocationOn fontSize="1.5rem" />
                            </Tooltip>
                          }
                          span={3}
                        >
                          {volunteer?.Address1 ? volunteer?.Address1 : null}
                        </Descriptions.Item>
                      </Descriptions>

                      <Typography.Title
                        level={4}
                        className="t-text-secondary-100"
                      >
                        Description
                      </Typography.Title>
                      <Typography.Paragraph className="t-text-justify">
                        {volunteer?.Description}
                      </Typography.Paragraph>

                      {isUserAlreadyRegistered && (
                        <div className="t-mb-2">
                          <Button
                            type="primary"
                            style={{ background: 'grey', borderColor: 'grey' }}
                          >
                            Registered
                          </Button>
                        </div>
                      )}
                      {!isUserAlreadyRegistered && isRegistrationFull && (
                        <div className="t-mb-2">
                          <Button
                            type="primary"
                            style={{
                              background: 'grey',
                              borderColor: 'grey',
                            }}
                          >
                            Max Limit Reached
                          </Button>
                        </div>
                      )}
                      {!isUserAlreadyRegistered && !isRegistrationFull && (
                        <div className="t-my-4 t-space-x-2">
                          <Button
                            loading={isLoading}
                            type="primary"
                            // onClick={() => handleRegister(volunteer?.id)}
                            onClick={() =>
                              history.push(
                                `/registration/volunteer/${volunteer?.id}`,
                              )
                            }
                          >
                            Register
                          </Button>
                        </div>
                      )}
                      <div className="t-flex t-flex-col">
                        <Button
                          type="primary"
                          onClick={shareVolunteer}
                          className="t-w-[10%] t-my-2"
                        >
                          Share
                        </Button>
                        <Button
                          type="primary"
                          onClick={changeModal}
                          className="t-w-[20%] t-my-2"
                        >
                          Embed Code
                        </Button>
                      </div>
                      <div>
                        <Modal
                          title="Feedback"
                          open={showFeedbackModal}
                          onCancel={() => setShowFeedbackModal(false)}
                          footer={null}
                        >
                          <Feedback
                            id={volunteer?.id}
                            type="Volunteer"
                            close={() => setShowFeedbackModal(false)}
                          />
                        </Modal>
                      </div>
                    </div>
                  </Col>
                </Row>
                {volunteer?.OrganizationId === organization?.id && (
                  <Row gutter={[24, 0]} className="mt-24">
                    <RegisteredVolunteer
                      id={volunteer?.id}
                      name={volunteer?.Title}
                    />
                    <Col span={24}>
                      <h6 className="t-font-bold t-text-base">User Feedback</h6>
                      <FeedbackByVolunteer id={volunteer?.id} />
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <div className="ant-result ant-result-404">
                <SVGIcon />
                <div className="ant-result-title">Invalid URL</div>
                <div className="ant-result-subtitle">
                  Sorry, the page you visited does not exist.
                </div>
                <div className="ant-result-extra">
                  <Button
                    onClick={() => history.push('/volunteer')}
                    type="primary"
                    size="small"
                  >
                    Goto Volunteers
                  </Button>
                </div>
              </div>
            )}
          </Skeleton>
        </Card>
      </Col>
      <ShareModal
        url={`${window.location.origin}/registration/volunteer/${volunteer?.id}`}
        visible={isShare}
        onClose={() => setShare(false)}
      />
      <Modal
        centered
        title="Embed URL"
        width={600}
        open={embedURLModalVisible}
        onCancel={changeModal}
        footer={null}
      >
        <div>
          <div className="t-my-2">
            <Title level={4}>Copy iframe code</Title>
            <CopyBlock
              language="html"
              text={`
              <iframe
              style="width: 100%; height: 100%"
              src=${window.location.origin}/registration/volunteer/${volunteer?.id}
              title="Register through Extensia"
              ></iframe>`}
              theme={dracula}
              wrapLines
              codeBlock
            />
          </div>
          <div className="t-my-2">
            <Title level={4}>Copy code to paste in the website</Title>
            <CopyBlock
              language="html"
              text={htmlCode(
                `${window.location.origin}/registration/volunteer/${volunteer?.id}`,
                'Register',
              )}
              theme={dracula}
              wrapLines
              codeBlock
            />
          </div>
        </div>
      </Modal>
    </Row>
  )
}

export { VolunteerView }

import React, { useEffect, useRef, useState } from 'react'
import { awsconfig } from 'configs'
import { Button, Col, message } from 'antd'
import {
  PayPalButtons,
  usePayPalScriptReducer,
  PayPalScriptProvider,
} from '@paypal/react-paypal-js'
import { savePaypalPaymentLog } from 'store/paymentSlice/paymentActions'
import { calculateComission } from 'utils'

const Paypal = ({
  form,
  subscriberPaymentInfo,
  paypalId,
  onSubmit,
  donating,
}) => {
  const [loading, setLoading] = useState(false)

  return (
    <div>
      <Col span={24} className="t-p-0">
        {loading || donating ? (
          <Button
            loading={loading || donating}
            type="primary"
            className="t-w-full"
            htmlType="button"
          >
            Donating
          </Button>
        ) : (
          <>
            <PaypalProvider
              form={form}
              amount={form.getFieldValue('askedAmount')}
              setLoading={setLoading}
              subscriberPaymentInfo={subscriberPaymentInfo}
              paypalId={paypalId}
              onSubmit={onSubmit}
            />
            <PaypalProvider
              form={form}
              amount={form.getFieldValue('askedAmount')}
              setLoading={setLoading}
              isVenmo
              subscriberPaymentInfo={subscriberPaymentInfo}
              paypalId={paypalId}
              onSubmit={onSubmit}
            />
          </>
        )}
      </Col>
    </div>
  )
}

const PaypalProvider = (props) => {
  const { paypalId } = props
  return (
    <PayPalScriptProvider
      options={{
        'client-id': awsconfig.paypal_client_id,
        'merchant-id': paypalId,
        components: 'buttons',
        currency: 'USD',
      }}
    >
      <PaypalSinglePaymentButton {...props} />
    </PayPalScriptProvider>
  )
}

const PaypalSinglePaymentButton = ({
  form,
  amount,
  setLoading,
  isVenmo,
  subscriberPaymentInfo,
  onSubmit,
  paypalId,
}) => {
  usePayPalScriptReducer()

  const donationAmount = useRef(0)
  const commissionAmount = useRef(0)

  useEffect(() => {
    const formatedAmount = Number(amount).toFixed(2)
    donationAmount.current = formatedAmount
    commissionAmount.current = calculateComission(
      Number(donationAmount.current),
      subscriberPaymentInfo?.markup,
    )
    // eslint-disable-next-line
  }, [amount])

  return (
    <PayPalButtons
      fundingSource={isVenmo ? 'venmo' : 'paypal'}
      style={{ layout: 'vertical', label: 'donate' }}
      // disabled={!amount}
      createOrder={async (data, actions) => {
        const isValid = await form.validateFields()
        if (isValid) {
          return actions.order
            .create({
              meta: {
                partner_attribution_id: awsconfig.paypal_bncode,
                'PayPal-Partner-Attribution-ID': awsconfig.paypal_bncode,
              },
              purchase_units: [
                {
                  amount: {
                    value: Number(donationAmount.current),
                    breakdown: {
                      item_total: {
                        currency_code: 'USD',
                        value: Number(donationAmount.current),
                      },
                    },
                  },
                  payment_instruction: {
                    disbursement_mode: 'INSTANT',
                    platform_fees: [
                      {
                        amount: {
                          currency_code: 'USD',
                          value: commissionAmount.current,
                        },
                      },
                    ],
                  },
                  items: [
                    {
                      name: 'Event',
                      quantity: '1',
                      unit_amount: {
                        currency_code: 'USD',
                        value: Number(donationAmount.current),
                      },
                      category: 'DONATION',
                    },
                  ],
                },
              ],
            })
            .then((orderId) => orderId)
            .catch(() => {
              throw new Error(
                'Request is not well-formed, syntactically incorrect, or violates schema',
              )
            })
          // eslint-disable-next-line
        } else {
          throw new Error('Fill the form')
        }
      }}
      onApprove={(data, actions) =>
        actions.order
          .capture()
          .then(async (details) => {
            setLoading(true)
            const capturedAmount = details?.purchase_units[0]?.amount?.value
            const paymentId =
              details?.purchase_units[0]?.payments.captures[0].id
            try {
              const payload = {
                amount: capturedAmount,
                paymentId,
                merchantId: paypalId,
              }
              onSubmit(payload)
              setLoading(false)
            } catch (err) {
              message.error(err?.message || 'Something went wrong')
              setLoading(false)
            }
          })
          .catch(() => {
            throw new Error('Paypal failed to capture the payment')
          })
      }
      onError={async (error) => {
        if (!error === 'Fill the form') {
          message.error('Could not able to process your payment')
          const payload = {
            type: 'PAYPAL_SEED_DONATION_CAMPAIGN',
            status: 'FAILURE',
            message: error?.message,
            action: 'Paypal payment for auction item',
          }
          await savePaypalPaymentLog(payload)
        }
      }}
      onCancel={() => message.info('Payment is cancelled')}
    />
  )
}

export { Paypal }

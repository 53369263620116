import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Timeline, Modal, Button, Table } from 'antd'
// import Api from 'api'
// import { formatAmount } from 'utils'

function MonthlyEarnings() {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [isModalVisible2ndCard, setIsModalVisible2ndCard] = useState(false)

  const showModal = (item) => {
    setSelectedItem(item)
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const showModal2ndCard = () => {
    setIsModalVisible2ndCard(true)
  }

  const handleOk2ndCard = () => {
    setIsModalVisible2ndCard(false)
  }

  const handleCancel2ndCard = () => {
    setIsModalVisible2ndCard(false)
  }

  const timelineData = [
    { title: 'Event 1', content: 'Details of Event 1' },
    { title: 'Event 2', content: 'Details of Event 2' },
    // Add more events as needed
  ]

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Payment Amount',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
    },
    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
    },
  ]

  useEffect(() => {
    // setLoading(true)
    // Api.get(`adminReports/performanceDetails/${id}`)
    //   .then((res) => {
    //     setReport(res.results)
    //     setValue(res.results)
    //     setLoading(false)
    //   })
    //   .catch((error) => {
    //     console.log('error', error)
    //     setLoading(false)
    //   })
    // eslint-disable-next-line
  }, [])

  //   const isEvent = type && type === 'Event'
  return (
    <>
      <h1>Monthly Earnings</h1>
      <div style={{ marginTop: '5%' }}>
        <Row style={{ marginBottom: '5%' }} gutter={[16, 16]}>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ height: '100%' }}
          >
            <Card
              style={{ height: '100%', overflowY: 'auto' }}
              title="Recent Transactions"
              bordered={false}
              extra={<Button onClick={showModal2ndCard}>See Full List</Button>}
            >
              <Timeline>
                {timelineData.map((item, index) => (
                  <Timeline.Item key={index}>
                    {item.title}
                    <Button
                      type="link"
                      style={{ float: 'right' }}
                      onClick={() => showModal(item)}
                    >
                      View Details
                    </Button>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Card>
          </Col>
        </Row>
        <Modal
          title={selectedItem?.title}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Close
            </Button>,
          ]}
        >
          <p>{selectedItem?.content}</p>
        </Modal>
        <Modal
          title="Full List"
          visible={isModalVisible2ndCard}
          onOk={handleOk2ndCard}
          onCancel={handleCancel2ndCard}
          width={800}
        >
          <Table dataSource={[]} columns={columns} />
        </Modal>
      </div>
    </>
    // <Skeleton loading={loading} paragraph={{ rows: 12 }}>
    //   {report ? (
    //     <div className="t-space-y-4">
    //       <div className="t-flex t-items-center t-justify-between">
    //         <p>Goal Amount</p>
    //         {formatAmount(report.goalAmount)}
    //       </div>
    //       <div className="t-flex t-items-center t-justify-between">
    //         <p>Donated Amount</p>
    //         {formatAmount(report.total?.Donations)}
    //       </div>
    //       {isEvent && (
    //         <div className="t-flex t-items-center t-justify-between">
    //           <p>Pledged Amount</p>
    //           {formatAmount(report.total?.Pledges)}
    //         </div>
    //       )}
    //       <div className="t-flex t-items-center t-justify-between">
    //         <p>Number of Attendees</p>
    //         {report.noOfAttendies}
    //       </div>
    //       <div className="t-flex t-items-center t-justify-between">
    //         <p>Event Cost</p>
    //         {formatAmount(report.total?.Donations * 0.02)}
    //       </div>
    //     </div>
    //   ) : (
    //     <div>No Data Found</div>
    //   )}
    // </Skeleton>
  )
}

export default MonthlyEarnings

import React from 'react'
import { Card, Table } from 'antd'
import { formatAmount, formatDate } from 'utils'

const OrganizationHardware = ({ formatedHardwareList }) => {
  const columns = [
    {
      title: 'Hardware Type',
      dataIndex: 'HardwareType',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Serial Number',
      dataIndex: 'DeviceSerialNumber',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Deployment Date',
      dataIndex: 'DeploymentDate',
      className: 'ant-table-row-cell-break-word',
      render: (_, { DeploymentDate }) =>
        DeploymentDate ? formatDate(DeploymentDate) : null,
    },
    {
      title: 'Amount',
      dataIndex: 'MonthlyCost',
      className: 'ant-table-row-cell-break-word',
      render: (_, { MonthlyCost }) => formatAmount(MonthlyCost),
    },
  ]

  const isHardwareAllocated = formatedHardwareList?.length > 1
  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0">Hardware</h6>
        </>,
      ]}
    >
      {isHardwareAllocated ? (
        <Table
          tableLayout="auto"
          scroll={{ x: 500 }}
          columns={columns}
          dataSource={formatedHardwareList || []}
          bordered={false}
          pagination={false}
        />
      ) : (
        <div>No Hardware Allocated To Your Organization</div>
      )}
    </Card>
  )
}

export { OrganizationHardware }

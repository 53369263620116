import React, { useEffect, useState } from 'react'
import {
  Card,
  Input,
  Select,
  Table,
  Tooltip,
  Space,
  Skeleton,
  message,
} from 'antd'
import { MdOutlineSimCardDownload } from 'react-icons/md'
import { SearchOutlined } from '@ant-design/icons'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import {
  downloadCSV,
  formatAmount,
  formatAmountWithOutDecimal,
  formateDateTimeToLocal,
} from 'utils'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { makeRequest } from 'api'
const { Option } = Select

const RecurringDonations = () => {
  const [drowpDownValue, setDropDownValue] = useState('recurringDonation')
  const [searchText, setSearchText] = useState(null)
  const [timeFrame, setTimeFrame] = useState('year') // State to track 'Year' or 'Month' selection
  const [recurringReportData, setRecurringReportData] = useState([])
  const [recurringDonationList, setRecurringDonationList] = useState([])
  const [transactionList, setTransactionList] = useState([])
  const [transactionData, setTransactionData] = useState([])

  // const [performance, setPerformance] = useState([])
  const [loading, setLoading] = useState(false)
  const { organization } = useAuthSelector()

  const currentDate = new Date()
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const [selectedMonth, setSelectedMonth] = useState(
    months[currentDate.getMonth()],
  )

  const history = useHistory()

  const handlePayoutChange = (value) => {
    setDropDownValue(value)
  }

  const columns = [
    {
      title: 'Donor Name',
      dataIndex: 'DonorName',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Donor Email',
      dataIndex: 'DonorEmail',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Phone Number',
      dataIndex: 'DonorPhoneNumber',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      className: 'ant-table-row-cell-break-word text-center',
      render: (amount) => formatAmount(amount),
      align: 'center',
    },
    {
      title: 'Last Date',
      dataIndex: 'ScheduleDate',
      className: 'ant-table-row-cell-break-word',
      render: (time) => formateDateTimeToLocal(time, 'datetime'),
      sorter: (a, b) => {
        const dateA = new Date(a.Date)
        const dateB = new Date(b.Date)
        return dateB - dateA
      },
      sortDirections: ['descend', 'ascend'],
    },
  ]

  const columns1 = [
    {
      title: 'Name',
      dataIndex: 'DonorName',
      className: 'ant-table-row-cell-break-word',
      render: (name) => {
        // Check if the name is not null or undefined
        if (name) {
          // Capitalize the first letter of the name
          return name.charAt(0).toUpperCase() + name.slice(1)
        }
        return name // Return the name as is if it's null or undefined
      },
    },
    {
      title: 'Email',
      dataIndex: 'DonorEmail',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Phone Number',
      dataIndex: 'DonorPhoneNumber',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      className: 'ant-table-row-cell-break-word align-right',
      render: (amount) => formatAmount(amount),
      align: 'center',
    },
    {
      title: 'Last Date',
      dataIndex: 'ScheduleDate',
      className: 'ant-table-row-cell-break-word',
      render: (date) => formateDateTimeToLocal(date, 'datetime'),
    },
  ]

  const makeRecurringCSVData = () => {
    if (recurringReportData?.recurringDonationList) {
      const updatedData = recurringReportData.recurringDonationList.map(
        (element) => {
          const dateData = formateDateTimeToLocal(element.ScheduleDate, 'date')
          const timeData = formateDateTimeToLocal(element.ScheduleDate, 'time')
          return {
            ...element,
            dateData,
            timeData,
          }
        },
      )
      setRecurringDonationList(updatedData)
    }
  }
  const makeTransactionCSVData = () => {
    if (recurringReportData?.transactionList) {
      const updatedData = recurringReportData.transactionList.map((element) => {
        const dateData = formateDateTimeToLocal(element.ScheduleDate, 'date')
        const timeData = formateDateTimeToLocal(element.ScheduleDate, 'time')
        return {
          ...element,
          dateData,
          timeData,
        }
      })
      setTransactionList(updatedData)
    }
  }

  useEffect(() => {
    makeRecurringCSVData()
    makeTransactionCSVData()
    // eslint-disable-next-line
  }, [recurringReportData])

  const csvColumns = [
    'DonorName',
    'DonorEmail',
    'DonorPhoneNumber',
    'Amount',
    'dateData',
    'timeData',
  ]
  const csvHeaderMapping = {
    DonorName: 'DonorName',
    DonorEmail: 'DonorEmail',
    DonorPhoneNumber: 'DonorPhoneNumber',
    Amount: 'Amount',
    dateData: 'Date',
    timeData: 'Time',
  }

  const searchFYP = (rows, searchText1) => {
    if (searchText1) {
      return rows?.filter(
        ({ DonorName, DonorEmail, DonorPhoneNumber, Amount }) =>
          DonorName?.toLowerCase().includes(searchText1.toLowerCase()) ||
          DonorEmail?.toLowerCase().includes(searchText1.toLowerCase()) ||
          DonorPhoneNumber?.toLowerCase().includes(searchText1.toLowerCase()) ||
          Amount?.toLowerCase().includes(searchText1.toLowerCase()),
      )
    }
    return rows
  }

  useEffect(() => {
    setSearchText('')
    // eslint-disable-next-line
  }, [])

  const handleTimeFrameChange = (value) => {
    setTimeFrame(value)
    // setSelectedMonth(null) // Reset month selection when time frame changes
  }
  const handleMonthChange = (value) => {
    // Find the month with a matching lowercase value
    const selected = months.find((month) => month.toLowerCase() === value)
    if (selected) {
      setSelectedMonth(selected)
    }
  }

  const handleReccuringDonations = () => {
    setLoading(true)
    makeRequest('get', 'reports/recurring-donation', {
      organization: organization.id,
    })
      .then((res) => {
        setRecurringReportData(res.results)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        message.error(error?.response?.data?.message || 'Something went wrong')
      })
  }
  useEffect(() => {
    handleReccuringDonations()
    // eslint-disable-next-line
  }, [])

  const filterByMonth = () => {
    if (selectedMonth && recurringReportData?.transactionList) {
      const monthData = recurringReportData.transactionList.filter(
        (transaction) => {
          const transactionMonth = new Date(transaction.ScheduleDate).getMonth()
          return transactionMonth === months.indexOf(selectedMonth)
        },
      )
      setTransactionData(monthData)
    } else {
      setTransactionData(recurringReportData?.transactionList || [])
    }
  }
  useEffect(() => {
    filterByMonth()
    // eslint-disable-next-line
  }, [selectedMonth, timeFrame])

  const [performanceData, setPerformanceData] = useState([])

  const performanceCSVData = () => {
    const { currentYear, lastYear, percentage } = recurringReportData || {}
    const items = [
      {
        Year: 'Current Year',
        collection: currentYear?.collection || 0,
        expected: currentYear?.expected || 0,
        failed: currentYear?.failed || 0,
        total: currentYear?.total || 0,
        '% Collected': percentage?.currentYear || 0,
      },
      {
        Year: 'Last Year',
        collection: lastYear?.collection || 0,
        expected: lastYear?.collection + lastYear?.failed || 0,
        failed: lastYear?.failed || 0,
        total: lastYear?.total || 0,
        '% Collected': percentage?.lastYear || 0,
      },
    ]
    setPerformanceData(items)
  }

  useEffect(() => {
    performanceCSVData()
    // eslint-disable-next-line
  }, [recurringReportData])

  const csvPerformanceColumns = [
    'Year',
    'collection',
    'expected',
    'failed',
    'total',
    '% Collected',
  ]

  const csvPerformanceHeaderMapping = {
    Year: 'Year',
    collection: 'Collection',
    expected: 'Expected',
    failed: 'Failed',
    total: 'Total',
    '% Collected': '% Collected',
  }

  return (
    <>
      <Skeleton loading={loading} active paragraph={{ rows: 16 }}>
        <Card
          className="header-solid"
          bordered={false}
          title={
            <div className="t-flex t-flex-wrap t-justify-between">
              <div className="t-flex t-space-x-4">
                <IoMdArrowRoundBack
                  fontSize="1.5rem"
                  className="t-cursor-pointer t-min-w-[1.5rem]"
                  id="back"
                  onClick={() => history.push('/reports')}
                />
                <h5 className="font-semibold">Recurring Donations</h5>
              </div>
              <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
                <div className="header-control t-mt-0">
                  {drowpDownValue !== 'performanceReport' && (
                    <Input
                      className="header-search t-w-36"
                      placeholder="Search ..."
                      prefix={<SearchOutlined />}
                      onChange={(e) => {
                        setSearchText(e.target.value)
                      }}
                    />
                  )}
                </div>
                {drowpDownValue === 'recurringDonation' ? (
                  <Tooltip title="Download">
                    <MdOutlineSimCardDownload
                      fontSize="1.7rem"
                      id="download"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() =>
                        downloadCSV(
                          drowpDownValue === 'performanceReport'
                            ? performanceData
                            : recurringDonationList,
                          drowpDownValue === 'performanceReport'
                            ? csvPerformanceColumns
                            : csvColumns,
                          drowpDownValue === 'performanceReport'
                            ? csvPerformanceHeaderMapping
                            : csvHeaderMapping,
                          drowpDownValue === 'performanceReport'
                            ? 'Year Report'
                            : 'Recurring Donation Report',
                        )
                      }
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Download">
                    <MdOutlineSimCardDownload
                      fontSize="1.7rem"
                      id="download"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() =>
                        downloadCSV(
                          drowpDownValue === 'performanceReport'
                            ? performanceData
                            : transactionList,
                          drowpDownValue === 'performanceReport'
                            ? csvPerformanceColumns
                            : csvColumns,
                          drowpDownValue === 'performanceReport'
                            ? csvPerformanceHeaderMapping
                            : csvHeaderMapping,
                          drowpDownValue === 'performanceReport'
                            ? 'Year Report'
                            : 'Transaction Report',
                        )
                      }
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          }
        >
          <Card className="header-solid" bordered={false}>
            <div className="t-pl-6 t-mb-4 t-w-full">
              <h3 className="t-text-lg t-font-semibold">Choose Your Report</h3>
            </div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                marginBottom: 20,
                marginLeft: '2%',
              }}
            >
              <div
                className="t-flex sm:t-flex-row t-flex-col"
                style={{
                  alignItems: 'center',
                  marginRight: 30,
                  marginTop: 8,
                }}
              >
                <span style={{ marginRight: 5, width: '100%' }}>
                  <b>Select Type:</b>
                </span>
                <Select
                  placeholder="Select Report Type"
                  onChange={handlePayoutChange}
                  value={drowpDownValue}
                  style={{ width: '100%' }}
                  dropdownMatchSelectWidth={false} // This allows the dropdown to have a different width
                  dropdownStyle={{ minWidth: 300 }} // Set a minimum width for the dropdown menu content
                >
                  <Option key={2} value="performanceReport">
                    Performance Report
                  </Option>
                  <Option key={3} value="transactionDetails">
                    Transaction Details
                  </Option>
                  <Option key={1} value="recurringDonation">
                    Recurring Donations List
                  </Option>
                </Select>
              </div>

              {drowpDownValue === 'transactionDetails' && (
                <div>
                  <Space direction="vertical">
                    <div className="t-mt-2">
                      <Space>
                        <p>
                          <b>Filter By</b>
                        </p>
                        <Select
                          defaultValue="year" // Default value
                          style={{ width: 100 }}
                          onChange={handleTimeFrameChange} // Use the same handler or modify it as needed
                        >
                          <Option value="year">Year</Option>
                          <Option value="month">Month</Option>
                        </Select>
                        {timeFrame === 'month' && (
                          <>
                            <p style={{ marginLeft: 10 }}>
                              <b>Select Month</b>
                            </p>
                            <Select
                              placeholder="Select Month"
                              style={{ width: 150 }}
                              value={
                                selectedMonth
                                  ? selectedMonth.toLowerCase()
                                  : months[currentDate.getMonth()]
                              } // Provide a fallback value
                              onChange={handleMonthChange}
                            >
                              {months.map((month, index) => (
                                <Option key={index} value={month.toLowerCase()}>
                                  {month}
                                </Option>
                              ))}
                            </Select>
                          </>
                        )}
                      </Space>
                    </div>
                  </Space>
                </div>
              )}
            </div>
          </Card>
          <Card
            style={{ marginTop: 30 }}
            className="header-solid"
            bordered={false}
            title={
              <div className="t-flex t-flex-wrap t-justify-between">
                <div className="t-flex t-space-x-4">
                  <IoMdArrowRoundBack
                    fontSize="1.5rem"
                    className="t-cursor-pointer t-min-w-[1.5rem]"
                    id="back"
                    onClick={() => history.push('/reports')}
                  />
                  {drowpDownValue === 'recurringDonation' ? (
                    <h5 className="font-semibold">Recurring Donations</h5>
                  ) : drowpDownValue === 'performanceReport' ? (
                    <h5 className="font-semibold">Performance Report</h5>
                  ) : (
                    <h5 className="font-semibold">Transaction Details</h5>
                  )}
                </div>
                {drowpDownValue === 'recurringDonation' ? (
                  <div className="t-flex t-justify-end t-items-center t-space-x-2">
                    <Tooltip title="Download CSV">
                      <MdOutlineSimCardDownload
                        fontSize="1.7rem"
                        className="t-text-secondary-100 t-cursor-pointer"
                        onClick={() =>
                          downloadCSV(
                            drowpDownValue === 'performanceReport'
                              ? performanceData
                              : recurringDonationList,
                            drowpDownValue === 'performanceReport'
                              ? csvPerformanceColumns
                              : csvColumns,
                            drowpDownValue === 'performanceReport'
                              ? csvPerformanceHeaderMapping
                              : csvHeaderMapping,
                            drowpDownValue === 'performanceReport'
                              ? 'Year Report'
                              : 'Recurring Donation Report',
                          )
                        }
                      />
                    </Tooltip>
                  </div>
                ) : (
                  <div className="t-flex t-justify-end t-items-center t-space-x-2">
                    <Tooltip title="Download CSV">
                      <MdOutlineSimCardDownload
                        fontSize="1.7rem"
                        className="t-text-secondary-100 t-cursor-pointer"
                        onClick={() =>
                          downloadCSV(
                            drowpDownValue === 'performanceReport'
                              ? performanceData
                              : transactionList,
                            drowpDownValue === 'performanceReport'
                              ? csvPerformanceColumns
                              : csvColumns,
                            drowpDownValue === 'performanceReport'
                              ? csvPerformanceHeaderMapping
                              : csvHeaderMapping,
                            drowpDownValue === 'performanceReport'
                              ? 'Year Report'
                              : 'Transaction Report',
                          )
                        }
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            }
          >
            {drowpDownValue === 'recurringDonation' && (
              <div>
                <Table
                  dataSource={
                    searchFYP(
                      recurringReportData.recurringDonationList,
                      searchText,
                    ) || []
                  }
                  columns={columns}
                  pagination={false}
                  tableLayout="auto"
                  scroll={{ x: 500 }}
                  className="td-right"
                ></Table>
              </div>
            )}
            {drowpDownValue === 'performanceReport' && (
              <div className="t-mx-8">
                <h1 className="t-text-base t-font-medium">
                  Current Year Recurring Donations
                </h1>
                <div className="t-space-y-4 t-my-4">
                  <div className="t-flex t-items-center t-justify-between">
                    <p>Total Amount Collected</p>
                    <h4>
                      {formatAmountWithOutDecimal(
                        recurringReportData?.currentYear?.collection,
                      )}
                    </h4>
                  </div>
                  <div className="t-flex t-items-center t-justify-between">
                    <p>Total Amount Expected</p>
                    <h4>
                      {formatAmountWithOutDecimal(
                        recurringReportData?.currentYear?.expected,
                      )}
                    </h4>
                  </div>
                  <div className="t-flex t-items-center t-justify-between">
                    <p>Total Amount not Collected</p>
                    <h4>
                      {formatAmountWithOutDecimal(
                        recurringReportData?.currentYear?.failed,
                      )}
                    </h4>
                  </div>
                </div>
                <h1 className="t-text-base t-font-medium">
                  Last Year Recurring Donations Report
                </h1>
                <div className="t-space-y-4 t-my-4">
                  <div className="t-flex t-items-center t-justify-between">
                    <p>Total Amount Collected Last Year</p>
                    <h4>
                      {formatAmountWithOutDecimal(
                        recurringReportData?.lastYear?.collection,
                      )}
                    </h4>
                  </div>
                  <div className="t-flex t-items-center t-justify-between">
                    <p>Total Amount not Collected Last Year</p>
                    <h4>
                      {formatAmountWithOutDecimal(
                        recurringReportData?.lastYear?.failed,
                      )}
                    </h4>
                  </div>
                </div>
                <h1 className="t-text-base t-font-medium">
                  Percentage and Trends
                </h1>
                <div className="t-space-y-4 t-my-4">
                  <div className="t-flex t-items-center t-justify-between">
                    <p>% Collected this Year</p>
                    <h4>{recurringReportData?.percentage?.currentYear}%</h4>
                  </div>
                  <div className="t-flex t-items-center t-justify-between">
                    <p>% Collected Last Year</p>
                    <h4>{recurringReportData?.percentage?.lastYear}%</h4>
                  </div>
                  <div className="t-flex t-items-center t-justify-between">
                    <p>% Increase or Decrease</p>
                    <h4>
                      {recurringReportData?.percentage?.increase === true
                        ? 'increase'
                        : 'decrease'}
                    </h4>
                  </div>
                </div>
              </div>
            )}
            {drowpDownValue === 'transactionDetails' && (
              <Table
                tableLayout="auto"
                scroll={{ x: 500 }}
                columns={columns1}
                dataSource={
                  searchFYP(
                    timeFrame === 'year'
                      ? recurringReportData?.transactionList
                      : transactionData,
                    searchText,
                  ) || []
                }
                bordered={false}
                pagination={false}
                className="td-right"
              />
            )}
          </Card>
        </Card>
      </Skeleton>
    </>
  )
}

export { RecurringDonations }

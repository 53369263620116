import React, { useEffect, useState } from 'react'
import {
  Button,
  Divider,
  List,
  Modal,
  Popconfirm,
  Skeleton,
  Tooltip,
  message,
} from 'antd'
import { makeRequest } from 'api'
import { formatAmount, formateDate } from 'utils'

const CategoryActiveSubscriptionModal = ({
  isVisible,
  onClose,
  organizationId,
  paymentCategoryId,
  categoryName,
  paymentType,
  accountId,
  handleDisconnent,
}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [cancelling, setCancelling] = useState(false)

  const getActiveSubscription = () => {
    setLoading(true)
    makeRequest(
      'post',
      `/payment-categories/active-subscription`,
      {},
      {
        organizationId,
        paymentCategoryId,
        paymentType,
        accountId,
      },
    )
      .then((res) => {
        const dummyData = res?.results
        if (dummyData) {
          setData(dummyData)
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        message.error(error?.response?.data?.message || 'Something went wrong')
      })
  }

  useEffect(() => {
    getActiveSubscription()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const disableCategory = () => {
    if (hasActiveSubscription) {
      setCancelling(true)
      makeRequest(
        'put',
        `/payment-categories/cancel-subscription`,
        {},
        {
          organizationId,
          paymentCategoryId,
          paymentType,
          accountId,
        },
      )
        .then(() => {
          setCancelling(false)
          onClose()
          handleDisconnent()
        })
        .catch((error) => {
          setCancelling(false)
          message.error(
            error?.response?.data?.message || 'Something went wrong',
          )
        })
    } else {
      onClose()
      handleDisconnent()
    }
  }

  const hasActiveSubscription = data?.length > 0
  return (
    <Modal
      centered
      title="Currently In Use By"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        {data && (
          <>
            {hasActiveSubscription ? (
              <p className="t-mb-2">
                <b>Note:</b> The following subscriptions use category[
                {categoryName}] and account[{accountId}]. If you disconnect, the
                subscriptions will be canncelled.
              </p>
            ) : (
              <p className="t-mb-2">
                The [{categoryName}] category connected to account ID [
                {accountId}] has no active subscriptions. You can safely
                disconnect this category.
              </p>
            )}
            <Popconfirm
              title="Are you sure you want to disconnect this account？"
              placement="top"
              //   loading={loading}
              onConfirm={() => disableCategory()}
              okText="Disconnect"
              cancelText="Cancel"
            >
              <Button
                type="primary"
                loading={cancelling}
                className="t-w-full t-p-x-4"
              >
                Disconnect {paymentType} Account
              </Button>
            </Popconfirm>
            {hasActiveSubscription && (
              <>
                <Divider />
                <p>List of Active Subscriptions</p>
                <List
                  itemLayout="horizontal"
                  dataSource={data || []}
                  split={false}
                  className="conversations-list"
                  renderItem={(item) => (
                    <List.Item
                      key={item.Id}
                      actions={[
                        <Tooltip title="Amount">
                          {formatAmount(item.TotalAmount)}
                        </Tooltip>,
                      ]}
                    >
                      <List.Item.Meta
                        key={item.Id}
                        title={
                          <Tooltip title="Donor Name">{item.DonorName}</Tooltip>
                        }
                        description={
                          <Tooltip title="Date">
                            {formateDate(item.CreatedDate)}
                          </Tooltip>
                        }
                      />
                    </List.Item>
                  )}
                />
              </>
            )}
          </>
        )}
      </Skeleton>
    </Modal>
  )
}

export { CategoryActiveSubscriptionModal }

import React, { useState } from 'react'
import { Switch, Typography, message } from 'antd'
import { makeRequest } from 'api'
import { CategoryComponentModal } from './CategoryComponentModal'
const { Paragraph } = Typography

const ActionButton = ({
  Id,
  Name,
  DefaultCategory,
  ActiveInd,
  refreshPayment,
}) => {
  const [loading, setLoading] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const handleEnableOrDisable = () => {
    if (isEnabledCategory) {
      setIsVisible(true)
    } else {
      enableOrDisablePaymentCategory(1)
    }
  }

  const enableOrDisablePaymentCategory = (value) => {
    setLoading(true)
    makeRequest(
      'put',
      `/payment-categories/active-status/${Id}`,
      {},
      { status: value },
    )
      .then((res) => {
        message.success(res?.message || 'Updated successfully')
        setLoading(false)
        refreshPayment()
      })
      .catch((error) => {
        setLoading(false)
        message.error(error?.response?.data?.message || 'Something went wrong')
      })
  }

  const isDefaultCategory = DefaultCategory === true
  const isEnabledCategory = ActiveInd === 1
  const labelText = isDefaultCategory
    ? "Default Category can't be disabled"
    : isEnabledCategory
    ? 'Disable'
    : 'Enable'
  return (
    <>
      <div className="t-flex t-flex-col md:t-flex-row ">
        <Paragraph level={5} className="t-px-2">
          Disabled
        </Paragraph>
        <Switch
          title={labelText}
          loading={loading}
          disabled={isDefaultCategory}
          // checkedChildren="Enabled"
          // unCheckedChildren="Disabled"
          checked={isEnabledCategory}
          onChange={handleEnableOrDisable}
        />
        <Paragraph level={5} className="t-px-2">
          Enabled
        </Paragraph>
      </div>
      {isVisible && (
        <CategoryComponentModal
          isVisible={isVisible}
          onClose={() => setIsVisible(false)}
          Id={Id}
          Name={Name}
          enableOrDisablePaymentCategory={enableOrDisablePaymentCategory}
        />
      )}
    </>
  )
}

export { ActionButton }

import React, { useState, useRef, memo } from 'react'
import { Button, Image, message, Spin, Tooltip } from 'antd'
import { AiFillCamera, AiOutlineCloudUpload } from 'react-icons/ai'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { isMachineReadable } from 'store/organizationSlice/organizationActions'
import { awsconfig } from 'configs'
import { MdOutlineChangeCircle } from 'react-icons/md'
const loadingIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />
)

const OpenCamera = ({ form, name, limit }) => {
  const [startCamera, setStartCamera] = useState(false)
  const [stream, setStream] = useState(null)
  const [facingMode, setFacingMode] = useState('user')
  const [error, setError] = useState('')
  const videoRef = useRef()
  const [images, setImages] = useState([])
  const [readingImage, setReadingImage] = useState(false)

  const handleOpenCamera = () => {
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode,
          },
        })
        .then(handleSuccess)
        .catch(handleError)
    }
  }

  const handleClick = async () => {
    if (images.length < limit) {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      canvas.width = videoRef.current.videoWidth
      canvas.height = videoRef.current.videoHeight
      context.drawImage(videoRef.current, 0, 0)
      canvas.toBlob(async (blob) => {
        // you can use the blob to save the image
        setReadingImage(true)
        try {
          const readableImage = await isMachineReadable(blob)
          if (readableImage === true) {
            setImages([...images, blob])
          } else {
            message.error('Image is not machine readable')
          }
          setReadingImage(false)
        } catch (err) {
          console.log('err>>>>', err)
          message.error('An error occured while reading image')
          setReadingImage(false)
        }
      })
    } else {
      message.error('You reached maximum limit')
    }
  }

  const handleSuccess = (stream1) => {
    setStream(stream1)
    videoRef.current.srcObject = stream1
  }

  const handleError = (error1) => {
    setError(error1.name)
  }

  const removeImage = (index) => {
    const newImages = [...images]
    newImages.splice(index, 1)
    setImages(newImages)
  }

  const handleCancel = () => {
    if (stream) {
      stream.getTracks().forEach((track) => {
        try {
          track.stop()
        } catch (e) {
          console.log("couldn't stop stream")
        }
      })
    }
    setImages([])
    setStartCamera(false)
  }

  const handleDone = () => {
    if (images.length > 0) {
      if (stream) {
        stream.getTracks().forEach((track) => {
          try {
            track.stop()
          } catch (e) {
            console.log("couldn't stop stream")
          }
        })
      }

      const files = images.map((image) => {
        const file = new File([image], 'image.png', { type: 'image/png' })
        return file
      })
      form.setFieldsValue({ [name]: files })
      setImages([])
      setStartCamera(false)
    } else {
      message.error('Take a picture to upload')
    }
  }

  const handleSwitchCamera = () => {
    setFacingMode(facingMode === 'user' ? 'environment' : 'user')
    handleOpenCamera()
  }

  return (
    <div>
      {!startCamera ? (
        <>
          <Button
            icon={<UploadOutlined />}
            style={{ marginRight: '1rem' }}
            onClick={() => {
              setStartCamera(true)
              handleOpenCamera()
            }}
          >
            {form.getFieldValue(name) && form.getFieldValue(name).length > 0
              ? 'Change'
              : 'Click to Take Photo'}
          </Button>
          <div className="t-mt-2 t-flex t-space-x-2">
            <Image.PreviewGroup>
              {form.getFieldValue(name) &&
                form
                  .getFieldValue(name)
                  .map((item) => (
                    <Image
                      width={100}
                      src={
                        item.name
                          ? URL.createObjectURL(item)
                          : awsconfig.bucketUrl + item?.fileName
                      }
                    />
                  ))}
            </Image.PreviewGroup>
          </div>
        </>
      ) : (
        <div className="t-fixed t-top-0 t-left-0 t-z-[99] t-w-screen t-h-screen t-bg-black">
          {stream && (
            <div className="t-relative">
              <div className="t-flex t-justify-center t-items-center t-absolute t-top-3.5 t-left-3.5 t-z-[1]">
                <Tooltip title="Go Back">
                  <IoMdArrowRoundBack
                    fontSize="1.5rem"
                    className="t-text-white t-cursor-pointer"
                    onClick={() => handleCancel()}
                  />
                </Tooltip>
              </div>
              <div className="t-flex t-justify-center t-items-center t-absolute t-top-3.5 t-right-3.5 t-z-[1] t-space-x-2">
                <Tooltip title="Done">
                  <MdOutlineChangeCircle
                    fontSize="1.5rem"
                    className="t-text-white t-cursor-pointer"
                    onClick={() => handleSwitchCamera()}
                  />
                </Tooltip>
                <Tooltip title="Done">
                  <AiOutlineCloudUpload
                    fontSize="1.5rem"
                    className="t-text-white t-cursor-pointer"
                    onClick={() => handleDone()}
                  />
                </Tooltip>
              </div>
              <div className="t-flex t-justify-center t-items-center t-absolute t-bottom-[2rem] t-z-[2]">
                <UploadedImages files={images} removeFile={removeImage} />
              </div>
              {readingImage ? (
                <div className="t-fixed t-w-full t-h-full t-flex t-flex-col t-justify-center t-items-center t-z-[1]">
                  <Spin indicator={loadingIcon} />
                  <div className="t-text-white">Processing ...</div>
                </div>
              ) : (
                <div className="t-flex t-justify-center t-items-center t-absolute t-bottom-3.5 t-left-[48%] t-z-[1]">
                  <Tooltip title="Take Picture">
                    <AiFillCamera
                      fontSize="1.5rem"
                      className="t-text-white t-cursor-pointer"
                      onClick={() => handleClick()}
                    />
                  </Tooltip>
                </div>
              )}
              {/* eslint-disable-next-line */}
              <video
                playsInline
                ref={videoRef}
                controls={false}
                autoPlay
                className="t-w-screen t-h-screen"
              />
            </div>
          )}
          {error && <p>{error}</p>}
        </div>
      )}
    </div>
  )
}

const UploadedImages = memo(({ files, removeFile }) => (
  <div className="t-flex t-items-center t-space-x-2 t-z-[2]">
    {files.map((file, i) => (
      <SingleImage index={i} file={file} removeFile={removeFile} />
    ))}
  </div>
))

const SingleImage = memo(({ index, file, removeFile }) => {
  const [showChild, setShowChild] = useState(false)
  return (
    <div
      key={index}
      className="t-relative t-w-20 t-h-20"
      onMouseEnter={() => setShowChild(true)}
      onMouseLeave={() => setShowChild(false)}
    >
      <div
        onClick={() => removeFile(index)}
        className={`${
          showChild ? 't-flex' : 't-hidden'
        } t-shadow-[0px_0px_10px_0px_rgba(0,0,0,0.4)] t-justify-center t-items-center t-cursor-pointer t-absolute t-right-[-5px] t-top-[-5px] t-bg-white t-p-1 t-w-4 t-h-4 t-rounded-full t-z-[3]`}
      >
        <p className="t-text-xs">x</p>
      </div>
      <img
        src={URL.createObjectURL(file)}
        alt={`taken ${index}`}
        className="t-rounded-lg t-border-[0.0625rem] t-border-slate-300 t-object-cover"
      />
    </div>
  )
})

export { OpenCamera }

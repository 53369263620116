import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { ImageUploader } from 'components/elements'
import moment from 'moment'
import React, { useEffect } from 'react'
import { compareDateTimeWithNow, disabledTime, numberWithCommas } from 'utils'
import { auctionItemValidation } from 'validations/auctionItem'
import { v4 as uuidv4 } from 'uuid'

const priorityTypes = ['Low', 'Medium', 'High']

const initialValues = {
  title: '',
  quantity: '',
  description: '',
  startBid: '',
  winningBid: '',
  priority: 'Low',
  itemImage: null,
}

const AddItemsModal = ({
  visible,
  close,
  auctionItemsList,
  setAuctionItemsList,
  isCampaign,
  defaultEndDate,
  defaultStartDate,
}) => {
  const [form] = Form.useForm()
  Form.useWatch('itemImage', form)

  useEffect(() => {
    if (isCampaign && defaultEndDate) {
      form.setFieldsValue({
        endDate: defaultEndDate,
      })
    }
    // eslint-disable-next-line
  }, [visible])

  const onFinish = (values) => {
    if (isCampaign && !defaultStartDate) {
      message.error('Go back and select fundraiser start date first')
      return
    }

    const Quantity = Number(values.quantity)
    const alreadyAdded = auctionItemsList?.length
    const total = Quantity + alreadyAdded
    if (total <= 100) {
      if (Quantity === 1) {
        const item = {
          ...values,
          key: uuidv4(),
          startBid: values?.startBid?.replaceAll(/,/g, ''),
          winningBid: values?.winningBid?.replaceAll(/,/g, ''),
        }
        setAuctionItemsList([...auctionItemsList, item])
      } else {
        const mutliQauntityList = []
        for (let i = 0; i < Quantity; i += 1) {
          const item = {
            ...values,
            key: uuidv4(),
            startBid: values?.startBid?.replaceAll(/,/g, ''),
            winningBid: values?.winningBid?.replaceAll(/,/g, ''),
            quantity: '1',
          }
          mutliQauntityList.push(item)
        }
        setAuctionItemsList([...auctionItemsList, ...mutliQauntityList])
      }
      form.resetFields()
      close()
    } else {
      message.error(
        `Items can't be greater than 100, you can add only ${
          100 - alreadyAdded
        } more`,
      )
    }
  }

  const onFileChange = (file) => {
    form.setFieldsValue({ itemImage: file })
  }

  const handleQualityChange = (value) => {
    const val = value.replace(/\D/g, '')
    form.setFieldsValue({ quantity: val })
  }

  const handleAmountChange = (name, value) => {
    const withoutCommas = value.replaceAll(/,/g, '')
    const val = withoutCommas.replace(/\D/g, '')
    form.setFieldsValue({ [name]: numberWithCommas(val) })
  }

  return (
    <Modal
      centered
      keyboard
      title="Add Auction Item"
      open={visible}
      onCancel={() => {
        form.resetFields()
        close()
      }}
      footer={null}
      destroyOnClose
    >
      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="add-items"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Form.Item
                name="itemImage"
                label="Item Image"
                colon={false}
                rules={[auctionItemValidation]}
              >
                <ImageUploader onFileChange={onFileChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="title"
                label="Title"
                colon={false}
                rules={[auctionItemValidation]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="quantity"
                label="Quantity"
                colon={false}
                rules={[auctionItemValidation]}
              >
                <Input
                  inputMode="numeric"
                  onChange={(e) => handleQualityChange(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Description"
                colon={false}
                rules={[auctionItemValidation]}
              >
                <TextArea
                  placeholder="Item Description"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="startBid"
                label="Start Bid"
                colon={false}
                rules={[auctionItemValidation]}
              >
                <Input
                  inputMode="numeric"
                  prefix="$"
                  onChange={(e) =>
                    handleAmountChange('startBid', e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="winningBid"
                label="Winning Bid"
                colon={false}
                rules={[auctionItemValidation]}
              >
                <Input
                  inputMode="numeric"
                  prefix="$"
                  onChange={(e) =>
                    handleAmountChange('winningBid', e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="priority"
                label="Priority"
                colon={false}
                // rules={[eventValidation]}
              >
                <Select placeholder="Priority" style={{ width: '100%' }}>
                  {priorityTypes.map((item, index) => (
                    <Select.Option value={item} key={index}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {isCampaign && (
              <Col span={12}>
                <Form.Item
                  name="endDate"
                  label="End Date"
                  colon={false}
                  rules={[auctionItemValidation]}
                >
                  <DatePicker
                    format="MM-DD-YYYY hh:mm A"
                    showTime
                    minuteStep={15}
                    className="t-w-full"
                    disabledDate={(d) =>
                      !d ||
                      d.isBefore(moment().subtract(1, 'day')) ||
                      d.isBefore(moment(defaultStartDate).startOf('day'))
                    }
                    disabledTime={disabledTime}
                    onChange={(selectedDate) =>
                      !compareDateTimeWithNow(selectedDate, 'before') &&
                      form.setFieldsValue({
                        endDate: moment(),
                      })
                    }
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <div className="t-h-full t-flex t-items-center">
                <Button type="primary" htmlType="submit">
                  Add Item
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>
    </Modal>
  )
}

export { AddItemsModal }

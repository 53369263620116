import React from 'react'
// import { Card } from 'antd'
import { LandingPageList } from './landingPage'
const SubscriberLandingPage = () => (
  <div style={{ minHeight: 'calc(100vh - 205px)' }}>
    <h1>Taha Khan</h1>
    <LandingPageList />
  </div>
  //   </Card>
)

export { SubscriberLandingPage }

import React, {
  useEffect,
  //  useState
} from 'react'
// import { Skeleton } from 'antd'
// import Api from 'api'
// import { formatAmount } from 'utils'

function CommunityMember() {
  //   const [loading, setLoading] = useState()
  //   const [report, setReport] = useState()

  useEffect(() => {
    // setLoading(true)
    // Api.get(`adminReports/performanceDetails/${id}`)
    //   .then((res) => {
    //     setReport(res.results)
    //     setValue(res.results)
    //     setLoading(false)
    //   })
    //   .catch((error) => {
    //     console.log('error', error)
    //     setLoading(false)
    //   })
    // eslint-disable-next-line
  }, [])

  //   const isEvent = type && type === 'Event'
  return (
    <h1>CommunityMember</h1>
    // <Skeleton loading={loading} paragraph={{ rows: 12 }}>
    //   {report ? (
    //     <div className="t-space-y-4">
    //       <div className="t-flex t-items-center t-justify-between">
    //         <p>Goal Amount</p>
    //         {formatAmount(report.goalAmount)}
    //       </div>
    //       <div className="t-flex t-items-center t-justify-between">
    //         <p>Donated Amount</p>
    //         {formatAmount(report.total?.Donations)}
    //       </div>
    //       {isEvent && (
    //         <div className="t-flex t-items-center t-justify-between">
    //           <p>Pledged Amount</p>
    //           {formatAmount(report.total?.Pledges)}
    //         </div>
    //       )}
    //       <div className="t-flex t-items-center t-justify-between">
    //         <p>Number of Attendees</p>
    //         {report.noOfAttendies}
    //       </div>
    //       <div className="t-flex t-items-center t-justify-between">
    //         <p>Event Cost</p>
    //         {formatAmount(report.total?.Donations * 0.02)}
    //       </div>
    //     </div>
    //   ) : (
    //     <div>No Data Found</div>
    //   )}
    // </Skeleton>
  )
}

export default CommunityMember

import React, { useEffect, useRef, useState } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import { Col, Form, Input } from 'antd'
import { awsconfig } from 'configs'
import * as Yup from 'yup'

const schema = Yup.object().shape({
  streetAddress: Yup.string().required('Street Address is required'),
  suite: Yup.string(),
})

const adddressValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const AutoCompleteAddress = ({
  form,
  validation = false,
  isInitiallyValidated = false,
  AddressData,
}) => {
  const [isValidated, setIsValidated] = useState(isInitiallyValidated)
  const antInputRef = useRef(null)

  useEffect(() => {
    form.setFieldsValue({
      streetAddress: AddressData.streetAddress || '',
    })
  }, [AddressData, form])

  const clearAddress = () => {
    setIsValidated(false)
  }

  const formatAddress = (address) => {
    const addressInfo = {}

    address.map((component) => {
      if (component.types.includes('country')) {
        addressInfo.country = component.short_name
      } else if (component.types.includes('locality')) {
        addressInfo.city = component.long_name
      } else if (component.types.includes('administrative_area_level_1')) {
        addressInfo.state = component.long_name
      } else if (component.types.includes('street_address')) {
        addressInfo.suite = component.long_name
      } else if (component.types.includes('postal_code')) {
        addressInfo.zipCode = component.long_name
      }
      return null
    })
    return addressInfo
  }

  const { ref: antRef } = usePlacesWidget({
    apiKey: awsconfig.google_api_key,
    onPlaceSelected: (place) => {
      setIsValidated(true)
      const formattedAddress = place?.formatted_address
      form.setFieldsValue({
        streetAddress: formattedAddress,
        Address1: formattedAddress,
        ...formatAddress(place?.address_components),
      })
    },
    options: {
      componentRestrictions: { country: ['us', 'ca'] },
      fields: ['address_components', 'formatted_address', 'geometry'],
      types: ['address'],
    },
  })

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
    }

    if (antRef.current) {
      antRef.current.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      if (antRef.current) {
        antRef.current.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [antRef])

  return (
    <>
      <Col xl={24} md={24} xs={24} sm={24}>
        <Form.Item
          name="streetAddress"
          label="Street Address*"
          colon={false}
          rules={validation ? [adddressValidation] : null}
        >
          <Input
            placeholder="Search Address"
            ref={(c) => {
              antInputRef.current = c
              if (c) antRef.current = c.input
            }}
            onFocus={() => {
              if (isValidated) {
                clearAddress()
              }
            }}
          />
        </Form.Item>
      </Col>
    </>
  )
}

export { AutoCompleteAddress }

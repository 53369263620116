import React, { useEffect, useReducer, useState } from 'react'
import { message, Skeleton, Spin, Tooltip } from 'antd'
import Api, { makeRequest } from 'api'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { MdInfoOutline } from 'react-icons/md'
import { searchDonnorRows } from 'utils'
import { PendingPledgesList } from './PendingPledgesList'
import { MessageForm } from './MessageForm'

const initialState = {
  get: false,
  custom: false,
  list: false,
}
// The reducer function
function reducer(state, action) {
  switch (action) {
    case 'get':
      return {
        ...state,
        get: !state.get,
      }
    case 'custom':
      return {
        ...state,
        custom: !state.custom,
      }
    case 'list':
      return {
        ...state,
        list: !state.list,
      }
    default:
      return state
  }
}

const PendingPledges = ({ searchText }) => {
  const { organization } = useAuthSelector()
  const [loading, setLoading] = useReducer(reducer, initialState)
  const [donors, setDonors] = useState()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    if (organization.id) {
      getDonors(organization?.id)
    }
    // eslint-disable-next-line
  }, [organization])

  const getDonors = () => {
    setLoading('list')
    Api.get(`reports/organization-pending-pledges?id=${organization?.id}`)
      .then((res) => {
        setDonors(res.result)
        setLoading('list')
      })
      .catch((error) => {
        console.log('error', error)
        message.error('An Error Occured in fetching list')
        setLoading('list')
      })
  }

  const sendCustom = (data) => {
    setIsModalVisible(false)
    const list = donors
      ?.filter((item) => selectedRowKeys.includes(item.Id))
      .map((item) => ({
        Id: item.Id,
        DonorName: item.DonorName,
        DonorEmail: item.DonorEmail,
        DonorPhoneNumber: item.DonorPhoneNumber,
        Amount: item.Amount,
      }))
    const dataList = list.length > 0 ? list : donors
    if (organization && organization.id && organization.Email) {
      const payload = {
        dataList,
        email: organization.CreatedBy,
        ...data,
      }
      setLoading('custom')
      makeRequest(
        'post',
        'subscriber/sendPledgeReminderMessage',
        {
          organization: organization?.id,
        },
        { payload },
      )
        .then((res) => {
          message.success(res.message)
          setSelectedRowKeys([])
          setLoading('custom')
        })
        .catch((error) => {
          message.error(error?.response?.data?.error || 'Something Went Wrong')
          setLoading('custom')
        })
    } else {
      message.error('Cound not get Organization data')
    }
  }

  return (
    <Skeleton loading={loading.get} active paragraph={{ rows: 12 }}>
      <div className="t-flex t-justify-end t-items-center t-space-x-2">
        <Tooltip title="Send Reminder">
          {loading.custom ? (
            <Spin />
          ) : (
            <MdInfoOutline
              fontSize="1.7rem"
              id="send-reminder"
              className="t-text-secondary-100 t-cursor-pointer"
              onClick={() => setIsModalVisible(true)}
            />
          )}
        </Tooltip>
        {isModalVisible && (
          <MessageForm
            show={isModalVisible}
            setShow={setIsModalVisible}
            submit={(data) => sendCustom(data)}
          />
        )}
      </div>
      <div>
        <PendingPledgesList
          loading={loading.list}
          data={searchDonnorRows(donors, searchText) || []}
          getData={() => getDonors(organization.id)}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      </div>
    </Skeleton>
  )
}

export { PendingPledges }

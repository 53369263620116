import React, { useEffect, useState } from 'react'
import { Donations } from 'components/common'
import Api from 'api'
import { Skeleton, message } from 'antd'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { config } from 'configs'

const Pledge = () => {
  const { type, id } = useParams()
  const [item, setItem] = useState(null)
  const [isFetching, setFetching] = useState(true)

  const isEventPledge = type === 'event'
  const isValidType = type === 'event' || type === 'organization'

  const getEventData = async () => {
    const itemResponse = await Api.get(`/event?id=${id}`)
    if (itemResponse.results) {
      setItem(itemResponse.results)
    }
  }

  const getOrganizationData = async () => {
    const itemResponse = await Api.get(
      `/component/organization/get-organization/${id}`,
    )
    if (itemResponse.item) {
      setItem(itemResponse.item)
    }
  }

  const getData = async () => {
    try {
      setFetching(true)
      // get item details
      if (isEventPledge) {
        await getEventData()
      } else {
        await getOrganizationData()
      }
      setFetching(false)
    } catch (error) {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      setFetching(false)
    }
  }

  useEffect(() => {
    if (isValidType && id) {
      getData()
    } else {
      setFetching(false)
    }
    // eslint-disable-next-line
  }, [id])

  return (
    <Skeleton
      loading={isFetching}
      active
      paragraph={{ rows: 18 }}
      className="t-w-full t-h-full"
    >
      <Donations
        donationType={isEventPledge ? 'event-pledge' : 'organization-pledge'}
        item={item}
      />
    </Skeleton>
  )
}

export { Pledge }

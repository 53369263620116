import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: false,
  room: null,
  rooms: [],
  reservations: [],
  subscriberPaymentInfo: {
    stripe: {
      isConnected: false,
      stripeId: null,
    },
    paypal: {
      isConnected: false,
      paypalId: null,
    },
    markup: 1,
  },
}

export const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = { ...state, loading: payload }
      return alteredState
    },
    setRoom: (state, { payload }) => {
      const alteredState = { ...state, program: payload }
      return alteredState
    },
    setRooms: (state, { payload }) => {
      const alteredState = {
        ...state,
        rooms: payload,
      }
      return alteredState
    },
    setReservations: (state, { payload }) => {
      const alteredState = {
        ...state,
        reservations: payload,
      }
      return alteredState
    },
    setSubscriberStripeInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        subscriberPaymentInfo: {
          ...state.subscriberPaymentInfo,
          stripe: {
            isConnected: payload.isConnected,
            stripeId: payload.stripeId,
          },
        },
      }
      return alteredState
    },
    setSubscriberPaypalInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        subscriberPaymentInfo: {
          ...state.subscriberPaymentInfo,
          paypal: {
            isConnected: payload.isConnected,
            paypalId: payload.paypalId,
          },
        },
      }
      return alteredState
    },
    setSubscriberMarkupInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        subscriberPaymentInfo: {
          ...state.subscriberPaymentInfo,
          markup: payload,
        },
      }
      return alteredState
    },
    reset: () => initialState,
  },
})

export const useRoomSelector = () => useSelector((state) => state.room)
export const roomActions = roomSlice.actions
export const roomReducer = roomSlice.reducer

import { Tooltip } from 'antd'
import React from 'react'
import { BsInfoCircleFill } from 'react-icons/bs'

const LabelWithHelpText = ({ label, helpText }) => (
  <div
    className="t-flex t-items-center t-space-x-2"
    onClick={(e) => e.preventDefault()}
  >
    <p>{label}</p>
    {helpText && (
      <Tooltip title={helpText}>
        <BsInfoCircleFill className="t-text-secondary-100 t-cursor-pointer" />
      </Tooltip>
    )}
  </div>
)

export { LabelWithHelpText }

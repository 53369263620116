import React, { useEffect, useState } from 'react'
import { Avatar, Card, Skeleton, Space, Spin, Table, Tooltip } from 'antd'
import { IoMdAddCircleOutline } from 'react-icons/io'
import { awsconfig } from 'configs'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { FaEdit } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import sample from 'assets/images/sample/sample1.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { getProfileList } from 'store/organizationProfilesSlice/OrganizationProfilesActions'
import {
  organizationProfilesActions,
  useOrganizationProfileSelector,
} from 'store/organizationProfilesSlice/OrganizationProfilesReducer'
import { useDispatch } from 'react-redux'
import { UsedInModal } from './UsedInModal'

const OrganizationProfiles = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { organization } = useAuthSelector()
  const { loading, profileList, profile } = useOrganizationProfileSelector()
  const { fetchProfileList, deleting } = loading
  const [isVisible, setIsVisible] = useState(false)

  const getData = () => {
    dispatch(getProfileList({ organizationId: organization?.id }))
  }

  useEffect(() => {
    if (organization?.id) {
      getData()
    }
    // eslint-disable-next-line
  }, [])

  const columns = [
    {
      title: 'Logo',
      dataIndex: 'Logo',
      className: 'ant-table-row-cell-break-word',
      render: (_, { Logo }) => (
        <Avatar
          src={
            Logo && Logo.fileName ? awsconfig.bucketUrl + Logo.fileName : sample
          }
          alt="photo"
          className="mr-10"
          size={48}
          shape="square"
        />
      ),
    },
    {
      title: 'Profile Title',
      dataIndex: 'ProfileTitle',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Organization Name',
      dataIndex: 'OrganizationName',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Organization Email',
      dataIndex: 'OrganizationEmail',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Organization Website',
      dataIndex: 'OrganizationWebsite',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, item) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <FaEdit
              fontSize="1.2rem"
              className="t-text-secondary-100 t-cursor-pointer"
              onClick={() =>
                history.push(
                  `/organization/profile/edit/${item?.OrganizationId}/${item?.ProfileId}`,
                )
              }
            />
          </Tooltip>
          <Tooltip title="Delete">
            {deleting && profile?.ProfileId === item?.ProfileId ? (
              <Spin />
            ) : (
              <>
                <MdDelete
                  fontSize="1.3rem"
                  className="t-text-secondary-100 t-cursor-pointer"
                  onClick={() => {
                    dispatch(organizationProfilesActions.setProfile(item))
                    setIsVisible(true)
                  }}
                />
                {isVisible && (
                  <UsedInModal
                    isVisible={isVisible}
                    onClose={() => setIsVisible(false)}
                    getData={getData}
                  />
                )}
              </>
            )}
          </Tooltip>
        </Space>
      ),
    },
  ]

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={
        <div className="t-flex t-items-center t-space-x-2">
          <h6 className="mb-0 t-text-secondary-100 t-font-bold">
            Alternate Organization Profiles
          </h6>
          <>
            <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
              {profileList?.length < 10 ? (
                <>
                  <Tooltip title="Add Profile">
                    <IoMdAddCircleOutline
                      fontSize="1.7rem"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() => history.push('/organization/profile/add')}
                    />
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="Maximum profile reached">
                  <IoMdAddCircleOutline
                    fontSize="1.7rem"
                    className="t-text-secondary-100 t-opacity-50"
                  />
                </Tooltip>
              )}
            </div>
          </>
        </div>
      }
    >
      <Skeleton loading={fetchProfileList} active paragraph={{ rows: 8 }}>
        <Table
          columns={columns}
          dataSource={profileList || []}
          tableLayout="auto"
          scroll={{ x: 500 }}
          bordered={false}
          pagination={false}
        />
      </Skeleton>
    </Card>
  )
}

export { OrganizationProfiles }

import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: false,
  program: null,
  programs: [],
  organizationPrograms: [],
  allPrograms: [],
  inactive: [],
  registeredUsers: [],
  feedbackByUsers: [],
  subscriberPaymentInfo: {
    stripe: {
      isConnected: false,
      stripeId: null,
    },
    paypal: {
      isConnected: false,
      paypalId: null,
    },
    markup: 1,
  },
}

export const programSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = { ...state, loading: payload }
      return alteredState
    },
    setProgram: (state, { payload }) => {
      const alteredState = { ...state, program: payload }
      return alteredState
    },
    setPrograms: (state, { payload }) => {
      const filteredPrograms = payload.filter((item) => item.ActiveInd === 1)
      const alteredState = {
        ...state,
        programs: filteredPrograms,
      }
      return alteredState
    },
    setOrganizationPrograms: (state, { payload }) => {
      const filteredPrograms = payload.filter((item) => item.ActiveInd === 1)
      const inactivePrograms = payload.filter((item) => item.ActiveInd === 0)
      const alteredState = {
        ...state,
        organizationPrograms: filteredPrograms,
        inactive: inactivePrograms,
      }
      return alteredState
    },
    setAllPrograms: (state, { payload }) => {
      const filteredPrograms = payload.filter((item) => item.ActiveInd === 1)
      const alteredState = {
        ...state,
        allPrograms: filteredPrograms,
      }
      return alteredState
    },
    setRegisteredUsers: (state, { payload }) => {
      const alteredState = { ...state, registeredUsers: payload }
      return alteredState
    },
    setFeedbackByUsers: (state, { payload }) => {
      const alteredState = { ...state, feedbackByVolunteers: payload }
      return alteredState
    },
    setSubscriberStripeInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        subscriberPaymentInfo: {
          ...state.subscriberPaymentInfo,
          stripe: {
            isConnected: payload.isConnected,
            stripeId: payload.stripeId,
          },
        },
      }
      return alteredState
    },
    setSubscriberPaypalInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        subscriberPaymentInfo: {
          ...state.subscriberPaymentInfo,
          paypal: {
            isConnected: payload.isConnected,
            paypalId: payload.paypalId,
          },
        },
      }
      return alteredState
    },
    setSubscriberMarkupInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        subscriberPaymentInfo: {
          ...state.subscriberPaymentInfo,
          markup: payload,
        },
      }
      return alteredState
    },
    reset: () => initialState,
  },
})

export const useProgramSelector = () => useSelector((state) => state.program)
export const programActions = programSlice.actions
export const programReducer = programSlice.reducer

/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Layout } from 'antd'
import { Icon } from './Icon'
import { SVGIcon } from './SvgIcon'

const { Content } = Layout

const UserMessageView = ({
  backgroundImageName,
  heading,
  subHeading,
  redirectLink,
  redirectLinkText = 'Back Home',
}) => (
  <Content>
    <div className="ant-result ant-result-404">
      {backgroundImageName ? (
        <div className="t-flex t-justify-center t-items-center">
          <Icon src={backgroundImageName} width={300} height={300} />
        </div>
      ) : (
        <SVGIcon />
      )}
      {heading && <div className="ant-result-title">{heading}</div>}
      {subHeading && <div className="ant-result-subtitle">{subHeading}</div>}
      <div className="ant-result-extra">
        <a
          href={redirectLink || '/dashboard'}
          className="ant-btn ant-btn-primary"
          style={{ lineHeight: '40px' }}
        >
          {redirectLinkText}
        </a>
      </div>
    </div>
  </Content>
)

export { UserMessageView }

import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { MdLocationOn, MdEdit } from 'react-icons/md'
import { HiDuplicate } from 'react-icons/hi'
import { TbTarget } from 'react-icons/tb'
import {
  formatAmount,
  formateDateTime,
  getWebAppFundraiserPledgeUrl,
  getWebAppFundraiserUrl,
  isMobile,
  mobileShareNavigator,
} from 'utils'
import { useAuthSelector } from 'store/authSlice/authReducer'
// import {
//   addFavourite,
//   removeFavourite,
// } from 'store/favouriteSlice/favouriteActions'
import { useDispatch } from 'react-redux'
import { IoIosArrowBack } from 'react-icons/io'
import { awsconfig } from 'configs'
import {
  Row,
  Col,
  Card,
  Descriptions,
  Button,
  message,
  Typography,
  Tooltip,
  Skeleton,
  Carousel,
  Divider,
} from 'antd'
import {
  // AiOutlineHeart,
  AiFillEye,
  AiFillEyeInvisible,
  // AiFillHeart,
} from 'react-icons/ai'
import sample from 'assets/images/sample/fundraiser-default-image.png'
import { downloadQRCodePDF, sendEmail } from 'store/authSlice/authActions'
import { ShareModal, SVGIcon } from 'components'
import { useFavouriteSelector } from 'store/favouriteSlice/favouriteReducer'
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
  BsFillCalendarCheckFill,
} from 'react-icons/bs'
import {
  getFundraiserDetails,
  updateFundraiserStatus,
} from 'store/fundraiserSlice/fundraiserActions'
import {
  fundraiserActions,
  useFundraiserSelector,
} from 'store/fundraiserSlice/fundraiserReducer'
import { SendRemindersModal } from '../FundraiserControl/components/SendReminders'
import { DonationList } from './DonationList'

const { Title, Paragraph } = Typography

const FundraiserView = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const { state } = useLocation()

  const slider = useRef(null)
  const [isLoading, setLoading] = useState(false)
  const [isShare, setShare] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const { loading, fundraiser } = useFundraiserSelector()
  const { loading: favLoading } = useFavouriteSelector()
  const { user, organization } = useAuthSelector()

  const getFundraiserById = () => {
    dispatch(getFundraiserDetails(params?.id))
  }

  useEffect(() => {
    if (user?.email) {
      getFundraiserById()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email, params?.id])

  const handleDuplicate = () => {
    history.push(`/settings/create-fundraiser/${params?.id}?duplicate=true`)
  }

  const updateStatus = () => {
    dispatch(
      updateFundraiserStatus(params?.id, {
        status: fundraiser?.activeInd === 1 ? 0 : 1,
        organizationId: fundraiser?.organizationId,
      }),
    )
    history.push('/fundraisers')
  }

  const startControlling = () => {
    history.push(`/fundraisers/${params?.id}/control-screen`)
  }

  const joinFundraiser = () => {
    dispatch(fundraiserActions.setJoinedFundraiserDetails(null))
    history.push(`/fundraisers/${params?.id}/join-fundraiser`)
  }

  // const handleAddFavourite = async (id) => {
  //   const payload = {
  //     ReferenceId: id,
  //     Username: user?.id,
  //     Type: 'fundraiser',
  //   }
  //   dispatch(addFavourite(payload, getFundraiserById))
  // }

  // const handleRemoveFavourite = async (id) => {
  //   dispatch(removeFavourite(id, getFundraiserById))
  // }

  const sendVerificationEmail = async () => {
    setLoading(true)
    try {
      await sendEmail(user?.email, `${user.firstname} ${user.lastname}`)
      message.success('Email Sent Successfully')
      setLoading(false)
    } catch (error) {
      message.error('Error sending verification email')
      setLoading(false)
    }
  }

  const shareFundraiser = async () => {
    if (isMobile) {
      await mobileShareNavigator(
        fundraiser?.title,
        `${window.location.origin}/fundraisers/${params?.id}/join-fundraiser`,
      )
    } else {
      setShare(true)
    }
  }

  const printQR = async (printType) => {
    message.loading('Generating QR code')
    const type = 'fundraiser'

    const name = `${
      printType === 'donate'
        ? 'Donate to '
        : printType === 'join'
        ? 'Join '
        : 'Pledge to '
    }${fundraiser?.title}`

    let url =
      printType === 'donate'
        ? getWebAppFundraiserUrl(fundraiser?.iD, organization.OrgDomain)
        : getWebAppFundraiserPledgeUrl(fundraiser?.iD, organization.OrgDomain)

    if (printType === 'join') {
      url = `${window.location.href}/join-fundraiser`
    }

    const fundraisersToPrint = [
      {
        id: fundraiser?.iD,
        name,
        description: fundraiser?.description,
        url,
      },
    ]
    const payload = {
      data: fundraisersToPrint,
      component: 'Fundraisers',
      Organization: organization.Name,
      logo: awsconfig.bucketUrl + organization.Logo?.fileName,
    }
    try {
      await downloadQRCodePDF(payload, dispatch)
      message.success('QR code generated!')
      // history.push(`/download/${type}`)
      window.open(`${window.location.origin}/download/${type}`, '_blank')
    } catch (error) {
      console.error('Error generating QR code:', error)
      message.error('Failed to generate QR code. Please try again.')
    }
  }

  const isCreator = fundraiser?.organizationId === organization?.id
  const isPresenter = fundraiser?.presenterEmail === user?.email
  const isPresenterAccepted = fundraiser?.acceptanceStatus === 'Accepted'
  const isFundraiserStarted = fundraiser?.startFundraiser
  const isPresenterEmailValidated = user?.email_verified
  const isSeedDonor = fundraiser?.seedDonors?.includes(user?.email)

  const fundraiserLoading = loading || isLoading || favLoading

  return (
    <Row gutter={[24, 0]}>
      <Col span={24}>
        <Card
          title={[
            <div className="t-flex t-justify-between">
              <div className="t-flex t-items-center t-space-x-4">
                <Button
                  type="primary"
                  className="t-rounded-full t-flex t-items-center"
                  onClick={() =>
                    state && state.urlData
                      ? history.push({
                          pathname: state.urlData.pathname,
                          state: { key: state.urlData.activeKey },
                        })
                      : history.goBack()
                  }
                  size="small"
                >
                  <IoIosArrowBack />
                  Back
                </Button>
              </div>
              <div className="t-flex t-items-center t-space-x-2 t-cursor-pointer">
                {/* <div
                  className={`${
                    fundraiserLoading && 't-pointer-events-none t-opacity-50'
                  } ${!fundraiser && 't-pointer-events-none t-opacity-0'}`}
                >
                  {fundraiser?.isFav ? (
                    <Tooltip title="Remove Favorite">
                      <AiFillHeart
                        fontSize="1.8rem"
                        id="make-unfav"
                        className="t-cursor-pointer t-text-red-500"
                        onClick={() => handleRemoveFavourite(fundraiser?.favId)}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Make Favorite">
                      <AiOutlineHeart
                        fontSize="1.8rem"
                        id="make-fav"
                        className="t-cursor-pointer t-text-secondary-100"
                        onClick={() => handleAddFavourite(params?.id)}
                      />
                    </Tooltip>
                  )}
                </div> */}

                {isCreator && (
                  <>
                    <Tooltip title="Edit Fundraiser">
                      <MdEdit
                        fontSize="1.8rem"
                        id="edit"
                        className="t-text-secondary-100"
                        onClick={() =>
                          history.push(
                            `/settings/create-fundraiser/${params?.id}`,
                          )
                        }
                      />
                    </Tooltip>
                    <Tooltip title="Make Duplicate">
                      <HiDuplicate
                        fontSize="1.8rem"
                        id="duplicate"
                        className="t-text-secondary-100"
                        onClick={handleDuplicate}
                      />
                    </Tooltip>

                    {!(
                      fundraiser?.startFundraiser === undefined ||
                      fundraiser?.startFundraiser === true
                    ) && (
                      <>
                        {fundraiser?.activeInd === 1 ? (
                          <Tooltip title="Make Inactive">
                            <AiFillEyeInvisible
                              fontSize="1.8rem"
                              id="make-inactive"
                              className="t-text-secondary-100"
                              onClick={updateStatus}
                            />
                          </Tooltip>
                        ) : (
                          <AiFillEye
                            fontSize="1.8rem"
                            id="make-active"
                            className="t-text-secondary-100"
                            onClick={updateStatus}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>,
          ]}
          bordered={false}
          className="header-solid card-product mb-24"
        >
          <Skeleton loading={fundraiserLoading} active paragraph={{ rows: 18 }}>
            {fundraiser ? (
              <>
                <Row
                  gutter={[24, 0]}
                  style={{ minHeight: 'calc(100vh - 220px)' }}
                >
                  <Col
                    span={24}
                    lg={12}
                    xl={10}
                    className="col-gallery t-mb-4 md:t-mb-24"
                  >
                    {fundraiser?.flyer.length ? (
                      <div className="t-relative">
                        {fundraiser?.flyer.length > 1 && (
                          <BsFillArrowLeftCircleFill
                            fontSize="1.2rem"
                            onClick={() => slider.current.prev()}
                            className="t-text-secondary-100 t-cursor-pointer t-absolute t-top-[50%] t-left-[10px] t-z-10 t-hidden md:t-block"
                          />
                        )}
                        <Carousel ref={slider} slidesPerRow={1} autoplay>
                          {fundraiser?.flyer.map((flyerItem, i) => (
                            <img
                              src={
                                flyerItem?.fileName
                                  ? awsconfig.bucketUrl + flyerItem?.fileName
                                  : sample
                              }
                              className="t-h-40 md:t-h-96 t-w-full t-rounded-lg shadow-3 t-object-cover"
                              alt="card-2"
                              key={i}
                            />
                          ))}
                        </Carousel>
                        {fundraiser?.flyer.length > 1 && (
                          <BsFillArrowRightCircleFill
                            fontSize="1.2rem"
                            onClick={() => slider.current.next()}
                            className="t-text-secondary-100 t-cursor-pointer t-absolute t-top-[50%] t-right-[10px] t-z-10 t-hidden md:t-block"
                          />
                        )}
                      </div>
                    ) : (
                      <img
                        src={
                          fundraiser?.flyer?.length
                            ? fundraiser?.flyer[0]
                            : fundraiser?.organization?.backgroundImage
                            ? fundraiser?.organization?.backgroundImage
                            : sample
                        }
                        className="t-h-40 md:t-h-96 t-w-full t-rounded-lg shadow-3 t-object-cover"
                        alt="card-2"
                      />
                    )}
                  </Col>
                  <Col span={24} xl={12} lg={{ offset: 1 }} className="t-p-4">
                    <div className="t-mb-4">
                      <h1 className="t-text-secondary-100 t-text-2xl md:t-text-4xl t-font-bold">
                        {fundraiser?.title}
                      </h1>
                      <h1 className="t-text-secondary-100 t-text-lg md:t-text-2xl">
                        {fundraiser?.organizationName}
                      </h1>
                      <Title level={4} className="t-text-secondary-100"></Title>
                      <Descriptions colon={false} className="t-my-4">
                        <Descriptions.Item
                          label={
                            <Tooltip title="Start At">
                              <BsFillCalendarCheckFill className="t-text-secondary-100 t-ml-1" />
                            </Tooltip>
                          }
                          span={3}
                        >
                          <Tooltip title="MM-DD-YYYY hh:mm A">
                            {formateDateTime(fundraiser?.startDate)}
                          </Tooltip>
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <Tooltip title="Zip code">
                              <MdLocationOn
                                fontSize="1.2rem"
                                className="t-text-secondary-100"
                              />
                            </Tooltip>
                          }
                          span={3}
                        >
                          {fundraiser?.zipCode}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <Tooltip title="Goal">
                              <TbTarget
                                fontSize="1.2rem"
                                className="t-text-secondary-100"
                              />
                            </Tooltip>
                          }
                          span={3}
                        >
                          {formatAmount(fundraiser?.targetAmount)}
                        </Descriptions.Item>
                      </Descriptions>
                      <Title level={4} className="t-text-secondary-100">
                        Description
                      </Title>
                      <Paragraph className="t-text-justify">
                        {fundraiser?.Details}
                      </Paragraph>
                      <div className="t-flex t-space-x-2 t-flex-wrap">
                        {((isPresenter &&
                          isPresenterAccepted &&
                          isPresenterEmailValidated) ||
                          isCreator) && (
                          <Button
                            type="primary"
                            id="controlButton"
                            className="t-my-1"
                            onClick={() => startControlling()}
                          >
                            Control
                          </Button>
                        )}
                        {isFundraiserStarted && (
                          <Button
                            type="primary"
                            id="joinFormButton"
                            className="t-my-1"
                            onClick={joinFundraiser}
                          >
                            Join
                          </Button>
                        )}
                        {(isPresenter || isCreator) && (
                          <Button
                            type="primary"
                            className="t-my-1"
                            onClick={() => setIsModalVisible(true)}
                          >
                            Send Pledge Reminders
                          </Button>
                        )}
                        <Button
                          type="primary"
                          className="t-my-1"
                          onClick={shareFundraiser}
                        >
                          Share
                        </Button>
                      </div>
                      <div className="t-flex t-space-x-2 t-flex-wrap">
                        <Button
                          type="primary"
                          className="t-my-1"
                          onClick={() => printQR('donate')}
                        >
                          Print Donate QR
                        </Button>
                        <Button
                          type="primary"
                          className="t-my-1"
                          onClick={() => printQR('pledge')}
                        >
                          Print Pledge QR
                        </Button>
                        <Button
                          type="primary"
                          className="t-my-1"
                          onClick={() => printQR('join')}
                        >
                          Print Join QR
                        </Button>
                      </div>
                      <div className="t-my-2">
                        {isPresenter &&
                          isPresenterAccepted &&
                          !isPresenterEmailValidated && (
                            <div className="t-flex t-space-x-2 t-items-center">
                              <h1 className="t-text-red-500 t-font-bold">
                                Kindly Validate Your Email Before Presenting The
                                Fundraiser
                              </h1>
                              <Button
                                type="primary"
                                size="small"
                                loading={isLoading}
                                onClick={sendVerificationEmail}
                              >
                                Verify Your Email
                              </Button>
                            </div>
                          )}
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <h6 className="t-font-bold t-text-base">Donations</h6>
                    <Divider />
                    <DonationList id={params?.id} />
                  </Col>
                </Row>
                {!isFundraiserStarted && isSeedDonor && (
                  <Row className="t-flex t-justify-end t-items-center t-space-x-6">
                    <Paragraph>
                      Fundraiser is not yet started. But you can make donations
                      before the start of fundraiser.
                    </Paragraph>
                    <Button
                      type="primary"
                      href={getWebAppFundraiserUrl(
                        fundraiser.iD,
                        fundraiser?.organization.domain,
                      )}
                      target="_blank"
                    >
                      Click To Donate
                    </Button>
                  </Row>
                )}
              </>
            ) : (
              <div className="ant-result ant-result-404">
                <SVGIcon />
                <div className="ant-result-title">Invalid URL</div>
                <div className="ant-result-subtitle">
                  Sorry, the page you visited does not exist.
                </div>
                <div className="ant-result-extra">
                  <Button
                    onClick={() => history.push('/volunteer')}
                    type="primary"
                    size="small"
                  >
                    Goto Volunteers
                  </Button>
                </div>
              </div>
            )}
          </Skeleton>
        </Card>
      </Col>
      <SendRemindersModal
        id={params?.id}
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      />
      <ShareModal
        // url={`${window.location.origin}/donation/${params?.id}`}
        url={`${awsconfig.API.endpoints[0].api_url}/html/share-fundraiser/${params?.id}`}
        visible={isShare}
        onClose={() => setShare(false)}
      />
    </Row>
  )
}

export { FundraiserView }

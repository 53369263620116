import { Button, Col, Form, Input, Modal, Row, Select, message } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
// import { ImageUploader } from 'components/elements'
import React, { useEffect } from 'react'
import { auctionItemValidation } from 'validations/auctionItem'
import Api from 'api/apiv2'
import { useDispatch } from 'react-redux'
import { numberWithCommas } from 'utils'
import { getFundraiserAuctionItems } from 'store/fundraiserSlice/fundraiserActions'

const priorityTypes = ['Low', 'Medium', 'High']

const initialValues = {
  title: '',
  quantity: '',
  description: '',
  // startBid: '',
  winningBid: '',
  priority: 'Low',
  itemImage: null,
}

const EditAuctiontemModal = ({ visible, close, selected, fundraiserId }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  Form.useWatch('itemImage', form)

  useEffect(() => {
    console.log('selected', selected)
    if (selected) {
      form.setFieldsValue({
        title: selected.title,
        description: selected.description,
        // startBid: '',
        winningBid: selected.winningBid,
        priority: selected.priority,
        // itemImage: null,
      })
    }
    // eslint-disable-next-line
  }, [visible])

  const onFinish = (values) => {
    if (fundraiserId) {
      const data = {
        type: 'fundraiser',
        recordId: selected.id,
        itemId: selected.id,
        title: values.title,
        description: values.description,
        priority: values.priority,
        winningBid: values.winningBid,
      }
      Api.put(`admin/fundraisers/${fundraiserId}/auction-item`, data)
        .then(() => {
          dispatch(getFundraiserAuctionItems(fundraiserId))
          message.success('Item updated successfully')
          form.resetFields()
          close()
        })
        .catch((error) => {
          console.log('error', error)
          message.error(
            error?.response?.data?.message || 'Something went wrong',
          )
        })
    }
  }

  // const onFileChange = (file) => {
  //   form.setFieldsValue({ itemImage: file })
  // }

  // const handleQualityChange = (value) => {
  //   const val = value.replace(/\D/g, '')
  //   form.setFieldsValue({ quantity: val })
  // }

  const handleAmountChange = (name, value) => {
    const withoutCommas = value.replaceAll(/,/g, '')
    const val = withoutCommas.replace(/\D/g, '')
    form.setFieldsValue({ [name]: numberWithCommas(val) })
  }

  return (
    <Modal
      centered
      keyboard
      title="Add Auction Item"
      open={visible}
      onCancel={() => {
        form.resetFields()
        close()
      }}
      footer={null}
      destroyOnClose
    >
      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="add-items"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            {/* <Col span={24}>
              <Form.Item
                name="itemImage"
                label="Item Image"
                colon={false}
                rules={[auctionItemValidation]}
              >
                <ImageUploader onFileChange={onFileChange} />
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <Form.Item
                name="title"
                label="Title"
                colon={false}
                rules={[auctionItemValidation]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                name="quantity"
                label="Quantity"
                colon={false}
                rules={[auctionItemValidation]}
              >
                <Input
                  inputMode="numeric"
                  onChange={(e) => handleQualityChange(e.target.value)}
                />
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <Form.Item
                name="description"
                label="Description"
                colon={false}
                rules={[auctionItemValidation]}
              >
                <TextArea
                  placeholder="Item Description"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                name="startBid"
                label="Start Bid"
                colon={false}
                rules={[auctionItemValidation]}
              >
                <Input
                  inputMode="numeric"
                  prefix="$"
                  onChange={(e) =>
                    handleAmountChange('startBid', e.target.value)
                  }
                />
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item
                name="winningBid"
                label="Winning Bid"
                colon={false}
                rules={[auctionItemValidation]}
              >
                <Input
                  inputMode="numeric"
                  prefix="$"
                  onChange={(e) =>
                    handleAmountChange('winningBid', e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="priority"
                label="Priority"
                colon={false}
                // rules={[eventValidation]}
              >
                <Select placeholder="Priority" style={{ width: '100%' }}>
                  {priorityTypes.map((item, index) => (
                    <Select.Option value={item} key={index}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* {isCampaign && (
              <Col span={12}>
                <Form.Item
                  name="endDate"
                  label="End Date"
                  colon={false}
                  rules={[auctionItemValidation]}
                >
                  <DatePicker
                    format="MM-DD-YYYY hh:mm A"
                    showTime
                    minuteStep={15}
                    className="t-w-full"
                    disabledDate={(d) =>
                      !d ||
                      d.isBefore(moment().subtract(1, 'day')) ||
                      d.isBefore(moment(defaultStartDate).startOf('day'))
                    }
                    disabledTime={disabledTime}
                    onChange={(selectedDate) =>
                      !compareDateTimeWithNow(selectedDate, 'before') &&
                      form.setFieldsValue({
                        endDate: moment(),
                      })
                    }
                  />
                </Form.Item>
              </Col>
            )} */}
            <Col span={12}>
              <div className="t-h-full t-flex t-items-center">
                <Button type="primary" htmlType="submit">
                  Edit Item
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>
    </Modal>
  )
}

export default EditAuctiontemModal

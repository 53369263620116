import { REGEXS } from 'utils'
import * as Yup from 'yup'

const amountValidation = REGEXS.amountRegex

const schema = Yup.object().shape({
  Title: Yup.string().required('Title is Required'),
  Description: Yup.string()
    .required('Description is Required')
    .min(10, 'Description should be atleast 10 character')
    .max(256, 'Description should less than 256 characters'),
  Amount: Yup.string()
    .required('Amount is Required')
    .matches(amountValidation, 'Invalid amount'),
})

const memberTierValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { memberTierValidation }

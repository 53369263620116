import React, { useEffect, useState } from 'react'
import {
  Card,
  Input,
  Skeleton,
  Spin,
  Table,
  Tooltip,
  message,
  Space,
  Typography,
} from 'antd'
import { MdOutlineSimCardDownload } from 'react-icons/md'
import { SearchOutlined } from '@ant-design/icons'
import {
  downloadCSV,
  formatAmount,
  formateDateTimeToLocal,
  searchYTDDonationsRows,
} from 'utils'
import Api from 'api'
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { useAuthSelector } from 'store/authSlice/authReducer'

const YtdProgramAndServiceForOrganization = () => {
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const eventType = searchParams.get('eventType')
  const { organization } = useAuthSelector()
  const [loading, setLoading] = useState(true)
  const [searchText, setSearchText] = useState(null)
  const [data, setData] = useState([])
  const [totalDonation, setTotalDonation] = useState(0)

  const { Title, Text } = Typography

  const getData = () => {
    Api.get(
      `reports/YearToDateProgramAndServiceForOrganization/${organization?.id}?eventType=${eventType}`,
    )
      .then((res) => {
        let total = 0 // Initialize total donation amount

        const list = res.results
        const donorPhoneNumbers = Object.keys(list)
        // Convert the object into an array of objects
        const arrayOfObjects = donorPhoneNumbers.map((phoneNumber) => {
          const donorInfo = list[phoneNumber]
          total += parseFloat(donorInfo.GrossAmount) // Convert to float and add to the total

          return {
            GrossAmount: donorInfo.GrossAmount,
            NetAmount: donorInfo.NetAmount,
            LastDonationDates: donorInfo.LastDonationDate,
            DonorName: donorInfo.DonorName,
            DonorEmail: donorInfo.DonorEmail,
            DonorPhoneNumber: donorInfo.DonorPhoneNumber,
            LastDonationDate: formateDateTimeToLocal(
              donorInfo.LastDonationDate,
              'date',
            ),
            LastDonationTime: formateDateTimeToLocal(
              donorInfo.LastDonationDate,
              'time',
            ),
          }
        })
        setData(arrayOfObjects)
        setTotalDonation(total) // Update state with the calculated total

        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        message.error(error?.exceptionMessage)
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  const csvColumns = [
    'DonorName',
    'DonorEmail',
    'DonorPhoneNumber',
    'GrossAmount',
    'NetAmount',
    'LastDonationDate',
    'LastDonationTime',
  ]
  const csvHeaderMapping = {
    DonorName: 'Name',
    DonorEmail: 'Email',
    DonorPhoneNumber: 'Phone Number',
    GrossAmount: 'Gross',
    NetAmount: 'Net',
    LastDonationDate: 'Date',
    LastDonationTime: 'Time',
  }
  const columns = [
    {
      title: 'Donor Check Name',
      dataIndex: 'DonorName',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Donor Email',
      dataIndex: 'DonorEmail',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Donor Phone Number',
      dataIndex: 'DonorPhoneNumber',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Gross',
      dataIndex: 'GrossAmount',
      className: 'ant-table-row-cell-break-word text-right align-right',
      render: (amount) => formatAmount(amount),
      align: 'center',
    },
    {
      title: 'Net',
      dataIndex: 'NetAmount',
      className: 'ant-table-row-cell-break-word text-right align-right',
      render: (amount) => formatAmount(amount),
      align: 'center',
    },
    {
      title: 'Last Donation',
      dataIndex: 'LastDonationDates',
      className: 'ant-table-row-cell-break-word',
      render: (time) => formateDateTimeToLocal(time, 'datetime'),
      sorter: (a, b) => {
        const dateA = new Date(a.LastDonationDates)
        const dateB = new Date(b.LastDonationDates)
        return dateB - dateA
      },
      sortDirections: ['descend', 'ascend'],
    },
  ]

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={
        <div className="t-flex t-flex-wrap t-justify-between">
          <div className="t-flex t-space-x-4">
            <IoMdArrowRoundBack
              fontSize="1.5rem"
              className="t-cursor-pointer t-min-w-[1.5rem]"
              id="back"
              onClick={() => history.push('/reports')}
            />
            <h5 className="font-semibold">
              {eventType === 'all'
                ? 'YTD Earning'
                : eventType === 'program'
                ? 'YTD Program Donations'
                : 'YTD Service Donations'}
            </h5>
          </div>
          <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
            <div className="header-control t-mt-0">
              <Input
                className="header-search t-w-36"
                placeholder="Search ..."
                prefix={<SearchOutlined />}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
            </div>
            <Tooltip title="Download">
              <MdOutlineSimCardDownload
                fontSize="1.7rem"
                id="download"
                className="t-text-secondary-100 t-cursor-pointer"
                onClick={() =>
                  downloadCSV(
                    data,
                    csvColumns,
                    csvHeaderMapping,
                    'YTD Donations',
                  )
                }
              />
            </Tooltip>
          </div>
        </div>
      }
    >
      <div style={{ marginBottom: 20, marginLeft: '2%' }}>
        <Space direction="vertical">
          <Title level={5}>
            Total Donation:{' '}
            <span style={{ marginLeft: 10 }}>
              {loading ? (
                <Spin />
              ) : (
                <Text type="success">
                  $
                  {totalDonation.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
              )}
            </span>
          </Title>
        </Space>
      </div>
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <Table
          tableLayout="auto"
          scroll={{ x: 500 }}
          columns={columns}
          dataSource={searchYTDDonationsRows(data, searchText) || []}
          bordered={false}
          pagination={false}
          className="td-right"
        />
      </Skeleton>
    </Card>
  )
}

export { YtdProgramAndServiceForOrganization }

import React from 'react'

const data = [
  {
    title: 'DEFINITIONS',
    content: (
      <>
        <p>
          “Event” means an orchestrated occurrence of activities conducted by
          representatives of our Customers (hereinafter “Subscribers”)
        </p>
        <p>
          “Campaign” means a cause, goal, or event for which a Subscriber seeks
          donations through the Services.
        </p>
        <p>
          “Subscriber” means an individual or organization who creates or
          manages an Event, Campaign, and / or coordinate Volunteer
          Opportunities.
        </p>
        <p>
          “Donor” means an individual or organization who donates to an Event or
          Campaign.
        </p>
        <p>
          “User” means any individual or organization that utilizes or consumes
          Services, with or without creating a user account.
        </p>
      </>
    ),
  },
  {
    title: 'ELIGIBILITY',
    content: (
      <p>
        To use the Services you must be, and represent and warrant that you are,
        of legal age (18 years of age or older or otherwise of legal age in your
        jurisdiction, or, if you have parental consent, 13 years of age) and
        competent to agree to these Terms. If Extensia has previously prohibited
        you from accessing or using the Services, you are not permitted to
        access or use the Services.
      </p>
    ),
  },
  {
    title: 'ADDITIONAL TERMS AND POLICIES',
    content: (
      <p>
        Please review our Privacy Policy , incorporated herein by reference, for
        information and notices concerning our collection and use of your
        information. Certain areas of and/or products on the Services may have
        different terms and conditions posted, which may require you to agree
        with and accept such additional terms and conditions or policies. If
        there is a conflict between these Services and the terms and conditions
        or policies posted for a specific area or product, the latter take
        precedence with respect to your use of that area or product.
      </p>
    ),
  },
  {
    title: 'MODIFICATIONS',
    content: (
      <p>
        We may, from time to time, modify these Terms. Please check this page
        periodically for updates. Any changes will be posted on the Services. If
        you do not agree to, or cannot comply with, the modified Terms, you must
        stop using the Services. The updated Terms will take effect after their
        posting and will apply on a going-forward basis, unless otherwise
        provided in a notice to you, and except as provided in the Arbitration
        and Class Action Waiver section of these Terms. Your continued use of
        the Services after any such update constitutes your binding acceptance
        of such changes.
      </p>
    ),
  },
  {
    title: 'ACCOUNT REGISTRATION',
    content: (
      <>
        <p>
          You may be required to create an account to use the Services. You
          agree to provide us with accurate, complete, and current registration
          information about yourself. As applicable, if you are prompted to use
          a user ID or password, it is your responsibility to ensure that your
          user ID password remains confidential and secure. By registering, you
          agree that you are fully responsible for all activities that occur
          under your account. We may assume that any communications we receive
          under your account have been made by you.
        </p>
        <p>
          You are responsible for notifying us at{' '}
          <a href="mailto:support@Extensia.com">support@Extensia.com</a> if you
          become aware of any unauthorized use of or access to your account. You
          understand and agree that we may require you to provide information
          that may be used to confirm your identity and help ensure the security
          of your account. Extensia will not be liable for any loss, damages,
          liability, expenses, or attorneys’ fees that you may incur as a result
          of someone else using your password or account, either with or without
          your knowledge and/or authorization, and regardless of whether you
          have or have not advised us of such unauthorized use. You will be
          liable for losses, damages, liability, expenses, and attorneys’ fees
          incurred by Extensia or a third party due to someone else using your
          account.
        </p>
      </>
    ),
  },
  {
    title: 'INTELLECTUAL PROPERTY AND OTHER PROPRIETARY RIGHTS',
    content: (
      <p>
        The Services are owned and operated by Extensia and contain materials
        (including all software, design, text, editorial materials,
        informational text, photographs, illustrations, audio clips, video
        clips, artwork and other graphic materials, and names, logos, trademarks
        and services marks) which are derived in whole or in part from materials
        supplied by Extensia and its partners, as well as other sources, and is
        protected by United States copyright laws, international treaty
        provisions, trademarks, service marks and other intellectual property
        laws. The Services are also protected as a collective work or
        compilation under U.S. copyright and other law and treaties. You agree
        to abide by all applicable copyright and other laws, as well as any
        additional copyright notices or restrictions contained in the Services.
        You acknowledge that the Services have been developed, compiled,
        prepared, revised, selected, and arranged by Extensia and others through
        the application of methods and standards of judgment developed and
        applied through the expenditure of substantial time, effort, and money
        and constitute valuable intellectual property of Extensia and such
        others. You agree to protect the proprietary rights of Extensia and all
        others having rights in the Services during and after the term of these
        Terms and to comply with all reasonable written requests made by
        Extensia or its suppliers and licensors of content or otherwise to
        protect their and others’ contractual, statutory, and common law rights
        in the Services. You agree to notify Extensia immediately upon becoming
        aware of any claim that the Services infringe upon any copyright,
        trademark, or other contractual, statutory, or common law rights. All
        present and future rights in and to trade secrets, patents, copyrights,
        trademarks, service marks, know- how, and other proprietary rights of
        any type under the laws of any governmental authority, domestic or
        foreign, including without limitation rights in and to all applications
        and registrations relating to the Services shall, as between you and
        Extensia, at all times be and remain the sole and exclusive property of
        Extensia.
      </p>
    ),
  },
  {
    title: 'USER CONTENT',
    content: (
      <>
        <p className="t-font-semibold">User Content and Submissions</p>
        <p>
          The Services may enable you to submit, post, upload, or otherwise make
          available (collectively, “Post”) content such as Events, Campaigns,
          photographs, questions, ideas, comments, and other content
          (collectively, “User Content”) that may or may not be viewable by
          other users.
        </p>
        <p className="t-font-semibold">Our License to User Content</p>
        <p>
          When you Post User Content on or through the Services, you grant
          Extensia a world-wide, perpetual, irrevocable, royalty-free,
          non-exclusive, and sub-licenseable license to use, copy, distribute,
          reproduce, modify, edit, adapt, publicly perform, publicly display,
          translate, create derivative works from, sell, lease, transmit,
          disassemble, and publish such User Content or feedback, in whole or in
          part, in any format or medium now known or developed in the future,
          including without limitation for promoting and redistributing part or
          all of the Services (and derivative works thereof) in any media
          formats and through any media channels. Notwithstanding the foregoing,
          our use of your personal data shall be governed by the Extensia
          Privacy Policy.
        </p>
        <p className="t-font-semibold">User Content Representations</p>
        <p>
          You acknowledge and agree that all User Content that you Post is your
          sole responsibility. You further agree that you have all required
          rights to Post such User Content without violation of any third-party
          rights. You understand that Extensia does not control, and is not
          responsible for, User Content, and that by using the Services, you may
          be exposed to User Content from other users that is offensive,
          indecent, inaccurate, misleading, or otherwise objectionable. Please
          also note that User Content may contain typographical errors, other
          inadvertent errors, or inaccuracies. You agree that you will
          indemnify, defend, and hold harmless Extensia for all claims resulting
          from User Content you Post. We reserve the right, at our own expense,
          to assume the exclusive defense and control of such disputes, and in
          any event you will cooperate with us in asserting any available
          defenses.
        </p>
        <p className="t-font-semibold">User Content Review</p>
        <p>
          You acknowledge and agree that Extensia and its designees may or may
          not, at Extensia’s discretion, pre-screen User Content Extensia its
          appearance on the Services, but that Extensia has no obligation to do
          so. You further acknowledge and agree that Extensia reserves the right
          (but does not assume the obligation) in its sole discretion to reject,
          move, edit, or remove any User Content that is contributed to the
          Services. Without limiting the foregoing, Extensia and its designees
          shall have the right to remove any User Content that violates these
          Terms or is otherwise objectionable in Extensia&apos;s sole
          discretion. You acknowledge and agree that Extensia does not verify,
          adopt, ratify, or sanction User Content, and you agree that you must
          evaluate and bear all risks associated with your use of User Content
          or your reliance on the accuracy, completeness, or usefulness of User
          Content.
        </p>
      </>
    ),
  },
  {
    title: 'LICENSE',
    content: (
      <p>
        Subject to your compliance with these Terms, we grant you a limited,
        non-exclusive, non-sublicensable, non-transferable, and revocable right
        to access and use the Services only for your own use, and only in a
        manner that complies with all legal requirements that apply to you or
        your use of the Services. Extensia may revoke this license at any time,
        in its sole discretion.
      </p>
    ),
  },
  {
    title: 'PROHIBITED USES',
    content: (
      <>
        <p>
          Use of the Services for any illegal purpose, or any other purpose not
          expressly permitted in these Terms, is strictly prohibited. Without
          limitation, you will not:
        </p>
        <p>
          1. Post User Content that is polemic, sectarian, or divisive in its
          nature;
        </p>
        <p>2. Post User Content that supports violence or terrorism;</p>
        <p>
          {`3. Post User Content that harasses, abuses, or threatens any other
          person, or that contains obscene content; is false, misleading, or
          inaccurate; degrades others on the basis of gender, race, class,
          ethnicity, national origin, religion, sexual preference, disability or
          any other classification; is unlawful, harmful, tortious, defamatory,
          libelous, or invasive of another's privacy;`}
        </p>
        4. Copy, download (other than through page caching necessary for
        personal use, or as otherwise expressly permitted by these Terms),
        modify, distribute, post, transmit, display, perform, reproduce,
        broadcast, duplicate, publish, republish, upload, license, reverse
        engineer, or create derivative works from any content or other
        information contained on or obtained from or through the Services, by
        any means except as provided for in these Terms or expressly provided by
        Extensia;
        <p>
          5. Scrape, access, monitor, index, frame, link, or copy any content or
          information on the Services by accessing the Services in an automated
          way, using any robot, spider, scraper, web crawler, or any other
          method of access other than manually accessing the publicly available
          portions of the Services through a web browser;
        </p>
        <p>
          6. Violate the restrictions in any robot exclusion headers of the
          Services, if any, or bypass or circumvent other measures employed to
          prevent or limit access to the Services;
        </p>
        <p>
          7. Post material that advocates illegal activity or discusses illegal
          activities with the intent to commit them;
        </p>
        <p>
          {`8. Post or otherwise make available any material that contains any
          software, device, instructions, computer code, files, programs and/or
          other content or feature that is designed to interrupt, destroy, harm,
          or limit the functionality of any computer software or hardware or
          telecommunications equipment (including without limitation any time
          bomb, virus, malware, software lock, worm, self-destruction,
          drop-device, malicious logic, Trojan horse, trap door, "disabling,"
          "lock out," "metering" device or any malicious code);`}
        </p>
        <p>
          9. Post or do anything that could disable, overburden, or impair the
          proper working of the Services;
        </p>
        <p>
          10. Post material that impedes or otherwise prohibits communication or
          disrupts user discussion;
        </p>
        <p>
          {`11. Post, utilize or otherwise make available any other party's
          intellectual property unless you have the right to do so, or remove or
          alter any copyright, trademark or other proprietary notice contained
          on the Services;`}
        </p>
        <p>
          12. Post any trade secrets or other confidential information, or post
          any material that you do not have a right to make available under any
          law or under contractual or fiduciary relationships, including but not
          limited to inside information, proprietary and confidential
          information learned or disclosed as part of employment relationships
          or under non-disclosure agreements;
        </p>
        <p>
          13. Falsely state or otherwise misrepresent your affiliation with a
          person or entity, or impersonate any person or entity in a manner that
          does not constitute parody;
        </p>
        <p>
          14. Solicit personal or sensitive information from other users for
          purposes not permitted by these Terms;
        </p>
        <p>
          15. Send spam or other advertisements or solicitations, surveys,
          contents, pyramid schemes, promote commercial entities, or otherwise
          engage in commercial activity on or through the Services;
        </p>
        <p>
          16. Frame, inline link, or similarly display the Services or any
          portion of the Services; or
        </p>
        <p>
          17. Interfere with any other party’s use and enjoyment of the
          Services.
        </p>
      </>
    ),
  },
  {
    title: 'NO ENDORSEMENT',
    content: (
      <>
        <p>
          Extensia provides a crowd-funding platform that allows individuals or
          organizations to orchestrate events, campaigns, solicit volunteers,
          and raise money. We do not endorse any Events, Campaigns, donors,
          individuals, or organizations, and Extensia is not a party to any
          agreements between or among our users or third parties. No agency,
          partnership, joint venture, or employment is created as a result of
          the Terms or any user’s use of the Services. Extensia does not engage
          in any solicitation activities. While Extensia has the right to
          monitor Events or Campaigns and donations in accordance with these
          Terms and our Privacy Policy, Extensia has no obligation to do so, and
          we do not make any representations about, confirm, or endorse any
          Events, Campaigns, Donors, individuals, or organizations. Nor does
          Extensia make any representations about, confirm, or verify that
          donations will be used in accordance with any fundraising purpose set
          forth in a Campaign. By using the Services, you understand and agree
          that Extensia is not responsible for the use of your donations or the
          amount of funds raised for your Event or Campaign.
        </p>
        <p>
          DONORS ARE SOLELY RESPONSIBLE FOR ASSESSING THE VALUE AND
          APPROPRIATENESS OF CONTRIBUTING TO ANY CAMPAIGN. WE ENCOURAGE DONORS
          TO USE THEIR DISCRETION WHEN SUPPORTING CAMPAIGNS.
        </p>
        <p>
          Though we have no obligation to verify that the accuracy of Events or
          Campaigns and the use of donations, we take possible fraudulent
          activity and the misuse of funds raised very seriously. If you have
          reason to believe that a Subscriber is not raising or using the funds
          as stated in the Campaign, please alert us of this potential issue by
          emailing us at support@Extensia.com.
        </p>
      </>
    ),
  },
  {
    title: 'USER DISPUTES',
    content: (
      <p>
        You agree that you are solely responsible for your interactions with any
        other user in connection with the Services and Extensia will have no
        liability or responsibility with respect thereto. Extensia reserves the
        right, but has no obligation, to become involved in any way with
        disputes between you and any other user of the Services and any third
        party relating to the use of the Services.
      </p>
    ),
  },
  {
    title: 'PAYMENTS',
    content: (
      <>
        <p>
          Extensia works with various payment providers to ensure we can send
          you funds in timely matter. We try as best as we can to screen initial
          acceptance with our payment providers but in some rare cases/times
          campaigns may not meet their risk appetite even after approval, we may
          have to refund donations in such cases. Although there are no platform
          fees, industry-standard payment processing fees apply for all refunds
          and these processing fees. Extensia reserves the right to deduct these
          fees and provide remaining refund as appropriate. In cases where
          Donors are not charged the refund processing fees, it is the
          responsibility of the Subscriber to reimburse Extensia for such fees.
        </p>
        <p>
          Funds will be sent to Subscribers within 30 days after the successful
          completion of a Campaign barring any unforeseen circumstances such as
          (1) a possible violation of the Terms or any other law, rule, or
          regulation, (2) inaccurate information associated with the Event or
          Campaign or any donations which can delay the transfer of funds, or
          (3) delay based on Holds as set forth in the Account Suspensions
          section below.
        </p>
        <p>
          Without limiting any other rights we have, we may refund all donations
          to an Event or Campaign if we have not received sufficient Campaign
          account information or accurate information to transfer funds within
          30 days after the Campaign closes. Questions about refunds may be
          directed to us at support@Extensia.com.
        </p>
        <p className="t-font-semibold">Perks and Incentives</p>
        <p>
          Subscribers may be given an opportunity to offer goods, promotions, or
          services (“Perks and Incentives”) to Donors of their Events or
          Campaigns. Subscribers are solely responsible for delivering Perks and
          Incentives promised to Donors. Failure to provide Perks and Incentives
          to Donors as promised may result in a various penalties including but
          not limited to; reimbursement of Donors at the Event or Campaign’s
          expense, and suspension of accounts and current and future Campaigns
          by the Subscriber.
        </p>
      </>
    ),
  },
  {
    title: 'TAXES',
    content: (
      <>
        <p>
          Each Subscriber is responsible for determining what, if any, taxes
          apply to the donations received through the Services. Subscribers are
          responsible for assessing, collecting, reporting, or remitting the
          correct tax, if any, to the appropriate tax authority.
        </p>
        <p>
          Extensia makes no representation as to whether all or any portion of
          your donations, including, if any, transaction fees, are tax
          deductible or eligible for tax credits. Extensia will have no
          liability for any claim by any federal, state, provincial,
          territorial, local or any other tax authority with respect to the
          characterization on any applicable tax return of any donation by any
          Donor. You should consult your tax advisor as to the amount of your
          donation that is tax-deductible or eligible for tax recognition,
          having regard to (among other things) the tax status of the recipient
          of any donation in any relevant jurisdiction, and particularly if you
          are awarded Perks and Incentives in connection with your donation.
        </p>
      </>
    ),
  },
  {
    title: 'ACCOUNT SUSPENSIONS',
    content: (
      <p>
        From time to time, Extensia may (1) place a hold on a Subscriber
        account, suspending the ability of a Subscriber to obtain donations made
        to the Event or Campaign and/or (2) place a hold of transfer of funds
        already raised (either or collectively a “Hold”). We may place a Hold
        (and refund all donations made to that particular Event or Campaign to
        the Member(s) who made such donations) if: (i) we have reason to believe
        (in our sole discretion) that information provided by a Subscriber is
        false, misleading, or fraudulent, or that funds are being used in a
        prohibited manner, (ii) the funds available should be provided directly
        to a person other than the Subscriber (such as a legal beneficiary or
        person entitled by law to act on behalf of a Subscriber), (iii) we have
        reason to believe that an Event, a Campaign or a Subscriber has violated
        these Terms, or (iv) required in order to comply with a court order,
        subpoena, writ, injunction, or as otherwise required under applicable
        laws and regulations.If you have questions about a Hold we may have
        placed on your Campaign account, or need information about how to
        resolve the Hold, please contact us at support@Extensia.com.
      </p>
    ),
  },
  {
    title: 'LEGAL COMPLIANCE',
    content: (
      <p>
        You acknowledge, consent, and agree that Extensia may access, preserve,
        and disclose your information and/or any User Content you submit or make
        available for inclusion on the Services, if required to do so by law or
        in a good faith belief that such access, preservation, or disclosure is
        permitted by Extensia’s Privacy Policy or reasonably necessary or
        appropriate for any of the following reasons: (1) to comply with legal
        process; (2) to enforce these Terms, our Privacy Policy, or other
        contracts with you, including investigation of potential violations
        thereof; (3) to respond to claims that any content violates the rights
        of third parties; (4) to respond to your requests for customer service;
        and/or (5) to protect the rights, property, or personal safety of
        Extensia, its agents and affiliates, its users, and the public. This
        includes exchanging information with other companies and organizations
        for fraud protection, and spam/malware prevention, and similar purposes.
      </p>
    ),
  },
  {
    title: 'WARRANTIES AND DISCLAIMERS',
    content: (
      <>
        <p>
          {`THE SERVICES AND ITS CONTENTS, WHETHER PROVIDED BY EXTENSIA, ITS
          LICENSORS, ITS VENDORS OR ITS USERS, AND OTHER INFORMATION ON OR
          ACCESSIBLE FROM THE SERVICES ARE PROVIDED "AS IS" WITHOUT WARRANTY,
          REPRESENTATION, CONDITION, OR GUARANTEE OF ANY KIND, EITHER EXPRESSED
          OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES,
          REPRESENTATIONS, CONDITIONS OR GUARANTEES OF QUALITY, MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, ALL OF WHICH
          ARE DISCLAIMED TO THE FULLEST EXTENT PERMITTED BY LAW. SPECIFICALLY,
          BUT WITHOUT LIMITATION, EXTENSIA DOES NOT WARRANT THAT: (i) THE
          INFORMATION AVAILABLE ON THE SERVICES IS FREE OF ERRORS; (ii) DEFECTS
          WILL BE CORRECTED, OR (iii) THE SERVICES OR THE SERVER(S) THAT MAKE
          THE SERVICES AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
          COMPONENTS.`}
        </p>
        <p>
          IN NO EVENT SHALL EXTENSIA OR ITS AFFILIATES, LICENSORS, VENDORS, OR
          ANY OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, OR
          OTHER REPRESENTATIVES BE LIABLE TO YOU OR ANY OTHER PERSON OR ENTITY
          FOR ANY DAMAGES, WHETHER DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
          CONSEQUENTIAL, PUNITIVE OR OTHERWISE (INCLUDING, BUT NOT LIMITED TO,
          DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, LOSS OF USE, OR COSTS OF
          OBTAINING SUBSTITUTE GOODS OR SERVICES), ARISING OUT OF OR IN
          CONNECTION WITH THE SERVICES, ANY MATERIALS, INFORMATION, OR
          RECOMMENDATIONS APPEARING ON THE SERVICES, OR ANY LINK PROVIDED ON THE
          SERVICES, WHETHER OR NOT EXTENSIA HAS BEEN ADVISED OF THE POSSIBILITY
          OF SUCH DAMAGES AND WHETHER BASED UPON WARRANTY, CONTRACT, TORT
          (INCLUDING NEGLIGENCE), STRICT LIABILITY, VIOLATION OF STATUTE, OR
          OTHERWISE. THIS EXCLUSION OF LIABILITY SHALL APPLY TO THE FULLEST
          EXTENT PERMITTED BY LAW. IN ANY EVENT, OUR AGGREGATE LIABILITY WILL
          NOT EXCEED THE AMOUNT YOU DONATED TO THE CAMPAIGN TO WHICH THE CLAIM
          RELATES OR, IF THE CLAIM DOES NOT RELATE TO A DONATION FOR AN EVENT OR
          CAMPAIGN, $100.
        </p>
        <p>
          {`If you are a California resident, you hereby waive California Civil
          Code §1542, which says: "A general release does not extend to claims
          which the creditor does not know or suspect to exist in his favor at
          the time of executing the release, which if known by him must have
          materially affected his settlement with the debtor." This release
          includes the criminal acts of others.`}
        </p>
      </>
    ),
  },
  {
    title: 'EXCLUSIONS AND LIMITATIONS',
    content: (
      <p>
        Some jurisdictions do not allow the exclusion of certain warranties or
        the limitation or exclusion of liability for incidental or consequential
        damages such as above in Section 17. Accordingly, some of the above
        limitations may not apply to you. If you are a New Jersey resident, or a
        resident of another state that permits the exclusion of these warranties
        and liabilities, then the limitations in Section 17 specifically do
        apply to you.
      </p>
    ),
  },
  {
    title: 'INDEMNIFICATION',
    content: (
      <p>
        {`YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD EXTENSIA AND ITS RESPECTIVE
        OFFICERS, DIRECTORS, EMPLOYEES, MEMBERS, SHAREHOLDERS, OR
        REPRESENTATIVES (AND ALL SUCCESSORS AND ASSIGNS OF ANY OF THE
        FOREGOING), HARMLESS FROM AND AGAINST ANY CLAIM OR DEMAND, INCLUDING
        WITHOUT LIMITATION, REASONABLE ATTORNEYS' FEES AND DISBURSEMENTS, MADE
        BY ANY THIRD PARTY IN CONNECTION WITH OR ARISING OUT OF YOUR VIOLATION
        OF THE TERMS OR EXTENSIA PRIVACY POLICY, YOUR VIOLATION OF AN APPLICABLE
        LAW, AND/OR YOUR VIOLATION OF ANY RIGHTS OF ANOTHER. WE RESERVE THE
        RIGHT, AT OUR OWN EXPENSE, TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL
        OF SUCH DISPUTES, AND IN ANY EVENT YOU WILL COOPERATE WITH US IN
        ASSERTING ANY AVAILABLE DEFENSES.`}
      </p>
    ),
  },
  {
    title: 'THIRD-PARTY LINKS AND SERVICES',
    content: (
      <p>
        {`The Services may provide (1) information and content provided by third
        parties; and (2) links to third-party websites or resources. Extensia is
        not responsible for the availability of such external sites or
        resources, and does not endorse and is not responsible or liable for (i)
        any content or other materials on or available from such sites or
        resources, (ii) any errors or omissions in these websites or resources,
        or (iii) any information handling practices or other business practices
        of the operators of such sites or resources. You further acknowledge and
        agree that Extensia shall not be responsible or liable, directly, or
        indirectly, for any damage or loss caused or alleged to be caused by or
        in connection with use of or reliance on any linked sites or resources.
        Your interactions with such third parties (including Subscribers) will
        be governed by the third parties’ own terms of service and privacy
        policies, and any other similar terms.`}
      </p>
    ),
  },
  {
    title: 'COPYRIGHT CLAIMS',
    content: (
      <>
        <p>
          In accordance with the Digital Millennium Copyright Act (DMCA) and
          other applicable law, if you believe that your work has been copied in
          a way that constitutes copyright infringement and is displayed on the
          Services, please provide substantially the following information to
          our Copyright Agent (please consult your legal counsel or see 17
          U.S.C. Section 512(c)(3) to confirm these requirements):
        </p>
        <p>
          1. an electronic or physical signature of the person authorized to act
          on behalf of the owner of the copyright or other intellectual property
          interest;
        </p>
        <p>
          2. a description of your copyrighted work or other intellectual
          property that you claim has been infringed;
        </p>
        <p>
          3. a description of where the material you claim is infringing is
          located on the Services (providing us with website URL is the quickest
          way to help us locate content quickly);
        </p>
        4. your address, telephone number, and e-mail address;
        <p>
          5. a statement by you that you have a good faith belief that the
          disputed use is not authorized by the copyright owner, its agent, or
          the law;
        </p>
        <p>Our copyright agent can be reached as follows:</p>
        <p>
          Extensia, Inc., Attn: Legal Team, 2300 Lakeview Parkway, Suite 700,
          Alpharetta, GA 30009.
        </p>
        <p>
          Please note that, pursuant to Section 512(f) of the Copyright Act, any
          person who knowingly materially misrepresents that material or
          activity is infringing may be subject to liability.
        </p>
      </>
    ),
  },
  {
    title: 'MODIFICATION AND TERMINATION OF THE SERVICES',
    content: (
      <>
        <p className="t-font-semibold">Modification of Services</p>
        <p>
          Extensia reserves the right at any time to modify or discontinue,
          temporarily or permanently, the Services (or any part thereof), with
          or without notice. You agree that Extensia shall not be liable to you
          or any third party for any modification, suspension, or discontinuance
          of the Services.
        </p>
        <p className="t-font-semibold">Termination</p>
        <p>
          These Terms are effective unless and until terminated by you or us. We
          may, in our sole and absolute discretion, deny you access to all or
          part of the Services at any time for any or no reason at all, with or
          without notice to you. If we terminate your right to access the
          Services, these Terms will terminate and all rights you have to access
          the Services will immediately terminate, provided that any donations
          made prior to the effective date of termination will continue to be
          processed in accordance with these Terms. All provisions of the Terms
          which by their nature should survive, shall survive termination of
          services, including without limitation Sections 1 (Definitions), 3
          (Additional Terms and Policies), 6 (Intellectual Property and Other
          Proprietary Rights), 7 (User Content), 10 (No Endorsement), 11 (User
          Disputes), 16 (Legal Compliance), 17 (Warranties and Disclaimers), 18
          (Exclusions and Limitations), 19 (Indemnification), 22 (Modification
          and Termination of the Services), 23 (Arbitration and Class Action
          Waiver), 24 (Controlling Law and Severability), 25 (Feedback), and 26
          (General Terms). Termination of your account may also include, at
          Extensia’s sole discretion, the deletion of your account and/or User
          Content.
        </p>
      </>
    ),
  },
  {
    title: 'ARBITRATION AND CLASS ACTION WAIVER',
    content: (
      <>
        <p>
          PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL RIGHTS,
          INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.
        </p>
        <p className="t-font-semibold">Application</p>
        <p>
          You and Extensia agree that these Terms affect interstate commerce and
          that the Federal Arbitration Act governs the interpretation and
          enforcement of these arbitration provisions. This Section 23 is
          intended to be interpreted broadly and governs any and all disputes
          between us including but not limited to claims arising out of or
          relating to any aspect of the relationship between us, whether based
          in contract, tort, statute, fraud, misrepresentation or any other
          legal theory; claims that arose before these Terms or any prior
          agreement (including, but not limited to, claims related to Campaigns
          and donations); and claims that may arise after the termination of
          these Terms. The only disputes excluded from this broad prohibition
          are the litigation of certain intellectual property and small court
          claims, as provided below.
        </p>
        <p className="t-font-semibold">Initial Dispute Resolution</p>
        <p>
          Most disputes can be resolved without resort to arbitration. If you
          have any dispute with us, you agree that before taking any formal
          action, you will contact us at support@Extensia.com , and provide a
          brief, written description of the dispute and your contact information
          (including your Campaign name, if your dispute relates to an Event or
          Campaign). Except for intellectual property and small claims court
          claims, the parties agree to use their best efforts to settle any
          dispute, claim, question, or disagreement directly through
          consultation with Extensia, and good faith negotiations shall be a
          condition to either party initiating a lawsuit or arbitration.
        </p>
        <p className="t-font-semibold">Binding Arbitration</p>
        <p>
          {`If the parties do not reach an agreed-upon solution within a period of
          sixty (60) days from the time informal dispute resolution is initiated
          under the Initial Dispute Resolution provision above, then either
          party may initiate binding arbitration as the sole means to resolve
          claims, (except as provided in section 23(g) below) subject to the
          terms set forth below. Specifically, all claims arising out of or
          relating to these Terms (including the Terms’ formation, performance,
          and breach), the parties' relationship with each other, and/or your
          use of Extensia shall be finally settled by binding arbitration
          administered by JAMS in accordance with the JAMS Streamlined
          Arbitration Procedure Rules for claims that do not exceed $250,000 and
          the JAMS Comprehensive Arbitration Rules and Procedures for claims
          exceeding $250,000 in effect at the time the arbitration is initiated,
          excluding any rules or procedures governing or permitting class
          actions.`}
        </p>
        <p className="t-font-semibold">Arbitrator’s Powers</p>
        <p>
          {`The arbitrator, and not any federal, state, or local court or agency,
          shall have exclusive authority to resolve all disputes arising out of
          or relating to the interpretation, applicability, enforceability, or
          formation of these Terms including but not limited to any claim that
          all or any part of these Terms is void or voidable, whether a claim is
          subject to arbitration, or the question of waiver by litigation
          conduct. The arbitrator shall be empowered to grant whatever relief
          would be available in a court under law or in equity. The arbitrator's
          award shall be written and shall be binding on the parties and may be
          entered as a judgment in any court of competent jurisdiction.`}
        </p>
        <p className="t-font-semibold">Filing a Demand</p>
        <p>
          To start an arbitration, you must do the following: (a) Write a Demand
          for Arbitration that includes a description of the claim and the
          amount of damages you seek to recover (you may find a copy of a Demand
          for Arbitration at www.jamsadr.com); (b) Send three copies of the
          Demand for Arbitration, plus the appropriate filing fee, to JAMS, 1201
          W Peachtree, NW, Suite 2650, Atlanta, GA 30309; and (c) Send one copy
          of the Demand for Arbitration to us at: Extensia, Inc., 2300 Lakeview
          Parkway. #700, Alpharetta, GA 30009.
        </p>
        <p>
          {`To the extent the filing fee for the arbitration exceeds the cost of
          filing a lawsuit, Extensia will pay the additional cost. If the
          arbitrator finds the arbitration to be non-frivolous, Extensia will
          pay the fees invoiced by JAMS, including filing fees and arbitrator
          and hearing expenses. You are responsible for your own attorneys' fees
          unless the arbitration rules and/or applicable law provide otherwise.`}
        </p>
        <p>
          The parties understand that, absent this mandatory arbitration
          provision, they would have the right to sue in court and have a jury
          trial. They further understand that, in some instances, the costs of
          arbitration could exceed the costs of litigation and the right to
          discovery may be more limited in arbitration than in court. If you are
          a resident of the United States, arbitration may take place in the
          county where you reside at the time of filing, unless you and we both
          agree to another location or telephonic arbitration. For individuals
          residing outside the United States, arbitration shall be initiated in
          Fulton County, Georgia, United States. You and Extensia further agree
          to submit to the personal jurisdiction of any federal or state court
          in Fulton County, Georgia, in order to compel arbitration, stay
          proceedings pending arbitration, or to confirm, modify, vacate, or
          enter judgment on the award entered by the arbitrator.
        </p>
        <p className="t-font-semibold">Class Action Waiver</p>
        <p>
          The parties further agree that the arbitration shall be conducted in
          the party’s respective individual capacities only and not as a class
          action or other representative action, and the parties expressly waive
          their right to file a class action or seek relief on a class basis.
          YOU AND EXTENSIA AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
          ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
          CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. If
          any court or arbitrator determines that the class action waiver set
          forth in this paragraph is void or unenforceable for any reason or
          that an arbitration can proceed on a class basis, then the arbitration
          provisions set forth above shall be deemed null and void in their
          entirety and the parties shall be deemed to have not agreed to
          arbitrate disputes.
        </p>
        <p className="t-font-semibold">
          Exception: Litigation of Intellectual Property and Small Claims Court
          Claims
        </p>
        <p>
          {`Notwithstanding the parties' decision to resolve all disputes through
          arbitration, either party may bring enforcement actions, validity
          determinations or claims arising from or relating to theft, piracy or
          unauthorized use of intellectual property in state or federal court
          with jurisdiction or in the U.S. Patent and Trademark Office to
          protect its intellectual property rights ("intellectual property
          rights" means patents, copyrights, moral rights, trademarks, and trade
          secrets, but not privacy or publicity rights). Either party may also
          seek relief in a small claims court for disputes or claims within the
          scope of that court's jurisdiction.`}
        </p>
        <p className="t-font-semibold">30-Day Right to Opt Out</p>
        <p>
          {`You have the right to opt out and not be bound by the arbitration and
          class action waiver provisions set forth above by sending written
          notice of your decision to opt out to at support@Extensia.com with the
          subject line, "ARBITRATION AND CLASS ACTION WAIVER OPT-OUT." The
          notice must be sent within thirty (30) days of (a) the Effective Date
          of these Terms; or (b) your first date that you used the Services that
          contained any versions of the Terms that included this version of the
          mandatory arbitration and class action waiver, whichever is later.
          Otherwise you shall be bound to arbitrate disputes in accordance with
          the terms of these paragraphs. If you opt out of these arbitration
          provisions, Extensia also will not be bound by them.`}
        </p>
        <p className="t-font-semibold">Changes to This Section</p>
        <p>
          {`Extensia will provide thirty (30) days' notice of any changes to this
          section by posting on the Services. Amendments will become effective
          thirty (30) days after they are posted on the Services or sent to you
          by email. Changes to this section will otherwise apply prospectively
          only to claims arising after the thirtieth (30th) day. If a court or
          arbitrator decides that this subsection on "Changes to This Section"
          is not enforceable or valid, then this subsection shall be severed
          from the section entitled Arbitration and Class Action Waiver, and the
          court or arbitrator shall apply the first Arbitration and Class Action
          Waiver section in existence after you began using the Services.`}
        </p>
        <p className="t-font-semibold">Survival</p>
        <p>
          This Arbitration and Class Action Waiver section shall survive any
          termination of your use of the Services.
        </p>
      </>
    ),
  },
  {
    title: 'CONTROLLING LAW AND SEVERABILITY',
    content: (
      <p>
        These Terms shall be construed in accordance with and governed by the
        laws of the State of Georgia notwithstanding its conflicts of law
        principles. Except for claims subject to mandatory arbitration, any
        dispute arising out of these terms and conditions or the use of this
        site shall be initiated and conducted in the state or federal courts of
        Fulton County, Georgia, and you and Extensia consent to the exclusive
        jurisdiction of such courts.
      </p>
    ),
  },
  {
    title: 'FEEDBACK',
    content: (
      <p>
        By sending us any feedback, comments, questions, or suggestions
        concerning Extensia or our services, including the Services
        (collectively, “Feedback”) you represent and warrant (a) that you have
        the right to disclose the Feedback, (b) that the Feedback does not
        violate the rights of any other person or entity, and (c) that your
        Feedback does not contain the confidential or proprietary information of
        any third party or parties. By sending us any Feedback, you further (i)
        agree that we are under no obligation of confidentiality, express or
        implied, with respect to the Feedback, (ii) acknowledge that we may have
        something similar to the Feedback already under consideration or in
        development, (iii) grant us an irrevocable, non-exclusive, royalty-free,
        perpetual, worldwide license to use, modify, prepare derivative works,
        publish, distribute and sublicense the Feedback, and (iv) irrevocably
        waive, and cause to be waived, against Extensia and its users any claims
        and assertions of any moral rights contained in such Feedback. This
        Feedback section shall survive any termination of your account or the
        Services.
      </p>
    ),
  },
  {
    title: 'GENERAL TERM',
    content: (
      <>
        <p className="t-font-semibold">Force Majeure</p>
        <p>
          Under no circumstances shall Extensia be held liable for any delay or
          failure in performance resulting directly or indirectly from an event
          beyond its reasonable control.
        </p>
        <p className="t-font-semibold">No Waiver</p>
        <p>
          No waiver of any provision of these Terms will be binding unless in
          writing, no waiver of any provisions of these Terms will be deemed a
          further or continuing waiver of such provision or any other provision,
          and the failure of Extensia to exercise or enforce any right or remedy
          in these Terms does not waive that right or remedy. If an arbitrator
          or a court of competent jurisdiction finds any provision of these
          Terms to be invalid, the parties agree that the court should endeavor
          to give effect, to the maximum extent permitted by law, to the
          parties’ intentions as reflected in the provision, and the other
          provisions of these Terms will remain in full force and effect.
        </p>
        <p className="t-font-semibold">
          Third-Party Beneficiaries/Relationship between the Parties
        </p>
        <p>
          You agree that there shall be no third-party beneficiaries to these
          Terms. No agency or employment between you and Extensia is created as
          a result of the Terms or your use of the Services.
        </p>
        <p className="t-font-semibold">Statute of Limitation</p>
        <p>
          Except for residents of New Jersey, you agree that regardless of any
          statute or law to the contrary, any claim or cause of action arising
          out of or related to the use of the Services and/or these Terms must
          be filed within one (1) year after such claim or cause of action arose
          or be forever barred.
        </p>
        <p className="t-font-semibold">Miscellaneous</p>
        <p>
          These Terms (and all terms and conditions incorporated herein)
          constitute the entire agreement between you and Extensia and govern
          your use of the Services, and supersede any prior agreements between
          you and Extensia on the subject matter. These Terms, and any rights or
          licenses granted hereunder, may not be assigned or delegated by you.
          These Terms, and any rights or licenses granted hereunder, may be
          assigned or delegated by Extensia without restriction. These Terms
          bind and inure to the benefit of each party and the party’s successors
          and permitted assigns. These Terms may not be modified by an oral
          statement by a representative of Extensia. No agency, partnership,
          joint venture, or employee-employer relationship is intended or
          created by these Terms. You agree that any agreements made by and
          between you and us in electronic form are as legally binding as if
          made in physical written form. The section titles in these Terms are
          for convenience only and have no legal or contractual effect.
        </p>
        <p className="t-font-semibold">Notices</p>
        <p>
          We may deliver notice to you by e-mail, posting a notice on the
          Services or any other method we choose and such notice will be
          effective on dispatch. If you give notice to us, it will be effective
          when received and you must use the following physical or email
          address: (1) Extensia, c/o Trustmarq, 2300 Lakeview Parkway, Suite
          700, Alpharetta, GA 30009; or (2) support@Extensia.com.
        </p>
      </>
    ),
  },
  {
    title: 'CONTACT US',
    content: (
      <p>
        If you have any questions about these Terms, please contact us by email
        at support@Extensia.com.
      </p>
    ),
  },
]

const TermsAndConditions = () => (
  <div className="t-flex t-flex-col t-space-y-4 modalHeight t-overflow-scroll t-text-justify t-px-8">
    <div>
      <p className="t-mb-4">
        <strong>This policy is effective since May 1, 2023.</strong>
      </p>
      <p className="t-font-medium">
        IMPORTANT NOTICE: THIS AGREEMENT CONTAINS A BINDING ARBITRATION
        PROVISION AND CLASS ACTION WAIVER. IT AFFECTS YOUR LEGAL RIGHTS AS
        DETAILED IN THE ARBITRATION AND CLASS ACTION WAIVER SECTION BELOW.
        PLEASE READ CAREFULLY.
      </p>
    </div>
    <div className="t-space-y-2">
      <p>
        Thank you for visiting Extensia. Extensia is an online donor
        relationship management and crowd-funding platform that supports
        organizations with nurturing a stronger relationship their support base
        of followers, supporters, donors, and volunteers. These Terms of Use
        (“Terms”) govern your access to, use of, and participation in the
        services made available by Extensia Inc., (“Extensia,” “we,” “our,” or
        “us”), including our websites, products, tools, promotions, and any
        other services that reference these Terms (collectively, the
        “Services”). By accessing or otherwise using the Services, you agree to
        be bound by these Terms. If you do not agree to these Terms, you may not
        access or use the Services.
      </p>
      <p>
        All references to “you” or “your,” as applicable, mean the person who
        accesses, uses, and/or participates in the Services in any manner, and
        each of your heirs, assigns, and successors. If you use the Services on
        behalf of an entity, you represent and warrant that you have the
        authority to bind that entity, your acceptance of the Terms will be
        deemed an acceptance by that entity, and “you” and “your” herein shall
        refer to that entity.
      </p>
    </div>
    <div className="t-space-y-4">
      {data.map((item) => (
        <div>
          <p className="t-text-lg t-font-semibold t-text-secondary-100">
            {item.title}
          </p>
          {item.content}
        </div>
      ))}
    </div>
  </div>
)

export { TermsAndConditions }

import React, { useState } from 'react'
import { Modal, Spin, Tooltip } from 'antd'
import { MdOutlineSimCardDownload } from 'react-icons/md'
import Api from 'api'

const UploadStatisticModal = ({
  isVisible,
  onClose,
  statisticData,
  downloadFile,
}) => {
  const [downloadLoading, setDownloadLoading] = useState(false)

  const download = () => {
    setDownloadLoading(true)
    const formatedData = statisticData?.errorData?.map((item) => ({
      DonorName: item.DonorName,
      DonorEmail: item.DonorEmail,
      DonorPhoneNumber: item.DonorPhoneNumber,
      DonorZipCode: item.DonorZipCode,
    }))
    Api.post('subscriber/downloadErrorFile', { data: formatedData })
      .then((res) => {
        downloadFile(res, 'application/ms-excel', 'UnFormated-DonorList.csv')
        setDownloadLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setDownloadLoading(false)
      })
  }

  const fieldsData = [
    {
      name: 'Records successfully imported',
      value: 'successfullyUploaded',
    },
    {
      name: 'Records skipped because they already exist',
      value: 'skippedRecords',
    },
    {
      name: 'Records were not imported due to errors',
      value: 'errorInRecords',
    },
  ]

  return (
    <Modal
      centered
      title="Upload Statistic Modal"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      {statisticData && (
        <div className="t-w-full">
          {statisticData?.errorInRecords > 0 && (
            <div className="t-mb-8 t-flex t-items-center t-justify-end">
              <Tooltip title="Download records were not imported due to errors">
                {downloadLoading ? (
                  <Spin />
                ) : (
                  <MdOutlineSimCardDownload
                    fontSize="1.7rem"
                    className="t-text-secondary-100 t-cursor-pointer"
                    onClick={() => download()}
                  />
                )}
              </Tooltip>
            </div>
          )}
          {fieldsData.map((item) => (
            <div
              key={item}
              className="t-w-full t-rounded-2xl t-h-12 t-p-3 t-bg-yellow-100 t-text-secondary-100 t-mb-2 t-flex t-items-center t-shadow-md t-justify-between"
            >
              <div className="t-flex t-flex-col t-justify-center t-space-x-2">
                {item.name}
                {item.name === 'Records were not imported due to errors' &&
                  statisticData?.errorInRecords > 0 && (
                    <div className="t-text-xs">
                      (Kindly download file to review them)
                    </div>
                  )}
              </div>
              <div className="t-flex t-flex-col">
                <h2 className="t-font-bold t-text-secondary-100 t-text-right t-text-xs md:t-text-sm">
                  {statisticData[item?.value]}
                </h2>
              </div>
            </div>
          ))}
        </div>
      )}
    </Modal>
  )
}

export { UploadStatisticModal }

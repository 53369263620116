import React from 'react'
import { Icon } from 'components/elements'
import { Link } from 'react-router-dom'
import { useRoles } from 'hooks'
import { config } from 'configs'

const NavLogo = (props) => {
  const { collapsed } = props
  const { isDonor, isSubscriber, isAdmin } = useRoles()

  return (
    <Link
      to={
        isSubscriber
          ? config.defaultRedirect.subscriber
          : isDonor
          ? config.defaultRedirect.donor
          : isAdmin
          ? config.defaultRedirect.admin
          : config.defaultRedirect.donor
      }
    >
      <div className="t-flex t-justify-center t-items-center">
        <Icon src="pg_logo" width={collapsed ? 60 : 100} height="auto" />
      </div>
    </Link>
  )
}

export default NavLogo

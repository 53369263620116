import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: false,
  contributionLoading: false,
  user: null,
  location: null,
  organization: null,
  customLocation: false,
  extras: {
    userPhone: null,
    userTemp: null,
    token: null,
  },
  api: {
    loading: false,
  },
  payment: {
    stripe: {
      id: null,
      isConnected: false,
      stripeId: null,
    },
    paypal: {
      id: null,
      isConnected: false,
      paypalId: null,
    },
  },
  govdocs: [],
  contributions: {
    nextPayment: 0,
    scheduledPaymentDate: 0,
    totalDonation: 0,
    totalPledges: 0,
  },
  ytd: {
    ytdOrg: [],
    org: [],
    indv: [],
    pledges: [],
  },
  paymentErrors: [],
  donorFeedbacks: [],
  userLogs: [],
  downloadable: [],
  geolocation: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = { ...state, loading: payload }
      return alteredState
    },
    setContributionLoading: (state, { payload }) => {
      const alteredState = { ...state, contributionLoading: payload }
      return alteredState
    },
    setUserPhone: (state, { payload }) => {
      const alteredState = {
        ...state,
        extras: { ...state.extras, userPhone: payload },
      }
      return alteredState
    },
    setTempUser: (state, { payload }) => {
      const alteredState = {
        ...state,
        extras: { ...state.extras, userTemp: payload },
      }
      return alteredState
    },
    setUser: (state, { payload }) => {
      const alteredState = { ...state, user: payload }
      return alteredState
    },
    setToken: (state, { payload }) => {
      const alteredState = {
        ...state,
        extras: { ...state.extras, token: payload },
      }
      return alteredState
    },
    setLoaction: (state, { payload }) => {
      const alteredState = {
        ...state,
        location: payload.data,
        customLocation: payload.customLocation,
      }
      return alteredState
    },
    setOrganization: (state, { payload }) => {
      const alteredState = {
        ...state,
        organization: payload,
      }
      return alteredState
    },
    setCustomLocation: (state, { payload }) => {
      const alteredState = {
        ...state,
        customLocation: payload,
      }
      return alteredState
    },
    setApiLoading: (state, { payload }) => {
      const alteredState = {
        ...state,
        api: { ...state.api, loading: payload },
      }
      return alteredState
    },
    setStripeDetail: (state, { payload }) => {
      const alteredState = {
        ...state,
        payment: {
          ...state.payment,
          stripe: {
            ...state.payment.stripe,
            isConnected: payload.isConnected,
            id: payload.id,
            stripeId: payload.stripeId,
          },
        },
      }
      return alteredState
    },
    setPaypalDetail: (state, { payload }) => {
      const alteredState = {
        ...state,
        payment: {
          ...state.payment,
          paypal: {
            ...state.payment.paypal,
            isConnected: payload.isConnected,
            id: payload.id,
            paypalId: payload.paypalId,
          },
        },
      }
      return alteredState
    },
    setGovDocs: (state, { payload }) => {
      const alteredState = { ...state, govdocs: payload }
      return alteredState
    },
    setPaymentErrors: (state, { payload }) => {
      const alteredState = { ...state, paymentErrors: payload }
      return alteredState
    },
    setDonorFeedbacks: (state, { payload }) => {
      const alteredState = { ...state, donorFeedbacks: payload }
      return alteredState
    },
    setUserLogs: (state, { payload }) => {
      const alteredState = { ...state, userLogs: payload }
      return alteredState
    },
    setDownloadable: (state, { payload }) => {
      const alteredState = { ...state, downloadable: payload }
      return alteredState
    },
    setContributions: (state, { payload }) => {
      const alteredState = { ...state, contributions: payload }
      return alteredState
    },
    setGeolocation: (state, { payload }) => {
      const alteredState = { ...state, geolocation: payload }
      return alteredState
    },
    setYTDOrg: (state, { payload }) => {
      const alteredState = {
        ...state,
        ytd: { ...state.ytd, ytdOrg: payload },
      }
      return alteredState
    },
    setLastYearOrg: (state, { payload }) => {
      const alteredState = {
        ...state,
        ytd: { ...state.ytd, org: payload },
      }
      return alteredState
    },
    setLastYearItemized: (state, { payload }) => {
      const alteredState = {
        ...state,
        ytd: { ...state.ytd, indv: payload },
      }
      return alteredState
    },
    setYTDPledges: (state, { payload }) => {
      const alteredState = {
        ...state,
        ytd: { ...state.ytd, pledges: payload },
      }
      return alteredState
    },
    reset: () => initialState,
  },
})

export const useAuthSelector = () => useSelector((state) => state.auth)
export const authActions = authSlice.actions
export const authReducer = authSlice.reducer

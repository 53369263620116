/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Progress, Modal, Button, message, Skeleton } from 'antd'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { formatAmountWithOutDecimal } from 'utils'
import { config } from 'configs'
import Api, { makeRequest } from 'api'

const Pledges = ({ pledge, setPledge }) => {
  const [pledgesData, setPledgesData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [reminderData, setReminderData] = useState([])
  const [cancelPledgeModal, setCancelPledgeModal] = useState(false)
  const [pledgeId, setPledgeId] = useState('')
  const [loading, setLoading] = useState(true)
  const [pledgeInfornmation, setPledgeInformation] = useState({})
  const { organization } = useAuthSelector()

  // Calculating the Due Days
  useEffect(() => {
    if (pledge.length > 0) {
      const currentDate = new Date() // Get the current date
      const pledgesWithDueDays = pledge.map((event) => {
        const scheduleDate = new Date(event.ScheduleDate || event.CreatedAt)
        const timeDifference = currentDate.getTime() - scheduleDate.getTime()
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24))

        return {
          ...event,
          dueDays: daysDifference,
        }
      })

      setPledgesData(pledgesWithDueDays)
    }
  }, [pledge])

  const reminderBtn = (item) => {
    setIsModalVisible(!isModalVisible)
    const { dueDays, ...modifiedItem } = item
    modifiedItem.key = 0
    setReminderData([modifiedItem])
  }

  const cancelPledgesBtn = (item) => {
    setCancelPledgeModal(!cancelPledgeModal)
    setPledgeId(item.Id)
  }

  const onFinish = (type) => {
    Api.post(`/pledge/reminder`, { data: reminderData, type })
      .then(() => {
        message.success('Reminders sent Successfully')
        setIsModalVisible(!isModalVisible)
      })
      .catch((error) => {
        console.log('error', error)
        message.error('An Error Occured')
      })
  }

  const cancelPledge = () => {
    Api.put(`pledge/${pledgeId}`)
      .then((res) => {
        setCancelPledgeModal(!cancelPledgeModal)
        message.success(res.message || 'Pledge cancelled successfully')
        setPledgesData((prevPledge) =>
          prevPledge.filter((pledge) => pledge.Id !== pledgeId),
        )
        setPledge((prevPledge) =>
          prevPledge.filter((pledge) => pledge.Id !== pledgeId),
        )
      })
      .catch((error) => {
        console.log('error', error)
        message.error('An Error Occured')
      })
  }

  const pledgeInfo = () => {
    setLoading(true)
    makeRequest('get', `subscriber-home/pledges`, {
      organization: organization.id,
    })
      .then((res) => {
        setPledgeInformation(res.results)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        message.error('An Error Occured')
        setLoading(false)
      })
  }

  useEffect(() => {
    pledgeInfo()
  }, [])

  const pledgeStyle = () => {
    if (pledgesData.length > 5) {
      return {
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: '',
        msOverflowStyle: 'none',
        WebkitOverflowScrolling: 'touch',
      }
    }
    return {}
  }

  return (
    <>
      {/* <div className="t-grid md:t-grid-cols-2 t-grid-cols-1 t-gap-4 t-mt-12 t-px-2">
        <select className="t-w-[100%] t-p-2 t-rounded-md t-border t-border-gray-300">
          <option>Pledge Reminders</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
        </select>
      </div> */}
      <div className="t-grid md:t-grid-cols-2 t-grid-cols-1 t-gap-4 t-mt-8 t-px-2">
        <div
          style={pledgeStyle()}
          className="t-rounded-lg t-mb-2 t-bg-white t-w-full t-border t-border-gray-300 t-max-h-[405px]"
        >
          {pledge.length <= 0 ? (
            <div className="t-flex t-items-center t-justify-center t-h-full">
              <h2 className="t-text-center t-font-normal t-text-lg">
                Pledges Not Available!
              </h2>
            </div>
          ) : (
            pledgesData.map((item, index) => (
              <div
                key={index}
                className="t-flex t-flex-row t-my-4 t-bg-red- t-w-full"
              >
                <div className="t-w-[40%]">
                  <h1 className="t-py-4 t-px-4 t-text-base t-font-medium t-ml-2">
                    <strong>{formatAmountWithOutDecimal(item.Amount)}</strong> -{' '}
                    {item.DonorName}
                  </h1>
                </div>
                <div className="t-w-[30%]">
                  <h2 className="t-py-4 t-pl-4 t-text-base t-font-medium">
                    {Math.abs(item.dueDays)}{' '}
                    {parseInt(item.dueDays) < 0
                      ? 'days remaining'
                      : 'days overdue'}
                  </h2>
                </div>
                <div className="t-w-[30%]">
                  <button
                    className="t-bg-white t-border t-border-gray-500 t-py-1 t-px-2 t-text-base t-mx-2 t-my-2 t-rounded-md hover:t-border-cyan-500 hover:t-text-cyan-500 t-text-black"
                    onClick={() => reminderBtn(item)}
                  >
                    Remind
                  </button>
                  <button
                    className="t-bg-white t-border t-border-gray-500 t-py-1 t-px-4 t-text-base t-mx-2 t-my-2 t-rounded-md hover:t-border-cyan-500 hover:t-text-cyan-500 t-text-black"
                    onClick={() => cancelPledgesBtn(item)}
                  >
                    X
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
        <div>
          <Skeleton loading={loading} active paragraph={{ rows: 10 }}>
            <div className="t-col-span-1 t-row-span-1 p-4 t-rounded-lg t-cursor-pointer t-bg-white t-text-black t-mb-4 t-border t-border-gray-300">
              <h1 className="t-text-lg t-pl-4 t-pt-4 t-pb-2 t-font-semibold t-text-gray-700">
                <strong>
                  {formatAmountWithOutDecimal(
                    pledgeInfornmation?.Monthly?.total,
                  )}
                </strong>{' '}
                - Pledge Monthly Total
              </h1>
              <div className="w-[90%] t-mx-4">
                <Progress
                  percent={
                    Number(
                      (pledgeInfornmation?.Monthly?.converted * 100) /
                        pledgeInfornmation?.Monthly?.total,
                    ).toFixed(0) || 0
                  }
                  strokeColor={config.colors.primary}
                />
              </div>
              <div className="t-flex t-flex-row t-justify-between t-px-4 t-mt-2 t-pb-4">
                <h2 className="t-text-base">
                  <strong>
                    {formatAmountWithOutDecimal(
                      pledgeInfornmation?.Monthly?.converted,
                    )}
                  </strong>{' '}
                  - Converted{' '}
                </h2>
                <h2 className="t-text-base">
                  <strong>
                    {formatAmountWithOutDecimal(
                      pledgeInfornmation?.Monthly?.pending,
                    )}
                  </strong>{' '}
                  - Pending
                </h2>
              </div>
            </div>
            <div className="t-col-span-1 t-row-span-1 p-4 t-rounded-lg t-cursor-pointer t-bg-white t-text-black t-mb-4 t-border t-border-gray-300">
              <h1 className="t-text-lg t-pl-4 t-pt-4 t-pb-2 t-font-semibold t-text-gray-700">
                <strong>
                  {formatAmountWithOutDecimal(pledgeInfornmation?.YTD?.total)}
                </strong>{' '}
                - YTD Total
              </h1>
              <div className="w-[90%] t-mx-4">
                <Progress
                  percent={
                    Number(
                      (pledgeInfornmation?.YTD?.converted * 100) /
                        pledgeInfornmation?.YTD?.total,
                    ).toFixed(0) || 0
                  }
                  strokeColor={config.colors.primary}
                />
              </div>
              <div className="t-flex t-flex-row t-justify-between t-px-4 t-mt-2 t-pb-4">
                <h2 className="t-text-base">
                  <strong>
                    {formatAmountWithOutDecimal(
                      pledgeInfornmation?.YTD?.converted,
                    )}
                  </strong>{' '}
                  - Converted{' '}
                </h2>
                <h2 className="t-text-base">
                  <strong>
                    {' '}
                    {formatAmountWithOutDecimal(
                      pledgeInfornmation?.YTD?.pending,
                    )}
                  </strong>{' '}
                  - Pending
                </h2>
              </div>
            </div>
            <div className="t-col-span-1 t-row-span-1 p-4 t-rounded-lg t-cursor-pointer t-bg-white t-text-black t-mb-4 t-border t-border-gray-300">
              <h1 className="t-text-lg t-pl-4 t-pt-4 t-pb-2 t-font-semibold t-text-gray-700">
                <strong>
                  {formatAmountWithOutDecimal(
                    pledgeInfornmation?.YTD?.cancelled,
                  )}
                </strong>{' '}
                - YTD Canceled Pledges
              </h1>
              <div className="w-[90%] t-mx-4">
                <Progress
                  percent={
                    Number(
                      (pledgeInfornmation?.YTD?.converted * 100) /
                        pledgeInfornmation?.YTD?.total,
                    ).toFixed(0) || 0
                  }
                  strokeColor={config.colors.primary}
                />
              </div>
              <div className="t-flex t-flex-row t-justify-between t-px-4 t-mt-2 t-pb-4">
                <h2 className="t-text-base">
                  <strong>
                    {formatAmountWithOutDecimal(
                      pledgeInfornmation?.YTD?.converted,
                    )}
                  </strong>{' '}
                  - Converted{' '}
                </h2>
                <h2 className="t-text-base">
                  <strong>
                    {formatAmountWithOutDecimal(
                      pledgeInfornmation?.YTD?.pending,
                    )}
                  </strong>{' '}
                  - Pending
                </h2>
              </div>
            </div>
          </Skeleton>
        </div>
      </div>

      {/*  Reminder Modal */}
      <Modal
        title="Select Reminder Type"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <div className="t-flex t-justify-end t-items-center t-space-x-2">
          <Button onClick={() => onFinish('Sms')}>Send SMS</Button>
          <Button onClick={() => onFinish('Email')}>Send Email</Button>
        </div>
      </Modal>

      {/* Cancel pledge Modal */}
      <Modal
        title="Cancel Pledge"
        open={cancelPledgeModal}
        onCancel={() => setCancelPledgeModal(false)}
        footer={null}
      >
        <h3 className="t-text-center">
          Are you sure you want to cancel this Pledge
        </h3>
        <div className="t-flex t-justify-end t-items-center t-space-x-2">
          <Button onClick={() => cancelPledge()}>Yes</Button>
          <Button onClick={() => setCancelPledgeModal(false)}>No</Button>
        </div>
      </Modal>
    </>
  )
}

export default Pledges

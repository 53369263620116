import React from 'react'
import { Row, Col, Card, Modal, Typography, Tooltip, Spin } from 'antd'
import { MdOutlineMedicalServices } from 'react-icons/md'

const BusinessServiceView = ({ visible, close, item, loading, submit }) => (
  <Modal
    title="Business Service View"
    visible={visible}
    onCancel={() => close()}
    footer={null}
  >
    {item && (
      <Card bordered={false} className="header-solid card-product mb-24">
        <Row gutter={[24, 0]}>
          <Col span={24} className="t-px-4 t-p-y-2">
            <div className="t-flex t-justify-end">
              <Tooltip title="Request Business Service">
                {loading ? (
                  <Spin />
                ) : (
                  <MdOutlineMedicalServices
                    fontSize="2rem"
                    className="t-text-secondary-100 t-cursor-pointer"
                    onClick={() => submit()}
                  />
                )}
              </Tooltip>
            </div>
            <div className="t-mb-4">
              <h1 className="t-text-secondary-100 t-text-2xl md:t-text-4xl t-font-bold">
                {item?.Title}
              </h1>
            </div>
            <div className="t-mb-4">
              <Typography.Title level={4} className="t-text-secondary-100">
                Description
              </Typography.Title>
              <Typography.Paragraph className="t-text-justify">
                {item?.Description}
              </Typography.Paragraph>
            </div>
            <div className="t-mb-4">
              <Typography.Title level={4} className="t-text-secondary-100">
                Deliverable
              </Typography.Title>
              <Typography.Paragraph className="t-text-justify">
                {item?.Deliverable}
              </Typography.Paragraph>
            </div>
          </Col>
        </Row>
      </Card>
    )}
  </Modal>
)

export { BusinessServiceView }

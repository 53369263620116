import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row, message } from 'antd'
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { awsconfig, config } from 'configs'
import { loadStripe } from '@stripe/stripe-js'
import { makeRequest } from 'api'

const initialValues = {
  card: 'no-card-selected',
}

const WrappedUpdateCreditCardModal = ({
  visible,
  close,
  association,
  getData,
}) => {
  const [form] = Form.useForm()
  const stripe = useStripe()
  const elements = useElements()
  const [loading, setLoading] = useState(false)

  // eslint-disable-next-line consistent-return
  const onFinish = async () => {
    if (!association?.Id) {
      message.error('Association Id is missing')
      return false
    }
    let cardToken = null
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    })
    if (error) {
      message.error(error?.message || 'Invalid Card Information')
      return false
    }
    cardToken = paymentMethod.id
    const payload = {
      cardToken,
      associationId: association?.Id,
    }
    setLoading(true)
    try {
      const response = await makeRequest(
        'post',
        `/component/membership-program/update-membership-card`,
        {},
        payload,
      )
      message.success(
        response?.message || 'Successfully updated card information',
      )
      onClose()
      getData()
    } catch (error1) {
      message.error(error1?.response?.data?.error || config.defaultErrorMessage)
    }
    setLoading(false)
  }

  const onClose = () => {
    form.resetFields()
    close()
  }
  return (
    <Modal
      centered
      keyboard
      title="Change Credit Card"
      open={visible}
      onCancel={() => onClose()}
      footer={null}
      destroyOnClose
    >
      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="add-items"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={24} xl={24}>
              <>
                <h1 className="t-text-sm t-my-2 t-font-semibold">
                  Enter New Card Number
                </h1>
                <div className="t-p-2 t-rounded-md t-border t-border-grey-900'">
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: '15px',
                          fontSmoothing: 'antialiased',
                        },
                      },
                    }}
                  />
                </div>
              </>
              <Col span={24} className="text-right t-p-0 t-my-2 t-space-x-2">
                <Button type="primary" onClick={() => onClose()}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={!stripe || !elements}
                >
                  Update Card
                </Button>
              </Col>
            </Col>
          </Row>
        </Form>
      </Col>
    </Modal>
  )
}

function StripeProvider({ visible, close, association, getData }) {
  const SubscriberStripeAccount = association?.SubscriberStripeAccount

  const stripePromise = loadStripe(awsconfig.stripe_publish_key, {
    stripeAccount: SubscriberStripeAccount,
  })
  return (
    <Elements stripe={stripePromise}>
      <WrappedUpdateCreditCardModal
        visible={visible}
        close={close}
        association={association}
        getData={getData}
      />
    </Elements>
  )
}
const UpdateCreditCardModal = StripeProvider
export { UpdateCreditCardModal }

import React, { useState, useEffect } from 'react'
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Button,
  Switch,
  Skeleton,
  DatePicker,
  Divider,
  message,
} from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import moment from 'moment'
import { compareDateTimeWithNow, disabledTime } from 'utils'
import { ImageUploader } from 'components/elements'
import { useDispatch } from 'react-redux'
import { getUserOrganization } from 'store/organizationSlice/organizationActions'
import {
  addAuction,
  getAuctionForUpdate,
  updateAuction,
} from 'store/auctionSlice/auctionActions'
import { auctionValidation } from 'validations'
import { useAuctionSelector } from 'store/auctionSlice/auctionReducer'
import { AuctionItems } from 'components/common/auction/AuctionItems'
import { AddItemsModal } from 'components/common/auction/AddItemsModal'

const { TextArea } = Input

const initialValues = {
  Title: '',
  ZipCode: '',
  ShowOnPage: false,
  Details: '',
  StartDate: null,
  EndDate: null,
  Flyer: null,
}

const CreateAuction = () => {
  const dispatch = useDispatch()
  const { loading: getDataLoading } = useAuctionSelector()
  const { user, organization } = useAuthSelector()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [addItemsModal, setAddItemsModal] = useState(false)
  const [auctionItemsList, setAuctionItemsList] = useState([])

  const params = useParams()
  const history = useHistory()

  Form.useWatch('Flyer', form)
  Form.useWatch('ZipCode', form)
  Form.useWatch('ShowOnPage', form)
  Form.useWatch('StartDate', form)
  Form.useWatch('EndDate', form)

  useEffect(() => {
    if (organization && organization?.ZipCode) {
      form.setFieldsValue({ ZipCode: organization.ZipCode })
    }
    // eslint-disable-next-line
  }, [organization])

  useEffect(() => {
    if (params.id) {
      dispatch(getAuctionForUpdate(params.id, form))
    }
    // eslint-disable-next-line
  }, [dispatch, params.id])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const queryparams = Object.fromEntries(urlSearchParams.entries())
    if (
      Object.entries(queryparams).length > 0 &&
      queryparams.duplicate &&
      queryparams.id
    ) {
      dispatch(getAuctionForUpdate(queryparams.id, form, true))
      history.replace('/settings/create-auction')
    }
    // eslint-disable-next-line
  }, [])

  const navigation = () => {
    form.resetFields()
    history.push('/auction')
  }

  const onFinish = async (values) => {
    // To check if campaign end date is after start date
    const { StartDate, EndDate } = values

    if (EndDate && moment(EndDate).isBefore(moment(StartDate))) {
      message.error('End date time must be after start date time')
      return
    }

    try {
      const userOrganization = await getUserOrganization(user.email)
      if (params.id) {
        const data = {
          id: params.id,
          ...values,
        }
        dispatch(updateAuction(data, setLoading, navigation))
      } else {
        const data = {
          ...values,
          AuctionItemsList: auctionItemsList,
          OrganizationId: userOrganization?.id,
          OrganizationName: userOrganization?.Name,
          OrganizationBackgroundImage:
            organization &&
            organization?.BackgroundImage &&
            organization.BackgroundImage,
        }
        dispatch(addAuction(data, setLoading, navigation))
      }
    } catch (error) {
      setLoading(false)
      console.log('Error', error)
      message.error(error?.response?.data?.message || 'Something Went Wrong')
    }
  }

  const buttonText = loading
    ? params.id
      ? 'Updating Auction'
      : 'Creating Auction'
    : params.id
    ? 'Update Auction'
    : 'Create Auction'

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0 t-text-2xl">
            {params.id ? 'Update Auction' : 'Add Auction'}
          </h6>
          <p className="font-regular">Enter Information</p>
        </>,
      ]}
    >
      {/* <Skeleton loading={fetching} active paragraph={{ rows: 18 }}> */}
      <Skeleton loading={getDataLoading} active paragraph={{ rows: 18 }}>
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="event"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Title"
                label="Title"
                colon={false}
                rules={[auctionValidation]}
              >
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="ZipCode"
                label="Zip code"
                colon={false}
                rules={[auctionValidation]}
              >
                <Input placeholder="e.g : 12345" />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="StartDate"
                label="Start Date"
                colon={false}
                rules={[auctionValidation]}
              >
                <DatePicker
                  format="MM-DD-YYYY hh:mm A"
                  showTime
                  minuteStep={15}
                  className="t-w-full"
                  disabledDate={(d) =>
                    !d || d.isBefore(moment().subtract(1, 'day'))
                  }
                  disabledTime={disabledTime}
                  onChange={(selectedDate) =>
                    !compareDateTimeWithNow(selectedDate, 'before') &&
                    form.setFieldsValue({
                      StartDate: moment(),
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="EndDate"
                label="End Date"
                colon={false}
                rules={[auctionValidation]}
              >
                <DatePicker
                  format="MM-DD-YYYY hh:mm A"
                  showTime
                  minuteStep={15}
                  className="t-w-full"
                  disabledDate={(d) =>
                    !d ||
                    d.isBefore(moment().subtract(1, 'day')) ||
                    d.isBefore(
                      moment(form.getFieldValue('StartDate')).startOf('day'),
                    )
                  }
                  disabledTime={disabledTime}
                  onChange={(selectedDate) =>
                    !compareDateTimeWithNow(selectedDate, 'before') &&
                    form.setFieldsValue({
                      EndDate: moment(),
                    })
                  }
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="Details"
                label="Brief Auction Description"
                colon={false}
                rules={[auctionValidation]}
              >
                <TextArea
                  placeholder="Tell us about the auction"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>

            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Flyer"
                label="Banner Image"
                colon={false}
                rules={[auctionValidation]}
              >
                <ImageUploader
                  ratio={2 / 1}
                  defaultFile={
                    form.getFieldValue('Flyer') && form.getFieldValue('Flyer')
                  }
                  onFileChange={(file) =>
                    form.setFieldsValue({
                      Flyer: file?.originFileObj,
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="ShowOnPage"
                label="Show on Landing Page"
                colon={false}
                rules={[auctionValidation]}
              >
                <Switch checked={form.getFieldValue('ShowOnPage')} />
              </Form.Item>
            </Col>
            <Divider />
            {!params.id && (
              <>
                <AuctionItems
                  auctionItemsList={auctionItemsList}
                  setAuctionItemsList={setAuctionItemsList}
                  setAddItemsModal={setAddItemsModal}
                />
                <Divider />
              </>
            )}
            <Col span={24} className="text-left t-space-x-2">
              <Button
                loading={loading || getDataLoading}
                type="primary"
                className="px-25"
                htmlType="submit"
              >
                {buttonText}
              </Button>
              <Button
                type="primary"
                className="px-25"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
        <AddItemsModal
          visible={addItemsModal}
          close={() => setAddItemsModal(false)}
          auctionItemsList={auctionItemsList}
          setAuctionItemsList={setAuctionItemsList}
          isCampaign
          defaultEndDate={
            form.getFieldValue('EndDate') && form.getFieldValue('EndDate')
          }
          defaultStartDate={
            form.getFieldValue('StartDate') && form.getFieldValue('StartDate')
          }
        />
      </Skeleton>
    </Card>
  )
}

export { CreateAuction }

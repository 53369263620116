import { Button, Col, Space, Table, Tooltip } from 'antd'
import { config } from 'configs'
import React from 'react'
import { IoMdAddCircleOutline } from 'react-icons/io'

const AuctionItems = ({
  auctionItemsList,
  setAuctionItemsList,
  setAddItemsModal,
}) => {
  const deleteItem = (key) => {
    const updatedData = auctionItemsList.filter((item) => key !== item.key)
    setAuctionItemsList(updatedData)
  }

  const columns = [
    // {
    //   title: 'Image',
    //   dataIndex: 'itemImage',
    //   className: 'ant-table-row-cell-break-word',
    //   render: (_, { itemImage }) => (
    //     <Avatar
    //       src={itemImage?.fileName && awsconfig.bucketUrl + itemImage?.fileName}
    //       alt="photo"
    //       className="mr-10"
    //       size={48}
    //       shape="square"
    //     />
    //   ),
    // },
    {
      title: 'Title',
      dataIndex: 'title',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Start Bid',
      dataIndex: 'startBid',
      className: 'ant-table-row-cell-break-word',
      render: (_, { startBid }) => `$${startBid}`,
    },
    {
      title: 'Winning Bid',
      dataIndex: 'winningBid',
      className: 'ant-table-row-cell-break-word',
      render: (_, { winningBid }) => `$${winningBid}`,
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Actions',
      key: 'key',
      width: 100,
      align: 'center',
      render: (_, item) => (
        <Space size="middle">
          <Button onClick={() => deleteItem(item.key)}>Delete</Button>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Col span={24}>
        <div className="t-flex t-justify-between t-py-4">
          <h1 className="t-text-base t-font-semibold">Auction Items List</h1>
          <div className="t-h-full t-flex t-items-center">
            {auctionItemsList.length < 100 ? (
              <Tooltip title="Add Auction Item">
                <IoMdAddCircleOutline
                  fontSize="1.7rem"
                  className="t-text-secondary-100 t-cursor-pointer"
                  onClick={() => setAddItemsModal(true)}
                />
              </Tooltip>
            ) : (
              <p className="t-text-red-600">Maximum Item limit reached</p>
            )}
          </div>
        </div>
      </Col>
      <Col span={24}>
        <Table
          tableLayout="auto"
          scroll={{ x: 500 }}
          columns={columns}
          dataSource={auctionItemsList}
          bordered={false}
          pagination={{
            pageSize: config.rowsPerPage,
            showSizeChanger: false,
          }}
        />
      </Col>
    </>
  )
}

export { AuctionItems }

import React, { useState } from 'react'
import { ComponentView } from 'components/common/componentView'
import { MyBusiness } from './components/BusinessLists'

const DonorOrganizationsView = () => {
  const [searchText, setSearchText] = useState(null)
  const [sortSwitchValue, setSortSwitchValue] = useState(false)
  const [sortKey, setSortKey] = useState('createdDate')

  const heading = 'Businesses'
  const optionList = {
    // all: <BusinessList searchText={searchText} sortKey={sortKey} />,
    my: <MyBusiness searchText={searchText} sortKey={sortKey} />,
    // favorite: <FavouriteList searchText={searchText} sortKey={sortKey} />,
  }

  // const sortFilterData = [
  //   {
  //     label: 'By Created Date',
  //     value: 'createdDate',
  //     showOn: ['my', 'favorite'],
  //   },
  //   {
  //     label: 'By Favorite Date',
  //     value: 'favDate',
  //     showOn: ['favorite'],
  //   },
  // ]

  return (
    <ComponentView
      heading={heading}
      optionList={optionList}
      setSearchText={setSearchText}
      // sortFilterData={sortFilterData}
      sortKey={sortKey}
      setSortKey={setSortKey}
      sortSwitchValue={sortSwitchValue}
      setSortSwitchValue={setSortSwitchValue}
    />
  )
}

export { DonorOrganizationsView }

import { awsconfig } from 'configs'
import React from 'react'
import {
  Row,
  Col,
  Card,
  Modal,
  Button,
  Skeleton,
  Typography,
  Descriptions,
  Tooltip,
  Input,
  Form,
  message,
} from 'antd'
import { ImTarget } from 'react-icons/im'
import Api from 'api'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useDispatch } from 'react-redux'
import { getEventDetailWithoutLoading } from 'store/eventSlice/eventActions'
import { formatAmount } from 'utils'
import { BiDollarCircle } from 'react-icons/bi'

const AuctionItemViewModal = ({
  visible,
  item,
  close,
  fetchData,
  type,
  parentId,
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { user } = useAuthSelector()

  const onFinish = (values) => {
    if (Number(values?.bidAmount) > Number(item?.currentBid)) {
      if (user) {
        const data = {
          type,
          id: parentId,
          itemId: item.id,
          bidData: {
            name: `${user?.firstname} ${user?.lastname}`,
            email: user?.email,
            phone: user?.phone,
            bid: values?.bidAmount,
          },
        }
        Api.put('auction/updateEventAuctionItemBid', data)
          .then(() => {
            fetchData()
            if (type === 'event') {
              dispatch(getEventDetailWithoutLoading(parentId))
            }
            message.success('You bid Successfully')
            form.resetFields()
            close()
          })
          .catch((error) => {
            console.log('error', error)
            message.error(
              error?.response?.data?.message || 'Something went wrong',
            )
          })
      } else {
        message.error('User data not found')
      }
    } else {
      message.error('You bid must be greater than current bid')
    }
  }

  return (
    <>
      <Modal
        centered
        keyboard
        title="Auction Item View"
        visible={visible}
        onCancel={close}
        footer={null}
        destroyOnClose
      >
        {item && (
          <Card bordered={false} className="header-solid card-product mb-24">
            <Skeleton loading={false} active paragraph={{ rows: 18 }}>
              <Row gutter={[24, 0]}>
                <Col span={24} className="col-gallery">
                  <img
                    src={
                      item?.itemImage?.fileName &&
                      awsconfig.bucketUrl + item?.itemImage?.fileName
                    }
                    className="t-h-40 t-w-full t-rounded-lg shadow-3 t-object-cover"
                    alt="card-2"
                  />
                </Col>
                <Col span={24} className="t-p-4">
                  <div className="t-mb-4">
                    <h1 className="t-text-secondary-100 t-text-2xl md:t-text-4xl t-font-bold">
                      {item?.title}
                    </h1>
                    <Descriptions colon={false} className="t-my-4">
                      <Descriptions.Item
                        label={
                          <Tooltip title="Current Bid">
                            <BiDollarCircle
                              fontSize="1.3rem"
                              className="t-text-secondary-100 t-cursor-pointer"
                            />
                          </Tooltip>
                        }
                        span={3}
                      >
                        <Tooltip title="Current Bid">
                          {item?.currentBid && formatAmount(item?.currentBid)}
                        </Tooltip>
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions colon={false}>
                      <Descriptions.Item
                        label={
                          <Tooltip title="Target Bid">
                            <ImTarget
                              fontSize="1.2rem"
                              className="t-text-secondary-100 t-cursor-pointer"
                            />
                          </Tooltip>
                        }
                        span={3}
                      >
                        <Tooltip title="Target Bid">
                          {item?.winningBid && formatAmount(item?.winningBid)}
                        </Tooltip>
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                  <div className="t-mb-4">
                    <Typography.Title
                      level={4}
                      className="t-text-secondary-100"
                    >
                      Description
                    </Typography.Title>
                    <Typography.Paragraph className="t-text-justify">
                      {item?.description}
                    </Typography.Paragraph>
                    <Form
                      form={form}
                      layout="vertical"
                      key={0}
                      onFinish={onFinish}
                      name="Bid"
                      scrollToFirstError
                    >
                      <Row className="t-p-1">
                        <Col xl={24} md={24} xs={24} sm={24}>
                          <Form.Item
                            name="bidAmount"
                            label="Bid Amount"
                            colon={false}
                            // rules={[creditCardValidation]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={24} className="text-right t-mt-6">
                          <Button
                            type="primary"
                            className="t-w-full md:t-w-auto"
                            htmlType="submit"
                          >
                            Bid
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Skeleton>
          </Card>
        )}
      </Modal>
    </>
  )
}

export { AuctionItemViewModal }

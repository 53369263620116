/* eslint-disable */
import React, { useRef } from 'react'
import { Col, Row, Card, Typography, Carousel } from 'antd'
// import { Availability } from './Availability'
import { awsconfig } from 'configs'
import sample from 'assets/images/sample/sample1.png'
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from 'react-icons/bs'

const { Title } = Typography

const RoomDetail = ({ room }) => {
  const slider = useRef(null)

  return (
    <Row gutter={[24, 0]}>
      <Col span={24}>
        <Card
          className="t-border-none t-shadow-none"
          cover={
            room?.Image?.length <= 1 || room?.Image?.length === undefined ? (
              <img
                src={
                  room?.Image?.length
                    ? awsconfig.bucketUrl + room?.Image[0]?.fileName
                    : room?.organization?.BackgroundImage?.fileName
                    ? awsconfig.bucketUrl +
                      room?.organization?.BackgroundImage?.fileName
                    : sample
                }
                className="t-h-36 md:t-h-80 t-rounded-lg t-object-cover"
                alt="card-2"
              />
            ) : (
              <></>
            )
          }
        >
          {room?.Image?.length > 1 && (
            <>
              <BsFillArrowLeftCircleFill
                fontSize="1.2rem"
                onClick={() => slider.current.prev()}
                className="t-text-secondary-100 t-cursor-pointer t-absolute t-top-[35%] -t-left-[6px] t-hidden md:t-block"
              />
              <Carousel ref={slider} slidesPerRow={1} autoplay>
                {room?.Image.map((flyerItem) => (
                  <img
                    src={
                      flyerItem?.fileName
                        ? awsconfig.bucketUrl + flyerItem?.fileName
                        : sample
                    }
                    className="t-h-36 md:t-h-80 t-rounded-lg t-object-cover"
                    alt="card-2"
                  />
                ))}
              </Carousel>
              <BsFillArrowRightCircleFill
                fontSize="1.2rem"
                onClick={() => slider.current.next()}
                className="t-text-secondary-100 t-cursor-pointer t-absolute t-top-[35%] -t-right-[6px] t-hidden md:t-block"
              />
            </>
          )}
          <Col span={24} className="t-flex t-flex-row t-mt-2 t-mb-4">
            <Title
              level={5}
              className="t-text-[#00bd9b] t-font-semibold t-bg-[#dbf2ee] t-px-2 t-py-1 t-rounded t-ml-[-20px] t-mr-2"
            >{`Area: ${room?.Breadth}×${room?.Width} sq ft`}</Title>
            <Title
              level={5}
              className="t-text-[#2e97de] t-font-semibold t-bg-[#dfecf5] t-px-2 t-py-1 t-rounded t-mx-2 t-mt-0"
            >{`Capacity: ${room?.Capacity}`}</Title>
          </Col>
        </Card>
      </Col>
    </Row>
  )
}

export { RoomDetail }

import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: {
    fetchHardware: false,
    fetchHardwareList: false,
    fetchOrganizationOptions: false,
    addOrUpdate: false,
    deletingHardware: false,
  },
  hardware: null,
  hardwareList: [],
  organizationOptions: [],
}

export const hardwareSlice = createSlice({
  name: 'hardware',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = {
        ...state,
        loading: { ...state.loading, ...payload },
      }
      return alteredState
    },
    setHardware: (state, { payload }) => {
      const alteredState = { ...state, hardware: payload }
      return alteredState
    },
    setHardwareList: (state, { payload }) => {
      const alteredState = {
        ...state,
        hardwareList: payload,
      }
      return alteredState
    },
    setOrganizationOptions: (state, { payload }) => {
      const alteredState = {
        ...state,
        organizationOptions: payload,
      }
      return alteredState
    },
    reset: () => initialState,
  },
})

export const useHardwareSelector = () => useSelector((state) => state.hardware)
export const hardwareActions = hardwareSlice.actions
export const hardwareReducer = hardwareSlice.reducer

import React, { useState } from 'react'
import { Row, Col, Button, Input, Form, Card, Divider, message } from 'antd'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Api from 'api'
import {
  organizationActions,
  useOrganizationSelector,
} from 'store/organizationSlice/organizationReducer'
import { useDispatch } from 'react-redux'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { ConnectStripe } from './ConnectStripe'
import { ConnectPaypal } from './ConnectPaypal'
const { TextArea } = Input

const schema = Yup.object().shape({
  Name: Yup.string()
    .required('Category name is required')
    .max(12, 'Category name should be atmost 12 character'),
  Description: Yup.string().required('Category description is required'),
})

const editDonorValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  Name: '',
  Description: '',
}

const AddPaymentCategory = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const [stripeId, setStripeId] = useState()
  const [paypalId, setPaypalId] = useState()
  const [loading, setLoading] = useState(false)
  const { organization } = useAuthSelector()
  const { paymentCategoryData } = useOrganizationSelector()

  const onFinish = (values) => {
    const data = {
      ...values,
      StripeId: stripeId,
      StripeOnBoarded: stripeId || false,
      PaypalId: paypalId,
      OrganizationId: organization?.id,
    }

    setLoading(true)
    Api.put('/paymentCategories', data)
      .then(() => {
        dispatch(
          organizationActions.setPaymentCategoryData({
            Name: '',
            Description: '',
            StripeId: null,
            PaypalId: null,
          }),
        )
        setLoading(false)
        message.success('Category Added Successfully')
        history.push('/settings/manage-payments-accounts')
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
        message.error('An Error Occured')
      })
    setLoading(false)
  }

  const getCategoryData = () => {
    const data = {
      Name: form.getFieldValue('Name'),
      Description: form.getFieldValue('Description'),
      StripeId: stripeId,
      PaypalId: paypalId,
    }
    return data
  }

  const setCategoryData = (type, id) => {
    form.setFieldsValue({
      Name: paymentCategoryData.Name,
      Description: paymentCategoryData.Description,
    })
    if (type === 'stripe') {
      setStripeId(id)
      setPaypalId(paymentCategoryData.PaypalId)
    } else {
      setStripeId(paymentCategoryData.StripeId)
      setPaypalId(id)
    }
  }

  const onCancel = () => {
    dispatch(
      organizationActions.setPaymentCategoryData({
        Name: '',
        Description: '',
        StripeId: null,
        PaypalId: null,
      }),
    )
    history.push('/settings/manage-payments-accounts')
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <div className="t-flex t-justify-between t-items-center">
          <h6 className="mb-0">Add Payment Category</h6>
        </div>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="Payment Category"
        initialValues={initialValues}
        scrollToFirstError
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item
              name="Name"
              label="Category Name"
              colon={false}
              rules={[editDonorValidation]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="Description"
              label="Category Description"
              colon={false}
              rules={[editDonorValidation]}
            >
              <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
            </Form.Item>
            <Col className="t-pb-8">
              <ConnectStripe
                stripeId={stripeId}
                getCategoryData={getCategoryData}
                setCategoryData={setCategoryData}
              />
              <Divider />
              <ConnectPaypal
                paypalId={paypalId}
                getCategoryData={getCategoryData}
                setCategoryData={setCategoryData}
              />
            </Col>
            <div className="t-text-right t-space-x-2">
              <Button
                type="primary"
                htmlType="button"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={!stripeId && !paypalId}
              >
                Add Category
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export { AddPaymentCategory }

import React, { useEffect, useRef, useState } from 'react'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { RiErrorWarningFill } from 'react-icons/ri'
import { awsconfig } from 'configs'
import { useAuthSelector } from 'store/authSlice/authReducer'
import {
  Row,
  Form,
  Checkbox,
  Button,
  Col,
  Typography,
  message,
  Input,
} from 'antd'
import {
  PayPalButtons,
  usePayPalScriptReducer,
  PayPalScriptProvider,
} from '@paypal/react-paypal-js'
import * as Yup from 'yup'
import { payAuctionPaypal } from 'store/eventSlice/eventActions'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { TermAndConditionModal } from 'components/elements'
import { savePaypalPaymentLog } from 'store/paymentSlice/paymentActions'
import { calculateComission } from 'utils'

const { Title, Paragraph } = Typography

const numberValidation = new RegExp('^[0-9]+$')

const schema = Yup.object().shape({
  askedAmount: Yup.string()
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
  agreeToTerms: Yup.string().required('Required'),
})

const paypalValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  askedAmount: null,
  agreeToTerms: false,
}

const Paypal = () => {
  const { soldItem, subscriberPaymentInfo } = useEventSelector()
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()

  Form.useWatch('agreeToTerms', form)
  Form.useWatch('askedAmount', form)

  useEffect(() => {
    if (soldItem?.winnerBid) {
      form.setFieldsValue({ askedAmount: soldItem?.winnerBid })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soldItem?.winnerBid])

  /* eslint-disable */
  const onFinish = async () => {}

  const isAgreed = !form.getFieldValue('agreeToTerms')

  Form.useWatch('repeatMonths', form)
  Form.useWatch('splitDonation', form)

  return (
    <div>
      {subscriberPaymentInfo?.paypal?.isConnected ? (
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="auction payment"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row className="t-p-3">
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="askedAmount"
                label="Asked Amount"
                colon={false}
                rules={[paypalValidation]}
              >
                <Input
                  disabled
                  type="number"
                  inputMode="numeric"
                  placeholder="Enter amount here"
                />
              </Form.Item>
            </Col>

            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="agreeToTerms"
                colon={false}
                rules={[paypalValidation]}
              >
                <Checkbox
                  onChange={(e) =>
                    form.setFieldsValue({
                      agreeToTerms: e.target.checked,
                    })
                  }
                >
                  <TermAndConditionModal />
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24} className="text-right">
              {loading ? (
                <Button
                  loading={loading}
                  type="primary"
                  className="t-w-full md:t-w-auto"
                  htmlType="submit"
                >
                  Donate
                </Button>
              ) : (
                <>
                  <PaypalProvider
                    disabled={isAgreed}
                    amount={form.getFieldValue('askedAmount')}
                    setLoading={setLoading}
                  />
                  <PaypalProvider
                    disabled={isAgreed}
                    amount={form.getFieldValue('askedAmount')}
                    setLoading={setLoading}
                    isVenmo
                  />
                </>
              )}
            </Col>
          </Row>
        </Form>
      ) : (
        <div className="t-h-96 t-flex t-items-center t-justify-center t-flex-col">
          <RiErrorWarningFill
            fontSize="2.5rem"
            className="t-text-secondary-100"
          />
          <Title level={5}>
            Paypal payments are not configured for this{' '}
            {soldItem?.type === 'auction' ? 'auction' : 'event'}
          </Title>
          <Paragraph>You can use other payment methods</Paragraph>
        </div>
      )}
    </div>
  )
}

const PaypalProvider = (props) => {
  const { subscriberPaymentInfo } = useEventSelector()
  return (
    <PayPalScriptProvider
      options={{
        'client-id': awsconfig.paypal_client_id,
        'merchant-id': subscriberPaymentInfo?.paypal?.paypalId,
        components: 'buttons',
        currency: 'USD',
      }}
    >
      <PaypalSinglePaymentButton {...props} />
    </PayPalScriptProvider>
  )
}

const PaypalSinglePaymentButton = ({
  disabled,
  amount,
  setLoading,
  isVenmo,
}) => {
  usePayPalScriptReducer()
  const history = useHistory()
  const { subscriberPaymentInfo, soldItem } = useEventSelector()
  const isAuction = soldItem?.type === 'auction'
  const { user } = useAuthSelector()
  const comission = calculateComission(
    Number(amount),
    subscriberPaymentInfo?.markup,
  )

  const donationAmount = useRef(0)

  useEffect(() => {
    donationAmount.current = amount
  }, [amount])

  return (
    <PayPalButtons
      fundingSource={isVenmo ? 'venmo' : 'paypal'}
      style={{ layout: 'vertical', label: 'donate' }}
      disabled={disabled}
      createOrder={(data, actions) =>
        actions.order
          .create({
            meta: {
              partner_attribution_id: awsconfig.paypal_bncode,
              'PayPal-Partner-Attribution-ID': awsconfig.paypal_bncode,
            },
            purchase_units: [
              {
                amount: {
                  value: Number(donationAmount.current),
                  breakdown: {
                    item_total: {
                      currency_code: 'USD',
                      value: Number(donationAmount.current),
                    },
                  },
                },
                payment_instruction: {
                  disbursement_mode: 'INSTANT',
                  platform_fees: [
                    {
                      amount: {
                        currency_code: 'USD',
                        value: comission,
                      },
                    },
                  ],
                },
                items: [
                  {
                    name: soldItem?.title,
                    quantity: '1',
                    unit_amount: {
                      currency_code: 'USD',
                      value: Number(donationAmount.current),
                    },
                    category: 'DONATION',
                  },
                ],
              },
            ],
          })
          .then((orderId) => orderId)
          .catch((error) => {
            throw new Error(
              'Request is not well-formed, syntactically incorrect, or violates schema',
            )
          })
      }
      onApprove={(data, actions) =>
        actions.order
          .capture()
          .then(async (details) => {
            setLoading(true)
            const capturedAmount = details?.purchase_units[0]?.amount?.value
            const paymentId =
              details?.purchase_units[0]?.payments.captures[0].id
            try {
              const data = {
                amount: capturedAmount,
                stripeAccount: subscriberPaymentInfo?.stripe?.stripeId,
                user,
                commission: subscriberPaymentInfo.markup,
                soldItem,
                paymentId,
              }
              await payAuctionPaypal(data)
              history.push(
                isAuction
                  ? `/auction/${soldItem.auctionId}`
                  : `/events/${soldItem?.eventId}`,
              )
              message.success('Your payment is successfully submitted')
              setLoading(false)
            } catch (err) {
              message.error(err?.message || 'Something went wrong')
              setLoading(false)
            }
          })
          .catch((error) => {
            throw new Error('Paypal failed to capture the payment')
          })
      }
      onError={async (error) => {
        message.error('Could not able to process your payment')
        const payload = {
          type: 'PAYPAL_SEED_DONATION_CAMPAIGN',
          status: 'FAILURE',
          message: error?.message,
          action: 'Paypal payment for auction item',
        }
        await savePaypalPaymentLog(payload)
      }}
      onCancel={() => message.info('Payment is cancelled')}
    />
  )
}

export { Paypal }

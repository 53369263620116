import React, { useEffect, useState } from 'react'
import { Card, Skeleton, Row } from 'antd'
import { config } from 'configs'
import { formatAmount } from 'utils'
import moment from 'moment'
import { FcDonate } from 'react-icons/fc'
import { InfiniteScrollContainer } from 'components/elements'
import { useFundraiserSelector } from 'store/fundraiserSlice/fundraiserReducer'
import { DonateFromSeed } from './DonateFromSeed'

const SeedDonations = () => {
  const { loading, fundraiserDetail } = useFundraiserSelector()
  const [modal, setModal] = useState(null)
  const [data, setData] = useState([])
  const [current, setCurrent] = useState([])

  useEffect(() => {
    if (fundraiserDetail?.seedDonations?.length > 0) {
      setData(fundraiserDetail?.seedDonations)
      setCurrent(fundraiserDetail?.seedDonations?.slice(0, config.rowsPerPage))
    }
    // eslint-disable-next-line
  }, [fundraiserDetail])

  const seedDonationsTotal = fundraiserDetail?.seedDonations.reduce(
    (total, item) => total + Number(item.RemainingAmount),
    0,
  )

  const getDonorName = (item) => {
    const firstName = item?.DonorFirstName || ''
    const lastName = item?.DonorLastName || ''

    return `${firstName} ${lastName}`
  }

  return (
    <Card className="t-w-full">
      <Row className="t-p-4 t-flex t-justify-between">
        <h1>
          Following are the seed donations that you can use to boost your
          fundraising
        </h1>
        <h1 className="t-font-bold t-text-secondary-100 t-text-lg">
          Available Seed ({formatAmount(seedDonationsTotal)})
        </h1>
      </Row>
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <div className="t-h-80">
          {data.length === 0 && (
            <div className="t-flex t-justify-center t-items-center t-h-full t-w-full">
              No Seed Donations Available
            </div>
          )}
          {data.length > 0 && (
            <InfiniteScrollContainer
              data={data}
              current={current}
              setCurrent={setCurrent}
              height="265px"
              itemsFirstLoad={config.rowsPerPage}
            >
              <>
                {current.map((item, index) => (
                  <div
                    key={index}
                    className={`t-w-full t-rounded-2xl t-h-12 t-p-3 ${
                      true ? 't-bg-yellow-100' : 't-bg-red-100'
                    } t-text-secondary-100 t-mb-2 t-flex t-items-center t-shadow-md t-justify-between`}
                  >
                    <div className="t-flex t-space-x-2 t-items-center">
                      <div className="t-w-8 t-h-8 t-rounded-full t-flex t-items-center t-justify-center t-bg-primary-100 t-p-2 t-shadow-lg t-text-white t-font-bold">
                        {getDonorName(item)
                          ? item.DonorFirstName.substring(0, 1).toUpperCase()
                          : ''}
                      </div>
                      <h1 className="t-font-bold t-text-xs md:t-text-sm">
                        {`${getDonorName(item)} donated via ${
                          item.PaymentMethod
                        }`}
                      </h1>
                    </div>
                    <div className="t-flex t-space-x-2 t-items-center">
                      <div className="t-flex t-flex-col">
                        <h2 className="t-font-bold t-text-secondary-100 t-text-right t-text-xs md:t-text-sm">
                          {`${formatAmount(
                            item.RemainingAmount,
                          )} / ${formatAmount(item.Amount)}`}
                        </h2>
                        <h2 className="t-text-gray-800 t-italic t-text-xs md:t-text-sm t-whitespace-nowrap t-text-right">
                          {moment(moment.utc(item.PaymentDate))
                            .local()
                            .fromNow()}
                        </h2>
                      </div>
                      <FcDonate
                        fontSize="2rem"
                        className="t-cursor-pointer"
                        onClick={() => setModal(item)}
                      />
                    </div>
                  </div>
                ))}
              </>
            </InfiniteScrollContainer>
          )}
        </div>
        <DonateFromSeed isVisible={modal} onClose={() => setModal(null)} />
      </Skeleton>
    </Card>
  )
}

export { SeedDonations }

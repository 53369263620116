import { Button, Col, Divider, Form, Input, Modal, Row } from 'antd'
import React, { useEffect } from 'react'
import { messageFormValidation } from 'validations/messageForm'

const initialValues = {
  message: '',
}

const SendInvite = ({ show, setShow, organization, submit }) => {
  const [messageForm] = Form.useForm()

  useEffect(() => {
    messageForm.setFieldsValue({
      message: `You have been invited by ${organization.Name}`,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinish = (values) => {
    messageForm.resetFields()
    submit(values)
  }

  const onClose = () => {
    messageForm.resetFields()
    setShow(false)
  }
  return (
    <Modal
      title="Send Invites"
      visible={show}
      onCancel={() => onClose()}
      footer={null}
    >
      <Form
        form={messageForm}
        layout="vertical"
        key={0}
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Row className="t-p-1">
          <Col span={24}>
            <Form.Item
              name="message"
              label="Message"
              colon={false}
              rules={[messageFormValidation]}
            >
              <Input.TextArea
                placeholder="Enter Message for invitation ..."
                autoSize={{ minRows: 3, maxRows: 3 }}
              />
            </Form.Item>
          </Col>
          <Divider />
          <Col span={24} className="text-right">
            <Button type="primary" className="px-25" htmlType="submit">
              Send
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export { SendInvite }

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Row,
  Form,
  Checkbox,
  Button,
  Col,
  message,
  Input,
  Select,
  Typography,
} from 'antd'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { usePlaidLink } from 'react-plaid-link'
import {
  getBankAccount,
  getExchangeToken,
  getPlaidToken,
  getUserBanks,
} from 'store/paymentSlice/paymentActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { RiErrorWarningFill } from 'react-icons/ri'
import * as Yup from 'yup'
import { payAuctionStripe } from 'store/eventSlice/eventActions'
import { useHistory } from 'react-router-dom'
import { TermAndConditionModal } from 'components/elements'

const { Title, Paragraph } = Typography

const numberValidation = new RegExp('^[0-9]+$')

const schema = Yup.object().shape({
  askedAmount: Yup.string()
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
  agreeToTerms: Yup.string().required('Required'),
  bank: Yup.string().required('Required'),
})

const bankDebitValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  askedAmount: null,
  includeEventCosts: true,
  agreeToTerms: false,
  bank: 'no-bank-selected',
}

const BankDebit = () => {
  const history = useHistory()
  const [token, setToken] = useState(null)
  const [banks, setBanks] = useState([])
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()

  const { user } = useAuthSelector()
  const { soldItem, subscriberPaymentInfo } = useEventSelector()
  const isAuction = soldItem?.type === 'auction'

  Form.useWatch('bank', form)
  Form.useWatch('askedAmount', form)
  Form.useWatch('agreeToTerms', form)

  const bank = form.getFieldValue('bank')

  const isNoBankSelected = bank === 'no-bank-selected'

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.OrganizationId])

  useEffect(() => {
    if (soldItem?.winnerBid) {
      form.setFieldsValue({ askedAmount: soldItem?.winnerBid })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soldItem?.winnerBid])

  const onSuccess = async (PUBLIC_TOKEN, metadata) => {
    try {
      setLoading(true)
      for (let i = 0; i < metadata?.accounts?.length; i++) {
        const singleAccount = metadata?.accounts[i]
        const exchangeTokenPayload = {
          publicToken: PUBLIC_TOKEN,
          account: singleAccount,
          user: user?.email,
        }
        const exchangeTokenResponse = await getExchangeToken(
          exchangeTokenPayload,
        )
        const getBankAccountPayload = {
          accessToken: exchangeTokenResponse?.accessToken,
          account_id: singleAccount?.id,
          email: user?.email,
          user,
        }
        await getBankAccount(getBankAccountPayload)
      }
      getCustomerBanks()
      setLoading(false)
    } catch (error) {
      // message.error(error?.response?.data?.message || 'Something Went Wrong')
      getCustomerBanks()
      setLoading(false)
    }
  }

  const onExit = async (error) => {
    message.error(error?.message || 'Error Connecting Bank Account')
  }

  const config = {
    token,
    onSuccess,
    onExit,
  }

  const { open } = usePlaidLink(config)

  /* eslint-disable */
  const onFinish = async (values) => {
    const askedAmountValue = Number(values.askedAmount)
    try {
      if (!values.agreeToTerms) {
        return message.error('You must agree to the terms and conditions')
      }

      setLoading(true)
      // if bank account is linked or not
      if (banks.length === 0) {
        message.warn('Link Your Bank Account')
        setLoading(false)
      } else {
        const data = {
          amount: askedAmountValue,
          card: bank,
          cardToken: null,
          stripeAccount: subscriberPaymentInfo?.stripe?.stripeId,
          user,
          commission: subscriberPaymentInfo.markup,
          isBankAccount: true,
          soldItem,
        }
        await payAuctionStripe(data)
        history.push(
          isAuction
            ? `/auction/${soldItem.auctionId}`
            : `/events/${soldItem?.eventId}`,
        )
        message.success('Your payment is successfully submitted')
        form.resetFields()
        setLoading(false)
      }
    } catch (error) {
      console.log('error', error)
      message.error(error?.response?.data?.message || 'Something Went Wrong')
      setLoading(false)
    }
  }

  const getCustomerBanks = async () => {
    try {
      const cards = await getUserBanks(user)
      setBanks(cards)
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }
  }

  const getToken = async () => {
    try {
      const payload = {
        id: user?.id,
        email: user?.email,
        name: `${user?.firstname} ${user?.lastname}`,
      }
      const response = await getPlaidToken(payload)
      setToken(response)
    } catch (error) {
      setLoading(false)
    }
  }

  const fetchData = async () => {
    setLoading(true)
    await getCustomerBanks()
    await getToken()
    setLoading(false)
  }

  const isAgreed = !form.getFieldValue('agreeToTerms')

  return (
    <div>
      {subscriberPaymentInfo?.stripe?.isConnected ? (
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="auction payment"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row className="t-p-3">
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="askedAmount"
                label="Asked Amount"
                colon={false}
                rules={[bankDebitValidation]}
              >
                <Input
                  disabled
                  type="number"
                  inputMode="numeric"
                  placeholder="Enter amount here"
                />
              </Form.Item>
            </Col>
            {banks.length > 0 ? (
              <>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <p className="t-mb-4">
                    You have connected following bank accounts to your account.
                    Your bank account will be charged with amount mentioned
                    below.
                  </p>
                  <p className="t-mb-4 t-font-bold t-text-red-600">
                    Bank debit payments can take up to 7 business days.
                  </p>
                </Col>
                {banks.length > 0 && (
                  <Col xl={24} md={24} xs={24} sm={24}>
                    <Form.Item
                      name="bank"
                      label="Select Bank"
                      colon={false}
                      rules={[bankDebitValidation]}
                    >
                      <Select
                        placeholder="Select Bank"
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) => trigger.parentElement}
                      >
                        <Select.Option value={'no-bank-selected'}>
                          {'No Bank Account Selected'}
                        </Select.Option>
                        {banks.map((item, index) => (
                          <Select.Option
                            value={item.id}
                            key={index}
                            className="t-capitalize"
                          >
                            {`${item.bank_name} - **** **** **** ${item.last4}`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {banks.length > 0 && (
                  <Col span={24}>
                    <Row className="t-flex t-justify-end ">
                      <h1
                        onClick={() => setManageCardModalVisible(true)}
                        className="t-text-secondary-100 t-underline t-cursor-pointer"
                      >
                        Manage Banks
                      </h1>
                    </Row>
                  </Col>
                )}
                {isNoBankSelected && (
                  <Col xl={24} md={24} xs={24} sm={24} className="t-mb-6">
                    <Button
                      loading={loading}
                      type="primary"
                      className="px-25"
                      onClick={open}
                    >
                      Link Bank Account
                    </Button>
                  </Col>
                )}
              </>
            ) : (
              <>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <Paragraph className="t-text-justify">
                    We use Plaid’s authentication service to ensure privacy of
                    your banking information.
                  </Paragraph>
                </Col>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <Paragraph className="t-text-justify">
                    You will now be forwarded to Plaid to authenticate with your
                    bank and process your payment.
                  </Paragraph>
                </Col>
                <Col xl={24} md={24} xs={24} sm={24} className="t-mb-6">
                  <Button
                    loading={loading}
                    type="primary"
                    className="px-25"
                    onClick={open}
                  >
                    Link Bank Account
                  </Button>
                </Col>
              </>
            )}
            {/* {banks.length > 0 ? (
              <>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <p className="t-mb-4">
                    You have connected following bank account to your account.
                    Your bank account will be charged with amount mentioned
                    below.
                  </p>
                  <p className="t-font-extrabold t-w-full t-rounded-2xl t-h-12 t-p-3 t-text-secondary-100 t-mb-6 t-flex t-items-center t-shadow-md t-justify-between t-bg-yellow-100">{`${banks[0].bank_name}      -      **** **** **** ${banks[0].last4}`}</p>
                </Col>
              </>
            ) : (
              <>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <Paragraph className="t-text-justify">
                    We use Plaid’s authentication service to ensure privacy of
                    your banking information.
                  </Paragraph>
                </Col>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <Paragraph className="t-text-justify">
                    You will now be forwarded to Plaid to authenticate with your
                    bank and process your payment.
                  </Paragraph>
                </Col>
                <Col xl={24} md={24} xs={24} sm={24} className="t-mb-6">
                  <Button
                    loading={loading}
                    type="primary"
                    className="px-25"
                    onClick={open}
                  >
                    Link Bank Account
                  </Button>
                </Col>
              </>
            )} */}
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="agreeToTerms"
                colon={false}
                rules={[bankDebitValidation]}
              >
                <Checkbox
                  onChange={(e) =>
                    form.setFieldsValue({
                      agreeToTerms: e.target.checked,
                    })
                  }
                >
                  <TermAndConditionModal />
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24} className="text-right">
              <Button
                loading={loading}
                disabled={banks.length === 0 || isAgreed}
                type="primary"
                className="t-w-full md:t-w-auto"
                htmlType="submit"
              >
                Pay Via Bank Account
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <div className="t-h-72 t-flex t-items-center t-justify-center t-flex-col">
          <RiErrorWarningFill
            fontSize="2.5rem"
            className="t-text-secondary-100"
          />
          <Title level={5}>
            Bank account payments are not configured for this{' '}
            {isAuction ? 'auction' : 'event'}
          </Title>
          <Paragraph>You can use other payment methods</Paragraph>
        </div>
      )}
    </div>
  )
}

export { BankDebit }

/* eslint-disable */
import React from 'react'
import { Button } from 'antd'
import { SVGIcon } from 'components'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const RoomNotFound = () => {
  const history = useHistory()
  return (
    <div className="ant-result ant-result-404">
      <SVGIcon />
      <div className="ant-result-title">Invalid URL</div>
      <div className="ant-result-subtitle">
        Sorry, the page you visited does not exist.
      </div>
      <div className="ant-result-extra">
        <Button
          onClick={() => history.push('/rentals')}
          type="primary"
          size="small"
        >
          Goto Rentals
        </Button>
      </div>
    </div>
  )
}

export { RoomNotFound }

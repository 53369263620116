import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  getFundraiserControlScreenDetails,
  getFundraiserDetailWithoutLoading,
} from 'store/fundraiserSlice/fundraiserActions'

const SECONDS = 25

const useDonations = (id) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const interval = setInterval(() => {
      if (id) {
        dispatch(getFundraiserDetailWithoutLoading(id))
        dispatch(getFundraiserControlScreenDetails(id))
      }
    }, SECONDS * 1000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return true
}

export { useDonations }

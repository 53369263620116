/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Card, Button, Skeleton } from 'antd'
import { ArrowRightOutlined, CarryOutFilled } from '@ant-design/icons'
import Api from 'api'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { capitalizeFirstLetter, formatAmount } from 'utils'
import { ShareReport } from 'components/elements/ShareReport'
import { InfiniteScrollContainer } from 'components/elements'
import { useHistory } from 'react-router-dom'
import { config } from 'configs'

const YtdEarningReport = () => {
  const history = useHistory()
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()
  const [pdfData, setPdfData] = useState()
  const [selected, setSelected] = useState()

  useEffect(() => {
    setLoading(true)
    Api.get('adminReports/ytdEarningReport')
      .then((res) => {
        setReport(res.results)
        setPdfData(res.results)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
  }, [])

  return (
    <Card
      id="YtdEarningReports"
      // className="mb-24 header-solid list-settings-sessions"
      className="header-solid"
      bordered={false}
      title={[
        <div className="t-flex t-justify-between t-items-center">
          {selected === undefined ? (
            <div className="t-flex t-space-x-4">
              <IoMdArrowRoundBack
                fontSize="1.5rem"
                className="t-cursor-pointer t-min-w-[1.5rem]"
                id="back"
                onClick={() =>
                  history.push('/settings/manage-organization/my-reports')
                }
              />
              <h5 className="font-semibold">YTD Revenue Report</h5>
            </div>
          ) : (
            <div className="t-flex t-space-x-4">
              <IoMdArrowRoundBack
                fontSize="1.5rem"
                className="t-cursor-pointer t-min-w-[1.5rem]"
                id="back"
                onClick={() => {
                  setPdfData(report)
                  setSelected()
                }}
              />
              <h5 className="font-semibold t-whitespace-normal">
                Ytd Revenue Report -{' '}
                <span className="t-capitalize">{selected}</span>
              </h5>
            </div>
          )}
          {!loading && pdfData != null && (
            <ShareReport
              reportName={
                selected === undefined
                  ? 'YTD Revenue Reports'
                  : `YTD Revenue Reports - ${capitalizeFirstLetter(selected)}`
              }
              // isSummary is boolean which refect if report is summary report or simple report
              isSummary={selected === undefined}
              report={pdfData}
            />
          )}
        </div>,
      ]}
    >
      {selected === undefined ? (
        <Skeleton loading={loading} paragraph={{ rows: 12 }}>
          {['events', 'campaigns', 'programs', 'services'].map(
            (item, index) => (
              <>
                {index !== 0 && <hr className="gradient-line my-10" />}
                <div className="ant-row-flex ant-row-flex-middle">
                  <div
                    className="text-center ant-col"
                    style={{ minWidth: '40px' }}
                  >
                    <CarryOutFilled
                      className="text-gray-6"
                      style={{ fontSize: '18px' }}
                    />
                  </div>
                  <div className="ant-col pl-15">
                    <p className="mb-0 t-capitalize">{item}</p>
                  </div>
                  <div
                    className="ml-auto ant-col ant-col-12"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <span className="ml-5">
                      {report && report[item]
                        ? formatAmount(report[item])
                        : '$0.00'}
                    </span>
                    <Button
                      type="link"
                      id={`ytd_earning_${item}_see_more`}
                      className="t-cursor-pointer"
                      onClick={() => {
                        setPdfData()
                        setSelected(item)
                      }}
                    >
                      See More <ArrowRightOutlined />
                    </Button>
                  </div>
                </div>
              </>
            ),
          )}
        </Skeleton>
      ) : (
        <Single selected={selected} setPdfData={setPdfData} />
      )}
    </Card>
  )
}

function Single({ selected, setPdfData }) {
  const url = {
    events: 'adminReports/ytdEarningReportByEvent',
    campaigns: 'adminReports/ytdEarningReportByCampaign',
    programs: 'adminReports/ytdEarningReportByProgram',
    services: 'adminReports/ytdEarningReportByService',
  }
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()
  const [current, setCurrent] = useState([])

  function compare(a, b) {
    if (a.totalAmount < b.totalAmount) {
      return 1
    }
    if (a.totalAmount > b.totalAmount) {
      return -1
    }
    return 0
  }

  useEffect(() => {
    setLoading(true)
    Api.get(url[selected])
      .then((res) => {
        if (res.results.Items) {
          const data = res.results.Items.sort(compare)
          setReport(data)
          setCurrent(data.slice(0, config.reportItemFirstLoad))
          setPdfData(data)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <Skeleton loading={loading} paragraph={{ rows: 8 }}>
      {report && report.length > 0 ? (
        <InfiniteScrollContainer
          data={report}
          current={current}
          setCurrent={setCurrent}
        >
          <>
            {current.map((item, index) => (
              <>
                {index !== 0 && <hr className="gradient-line my-10" />}
                <div className="ant-row-flex ant-row-flex-middle">
                  <div
                    className="text-center ant-col"
                    style={{ minWidth: '40px' }}
                  >
                    <CarryOutFilled
                      className="text-gray-6"
                      style={{ fontSize: '18px' }}
                    />
                  </div>
                  <div className="ant-col pl-15">
                    <p className="mb-0">{item?.Title}</p>
                    {/* <small className="text-dark">Friday, 12/03/2021</small> */}
                  </div>
                  <div
                    className="ml-auto ant-col ant-col-12"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <span className="ml-5">
                      {item?.totalAmount
                        ? formatAmount(item?.totalAmount)
                        : '$0.00'}
                    </span>
                  </div>
                </div>
              </>
            ))}
          </>
        </InfiniteScrollContainer>
      ) : (
        <div>No Data Found</div>
      )}
    </Skeleton>
  )
}
export { YtdEarningReport }

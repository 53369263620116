import React, { useEffect } from 'react'
import { Table } from 'antd'
import { useDispatch } from 'react-redux'
import { useFundraiserSelector } from 'store/fundraiserSlice/fundraiserReducer'
import { getFundraiserDonations } from 'store/fundraiserSlice/fundraiserActions'
import { numberWithCommas } from 'utils'

function DonationList({ id }) {
  const dispatch = useDispatch()
  const { fundraiserDetail, loading } = useFundraiserSelector()

  const getDonorName = (item) => {
    if (item?.DonorName) {
      return item?.DonorName
    }
    const firstName = item?.DonorFirstName || ''
    const lastName = item?.DonorLastName || ''

    return `${firstName} ${lastName}`
  }

  useEffect(() => {
    dispatch(getFundraiserDonations(id))
  }, [dispatch, id])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'DonorFirstName',
      className: 'ant-table-row-cell-break-word',
      render: (_, record) => getDonorName(record),
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      className: 'ant-table-row-cell-break-word',
      render: (value) => `$${numberWithCommas(value)}`,
    },
    {
      title: 'Note',
      dataIndex: 'DonationNote',
      className: 'ant-table-row-cell-break-word',
      render: (value) => value || '-',
    },
  ]

  return loading ? (
    <></>
  ) : (
    <Table
      tableLayout="auto"
      scroll={{ x: 500 }}
      columns={columns}
      dataSource={fundraiserDetail?.donations || []}
      bordered={false}
      pagination
    />
  )
}

export { DonationList }

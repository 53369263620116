import React, { useEffect, useState } from 'react'
import { Card, Tabs } from 'antd'
import { useLocation } from 'react-router-dom'
import { MyAccount, MyContributions, MyReservations } from './components'

const Account = () => {
  const [activeKey, setActiveKey] = useState('1')
  const { search } = useLocation()

  useEffect(() => {
    if (search) {
      const urlSearchParams = new URLSearchParams(search)
      const { key } = Object.fromEntries(urlSearchParams.entries())
      if (key) setActiveKey(key)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Card className="header-solid" bordered={false}>
      <Tabs
        destroyInactiveTabPane
        animated={false}
        className="sliding-tab"
        activeKey={activeKey}
        onChange={(active) => {
          setActiveKey(active)
        }}
      >
        <Tabs.TabPane tab="Account" key="1">
          <MyAccount />
        </Tabs.TabPane>
        <Tabs.TabPane tab="My Contributions" key="2">
          <MyContributions />
        </Tabs.TabPane>
        <Tabs.TabPane tab="My Reservations" key="3">
          <MyReservations />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  )
}

export { Account }

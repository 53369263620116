import React, { useState } from 'react'
import { Row, Col, Card, Statistic, Tooltip, Popconfirm, Spin } from 'antd'
import { Icon } from 'components/elements'
import { MdOutlineCreditCardOff, MdOutlinePayment } from 'react-icons/md'
import { formatAmount, formatDate } from 'utils'
import { usePlatformSubscriptionSelector } from 'store/platformSubscriptionSlice/platformSubscriptionReducer'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { FaEdit } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { cancelPlaformSubscription } from 'store/platformSubscriptionSlice/platformSubscriptionActions'
import WifiIcon from './WifiIcon'
import { ChangeCreditCard } from './ChangeCreditCard'

const SubscriptionDetails = ({ totalDue }) => {
  const dispatch = useDispatch()
  const { user } = useAuthSelector()
  const { platformSubscription, loading } = usePlatformSubscriptionSelector()
  const [isUpdateCard, setIsUpdateCard] = useState(false)

  const handleCancelSubscription = () => {
    dispatch(
      cancelPlaformSubscription({
        organizationId: OrganizationId,
      }),
    )
  }

  const { OrganizationId } = platformSubscription
  const card = platformSubscription?.card
  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0">Subscription Details</h6>
        </>,
      ]}
    >
      <Row gutter={[24, 0]} className="t-space-y-2">
        <Col xs={24} xl={14} className="t-h-full t-mt-2">
          {isUpdateCard === false ? (
            <div className="t-relative">
              <Card
                title={card && WifiIcon}
                bordered={false}
                className="card-credit header-solid h-full t-p-2 md:t-p-10"
              >
                {card ? (
                  <>
                    <h5 className="card-number">
                      **** **** **** {card?.last4}
                    </h5>

                    <div className="card-footer">
                      <div className="mr-30">
                        <p>User Name</p>
                        <h6>
                          {user?.firstname} {user?.lastname}
                        </h6>
                      </div>
                      <div className="mr-30">
                        <p>Expires</p>
                        <h6>
                          {card?.exp_month}/
                          {card?.exp_year?.toString().slice(-2)}
                        </h6>
                      </div>
                      <div className="card-footer-col col-logo ml-auto">
                        {card?.brand ? (
                          <Icon src="visa_logo" />
                        ) : (
                          <Icon src="mastercard_logo" />
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="t-flex t-justify-center t-items-center t-p-20">
                    Unable to get card details
                  </div>
                )}
              </Card>
              <div className="t-absolute t-top-5 t-right-5 t-flex t-justify-center t-items-center t-space-x-4">
                <Popconfirm
                  title="Are you sure you want to cancel subscription？"
                  placement="top"
                  onConfirm={() => handleCancelSubscription()}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title="Cancel Subscription">
                    {loading?.cancellingPlatformSubscription ? (
                      <Spin />
                    ) : (
                      <MdOutlineCreditCardOff
                        className="t-text-white t-cursor-pointer"
                        fontSize="1.7rem"
                      />
                    )}
                  </Tooltip>
                </Popconfirm>
                <Tooltip title="Change Card">
                  <FaEdit
                    className="t-cursor-pointer t-text-white"
                    style={{ fontSize: '1.7rem' }}
                    onClick={() => setIsUpdateCard(true)}
                  />
                </Tooltip>
              </div>
            </div>
          ) : (
            <ChangeCreditCard
              organizationId={OrganizationId}
              onCancel={() => setIsUpdateCard(false)}
            />
          )}
        </Col>
        <Col xs={24} xl={10} className="t-h-full">
          <Card
            className="header-solid t-p-2 md:t-p-10 t-shadow-2xl t-rounded-lg widget-2 t-h-full t-flex t-justify-center t-items-center"
            bordered={false}
          >
            <Statistic
              title={
                <>
                  <div className="icon">
                    <MdOutlinePayment />
                  </div>
                  <h6 className="t-text-secondary-100 t-text-xl t-font-semi">
                    {formatDate(platformSubscription?.NextPaymentDate)}
                  </h6>
                  <p>Next Payment Date</p>
                </>
              }
              value={formatAmount(totalDue)}
            />
          </Card>
        </Col>
      </Row>
    </Card>
  )
}

export { SubscriptionDetails }

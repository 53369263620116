import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Input, Form, message } from 'antd'
import * as Yup from 'yup'
import Api from 'api'

const emailRegex = /^([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)?$/gi
const phoneNumberValidation = /^(\+[0-9]{11,12})?$/gi
const zipCodeRegex = '^(?:[0-9]{5})?$'
const schema = Yup.object().shape({
  DonorName: Yup.string().required('Donor name is required'),
  DonorEmail: Yup.string().matches(emailRegex, 'Invalid Email'),
  DonorPhoneNumber: Yup.string().matches(
    phoneNumberValidation,
    'Invalid Phone number e.g +1234567891',
  ),
  // DonorEmail: Yup.string().when('DonorPhoneNumber', {
  //   is: (DonorPhoneNumber) => !DonorPhoneNumber || DonorPhoneNumber === '',
  //   then: Yup.string()
  //     .required('Donor email is required')
  //     .matches(emailRegex, 'Invalid Email'),
  // }),
  // DonorPhoneNumber: Yup.string().when('DonorEmail', {
  //   is: (DonorEmail) => !DonorEmail || DonorEmail === '',
  //   then: Yup.string()
  //     .required('Donor phone number is required')
  //     .matches(phoneNumberValidation, 'Invalid Phone number e.g +1234567891'),
  // }),
  DonorZipCode: Yup.string().matches(
    zipCodeRegex,
    'Invalid Zip code e.g 12345',
  ),
})

const editDonorValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  DonorName: '',
  DonorEmail: '',
  DonorPhoneNumber: '',
  DonorZipCode: '',
}

const EditDonorModal = ({
  isVisible,
  onClose,
  editData,
  setEditData,
  getData,
}) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const updateFields = (data) => {
    form.setFieldsValue({ DonorName: data.DonorName })
    if (data.DonorEmail) form.setFieldsValue({ DonorEmail: data.DonorEmail })
    if (data.DonorPhoneNumber)
      form.setFieldsValue({ DonorPhoneNumber: data.DonorPhoneNumber })
    form.setFieldsValue({ DonorZipCode: data.DonorZipCode })
  }

  useEffect(() => {
    if (editData) updateFields(editData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData])

  const onFinish = (values) => {
    const { DonorEmail, DonorPhoneNumber } = values
    if (!DonorEmail && !DonorPhoneNumber) {
      message.error('Donor Email or Donor Phone Number is required')
      return false
    }
    setLoading(true)
    const { Id } = editData
    const data = values
    Api.put(`subscriber/donorList/donor/${Id}`, { data })
      .then(() => {
        message.success('Updated successfully')
        setEditData()
        form.resetFields()
        setLoading(false)
        onClose()
        getData()
      })
      .catch((error) => {
        message.error('An error occurred')
        console.log('error', error)
        setLoading(false)
      })
    return true
  }
  return (
    <Modal
      centered
      title="Edit Donor Modal"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="Edit Donor"
        initialValues={initialValues}
        scrollToFirstError
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item
              name="DonorName"
              label="Donor Name"
              colon={false}
              rules={[editDonorValidation]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="DonorEmail"
              label="Donor Email"
              colon={false}
              rules={[editDonorValidation]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="DonorPhoneNumber"
              label="Donor Phone Number"
              colon={false}
              rules={[editDonorValidation]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="DonorZipCode"
              label="Donor Zip code"
              colon={false}
              rules={[editDonorValidation]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                style={{ width: '100%' }}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Update
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export { EditDonorModal }

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Row,
  Switch,
  Form,
  Checkbox,
  Button,
  Col,
  message,
  Input,
  Typography,
  Popconfirm,
  Select,
  Modal,
} from 'antd'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { usePlaidLink } from 'react-plaid-link'
import {
  deleteUserCard,
  donateByStripe,
  getBankAccount,
  getExchangeToken,
  getPlaidToken,
  getUserBanks,
} from 'store/paymentSlice/paymentActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { RiErrorWarningFill } from 'react-icons/ri'
import { AiFillDelete } from 'react-icons/ai'
import { formatAmount, REGEXS } from 'utils'
import * as Yup from 'yup'
import { TermAndConditionModal, AmountInput } from 'components/elements'

const { Title, Paragraph } = Typography

const amountValidation = REGEXS.amountRegex
const numberValidation = REGEXS.numberRegex

const schema = Yup.object().shape({
  askedAmount: Yup.string()
    .required('Required')
    .matches(amountValidation, 'Invalid amount'),
  includeEventCosts: Yup.string().required('Required'),
  bank: Yup.string().required('Required'),
  agreeToTerms: Yup.string().required('Required'),
  splitDonation: Yup.string().required('Required'),
  splitnoOfMonths: Yup.string()
    .typeError('Required')
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
  repeatMonths: Yup.string().required('Required'),
  repeatnoOfMonths: Yup.string()
    .typeError('Required')
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
})

const bankDebitValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  askedAmount: null,
  includeEventCosts: true,
  agreeToTerms: false,
  splitDonation: false,
  splitnoOfMonths: null,
  repeatMonths: false,
  repeatnoOfMonths: null,
  bank: 'no-bank-selected',
}

const BankDebit = ({ refreshEventData }) => {
  const [token, setToken] = useState(null)
  const [banks, setBanks] = useState([])
  const [loading, setLoading] = useState(false)
  const [manageCardModalVisible, setManageCardModalVisible] = useState(false)

  const [form] = Form.useForm()

  const { user, location, geolocation } = useAuthSelector()
  const { event, subscriberPaymentInfo, joinedEventDetails } =
    useEventSelector()

  Form.useWatch('bank', form)
  Form.useWatch('askedAmount', form)
  Form.useWatch('agreeToTerms', form)
  Form.useWatch('includeEventCosts', form)
  Form.useWatch('repeatMonths', form)
  Form.useWatch('splitDonation', form)
  Form.useWatch('splitnoOfMonths', form)
  Form.useWatch('repeatnoOfMonths', form)

  const repeatMonths = form.getFieldValue('repeatnoOfMonths')
  const splitMonths = form.getFieldValue('splitnoOfMonths')

  const isSplit = form.getFieldValue('splitDonation')
  const isRepeat = form.getFieldValue('repeatMonths')

  const bank = form.getFieldValue('bank')

  const isNoBankSelected = bank === 'no-bank-selected'

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.OrganizationId])

  useEffect(() => {
    form.setFieldsValue({
      askedAmount: event?.CurrentAsk || '0',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.CurrentAsk])

  const onSuccess = async (PUBLIC_TOKEN, metadata) => {
    try {
      setLoading(true)
      for (let i = 0; i < metadata?.accounts?.length; i++) {
        const singleAccount = metadata?.accounts[i]
        const exchangeTokenPayload = {
          publicToken: PUBLIC_TOKEN,
          account: singleAccount,
          user: user?.email,
        }
        const exchangeTokenResponse = await getExchangeToken(
          exchangeTokenPayload,
        )
        const getBankAccountPayload = {
          accessToken: exchangeTokenResponse?.accessToken,
          account_id: singleAccount?.id,
          email: user?.email,
          user,
        }
        await getBankAccount(getBankAccountPayload)
      }
      getCustomerBanks()
      setLoading(false)
    } catch (error) {
      // message.error(error?.response?.data?.message || 'Something Went Wrong')
      getCustomerBanks()
      setLoading(false)
    }
  }

  const onExit = async (error) => {
    message.error(error?.message || 'Error Connecting Bank Account')
  }

  const config = {
    token,
    onSuccess,
    onExit,
  }

  const { open } = usePlaidLink(config)

  const onFinish = async (values) => {
    if (isNoBankSelected) {
      message.error('No Bank Account Selected')
    } else {
      const askedAmountValue = values?.askedAmount
      if (askedAmountValue == '0') {
        message.error("Amount can't be zero")
        return false
      }

      const includeEventCostValue = values.includeEventCosts
      const twoPercentage = (2 / 100) * Number(askedAmountValue)
      const totalDonation = includeEventCostValue
        ? Number(askedAmountValue) + twoPercentage
        : askedAmountValue

      let repeatPayment = {
        split: {
          splitDonation: Boolean(values.splitDonation),
          months: values.splitDonation ? values.splitnoOfMonths || null : null,
        },
        repeat: {
          repeatMonths: Boolean(values.repeatMonths),
          months: values.repeatMonths ? values.repeatnoOfMonths || null : null,
        },
      }

      try {
        if (!values.agreeToTerms) {
          return message.error('You must agree to the terms and conditions')
        }

        // checking the allowed split months
        if (repeatPayment.split.splitDonation) {
          if (
            Number(repeatPayment.split.months) >
            Number(event?.splitPaymentMonths)
          ) {
            return message.error(
              `You can't split the donation for more than ${event?.splitPaymentMonths} months`,
            )
          }
        }

        // checking the allowed repeat months
        if (repeatPayment.repeat.repeatMonths) {
          if (
            Number(repeatPayment.repeat.months) >
            Number(event?.monthlyPaymentMonths)
          ) {
            return message.error(
              `You can't repeat the donation for more than ${event?.monthlyPaymentMonths} months`,
            )
          }
        }

        if (repeatPayment.repeat.repeatMonths) {
          repeatPayment.repeat.months = Number(repeatPayment.repeat.months) + 1
        }

        setLoading(true)
        // if bank account is linked or not
        if (banks.length === 0) {
          message.warn('Link Your Bank Account')
          setLoading(false)
        } else {
          const data = {
            amount: totalDonation,
            card: bank,
            cardToken: null,
            stripeAccount: subscriberPaymentInfo?.stripe?.stripeId,
            user,
            event,
            joinedEventDetails,
            eventCost: twoPercentage,
            location,
            includeEventCost: includeEventCostValue,
            commission: subscriberPaymentInfo.markup,
            isBankAccount: true,
            repeatPayment,
            geolocation,
            // start - event cost values
            eventCostAmount: includeEventCostValue ? twoPercentage : 0,
            totalDonation: askedAmountValue,
            // end - event cost values
          }
          await donateByStripe(data)
          refreshEventData()
          message.success('Your donation is submitted')
          form.resetFields()
          setLoading(false)
        }
      } catch (error) {
        console.log('error', error)
        message.error(error?.response?.data?.message || 'Something Went Wrong')
        setLoading(false)
      }
    }
  }

  const getCustomerBanks = async () => {
    try {
      const cards = await getUserBanks(user)
      setBanks(cards)
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }
  }

  const getToken = async () => {
    try {
      const payload = {
        id: user?.id,
        email: user?.email,
        name: `${user?.firstname} ${user?.lastname}`,
      }
      const response = await getPlaidToken(payload)
      setToken(response)
    } catch (error) {
      setLoading(false)
    }
  }

  const fetchData = async () => {
    setLoading(true)
    await getCustomerBanks()
    await getToken()
    setLoading(false)
  }

  // const isNoBank = form.getFieldValue("bank") === "no-bank-selected";

  const askedAmountValue = Number(form.getFieldValue('askedAmount'))
  const includeEventCostValue = form.getFieldValue('includeEventCosts')
  const isAgreed = !form.getFieldValue('agreeToTerms')

  const twoPercentage = (2 / 100) * Number(askedAmountValue)
  const totalDonation = includeEventCostValue
    ? Number(askedAmountValue) + twoPercentage
    : askedAmountValue

  const getDonateText = () => {
    if (isRepeat) {
      const totalRepeatDonationAmount = (repeatMonths || 0) * totalDonation
      const totalNumberofRepeatMonths = repeatMonths || 0
      const oneMonthAmount =
        totalRepeatDonationAmount / totalNumberofRepeatMonths
      return `You are planning donation that will repeat ${formatAmount(
        totalRepeatDonationAmount,
      )} in next ${totalNumberofRepeatMonths} months with a monthly payment of ${formatAmount(
        oneMonthAmount,
      )}. For keeping you posted your first donation will be made now of ${formatAmount(
        oneMonthAmount,
      )} and rest ${formatAmount(
        totalRepeatDonationAmount,
      )} in coming ${totalNumberofRepeatMonths} months. Please confirm by clicking below.`
    }
    if (isSplit) {
      const totalSplitDonationAmount = totalDonation
      const totalNumberofRepeatMonths = splitMonths || 0
      const oneMonthAmount = totalDonation / totalNumberofRepeatMonths
      return `You have planning donation that will split ${formatAmount(
        totalSplitDonationAmount,
      )} in next ${totalNumberofRepeatMonths} months with a monthly payment of ${formatAmount(
        oneMonthAmount,
      )}. Please confirm by clicking below.`
    }
    return `You are donating ${formatAmount(
      totalDonation,
    )}. Please confirm by clicking below.`
  }

  const onDeleteCard = async (cardId) => {
    try {
      setLoading(true)
      await deleteUserCard(user, cardId)
      message.success('Bank Deleted Successfully')
      getCustomerBanks()
      form.setFieldsValue({ bank: 'no-bank-selected' })
      setLoading(false)
    } catch (error) {
      message.error(
        error.resopnse.data.message ||
          'Error occured while deleting the bank account',
      )
      setLoading(false)
    }
  }

  return (
    <div>
      {subscriberPaymentInfo?.stripe?.isConnected ? (
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="event"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row className="t-p-3">
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="askedAmount"
                label="Asked Amount"
                colon={false}
                rules={[bankDebitValidation]}
              >
                <AmountInput
                  placeholder="Enter amount here"
                  value={form.getFieldValue('askedAmount')}
                  onChange={(value) =>
                    form.setFieldsValue({ askedAmount: value })
                  }
                  disabled={!event?.EditAskedAmount}
                />
              </Form.Item>
            </Col>
            {banks.length > 0 ? (
              <>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <p className="t-mb-4">
                    You have connected following bank accounts to your account.
                    Your bank account will be charged with amount mentioned
                    below.
                  </p>
                  <p className="t-mb-4 t-font-bold t-text-red-600">
                    Bank debit payments can take up to 7 business days.
                  </p>
                </Col>
                {banks.length > 0 && (
                  <Col xl={24} md={24} xs={24} sm={24}>
                    <Form.Item
                      name="bank"
                      label="Select Bank"
                      colon={false}
                      rules={[bankDebitValidation]}
                    >
                      <Select
                        placeholder="Select Bank"
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) => trigger.parentElement}
                      >
                        <Select.Option value={'no-bank-selected'}>
                          {'No Bank Account Selected'}
                        </Select.Option>
                        {banks.map((item, index) => (
                          <Select.Option
                            value={item.id}
                            key={index}
                            className="t-capitalize"
                          >
                            {`${item.bank_name} - **** **** **** ${item.last4}`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {banks.length > 0 && (
                  <Col span={24}>
                    <Row className="t-flex t-justify-end ">
                      <h1
                        onClick={() => setManageCardModalVisible(true)}
                        className="t-text-secondary-100 t-underline t-cursor-pointer"
                      >
                        Manage Banks
                      </h1>
                    </Row>
                  </Col>
                )}
                {isNoBankSelected && (
                  <Col xl={24} md={24} xs={24} sm={24} className="t-mb-6">
                    <Button
                      loading={loading}
                      type="primary"
                      className="px-25"
                      onClick={open}
                    >
                      Link Bank Account
                    </Button>
                  </Col>
                )}
                {event?.splitPaymentAllowed && (
                  <>
                    <Col
                      xl={isSplit ? 12 : 24}
                      md={isSplit ? 12 : 24}
                      xs={24}
                      sm={24}
                    >
                      <Form.Item
                        name="splitDonation"
                        label="Split Payment"
                        colon={false}
                        rules={[bankDebitValidation]}
                      >
                        <Switch disabled={isRepeat} />
                      </Form.Item>
                    </Col>
                    {isSplit && (
                      <>
                        <Col
                          xl={isSplit ? 12 : 24}
                          md={isSplit ? 12 : 24}
                          xs={24}
                          sm={24}
                        >
                          <Form.Item
                            name="splitnoOfMonths"
                            label="Number of Months"
                            colon={false}
                            rules={[bankDebitValidation]}
                          >
                            <Input
                              placeholder="Number of Months"
                              disabled={isRepeat}
                              inputMode="numeric"
                              onChange={(e) =>
                                form.setFieldsValue({
                                  splitnoOfMonths: e.target.value.replace(
                                    /\D/g,
                                    '',
                                  ),
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Paragraph className="t-font-bold t-text-xs t-text-yellow-600 t-text-right">
                            Event creator allowed only{' '}
                            {event?.splitPaymentMonths} months
                          </Paragraph>
                        </Col>
                      </>
                    )}
                  </>
                )}
                {event?.monthlyPaymentAllowed && (
                  <>
                    <Col
                      xl={isRepeat ? 12 : 24}
                      md={isRepeat ? 12 : 24}
                      xs={24}
                      sm={24}
                    >
                      <Form.Item
                        name="repeatMonths"
                        label="Months To Repeat"
                        colon={false}
                        rules={[bankDebitValidation]}
                      >
                        <Switch disabled={isSplit} />
                      </Form.Item>
                    </Col>
                    {isRepeat && (
                      <>
                        <Col
                          xl={isRepeat ? 12 : 24}
                          md={isRepeat ? 12 : 24}
                          xs={24}
                          sm={24}
                        >
                          <Form.Item
                            name="repeatnoOfMonths"
                            label="Number of Months"
                            colon={false}
                            rules={[bankDebitValidation]}
                          >
                            <Input
                              placeholder="Number of Months"
                              disabled={isSplit}
                              inputMode="numeric"
                              onChange={(e) =>
                                form.setFieldsValue({
                                  repeatnoOfMonths: e.target.value.replace(
                                    /\D/g,
                                    '',
                                  ),
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Paragraph className="t-font-bold t-text-xs t-text-yellow-600 t-text-right">
                            Event creator allowed only{' '}
                            {event?.monthlyPaymentMonths} months
                          </Paragraph>
                        </Col>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <Paragraph className="t-text-justify">
                    We use Plaid’s authentication service to ensure privacy of
                    your banking information.
                  </Paragraph>
                </Col>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <Paragraph className="t-text-justify">
                    You will now be forwarded to Plaid to authenticate with your
                    bank and process your payment.
                  </Paragraph>
                </Col>
                <Col xl={24} md={24} xs={24} sm={24} className="t-mb-6">
                  <Button
                    loading={loading}
                    type="primary"
                    className="px-25"
                    onClick={open}
                  >
                    Link Bank Account
                  </Button>
                </Col>
              </>
            )}
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="includeEventCosts"
                label={
                  event.EventType === 'Fundraiser'
                    ? 'Include Event Fees'
                    : 'Include Campaign Fees'
                }
                colon={false}
                rules={[bankDebitValidation]}
              >
                <Switch defaultChecked={true} />
              </Form.Item>
            </Col>
            {/* <Col xl={24} md={24} xs={24} sm={24}>
              <Row justify="end">
                <Title level={3}>
                  {`Total Donation = ${formatAmount(totalDonation)}`}
                </Title>
              </Row>
            </Col> */}
            <Col xl={24} md={24} xs={24} sm={24}>
              <Paragraph className="t-text-justify">
                {getDonateText()}
              </Paragraph>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="agreeToTerms"
                colon={false}
                rules={[bankDebitValidation]}
              >
                <Checkbox
                  onChange={(e) =>
                    form.setFieldsValue({
                      agreeToTerms: e.target.checked,
                    })
                  }
                >
                  <TermAndConditionModal />
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24} className="text-right">
              <Button
                loading={loading}
                disabled={banks.length === 0 || isAgreed}
                type="primary"
                className="t-w-full md:t-w-auto"
                htmlType="submit"
              >
                Donate via bank account
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <div className="t-h-72 t-flex t-items-center t-justify-center t-flex-col">
          <RiErrorWarningFill
            fontSize="2.5rem"
            className="t-text-secondary-100"
          />
          <Title level={5}>
            Bank account payments are not configured for this event
          </Title>
          <Paragraph>You can use other payment methods</Paragraph>
        </div>
      )}
      <Modal
        centered
        title="Manage Banks"
        open={manageCardModalVisible}
        onCancel={() => setManageCardModalVisible(false)}
        footer={null}
      >
        <Col span={24} className="t-p-4">
          <div className="t-flex t-justify-between">
            <h1>Following are the banks attached to your account</h1>
            <h1>Total Banks : {banks.length}</h1>
          </div>
          <div>
            {banks.map((item, index) => (
              <div
                key={index}
                className="t-flex t-justify-between t-capitalize t-border-2 t-rounded-2xl t-shadow-lg t-p-4 t-my-4"
              >
                <h1>{`${item.bank_name} - **** **** **** ${item.last4}`}</h1>
                <Popconfirm
                  title="Are you sure you want to delete this card？"
                  placement="top"
                  loading={loading}
                  onConfirm={() => onDeleteCard(item?.id)}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <AiFillDelete
                    fontSize={'1.2rem'}
                    className="t-text-red-500 t-cursor-pointer"
                  />
                </Popconfirm>
              </div>
            ))}
          </div>
        </Col>
      </Modal>
    </div>
  )
}

export { BankDebit }

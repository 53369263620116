import React from 'react'
import { Button, Card, Col, Typography } from 'antd'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { PaymentCategories } from './payment-categories'

const { Title } = Typography
const SetupPayments = () => {
  const history = useHistory()
  return (
    <Card
      className="header-solid"
      bordered={false}
      title={
        <div className="t-flex t-flex-wrap">
          <Col>
            <Title level={4}>Manage Payments</Title>
            <p>Connect your payment accounts.</p>
          </Col>
          <Button
            type="primary"
            // className="t-px-4"
            onClick={() => history.push('/settings/add-payment-category')}
          >
            Create New
          </Button>
        </div>
      }
    >
      <PaymentCategories />
    </Card>
  )
}

export { SetupPayments }

import React, { useEffect, useState } from 'react'
import { Modal, Skeleton } from 'antd'
import { MdPreview } from 'react-icons/md'
import { formatAmount } from 'utils'
import Api from 'api'
import { ShareReport } from 'components/elements'

function YearOnYearSummary() {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()

  useEffect(() => {
    setLoading(true)
    Api.get('adminReports/yearOnYearSummary')
      .then((res) => {
        setReport(res.results)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div>
        <MdPreview
          id="year-on-year"
          className="t-text-secondary-100"
          fontSize="2rem"
          onClick={() => setVisible(true)}
        />
      </div>
      <Modal
        centered
        keyboard
        title="Year-On-Year Summary"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[]}
      >
        <Skeleton loading={loading} paragraph={{ rows: 12 }}>
          {report ? (
            <div className="t-w-full t-h-96">
              <div className="t-mb-8">
                <ShareReport
                  reportName="Year On Year Summary"
                  report={report}
                />
              </div>
              {report.map((item, index) => (
                <div
                  key={index}
                  className="t-w-full t-rounded-2xl t-h-12 t-p-3 t-bg-yellow-100 t-text-secondary-100 t-mb-2 t-flex t-items-center t-shadow-md t-justify-between"
                >
                  <div className="t-flex t-space-x-2 t-items-center">
                    {item?.year}
                  </div>
                  <div className="t-flex t-flex-col">
                    <h2 className="t-font-bold t-text-secondary-100 t-text-right t-text-xs md:t-text-sm">
                      {`${formatAmount(item?.amount)}`}
                    </h2>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>No Report Found</div>
          )}
        </Skeleton>
      </Modal>
    </>
  )
}

export { YearOnYearSummary }

import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Descriptions,
  Button,
  Tooltip,
  Tag,
  Skeleton,
  Typography,
  Modal,
} from 'antd'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { MdEdit, MdLocationOn } from 'react-icons/md'
import {
  AiFillEye,
  AiFillEyeInvisible,
  // AiOutlineHeart,
  // AiFillHeart,
} from 'react-icons/ai'
import { HiDuplicate } from 'react-icons/hi'
import { DiRequirejs } from 'react-icons/di'
import { VscTypeHierarchySub } from 'react-icons/vsc'
import { FaMoneyBillWave } from 'react-icons/fa'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useDispatch } from 'react-redux'
// import {
//   addFavourite,
//   removeFavourite,
// } from 'store/favouriteSlice/favouriteActions'
import { CopyBlock, dracula } from 'react-code-blocks'
import { useProgramSelector } from 'store/programSlice/programReducer'
import sample from 'assets/images/sample/sample1.png'
import {
  getSubscriberPaymentInformation,
  isUserAlreadyRegisteredInProgram,
  updateProgramStatus,
  getProgram,
  getSubscriberMarkupInfo,
} from 'store/programSlice/programActions'
import { formatAmount, htmlCode, isMobile, mobileShareNavigator } from 'utils'
import { ShareModal, SVGIcon } from 'components'
import { awsconfig } from 'configs'
import { useFavouriteSelector } from 'store/favouriteSlice/favouriteReducer'
import { RegisteredUsers } from './RegisteredUsers'

const { Title } = Typography

const ProgramView = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { state } = useLocation()

  const [isLoading, setLoading] = useState(false)
  const [isUserAlreadyRegistered, setAlreadyRegistered] = useState(false)
  const [isShare, setShare] = useState(false)
  const [checkLoading, setCheckLoading] = useState(true)
  const [embedURLModalVisible, setEmbedURLModalVisible] = useState(false)

  const { loading, program } = useProgramSelector()
  const { user, organization } = useAuthSelector()
  const { loading: favLoading } = useFavouriteSelector()

  const changeModal = () => setEmbedURLModalVisible(!embedURLModalVisible)

  const isClassProgram = program?.ProgramType === 'Class'

  const checkRegisteredUserInProgram = async () => {
    setLoading(true)
    try {
      const isExist = await isUserAlreadyRegisteredInProgram(
        program?.ProgramId,
        user?.email,
      )
      if (isExist) {
        setAlreadyRegistered(true)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const getProgramDetails = () => {
    dispatch(getProgram(params?.id, user?.id))
  }

  useEffect(() => {
    if (program?.organization?.id) {
      dispatch(getSubscriberPaymentInformation(program?.organization?.id))
      dispatch(getSubscriberMarkupInfo(program?.organization?.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program?.organization?.id])

  useEffect(() => {
    if (user?.email) {
      getProgramDetails()
      checkRegisteredUserInProgram()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id, user?.email])

  useEffect(() => {
    if (program?.ProgramId && user?.email) {
      checkRegisteredUserInService()
    }
    // eslint-disable-next-line
  }, [program?.ProgramId, user?.email])

  // const handleAddFavourite = async (id) => {
  //   const payload = {
  //     ReferenceId: id,
  //     Username: user?.id,
  //     Type: 'program',
  //   }
  //   dispatch(addFavourite(payload, getProgramDetails))
  // }

  // const handleRemoveFavourite = async (id) => {
  //   dispatch(removeFavourite(id, getProgramDetails))
  // }

  const handleDuplicate = () => {
    history.push(
      `/settings/create-program?id=${program?.ProgramId}&duplicate=true`,
    )
  }

  const actions = () => {
    history.push('/programs')
  }
  const handleUpdate = () => {
    dispatch(
      updateProgramStatus(program?.ProgramId, program?.ActiveInd, actions),
    )
  }

  const isProgramCreator = program?.OrganizationId === organization?.id

  const shareProgram = async () => {
    if (isMobile) {
      await mobileShareNavigator(
        program?.Title,
        `${window.location.origin}/registration/program/${program?.ProgramId}`,
      )
    } else {
      setShare(true)
    }
  }

  const programLoading = loading || favLoading || isLoading

  const checkRegisteredUserInService = async () => {
    setCheckLoading(true)
    try {
      const isExist = await isUserAlreadyRegisteredInProgram(
        program?.ProgramId,
        user.email,
      )
      if (isExist) {
        setAlreadyRegistered(true)
      }
      setCheckLoading(false)
    } catch (error) {
      setCheckLoading(false)
    }
  }

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            title={[
              <div className="t-flex t-justify-between">
                <div className="t-flex t-items-center t-space-x-4">
                  <Button
                    type="primary"
                    className="t-rounded-full t-flex t-items-center"
                    onClick={() =>
                      state && state.urlData
                        ? history.push({
                            pathname: state.urlData.pathname,
                            state: { key: state.urlData.activeKey },
                          })
                        : history.goBack()
                    }
                    size="small"
                    loading={isLoading}
                  >
                    <IoIosArrowRoundBack />
                    Back
                  </Button>
                </div>
                <div className="t-flex t-items-center t-space-x-2 t-cursor-pointer">
                  {/* <div
                    className={`${
                      programLoading && 't-pointer-events-none t-opacity-50'
                    } ${!program && 't-pointer-events-none t-opacity-0'}`}
                  >
                    {program?.isFav ? (
                      <Tooltip title="Remove Favorite">
                        <AiFillHeart
                          fontSize="1.8rem"
                          id="make-unfav"
                          className="t-cursor-pointer t-text-red-500"
                          onClick={() => handleRemoveFavourite(program?.favId)}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Make Favorite">
                        <AiOutlineHeart
                          fontSize="1.8rem"
                          id="make-fav"
                          className="t-cursor-pointer t-text-secondary-100"
                          onClick={() => handleAddFavourite(program?.ProgramId)}
                        />
                      </Tooltip>
                    )}
                  </div> */}
                  {program?.OrganizationId === organization?.id && (
                    <>
                      <Tooltip title="Edit Program">
                        <MdEdit
                          fontSize="1.8rem"
                          id="edit"
                          className="t-text-secondary-100"
                          onClick={() =>
                            history.push(
                              `/settings/create-program/${program?.ProgramId}`,
                            )
                          }
                        />
                      </Tooltip>
                      <Tooltip title="Duplicate Program">
                        <HiDuplicate
                          id="duplicate"
                          fontSize="1.8rem"
                          className="t-text-secondary-100"
                          onClick={() => handleDuplicate()}
                        />
                      </Tooltip>
                      {program?.ActiveInd === 1 ? (
                        <Tooltip title="Make Inactive">
                          <AiFillEyeInvisible
                            id="make-inactive"
                            fontSize="1.8rem"
                            className="t-text-secondary-100"
                            onClick={() => handleUpdate()}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Make Active">
                          <AiFillEye
                            id="make-active"
                            fontSize="1.8rem"
                            className="t-text-secondary-100"
                            onClick={() => handleUpdate()}
                          />
                        </Tooltip>
                      )}
                    </>
                  )}
                </div>
              </div>,
            ]}
            bordered={false}
            className="header-solid card-product mb-24"
          >
            <Skeleton
              loading={programLoading || false}
              active
              paragraph={{ rows: 18 }}
            >
              {program ? (
                <>
                  <Row gutter={[24, 0]}>
                    <Col
                      span={24}
                      lg={12}
                      xl={10}
                      className="col-gallery mb-24"
                    >
                      <img
                        src={
                          program?.Flyer?.fileName
                            ? awsconfig.bucketUrl + program?.Flyer?.fileName
                            : program?.organization?.BackgroundImage
                            ? awsconfig.bucketUrl +
                              program?.organization?.BackgroundImage?.fileName
                            : sample
                        }
                        alt="card-2"
                        className="gallery-img shadow-3 img-contain"
                      />
                    </Col>
                    <Col span={24} xl={12} lg={{ offset: 1 }}>
                      <div className="t-mb-4">
                        <h1 className="t-text-secondary-100 t-text-2xl md:t-text-4xl t-font-bold">
                          {program?.Title}
                        </h1>
                        <h1 className="t-text-secondary-100 t-text-lg md:t-text-2xl">
                          {program?.organization?.Name}
                        </h1>
                        <Descriptions colon={false} className="t-my-4">
                          <Descriptions.Item
                            label={
                              <Tooltip title="Delivery Type">
                                <VscTypeHierarchySub fontSize="1.5rem" />
                              </Tooltip>
                            }
                            span={3}
                          >
                            {program?.DeliveryLocation}
                          </Descriptions.Item>
                          {program.DeliveryLocation &&
                            program?.DeliveryLocation !== 'Online' && (
                              <Descriptions.Item
                                label={
                                  <Tooltip title="Address">
                                    <MdLocationOn fontSize="1.5rem" />
                                  </Tooltip>
                                }
                                span={3}
                              >
                                {program?.DeliveryLocation === 'Hybrid'
                                  ? program?.Address1
                                    ? program?.Address1
                                    : null
                                  : program?.organization?.Address1
                                  ? program?.organization?.Address1
                                  : null}
                              </Descriptions.Item>
                            )}
                          <Descriptions.Item
                            label={
                              <Tooltip title="Program Fees">
                                <FaMoneyBillWave fontSize="1.5rem" />
                              </Tooltip>
                            }
                            span={3}
                          >
                            {formatAmount(program?.ProgramFees)}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <Tooltip title="Requirements">
                                <DiRequirejs fontSize="1.5rem" />
                              </Tooltip>
                            }
                            span={3}
                          >
                            {program?.Requirements}
                          </Descriptions.Item>
                        </Descriptions>

                        <Typography.Title
                          level={4}
                          className="t-text-secondary-100"
                        >
                          Description
                        </Typography.Title>
                        <Typography.Paragraph className="t-text-justify">
                          {program?.Description}
                        </Typography.Paragraph>
                        {program?.ProgramType === 'Class' && (
                          <div className="t-my-2">
                            <Typography.Title
                              level={4}
                              className="t-text-secondary-100"
                            >
                              Schedule
                            </Typography.Title>
                            {[
                              'Monday',
                              'Tuesday',
                              'Wednesday',
                              'Thursday',
                              'Friday',
                              'Saturday',
                              'Sunday',
                            ].map((item) => (
                              <div className="t-flex t-space-x-2 t-my-2">
                                <div className="t-w-20 t-text-secondary-100 t-font-bold">
                                  {item}
                                </div>
                                {program.Schedule[item.toLocaleLowerCase()]
                                  .open && (
                                  <>
                                    {program.Schedule[item.toLocaleLowerCase()]
                                      ?.nonstop ? (
                                      <div className="t-mx-2 t-text-secondary-100 t-text-sm">
                                        Class is scheduled for whole day
                                      </div>
                                    ) : (
                                      <>
                                        {program.Schedule[
                                          item.toLocaleLowerCase()
                                        ]?.durations.map((element) => (
                                          <Tag color="green">{`${element?.from} - ${element?.to}`}</Tag>
                                        ))}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            ))}
                          </div>
                        )}

                        <div>
                          {checkLoading && (
                            <div className="t-mb-2">
                              <Button
                                type="primary"
                                loading={checkLoading}
                                style={{
                                  background: 'grey',
                                  borderColor: 'grey',
                                }}
                              >
                                Loading ...
                              </Button>
                            </div>
                          )}
                          {!checkLoading && isUserAlreadyRegistered && (
                            <div className="t-mb-2">
                              <Button
                                type="primary"
                                style={{
                                  background: 'grey',
                                  borderColor: 'grey',
                                }}
                              >
                                Registered
                              </Button>
                            </div>
                          )}
                          {!checkLoading && !isUserAlreadyRegistered && (
                            <>
                              {program.registeredUser <
                              program.MaximumNumberOfRegistrations ? (
                                <div className="t-my-4 t-space-x-2">
                                  <Button
                                    type="primary"
                                    loading={loading}
                                    onClick={() =>
                                      history.push(
                                        `/registration/program/${program?.ProgramId}`,
                                      )
                                    }
                                  >
                                    Register
                                  </Button>
                                </div>
                              ) : (
                                <div className="t-mb-2">
                                  <Button
                                    type="primary"
                                    loading={loading}
                                    style={{
                                      background: 'grey',
                                      borderColor: 'grey',
                                    }}
                                  >
                                    {isClassProgram
                                      ? 'Class Full'
                                      : 'Max Limit Reached'}
                                  </Button>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        <div className="t-flex t-flex-col">
                          <Button
                            type="primary"
                            onClick={shareProgram}
                            className="t-w-[10%] t-mb-2"
                          >
                            Share
                          </Button>

                          <Button
                            type="primary"
                            onClick={changeModal}
                            className="t-w-[20%] t-my-2"
                          >
                            Embed Code
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {isProgramCreator && (
                    <Row gutter={[24, 0]} className="mt-24">
                      <Col span={24}>
                        <h6 className="t-font-bold t-text-base">
                          Registered User Here
                        </h6>
                        <RegisteredUsers />
                      </Col>
                    </Row>
                  )}
                </>
              ) : (
                <div className="ant-result ant-result-404">
                  <SVGIcon />
                  <div className="ant-result-title">Invalid URL</div>
                  <div className="ant-result-subtitle">
                    Sorry, the page you visited does not exist.
                  </div>
                  <div className="ant-result-extra">
                    <Button
                      onClick={() => history.push('/programs')}
                      type="primary"
                      size="small"
                    >
                      Goto Programs
                    </Button>
                  </div>
                </div>
              )}
            </Skeleton>
          </Card>
        </Col>
        <ShareModal
          url={`${window.location.origin}/registration/program/${program?.ProgramId}`}
          visible={isShare}
          onClose={() => setShare(false)}
        />
      </Row>

      <Modal
        centered
        title="Embed URL"
        width={600}
        open={embedURLModalVisible}
        onCancel={changeModal}
        footer={null}
      >
        <div>
          <div className="t-my-2">
            <Title level={4}>Copy iframe code</Title>
            <CopyBlock
              language="html"
              text={`
              <iframe
              style="width: 100%; height: 100%"
              src=${window.location.origin}/registration/program/${program?.ProgramId}
              title="Register through Extensia"
              ></iframe>`}
              theme={dracula}
              wrapLines
              codeBlock
            />
          </div>
          <div className="t-my-2">
            <Title level={4}>Copy code to paste in the website</Title>
            <CopyBlock
              language="html"
              text={htmlCode(
                `${window.location.origin}/registration/program/${program?.ProgramId}`,
                'Register',
              )}
              theme={dracula}
              wrapLines
              codeBlock
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export { ProgramView }

import React from 'react'
import { MinusCircleFilled, PlusCircleOutlined } from '@ant-design/icons'
import { Card } from 'antd'

const styles = {
  fontSize: '24px',
  cursor: 'pointer',
}

const CollapsiblePanel = ({
  isOpen,
  setToggle,
  title,
  children,
  style = {},
  hasChild = true,
  hideToggle = false,
}) => (
  <Card
    style={{ ...style }}
    title={title}
    extra={
      hideToggle ? null : !isOpen ? (
        <PlusCircleOutlined onClick={setToggle} style={styles} />
      ) : (
        <MinusCircleFilled onClick={setToggle} style={styles} />
      )
    }
    headStyle={{
      borderBottom: hasChild
        ? `1px solid ${isOpen ? '#efefef' : 'white'}`
        : 'none',
    }}
  >
    <span style={{ display: isOpen ? 'block' : 'none' }}>{children}</span>
  </Card>
)

export default CollapsiblePanel

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Card, Skeleton } from 'antd'
import { CarryOutFilled } from '@ant-design/icons'
import { config } from 'configs'
import { InfiniteScrollContainer, ShareReport } from 'components/elements'
import Api from 'api'
import moment from 'moment'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { useHistory } from 'react-router-dom'

const ActiveDonorsReport = () => {
  const history = useHistory()
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()
  const [current, setCurrent] = useState([])

  useEffect(() => {
    setLoading(true)
    Api.get('adminReports/activeDonorList')
      .then((res) => {
        const reponseResult = res?.results?.sort((a, b) => {
          const dateA = new Date(a?.PaymentDate)
          const dateB = new Date(b?.PaymentDate)
          return dateB - dateA
        })
        setReport(reponseResult)
        setCurrent(reponseResult.slice(0, config.rowsPerPage))
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  const formatCustomDate = (date) => {
    const givenDate = new Date(date)
    const givenDateFormatted = moment(givenDate).format('MM-DD-YYYY')
    const todayFormatted = moment().format('MM-DD-YYYY')
    const yesterdayFormatted = moment().subtract(1, 'day').format('MM-DD-YYYY')
    if (givenDateFormatted === todayFormatted) return 'Today'
    if (givenDateFormatted === yesterdayFormatted) return 'Yesterday'
    return givenDateFormatted
  }

  return (
    <Card
      id="ActiveDonorsReport"
      className="mb-24 header-solid list-settings-sessions"
      bordered={false}
      title={[
        <div className="t-flex t-justify-between t-items-center">
          <div className="t-flex t-space-x-4">
            <IoMdArrowRoundBack
              fontSize="1.5rem"
              className="t-cursor-pointer t-min-w-[1.5rem]"
              id="back"
              onClick={() =>
                history.push('/settings/manage-organization/my-reports?key=2')
              }
            />
            <h5 className="font-semibold">Active Donors Report</h5>
          </div>
          {!loading && report && report.length > 0 && (
            <ShareReport
              reportName="Active Donor List Report"
              report={report}
            />
          )}
        </div>,
      ]}
    >
      <Skeleton loading={loading} paragraph={{ rows: 12 }}>
        {report && report.length > 0 ? (
          <InfiniteScrollContainer
            data={report}
            current={current}
            setCurrent={setCurrent}
          >
            <>
              {current.map((item, index) => (
                <>
                  {index !== 0 && <hr className="gradient-line my-10" />}
                  <div className="ant-row-flex ant-row-flex-middle">
                    <div
                      className="text-center ant-col"
                      style={{ minWidth: '40px' }}
                    >
                      <CarryOutFilled
                        className="text-gray-6"
                        style={{ fontSize: '18px' }}
                      />
                    </div>
                    <div className="ant-col pl-15">
                      <p className="mb-0">{item?.DonorName}</p>
                    </div>
                    <div
                      className="ml-auto ant-col ant-col-24 ant-col-md-12"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <span className="ml-5">
                        {/* {moment(moment.utc(item?.PaymentDate)).local().fromNow()} */}
                        {item?.PaymentDate &&
                          formatCustomDate(item?.PaymentDate)}
                      </span>
                    </div>
                  </div>
                </>
              ))}
            </>
          </InfiniteScrollContainer>
        ) : (
          <div>No Data Found</div>
        )}
      </Skeleton>
    </Card>
  )
}

export { ActiveDonorsReport }

import React from 'react'
import { useAuthSelector } from 'store/authSlice/authReducer'

const Downloadable = () => {
  const { downloadable } = useAuthSelector((state) => state.auth.downloadable)

  return (
    <>
      {/* eslint-disable-next-line */}
      <div dangerouslySetInnerHTML={{ __html: downloadable }} />
    </>
  )
}

export { Downloadable }

import * as Yup from 'yup'
import { REGEXS } from 'utils'

const zipCodeRegex = REGEXS.zipcodeRegex
const websiteRegex = new RegExp(
  '^(https?:\\/\\/)?' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$',
  'i',
)
const phoneNumberValidation = '^[0-9-() ]{14,15}$'

const schema = Yup.object().shape({
  Name: Yup.string().required('Name is required'),
  Description: Yup.string()
    .required('Description is required')
    .min(10, 'Description should be atleast 10 character'),
  Country: Yup.string().required('Country is required'),
  PhoneNumber: Yup.string()
    .matches(phoneNumberValidation, 'Invalid Phone number e.g 1234567891')
    .required('Phone number is required'),
  Address1: Yup.string(),
  Address2: Yup.string().required('Number and street is required'),
  City: Yup.string().required('City is required'),
  State: Yup.string().required('State is required'),
  ZipCode: Yup.string()
    .matches(zipCodeRegex, 'Invalid Zip code e.g 12345')
    .required('Zip code is required'),
  Website: Yup.string().matches(websiteRegex, {
    message: 'Invalid website url e.g https://www.google.com',
    excludeEmptyString: true,
  }),
  BackgroundImage: Yup.mixed()
    .typeError('Background Image is not a file')
    .required('Background Image is required'),
  ProfilePicture: Yup.mixed()
    .typeError('Profile picture is not a file')
    .required('Profile picture is required'),
  SearchKeywords: Yup.array().min(1, 'Search keywords is required'),
  ChargableServices: Yup.boolean().required('Chargable services is required'),
  PrimaryBusinessCategory: Yup.string().required(
    'Primary business category is required',
  ),
  DeliveryLocation: Yup.array().min(1, 'Delivery location is required'),
  ServiceCoverage: Yup.string().required('Service coverage is required'),
  // PrimaryAssociation: Yup.string().required('Primary association is required'),
  SecondaryAssociation: Yup.array().min(1, 'Business association is required'),
  Note: Yup.string()
    .required('Note is required')
    .min(10, 'Note should be atleast 10 character'),
})

const businessValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { businessValidation }

import React, { useState } from 'react'
import { Button, Card, Col, Row, Avatar } from 'antd'
import { ContactsFilled } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { requestBusinessService } from 'store/businessSlice/businessActions'
import { config } from 'configs'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { BusinessServiceView } from './BusinessServiceView'
import RequestServiceModal from './RequestServiceModal'

const MyBusinessServices = ({ business, unauthenticated }) => {
  const dispatch = useDispatch()
  const { user } = useAuthSelector()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [service, setService] = useState()
  const [requestLoading, setRequestLoading] = useState(false)
  const [addServiceModal, setAddServiceModal] = useState(false)

  const handleViewClick = (e, serv) => {
    e.preventDefault()
    e.stopPropagation()
    setService(serv)
    setIsModalVisible(true)
  }

  const action = () => {
    setIsModalVisible(false)
    setService(null)
  }
  const handleServiceRequest = () => {
    setAddServiceModal(true)
  }
  const sendServiceRequest = (data) => {
    const payload = {
      BusinessId: business.Id,
      BusinessEmail: business.UserEmail,
      ServiceId: service.Id,
      Title: service.Title,
      Description: service.Description,
      Deliverable: service.Deliverable,
      Details: data.Details,
    }
    if (isEventType) {
      payload.EventId = data.Event
    }
    if (unauthenticated) {
      payload.UserName = data.UserName
      payload.UserEmail = data.UserEmail
    } else {
      payload.UserName = `${user?.firstname} ${user?.lastname}`
      payload.UserEmail = user?.email
    }
    dispatch(requestBusinessService(payload, setRequestLoading, action))
  }

  const isEventType =
    business?.PrimaryBusinessCategoryLabel ===
    'Event Orchestration (emcee, keynote speaker, fundraising expert)'

  const filteredServices = business?.Services

  return (
    <Card className="header-solid" bordered={false}>
      <div style={{ minHeight: 'calc(100vh - 220px)' }}>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <div className="t-my-2 t-mx-2 t-flex t-justify-between t-items-center">
              <h1 className="t-text-secondary-100 t-font-bold t-text-2xl">
                {unauthenticated ? business.Name : 'Business Services'}
              </h1>
            </div>
            <div
              className={`t-flex t-flex-col t-justify-between ${
                unauthenticated ? 't-h-screen' : 't-h-full'
              }`}
              style={{ minHeight: 'calc(100vh - 250px)' }}
            >
              <Row gutter={[24, 24]}>
                {filteredServices?.length === 0 && (
                  <div
                    className="t-h-96 t-flex t-justify-center t-items-center t-w-full"
                    style={{ minHeight: 'calc(100vh - 300px)' }}
                  >
                    <div className="t-flex t-flex-col t-items-center t-space-y-2">
                      <ContactsFilled
                        style={{ fontSize: '2rem' }}
                        className="t-text-secondary-100"
                      />
                      <p>No Business Services</p>
                    </div>
                  </div>
                )}
                {filteredServices?.map((item, index) => (
                  <Col span={24} lg={8} className="mb-24" key={index}>
                    <Card
                      bodyStyle={{ paddingTop: '14px' }}
                      bordered={false}
                      className="card-project-2 header-solid t-cursor-pointer card-shadow"
                      title={[
                        <Row gutter={[24, 24]} key={0}>
                          <Col>
                            <Avatar
                              src={<ContactsFilled />}
                              style={{
                                width: '25px',
                                height: '25px',
                                lineHeight: '74px',
                                borderRadius: '8px',
                                padding: '16px',
                                backgroundColor: config.colors.primary,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            />
                          </Col>
                          <Col>
                            <h6 className="font-semibold t-w-64 t-truncate">
                              {item.Title ? item.Title : 'No Title Found'}
                            </h6>
                          </Col>
                        </Row>,
                      ]}
                      onClick={(e) => handleViewClick(e, item)}
                    >
                      <p className="t-truncate">
                        {item?.Description
                          ? item?.Description
                          : 'No Desription Found'}
                      </p>
                      <hr />
                      <div className="t-flex t-justify-end t-mt-2">
                        <Button
                          type="link"
                          size="small"
                          className="t-rounded-full"
                          onClick={(e) => handleViewClick(e, item)}
                        >
                          See More
                        </Button>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          <hr className="my-12" />
        </Row>
      </div>
      {isModalVisible && (
        <BusinessServiceView
          visible={isModalVisible}
          close={() => {
            setIsModalVisible(false)
            setService(null)
          }}
          item={service}
          loading={requestLoading}
          submit={handleServiceRequest}
        />
      )}
      {addServiceModal && (
        <RequestServiceModal
          visible={addServiceModal}
          close={() => setAddServiceModal(false)}
          service={service}
          isEventType={isEventType}
          submit={(values) => sendServiceRequest(values)}
          unauthenticated={unauthenticated}
        />
      )}
    </Card>
  )
}

export { MyBusinessServices }

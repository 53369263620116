import { makeRequest } from 'api'
import { message } from 'antd'
import { config } from 'configs'
import moment from 'moment'
import { hardwareActions } from './hardwareReducer'

export const getOrganizationSelectorOptions = () => (dispatch) => {
  dispatch(hardwareActions.setLoading({ fetchOrganizationOptions: true }))
  makeRequest('get', `/admins/get-organizations`)
    .then((res) => {
      const items = res.results
      if (items) {
        const formattedItems = items.map((item) => ({
          value: item.id,
          label: item.Name,
          status: item?.OrgStatus,
        }))
        dispatch(hardwareActions.setOrganizationOptions(formattedItems))
      } else {
        dispatch(hardwareActions.setOrganizationOptions([]))
      }
      dispatch(hardwareActions.setLoading({ fetchOrganizationOptions: false }))
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(hardwareActions.setOrganizationOptions([]))
      dispatch(hardwareActions.setLoading({ fetchOrganizationOptions: false }))
    })
}

export const addHardware = (payload, actions) => (dispatch) => {
  dispatch(hardwareActions.setLoading({ addOrUpdate: true }))
  makeRequest(
    'post',
    `/admins/hardware`,
    {
      organization: payload.OrganizationId,
    },
    payload,
  )
    .then((res) => {
      message.success(res.message || 'Hardware added successfully')
      dispatch(hardwareActions.setLoading({ addOrUpdate: false }))
      if (actions) {
        actions()
      }
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(hardwareActions.setLoading({ addOrUpdate: false }))
    })
}

export const updateHardware = (payload, actions) => (dispatch) => {
  dispatch(hardwareActions.setLoading({ addOrUpdate: true }))
  makeRequest(
    'put',
    `/admins/hardware/${payload?.OrganizationId}/${payload?.HardwareId}`,
    {
      organization: payload.OrganizationId,
    },
    payload,
  )
    .then((res) => {
      message.success(res.message || 'Hardware updated successfully')
      dispatch(hardwareActions.setLoading({ addOrUpdate: false }))
      if (actions) {
        actions()
      }
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(hardwareActions.setLoading({ addOrUpdate: false }))
    })
}

export const deleteHardware = (item, actions) => (dispatch) => {
  dispatch(hardwareActions.setHardware(item))
  dispatch(hardwareActions.setLoading({ deletingHardware: true }))
  makeRequest(
    'delete',
    `/admins/hardware/${item?.OrganizationId}/${item?.Id}`,
    {
      organization: item.OrganizationId,
    },
  )
    .then((res) => {
      message.success(res.message || 'Hardware deleted successfully')
      dispatch(hardwareActions.setLoading({ deletingHardware: false }))
      dispatch(hardwareActions.setHardware(null))
      if (actions) {
        actions()
      }
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(hardwareActions.setLoading({ deletingHardware: false }))
      dispatch(hardwareActions.setHardware(null))
    })
}

export const getHardware = (organizationId, hardwareId, form) => (dispatch) => {
  dispatch(hardwareActions.setLoading({ fetchHardware: true }))
  makeRequest('get', `/admins/hardware/${organizationId}/${hardwareId}`, {
    organization: organizationId,
  })
    .then((res) => {
      const item = res.results
      if (item) {
        if (form) {
          form.setFieldsValue({
            OrganizationId: item.OrganizationId,
            OrganizationName: item.OrganizationName,
            HardwareType: item.HardwareType,
            DeviceSerialNumber: item.DeviceSerialNumber,
            MDMDeviceName: item.MDMDeviceName,
            DeploymentDate: moment(item.DeploymentDate),
            CustomerOwned: item.CustomerOwned,
            Amount: item.Amount,
            CardReaderSerialNumber: item.CardReaderSerialNumber,
            CardReaderIdentifier: item.CardReaderIdentifier,
            CardReaderType: item.CardReaderType,
            BluetoothCode: item.BluetoothCode,
            Notes: item.Notes,
          })
        } else {
          dispatch(hardwareActions.setHardware(item))
        }
      } else {
        dispatch(hardwareActions.setHardware(null))
      }
      dispatch(hardwareActions.setLoading({ fetchHardware: false }))
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(hardwareActions.setHardware(null))
      dispatch(hardwareActions.setLoading({ fetchHardware: false }))
    })
}

export const getHardwareList =
  ({ onlyOrganization, organizationId } = {}) =>
  (dispatch) => {
    dispatch(hardwareActions.reset())
    dispatch(hardwareActions.setLoading({ fetchHardwareList: true }))
    const url = onlyOrganization
      ? `/component/organization/hardware`
      : `/admins/hardware`
    makeRequest('get', url, { organization: organizationId })
      .then((res) => {
        const items = res.results
        if (items) {
          dispatch(hardwareActions.setHardwareList(items))
        } else {
          dispatch(hardwareActions.setHardwareList([]))
        }
        dispatch(hardwareActions.setLoading({ fetchHardwareList: false }))
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
        dispatch(hardwareActions.setHardwareList([]))
        dispatch(hardwareActions.setLoading({ fetchHardwareList: false }))
      })
  }

import React from 'react'

const WifiIcon = [
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 22.5 20.625"
    key={0}
  >
    <g id="wifi" transform="translate(0.75 0.75)">
      <circle
        id="Oval"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(9 16.875)"
        fill="#fff"
      ></circle>
      <path
        id="Path"
        d="M0,1.36a6.377,6.377,0,0,1,7.5,0"
        transform="translate(6.75 11.86)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      ></path>
      <path
        id="Path-2"
        data-name="Path"
        d="M14.138,2.216A12.381,12.381,0,0,0,0,2.216"
        transform="translate(3.431 6)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      ></path>
      <path
        id="Path-3"
        data-name="Path"
        d="M0,3.294a18.384,18.384,0,0,1,21,0"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      ></path>
    </g>
  </svg>,
]

export default WifiIcon

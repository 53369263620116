import React, { useEffect, useState } from 'react'
import {
  Tooltip,
  Modal,
  Skeleton,
  Table,
  Popconfirm,
  Card,
  message,
  Button,
} from 'antd'
import { GiSellCard } from 'react-icons/gi'
import Api, { makeRequest } from 'api'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import {
  endEventAuction,
  getEventAuctionItems,
} from 'store/eventSlice/eventActions'
import { useDispatch } from 'react-redux'

const DecidingItems = ({ visible, onClose }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState()
  const [view, setView] = useState(false)
  const [decidingItems, setDecidingItems] = useState()

  const { event, eventAuctionItems } = useEventSelector()

  const setData = () => {
    setLoading(true)
    const decidingAuctionItem = []
    for (let i = 0; i < eventAuctionItems.length; i += 1) {
      const item = eventAuctionItems[i]
      if (
        item.bidHistory &&
        item.bidHistory.length > 0 &&
        Number(item.winningBid) > Number(item.currentBid)
      ) {
        decidingAuctionItem.push(item)
      }
    }

    if (
      event &&
      eventAuctionItems.length > 0 &&
      decidingAuctionItem.length === 0
    ) {
      if (event.AuctionEnded === undefined || event.AuctionEnded === false) {
        setLoading(false)
        setView(true)
      } else {
        setLoading(false)
      }
    } else {
      setDecidingItems(decidingAuctionItem)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (visible) {
      setData()
    }
    // eslint-disable-next-line
  }, [visible, eventAuctionItems])

  const ItemAuctionWinner = (bidHistory) => {
    const tempArr = []
    let winnerName
    let winnerEmail
    let winnerPhone
    let winnerBid

    if (bidHistory.length > 0) {
      for (let i = 0; i < bidHistory.length; i += 1) {
        const index = tempArr.findIndex(
          (ta) => bidHistory[i].email === ta.email,
        )
        if (index >= 0) {
          tempArr[index] = { ...bidHistory[i], status: 'lost' }
        } else {
          tempArr.push({ ...bidHistory[i], status: 'lost' })
        }
      }
      tempArr.sort((a, b) => Number(b.bid) - Number(a.bid))
      tempArr[0].status = 'won'

      winnerName = tempArr[0].name
      winnerEmail = tempArr[0].email
      winnerPhone = tempArr[0].winnerPhone
      winnerBid = tempArr[0].bid
    }
    return { histoy: tempArr, winnerName, winnerEmail, winnerPhone, winnerBid }
  }

  const onEndItemAuction = async (item) => {
    const sortedWinnerData = ItemAuctionWinner(item.bidHistory)
    const body = {
      type: 'event',
      eventId: event?.Id,
      organizationId: event?.OrganizationId,
      organizationName: event?.OrganizationName,
      organizationEmail: event?.CreatedBy,
      itemId: item.id,
      description: item.description,
      title: item.title,
      itemImage: item.itemImage,
      paymentStatus: 'Pending',
      deliveryStatus: false,
      bidHistory: sortedWinnerData?.histoy,
      winnerName: sortedWinnerData?.winnerName,
      winnerEmail: sortedWinnerData?.winnerEmail,
      winnerPhone: sortedWinnerData?.winnerPhone,
      winnerBid: sortedWinnerData?.winnerBid,
    }
    makeRequest(
      'post',
      'auction/sellAuctionItem',
      {
        organization: event?.OrganizationId,
      },
      { data: body },
    )
      .then(() => {
        message.success('Item Sold')
        dispatch(getEventAuctionItems(event.Id, setLoading))
      })
      .catch((error) => {
        console.log('error', error)
        message.error(error?.response?.data?.message || 'Something went wrong')
      })
  }

  const onEndItemAuctionWithoutSelling = async (item) => {
    const data = {
      eventId: event?.Id,
      itemId: item.id,
    }
    Api.post('events/endItemAuction', { data })
      .then(() => {
        message.success('Item Auction Ended')
        dispatch(getEventAuctionItems(event.Id, setLoading))
      })
      .catch((error) => {
        console.log('error', error)
        message.error(error?.response?.data?.message || 'Something went wrong')
      })
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Winning Bid',
      dataIndex: 'winningBid',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Current Bid',
      dataIndex: 'currentBid',
      className: 'ant-table-row-cell-break-word',
    },
    {
      // title: 'Live',
      dataIndex: 'id',
      className: 'ant-table-row-cell-break-word',
      render: (_, item) => (
        <Tooltip title="End Item Auction">
          <Popconfirm
            title="Current bid is less than winning bid, Do you want to sell this item or end auction without selling"
            onConfirm={() => onEndItemAuction(item)}
            onCancel={() => onEndItemAuctionWithoutSelling(item)}
            okText="Sell to Highest Bidder"
            cancelText="End Without Selling"
          >
            <GiSellCard
              fontSize="1.8rem"
              className="t-text-secondary-100 t-cursor-pointer"
            />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ]

  const onEndAuction = (type) => {
    setLoading(true)
    dispatch(
      endEventAuction(event.Id, event?.OrganizationId, type, () => {
        setLoading(false)
        onClose()
      }),
    )
  }

  return (
    <Modal
      centered
      title="End Auction"
      open={visible}
      onCancel={() => onClose()}
      footer={[]}
    >
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        {view ? (
          <>
            <p className="t-text-sm">Are you sure you want to end auction?</p>
            <div className="t-flex t-justify-end t-items-center t-space-x-2">
              <Button
                onClick={() =>
                  dispatch(
                    endEventAuction(
                      event.Id,
                      event?.OrganizationId,
                      'None',
                      () => {
                        setLoading(false)
                        onClose()
                      },
                    ),
                  )
                }
              >
                Yes
              </Button>
              <Button onClick={() => onClose()}>No</Button>
            </div>
          </>
        ) : (
          <>
            <Card className="header-solid" bordered={false}>
              <Table columns={columns} dataSource={decidingItems || []} />
              <p className="t-text-sm">
                Note: Decide what to do with these item before you end auction
              </p>
            </Card>
            <div className="t-flex t-justify-end t-items-center t-space-x-2">
              <Button onClick={() => onEndAuction('End')}>
                End Without Selling
              </Button>
              <Button onClick={() => onEndAuction('Sell')}>
                Sell To Highest Bidder
              </Button>
            </div>
          </>
        )}
      </Skeleton>
    </Modal>
  )
}

export { DecidingItems }

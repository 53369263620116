import * as Yup from 'yup'
const phoneNumberValidation = '^[0-9-() ]{14,15}$'

const schema = Yup.object().shape({
  organizationType: Yup.string().required('Organization Type is required'),
  streetAddress: Yup.string().required('Street Address is required'),
  phoneNumber: Yup.string()
    .matches(phoneNumberValidation, 'Invalid Phone number e.g 1234567891')
    .required('Phone number is required')
    .nullable(),
  website: Yup.string().url('Invalid URL format').notRequired(),
  orgDomain: Yup.string().required('OrgDomain is required'),
  description: Yup.string().required('Description is required'),
  internationallyFunded: Yup.string()
    .oneOf(['Yes', 'No'], 'Invalid value')
    .required('This field is required'),
  logo: Yup.mixed().required('Logo is required'),
  backgroundImage: Yup.mixed().required('Background Image is required'),
  W9Form: Yup.mixed()
    .required('W9-form is required')
    .test('fileType', 'Only PDF files are allowed', (value) => {
      if (!value) return true
      return value && ['application/pdf'].includes(value.type)
    }),
  photoID: Yup.mixed().required('Photo ID is required'),
})

const organizationRegistrationValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { organizationRegistrationValidation }

import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
} from 'antd'
import { businessServiceValidation } from 'validations'
import { getMyOrganizationEvents } from 'store/eventSlice/eventActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { config } from 'configs'
import * as Yup from 'yup'
// import { AutoCompleteAddress } from 'components/common'

const { TextArea } = Input

const stringRegex = /^[A-Za-z ]+$/
const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi

const schema = Yup.object().shape({
  UserName: Yup.string()
    .required('Required')
    .matches(stringRegex, 'Invalid Name'),
  UserEmail: Yup.string()
    .required('Required')
    .matches(emailRegex, 'Invalid Email'),
})

const requestValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  Event: '',
  Details: '',
  UserName: '',
  UserEmail: '',
}

const RequestServiceModal = ({
  visible,
  close,
  loading,
  // service,
  isEventType,
  submit,
  unauthenticated,
}) => {
  const { user, organization } = useAuthSelector()
  const isSubscriber = user?.roles.includes(config?.roles?.subscriber)

  const [form] = Form.useForm()

  const [events, setEvents] = useState([])
  const [eventsLoading, setEventsLoading] = useState(false)

  const getEvents = async (id) => {
    setEventsLoading(true)
    try {
      const response = await getMyOrganizationEvents(id)
      if (response && response.length) {
        setEvents(response)
      }
      setEventsLoading(false)
    } catch (error) {
      setEventsLoading(false)
    }
  }
  useEffect(() => {
    if (organization && organization.id) {
      getEvents(organization.id)
    }
    // eslint-disable-next-line
  }, [organization])

  const onFinish = (values) => {
    // const data = {
    //   ...values,
    //   CompleteAddress: values.mailingAddress,
    //   ZipCode: values.zipCode,
    //   City: values.city,
    //   State: values.state,
    //   Country: values.country,
    //   Address1: values.suite,
    // }
    form.resetFields()
    close()
    submit(values)
  }
  return (
    <Modal
      centered
      keyboard
      title="Book Service"
      open={visible}
      onCancel={() => {
        form.resetFields()
        close()
      }}
      footer={null}
      destroyOnClose
    >
      <Skeleton loading={eventsLoading} active paragraph={{ rows: 18 }}>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            key={0}
            onFinish={onFinish}
            name="add-items"
            initialValues={initialValues}
            scrollToFirstError
          >
            <Row gutter={[24, 0]}>
              {unauthenticated && (
                <>
                  <Col span={24}>
                    <Form.Item
                      name="UserName"
                      label="Name"
                      colon={false}
                      rules={[requestValidation]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="UserEmail"
                      label="Email"
                      colon={false}
                      rules={[requestValidation]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </>
              )}
              {isSubscriber && organization && isEventType && events && (
                <Col span={24}>
                  <Form.Item
                    name="Event"
                    label="Select Event"
                    colon={false}
                    // rules={[businessValidation]}
                    // getPopupContainer={(trigger) => trigger.parentElement}
                  >
                    <Select style={{ width: '100%' }}>
                      {events.map((item, index) => (
                        <Select.Option value={item.Id} key={index}>
                          {item.Title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {/* <AutoCompleteAddress form={form} validation />
              <Divider /> */}
              <Col span={24}>
                <Form.Item
                  name="Details"
                  label="Details"
                  colon={false}
                  rules={[businessServiceValidation]}
                >
                  <TextArea
                    placeholder="Details"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Form.Item>
              </Col>
              <Divider />
              <Col span={24}>
                <div className="t-w-full">
                  <Button
                    loading={loading}
                    className="t-float-right"
                    type="primary"
                    htmlType="submit"
                  >
                    Book Service
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Skeleton>
    </Modal>
  )
}

export default RequestServiceModal

import React from 'react'
import { Row, Col, Card, Table, Skeleton } from 'antd'
import { formatAmount } from 'utils'

const SmsAndEmailUsage = ({ usageCharges }) => {
  const columns = [
    {
      title: 'Usage Item',
      dataIndex: 'UsageItem',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Quota',
      dataIndex: 'Quota',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Current Usage',
      dataIndex: 'CurrentUsage',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Charges Due',
      dataIndex: 'ChargesDue',
      className: 'ant-table-row-cell-break-word',
      render: (_, { ChargesDue }) => formatAmount(ChargesDue),
    },
  ]
  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0">Usage Charges</h6>
        </>,
      ]}
    >
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Skeleton loading={false} active paragraph={{ rows: 12 }}>
            <Table
              tableLayout="auto"
              scroll={{ x: 500 }}
              columns={columns}
              dataSource={usageCharges}
              bordered={false}
              pagination={false}
            />
          </Skeleton>
        </Col>
      </Row>
    </Card>
  )
}

export { SmsAndEmailUsage }

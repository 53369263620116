import { Button } from 'antd'
import React, { useState } from 'react'
import { CategoryActiveSubscriptionModal } from './CategoryActiveSubscriptionModal'

const DisconnectButton = ({
  loading,
  handleDisconnent,
  organizationId,
  paymentCategoryId,
  categoryName,
  paymentType,
  accountId,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <div>
      <Button
        type="primary"
        size="small"
        className="payment-setup-button t-bg-green-500"
        loading={loading}
        onClick={() => setIsVisible(true)}
      >
        {!loading ? 'Disconnect' : 'Loading'}
      </Button>
      {isVisible && (
        <CategoryActiveSubscriptionModal
          isVisible={isVisible}
          onClose={() => setIsVisible(false)}
          organizationId={organizationId}
          paymentCategoryId={paymentCategoryId}
          categoryName={categoryName}
          paymentType={paymentType}
          accountId={accountId}
          handleDisconnent={handleDisconnent}
        />
      )}
    </div>
  )
}

export { DisconnectButton }

import * as Yup from 'yup'

const schema = Yup.object().shape({
  Name: Yup.string().required('Name is required'),
  DOB: Yup.string().typeError('Invalid Date').required('DOB is required'),
  SSN: Yup.string().required('SSN is required'),
  NextKin: Yup.string().required('Next of kin is required'),
  DateOfDeath: Yup.string()
    .typeError('Invalid Date')
    .required('Date of death is required'),
  GraveyardName: Yup.string().required('Graveyard name is required'),
  SpecialInstructions: Yup.string().required(
    'Special instructions is required',
  ),
  DateOfBurial: Yup.string()
    .typeError('Invalid Date')
    .required('Date of burial is required'),
  OtherInstructions: Yup.string().required('Other instructions is required'),
})

const funeralValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { funeralValidation }

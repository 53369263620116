import React from 'react'
import { Button, Form, Input } from 'antd'
import { signUpValidation } from 'validations'
// import ReCAPTCHA from 'react-google-recaptcha'
// import { awsconfig } from 'configs'

const RegisterForm = ({ loading, onSubmit, goTo }) => {
  const onFinish = (values) => {
    onSubmit(values)
  }

  // const onRecaptchaChange = () => {}

  return (
    <>
      <Form name="basic" onFinish={onFinish} className="row-col">
        <Form.Item name="firstName" rules={[signUpValidation]}>
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item name="lastName" rules={[signUpValidation]}>
          <Input placeholder="Last Name" />
        </Form.Item>
        <Form.Item name="email" rules={[signUpValidation]}>
          <Input inputmode="email" placeholder="Email" />
        </Form.Item>
        {/* <Form.Item name="recaptcha" rules={[signUpValidation]}>
          <ReCAPTCHA
            sitekey={awsconfig.reCaptchaKey}
            onChange={onRecaptchaChange}
          />
        </Form.Item> */}
        <Form.Item>
          <Button
            style={{ width: '100%' }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Confirm
          </Button>
        </Form.Item>
      </Form>
      <h6
        className="t-text-sm font-regular text-muted t-my-2 t-text-center"
        level={5}
      >
        Already have an account?{' '}
        <span
          className="font-bold t-text-secondary-100 hover:t-underline t-cursor-pointer"
          onClick={() => goTo('signin')}
        >
          Sign In
        </span>
      </h6>
    </>
  )
}

export { RegisterForm }

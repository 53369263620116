import React, { useEffect, useRef, useState } from 'react'
import { message } from 'antd'
import {
  addPledge,
  publicDonation,
  publicPaypalDonation,
  publicPaypalSeedDonation,
  publicSeedDonation,
} from 'store/authSlice/authActions'
import { AiFillDownCircle } from 'react-icons/ai'
import { config } from 'configs'
import { getDataFromDonationType, getIsEventPledgeConversion } from 'utils'
import {
  EventInformation,
  DonateForm,
  InvalidURL,
  Confirmation,
} from './components'
import { OrgInformation } from './components/OrgInformation'
import { SVG } from './components/SVG'

const AlreadyPaid = {
  'migrated-donation': {
    statusAttribute: 'RecurringStatus',
    statusValue: 'Completed',
    title: 'Already Setup',
    description: 'Donor has already setup recurring payment',
  },
  'pledge-conversion': {
    statusAttribute: 'PledgeStatus',
    statusValue: 'Completed',
    title: 'Already Converted',
    description: 'Donor has already converted pledge',
  },
}

// donationType: [event-donation, seed-donation, organization-donation, migrated-donation, event-pledge, organization-pledge, pledge-conversion]
const Donations = ({ donationType, item }) => {
  const [isDonating, setDonating] = useState(false)
  const [isDonated, setDonated] = useState(false)
  const [htmlContent, setHtmlContent] = useState()

  // Check pledge conversion type
  const isEventPledgeConversion =
    donationType === 'pledge-conversion' && getIsEventPledgeConversion(item)
  const isOrganizationPledgeConversion =
    donationType === 'pledge-conversion' &&
    item?.TransactionType === 'organization'

  const isOrganizationReletated =
    donationType === 'organization-donation' ||
    donationType === 'migrated-donation' ||
    donationType === 'organization-pledge' ||
    isOrganizationPledgeConversion

  const isOrganizationPayment =
    donationType === 'organization-donation' ||
    donationType === 'migrated-donation' ||
    isOrganizationPledgeConversion

  const isEventPayment =
    donationType === 'event-donation' ||
    donationType === 'seed-donation' ||
    isEventPledgeConversion

  const { organization, event } = getDataFromDonationType({
    item,
    donationType,
  })

  const organizationId = organization?.id

  const onDonateSubmit = async (payload) => {
    try {
      let response
      if (
        donationType === 'event-pledge' ||
        donationType === 'organization-pledge'
      ) {
        response = await addPledge(payload)
      } else if (donationType === 'seed-donation') {
        response = await publicSeedDonation(payload, organizationId)
      } else {
        response = await publicDonation(payload)
      }
      if (response?.htmlReceipt) {
        setHtmlContent(response.htmlReceipt)
      }
      setDonating(false)
      setDonated(true)
      message.success('Your contribution is successfully recieved')
    } catch (error) {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      setDonating(false)
    }
  }

  const onPaypalDonateSubmit = async (payload) => {
    try {
      let response
      if (donationType === 'seed-donation') {
        response = await publicPaypalSeedDonation(payload)
      } else {
        response = await publicPaypalDonation(payload)
      }
      if (response.htmlReceipt) {
        setHtmlContent(response.htmlReceipt)
      }
      setDonating(false)
      setDonated(true)
      message.success('Your contribution is successfully recieved')
    } catch (error) {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      setDonating(false)
    }
  }

  //  Scroll
  const scrollableDivRef = useRef(null)
  const [isAtEnd, setIsAtEnd] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableDivRef.current) {
        const { scrollHeight, scrollTop, clientHeight } =
          scrollableDivRef.current
        // To check if scroll is at end
        if (scrollHeight - scrollTop === clientHeight) {
          setIsAtEnd(true)
        } else {
          setIsAtEnd(false)
        }
      }
    }

    scrollableDivRef.current.addEventListener('scroll', handleScroll)
    return () => {
      if (scrollableDivRef && scrollableDivRef.current)
        // eslint-disable-next-line
        scrollableDivRef.current.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // Check if already paid on mirrored donation and pledge conversion
  const isDonationTypePresent = donationType in AlreadyPaid
  const alreayPaidCondition =
    item &&
    isDonationTypePresent &&
    AlreadyPaid[donationType] &&
    item[AlreadyPaid[donationType].statusAttribute] ===
      AlreadyPaid[donationType].statusValue
  return (
    <div
      className="t-relative t-h-screen t-overflow-auto no-scrollbar t-p-2"
      ref={scrollableDivRef}
    >
      {item ? (
        <>
          {alreayPaidCondition ? (
            <div className="t-w-full t-h-full t-flex t-justify-center t-items-center">
              <div className="ant-result ant-result-404">
                <SVG />
                <div className="ant-result-title">
                  {AlreadyPaid[donationType].title}
                </div>
                <div className="ant-result-subtitle">
                  {AlreadyPaid[donationType].description}
                </div>
              </div>
            </div>
          ) : (
            <>
              {!isDonated ? (
                <>
                  {isOrganizationReletated ? (
                    <OrgInformation organization={organization} />
                  ) : (
                    <EventInformation event={event} />
                  )}
                  <DonateForm
                    donationType={donationType}
                    item={item}
                    isOrganizationPayment={isOrganizationPayment}
                    isEventPayment={isEventPayment}
                    setLoading={setDonating}
                    isLoading={isDonating}
                    onSubmit={onDonateSubmit}
                    onPaypalSubmit={onPaypalDonateSubmit}
                  />
                </>
              ) : (
                <Confirmation
                  donationType={donationType}
                  orgWebsite={organization?.Website}
                  orgDomain={organization?.OrgDomain}
                  htmlContent={htmlContent}
                />
              )}
            </>
          )}

          <div className="t-flex t-justify-end t-w-full t-text-xs t-italic t-text-gray-500 t-mt-12">
            Powered by{' '}
            <a
              className="t-ml-1 font-bold t-text-[#14B1E0] t-cursor-pointer"
              href="/"
              target="_blank"
            >
              Extensia
            </a>
          </div>
        </>
      ) : (
        <InvalidURL />
      )}
      {!isAtEnd && (
        <div className="t-fixed t-bottom-6 t-right-6">
          <AiFillDownCircle
            fontSize="1.5rem"
            className="t-text-secondary-100 t-cursor-pointer"
            onClick={() =>
              scrollableDivRef.current.scrollTo({
                top: scrollableDivRef.current.scrollHeight,
                behavior: 'smooth',
              })
            }
          />
        </div>
      )}
    </div>
  )
}

export { Donations }

import { Button, Col, Form, Input, Row, Select, Skeleton, message } from 'antd'
import Api, { makeRequest } from 'api'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { organizationUpdateSettingValidation } from 'validations/updateSetting'
import { DollarOutlined } from '@ant-design/icons'
import { numberWithCommas } from 'utils'
import { config } from 'configs'

const recurringFrequencies = [
  'daily',
  'weekly',
  'monthly',
  'quarterly',
  'yearly',
].map((d) => ({ label: d, value: d }))

const paymentMethods = [
  {
    label: 'Card',
    value: 'card',
  },
  {
    label: 'CashApp',
    value: 'cashapp',
  },
  {
    label: 'PayPal',
    value: 'paypal',
  },
  {
    label: 'ACH Transfer',
    value: 'us_bank_account',
  },
]

const Settings = () => {
  const [activeKeys, setActiveKeys] = useState([])
  const [activePaymentMethods, setActivePaymentMethods] = useState([])
  const [paymentCategories, setPaymentCategories] = useState([])
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)

  // eslint-disable-next-line
  // const [setting, setSetting] = useState({})
  const history = useHistory()
  const [form] = Form.useForm()
  const { organization } = useAuthSelector()

  const onFinish = async (values) => {
    const data = {
      DefaultDonationValues: [
        values.donationValue0?.replace('$', ''),
        values.donationValue1?.replace('$', ''),
        values.donationValue2?.replace('$', ''),
        values.donationValue3?.replace('$', ''),
      ],
      RequireStreetAddress: values.donorMailingAddressVisibility === 'required',
      RequireEmailAddress: values.donorEmailVisibility === 'required',
      donorNameVisibility: values.donorNameVisibility,
      donorPhoneNumberVisibility: values.donorPhoneNumberVisibility,
      donorMailingAddressVisibility: values.donorMailingAddressVisibility,
      donorEmailVisibility: values.donorEmailVisibility,
      enableRecurringPayment: values.enableRecurringPayment === 'yes',
      enableSplitPayment: values.enableSplitPayment === 'yes',
      recurringFrequency: values?.recurringFrequency || [],
      splitFrequency: ['monthly'],
      minimumSplitAmount:
        values?.minimumSplitAmount?.replaceAll(/,/g, '') || 50,
      maximumSplitDuration: values?.maximumSplitDuration || 12,
      enablePaymentCategories: values.enablePaymentCategories === 'yes',
      paymentCategories: activeKeys,
      allowedPaymentMethods: activePaymentMethods,
    }
    try {
      setBtnLoading(true)
      const response = await makeRequest(
        'patch',
        '/webapp/organization/setting',
        { organizationId: organization.id },
        data,
      )
      message.success('Settings updated successfully!', response)
      history.push('/settings/manage-organization/organization-detail')
      setBtnLoading(false)
    } catch (error) {
      setBtnLoading(false)
      message.error('Failed to update settings.')
    }
  }

  const onFinishFailed = (errorInfo) => {
    message.error('Fail', errorInfo)
  }

  const handleBack = () => {
    history.push(config.defaultRedirect.subscriber)
  }

  const handleActiveKey = (key) => {
    setActiveKeys((prevActiveKeys) => {
      if (prevActiveKeys.includes(key)) {
        return prevActiveKeys.filter((k) => k !== key)
      }
      return [...prevActiveKeys, key]
    })
  }

  const handleActivePaymentMethod = (value) => {
    const newMethods = activePaymentMethods.includes(value)
      ? activePaymentMethods.filter((method) => method !== value)
      : [...activePaymentMethods, value]

    setActivePaymentMethods(newMethods)
    form.setFieldValue('allowedPaymentMethods', newMethods)

    return newMethods
  }

  const getPaymentCategories = () => {
    Api.get(
      `paymentCategories/getOrganizationPaymentCategories/${organization?.id}`,
    )
      .then((res) => {
        const formatedCategories = res?.results
          ?.filter((item) => item.ActiveInd === 1)
          .map((item) => ({
            value: item.Id,
            label: item.Name,
          }))
        setPaymentCategories(formatedCategories)
      })
      .catch((error) => {
        console.log('error', error)
        message.error('An Error Occured in fetching payment categories')
      })
  }

  const getSettingData = () => {
    setLoading(true)
    makeRequest('get', '/webapp/organization?type=settings', {
      organizationId: organization?.id,
    })
      .then((res) => {
        setLoading(false)
        // setSetting(res.item)
        // Set default donation values
        const donationValues = {}
        res.item.DefaultDonationValues.forEach((value, index) => {
          donationValues[`donationValue${index}`] = `$${value}`
        })
        form.setFieldsValue({
          RequireEmailAddress: res.item.RequireEmailAddress ? 'Yes' : 'No',
          RequireStreetAddress: res.item.RequireStreetAddress ? 'Yes' : 'No',
          PaymentCategories: res.item.PaymentCategories || [],
          maximumSplitDuration: res.item.MaximumSplitDuration || 12,
          minimumSplitAmount: res.item.MinimumSplitAmount || '50',
          enablePaymentCategories: res.item.EnablePaymentCategories
            ? 'yes'
            : 'no',
          enableRecurringPayment: res.item.EnableRecurringPayment
            ? 'yes'
            : 'no',
          recurringFrequency: res.item.RecurringFrequency || [],
          enableSplitPayment: res.item.EnableSplitPayment ? 'yes' : 'no',
          donorEmailVisibility: res.item.DonorEmailVisibility || 'required',
          donorNameVisibility: res.item.DonorNameVisibility || 'required',
          donorPhoneNumberVisibility:
            res.item.DonorPhoneNumberVisibility || 'required',
          donorMailingAddressVisibility:
            res.item.DonorMailingAddressVisibility || 'required',
          ...donationValues,
        })
        // Set active keys
        setActiveKeys(res?.item?.PaymentCategories?.map((p) => p) || [])
        setActivePaymentMethods(res.item.AllowedPaymentMethods || [])
      })
      .catch(() => {
        setLoading(false)
        message.error('Something went wrong!')
      })
  }

  useEffect(() => {
    if (organization?.id) {
      getSettingData()
      getPaymentCategories()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization.id])

  Form.useWatch('enableRecurringPayment', form)
  Form.useWatch('enablePaymentCategories', form)
  Form.useWatch('enableSplitPayment', form)

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 16 }}>
      <div className="t-mx-2 lg:t-mr-7 lg:t-ml-3">
        <Form
          name="basic"
          layout="vertical"
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 24,
          }}
          className="t-w-[80%] sm:t-w-[100%] t-m-auto ant-roboto-font"
          initialValues={{
            remember: true,
            minimumSplitAmount: 50,
            maximumSplitDuration: 12,
            donorNameVisibility: 'required',
            donorPhoneNumberVisibility: 'required',
            donorMailingAddressVisibility: 'required',
            donorEmailVisibility: 'required',
            enableSplitPayment: 'no',
            enableRecurringPayment: 'no',
            enablePaymentCategories: 'no',
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <div className="t-flex t-flex-row t-justify-between t-border-b t-border-b-gray-500 t-mt-8 t-mb-8">
            <h1 className="t-text-base t-font-semibold t-text-black t-pb-1 roboto-font">
              Payment Page Settings:
            </h1>
          </div>
          <Form.Item
            label="Donor Name?"
            name="donorNameVisibility"
            rules={[organizationUpdateSettingValidation]}
          >
            <Select defaultValue="required">
              <Select.Option value="required">Required</Select.Option>
              <Select.Option value="optional">Optional</Select.Option>
              <Select.Option value="hidden">Hidden</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Donor Email?"
            name="donorEmailVisibility"
            rules={[organizationUpdateSettingValidation]}
          >
            <Select defaultValue="required">
              <Select.Option value="required">Required</Select.Option>
              <Select.Option value="optional">Optional</Select.Option>
              <Select.Option value="hidden">Hidden</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Donor Phone Number?"
            name="donorPhoneNumberVisibility"
            rules={[organizationUpdateSettingValidation]}
          >
            <Select defaultValue="required">
              <Select.Option value="required">Required</Select.Option>
              <Select.Option value="optional">Optional</Select.Option>
              <Select.Option value="hidden">Hidden</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Donor Mailing Address?"
            name="donorMailingAddressVisibility"
            rules={[organizationUpdateSettingValidation]}
          >
            <Select defaultValue="required">
              <Select.Option value="required">Required</Select.Option>
              <Select.Option value="optional">Optional</Select.Option>
              <Select.Option value="hidden">Hidden</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Enable Recurring Payments?"
            name="enableRecurringPayment"
            rules={[organizationUpdateSettingValidation]}
          >
            <Select defaultValue="yes">
              <Select.Option value="yes">Yes</Select.Option>
              <Select.Option value="no">No</Select.Option>
            </Select>
          </Form.Item>
          {form.getFieldValue('enableRecurringPayment') === 'yes' && (
            <Form.Item
              label="Select Recurring Frequencies"
              name="recurringFrequency"
              rules={[organizationUpdateSettingValidation]}
            >
              <Select
                mode="multiple"
                style={{
                  width: '100%',
                }}
                placeholder="Please select frequencies"
                defaultValue={[]}
                onChange={(e) => form.setFieldValue('recurringFrequency', e)}
                options={recurringFrequencies}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Enable Split Payments?"
            name="enableSplitPayment"
            rules={[organizationUpdateSettingValidation]}
          >
            <Select defaultValue="yes">
              <Select.Option value="yes">Yes</Select.Option>
              <Select.Option value="no">No</Select.Option>
            </Select>
          </Form.Item>
          {form.getFieldValue('enableSplitPayment') === 'yes' && (
            <>
              <Form.Item
                label="Minimum Split Amount"
                name="minimumSplitAmount"
                rules={[organizationUpdateSettingValidation]}
              >
                <Input
                  prefix={<DollarOutlined />}
                  min={1}
                  inputMode="numeric"
                  style={{ width: '100%' }}
                  onChange={(e) =>
                    form.setFieldsValue({
                      minimumSplitAmount: numberWithCommas(e.target.value),
                    })
                  }
                />
              </Form.Item>
              <Form.Item
                label="Maximum Split Duration"
                name="maximumSplitDuration"
                rules={[organizationUpdateSettingValidation]}
              >
                <Input
                  min={1}
                  inputMode="numeric"
                  style={{ width: '100%' }}
                  onChange={(e) =>
                    form.setFieldsValue({
                      maximumSplitDuration: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </>
          )}
          <div className="t-flex t-flex-row t-justify-between t-border-b t-border-b-gray-500 t-mt-8 t-mb-8">
            <h1 className="t-text-base t-font-semibold t-text-black t-pb-1 roboto-font">
              Org Donation Button Amount:
            </h1>
          </div>
          <Row className="t-flex t-justify-center t-item-center t-mt-4">
            <Col span={8} className="t-mx-6">
              <Form.Item
                name="donationValue0"
                rules={[organizationUpdateSettingValidation]}
              >
                <Input className="t-text-lg t-text-center t-py-8" />
              </Form.Item>
            </Col>
            <Col span={8} className="t-mx-6">
              <Form.Item
                name="donationValue1"
                rules={[organizationUpdateSettingValidation]}
              >
                <Input className="t-text-lg t-text-center t-py-8" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="t-flex t-justify-center t-item-center t-mt-4">
            <Col span={8} className="t-mx-6">
              <Form.Item
                name="donationValue2"
                rules={[organizationUpdateSettingValidation]}
              >
                <Input className="t-text-lg t-text-center t-py-8" />
              </Form.Item>
            </Col>
            <Col span={8} className="t-mx-6">
              <Form.Item
                name="donationValue3"
                rules={[organizationUpdateSettingValidation]}
              >
                <Input className="t-text-lg t-text-center t-py-8" />
              </Form.Item>
            </Col>
          </Row>
          <div className="t-flex t-flex-row t-justify-between t-border-b t-border-b-gray-500 t-mt-8 t-mb-8">
            <h1 className="t-text-base t-font-semibold t-text-black t-pb-1 roboto-font">
              Payment Categories:
            </h1>
          </div>
          <Form.Item
            label="Enable Payment Categories?"
            name="enablePaymentCategories"
            rules={[organizationUpdateSettingValidation]}
          >
            <Select defaultValue="yes">
              <Select.Option value="yes">Yes</Select.Option>
              <Select.Option value="no">No</Select.Option>
            </Select>
          </Form.Item>
          {form.getFieldValue('enablePaymentCategories') === 'yes' &&
            paymentCategories.map((item) => (
              <Form.Item
                key={item.value}
                rules={[organizationUpdateSettingValidation]}
              >
                <div
                  onClick={() => handleActiveKey(item.value)}
                  className={`t-w-[90%] t-m-auto t-rounded-md t-my-2 t-cursor-pointer ${
                    activeKeys.includes(item.value)
                      ? 't-bg-[#e6f4ff] t-border-2 t-border-[#2e97de]'
                      : 't-bg-[#f3f3f3]'
                  }`}
                >
                  <h1 className="t-text-lg t-font-medium t-text-center t-py-4 roboto-font">
                    {item.label}
                  </h1>
                </div>
              </Form.Item>
            ))}
          <div className="t-flex t-flex-row t-justify-between t-border-b t-border-b-gray-500 t-mt-8 t-mb-8">
            <h1 className="t-text-base t-font-semibold t-text-black t-pb-1 roboto-font">
              Donation Methods:
            </h1>
          </div>
          {paymentMethods.map((item) => (
            <Form.Item
              key={item.value}
              name="allowedPaymentMethods"
              rules={[organizationUpdateSettingValidation]}
            >
              <div
                onClick={() => handleActivePaymentMethod(item.value)}
                className={`t-w-[90%] t-m-auto t-rounded-md t-my-2 t-cursor-pointer ${
                  activePaymentMethods.includes(item.value)
                    ? 't-bg-[#e6f4ff] t-border-2 t-border-[#2e97de]'
                    : 't-bg-[#f3f3f3]'
                }`}
              >
                <h1 className="t-text-lg t-font-medium t-text-center t-py-4 roboto-font">
                  {item.label}
                </h1>
              </div>
            </Form.Item>
          ))}
          <Row className="t-flex t-justify-center lg:t-justify-end t-item-center t-mt-8">
            <Col span={8} className="t-mx-3">
              <Form.Item>
                <Button
                  htmlType="button"
                  className="t-w-[85%] t-text-[14px]"
                  onClick={handleBack}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={btnLoading}
                  className="t-w-[85%] t-text-[14px] t-bg-[#2e97de]"
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Skeleton>
  )
}

export { Settings }

import React from 'react'
import { useTimer } from 'react-timer-hook'

const Timer = ({ expiryTimestamp }) => {
  const { seconds } = useTimer({
    expiryTimestamp,
    onExpire: () => {},
  })

  return (
    <div className="col-md-3" style={{ marginLeft: '0.5rem' }}>
      <span>
        Resend OTP in{' '}
        <b>
          <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
        </b>
      </span>
    </div>
  )
}

export { Timer }

import React from 'react'
import { Input, Form, Col, DatePicker } from 'antd'
import { funeralValidation } from 'validations'
import { compareDateTimeWithNow, disabledTime } from 'utils'
import moment from 'moment'

const FuneralForm = ({ form }) => (
  <>
    <Col span={24}>
      <Form.Item
        name="Name"
        label="Deceased Name"
        colon={false}
        rules={[funeralValidation]}
      >
        <Input />
      </Form.Item>
    </Col>
    <Col span={24}>
      <Form.Item
        name="DOB"
        label="Deceased Date Of Birth"
        colon={false}
        rules={[funeralValidation]}
      >
        <DatePicker
          format="MM-DD-YYYY"
          className="t-w-full"
          disabledDate={(d) => !d || d.isAfter(moment())}
          getPopupContainer={(trigger) => trigger.parentElement}
        />
      </Form.Item>
    </Col>

    <Col span={24}>
      <Form.Item
        name="SSN"
        label="Deceased SSN"
        colon={false}
        rules={[funeralValidation]}
      >
        <Input />
      </Form.Item>
    </Col>
    <Col span={24}>
      <Form.Item
        name="NextKin"
        label="Next of Kin"
        colon={false}
        rules={[funeralValidation]}
      >
        <Input />
      </Form.Item>
    </Col>

    <Col span={24}>
      <Form.Item
        name="DateOfDeath"
        label="Date of Death"
        colon={false}
        rules={[funeralValidation]}
      >
        <DatePicker
          format="MM-DD-YYYY"
          className="t-w-full"
          // disabledDate={(d) => !d || d.isAfter(moment())}
          disabledDate={(d) =>
            !d ||
            d.isBefore(moment().subtract(1, 'day')) ||
            d.isBefore(moment(form.getFieldValue('DOB')).startOf('day'))
          }
          disabledTime={disabledTime}
          onChange={(selectedDate) =>
            !compareDateTimeWithNow(selectedDate, 'before') &&
            form.setFieldsValue({
              DateOfDeath: moment(),
            })
          }
          getPopupContainer={(trigger) => trigger.parentElement}
        />
      </Form.Item>
    </Col>
    <Col span={24}>
      <Form.Item
        name="GraveyardName"
        label="Name of Graveyard for Burial"
        colon={false}
        rules={[funeralValidation]}
      >
        <Input />
      </Form.Item>
    </Col>
    <Col span={24}>
      <Form.Item
        name="SpecialInstructions"
        label="Special Instructions"
        colon={false}
        rules={[funeralValidation]}
      >
        <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
      </Form.Item>
    </Col>

    <Col span={24}>
      <Form.Item
        name="DateOfBurial"
        label="Requested Date of Burial"
        colon={false}
        rules={[funeralValidation]}
      >
        <DatePicker
          format="MM-DD-YYYY hh:mm A"
          showTime
          className="t-w-full"
          disabledDate={(d) => !d || d.isBefore(moment().subtract(1, 'day'))}
          disabledTime={disabledTime}
          onChange={(selectedDate) =>
            !compareDateTimeWithNow(selectedDate, 'before') &&
            form.setFieldsValue({
              DateOfBurial: moment(),
            })
          }
          getPopupContainer={(trigger) => trigger.parentElement}
        />
      </Form.Item>
    </Col>

    <Col span={24}>
      <Form.Item
        name="OtherInstructions"
        label="Other instructions"
        colon={false}
        rules={[funeralValidation]}
      >
        <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
      </Form.Item>
    </Col>
  </>
)

export { FuneralForm }

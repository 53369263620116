import React, { useEffect, useState } from 'react'
import { Skeleton, Table } from 'antd'
import Api from 'api'

function VPPerformanceDetail({ id, setValue }) {
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()

  useEffect(() => {
    setLoading(true)
    Api.get(`volunteer/getRegisterVolunteerByOrganization/${id}`)
      .then((res) => {
        const data = res.results
        setReport(data)
        setValue(data)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  const baseColumns = [
    {
      title: 'FullName',
      key: 'FullName',
      render: (text, record) => `${record?.firstName} ${record?.lastName}`,
    },

    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
  ]

  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      {report && report.length > 0 ? (
        <>
          <div className="t-flex t-items-center t-mb-8">
            <h1 className="t-font-semibold t-mx-2">Volunteer Count:</h1>
            <p className="t-font-semibold">{report.length}</p>
          </div>
          <div>
            <Table
              dataSource={report}
              columns={baseColumns}
              rowKey={(record) => record.Id}
              // components={components}
              scroll={{ x: true }} // Enables horizontal scrolling
              pagination={false} // Disable default pagination
              // loading={loading}
            />
          </div>
        </>
      ) : (
        <div>No Data Found</div>
      )}
    </Skeleton>
  )
}

export default VPPerformanceDetail

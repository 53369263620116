import React, { useEffect, useReducer, useState } from 'react'
import { Card, Dropdown, Input, Menu, message, Tooltip } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { searchBusinessListingsRows } from 'utils'
import { getMyBusinessListing } from 'store/businessSlice/businessActions'
import { MdAddBusiness } from 'react-icons/md'
// import { TbPlaylistX } from 'react-icons/tb'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { BiSort } from 'react-icons/bi'
import { config } from 'configs'
import { BusinessEntitiesList } from './BusinessEntitiesList'
// import { BanEntitiesList } from './BanEntitiesList'

const initialState = {
  get: false,
  ban: false,
}
// The reducer function
function reducer(state, action) {
  switch (action) {
    case 'get':
      return {
        ...state,
        get: !state.get,
      }
    case 'ban':
      return {
        ...state,
        ban: !state.ban,
      }
    default:
      return state
  }
}

const initialViewValue = {
  businessEntities: true,
  businessInvitationList: false,
  BanList: false,
}

const MyBusinessEntities = () => {
  const [loading, setLoading] = useReducer(reducer, initialState)
  const [donors, setDonors] = useState([])
  const [searchText, setSearchText] = useState(null)
  const [sortKey, setSortKey] = useState('All')
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { organization } = useAuthSelector()

  const [isViews, setViews] = useState(initialViewValue)

  const changeView = (key) => {
    switch (key) {
      case 'businessEntities':
        setViews({
          businessEntities: true,
          businessInvitationList: false,
          BanList: false,
        })
        break
      case 'businessInvitationList':
        setViews({
          businessEntities: false,
          businessInvitationList: true,
          BanList: false,
        })
        break
      case 'BanList':
        setViews({
          businessEntities: false,
          businessInvitationList: false,
          BanList: true,
        })
        break
      default:
        setViews({
          businessEntities: true,
          businessInvitationList: false,
          BanList: false,
        })
        break
    }
  }

  const getData = async () => {
    setLoading('get')
    try {
      const response = await getMyBusinessListing(organization?.id)
      if (response) setDonors(response)
      setLoading('get')
    } catch (error) {
      console.log('error', error)
      message.error('An error occur')
      setLoading('get')
    }
  }

  useEffect(() => {
    if (organization?.id) {
      getData()
    }
    // eslint-disable-next-line
  }, [organization])

  const menu = (
    <Menu>
      <Menu.Item
        key={1}
        onClick={() => setSortKey('All')}
        style={
          sortKey === 'All' && {
            backgroundColor: config.colors.primary,
            color: 'white',
          }
        }
      >
        All
      </Menu.Item>
      <Menu.Item
        key={2}
        onClick={() => setSortKey('Members')}
        style={
          sortKey === 'Members' && {
            backgroundColor: config.colors.primary,
            color: 'white',
          }
        }
      >
        Members
      </Menu.Item>
      <Menu.Item
        key={2}
        onClick={() => setSortKey('Paused')}
        style={
          sortKey === 'Paused' && {
            backgroundColor: config.colors.primary,
            color: 'white',
          }
        }
      >
        Paused
      </Menu.Item>
    </Menu>
  )

  const filterDonors = donors?.filter((item) => {
    if (sortKey === 'All') return true
    if (sortKey === 'Members') {
      return item?.PaymentStatus === 'Completed'
    }
    if (sortKey === 'Paused') {
      return item?.Status === 'Paused'
    }
    return false
  })

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={
        <>
          <div className="t-flex t-flex-wrap t-justify-between">
            <h6 className="mb-0 t-text-secondary-100 t-font-bold t-text-xl">
              {isViews.businessEntities && 'My Business Entities List'}
              {isViews.BanList && 'Ban Entities List'}
            </h6>
            <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
              <div>
                <Dropdown overlay={menu} placement="bottomLeft" arrow>
                  <Tooltip title="Sort Filter">
                    <BiSort
                      fontSize="1.7rem"
                      className="t-text-secondary-100 t-cursor-pointer"
                    />
                  </Tooltip>
                </Dropdown>
              </div>
              <div className="header-control t-mt-0">
                <Input
                  className="header-search t-w-36"
                  placeholder="Search ..."
                  prefix={<SearchOutlined />}
                  onChange={(e) => {
                    setSearchText(e.target.value)
                  }}
                />
              </div>
              {!isViews.businessEntities && (
                <Tooltip title="View Business Entities">
                  <MdAddBusiness
                    fontSize="2rem"
                    id="volunteers-inactive-not-clicked"
                    className="t-text-secondary-100 t-cursor-pointer"
                    onClick={() => changeView('businessEntities')}
                  />
                </Tooltip>
              )}
              {/* {!isViews.BanList && (
                <Tooltip title="View Ban List">
                  <TbPlaylistX
                    fontSize="2rem"
                    id="volunteers-inactive-not-clicked"
                    className="t-text-secondary-100 t-cursor-pointer"
                    onClick={() => changeView('BanList')}
                  />
                </Tooltip>
              )} */}
            </div>
          </div>
        </>
      }
    >
      <>
        {isViews.businessEntities && (
          <BusinessEntitiesList
            loading={loading.get}
            data={searchBusinessListingsRows(filterDonors, searchText) || []}
            getData={getData}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        )}
        {/* {isViews.BanList && <BanEntitiesList searchText={searchText} />} */}
      </>
    </Card>
  )
}

export { MyBusinessEntities }

import React, { useEffect } from 'react'
import { Card, Col, Row, Typography, Skeleton } from 'antd'
import { AiFillDollarCircle } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { getUserContributions } from 'store/authSlice/authActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { formatAmount, formateDateTime } from 'utils'
import { YTDDonations } from './YTDDonations'
import { YTDPledges } from './YTDPledges'
import { DonationsSummary } from './DonationSummary'
import { ItemizedSummary } from './ItemizedSummary'
import { LastYearPaymentMethodsReport } from './LastYearPaymentMethodsReport'
import { YearOnYearSummary } from './YearOnYearSummary'
import { CurrentYearPaymentMethodsReport } from './CurrentYearPaymentMethodsReport'

const { Title } = Typography

const MyContributions = () => {
  const { loading, contributions } = useAuthSelector()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserContributions())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card
      title={[
        <>
          <h4 className="mb-0">My Contributions</h4>
          <p className="font-regular">My Donations Summary</p>
        </>,
      ]}
      className="t-mb-5"
    >
      <div style={{ minHeight: 'calc(100vh - 320px)' }}>
        <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
          <Row justify="space-between">
            <Col xs={24} sm={24} lg={5} className="mb-24">
              <Card bordered={false} className="criclebox">
                <div className="number">
                  <Row align="middle" gutter={[0]}>
                    <Col xs={20}>
                      <span>YTD Donations</span>
                      <Title level={4}>
                        {`${formatAmount(contributions?.totalDonation)}`}
                      </Title>
                    </Col>
                    <Col xs={4}>
                      <AiFillDollarCircle
                        className="t-text-secondary-100"
                        fontSize="2.5rem"
                      />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} lg={5} className="mb-24">
              <Card bordered={false} className="criclebox">
                <div className="number">
                  <Row align="middle" gutter={[0]}>
                    <Col xs={20}>
                      <span>YTD Pledges</span>
                      <Title level={4}>
                        {`${formatAmount(contributions?.totalPledges)}`}
                      </Title>
                    </Col>
                    <Col xs={4}>
                      <AiFillDollarCircle
                        className="t-text-secondary-100"
                        fontSize="2.5rem"
                      />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} lg={5} className="mb-24">
              <Card bordered={false} className="criclebox">
                <div className="number">
                  <Row align="middle" gutter={[0]}>
                    <Col xs={20}>
                      <span>Remaining Pledges</span>
                      <Title level={4}>
                        {`${formatAmount(contributions?.totalRemaining)}`}
                      </Title>
                    </Col>
                    <Col xs={4}>
                      <AiFillDollarCircle
                        className="t-text-secondary-100"
                        fontSize="2.5rem"
                      />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} lg={5} className="mb-24">
              <Card bordered={false} className="criclebox">
                <div className="number">
                  <Row align="middle" gutter={[0]}>
                    <Col xs={20}>
                      <span>Next Payment</span>
                      <Title level={4}>{`${formatAmount(
                        contributions?.nextPayment,
                      )}`}</Title>
                    </Col>
                    <Col xs={4}>
                      <AiFillDollarCircle
                        className="t-text-secondary-100"
                        fontSize="2.5rem"
                      />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="t-mb-6">
            <h6 className="t-text-base t-font-bold">
              Scheduled Pledge Payment Date :{' '}
              <span className="t-text-base t-font-normal">
                {`${
                  contributions?.scheduledPaymentDate
                    ? formateDateTime(contributions?.scheduledPaymentDate)
                    : 'N/A'
                }`}
              </span>
            </h6>
          </Row>
          <Row className="t-my-5">
            <Title level={5}>Contribution Details</Title>
          </Row>
          <Row justify="space-between">
            <Col xs={24} sm={24} lg={11} className="t-my-1">
              <Card bordered={false} className="criclebox">
                <div className="number">
                  <Row align="middle" gutter={[0]}>
                    <Col xs={22}>
                      <span>View YTD Donations</span>
                    </Col>
                    <Col xs={2} className="t-cursor-pointer">
                      <YTDDonations />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} lg={11} className="t-my-1">
              <Card bordered={false} className="criclebox">
                <div className="number">
                  <Row align="middle" gutter={[0]}>
                    <Col xs={22}>
                      <span>View YTD Pledges</span>
                    </Col>
                    <Col xs={2} className="t-cursor-pointer">
                      <YTDPledges />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="t-my-5">
            <Title level={5}>My Reports</Title>
          </Row>
          <Row justify="space-between">
            <Col xs={24} sm={24} lg={11} className="t-my-1">
              <Card bordered={false} className="criclebox">
                <div className="number">
                  <Row align="middle" gutter={[0]}>
                    <Col xs={22}>
                      <span>Last Year Donation Summary</span>
                    </Col>
                    <Col xs={2} className="t-cursor-pointer">
                      <DonationsSummary title="Last Year Donation Summary" />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} lg={11} className="t-my-1">
              <Card bordered={false} className="criclebox">
                <div className="number">
                  <Row align="middle" gutter={[0]}>
                    <Col xs={22}>
                      <span>Last Year Itemized Donations</span>
                    </Col>
                    <Col xs={2} className="t-cursor-pointer">
                      <ItemizedSummary />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} lg={11} className="t-my-1">
              <Card bordered={false} className="criclebox">
                <div className="number">
                  <Row align="middle" gutter={[0]}>
                    <Col xs={22}>
                      <span>Last Year Payment Methods Report</span>
                    </Col>
                    <Col xs={2} className="t-cursor-pointer">
                      <LastYearPaymentMethodsReport />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} lg={11} className="t-my-1">
              <Card bordered={false} className="criclebox">
                <div className="number">
                  <Row align="middle" gutter={[0]}>
                    <Col xs={22}>
                      <span>Current Year Payment Methods Report</span>
                    </Col>
                    <Col xs={2} className="t-cursor-pointer">
                      <CurrentYearPaymentMethodsReport />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} lg={11} className="t-my-1">
              <Card bordered={false} className="criclebox">
                <div className="number">
                  <Row align="middle" gutter={[0]}>
                    <Col xs={22}>
                      <span>Year-On-Year Summary</span>
                    </Col>
                    <Col xs={2} className="t-cursor-pointer">
                      <YearOnYearSummary />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </Skeleton>
      </div>
    </Card>
  )
}

export { MyContributions }

import React, { useEffect } from 'react'
import { Card, Col, Row, Dropdown, Menu, Skeleton, Tag, Tooltip } from 'antd'
import { IoIosArrowDropdownCircle } from 'react-icons/io'
import { useDispatch } from 'react-redux'
import { timeInAgo } from 'utils'
import {
  changeBusinessRequestStatus,
  getMyAssociationBusinessRequests,
} from 'store/businessSlice/businessActions'
import { useBusinessSelector } from 'store/businessSlice/businessReducer'
import { useAuthSelector } from 'store/authSlice/authReducer'

const MyAssociationrequests = () => {
  const dispatch = useDispatch()
  const { loading, associationRequests } = useBusinessSelector()
  const { organization } = useAuthSelector()

  useEffect(() => {
    dispatch(getMyAssociationBusinessRequests(organization?.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onApproveClick = (Id) => {
    dispatch(changeBusinessRequestStatus(Id, 'Approved'))
  }

  const onRejectClick = (Id) => {
    dispatch(changeBusinessRequestStatus(Id, 'Rejected'))
  }

  const customMenu = (Id, isPending) => (
    <>
      {isPending ? (
        <Menu>
          <Menu.Item
            id="acceptInvite"
            key={1}
            onClick={() => onApproveClick(Id)}
          >
            Approve Request
          </Menu.Item>
          <Menu.Item
            id="rejectInvite"
            key={2}
            onClick={() => onRejectClick(Id)}
          >
            Reject Request
          </Menu.Item>
        </Menu>
      ) : (
        <></>
      )}
    </>
  )

  const InitiatedByBusiness = associationRequests?.filter(
    (item) => item?.Initiator === 'Business',
  )

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0 t-text-2xl">Listing Requests</h6>
          {/* <h6 className="font-regular">
            You are requested for associations by the following users
          </h6> */}
        </>,
      ]}
    >
      <div style={{ minHeight: 'calc(100vh - 250px)' }}>
        <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
          <Col span={24}>
            {InitiatedByBusiness.length === 0 && (
              <Card className="t-h-96 t-w-full t-flex t-justify-center t-items-center">
                <div>No Associations</div>
              </Card>
            )}
            <div id="presenterEventList">
              {InitiatedByBusiness.map((item, index) => (
                <Card key={index} className="w-full t-my-2 t-p-2">
                  <Row gutter={[24, 0]} className="t-flex t-justify-between">
                    <div className="font-bold t-flex t-space-x-4">
                      <div>
                        You are requested for associations by{' '}
                        <span className="t-italic t-text-secondary-100">
                          {item.UserName}
                        </span>
                      </div>
                      <div>
                        {item.Status === 'Approved' && (
                          <Tag color="green" className="font-bold">
                            Approved
                          </Tag>
                        )}
                        {item.Status === 'Rejected' && (
                          <Tag color="red" className="font-bold">
                            Rejected
                          </Tag>
                        )}
                        {item.Status === 'Pending' && (
                          <Tag color="gray" className="font-bold">
                            Pending
                          </Tag>
                        )}
                      </div>
                    </div>
                    {item.Status === 'Pending' && (
                      <Dropdown
                        overlay={customMenu(
                          item?.Id,
                          item.Status === 'Pending',
                        )}
                      >
                        <IoIosArrowDropdownCircle
                          id="inviteMenu"
                          fontSize="1.5rem"
                          className="t-text-secondary-100 t-cursor-pointer"
                        />
                      </Dropdown>
                    )}
                  </Row>
                  <Row gutter={[24, 0]}>
                    <Tooltip title="Create Date">
                      {item?.CreatedDate && timeInAgo(item.CreatedDate)}
                    </Tooltip>
                  </Row>
                </Card>
              ))}
            </div>
          </Col>
        </Skeleton>
      </div>
    </Card>
  )
}

export { MyAssociationrequests }

import React, { useEffect, useState } from 'react'
import { Card, Skeleton } from 'antd'
import { config } from 'configs'
import { formatAmount } from 'utils'
import moment from 'moment'
import { InfiniteScrollContainer } from 'components/elements'
import { useFundraiserSelector } from 'store/fundraiserSlice/fundraiserReducer'

const Donations = () => {
  const { loading, fundraiserDetail } = useFundraiserSelector()
  const [data, setData] = useState([])
  const [current, setCurrent] = useState([])

  useEffect(() => {
    if (fundraiserDetail?.donations?.length > 0) {
      setData(fundraiserDetail?.donations)
      setCurrent(fundraiserDetail?.donations?.slice(0, config.rowsPerPage))
    }
    // eslint-disable-next-line
  }, [fundraiserDetail])

  const PaymentMethodText = (text) => {
    let updatedtext = text.toLowerCase()
    if (updatedtext === 'paypal' || updatedtext === 'stripe') {
      updatedtext = 'Credit Card'
    }
    return updatedtext
  }

  const getDonorName = (item) => {
    if (item?.DonorName) {
      return item?.DonorName
    }
    const firstName = item?.DonorFirstName || ''
    const lastName = item?.DonorLastName || ''

    return `${firstName} ${lastName}`
  }

  const getNameInitials = (item) => {
    if (item?.DonorName) {
      return item?.DonorName.substring(0, 1).toUpperCase()
    }
    return item?.DonorFirstName.substring(0, 1).toUpperCase()
  }

  return (
    <Card className="t-w-full">
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <div className="t-h-80">
          {data?.length === 0 && (
            <div className="t-flex t-justify-center t-items-center t-h-full t-w-full">
              No Donations
            </div>
          )}
          {data.length > 0 && (
            <InfiniteScrollContainer
              data={data}
              current={current}
              setCurrent={setCurrent}
              height="265px"
              itemsFirstLoad={config.rowsPerPage}
            >
              <>
                {current.map((item, index) => {
                  const includeEventCost = item?.contributeToEventCost
                  return (
                    <div
                      key={index}
                      className={`t-w-full t-rounded-2xl t-h-12 t-p-3 ${
                        true ? 't-bg-yellow-100' : 't-bg-red-100'
                      } t-text-secondary-100 t-mb-2 t-flex t-items-center t-shadow-md t-justify-between`}
                    >
                      <div className="t-flex t-space-x-2 t-items-center">
                        <div className="t-w-8 t-h-8 t-rounded-full t-flex t-items-center t-justify-center t-bg-primary-100 t-p-2 t-shadow-lg t-text-white t-font-bold">
                          {item.ItemType === 'AuctionItem'
                            ? item?.winnerName?.substring(0, 1).toUpperCase()
                            : getDonorName(item)
                            ? getNameInitials(item)
                            : ''}
                        </div>
                        <h1 className="t-font-bold t-text-xs md:t-text-sm">
                          {item.ItemType === 'AuctionItem'
                            ? `${item.winnerName} won an auction item`
                            : item.PaymentMethod === 'Pledge'
                            ? `${
                                item.IsDonatingAnonymously
                                  ? 'Anonymous'
                                  : getDonorName(item)
                              } made a pledge`
                            : `${
                                item.IsDonatingAnonymously
                                  ? 'Anonymous'
                                  : getDonorName(item)
                              } donated via ${PaymentMethodText(
                                item.PaymentMethod,
                              )}`}
                        </h1>
                      </div>
                      <div className="t-flex t-flex-col">
                        {item.ItemType === 'AuctionItem' ? (
                          <h2 className="t-font-bold t-text-secondary-100 t-text-right t-text-xs md:t-text-sm">
                            {item?.winnerBid && formatAmount(item?.winnerBid)}
                          </h2>
                        ) : (
                          <h2 className="t-font-bold t-text-secondary-100 t-text-right t-text-xs md:t-text-sm">
                            {includeEventCost
                              ? item?.DonatedAmount
                                ? formatAmount(item?.DonatedAmount)
                                : formatAmount(item.Amount)
                              : formatAmount(item.Amount)}
                          </h2>
                        )}
                        {item.ItemType !== 'AuctionItem' && (
                          <h2 className="t-text-gray-800 t-italic t-text-xs md:t-text-sm t-whitespace-nowrap t-text-right">
                            {moment(moment.utc(item.PaymentDate))
                              .local()
                              .fromNow()}
                          </h2>
                        )}
                      </div>
                    </div>
                  )
                })}
              </>
            </InfiniteScrollContainer>
          )}
        </div>
      </Skeleton>
    </Card>
  )
}

export { Donations }

import React from 'react'
import { Card } from 'antd'

const MyEarnings = () => (
  <Card
    className="header-solid"
    bordered={false}
    title={
      <h1 className="t-font-bold t-text-lg t-text-secondary-100">
        My Business Revenue
      </h1>
    }
  >
    <div className="t-h-96  t-flex t-justify-center t-items-center">
      Content
    </div>
  </Card>
)

export { MyEarnings }

import React, { useEffect } from 'react'
import { Form, Select, Col, Row, Button, Divider } from 'antd'
import * as Yup from 'yup'
import { BsCheckCircleFill } from 'react-icons/bs'
import { formatUSAPhoneNumber, MaskPattern } from 'utils'
import MaskedInput from 'antd-mask-input'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const phoneNumberValidation = '^[0-9-() ]{14,15}$'

const schema = Yup.object().shape({
  countryCode: Yup.string().required('Required'),
  phoneNumber: Yup.string()
    .required('Please input your phone number!')
    .matches(phoneNumberValidation, 'Invalid phone number')
    .typeError('Invalid phone number'),
  termsAndCondition: Yup.boolean().oneOf(
    [true],
    'Please accept Terms and Condtions to proceed',
  ),
})

const siginValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  countryCode: '',
  phoneNumber: '',
  termsAndCondition: false,
}

const RecipetForm = ({ isLoading, onSubmit, user }) => {
  const [form] = Form.useForm()
  const history = useHistory()

  useEffect(() => {
    form.setFieldsValue({ countryCode: user?.countryCode })
    form.setFieldsValue({
      phoneNumber: user?.phoneNumber
        ? formatUSAPhoneNumber(user?.phoneNumber)
        : null,
    })
    // eslint-disable-next-line
  }, [user])

  const handleInputChange = (value) => {
    form.setFieldsValue({ phoneNumber: value })
  }

  const onFormSubmit = async (values) => {
    const formatedPhoneNumber = values.phoneNumber?.replace(/\D/g, '')
    const trimPhoneNumber = formatedPhoneNumber.replace(/^0+/, '')
    const phone = values.countryCode + trimPhoneNumber
    onSubmit(phone)
  }

  return (
    <div>
      <div>
        <div className="t-flex t-flex-col t-space-y-2 t-items-center t-justify-center t-py-4">
          <BsCheckCircleFill fontSize="3rem" className="t-text-green-500" />
          <h1 className="t-font-semibold">
            Your donation is successfully submitted
          </h1>
        </div>
        <Divider />
        <p className="t-font-bold t-text-lg t-my-4">
          To generate donation receipts, please finish your account setup by
          providing your mobile number below.
        </p>
      </div>
      <Form
        form={form}
        layout="vertical"
        key={0}
        onFinish={onFormSubmit}
        name="donation"
        scrollToFirstError
        initialValues={initialValues}
      >
        <Row gutter={[24, 0]}>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="countryCode"
              label="Country Code"
              rules={[siginValidation]}
            >
              <Select
                placeholder="Select Country Code"
                style={{ width: '100%' }}
              >
                <Select.Option value="+1">
                  United States/Canada (+1)
                </Select.Option>
                <Select.Option value="+54">Argentina (+54)</Select.Option>
                <Select.Option value="+92">Pakistan (+92)</Select.Option>
                <Select.Option value="+55">Brazil (+55)</Select.Option>
                <Select.Option value="+58">
                  Venezuela, Bolivarian Republic of Venezuela (+58)
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
              rules={[siginValidation]}
            >
              <MaskedInput
                inputMode="numeric"
                mask={MaskPattern}
                onChange={(e) => {
                  handleInputChange(e.maskedValue)
                }}
              />
            </Form.Item>
          </Col>
          <div className="t-w-full t-flex t-justify-start md:t-justify-end t-space-x-1">
            <Button
              type="ghost"
              className="t-w-[50%] md:t-w-auto"
              onClick={() => {
                if (window.opener && window.opener === window) {
                  window.opener = null
                  window.open('', '_self')
                  window.close()
                } else {
                  history.push('/')
                }
              }}
            >
              Done
            </Button>
            <Button
              loading={isLoading}
              type="primary"
              className="t-w-[50%] md:t-w-auto"
              htmlType="submit"
              onClick={() => {}}
            >
              Receive OTP
            </Button>
          </div>
        </Row>
      </Form>
    </div>
  )
}

export { RecipetForm }

import React, { useEffect } from 'react'
import { Skeleton } from 'antd'
import { useDispatch } from 'react-redux'
import { useAuthSelector } from 'store/authSlice/authReducer'
import {
  getGlobalSubscriptionCharges,
  getOrganizatinPlatformSubscription,
  getOrganizatinUsageCharges,
} from 'store/platformSubscriptionSlice/platformSubscriptionActions'
import { usePlatformSubscriptionSelector } from 'store/platformSubscriptionSlice/platformSubscriptionReducer'
import { useHardwareSelector } from 'store/hardwareSlice/hardwareReducer'
import { getHardwareList } from 'store/hardwareSlice/hardwareActions'
import { CreditCardSubscription } from './CreditCardSubscription'
import { SmsAndEmailUsage } from './SmsAndEmailUsage'
import { SubscriptionDetails } from './SubscriptionDetails'
import { OrganizationHardware } from './OrganizationHardware'
import { BillingDetails } from './BillingDetails'
import { getBillDetails, getHardwareTotal } from './helper'

const ManageSubscription = () => {
  const dispatch = useDispatch()
  const { organization } = useAuthSelector()
  const {
    loading,
    platformSubscription,
    usageCharges,
    globalSubscriptionCharges,
  } = usePlatformSubscriptionSelector()
  const { loading: hardwareLoading, hardwareList } = useHardwareSelector()
  const { fetchHardwareList } = hardwareLoading

  const organizationId = organization?.id
  useEffect(() => {
    dispatch(getGlobalSubscriptionCharges({ organizationId }))
    dispatch(getOrganizatinPlatformSubscription({ organizationId }))
    dispatch(getOrganizatinUsageCharges({ organizationId }))
    dispatch(
      getHardwareList({
        onlyOrganization: true,
        organizationId,
      }),
    )
    // eslint-disable-next-line
  }, [])

  const hardwareTotal = getHardwareTotal(hardwareList)

  const { billingTotal, billingDetails } = getBillDetails({
    globalSubscriptionCharges,
    usageCharges,
    hardwareTotal,
  })
  return (
    <div className="t-space-y-2">
      <Skeleton
        loading={
          loading?.getPlatformSubscription ||
          loading?.getGlobalSubscriptionCharges ||
          loading?.getUsageCharges ||
          fetchHardwareList
        }
        active
        paragraph={{ rows: 18 }}
      >
        <div className="t-space-y-4 t-pb-4">
          {platformSubscription &&
          platformSubscription?.organization?.SubscriptionStatus ===
            'Active' ? (
            <>
              <SubscriptionDetails totalDue={billingTotal} />
              <BillingDetails billingDetails={billingDetails} />
              {usageCharges && Array.isArray(usageCharges) && (
                <SmsAndEmailUsage usageCharges={usageCharges} />
              )}
              <OrganizationHardware
                formatedHardwareList={[
                  ...hardwareList,
                  {
                    HardwareType: 'Total',
                    MonthlyCost: hardwareTotal,
                  },
                ]}
              />
            </>
          ) : (
            <CreditCardSubscription />
          )}
        </div>
      </Skeleton>
    </div>
  )
}

export { ManageSubscription }

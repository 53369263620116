import React, { useEffect, useState } from 'react'
import { Input, Form, Row, Col, Select, Card } from 'antd'
import MaskedInput from 'antd-mask-input'
import {
  MaskPattern,
  getCodeAndNumberFromPhoneNumber,
  getSplitNamesFromFullName,
} from 'utils'
import { donationValidation } from 'validations'
import { AutoCompleteAddress } from 'components/common/unAuthenticatedPayments'
import { useAuthSelector } from 'store/authSlice/authReducer'

const UserForm = ({ form, isShowMailingAddress, itemType, item }) => {
  const { user } = useAuthSelector()
  const [authenticatedUser, setAuthenticatedUser] = useState(false)
  const [phoneNumberInitialized, setPhoneNumberInitialized] = useState(false)

  const setUserInfo = ({ firstname, lastname, email, phone }) => {
    if (firstname) form.setFieldValue('firstName', firstname)
    if (lastname) form.setFieldValue('lastName', lastname)
    if (email) form.setFieldValue('email', email)
    if (phone) {
      const { valid, countryCode, phoneNumber } =
        getCodeAndNumberFromPhoneNumber(phone)

      if (valid) {
        form.setFieldsValue({
          countryCode,
          phoneNumber,
        })
      }
    }
  }

  useEffect(() => {
    if (isMigratedDonation) {
      const {
        RecurringAmount,
        RecurringMonths,
        DonorName,
        DonorEmail,
        DonorPhoneNumber,
      } = item

      if (RecurringAmount) form.setFieldValue('askedAmount', RecurringAmount)
      if (RecurringMonths) {
        form.setFieldValue('repeatnoOfMonths', RecurringMonths)
      }

      const { firstname, lastname } = getSplitNamesFromFullName(DonorName)
      setUserInfo({
        firstname,
        lastname,
        email: DonorEmail,
        phone: DonorPhoneNumber,
      })

      setPhoneNumberInitialized(true)
    } else if (isPledgeConversion) {
      setAuthenticatedUser(true)
      const { Amount, DonorName, DonorEmail, DonorPhoneNumber } = item

      if (Amount) form.setFieldValue('askedAmount', Amount)

      const { firstname, lastname } = getSplitNamesFromFullName(DonorName)
      setUserInfo({
        firstname,
        lastname,
        email: DonorEmail,
        phone: DonorPhoneNumber,
      })
    } else if (user) {
      setAuthenticatedUser(true)
      const { firstname, lastname, email, phone } = user
      setUserInfo({ firstname, lastname, email, phone })
    }
    // eslint-disable-next-line
  }, [user, item])

  const handleInputChange = (value) => {
    form.setFieldsValue({ phoneNumber: value })
  }

  const isMigratedDonation = itemType === 'migrated-donation'
  const isPledgeConversion = itemType === 'pledge-conversion'
  const isEditable = isMigratedDonation ? false : authenticatedUser
  const isZakaatService =
    itemType === 'service-payment' && item?.ServiceType === 'Zakaat'
  const isClassProgram =
    itemType === 'program-payment' && item?.ProgramType === 'Class'

  return (
    <Card
      className="header-solid t-p-2 md:t-p-10 t-m-4 t-shadow-2xl t-rounded-lg"
      bordered={false}
    >
      <Row gutter={[24, 0]}>
        {isClassProgram && (
          <Col span={24}>
            <h3 className="t-mb-4 t-text-lg t-font-bold">
              Parent / Guardian Information
            </h3>
          </Col>
        )}
        <Col xl={12} md={12} xs={24} sm={12}>
          <Form.Item
            name="firstName"
            label="First Name *"
            rules={[donationValidation]}
            className="t-mb-[12px] md:t-mb-[24px]"
          >
            <Input placeholder="First Name" disabled={isEditable} />
          </Form.Item>
        </Col>
        <Col xl={12} md={12} xs={24} sm={12}>
          <Form.Item
            name="lastName"
            label="Last Name *"
            rules={[donationValidation]}
            className="t-mb-[12px] md:t-mb-[24px]"
          >
            <Input placeholder="Last Name" disabled={isEditable} />
          </Form.Item>
        </Col>
        <Col xl={12} md={12} xs={24} sm={12}>
          <Form.Item
            name="countryCode"
            label="Country Code"
            rules={[donationValidation]}
            className="t-mb-[12px] md:t-mb-[24px]"
          >
            <Select
              placeholder="Select Country Code"
              style={{ width: '100%' }}
              disabled={isEditable}
            >
              <Select.Option value="+1">
                United States/Canada (+1)
              </Select.Option>
              <Select.Option value="+54">Argentina (+54)</Select.Option>
              <Select.Option value="+92">Pakistan (+92)</Select.Option>
              <Select.Option value="+55">Brazil (+55)</Select.Option>
              <Select.Option value="+58">
                Venezuela, Bolivarian Republic of Venezuela (+58)
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xl={12} md={12} xs={24} sm={12}>
          <Form.Item
            name="phoneNumber"
            label="Mobile Number *"
            rules={[donationValidation]}
            className="t-mb-[12px] md:t-mb-[24px]"
          >
            {isEditable ? (
              <Input disabled={isEditable} />
            ) : (
              <>
                {(!isMigratedDonation || phoneNumberInitialized) && (
                  <MaskedInput
                    defaultValue={form.getFieldValue('phoneNumber')}
                    inputMode="numeric"
                    mask={MaskPattern}
                    onChange={(e) => {
                      handleInputChange(e.unmaskedValue)
                    }}
                  />
                )}
              </>
            )}
          </Form.Item>
        </Col>
        <Col xl={12} md={12} xs={24} sm={12}>
          <Form.Item
            name="email"
            label="Email *"
            rules={[donationValidation]}
            className="t-mb-[12px] md:t-mb-[24px]"
          >
            <Input placeholder="Email" disabled={authenticatedUser} />
          </Form.Item>
        </Col>
        {isShowMailingAddress && (
          <AutoCompleteAddress form={form} validation={isZakaatService} />
        )}
      </Row>
    </Card>
  )
}

export { UserForm }

import React, { useEffect, useState } from 'react'
import {
  Card,
  Input,
  Table,
  Tooltip,
  Button,
  Modal,
  Skeleton,
  message,
  Spin,
} from 'antd'
import { MdOutlineSimCardDownload } from 'react-icons/md'
import { SearchOutlined } from '@ant-design/icons'
import { downloadCSV, formatAmount, formateDateTimeToLocal } from 'utils'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { useProgramSelector } from 'store/programSlice/programReducer'
import { makeRequest } from 'api'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { config } from 'configs'

const ClassRegistration = () => {
  const history = useHistory()
  const [searchText, setSearchText] = useState('')
  const { loading, organizationPrograms } = useProgramSelector()
  const [viewModal, setViewModal] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [detailView, setDetailView] = useState(false)
  const [detailClassLoading, setDetailClassLoading] = useState(false)
  const [detailOfClass, setDetailOfClass] = useState([])
  const [reportData, setReportData] = useState([])

  const { organization } = useAuthSelector()

  const handleBack = () => {
    if (!detailView) {
      history.push('/reports')
    } else {
      setDetailView(false)
    }
  }

  const fetchClassDetail = (record) => {
    setDetailClassLoading(true)
    makeRequest('get', '/reports/class-registeration', {
      organization: organization.id,
    })
      .then((res) => {
        setDetailOfClass(
          res.results.filter((x) => x.ProgramId === record.ProgramId),
        )
        setDetailClassLoading(false)
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
        setDetailClassLoading(false)
      })
  }

  const handleViewDetails = (record) => {
    setSelectedRecord(record)
    setViewModal(true)
  }

  const handleClassDetails = (record) => {
    setDetailView(true)
    fetchClassDetail(record)
  }

  const searchYTDDonationsRows = (data, searchData) => {
    if (!searchData) return data
    return data.filter(
      (item) =>
        item.Title?.toLowerCase().includes(searchData.toLowerCase()) ||
        item.UserName?.toLowerCase().includes(searchData.toLowerCase()) ||
        item.Name?.toLowerCase().includes(searchData.toLowerCase()),
    )
  }

  const columns = !detailView
    ? [
        {
          title: 'Class Name',
          dataIndex: 'Title',
          className: 'ant-table-row-cell-break-word t-w-[37%]',
        },
        {
          title: 'Status',
          dataIndex: 'ActiveInd',
          className: 'ant-table-row-cell-break-word t-w-[37%]',
          render: (_, record) =>
            record.ActiveInd === 1 ? 'Active' : 'Inactive',
        },
        {
          title: 'Action',
          key: 'action',
          className: 'ant-table-row-cell-break-word t-w-[26%]',
          render: (_, record) => (
            <Button type="primary" onClick={() => handleClassDetails(record)}>
              View Details
            </Button>
          ),
        },
      ]
    : [
        {
          title: 'Parent Name',
          dataIndex: 'UserName',
          className: 'ant-table-row-cell-break-word',
        },
        {
          title: 'Student Name',
          dataIndex: 'Name',
          className: 'ant-table-row-cell-break-word',
        },
        {
          title: 'Payment Amount',
          dataIndex: 'Amount',
          className: 'ant-table-row-cell-break-word',
          render: (Amount) => formatAmount(Amount),
        },
        {
          title: 'Registration Date',
          dataIndex: 'CreatedDate',
          className: 'ant-table-row-cell-break-word',
          render: (CreatedDate) =>
            formateDateTimeToLocal(CreatedDate, 'datetime'),
          sorter: (a, b) => {
            const dateA = new Date(a.CreatedDate)
            const dateB = new Date(b.CreatedDate)
            return dateB - dateA
          },
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Action',
          key: 'action',
          className: 'ant-table-row-cell-break-word',
          render: (_, record) => (
            <Button type="primary" onClick={() => handleViewDetails(record)}>
              View Details
            </Button>
          ),
        },
      ]

  const csvColumns = !detailView
    ? ['Title', 'Status', 'ProgramFees', 'RegistrationDate', 'RegistrationTime']
    : [
        'UserName',
        'UserPhoneNumber',
        'UserEmail',
        'Name',
        'Age',
        'Gender',
        'Instructions',
        'Amount',
        'RegistrationDate',
        'RegistrationTime',
      ]

  const makeClassReportCsvData = () => {
    if (detailView) {
      if (detailOfClass.length > 0) {
        const updatedData = detailOfClass.map((element) => {
          const dateData = formateDateTimeToLocal(element.CreatedDate, 'date')
          const timeData = formateDateTimeToLocal(element.CreatedDate, 'time')
          return {
            ...element,
            RegistrationDate: dateData,
            RegistrationTime: timeData,
          }
        })
        setReportData(updatedData)
      }
    } else {
      const classData = organizationPrograms
        .filter((x) => x.ProgramType === 'Class')
        .map((element) => {
          const dateData = formateDateTimeToLocal(element.CreatedDate, 'date')
          const timeData = formateDateTimeToLocal(element.CreatedDate, 'time')
          return {
            ...element,
            RegistrationDate: dateData,
            RegistrationTime: timeData,
            Status: element.ActiveInd === 1 ? 'Active' : 'Inactive',
          }
        })
      setReportData(classData)
    }
  }

  useEffect(() => {
    makeClassReportCsvData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailOfClass, organizationPrograms, detailView])

  const csvHeaderMapping = !detailView
    ? {
        Title: 'Class Name',
        Status: 'Status',
        ProgramFees: 'Program Fees',
        RegistrationDate: 'Class Created Date',
        RegistrationTime: 'Class Created Time',
      }
    : {
        UserName: 'Parent Name',
        UserPhoneNumber: 'Parent Phone Number',
        UserEmail: 'Parent Email Address',
        Name: 'Student Name',
        Age: 'Age',
        Gender: 'Gender',
        Instructions: 'Special Instruction',
        Amount: 'Payment Amount',
        RegistrationDate: 'Registration Date',
        RegistrationTime: 'Registration Time',
      }

  return (
    <>
      <Card
        className="header-solid"
        bordered={false}
        title={
          <div className="t-flex t-flex-wrap t-justify-between">
            <div className="t-flex t-space-x-4">
              <IoMdArrowRoundBack
                fontSize="1.5rem"
                className="t-cursor-pointer t-min-w-[1.5rem]"
                id="back"
                onClick={() => handleBack()}
              />
              <h5 className="font-semibold">Class Registration Report</h5>
            </div>
            <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
              <div className="header-control t-mt-0">
                <Input
                  className="header-search t-w-36"
                  placeholder="Search ..."
                  prefix={<SearchOutlined />}
                  onChange={(e) => {
                    setSearchText(e.target.value)
                  }}
                />
              </div>
              {detailClassLoading ? (
                <Spin />
              ) : (
                <Tooltip title="Download">
                  <MdOutlineSimCardDownload
                    fontSize="1.7rem"
                    id="download"
                    className="t-text-secondary-100 t-cursor-pointer"
                    onClick={() =>
                      downloadCSV(
                        reportData,
                        csvColumns,
                        csvHeaderMapping,
                        'ClassReport.csv',
                      )
                    }
                  />
                </Tooltip>
              )}
            </div>
          </div>
        }
      >
        <Skeleton
          loading={loading || detailClassLoading}
          active
          paragraph={{ rows: 18 }}
        >
          {!detailView ? (
            <Table
              tableLayout="auto"
              scroll={{ x: 500 }}
              columns={columns}
              dataSource={searchYTDDonationsRows(
                organizationPrograms.filter((x) => x.ProgramType === 'Class'),
                searchText,
              )}
              bordered={false}
              pagination={false}
            />
          ) : (
            <Table
              tableLayout="auto"
              scroll={{ x: 500 }}
              columns={columns}
              dataSource={searchYTDDonationsRows(detailOfClass, searchText)}
              bordered={false}
              pagination={false}
            />
          )}
        </Skeleton>
      </Card>
      {viewModal && (
        <Modal
          title="Class Details"
          open={viewModal}
          onCancel={() => setViewModal(false)}
          footer={null}
        >
          {selectedRecord && (
            <>
              <p className="t-text-base">
                <strong>Parent Name:</strong> {selectedRecord.UserName}
              </p>
              <p className="t-text-base">
                <strong>Parent Phone Number:</strong>{' '}
                {selectedRecord.UserPhoneNumber}
              </p>
              <p className="t-text-base">
                <strong> Parent Email Address:</strong>{' '}
                {selectedRecord.UserEmail}
              </p>
              <p className="t-text-base">
                <strong> Student Name </strong> {selectedRecord.Name}
              </p>
              <p className="t-text-base">
                <strong>Age:</strong> {selectedRecord.Age}
              </p>
              <p className="t-text-base">
                <strong>Gender:</strong> {selectedRecord.Gender}
              </p>
              <p className="t-text-base">
                <strong>Special Instructions:</strong>{' '}
                {selectedRecord.Instructions}
              </p>
            </>
          )}
        </Modal>
      )}
    </>
  )
}

export { ClassRegistration }

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import Api from 'api'
import { formatAmount } from 'utils'

function EventStatisticsReport({ id, type, setPdfData }) {
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()

  useEffect(() => {
    setLoading(true)
    Api.get(`adminReports/eventStats/${id}`)
      .then((res) => {
        setReport(res.results)
        setPdfData(res.results)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  const isEvent = type && type === 'Event'
  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      {report ? (
        <div className="t-space-y-4">
          <div className="t-flex t-items-center t-justify-between">
            <p>% of Goal Achieved</p>
            {report?.goalAchieved}
          </div>
          {isEvent && (
            <div className="t-flex t-items-center t-justify-between">
              <p>Ratio of Donation to Pledges</p>
              {report?.ratioOfDonationsToPedges}
            </div>
          )}
          <div className="t-flex t-items-center t-justify-between">
            <p>% of Cash Donation</p>
            {report?.percentageOfCashDonations}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>% of Card Donation:</p>
            {report?.percentageOfCreditCardDonations}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>% of Bank Donation:</p>
            {report?.percentageOfBankDebitDonations}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>% of Check Donation</p>
            {report?.percentageOfCheckDonations}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Average Donation Size</p>
            {formatAmount(report?.averageDonations)}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>% of Remote Donation</p>
            {report?.percentageOfRemoteDonations}
          </div>
        </div>
      ) : (
        <div>No Data Found</div>
      )}
    </Skeleton>
  )
}

export default EventStatisticsReport

import React, { useState } from 'react'
import { ComponentView } from 'components/common/componentView'
import {
  // EventList,
  // FavouriteList,
  InactiveList,
  MyEvents,
  // PresenterEvents,
} from './components/EventLists'

const Events = () => {
  const [searchText, setSearchText] = useState(null)
  const [sortSwitchValue, setSortSwitchValue] = useState(false)
  const [sortKey, setSortKey] = useState('StartDate')

  const heading = 'Events'
  const optionList = {
    // all: <EventList searchText={searchText} sortKey={sortKey} />,
    my: <MyEvents searchText={searchText} sortKey={sortKey} />,
    inactive: <InactiveList searchText={searchText} sortKey={sortKey} />,
    // favorite: <FavouriteList searchText={searchText} sortKey={sortKey} />,
    // presenter: <PresenterEvents searchText={searchText} sortKey={sortKey} />,
  }
  // const createButtonLink = '/settings/create-event'

  const sortFilterData = [
    {
      label: 'By Created Date',
      value: 'createdDate',
      showOn: ['all', 'my', 'inactive', 'presenter', 'favorite'],
    },
    {
      label: 'By Start Date',
      value: 'StartDate',
      showOn: ['all', 'my', 'inactive', 'presenter', 'favorite'],
    },
    {
      label: 'By Favorite Date',
      value: 'favDate',
      showOn: ['favorite'],
    },
  ]

  return (
    <ComponentView
      heading={heading}
      optionList={optionList}
      // createButtonLink={createButtonLink}
      setSearchText={setSearchText}
      sortFilterData={sortFilterData}
      sortKey={sortKey}
      setSortKey={setSortKey}
      sortSwitchValue={sortSwitchValue}
      setSortSwitchValue={setSortSwitchValue}
    />
  )
}

export { Events }

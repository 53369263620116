import React, { useState } from 'react'
import { Input, Button, Card, Form, Row, Col, message } from 'antd'
import * as Yup from 'yup'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { sendContactUsForm } from 'store/authSlice/authActions'
import { useHistory } from 'react-router-dom'

const schema = Yup.object().shape({
  Subject: Yup.string().required('Required'),
  Message: Yup.string().required('Required'),
})

const needHelpValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  Subject: '',
  Message: '',
}

const ContactUsForm = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const { user } = useAuthSelector()
  const [form] = Form.useForm()

  const onFinish = async (values) => {
    const payload = {
      ...values,
      Name: `${user?.firstname} ${user?.lastname}`,
      Email: user?.email,
      Phone: user?.phone,
      UserId: user?.id,
    }
    setLoading(true)
    try {
      await sendContactUsForm(payload)
      message.success('Email Sent Successfully')
      history.goBack()
      setLoading(false)
    } catch (error) {
      message.error('Could not able to send the message')
      setLoading(false)
    }
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0">Contact Us</h6>
          <p className="font-regular">Enter details of your query</p>
        </>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        key={0}
        onFinish={onFinish}
        name="organization"
        initialValues={initialValues}
        scrollToFirstError
      >
        <Row gutter={[24, 0]}>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="Subject"
              label="Subject"
              colon={false}
              rules={[needHelpValidation]}
            >
              <Input placeholder="Enter Subject" />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="Message"
              label="Message"
              colon={false}
              rules={[needHelpValidation]}
            >
              <Input.TextArea
                placeholder="Add the message detail here"
                autoSize={{ minRows: 8 }}
              />
            </Form.Item>
          </Col>

          <Col span={24} className="text-left">
            <Button
              loading={loading}
              type="primary"
              className="px-25"
              htmlType="submit"
            >
              Send Message
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export { ContactUsForm }

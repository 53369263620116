import Api from 'api'

export const generatePdfAndEmail = async (payload) => {
  const response = await Api.put('/adminReports/generatePdfandEmail', payload)
  return response
}
export const convertFile = async (base64, fileName) => {
  const url = `data:application/pdf;base64,${base64}`
  const blob = await fetch(url).then((res) => res.blob())
  const blobUrl = URL.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.download = fileName
  anchor.href = blobUrl
  anchor.click()
}
// export const convertFile = async (base64, fileName, fileType) => {
//   const fileExt = `.${contentType.substring(contentType.indexOf('/') + 1)}`
//   const blob = await base64toBlob(base64, fileType)
//   const blobUrl = URL.createObjectURL(blob)
//   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//     window.navigator.msSaveOrOpenBlob(blob, fileName + fileExt)
//   } else {
//   const a = document.createElement('a')
//   a.href = blobUrl
//   a.download = fileName
//   document.body.appendChild(a)
//   a.click()
//   document.body.removeChild(a)
//   }
// }

// const base64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
//   const byteCharacters = atob(b64Data)
//   const byteArrays = []
//   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//     const slice = byteCharacters.slice(offset, offset + sliceSize)
//     const byteNumbers = new Array(slice.length)
//     for (let i = 0; i < slice.length; i += i) {
//       byteNumbers[i] = slice.charCodeAt(i)
//     }
//     const byteArray = new Uint8Array(byteNumbers)
//     byteArrays.push(byteArray)
//   }
//   const blob = new Blob(byteArrays, { type: contentType })
//   return blob
// }

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Stepper1 } from '../Stepper/Stepper'
import { Modal, Table, Tabs } from 'antd'
import { formatAmountWithOutDecimal } from 'utils'

const MonthlyEarning = ({ monthlyEarn, netGross }) => {
  const [performer, setPerformer] = useState([])
  const [earn, setEarner] = useState([])
  const [isModal, setIsModal] = useState(false)
  const [modalData, setModalData] = useState([])
  const [isModalVisible2ndCard, setIsModalVisible2ndCard] = useState(false)

  const showModal2ndCard = () => {
    setIsModalVisible2ndCard(true)
  }

  const handleOk2ndCard = () => {
    setIsModalVisible2ndCard(false)
  }

  const handleCancel2ndCard = () => {
    setIsModalVisible2ndCard(false)
  }

  //  Formating the Date
  function formatDateTime(dateString) {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }

    const formattedDateTime = new Date(dateString).toLocaleString(
      'en-US',
      options,
    )
    return formattedDateTime
  }
  useEffect(() => {
    setPerformer(monthlyEarn?.topPerformer?.ProgramAndService)
    setEarner(monthlyEarn?.earnings?.total?.transactionsList)
  }, [monthlyEarn])

  const handleProgramDetails = (item) => {
    setIsModal(!isModal)
    setModalData(item.transactionsList)
    // setLoading(true)
    // makeRequest('get', `component/program/${item.Id}`)
    //   .then((res) => {
    //     setModalData(res.results)
    //     setItemAmount(item.amount)
    //     setLoading(false)
    //   })
    //   .catch((error) => {
    //     console.error('Error', error)
    //     setLoading(false)
    //   })
  }

  const columns = [
    {
      title: 'Event Title',
      dataIndex: 'Title',
      key: 'Title',
    },
    {
      title: 'Full Name',
      dataIndex: 'UserName',
      key: 'UserName',
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      render: (text, record) => {
        const amountToShow = netGross ? record.NetAmount : record.GrossAmount
        return formatAmountWithOutDecimal(amountToShow)
      },
    },
    {
      title: 'Payment Method',
      dataIndex: 'PaymentMethod',
      key: 'PaymentMethod',
    },
    {
      title: 'Payment Reference No',
      dataIndex: 'PaymentReferenceNumber',
      key: 'PaymentReferenceNumber',
    },
    {
      title: 'Payment Date',
      dataIndex: 'PaymentDate',
      key: `PaymentDate`,
      render: (text, record) => formatDateTime(record.PaymentDate),
    },
  ]

  const columns1 = [
    {
      title: 'Event Title',
      dataIndex: 'Title',
      key: 'Title',
    },
    {
      title: 'Full Name',
      dataIndex: 'UserName',
      key: 'UserName',
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      render: (text, record) => {
        const amountToShow = netGross ? record.NetAmount : record.GrossAmount
        return formatAmountWithOutDecimal(amountToShow)
      },
    },
    {
      title: 'Payment Date',
      dataIndex: 'PaymentDate',
      key: `PaymentDate`,
      render: (text, record) => formatDateTime(record.PaymentDate),
    },
  ]

  return (
    <>
      <div className="t-grid md:t-grid-cols-2 t-grid-cols-1 t-gap-4 t-mt-8 t-px-2">
        <div className="t-rounded-lg t-justify-between t-bg-white t-border t-border-gray-300 t-mb-4">
          {/* left div */}
          <div className="t-pl-2 sm:t-pl-4 md:t-pl-6 t-pt-10 t-pb-6">
            {earn?.length <= 0 || !earn ? (
              <div className="t-text-center t-font-normal t-text-lg">
                No Earning!
              </div>
            ) : (
              earn
                ?.slice(0, 6)
                ?.map((item, index) => (
                  <Stepper1 item={item} key={index} netGross={netGross} />
                ))
            )}
          </div>
          <hr />
          <button
            onClick={showModal2ndCard}
            className="t-text-black t-border t-border-gray-400 t-float-right t-my-4 t-mr-11 t-px-4 t-py-2 t-rounded-md t-text-sm hover:t-border-cyan-500 hover:t-text-cyan-500"
          >
            See Full List
          </button>
        </div>
        {/* Right div */}
        <div className="t-rounded-lg">
          <Tabs
            destroyInactiveTabPane
            className="t-w-full sliding-tab t-bg-white"
            defaultActiveKey="1"
            animated={false}
          >
            <Tabs.TabPane tab="Programs" key="1">
              {performer?.filter((x) => x.Title === 'Program').length <= 0 ? (
                <div className="t-text-center t-font-normal t-text-lg t-bg-white t-py-12 t-border t-border-gray-300 t-rounded-md">
                  Programs is not Available
                </div>
              ) : (
                performer
                  ?.filter((x) => x.Title === 'Program')
                  .map((item) => {
                    return (
                      <div
                        key={item.Id}
                        className="t-col-span-1 t-row-span-1 p-4 t-rounded-lg t-cursor-pointer t-bg-white t-text-black t-mb-5 t-border t-border-gray-300"
                      >
                        <h2 className="t-text-base t-pl-4 t-pt-4 t-font-bold t-text-gray-600">
                          {item.name}
                        </h2>
                        <div className="t-flex t-flex-row t-justify-between">
                          <h1 className="t-text-2xl t-pl-4 t-pt-1 t-pb-2 t-font-bold t-text-gray-700">
                            {netGross
                              ? formatAmountWithOutDecimal(item.NetAmount)
                              : formatAmountWithOutDecimal(item.GrossAmount)}
                          </h1>
                          <button
                            onClick={() => handleProgramDetails(item)}
                            className="t-bg-white t-border t-border-gray-500 t-px-6 t-py-1 t-shadow-sm t-shadow-gray-300 t-text-black t-text-sm t-mb-7 t-mr-9 t-rounded-md hover:t-border-cyan-500 hover:t-text-cyan-500"
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    )
                  })
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Services" key="2">
              {performer?.filter((x) => x.Title === 'Service').length <= 0 ? (
                <div className="t-text-center t-font-normal t-text-lg t-bg-white t-py-12 t-border t-border-gray-300 t-rounded-md">
                  Services is not Available
                </div>
              ) : (
                performer
                  ?.filter((x) => x.Title === 'Service')
                  .map((item) => {
                    return (
                      <div
                        key={item?.Id}
                        className="t-col-span-1 t-row-span-1 p-4 t-rounded-lg t-cursor-pointer t-bg-white t-text-black t-mb-5 t-border t-border-gray-300"
                      >
                        <h2 className="t-text-base t-pl-4 t-pt-4 t-font-bold t-text-gray-600">
                          {item.name}
                        </h2>
                        <div className="t-flex t-flex-row t-justify-between">
                          <h1 className="t-text-2xl t-pl-4 t-pt-1 t-pb-2 t-font-bold t-text-gray-700">
                            {netGross
                              ? formatAmountWithOutDecimal(item.NetAmount)
                              : formatAmountWithOutDecimal(item.GrossAmount)}
                          </h1>
                          <button
                            onClick={() => handleProgramDetails(item)}
                            className="t-bg-white t-border t-border-gray-500 t-px-6 t-py-1 t-shadow-sm t-shadow-gray-300 t-text-black t-text-sm t-mb-7 t-mr-9 t-rounded-md hover:t-border-cyan-500 hover:t-text-cyan-500"
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    )
                  })
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Rentals" key="3">
              {performer?.filter((x) => x.Title === 'Rental').length <= 0 ? (
                <div className="t-text-center t-font-normal t-text-lg t-bg-white t-py-12 t-border t-border-gray-300 t-rounded-md">
                  Rentals is not Available
                </div>
              ) : (
                performer
                  ?.filter((x) => x.Title === 'Rental')
                  .map((item) => {
                    return (
                      <div
                        key={item?.Id}
                        className="t-col-span-1 t-row-span-1 p-4 t-rounded-lg t-cursor-pointer t-bg-white t-text-black t-mb-5 t-border t-border-gray-300"
                      >
                        <h2 className="t-text-base t-pl-4 t-pt-4 t-font-bold t-text-gray-600">
                          {item.name}
                        </h2>
                        <div className="t-flex t-flex-row t-justify-between">
                          <h1 className="t-text-2xl t-pl-4 t-pt-1 t-pb-2 t-font-bold t-text-gray-700">
                            {netGross
                              ? formatAmountWithOutDecimal(item.NetAmount)
                              : formatAmountWithOutDecimal(item.GrossAmount)}
                          </h1>
                          <button
                            onClick={() => handleProgramDetails(item)}
                            className="t-bg-white t-border t-border-gray-500 t-px-6 t-py-1 t-shadow-sm t-shadow-gray-300 t-text-black t-text-sm t-mb-7 t-mr-9 t-rounded-md hover:t-border-cyan-500 hover:t-text-cyan-500"
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    )
                  })
              )}
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
      <Modal
        title="Full List"
        open={isModalVisible2ndCard}
        onOk={handleOk2ndCard}
        onCancel={handleCancel2ndCard}
        width={1000}
      >
        <Table
          dataSource={earn}
          columns={columns}
          ableLayout="auto"
          scroll={{ x: 500 }}
        />
      </Modal>
      <Modal
        title="View Details"
        open={isModal}
        onCancel={() => setIsModal(false)}
        footer={null}
        width={650}
      >
        <Table
          dataSource={modalData}
          columns={columns1}
          tableLayout="auto"
          scroll={{ x: 500 }}
        />
      </Modal>
    </>
  )
}

export default MonthlyEarning

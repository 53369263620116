import React from 'react'
import { icons } from 'assets/images'

function Icon(props) {
  const { src, width, height, className, onClick } = props
  return (
    <img
      alt="icon"
      src={icons[src]}
      className={`${className}`}
      width={width}
      height={height}
      onClick={onClick}
    />
  )
}

export { Icon }

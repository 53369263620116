import React, { useEffect, useState } from 'react'
import {
  Card,
  Input,
  Skeleton,
  Spin,
  Table,
  Tooltip,
  Space,
  message,
  Typography,
  Select,
} from 'antd'
import { MdOutlineSimCardDownload } from 'react-icons/md'
import { SearchOutlined } from '@ant-design/icons'
import {
  formatAmount,
  searchYTDDonationsRows,
  downloadCSV,
  formateDateTimeToLocal,
} from 'utils'
import Api from 'api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { useAuthSelector } from 'store/authSlice/authReducer'
const { Title, Text } = Typography
const { Option } = Select

const YtdRevenueForOrganization = () => {
  const history = useHistory()
  const { organization } = useAuthSelector()
  const [loading, setLoading] = useState(true)
  const [downloadLoading, setDownloadLoading] = useState(true)
  const [searchText, setSearchText] = useState(null)
  const [data, setData] = useState([])
  const [totalDonation, setTotalDonation] = useState(0)

  const [timeFrame, setTimeFrame] = useState('year') // State to track 'Year' or 'Month' selection
  const [donationType, setDonationType] = useState('all') // State to track donation type selection
  const [selectedMonth, setSelectedMonth] = useState('January') // State to track selected month
  const [filteredData, setFilteredData] = useState([])

  const currentDate = new Date()

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const csvColumns = [
    'DonorName',
    'DonorEmail',
    'DonorPhoneNumber',
    'GrossAmount',
    'NetAmount',
    'lastDonationDate',
    'lastDonationTime',
    'type',
  ]

  const csvHeaderMapping = {
    DonorName: 'Name',
    DonorEmail: 'Email',
    DonorPhoneNumber: 'Phone Number',
    // DonorPhone: 'Donor Phone',
    GrossAmount: 'Gross',
    NetAmount: 'Net',
    lastDonationDate: 'Date',
    lastDonationTime: 'Time',
    type: 'Source',
  }

  const downloadCSVWithFormattedDateTime = () => {
    const formattedData = filteredData.map((row) => ({
      ...row,
      lastDonationDate: formateDateTimeToLocal(row.LastDonationDate, 'date'),
      lastDonationTime: formateDateTimeToLocal(row.LastDonationDate, 'time'),
    }))

    downloadCSV(
      formattedData,
      csvColumns,
      csvHeaderMapping,
      'YTD Revenue Report',
    )
  }

  const processApiResponse = (responseData) => {
    const result = {
      organization: {},
      fundraiser: {},
      campaign: {},
      event: {},
      program: {},
      service: {},
      rental: {},
      // Add other categories if needed
    }

    // Helper function to add donation data to the result
    const addDonation = (category, donation) => {
      const date = new Date(donation.LastDonationDate)
      const yearMonth = `${date.getFullYear()}-${String(
        date.getMonth() + 1,
      ).padStart(2, '0')}`

      if (!result[category][yearMonth]) {
        result[category][yearMonth] = {
          count: 0,
          totalDonatedAmount: 0,
          data: [],
        }
      }

      // eslint-disable-next-line no-plusplus
      result[category][yearMonth].count++
      result[category][yearMonth].totalDonatedAmount += donation.GrossAmount
      result[category][yearMonth].data.push(donation)
    }

    // Process each category
    Object.keys(responseData).forEach((category) => {
      // console.log(category, '======category========')
      // console.log(responseData[category], '======responseData[category]======')
      Object.values(responseData[category]).forEach((donation) => {
        addDonation(category, donation)
      })
    })

    return result
  }

  const appendAndSortByLastDonationDate = (argument) => {
    let totalAmount = 0

    const allDonations = Object.entries(argument).reduce(
      (acc, [type, category]) => {
        const donationsWithType = Object.values(category).flatMap((year) =>
          year.data.map((donation) => {
            totalAmount += donation.GrossAmount
            return { ...donation, type }
          }),
        )
        return acc.concat(donationsWithType)
      },
      [],
    )
    allDonations.sort(
      (a, b) => new Date(b.LastDonationDate) - new Date(a.LastDonationDate),
    )

    setTotalDonation(totalAmount)
    return allDonations
  }

  const filterAndSortDonationsByMonth = (argumentData, argument2Month) => {
    const monthIndex = new Date(
      `${argument2Month ?? months[currentDate.getMonth()]} 1, 2000`,
    ).getMonth()
    const filteredDonations = []
    let totalAmount = 0

    Object.entries(argumentData).forEach(([type, category]) => {
      Object.values(category).forEach((year) => {
        year.data.forEach((donation) => {
          const donationDate = new Date(donation.LastDonationDate)
          if (donationDate.getMonth() === monthIndex) {
            totalAmount += donation.GrossAmount
            filteredDonations.push({ ...donation, type })
          }
        })
      })
    })

    filteredDonations.sort(
      (a, b) => new Date(b.LastDonationDate) - new Date(a.LastDonationDate),
    )
    setTotalDonation(totalAmount)

    return filteredDonations
  }

  const filterAndSortDonationsByTypeYear = (
    dataArgument1,
    donationTypeArgument2,
  ) => {
    const filteredDonations = []
    let totalAmount = 0

    if (dataArgument1[donationTypeArgument2]) {
      Object.values(dataArgument1[donationTypeArgument2]).forEach((year) => {
        year.data.forEach((donation) => {
          totalAmount += donation.GrossAmount
          filteredDonations.push({ ...donation, type: donationTypeArgument2 })
        })
      })
    }

    filteredDonations.sort(
      (a, b) => new Date(b.LastDonationDate) - new Date(a.LastDonationDate),
    )
    setTotalDonation(totalAmount)
    return filteredDonations
  }

  const filterAndSortDonationsByTypeAndMonth = (
    dataArgument1,
    donationTypeArgument2,
    monthArgument3,
  ) => {
    const monthIndex = new Date(
      `${monthArgument3 ?? months[currentDate.getMonth()]} 1, 2000`,
    ).getMonth()
    let totalAmount = 0
    let donations = []

    if (dataArgument1[donationTypeArgument2]) {
      // Flattening the data for the specific donation type across all years
      donations = Object.values(dataArgument1[donationTypeArgument2]).flatMap(
        (year) => year.data,
      )

      // Filtering by month
      donations = donations
        .filter((donation) => {
          const donationDate = new Date(donation.LastDonationDate)
          return donationDate.getMonth() === monthIndex
        })
        .map((donation) => ({ ...donation, type: donationType }))
      // Calculate total amount
      // eslint-disable-next-line no-return-assign
      donations.forEach((donation) => (totalAmount += donation.GrossAmount))
    }

    // Sorting by LastDonationDate
    donations.sort(
      (a, b) => new Date(b.LastDonationDate) - new Date(a.LastDonationDate),
    )

    setTotalDonation(totalAmount)
    return donations
  }

  // Function to filter and aggregate data
  const getData = () => {
    Api.get(`reports/ytd-revenue-for-organization/${organization?.id}`)
      .then((res) => {
        const processedData = processApiResponse(res.response) // Assuming response data is in res.data.response
        setData(processedData)
        setTotalDonation(0) // Update state with the calculated total

        setLoading(false)
        setDownloadLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        message.error(error?.exceptionMessage)
        setLoading(false)
        setData([])
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // setGrandTotal(0)]
    if (timeFrame === 'year' && donationType === 'all') {
      const sortedDonations = appendAndSortByLastDonationDate(data)
      setFilteredData(sortedDonations)
    } else if (timeFrame === 'month' && donationType === 'all') {
      const sortedDonationsByMonth = filterAndSortDonationsByMonth(
        data,
        selectedMonth ?? months[currentDate.getMonth()],
      )
      setFilteredData(sortedDonationsByMonth)
    } else if (timeFrame === 'year' && donationType !== 'all') {
      const sortedDonationsByMonth = filterAndSortDonationsByTypeYear(
        data,
        donationType,
      )

      setFilteredData(sortedDonationsByMonth)
    } else if (timeFrame === 'month' && donationType !== 'all') {
      const sortedDonationsByMonthType = filterAndSortDonationsByTypeAndMonth(
        data,
        donationType,
        selectedMonth,
      )
      setFilteredData(sortedDonationsByMonthType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedMonth, timeFrame, donationType])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'DonorName',
      className: 'ant-table-row-cell-break-word',
      render: (name) => {
        // Check if the name is not null or undefined
        if (name) {
          // Capitalize the first letter of the name
          return name.charAt(0).toUpperCase() + name.slice(1)
        }
        return name // Return the name as is if it's null or undefined
      },
    },
    {
      title: 'Email',
      dataIndex: 'DonorEmail',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Phone Number',
      dataIndex: 'DonorPhoneNumber',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Gross',
      dataIndex: 'GrossAmount',
      className: 'ant-table-row-cell-break-word text-center align-right',
      render: (amount) => formatAmount(amount),
      align: 'center',
    },
    {
      title: 'Net',
      dataIndex: 'NetAmount',
      className: 'ant-table-row-cell-break-word text-center align-right',
      render: (amount) => formatAmount(amount),
      align: 'center',
    },
    {
      title: 'Last Donation',
      dataIndex: 'LastDonationDate',
      className: 'ant-table-row-cell-break-word',
      render: (time) => formateDateTimeToLocal(time, 'datetime'),
      sorter: (a, b) => {
        const dateA = new Date(a.LastDonationDate)
        const dateB = new Date(b.LastDonationDate)
        return dateB - dateA
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Source',
      dataIndex: 'type',
      className: 'ant-table-row-cell-break-word',
    },
  ]

  // Event handler for time frame radio button change
  const handleTimeFrameChange = (value) => {
    setTimeFrame(value)
    setSelectedMonth(null) // Reset month selection when time frame changes
  }

  // Event handler for donation type dropdown change
  const handleDonationTypeChange = (value) => {
    setDonationType(value)
  }

  // Event handler for month dropdown change

  const handleMonthChange = (value) => {
    // Find the month with a matching lowercase value
    const selected = months.find((month) => month.toLowerCase() === value)
    if (selected) {
      setSelectedMonth(selected)
    }
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={
        <div className="t-flex t-flex-wrap t-justify-between">
          <div className="t-flex t-space-x-4">
            <IoMdArrowRoundBack
              fontSize="1.5rem"
              className="t-cursor-pointer t-min-w-[1.5rem]"
              id="back"
              onClick={() => history.push('/reports')}
            />
            <h5 className="font-semibold">YTD Organization Revenue</h5>
          </div>
          <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
            <div className="header-control t-mt-0">
              <Input
                className="header-search t-w-36"
                placeholder="Search ..."
                prefix={<SearchOutlined />}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
            </div>
            <Tooltip title="Download">
              {downloadLoading ? (
                <Spin />
              ) : (
                <MdOutlineSimCardDownload
                  fontSize="1.7rem"
                  id="download"
                  className="t-text-secondary-100 t-cursor-pointer"
                  onClick={downloadCSVWithFormattedDateTime}
                />
              )}
            </Tooltip>
          </div>
        </div>
      }
    >
      <div style={{ marginBottom: 20, marginLeft: '2%' }}>
        <Space direction="vertical">
          <div style={{ marginBottom: 20 }}>
            <Space>
              <p>
                <b>Filter By</b>
              </p>
              <Select
                defaultValue="year" // Default value
                style={{ width: 200 }}
                onChange={handleTimeFrameChange} // Use the same handler or modify it as needed
              >
                <Option value="year">Year</Option>
                <Option value="month">Month</Option>
              </Select>
              <p style={{ marginLeft: 10 }}>
                <b>Select Type</b>
              </p>
              <Select
                defaultValue="all"
                style={{ width: 200 }}
                onChange={handleDonationTypeChange}
              >
                <Option value="all">All</Option>
                <Option value="organization">Organization Donation</Option>
                <Option value="fundraiser">Fundraiser Donation</Option>
                <Option value="event">Event Donation</Option>
                <Option value="campaign">Campaign Donation</Option>
                <Option value="program">Program Earning</Option>
                <Option value="service">Service Earning</Option>
                <Option value="rental">Rental Service Earning</Option>
              </Select>
              {timeFrame === 'month' && (
                <>
                  <p style={{ marginLeft: 10 }}>
                    <b>Select Month</b>
                  </p>
                  <Select
                    placeholder="Select Month"
                    style={{ width: 150 }}
                    value={
                      selectedMonth
                        ? selectedMonth.toLowerCase()
                        : months[currentDate.getMonth()]
                    } // Provide a fallback value
                    onChange={handleMonthChange}
                  >
                    {months.map((month, index) => (
                      <Option key={index} value={month.toLowerCase()}>
                        {month}
                      </Option>
                    ))}
                  </Select>
                </>
              )}
            </Space>
          </div>

          <Title level={5}>
            Revenue Generated :
            <span style={{ marginLeft: 10 }}>
              {loading ? (
                <Spin />
              ) : (
                <Text type="success">
                  $
                  {totalDonation.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
              )}
            </span>
          </Title>
        </Space>
      </div>
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <Table
          tableLayout="auto"
          scroll={{ x: 500 }}
          columns={columns}
          dataSource={searchYTDDonationsRows(filteredData, searchText) || []}
          bordered={false}
          pagination={false}
          className="td-right"
        />
      </Skeleton>
    </Card>
  )
}

export { YtdRevenueForOrganization }

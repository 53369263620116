import { Button, Col, Form, Input, Modal, Row, message } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { makeRequest } from 'api'
import { AmountInput } from 'components/elements'
import { config } from 'configs'

import React, { useEffect, useState } from 'react'
import { memberTierValidation } from 'validations'

const initialValues = {
  title: '',
  description: '',
  amount: '',
}

const AddTierModal = ({
  visible,
  close,
  organizationId,
  selectedItem,
  setSelectedItem,
  items,
}) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState()
  const isEdit = selectedItem || false

  useEffect(() => {
    if (isEdit) {
      const { Title, Description, Amount } = selectedItem
      form.setFieldsValue({
        Title,
        Description,
        Amount,
      })
    }
    // eslint-disable-next-line
  }, [isEdit])

  // eslint-disable-next-line consistent-return
  const onFinish = (values) => {
    const { Title } = values
    // If want to check if Title is same as any of items.Title
    const titleAlreadyExist = items.some(
      (item) => item.Title === Title && item.Id !== selectedItem?.Id,
    )
    if (titleAlreadyExist) {
      message.error('This title already exist')
      return false
    }

    const data = {
      ...values,
      Amount: values.Amount.replaceAll(/,/g, ''),
      OrganizationId: organizationId,
    }

    if (isEdit) {
      data.Id = selectedItem?.Id
    }
    const method = isEdit ? 'put' : 'post'
    const url = isEdit
      ? '/component/organization/update-membership-program-tier'
      : '/component/organization/add-membership-program-tier'
    setLoading(true)
    makeRequest(
      method,
      url,
      {
        organization: organizationId,
      },
      data,
    )
      .then(() => {
        message.success(
          isEdit ? 'Tier Updated Successfully' : 'Tier Added Successfully',
        )
        form.resetFields()
        if (isEdit) {
          setSelectedItem(null)
        }
        setLoading(false)
        close()
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
        setLoading(false)
      })
  }

  return (
    <Modal
      centered
      keyboard
      title={isEdit ? 'Edit Tier' : 'Add Tier'}
      open={visible}
      onCancel={() => {
        form.resetFields()
        close()
      }}
      footer={null}
      destroyOnClose
    >
      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="add-items"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Form.Item
                name="Title"
                label="Title"
                colon={false}
                rules={[memberTierValidation]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="Description"
                label="Description"
                colon={false}
                rules={[memberTierValidation]}
              >
                <TextArea
                  placeholder="Item Description"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="Amount"
                label="Annual Amount"
                colon={false}
                rules={[memberTierValidation]}
              >
                <AmountInput
                  placeholder="Enter Tier Amount"
                  value={form.getFieldValue('Amount')}
                  onChange={(value) => form.setFieldValue('Amount', value)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="t-text-right">
                <Button type="primary" loading={loading} htmlType="submit">
                  {isEdit ? 'Update Tier' : 'Add Tier'}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>
    </Modal>
  )
}

export { AddTierModal }

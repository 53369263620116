import React, { useState } from 'react'
import { Form, Row, Col, Card, message, Button, Statistic } from 'antd'
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { awsconfig, config } from 'configs'
import { loadStripe } from '@stripe/stripe-js'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { TermsAndConditionsModal } from 'components/common/donations/components/TermsAndConditionsModal'
import { useDispatch } from 'react-redux'
import { MdOutlinePayment } from 'react-icons/md'
import { formatAmount } from 'utils'
import {
  getOrganizatinPlatformSubscription,
  setupPlaformSubscription,
} from 'store/platformSubscriptionSlice/platformSubscriptionActions'
import { usePlatformSubscriptionSelector } from 'store/platformSubscriptionSlice/platformSubscriptionReducer'
// import { useAuthSelector } from 'store/authSlice/authReducer'

const initialValues = {
  card: 'no-card-selected',
}

const WrappedSubscriptionForm = () => {
  const dispatch = useDispatch()
  const { user, organization } = useAuthSelector()
  const { globalSubscriptionCharges } = usePlatformSubscriptionSelector()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  Form.useWatch('card', form)

  /* eslint-disable consistent-return */
  const onFinish = async () => {
    if (!user && !organization) {
      message.error('User or organization not found')
      return false
    }
    let cardToken = null
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    })
    if (error) {
      message.error(error?.message || 'Invalid Card Information')
      return false
    }
    cardToken = paymentMethod.id
    const payload = {
      cardToken,
      organizationId: organization?.id,
      user: {
        id: user?.id,
        email: user?.email,
        firstname: user?.firstname,
        lastname: user?.lastname,
        phone: user?.phone,
        // countryCode: data.countryCode,
        // phoneNumber: data.phoneNumber?.replace(/\D/g, ''),
      },
    }
    setLoading(true)
    try {
      await setupPlaformSubscription(payload)
      dispatch(
        getOrganizatinPlatformSubscription({
          organizationId: organization?.id,
        }),
      )
      message.success('Your subscription is successfully setup')
    } catch (error1) {
      message.error(error1?.response?.data?.error || config.defaultErrorMessage)
    }
    setLoading(false)
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0">Current Subscription</h6>
        </>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        key={0}
        onFinish={onFinish}
        name="donation"
        scrollToFirstError
        initialValues={initialValues}
      >
        <Row gutter={[24, 0]} className="t-mb-8 t-space-y-2">
          <Col xs={24} sm={24} md={8} xl={8}>
            <Card
              className="header-solid t-p-2 md:t-p-10 t-m-4 t-shadow-2xl t-rounded-lg widget-2 t-h-full t-flex t-justify-center t-items-center"
              bordered={false}
            >
              <Statistic
                title={
                  <>
                    <div className="icon">
                      <MdOutlinePayment />
                    </div>
                    <h6 className="t-text-secondary-100 t-text-xl t-font-semi">
                      Platform Subscription
                    </h6>
                    <p>Subscribe to get paid features</p>
                  </>
                }
                value={formatAmount(globalSubscriptionCharges)}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={16} xl={16}>
            <Card
              className="header-solid t-p-2 md:t-p-10 t-m-4 t-shadow-2xl t-rounded-lg t-h-full"
              bordered={false}
            >
              <>
                <h6 className="t-text-sm t-mb-4 t-font-semibold">
                  To start or renew your subscription, please enter your credit
                  card information below.
                </h6>
                <h1 className="t-text-sm t-my-2 t-font-semibold">
                  Enter Card Number
                </h1>
                <div className="t-p-2 t-rounded-md t-border t-border-grey-900'">
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: '15px',
                          fontSmoothing: 'antialiased',
                        },
                      },
                    }}
                  />
                </div>
              </>
              <Col span={24} className="text-right t-p-0 t-my-2">
                <Button
                  type="primary"
                  className="t-w-full"
                  htmlType="submit"
                  loading={loading}
                  disabled={!stripe || !elements}
                >
                  Subscribe
                </Button>
              </Col>
              <TermsAndConditionsModal />
            </Card>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

function StripeProvider() {
  const stripePromise = loadStripe(awsconfig.stripe_publish_key)

  return (
    <Elements stripe={stripePromise}>
      <WrappedSubscriptionForm />
    </Elements>
  )
}
const CreditCardSubscription = StripeProvider
export { CreditCardSubscription }

import React from 'react'

const QuickPathFundraiser = () => {
  const is = true
  return (
    <>
      <div>{is ? 'Quick Path Fundraiser' : 'Quick Path Fundraiser'}</div>
    </>
  )
}
export { QuickPathFundraiser }

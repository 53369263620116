import React, { useState, useEffect } from 'react'
import { Row, Button, Tag, message } from 'antd'
import { useAuthSelector } from 'store/authSlice/authReducer'
import {
  getPaymentCategoryStripeAccountDetails,
  UnlinkPaymentCategoryStripeAccount,
  savePaymentCategoryStripeID,
} from 'store/paymentSlice/paymentActions'
import { useHistory } from 'react-router-dom'
import { awsconfig, config } from 'configs'
import { DisconnectButton } from './DisconnectButton'

const CatgegoryStripeButton = ({
  refreshPayment,
  OrganizationId,
  Id,
  Name,
  Type,
  StripeId,
  StripeOnBoarded,
}) => {
  const history = useHistory()
  const { user } = useAuthSelector()
  const [loading, setLoading] = useState(false)
  const [stripeErrors, setStripeErrors] = useState({
    errors: [],
    requirements: [],
    pending: [],
  })

  useEffect(() => {
    const storeStripeID = async (ACCOUNT_ID, ID) => {
      try {
        await savePaymentCategoryStripeID(ACCOUNT_ID, ID)
        refreshPayment()
        history.push('/settings/manage-payments-accounts')
      } catch (error) {
        message.error('Something Went Wrong')
      }
    }
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (Object.entries(params).length > 0) {
      if (params.category_account_id && params.category_id) {
        storeStripeID(params.category_account_id, params.category_id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, user])

  useEffect(() => {
    if (StripeId) getStripeAccount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const getStripeAccount = async () => {
    try {
      setLoading(true)
      const response = await getPaymentCategoryStripeAccountDetails(StripeId)
      setStripeErrors(response)
      setLoading(false)
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }
  }

  const openStripeOAuth = async () => {
    const clientId = awsconfig.stripe_connect_client_id
    // Redirect URI should match in the Stripe dashboard
    const redirectUri = `${window.location.origin}${config.stripeRedirectUri}`
    const data = {
      caller: 'ConnectGeneralCategory',
      category_id: Id,
    }
    const stringifiedData = JSON.stringify(data)
    const link = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&redirect_uri=${redirectUri}&state=${stringifiedData}`
    window.open(link, '_self')
  }

  const unlinkStripe = async () => {
    try {
      setLoading(true)
      await UnlinkPaymentCategoryStripeAccount(Id)
      refreshPayment()
      setLoading(false)
      message.success('Stripe Account Disconnectd')
    } catch (error) {
      setLoading(false)
      message.error('Something Went Wrong')
    }
  }

  const isErrors = stripeErrors?.errors?.length > 0
  const isRequirements = stripeErrors?.requirements?.length > 0
  const isPending = stripeErrors?.pending?.length > 0

  return (
    <>
      {!isErrors && !isRequirements && (
        <>
          <Row className="t-space-x-4 t-flex t-items-center">
            {StripeId && StripeOnBoarded ? (
              <DisconnectButton
                loading={loading}
                handleDisconnent={unlinkStripe}
                organizationId={OrganizationId}
                paymentCategoryId={Id}
                categoryName={Name}
                paymentType={Type}
                accountId={StripeId}
              />
            ) : (
              <>
                {isPending ? (
                  <Tag color="gray">under review</Tag>
                ) : (
                  <Button
                    size="small"
                    type="primary"
                    onClick={openStripeOAuth}
                    className="payment-setup-button t-bg-orange-500"
                    loading={loading}
                    disabled={isErrors || isRequirements || isPending}
                  >
                    {!loading ? 'Connect' : 'Loading'}
                  </Button>
                )}
              </>
            )}
          </Row>
        </>
      )}
      {(isErrors || isRequirements) && (
        <>
          <div className="t-my-4">
            <Button
              size="small"
              type="primary"
              onClick={openStripeOAuth}
              className="payment-setup-button"
              loading={loading}
            >
              Update
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export { CatgegoryStripeButton }

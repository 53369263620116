/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { makeRequest } from 'api'
import { Skeleton, Switch } from 'antd'
import { config } from 'configs'
import { formatAmountWithOutDecimal } from 'utils'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useHistory } from 'react-router-dom'
import TodayDonations from './today-donation/TodayDonations'
import MonthlyEarning from './monthly-earning/MonthlyEarning'
import CommunityMemebers from './community-members/CommunityMemebers'
import Fundraiser from './fundraiser/Fundraiser'
import Pledges from './pledges/Pledges'

const Home = () => {
  const [page, setPage] = useState(1)
  const [loading1, setLoading1] = useState(true)
  const [loading2, setLoading2] = useState(true)
  const [loading3, setLoading3] = useState(true)
  const [loading4, setLoading4] = useState(true)
  const [donationData, setDonationData] = useState([])
  const [err, setErr] = useState(false)
  const [pledge, setPledge] = useState([])
  const [monthlyEarn, setMonthlyEarn] = useState({})
  const [comunityMembers, setCommunityMembers] = useState([])
  const [netGross, setNetGross] = useState(false)

  const { organization, user } = useAuthSelector()
  const isSubscriber = user?.roles?.includes(config.roles.subscriber)
  const history = useHistory()

  const loading = loading1 || loading2 || loading3 || loading4

  useEffect(() => {
    if (!isSubscriber) {
      history.push('/organizations')
    }
  }, [isSubscriber, history])

  // API Call for Home Page
  useEffect(() => {
    if (isSubscriber) {
      setLoading1(true)
      setErr(false)
      makeRequest('get', `subscriber-home/header`, {
        organization: organization?.id,
      })
        .then((res) => {
          setDonationData(res.data)
          setLoading1(false)
        })
        .catch((error) => {
          console.log('error', error)
          setLoading1(false)
          setErr(true)
        })
    }
    // eslint-disable-next-line
  }, [])

  // API Call for Pledges
  const fetchPledges = async () => {
    setLoading2(true)
    makeRequest(
      'get',
      `/reports/organization-pending-pledges?id=${organization?.id}`,
    )
      .then((res) => {
        setPledge(res?.result)
        setLoading2(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading2(false)
        setErr(true)
      })
  }

  const amountChange = () => {
    setNetGross(!netGross)
  }

  useEffect(() => {
    fetchPledges()
  }, [])

  const fetchMonthlyEarning = async () => {
    setLoading3(true)
    makeRequest('get', `/subscriber-home/monthly-earings`, {
      organization: organization?.id,
    })
      .then((res) => {
        setMonthlyEarn(res?.results)
        setLoading3(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading3(false)
        setErr(true)
      })
  }

  useEffect(() => {
    fetchMonthlyEarning()
  }, [])

  // API for My Comunity
  const fetchComunityMembers = async () => {
    setLoading4(true)
    makeRequest('get', `subscriber-home/community-members`, {
      organization: organization?.id,
    })
      .then((res) => {
        setCommunityMembers(res?.results)
        setLoading4(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading4(false)
        setErr(true)
      })
  }

  useEffect(() => {
    fetchComunityMembers()
  }, [])

  if (loading) {
    return <Skeleton active paragraph={{ rows: 18 }} />
  }

  if (err) {
    return <div className="t-font-medium t-text-lg">Error Occured</div>
  }

  return (
    <>
      <div className="t-grid md:t-grid-cols-4 t-gap-4 lg:t-grid-cols-5 sm:t-grid-cols-3 t-grid-cols-2 t-px-2 t-mt-2 md:t-mt-2 ">
        {/* First Div */}
        <div
          className={`t-relative t-col-span-1 t-row-span-1 p-4 t-border t-border-gray-300 t-rounded-lg t-cursor-pointer ${
            page === 1
              ? 't-bg-[#4B9FDB] t-text-white'
              : 't-bg-white t-text-black'
          }`}
          onClick={() => setPage(1)}
        >
          <span className="t-absolute t-border t-border-gray-300 t-bg-white t-text-black t-text-base t-font-bold t-rounded-lg t-top-[0.4rem] t-right-[0.4rem] t-w-12 t-text-center">
            {donationData?.donations?.today?.list?.length || 0}
          </span>
          <h1
            className={`t-duration-300 t-text-base md:t-text-lg t-pl-4 t-pt-4 t-font-semibold t-mt-4 ${
              page === 1 ? 't-text-white' : 't-text-black'
            }`}
          >
            Today`s Donations
          </h1>
          <h1
            className={`t-text-xl md:t-text-2xl t-pl-4 t-pb-2 t-font-bold ${
              page === 1 ? 't-text-white' : 't-text-black'
            }`}
          >
            {formatAmountWithOutDecimal(
              netGross
                ? donationData?.donations?.today.netAmount
                : donationData?.donations?.today.amount,
            )}
          </h1>
        </div>
        {/* Fifth Div */}
        <div
          className={`t-col-span-1 t-row-span-1 p-4 t-border t-border-gray-300 t-rounded-lg t-cursor-pointer ${
            page === 5
              ? 't-bg-[#4B9FDB] t-text-white'
              : 't-bg-white t-text-black'
          }`}
          onClick={() => setPage(5)}
        >
          <h2
            className={`t-text-base md:t-text-lg t-pl-4 t-pt-4 t-font-semibold t-mt-4 ${
              page === 5 ? 't-text-white' : 't-text-black'
            }`}
          >
            Pledges
          </h2>
          <h1
            className={`t-text-xl md:t-text-2xl t-pl-4 t-pb-2 t-font-bold ${
              page === 5 ? 't-text-white' : 't-text-black'
            }`}
          >
            {pledge?.length}
          </h1>
        </div>
        {/* Third Div */}
        <div
          className={`t-relative t-col-span-1 t-row-span-1 p-4 t-border t-border-gray-300 t-rounded-lg t-cursor-pointer ${
            page === 3
              ? 't-bg-[#4B9FDB] t-text-white'
              : 't-bg-white t-text-black'
          }`}
          onClick={() => setPage(3)}
        >
          <span className="t-absolute t-border t-border-gray-300 t-bg-white t-text-black t-text-base t-font-bold t-rounded-lg t-top-[0.4rem] t-right-[0.4rem] t-w-12 t-text-center">
            +{comunityMembers.joinedThisMonth}
          </span>
          <h1
            className={`t-duration-300 t-text-base md:t-text-lg t-pl-4 t-pt-4 t-font-semibold t-mt-4 ${
              page === 3 ? 't-text-white' : 't-text-black'
            }`}
          >
            My Community
          </h1>
          <h1
            className={`t-text-xl md:t-text-2xl t-pl-4 t-pb-2 t-font-bold ${
              page === 3 ? 't-text-white' : 't-text-black'
            }`}
          >
            {comunityMembers?.total}
          </h1>
        </div>
        {/* Fourth Div */}
        <div
          className={`t-col-span-1 t-row-span-1 p-4 t-border t-border-gray-300 t-rounded-lg t-cursor-pointer ${
            page === 4
              ? 't-bg-[#4B9FDB] t-text-white'
              : 't-bg-white t-text-black'
          } `}
          onClick={() => setPage(4)}
        >
          <h2
            className={`t-text-base md:t-text-lg t-pl-4 t-pt-4 t-font-semibold t-mt-4 ${
              page === 4 ? 't-text-white' : 't-text-black'
            }`}
          >
            Fundraisers
          </h2>
          <h1
            className={`t-text-xl md:t-text-2xl t-pl-4 t-pb-2 t-font-bold ${
              page === 4 ? 't-text-white' : 't-text-black'
            } `}
          >
            {donationData?.activeEvents?.length}
          </h1>
        </div>

        {/* Second Div */}
        <div
          className={`t-relative t-col-span-1 t-row-span-1 p-4 t-border t-border-gray-300 t-rounded-lg t-cursor-pointer ${
            page === 2
              ? 't-bg-[#4B9FDB] t-text-white'
              : 't-bg-white t-text-black'
          }`}
          onClick={() => setPage(2)}
        >
          <span className="t-absolute t-border t-border-gray-300 t-bg-white t-text-black t-text-base t-font-bold t-rounded-lg t-top-[0.4rem] t-right-[0.4rem] t-w-12 t-text-center">
            {monthlyEarn?.earnings?.total?.transactions &&
            typeof monthlyEarn?.earnings?.total?.transactions === 'number' &&
            !isNaN(monthlyEarn.earnings.total.transactions)
              ? monthlyEarn.earnings.total.transactions
              : 0}
          </span>
          <h1
            className={`t-duration-300 t-text-base md:t-text-lg t-pl-4 t-pt-4 t-font-semibold t-mt-4 ${
              page === 2 ? 't-text-white' : 't-text-black'
            }`}
          >
            Earnings
          </h1>
          <h1
            className={`t-text-xl md:t-text-2xl t-pl-4 t-pb-2 t-font-bold ${
              page === 2 ? 't-text-white' : 't-text-black'
            }`}
          >
            {formatAmountWithOutDecimal(
              netGross
                ? monthlyEarn?.earnings?.total?.NetAmount &&
                  typeof monthlyEarn?.earnings?.total?.NetAmount === 'number' &&
                  !isNaN(monthlyEarn?.earnings?.total?.NetAmount)
                  ? monthlyEarn?.earnings?.total?.NetAmount
                  : 0
                : monthlyEarn?.earnings?.total?.GrossAmount &&
                  typeof monthlyEarn?.earnings?.total?.GrossAmount ===
                    'number' &&
                  !isNaN(monthlyEarn?.earnings?.total?.GrossAmount)
                ? monthlyEarn?.earnings?.total?.GrossAmount
                : 0,
            )}
          </h1>
        </div>
        <div className="t-flex t-flex-row t-mx-2 t-mt-4">
          <h1 className="t-mx-2 t-text-base">Show Net Amount:</h1>
          <Switch onChange={amountChange} checked={netGross} />
        </div>
      </div>

      {page === 1 ? (
        <TodayDonations donationData={donationData} netGross={netGross} />
      ) : page === 2 ? (
        <MonthlyEarning monthlyEarn={monthlyEarn} netGross={netGross} />
      ) : page === 3 ? (
        <CommunityMemebers
          comunityMembers={comunityMembers}
          netGross={netGross}
        />
      ) : page === 4 ? (
        <Fundraiser
          array={donationData?.activeEvents || []}
          netGross={netGross}
        />
      ) : page === 5 ? (
        <Pledges pledge={pledge} setPledge={setPledge} netGross={netGross} />
      ) : null}
    </>
  )
}

export { Home }

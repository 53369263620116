import React from 'react'
import { useRoles } from 'hooks'
import { PageNotFound } from 'screens/main/settings/page-not-found'
import { config } from 'configs'

const {
  roles: { donor, subscriber, business, admin },
} = config

const RoleAuthenticator = ({ roles, children }) => {
  const allowedRoles = roles || []
  const { isDonor, isSubscriber, isBusiness, isAdmin } = useRoles()

  const notRestricted = allowedRoles.length === 0
  const isDonorAllowed = allowedRoles.includes(donor) && isDonor
  const isSubscriberAllowed = allowedRoles.includes(subscriber) && isSubscriber
  const isBusinessAllowed = allowedRoles.includes(business) && isBusiness
  const isAdminAllowed = allowedRoles.includes(admin) && isAdmin

  if (
    !notRestricted &&
    !isDonorAllowed &&
    !isSubscriberAllowed &&
    !isBusinessAllowed &&
    !isAdminAllowed
  ) {
    return <PageNotFound />
  }
  return children
}

export { RoleAuthenticator }

import React, { useEffect, useState } from 'react'
import { Card, Row, Skeleton, Tabs } from 'antd'
import { SVGIcon } from 'components'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { getSoldItemDetails } from 'store/eventSlice/eventActions'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { BankDebit, CreditCard, Paypal } from './components'

const AuctionItemPayment = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const { user } = useAuthSelector()
  const { soldItem, subscriberPaymentInfo } = useEventSelector()
  const { id } = params
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    if (id) {
      dispatch(getSoldItemDetails(id, setLoading, setError))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  // const onWithdraw = () => {
  //   Api.get(`events/withdrawBid/${soldItem.Id}`)
  //     .then(() => {
  //       message.success('Bid Withdrawn Successfully')
  //       history.push(`/${window.location.origin}`)
  //     })
  //     .catch((error) => {
  //       console.log('error', error)
  //       message.error(e?.response?.data?.message || 'Something went wrong')
  //     })
  // }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <div className="t-flex t-justify-between t-items-center">
          <h6 className="mb-0">Auction Item Payment</h6>
        </div>,
      ]}
    >
      <Skeleton
        loading={loading}
        active
        paragraph={{ rows: 12 }}
        className="t-w-full t-h-full"
      >
        {/* If and error occured in api calls */}
        {error && (
          <div className="ant-result ant-result-404">
            <SVGIcon />
            <div className="ant-result-title">An Error Occured</div>
            <div className="ant-result-subtitle">{error}</div>
          </div>
        )}

        {/* If user is invalid */}
        {!error && soldItem && user.email !== soldItem.winnerEmail && (
          <div className="ant-result ant-result-404">
            <SVGIcon />
            <div className="ant-result-title">Invalid User</div>
            <div className="ant-result-subtitle">
              Kindly use the account you used to bid
            </div>
          </div>
        )}

        {/* If payment is already completed */}
        {!error &&
          soldItem &&
          user.email === soldItem.winnerEmail &&
          soldItem?.paymentStatus === 'Completed' && (
            <div className="ant-result ant-result-404">
              <SVGIcon />
              <div className="ant-result-title">Already Paid</div>
              <div className="ant-result-subtitle">
                This item payment has been already made
              </div>
            </div>
          )}

        {/* If it passed all the checks */}
        {!error &&
          soldItem &&
          user.email === soldItem.winnerEmail &&
          soldItem?.paymentStatus !== 'Completed' && (
            <Row className="modal_height_mobile md:modal_height_desktop t-flex t-justify-center t-overflow-scroll no-scrollbar">
              <Row className="t-w-full">
                <Tabs
                  destroyInactiveTabPane
                  className="t-w-full sliding-tab"
                  defaultActiveKey="1"
                  animated={false}
                >
                  {subscriberPaymentInfo?.stripe?.isConnected && (
                    <>
                      <Tabs.TabPane tab="Credit Card" key="1">
                        <CreditCard />
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Bank Debit" key="2">
                        <BankDebit />
                      </Tabs.TabPane>
                    </>
                  )}
                  {subscriberPaymentInfo?.paypal?.isConnected && (
                    <>
                      <Tabs.TabPane tab="Paypal" key="3">
                        <Paypal />
                      </Tabs.TabPane>
                    </>
                  )}
                </Tabs>
              </Row>
            </Row>
          )}
      </Skeleton>
    </Card>
  )
}

export { AuctionItemPayment }

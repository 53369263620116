import React, { useEffect } from 'react'
import { Card, Form, Input, Row, Col, Button, Skeleton } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  addProfile,
  getProfile,
  updateProfile,
} from 'store/organizationProfilesSlice/OrganizationProfilesActions'
import { useOrganizationProfileSelector } from 'store/organizationProfilesSlice/OrganizationProfilesReducer'
import { ImageUploader } from 'components'
import { organizationProfileValidation } from 'validations'
import { useAuthSelector } from 'store/authSlice/authReducer'

const { TextArea } = Input

const initialValues = {
  ProfileTitle: '',
  OrganizationName: '',
  OrganizationEmail: '',
  OrganizationWebsite: '',
  Description: '',
  Logo: null,
}

const AddOrEditProfile = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { organizationId, profileId } = params
  const isEdit = organizationId && profileId
  const { organization } = useAuthSelector()
  const { loading } = useOrganizationProfileSelector()
  const { addOrUpdate, fetchProfile } = loading
  const [form] = Form.useForm()
  Form.useWatch('Logo', form)

  useEffect(() => {
    if (isEdit) {
      dispatch(getProfile(organizationId, profileId, form))
    }
  }, [dispatch, isEdit, organizationId, profileId, form])

  const navigation = () => {
    form.resetFields()
    history.push('/settings/manage-organization/organization-detail')
  }

  const onFinish = async (values) => {
    const {
      ProfileTitle,
      OrganizationName,
      OrganizationEmail,
      OrganizationWebsite,
      Description,
      Logo,
    } = values
    const payload = {
      ProfileTitle,
      OrganizationName,
      OrganizationEmail,
      OrganizationWebsite,
      Description,
      Logo,
      OrganizationId: organization?.id,
    }
    if (isEdit) {
      payload.ProfileId = profileId
      dispatch(updateProfile(payload, navigation))
    } else {
      dispatch(addProfile(payload, navigation))
    }
  }

  const buttonText = false
    ? isEdit
      ? 'Updating Profile'
      : 'Adding Profile'
    : isEdit
    ? 'Update Profile'
    : 'Add Profile'

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0 t-text-2xl">
            {isEdit ? 'Update Profile' : 'Add Profile'}
          </h6>
          <p className="font-regular">Enter Information</p>
        </>,
      ]}
    >
      <Skeleton loading={fetchProfile} active paragraph={{ rows: 18 }}>
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="Profile Form"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={12} xl={12}>
              <Form.Item
                name="ProfileTitle"
                label="Profile Title*"
                colon={false}
                rules={[organizationProfileValidation]}
              >
                <Input placeholder="Enter Profile Title" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} xl={12}>
              <Form.Item
                name="OrganizationName"
                label="Organization Name*"
                colon={false}
                rules={[organizationProfileValidation]}
              >
                <Input placeholder="Enter Organization Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} xl={12}>
              <Form.Item
                name="OrganizationEmail"
                label="Organization Email*"
                colon={false}
                rules={[organizationProfileValidation]}
              >
                <Input placeholder="Enter Organization Email" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} xl={12}>
              <Form.Item
                name="OrganizationWebsite"
                label="Organization Website*"
                colon={false}
                rules={[organizationProfileValidation]}
              >
                <Input placeholder="Enter Organization Website" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="Description"
                label="Description*"
                colon={false}
                rules={[organizationProfileValidation]}
              >
                <TextArea
                  placeholder="Enter Description"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="Logo"
                label="Logo*"
                colon={false}
                rules={[organizationProfileValidation]}
              >
                <ImageUploader
                  defaultFile={
                    form.getFieldValue('Logo') && form.getFieldValue('Logo')
                  }
                  onFileChange={(file) =>
                    form.setFieldsValue({
                      Logo: file?.originFileObj,
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24} className="text-right t-space-x-2">
              <Button
                type="primary"
                className="px-25"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                loading={addOrUpdate}
                type="primary"
                className="px-25"
                htmlType="submit"
              >
                {buttonText}
              </Button>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </Card>
  )
}

export { AddOrEditProfile }

import { makeRequest } from 'api/apiv2'

export const addClickTapFundraiser = async (data, user) => {
  let updatedData = data

  updatedData = {
    ...updatedData,
    createdBy: user.email,
  }

  delete updatedData.recaptcha
  const response = await makeRequest(
    'post',
    '/admin/fundraisers/click-tap',
    { organization: data?.organizationId },
    updatedData,
  )
  return response
}

export const updateClickTapFundraiser = async (id, data, user) => {
  let updatedData = data

  updatedData = {
    ...updatedData,
    updatedBy: user.email,
  }

  delete updatedData.recaptcha
  const response = await makeRequest(
    'put',
    `/admin/fundraisers/${id}/click-tap`,
    { organization: data?.organizationId },
    updatedData,
  )
  return response
}

export const getClickTapFundraiser = async (id) => {
  const { data } = await makeRequest(
    'get',
    `/admin/fundraisers/${id}/click-tap`,
  )
  return data
}

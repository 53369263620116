import { message } from 'antd'
import Api from 'api'
import { uploadFile } from 'store/authSlice/authActions'
import { sortByDate, sortByZipcode } from 'utils'
import moment from 'moment'
import { auctionActions } from './auctionReducer'

export const addAuction = (payload, setLoading, actions) => async () => {
  setLoading(true)
  try {
    let updatedData = payload
    if (!updatedData?.Flyer?.fileName) {
      const flyerFile = await uploadFile(updatedData.Flyer)
      updatedData = {
        ...updatedData,
        Flyer: {
          fileName: flyerFile.fileName,
          fileMimeType: flyerFile.fileMimeType,
        },
      }
    }

    // Auction Item Image Upload
    if (updatedData.AuctionItemsList) {
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < updatedData.AuctionItemsList.length; i += 1) {
        const item = updatedData.AuctionItemsList[i]
        if (item?.itemImage?.originFileObj) {
          const itemFile = await uploadFile(item.itemImage.originFileObj)
          item.itemImage = {
            fileName: itemFile.fileName,
            fileMimeType: itemFile.fileMimeType,
          }
        }
        updatedData.AuctionItemsList[i] = item
      }
      /* eslint-disable no-await-in-loop */
    }

    Api.put('/auction', updatedData)
      .then(() => {
        setLoading(false)
        message.success('Auction Added Successfully')
        if (actions) {
          actions()
        }
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
        message.error('An Error Occured')
      })
  } catch (error) {
    console.log('error', error)
    setLoading(false)
  }
}

export const updateAuction = (payload, setLoading, actions) => async () => {
  setLoading(true)
  try {
    let updatedData = payload
    if (!updatedData?.Flyer?.fileName) {
      const flyerFile = await uploadFile(updatedData.Flyer)
      updatedData = {
        ...updatedData,
        Flyer: {
          fileName: flyerFile.fileName,
          fileMimeType: flyerFile.fileMimeType,
        },
      }
    }

    Api.put('/auction/updateAuction', updatedData)
      .then(() => {
        setLoading(false)
        message.success('Auction Updated Successfully')
        if (actions) {
          actions()
        }
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
        message.error('An Error Occured')
      })
  } catch (error) {
    console.log('error', error)
    setLoading(false)
  }
}

export const geNearByAuction = (body, sortKey) => (dispatch) => {
  dispatch(auctionActions.setLoading(true))
  Api.post('/auction/nearBy', body)
    .then((res) => {
      const sortedData = sortByDate(res?.results, sortKey)
      dispatch(auctionActions.setAuctions(sortedData))
      dispatch(auctionActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(auctionActions.setLoading(false))
    })
}

export const getAllAuction = (loadingOff, zipCode) => (dispatch) => {
  Api.get('/auction/all')
    .then((res) => {
      const sortedData = sortByZipcode(res?.results, 'ZipCode', zipCode)
      dispatch(auctionActions.setAllAuctions(sortedData))
      loadingOff()
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(auctionActions.setAllAuctions([]))
      loadingOff()
    })
}

export const getAuctions = (sortKey) => (dispatch) => {
  dispatch(auctionActions.setLoading(true))
  Api.get('/auction/all')
    .then((res) => {
      const sortedData = sortByDate(res?.results, sortKey)
      dispatch(auctionActions.setAuctions(sortedData))
      dispatch(auctionActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(auctionActions.setLoading(false))
    })
}

export const getAuctionBySubscriber = (sortKey, id) => (dispatch) => {
  dispatch(auctionActions.setLoading(true))
  Api.get(`/auction/getBySubscriber?id=${id}`)
    .then((res) => {
      const sortedData = sortByDate(res?.results, sortKey)
      dispatch(auctionActions.setAuction(sortedData))
      dispatch(auctionActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(auctionActions.setLoading(false))
    })
}

export const getAuctionForUpdate = (id, form, duplicate) => (dispatch) => {
  dispatch(auctionActions.setLoading(true))
  Api.get(`/auction/getAuction/${id}`)
    .then((res) => {
      if (res.results) {
        const auction = res.results
        if (form) {
          form.setFieldsValue({
            Title: auction?.Title,
            ZipCode: auction?.ZipCode,
            StartDate:
              !duplicate && auction?.StartDate && moment(auction?.StartDate),
            EndDate: !duplicate && auction?.EndDate && moment(auction?.EndDate),
            Details: auction?.Details,
            ShowOnPage: auction?.ShowOnPage,
            Flyer: auction?.Flyer && auction?.Flyer,
          })
        }
      }
      dispatch(auctionActions.setLoading(false))
    })
    .catch(() => {
      dispatch(auctionActions.setLoading(false))
    })
}

export const getAuctionDetails = (id) => (dispatch) => {
  dispatch(auctionActions.setLoading(true))
  Api.get(`/auction/getAuction/${id}`)
    .then((res) => {
      dispatch(auctionActions.setAuction(res.results))
      dispatch(auctionActions.setLoading(false))
    })
    .catch(() => {
      dispatch(auctionActions.setAuction(null))
      dispatch(auctionActions.setLoading(false))
    })
}

export const updateAuctionStatus =
  (auctionId, status, nagigate) => (dispatch) => {
    console.log('Hello')
    dispatch(auctionActions.setLoading(true))
    const modifiedStatus = status === 1 ? 0 : 1
    console.log('modifiedStatus', modifiedStatus)
    Api.put('/auction/changeActiveStatus', {
      id: auctionId,
      status: modifiedStatus,
    })
      .then(() => {
        dispatch(auctionActions.setLoading(false))
        if (status === 1) {
          message.success('Event Deactivated Successfully')
        } else {
          message.success('Event Activated Successfully')
        }
        if (nagigate) {
          nagigate()
        }
      })
      .catch((error) => {
        console.log('error', error)
        message.error('Something Went Wrong')
        dispatch(auctionActions.setLoading(false))
      })
  }

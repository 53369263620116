import React, { useState } from 'react'
import { ComponentView } from 'components/common/componentView'
import {
  // FavouriteList,
  // ServiceList,
  InactiveList,
  MyServices,
} from './components/ServiceLists'

const Services = () => {
  const [searchText, setSearchText] = useState(null)
  const [sortSwitchValue, setSortSwitchValue] = useState(false)
  const [sortKey, setSortKey] = useState('CreatedDate')

  const heading = 'Services'
  const optionList = {
    // all: <ServiceList searchText={searchText} />,
    my: <MyServices searchText={searchText} />,
    inactive: <InactiveList searchText={searchText} />,
    // favorite: <FavouriteList searchText={searchText} sortKey={sortKey} />,
  }
  const createButtonLink = '/settings/create-service'

  const sortFilterData = [
    {
      label: 'By Created Date',
      value: 'CreatedDate',
      onlyShowOn: 'favorite',
      showOn: ['favorite'],
    },
    {
      label: 'By Favorite Date',
      value: 'favDate',
      showOn: ['favorite'],
    },
  ]

  return (
    <ComponentView
      heading={heading}
      optionList={optionList}
      createButtonLink={createButtonLink}
      setSearchText={setSearchText}
      sortFilterData={sortFilterData}
      sortKey={sortKey}
      setSortKey={setSortKey}
      sortSwitchValue={sortSwitchValue}
      setSortSwitchValue={setSortSwitchValue}
    />
  )
}

export { Services }

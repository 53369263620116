import { config } from 'configs'
import { useAuthSelector } from 'store/authSlice/authReducer'

const useRoles = () => {
  const { user } = useAuthSelector()

  const isDonor = user?.roles.includes(config?.roles?.donor)
  const isPresenter = user?.roles.includes(config?.roles?.presenter)
  const isSubscriber = user?.roles.includes(config?.roles?.subscriber)
  const isBusiness = user?.roles.includes(config?.roles?.business)
  const isAdmin = user?.roles.includes(config?.roles?.admin)

  return {
    isDonor,
    isPresenter,
    isSubscriber,
    isBusiness,
    isAdmin,
  }
}

export { useRoles }

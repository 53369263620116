/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Dropdown, Menu } from 'antd'
import { AppstoreFilled } from '@ant-design/icons'
import { HiOutlineDotsVertical } from 'react-icons/hi'
import { useHistory, useLocation } from 'react-router-dom'
import { EventReports } from './EventReports'

const MyReports = () => {
  const history = useHistory()
  const [activeKey, setActiveKey] = useState('1')
  const { search } = useLocation()

  useEffect(() => {
    if (search) {
      const urlSearchParams = new URLSearchParams(search)
      const { key } = Object.fromEntries(urlSearchParams.entries())
      if (key) setActiveKey(key)
    }
    // eslint-disable-next-line
  }, [])

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <div
              onClick={() => setActiveKey('1')}
              id="reports-dropdown-financial"
            >
              Financial Reports
            </div>
          ),
        },
        {
          key: '2',
          label: (
            <div onClick={() => setActiveKey('2')} id="reports-dropdown-donor">
              Donor Reports
            </div>
          ),
        },
        {
          key: '3',
          label: (
            <div onClick={() => setActiveKey('3')} id="reports-dropdown-events">
              Event Reports
            </div>
          ),
        },
      ]}
    />
  )

  return (
    <Card className="header-solid" bordered={false}>
      <div className="t-flex t-justify-between t-items-center t-mx-2">
        <h6 className="mb-0 t-text-secondary-100 t-font-bold t-text-xl">
          My Reports
        </h6>
        <Dropdown overlay={menu} placement="bottomLeft" arrow>
          <HiOutlineDotsVertical
            fontSize="1.5rem"
            id="reports-dropdown"
            className="t-cursor-pointer t-text-secondary-100 t-mx-1"
          />
        </Dropdown>
      </div>
      <div>
        {activeKey === '1' && (
          <>
            <div className="t-my-2 t-mx-2">
              <h1 className="t-font-bold t-text-md t-text-secondary-100">
                Financial Reports
              </h1>
            </div>
            <Row gutter={[24, 24]}>
              <Col span={24} className="t-space-y-2">
                <Card
                  className="header-solid t-cursor-pointer hover:t-bg-primary-100 t-text-secondary-100 hover:t-text-white"
                  bordered={false}
                >
                  <div
                    className="t-flex t-items-center t-space-x-2"
                    onClick={() =>
                      history.push(
                        '/settings/manage-organization/my-reports/ytd-earnings-forecast-report',
                      )
                    }
                  >
                    <AppstoreFilled className="text-lg" />
                    <p>YTD Revenue Report</p>
                  </div>
                </Card>
                <Card
                  className="header-solid t-cursor-pointer hover:t-bg-primary-100 t-text-secondary-100 hover:t-text-white"
                  bordered={false}
                >
                  <div
                    className="t-flex t-items-center t-space-x-2"
                    onClick={() =>
                      history.push(
                        '/settings/manage-organization/my-reports/fy-earnings-forecast-report',
                      )
                    }
                  >
                    <AppstoreFilled className="text-lg" />
                    <p>FY Revenue Forecast Report</p>
                  </div>
                </Card>
                <Card
                  className="header-solid t-cursor-pointer hover:t-bg-primary-100 t-text-secondary-100 hover:t-text-white"
                  bordered={false}
                >
                  <div
                    className="t-flex t-items-center t-space-x-2"
                    onClick={() =>
                      history.push(
                        '/settings/manage-organization/my-reports/last-year-earnings-report',
                      )
                    }
                  >
                    <AppstoreFilled className="text-lg" />
                    <p>Last Year Revenue Report</p>
                  </div>
                </Card>
                <Card
                  className="header-solid t-cursor-pointer hover:t-bg-primary-100 t-text-secondary-100 hover:t-text-white"
                  bordered={false}
                >
                  <div
                    className="t-flex t-items-center t-space-x-2"
                    onClick={() =>
                      history.push(
                        '/settings/manage-organization/my-reports/pledge-conversion-reports',
                      )
                    }
                  >
                    <AppstoreFilled className="text-lg" />
                    <p>Pledge Conversion Reports</p>
                  </div>
                </Card>
                <Card
                  className="header-solid t-cursor-pointer hover:t-bg-primary-100 t-text-secondary-100 hover:t-text-white"
                  bordered={false}
                >
                  <div
                    className="t-flex t-items-center t-space-x-2"
                    onClick={() =>
                      history.push(
                        '/settings/manage-organization/my-reports/annual-costs',
                      )
                    }
                  >
                    <AppstoreFilled className="text-lg" />
                    <p>Annual Costs</p>
                  </div>
                </Card>
              </Col>
            </Row>
          </>
        )}

        {activeKey === '2' && (
          <>
            <div className="t-my-2 t-mx-2">
              <h1 className="t-font-bold t-text-md t-text-secondary-100">
                Donor Reports
              </h1>
            </div>
            <Row gutter={[24, 24]}>
              <Col span={24} className="t-space-y-2">
                <Card
                  className="header-solid t-cursor-pointer hover:t-bg-primary-100 t-text-secondary-100 hover:t-text-white"
                  bordered={false}
                >
                  <div
                    className="t-flex t-items-center t-space-x-2"
                    onClick={() =>
                      history.push(
                        '/settings/manage-organization/my-reports/master-donor-list-report',
                      )
                    }
                  >
                    <AppstoreFilled className="text-lg" />
                    <p>Master Community List</p>
                  </div>
                </Card>
                <Card
                  className="header-solid t-cursor-pointer hover:t-bg-primary-100 t-text-secondary-100 hover:t-text-white"
                  bordered={false}
                >
                  <div
                    className="t-flex t-items-center t-space-x-2"
                    onClick={() =>
                      history.push(
                        '/settings/manage-organization/my-reports/active-donors-report',
                      )
                    }
                  >
                    <AppstoreFilled className="text-lg" />
                    <p>Active Donors Report</p>
                  </div>
                </Card>
                <Card
                  className="header-solid t-cursor-pointer hover:t-bg-primary-100 t-text-secondary-100 hover:t-text-white"
                  bordered={false}
                >
                  <div
                    className="t-flex t-items-center t-space-x-2"
                    onClick={() =>
                      history.push(
                        '/settings/manage-organization/my-reports/dormant-donors-report',
                      )
                    }
                  >
                    <AppstoreFilled className="text-lg" />
                    <p>Dormant Donors Report</p>
                  </div>
                </Card>
                <Card
                  className="header-solid t-cursor-pointer hover:t-bg-primary-100 t-text-secondary-100 hover:t-text-white"
                  bordered={false}
                >
                  <div
                    className="t-flex t-items-center t-space-x-2"
                    onClick={() =>
                      history.push(
                        '/settings/manage-organization/my-reports/top-50-donors-report',
                      )
                    }
                  >
                    <AppstoreFilled className="text-lg" />
                    <p>Top 50 Donors Report</p>
                  </div>
                </Card>
                <Card
                  className="header-solid t-cursor-pointer hover:t-bg-primary-100 t-text-secondary-100 hover:t-text-white"
                  bordered={false}
                >
                  <div
                    className="t-flex t-items-center t-space-x-2"
                    onClick={() =>
                      history.push(
                        '/settings/manage-organization/my-reports/ytd-account-donation',
                      )
                    }
                  >
                    <AppstoreFilled className="text-lg" />
                    <p>YTD Account Donation Report</p>
                  </div>
                </Card>
              </Col>
            </Row>
          </>
        )}

        {activeKey === '3' && (
          <>
            <div className="t-my-2 t-mx-2">
              <h1 className="t-font-bold t-text-md t-text-secondary-100">
                Events Reports
              </h1>
            </div>
            <EventReports />
          </>
        )}
      </div>
    </Card>
  )
}

export { MyReports }

import React, { useEffect, useState } from 'react'
import { Modal, Typography, Skeleton } from 'antd'
import { MdPreview } from 'react-icons/md'
import { formatAmount } from 'utils'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { InfiniteScrollContainer, ShareReport } from 'components/elements'

const { Title } = Typography

function DonationsSummary({ title }) {
  const [visible, setVisible] = useState(false)

  const { loading, ytd } = useAuthSelector()

  const itemsFirstLoad = 6
  const [data, setData] = useState([])
  const [current, setCurrent] = useState([])

  useEffect(() => {
    document.body.style = visible
      ? 'overflow: hidden !important'
      : 'overflow: auto'
  }, [visible])

  useEffect(() => {
    if (ytd?.org?.length > 0) {
      setData(ytd?.org)
      setCurrent(ytd?.org?.slice(0, itemsFirstLoad))
    }
    // eslint-disable-next-line
  }, [ytd])

  return (
    <>
      <div>
        <MdPreview
          id="view-last-year-donation-summary"
          className="t-text-secondary-100"
          fontSize="2rem"
          onClick={() => setVisible(true)}
        />
      </div>
      <Modal
        centered
        keyboard
        title={title || 'YTD Donations Summary'}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={[]}
      >
        <div className="t-w-full t-h-96">
          <div className="t-flex t-justify-between t-items-center t-mb-4">
            <Title level={5}>Donations</Title>
            {!loading && ytd?.org && ytd?.org.length > 0 && (
              <ShareReport
                reportName="Last Year Donation Summary Report"
                report={ytd?.org}
              />
            )}
          </div>
          <Skeleton loading={loading} active paragraph={{ rows: 6 }}>
            <div className="t-h-80 t-relative">
              {data.length === 0 && (
                <div className="t-flex t-justify-center t-items-center t-h-full t-w-full">
                  No donations recorded. Support a cause today.
                </div>
              )}
              {data.length > 0 && (
                <InfiniteScrollContainer
                  data={data}
                  current={current}
                  setCurrent={setCurrent}
                  height="280px"
                  itemsFirstLoad={itemsFirstLoad}
                >
                  <>
                    {current.map((item, index) => (
                      <div
                        key={index}
                        className="t-w-full t-rounded-2xl t-h-12 t-p-3 t-bg-yellow-100 t-text-secondary-100 t-mb-2 t-flex t-items-center t-shadow-md t-justify-between"
                      >
                        <div className="t-flex t-space-x-2 t-items-center">
                          {item?.OrganizationName}
                        </div>
                        <div className="t-flex t-flex-col">
                          <h2 className="t-font-bold t-text-secondary-100 t-text-right t-text-xs md:t-text-sm">
                            {`${formatAmount(item?.amount)}`}
                          </h2>
                        </div>
                      </div>
                    ))}
                  </>
                </InfiniteScrollContainer>
              )}
            </div>
          </Skeleton>
        </div>
      </Modal>
    </>
  )
}

export { DonationsSummary }

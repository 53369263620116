import React from 'react'
import { Avatar, Col, Tooltip } from 'antd'
import sample from 'assets/images/sample/sample1.png'
import { awsconfig } from 'configs'
import { AiFillPhone } from 'react-icons/ai'
import { CgWebsite } from 'react-icons/cg'
import { HiOutlineMail } from 'react-icons/hi'
import { MyOrganization } from './MyOrganization'

const formatPhoneNumber = (Number) => {
  const PhoneNumber = Number.replace(/^0+/, '')
  const firstThree = PhoneNumber.substring(0, 3)
  const secondThree = PhoneNumber.substring(3, 6)
  const lastFour = PhoneNumber.substring(6, PhoneNumber.length)
  const phone = `+1 (${firstThree}) ${secondThree}-${lastFour}`

  return phone
}

const OrganizationProfile = ({ organization }) => {
  const addProtocolToURL = (url) => {
    let newUrl = url
    const protocolRegex = /^(https?:\/\/)/i

    if (!protocolRegex.test(url)) {
      // Protocol is missing, add "http://" by default
      newUrl = `http://${url}`
    }

    return newUrl
  }

  return (
    <>
      <Col span={24}>
        <div className="t-flex t-flex-col t-items-center">
          <img
            src={
              organization?.BackgroundImage
                ? awsconfig.bucketUrl + organization?.BackgroundImage?.fileName
                : sample
            }
            alt="card-2"
            className="t-h-48 md:t-h-80 t-w-full t-rounded-lg shadow-3 t-bg-cover t-bg-no-repeat t-opacity-80"
          />
          <div className="t-w-[90%] t-relative -t-top-12 glass_bg t-p-2 md:t-p-3">
            <div className="t-w-full t-flex t-justify-between">
              <Avatar.Group>
                <Avatar
                  size={70}
                  shape="square"
                  src={
                    organization?.Logo
                      ? awsconfig.bucketUrl + organization?.Logo?.fileName
                      : sample
                  }
                />
                <div className="avatar-info t-ml-4">
                  <h4 className="font-semibold m-0 t-text-secondary-100 t-text-base md:t-text-2xl">
                    <Tooltip title="Organization Name">
                      {organization?.Name}
                    </Tooltip>
                  </h4>
                  <p className="t-text-secondary-100">
                    <Tooltip title="Organization Type">
                      {organization?.OrganizationType}
                    </Tooltip>
                  </p>
                  <p className="t-text-secondary-100">
                    <Tooltip title="Organization Address">
                      {`
                  ${organization.Address1 ? `${organization.Address1}, ` : ''}
                  ${organization.City}, ${organization.State}`}
                    </Tooltip>
                  </p>
                </div>
              </Avatar.Group>
              <div className="t-flex t-items-center t-space-x-2">
                <Tooltip
                  title={`Phone Number: ${
                    organization?.PhoneNumber &&
                    formatPhoneNumber(organization.PhoneNumber)
                  }`}
                >
                  <AiFillPhone
                    fontSize="1.2rem"
                    className="t-text-secondary-100 t-cursor-pointer"
                    onClick={() =>
                      window.open(`tel:${organization?.PhoneNumber}`)
                    }
                  />
                </Tooltip>
                <Tooltip title={`Email: ${organization?.Email}`}>
                  <HiOutlineMail
                    fontSize="1.2rem"
                    className="t-text-secondary-100 t-cursor-pointer"
                    onClick={() => window.open(`mailto:${organization?.Email}`)}
                  />
                </Tooltip>
                <Tooltip title={`Website: ${organization?.Website}`}>
                  <CgWebsite
                    fontSize="1.2rem"
                    className="t-text-secondary-100 t-cursor-pointer"
                    onClick={() =>
                      window.open(addProtocolToURL(organization?.Website))
                    }
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col span={24} className="col-info">
        <MyOrganization id={organization.id} />
      </Col>
    </>
  )
}

export { OrganizationProfile }

import React from 'react'
import { message, Modal } from 'antd'
import { BsFacebook, BsWhatsapp, BsLinkedin } from 'react-icons/bs'
import { FaSquareXTwitter } from 'react-icons/fa6'
import { FaCreativeCommonsShare } from 'react-icons/fa'
// import Api from 'api'

const socialMediaColors = {
  facebook: '#3b5998',
  linkledin: '#0072b1',
  whatsapp: '#25D366',
  twitter: '#000000',
}

const iconSize = '3rem'

const ShareModal = ({ url, visible, onClose }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(url)
    message.success('Copied to Clipboard')
    // Api.post(`publicEvents/generate-shortlink`, { url })
    //   .then((res) => {
    //     const link = res.results.shorturl
    //     navigator.clipboard.writeText(link)
    //     message.success('Copied to Clipboard')
    //   })
    //   .catch(() => {
    //     message.error('Could not copy short url')
    //   })
  }

  return (
    <Modal
      centered
      title="Share On Social Media"
      open={visible}
      onCancel={onClose}
      width={400}
      okText="Donate"
      footer={null}
    >
      <div className="t-py-4 t-flex t-justify-between t-items-center t-space-x-1">
        <FaCreativeCommonsShare
          className="t-text-secondary-100 t-cursor-pointer"
          onClick={copyToClipboard}
          fontSize={iconSize}
        />
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Share on Facebook"
        >
          <BsFacebook fontSize={iconSize} color={socialMediaColors.facebook} />
        </a>
        <a
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Share on Linkedin"
        >
          <BsLinkedin fontSize={iconSize} color={socialMediaColors.linkledin} />
        </a>
        <a
          href={`https://twitter.com/share?url=${url}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Share on X"
        >
          <FaSquareXTwitter
            fontSize={iconSize}
            color={socialMediaColors.twitter}
          />
        </a>
        <a
          href={`https://web.whatsapp.com/send?text=${url}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Share on Whatsapp"
        >
          <BsWhatsapp fontSize={iconSize} color={socialMediaColors.whatsapp} />
        </a>
      </div>
    </Modal>
  )
}

export { ShareModal }

import React, { useEffect, useState } from 'react'
import {
  Form,
  Input,
  Row,
  Col,
  Card,
  Skeleton,
  Select,
  Button,
  Divider,
  message,
} from 'antd'
import { businessValidation } from 'validations/business'
import { MaskedInput } from 'antd-mask-input'
import { useHistory, useParams } from 'react-router-dom'
import { MdSmsFailed } from 'react-icons/md'
import { ImageUploader } from 'components/elements'
import { useAuthSelector } from 'store/authSlice/authReducer'
import {
  getAssociations,
  getBusinessCategoriesList,
  getMyBusiness,
  registerBusiness,
  registrationStatus,
  updateBusiness,
} from 'store/businessSlice/businessActions'
import { formatUSAPhoneNumber, MaskPattern } from 'utils'
import { AutoCompleteAddress } from 'components/common'
import { fetchUserDetail } from 'store/authSlice/authActions'
import { useDispatch } from 'react-redux'
import { config } from 'configs'
import AddServiceModal from './addServiceModal'
import Services from './services'

const { TextArea } = Input
// const { Option } = Select

const initialValues = {
  Name: '',
  Description: '',
  // Country: 'US',
  PhoneNumber: '',
  Website: '',
  BackgroundImage: null,
  ProfilePicture: null,
  SearchKeywords: [],
  DeliveryLocation: [],
  // ChargableServices: false,
  // Note: '',
  SecondaryAssociation: [],
}

// const countriesOptions = [
//   {
//     label: 'United States',
//     value: 'US',
//   },
//   {
//     label: 'Canada',
//     value: 'Canada',
//   },
// ]

// const primaryBusinessCategoryOptions = [
//   'Home/Labor Services',
//   'Construction, Home Improvement, Handyman Services',
//   'Marketing and Advertising',
//   'Creative Design Services',
//   'Photography and Movie Services',
//   'Publishing Services',
//   'Online/Internet Services',
//   'Restaurants and Catering',
//   'Retail/Storefront',
//   'Event Orchestration (emcee, keynote speaker, fundraising expert)',
//   'Legal, Compliance',
//   'Accounting / Bookkeeping',
//   'Real Estate Rentals and Sales',
//   'Arts and Crafts',
//   'Education and Tutoring Services',
//   'Automotive Customization or Repairs',
//   'Insurance Brokers and Agents',
//   'Business BackOffice Services',
//   'Babysitting and Child Care Services',
//   'Cleaning or Restoration Services',
//   'Entertainment and Party Services',
//   'Health and Beauty Services',
//   'Physical Therapy and Fitness Services',
//   'Professional Services',
//   'Sports and Recreation Services',
//   'Computer Related Repairs and Sales',
//   'Medical Services',
//   'Storage, Shipping, Delivery, and Transportation Services',
//   'Travel, Tourism and Vacation Services',
//   'Engineering and Technical Services ',
//   'Other',
// ]

const RegisterBusiness = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  // const { isLoading, isAcccessGranted, isSuccess, lat, lng } = useZipCode()
  const { user } = useAuthSelector()
  const oldWebsiteValueRef = React.useRef()
  const searchKeywordsRef = React.useRef(null)
  const [form] = Form.useForm()
  // Form.useWatch('ChargableServices', form)
  // Form.useWatch('ServiceCoverage', form)
  Form.useWatch('SecondaryAssociation', form)

  // const [loadingLocation, setLoadingLocation] = useState(false)
  // const [isAllowed, setIsAllowed] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  // const [donorsSelected, setDonorsSelected] = useState([])
  const [addServiceModal, setAddServiceModal] = useState(false)
  const [services, setServices] = useState([])
  const [selectedServiceId, setSelectedServiceId] = useState(null)
  const [loadingBusinessCategoriesList, setLoadingBusinessCategoriesList] =
    useState(true)
  const [businessCategoriesList, setBusinessCategoriesList] = useState([])
  const [loadingAssociations, setLoadingAssociations] = useState(true)
  const [associations, setAssociations] = useState([])
  const [isRegisterAlreadyLoading, setIsRegisterAlreadyLoading] = useState(true)
  const [isBusinessAlreadyRegister, setBusinessIsAlreadyRegister] =
    useState(false)

  // const getLocation = () => {
  //   setLoadingLocation(true)
  //   Api.put('donor/getZipCodeByPosition', { lat, lon: lng })
  //     .then((res) => {
  //       const resCountry = res?.results?.country
  //       setLoadingLocation(false)
  //       const allowedCountry = ['US', 'Canada']
  //       const condition = allowedCountry.includes(resCountry)
  //       setIsAllowed(condition)
  //     })
  //     .catch(() => setLoadingLocation(false))
  // }

  // useEffect(() => {
  //   getLocation()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [lat, lng])

  const GetData = async () => {
    try {
      if (!params.id) {
        const isAlreadyRegister = await registrationStatus(user?.id)
        if (isAlreadyRegister) {
          setBusinessIsAlreadyRegister(isAlreadyRegister)
        }
      }
      if (params.id) {
        const business = await getMyBusiness(user?.id)
        if (business) {
          form.setFieldsValue({
            Name: business.Name,
            Description: business.Description,
            PhoneNumber: formatUSAPhoneNumber(business.PhoneNumber),
            mailingAddress: business?.Address1
              ? business.Address1
              : `${business?.Address2 && `${business?.Address2},`}, ${
                  business.City
                }, ${business.State}, ${business.Country}`,
            suite: business?.Address2,
            zipCode: business?.ZipCode,
            city: business?.City,
            state: business?.State,
            country: business?.Country,
            Website: business.Website,
            BackgroundImage:
              business.BackgroundImage && business.BackgroundImage,
            ProfilePicture: business.ProfilePicture && business.ProfilePicture,
            SearchKeywords: business.SearchKeywords,
            // ChargableServices: business.ChargableServices,
            PrimaryBusinessCategory: business.PrimaryBusinessCategory,
            DeliveryLocation: business.DeliveryLocation,
            // ServiceCoverage: business.ServiceCoverage,
            // Note: business.Note,
          })
          if (business?.Assocaitions && business?.Assocaitions?.length) {
            // for secondary association
            const SecondaryAssociation = business?.Assocaitions?.map(
              (item) => item.OrganizationId,
            )
            if (SecondaryAssociation) {
              form.setFieldsValue({
                SecondaryAssociation,
              })
            }
          }
          if (business?.Services && business?.Services?.length)
            setServices(business?.Services)
        } else {
          message.error('Business not found')
          history.push('/settings/register-business')
        }
      }
      setIsRegisterAlreadyLoading(false)
    } catch (err) {
      setIsRegisterAlreadyLoading(false)
      console.log('err', err)
    }
  }

  const GetAssociation = async () => {
    try {
      const response = await getAssociations()
      const dummyAssociatation = response?.results
      if (dummyAssociatation?.length > 0) {
        setAssociations(dummyAssociatation)
        const invitations = response?.invitations
        if (Array.isArray(invitations) && invitations.length > 0) {
          const defaultSecondaryAssociation =
            form.getFieldValue('SecondaryAssociation') || []
          const newSecondaryAssociation = [
            ...defaultSecondaryAssociation,
            ...invitations,
          ]
          if (!params.id) {
            form.setFieldValue('SecondaryAssociation', newSecondaryAssociation)
          }
        }
      }
      setLoadingAssociations(false)
    } catch (err) {
      setLoadingAssociations(false)
      console.log('err', err)
    }
  }

  const GetBusinessCategoryOptions = async () => {
    try {
      const response = await getBusinessCategoriesList()
      if (response && Array.isArray(response) && response.length > 0) {
        setBusinessCategoriesList(response)
      }
      setLoadingBusinessCategoriesList(false)
    } catch (error) {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      setLoadingBusinessCategoriesList(false)
    }
  }

  useEffect(() => {
    GetBusinessCategoryOptions()
    GetAssociation()
    GetData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const afterRegisterAction = () => {
    // message.success(
    //   'Your business listing has been forwarded for review. Please allow 3 to 5 business days for a decision.',
    // )
    message.success('Your business listing has been forwarded for review.')
    setButtonLoading(false)
    form.resetFields()
    history.push('/settings/manage-organization/business-detail')
  }

  const onFinish = async (values) => {
    let data = {
      Name: values.Name,
      Description: values.Description,
      PhoneNumber: values.PhoneNumber.replace(/\D/g, ''),
      Address1: values.mailingAddress,
      Address2: values.suite,
      ZipCode: values.zipCode,
      City: values.city,
      State: values.state,
      Country: values.country,
      Website: values.Website,
      BackgroundImage: values.BackgroundImage || null,
      ProfilePicture: values.ProfilePicture || null,
      SearchKeywords: values.SearchKeywords,
      PrimaryBusinessCategory: values.PrimaryBusinessCategory,
      // PrimaryAssociation: values.PrimaryAssociation,
      SecondaryAssociation: values.SecondaryAssociation || [],
      DeliveryLocation: values.DeliveryLocation,
      // ServiceCoverage: values.ServiceCoverage,
      ServiceCoverage: 'Global',
      // Note: values.Note,
      // ...values,
      UserId: user.id,
      UserEmail: user.email,
      UserName: `${user.firstname} ${user.lastname}`,
      UserPhoneNumber: user.phone,
      Services: services,
    }
    try {
      setButtonLoading(true)
      if (params.id) {
        data = { Id: params.id, ...data }
        await updateBusiness(data)
        message.success(`Business detail updated successfully`)
        history.push('/settings/manage-organization/business-detail')
        setButtonLoading(false)
      } else {
        await registerBusiness(data)
        dispatch(fetchUserDetail(afterRegisterAction))

        // history.push('/businesses')
      }
    } catch (error) {
      message.error(error.response?.data?.message || 'Something Went Wrong')
      setButtonLoading(false)
    }
  }

  const handleWebsiteChange = (value) => {
    const oldVal = oldWebsiteValueRef.current
    let val = value
    if (value.length === 1 && oldVal.length < 1) {
      val = `https://www.${value}`
    }
    oldWebsiteValueRef.current = val
    form.setFieldsValue({ Website: val })
  }

  const editService = (Id) => {
    setSelectedServiceId(Id)
    setAddServiceModal(true)
  }

  const filteredAssociations = associations || []

  const sortedPrimaryBusinessCategoryOptions = businessCategoriesList.sort()

  return (
    // <>
    //   {!isLoading && isAcccessGranted && isSuccess ? (
    //     <Skeleton
    //       loading={loadingAssociations || loadingBusinessCategoriesList}
    //       active
    //       paragraph={{ rows: 18 }}
    //     >
    //       {isAllowed ? (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0 t-text-2xl">Register Your Business</h6>
        </>,
      ]}
    >
      <div style={{ minHeight: 'calc(100vh - 220px)' }}>
        <Skeleton
          loading={
            loadingAssociations ||
            loadingBusinessCategoriesList ||
            isRegisterAlreadyLoading
          }
          active
          paragraph={{ rows: 18 }}
        >
          {isBusinessAlreadyRegister ? (
            <div
              className="t-w-full t-flex t-justify-center t-items-center"
              style={{ height: 'calc(74vh)' }}
            >
              <div className="t-flex t-flex-col t-justify-center t-items-center">
                <MdSmsFailed fontSize="3rem" className="t-text-secondary-100" />
                <p>
                  Your business registration has been received and is pending
                  approval.
                </p>
              </div>
            </div>
          ) : (
            <Form
              form={form}
              layout="vertical"
              key={0}
              onFinish={onFinish}
              name="business"
              initialValues={initialValues}
              scrollToFirstError
            >
              <Row gutter={[24, 0]}>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <Form.Item
                    name="Name"
                    label="Business Name*"
                    colon={false}
                    rules={[businessValidation]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                {/* <Col xl={12} md={12} xs={24} sm={24}>
                        <Form.Item
                          name="FEIN"
                          label="Federal Employer Identification Number"
                          colon={false}
                          rules={[businessValidation]}
                        >
                          <Input
                            onChange={(e) => handleTaxIdChange(e.target.value)}
                          />
                        </Form.Item>
                      </Col> */}
                <Col span={24}>
                  <Form.Item
                    name="Description"
                    label="Brief Business Description*"
                    colon={false}
                    rules={[businessValidation]}
                  >
                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                  </Form.Item>
                </Col>
                {/* <Col xl={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            name="Country"
                            label="Select Country"
                            colon={false}
                            rules={[businessValidation]}
                          >
                            <Select
                              placeholder="Select Country"
                              style={{ width: '100%' }}
                            >
                              {countriesOptions.map((item) => (
                                <Select.Option
                                  value={item.value}
                                  key={item.value}
                                >
                                  {item.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col> */}
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="PhoneNumber"
                    label="Business Phone Number*"
                    colon={false}
                    rules={[businessValidation]}
                  >
                    <MaskedInput
                      inputMode="numeric"
                      mask={MaskPattern}
                      onChange={(e) => {
                        form.setFieldsValue({
                          PhoneNumber: e.maskedValue,
                        })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Divider />
                <AutoCompleteAddress
                  form={form}
                  validation
                  isInitiallyValidated={params.id}
                />
                <Divider />
                <Col span={24}>
                  <Form.Item
                    label="Website"
                    name="Website"
                    colon={false}
                    rules={[businessValidation]}
                  >
                    <Input
                      onChange={(e) => handleWebsiteChange(e.target.value)}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="ProfilePicture"
                    label="Business Owner Photo"
                    colon={false}
                    // rules={[businessValidation]}
                  >
                    <ImageUploader
                      defaultFile={
                        form.getFieldValue('ProfilePicture') &&
                        form.getFieldValue('ProfilePicture')
                      }
                      onFileChange={(file) =>
                        form.setFieldsValue({
                          ProfilePicture: file?.originFileObj,
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="BackgroundImage"
                    label="Business Banner Image"
                    colon={false}
                    // rules={[businessValidation]}
                  >
                    <ImageUploader
                      ratio={3 / 1}
                      defaultFile={
                        form.getFieldValue('BackgroundImage') &&
                        form.getFieldValue('BackgroundImage')
                      }
                      onFileChange={(file) =>
                        form.setFieldsValue({
                          BackgroundImage: file?.originFileObj,
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="SearchKeywords"
                    label="Search keywords"
                    colon={false}
                  >
                    <Select
                      ref={searchKeywordsRef}
                      mode="tags"
                      style={{
                        width: '100%',
                      }}
                      placeholder="Search keywords"
                      onChange={() => searchKeywordsRef.current.blur()}
                    />
                  </Form.Item>
                </Col>
                {/* <Col span={24}>
                          <Form.Item
                            name="ChargableServices"
                            label={
                              <div className="t-flex t-items-center t-space-x-2">
                                <p>Chargeable Services</p>
                                <Tooltip title="Will you be charging for your services?">
                                  <BsInfoCircleFill className="t-text-secondary-100 t-cursor-pointer" />
                                </Tooltip>
                              </div>
                            }
                            colon={false}
                            rules={[businessValidation]}
                          >
                            <Switch
                              checked={form.getFieldValue('ChargableServices')}
                            />
                          </Form.Item>
                        </Col> */}
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="PrimaryBusinessCategory"
                    label="Business Category*"
                    colon={false}
                    rules={[businessValidation]}
                  >
                    <Select
                      placeholder="Select your business category"
                      style={{ width: '100%' }}
                    >
                      {sortedPrimaryBusinessCategoryOptions?.map((item) => (
                        <Select.Option value={item.Id}>
                          {item.Description}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="DeliveryLocation"
                    label="Delivery Location*"
                    colon={false}
                    rules={[businessValidation]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select Delivery Type"
                      style={{ width: '100%' }}
                    >
                      <Select.Option value="Onsite">Onsite</Select.Option>
                      <Select.Option value="Online">Online</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col xl={24} md={24} xs={24} sm={24}>
                          <Form.Item
                            name="ServiceCoverage"
                            label="Service Coverage"
                            colon={false}
                            rules={[businessValidation]}
                          >
                            <Select
                              placeholder="Select Service Coverage"
                              style={{ width: '100%' }}
                            >
                              <Select.Option value="Local">Local</Select.Option>
                              <Select.Option value="Global">
                                Global
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col> */}
                {/* {isLocalCoverage && (
                          <Col xl={24} md={24} xs={24} sm={24}>
                            <Form.Item
                              name="PrimaryAssociation"
                              label="Primary Association"
                              colon={false}
                              rules={[businessValidation]}
                            >
                              <Select
                                placeholder="Select PrimaryAssociation"
                                style={{ width: '100%' }}
                                notFoundContent="Since you are not associated with any organizations, select global coverage or associate with an organization by make it favourite"
                                onChange={() =>
                                  form.setFieldValue('SecondaryAssociation', [])
                                }
                                options={associations}
                              />
                            </Form.Item>
                          </Col>
                        )} */}
                <Col xl={24} md={24} xs={24} sm={24}>
                  <Form.Item
                    name="SecondaryAssociation"
                    label="Business Association"
                    colon={false}
                    // rules={[businessValidation]}
                  >
                    <Select
                      mode="multiple"
                      style={{
                        width: '100%',
                      }}
                      placeholder="Select Business Association"
                      options={filteredAssociations}
                    />
                  </Form.Item>
                </Col>
                <>
                  <Services
                    services={services}
                    setServices={setServices}
                    setAddServiceModal={setAddServiceModal}
                    editService={editService}
                  />
                  <Divider />
                </>
                {/* <Col span={24}>
                          <Form.Item
                            name="Note"
                            label="Note to Approvers"
                            colon={false}
                            // rules={[businessValidation]}
                          >
                            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                          </Form.Item>
                        </Col> */}
                <Col span={24} className="text-left t-space-x-2">
                  <Button
                    loading={buttonLoading}
                    type="primary"
                    className="px-25"
                    htmlType="submit"
                  >
                    {params.id ? 'Update Business' : 'Register Business'}
                  </Button>
                  <Button
                    type="primary"
                    className="px-25"
                    onClick={() => history.push('/businesses')}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
          <AddServiceModal
            visible={addServiceModal}
            close={() => setAddServiceModal(false)}
            services={services}
            setServices={setServices}
            selectedServiceId={selectedServiceId}
            setSelectedServiceId={setSelectedServiceId}
          />
        </Skeleton>
      </div>
    </Card>
    //       ) : (
    //         <div
    //           className="t-w-full t-flex t-justify-center t-items-center"
    //           style={{ height: 'calc(74vh)' }}
    //         >
    //           <div className="t-flex t-flex-col t-justify-center t-items-center">
    //             <MdSmsFailed fontSize="3rem" className="t-text-secondary-100" />
    //             <p>This Feature is not available in your country</p>
    //           </div>
    //         </div>
    //       )}
    //     </Skeleton>
    //   ) : (
    //     <Skeleton loading={isLoading} active paragraph={{ rows: 18 }}>
    //       <div
    //         className="t-w-full t-flex t-justify-center t-items-center"
    //         style={{ height: 'calc(74vh)' }}
    //       >
    //         <div className="t-flex t-flex-col t-justify-center t-items-center">
    //           <MdSmsFailed fontSize="3rem" className="t-text-secondary-100" />
    //           <p>
    //             {isAcccessGranted
    //               ? 'Error in getting location'
    //               : 'Please Allow Location Access to continue'}
    //           </p>
    //         </div>
    //       </div>
    //     </Skeleton>
    //   )}
    // </>
  )
}

export { RegisterBusiness }

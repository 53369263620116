import React, { useState } from 'react'
import { Row, Col, Button, Modal, Form, Radio, message } from 'antd'
import Api from 'api'

const initialValues = {
  MasterRecord: 0,
  DonorName: 0,
  DonorEmail: 0,
  DonorPhoneNumber: 0,
  DonorZipCode: 0,
}

const MergeDonorModal = ({ isVisible, onClose, mergeData, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const updateFields = (value) => {
    form.setFieldsValue({ DonorName: value })
    form.setFieldsValue({ DonorEmail: value })
    form.setFieldsValue({ DonorPhoneNumber: value })
    form.setFieldsValue({ DonorZipCode: value })
  }

  const onFinish = (values) => {
    setLoading(true)
    const data = {
      MasterRecord: mergeData[values.MasterRecord]?.Id,
      SlaveRecord:
        values.MasterRecord === 0 ? mergeData[1]?.Id : mergeData[0]?.Id,
      DonorName: mergeData[values.DonorName]?.DonorName,
      DonorEmail: mergeData[values.DonorEmail]?.DonorEmail,
      DonorPhoneNumber: mergeData[values.DonorPhoneNumber]?.DonorPhoneNumber,
      DonorZipCode: mergeData[values.DonorZipCode]?.DonorZipCode,
    }
    Api.post('subscriber/mergeDonor', { data })
      .then(() => {
        message.success('Successfully merged')
        setLoading(false)
        refresh()
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
        message.error('An error occured')
      })
  }

  return (
    <Modal
      centered
      title="Merge Donor Modal"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="Merge Donor"
        initialValues={initialValues}
        scrollToFirstError
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item name="MasterRecord" label="Master Record:" colon={false}>
              <Radio.Group
                value={form.getFieldValue('MasterRecord')}
                onChange={(e) => updateFields(e.target.value)}
              >
                <Radio value={0}>{mergeData[0]?.DonorName}</Radio>
                <Radio value={1}>{mergeData[1]?.DonorName}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="DonorName" label="Donor Name:" colon={false}>
              <Radio.Group value={form.getFieldValue('DonorName')}>
                <Radio value={0}>{mergeData[0]?.DonorName}</Radio>
                <Radio value={1}>{mergeData[1]?.DonorName}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="DonorEmail" label="Donor Email:" colon={false}>
              <Radio.Group value={form.getFieldValue('DonorName')}>
                <Radio value={0}>{mergeData[0]?.DonorEmail}</Radio>
                <Radio value={1}>{mergeData[1]?.DonorEmail}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="DonorPhoneNumber"
              label="Donor Phone Number:"
              colon={false}
            >
              <Radio.Group value={form.getFieldValue('DonorName')}>
                <Radio value={0}>{mergeData[0]?.DonorPhoneNumber}</Radio>
                <Radio value={1}>{mergeData[1]?.DonorPhoneNumber}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="DonorZipCode"
              label="Donor Zip code:"
              colon={false}
            >
              <Radio.Group value={form.getFieldValue('DonorName')}>
                <Radio value={0}>{mergeData[0]?.DonorZipCode}</Radio>
                <Radio value={1}>{mergeData[1]?.DonorZipCode}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item>
              <Button
                style={{ width: '100%' }}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Merge
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export { MergeDonorModal }

import React, { useState } from 'react'
import { Modal } from 'antd'
import { TermsAndConditions } from 'screens/auth/components'
// import Api from 'api'

const TermAndConditionModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  return (
    <>
      <div
        className="t-cursor-default"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        I hereby agree to pay and adhere to{' '}
        <span
          className="font-bold text-dark hover:t-text-secondary-100 t-cursor-pointer"
          onClick={() => setIsModalVisible(true)}
        >
          Terms and Conditions
        </span>
      </div>
      <Modal
        title="Terms Of Use Policy"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <TermsAndConditions />
      </Modal>
    </>
  )
}

export { TermAndConditionModal }

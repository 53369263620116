/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Stepper2 } from '../Stepper/Stepper'
import ApexCharts from 'react-apexcharts'

const CommunityMemebers = ({ comunityMembers, netGross }) => {
  function formatAmount(amount) {
    const amountAsNumber = parseFloat(amount)
    const formattedAmount = amountAsNumber.toLocaleString()
    return `${formattedAmount}`
  }

  const [chartData, setChartData] = useState({
    series: [
      {
        name: 'Community Members',
        type: 'bar',
        data: [], // Bar data
        color: '#06B6D4', // Bar color
      },
      {
        name: 'Community Members Trend',
        type: 'line',
        data: [], // Line data
        color: '#00E396', // Line color
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        width: [3, 3, 0],
        curve: 'smooth',
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          dataLabels: {
            position: 'top', // position of dataLabels on top of the bars
          },
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0], // Enable data labels only for total donations (bar series)
        offsetY: -20, // Adjust position to be above the bar
        style: {
          fontSize: '12px',
          colors: ['#304758'],
          fontWeight: 'bold',
        },
        formatter(val) {
          return `${formatAmount(val.toFixed(2))}`
        },
      },
      xaxis: {
        type: 'category',
        categories: [],
      },
      yaxis: {
        labels: {
          formatter: (value) => formatAmount(value.toFixed(2)),
        },
      },
      tooltip: {
        y: {
          formatter: (value) => formatAmount(value.toFixed(2)),
        },
      },
      colors: ['#06B6D4', '#00E396'],
    },
  })

  useEffect(() => {
    if (!comunityMembers || comunityMembers.length === 0) {
      return
    }

    const currentDate = new Date()
    const currentMonthIndex = currentDate.getMonth()
    // Create a fixed comunityMembers of months
    const allMonths = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    const cumulativeData = allMonths.map((month, index) => {
      if (index > currentMonthIndex) {
        return null // Skip future months
      }
      const usersForMonth = comunityMembers?.list?.filter(
        (d) =>
          new Date(d.CreatedAt).toLocaleString('en-US', { month: 'short' }) ===
          month,
      )
      const totalUsersJoined = usersForMonth?.length
      return {
        month,
        totalUsersJoined,
      }
    })

    // Filter out null values from cumulativeData
    const validData = cumulativeData?.filter((data) => data !== null)

    // Calculate the trend data (for the line)
    const trendData = validData?.map((data) => data?.totalUsersJoined)

    // Update state with dynamic data
    setChartData((prevChartData) => ({
      ...prevChartData,
      series: [
        {
          name: 'Community Members',
          type: 'bar',
          data: validData.map((data) => data?.totalUsersJoined),
          color: '#06B6D4',
        },
        {
          name: 'Community Members Trend',
          type: 'line',
          data: trendData,
          color: '#00E396',
        },
      ],
      options: {
        ...prevChartData.options,
        xaxis: {
          type: 'category',
          categories: validData.map((data) => data?.month),
        },
        plotOptions: {
          bar: {
            columnWidth:
              currentMonthIndex + 1 >= 12
                ? '100%'
                : currentMonthIndex + 1 >= 9
                ? '80%'
                : currentMonthIndex + 1 >= 6
                ? '50%'
                : currentMonthIndex + 1 >= 3
                ? '20%'
                : '20%',
          },
        },
      },
    }))
  }, [comunityMembers])

  return (
    <>
      <div className="t-grid t-grid-cols-1 md:t-grid-cols-2 t-gap-4 t-mt-8 t-px-2">
        <div
          style={{
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: '',
            msOverflowStyle: 'none',
            WebkitOverflowScrolling: 'touch',
          }}
          className="t-rounded-lg t-justify-between t-bg-white t-border t-border-gray-300 t-mb-4 t-max-h-[510px] t-overflow-y-scroll"
        >
          <div className="t-pl-2 sm:t-pl-4 md:t-pl-6 t-pt-10 t-pb-10">
            {comunityMembers && comunityMembers.length <= 0 ? (
              <div className="t-text-center t-font-normal t-text-lg">
                There is no member in Community
              </div>
            ) : (
              comunityMembers?.list?.map((item) => (
                <Stepper2 item={item} key={item.Id} netGross={netGross} />
              ))
            )}
          </div>
        </div>
        <div className="t-rounded-lg t-max-h-[510px] t-bg-white t-border t-border-gray-300 t-mb-4">
          <h1 className="t-py-4 t-px-8 t-text-2xl t-font-bold t-text-gray-900">
            Growth Over The Months
          </h1>
          <div className="t-mt-4 t-m-3">
            <ApexCharts
              options={chartData.options}
              series={chartData.series}
              type="line"
              height={400}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CommunityMemebers

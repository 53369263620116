import React, { useEffect, useState } from 'react'
import { Card, Tabs } from 'antd'
import { useLocation } from 'react-router-dom'
import { ClassInvites } from './ClassInvites'
import { EventInvites } from './EventInvites'
import { UserServiceRequests } from './UserServiceRequests'
import { FundraiserInvites } from './FundraiserInvites'

const MyInvites = () => {
  const [activeKey, setActiveKey] = useState('1')
  const { search } = useLocation()

  useEffect(() => {
    if (search) {
      const urlSearchParams = new URLSearchParams(search)
      const { key } = Object.fromEntries(urlSearchParams.entries())
      if (key) setActiveKey(key)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Card className="header-solid" bordered={false}>
      <Tabs
        destroyInactiveTabPane
        animated={false}
        className="sliding-tab"
        activeKey={activeKey}
        onChange={(active) => {
          setActiveKey(active)
        }}
      >
        <Tabs.TabPane tab="Fundraiser Invites" key="1">
          <FundraiserInvites />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Event Invites" key="2">
          <EventInvites />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Class Invites" key="3">
          <ClassInvites />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Business Services Requests" key="4">
          <UserServiceRequests />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  )
}

export { MyInvites }

import React, { useState } from 'react'
import {
  Button,
  message,
  Popconfirm,
  // Radio,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from 'antd'
import { MdDelete } from 'react-icons/md'
import { FaEdit } from 'react-icons/fa'
import Api from 'api'

// import { AmountInput } from 'components/elements'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { EditDonorModal } from './EditDonorModal'
// import { PledgeModal } from './PledgeModal'

const DonorList = ({
  loading,
  data,
  getData,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [editData, setEditData] = useState()
  // const [pledgeMember, setPlegeMember] = useState()
  // const [pledgeModal, setPledgeModal] = useState(false)

  const formateData = (values) => {
    const formatedData = values?.map((item) => ({
      ...item,
      key: item.Id,
    }))
    return formatedData || []
  }

  const onDelete = (Id) => {
    Api.delete(`subscriber/donorList/donor/${Id}`)
      .then(() => {
        message.success('Deleted successfully')
        getData()
      })
      .catch((error) => {
        message.error('An error occurred')
        console.log('error', error)
      })
  }

  const onEdit = (Id) => {
    const formatedData = data.find((item) => item.Id === Id)
    setEditData(formatedData)
    setShowModal(true)
  }

  const history = useHistory()
  const onPledge = (Id) => {
    // setPledgeModal(true)
    history.push(
      `/settings/manage-organization/my-community/add-manual-pledge/${Id}`,
    )
    // const filterPledgeList = data.find((x) => x.Id === Id)
    // setPlegeMember(filterPledgeList)
  }
  const columns = [
    {
      title: 'Donor Name',
      dataIndex: 'DonorName',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Donor Email',
      dataIndex: 'DonorEmail',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Donor Phone Number',
      dataIndex: 'DonorPhoneNumber',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, { Id }) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <FaEdit
              fontSize="1.2rem"
              className="t-text-secondary-100 t-cursor-pointer"
              onClick={() => onEdit(Id)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure you want to delete this donor?"
              onConfirm={() => onDelete(Id)}
            >
              <MdDelete
                fontSize="1.3rem"
                className="t-text-secondary-100 t-cursor-pointer"
              />
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Add Pledge">
            <Button
              fontSize="1.2rem"
              className="t-text-secondary-100 t-cursor-pointer"
              onClick={() => onPledge(Id)}
            >
              Add Pledge
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ]

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  // const DefaultDonationValues = ['25', '50', '100', '225']
  return (
    <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
      <Table
        tableLayout="auto"
        scroll={{ x: 500 }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={formateData(data)}
        bordered={false}
        pagination={false}
      />
      {showModal && (
        <EditDonorModal
          isVisible={showModal}
          onClose={() => {
            setEditData()
            setShowModal(false)
          }}
          editData={editData}
          setEditData={setEditData}
          getData={getData}
        />
      )}
      {/* {pledgeModal && (
        <PledgeModal
          pledgeMember={pledgeMember}
          pledgeModal={pledgeModal}
          setPledgeModal={setPledgeModal}
        />
      )} */}
    </Skeleton>
  )
}

export { DonorList }

import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Timeline, Modal, Button, Table } from 'antd'
// import Api from 'api'
// import { formatAmount } from 'utils'
import ApexCharts from 'react-apexcharts'

function TodayDonation() {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [isModalVisible2ndCard, setIsModalVisible2ndCard] = useState(false)

  //   const [loading, setLoading] = useState()
  //   const [report, setReport] = useState()

  const showModal = (item) => {
    setSelectedItem(item)
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const showModal2ndCard = () => {
    setIsModalVisible2ndCard(true)
  }

  const handleOk2ndCard = () => {
    setIsModalVisible2ndCard(false)
  }

  const handleCancel2ndCard = () => {
    setIsModalVisible2ndCard(false)
  }

  const timelineData = [
    { title: 'Event 1', content: 'Details of Event 1' },
    { title: 'Event 2', content: 'Details of Event 2' },
    // Add more events as needed
  ]

  const series = [
    {
      name: 'Web Donations',
      type: 'line',
      data: [450, 650, 440, 160, 940, 414, 555],
    },
    {
      name: 'Kiosk Donations',
      type: 'line',
      data: [580, 560, 610, 780, 620, 680, 790],
    },
    {
      name: 'Total Donations',
      type: 'bar',
      data: [1030, 1210, 1050, 940, 1560, 1094, 1345],
    },
  ]

  const options = {
    chart: {
      height: 350,
      type: 'line',
    },
    stroke: {
      width: [3, 3, 0],
      curve: 'smooth',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        dataLabels: {
          position: 'top', // position of dataLabels on top of the bars
        },
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [2], // Enable data labels only for total donations (bar series)
      offsetY: -20, // Adjust position to be above the bar
      style: {
        fontSize: '12px',
        colors: ['#304758'],
        fontWeight: 'bold',
      },
      formatter(val) {
        return `$${val}`
      },
    },
    xaxis: {
      type: 'category',
      categories: [
        'Day 1',
        'Day 2',
        'Day 3',
        'Day 4',
        'Day 5',
        'Day 6',
        'Day 7',
      ],
    },
    yaxis: {
      labels: {
        formatter: (value) => `$${value.toFixed(2)}`,
      },
      title: {
        text: 'Amount (USD)',
      },
    },
    tooltip: {
      y: {
        formatter: (value) => `$${value.toFixed(2)}`,
      },
    },
    colors: ['#008FFB', '#00E396', '#FEB019'],
  }

  const seriesYTD = [
    {
      name: 'Web Donations',
      type: 'line',
      data: [450, 650, 440, 160, 940, 414, 555, 234, 321, 123, 456, 321],
    },
    {
      name: 'Kiosk Donations',
      type: 'line',
      data: [580, 560, 610, 780, 620, 680, 790, 343, 456, 787, 666, 544],
    },
    {
      name: 'Total Donations',
      type: 'bar',
      data: [
        1030, 1210, 1050, 940, 1560, 1094, 1345, 2345, 4221, 2345, 2345, 2345,
      ],
    },
  ]

  const optionsYTD = {
    chart: {
      height: 350,
      type: 'line',
    },
    stroke: {
      width: [3, 3, 0],
      curve: 'smooth',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        dataLabels: {
          position: 'top', // position of dataLabels on top of the bars
        },
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [2], // Enable data labels only for total donations (bar series)
      offsetY: -20, // Adjust position to be above the bar
      style: {
        fontSize: '12px',
        colors: ['#304758'],
        fontWeight: 'bold',
      },
      formatter(val) {
        return `$${val}`
      },
    },
    xaxis: {
      type: 'category',
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    },
    yaxis: {
      labels: {
        formatter: (value) => `$${value.toFixed(2)}`,
      },
      title: {
        text: 'Amount (USD)',
      },
    },
    tooltip: {
      y: {
        formatter: (value) => `$${value.toFixed(2)}`,
      },
    },
    colors: ['#008FFB', '#00E396', '#FEB019'],
  }

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Payment Amount',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
    },
    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
    },
  ]

  useEffect(() => {
    // setLoading(true)
    // Api.get(`adminReports/performanceDetails/${id}`)
    //   .then((res) => {
    //     setReport(res.results)
    //     setValue(res.results)
    //     setLoading(false)
    //   })
    //   .catch((error) => {
    //     console.log('error', error)
    //     setLoading(false)
    //   })
    // eslint-disable-next-line
  }, [])

  //   const isEvent = type && type === 'Event'
  return (
    <>
      <h1>Today Donations</h1>
      <div style={{ marginTop: '5%' }}>
        <Row style={{ marginBottom: '5%' }} gutter={[16, 16]}>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ height: '100%' }}
          >
            <Card
              style={{ height: '100%', overflowY: 'auto' }}
              title="Recent Transactions"
              bordered={false}
              extra={<Button onClick={showModal2ndCard}>See Full List</Button>}
            >
              <Timeline>
                {timelineData.map((item, index) => (
                  <Timeline.Item key={index}>
                    {item.title}
                    <Button
                      type="link"
                      style={{ float: 'right' }}
                      onClick={() => showModal(item)}
                    >
                      View Details
                    </Button>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card title="Last 7 Days Donations" bordered={false}>
              <ApexCharts
                options={options}
                series={series}
                type="line"
                height={350}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Card
            style={{ width: '100%' }}
            title="YTD Donations"
            bordered={false}
          >
            <ApexCharts
              options={optionsYTD}
              series={seriesYTD}
              type="line"
              height={350}
            />
          </Card>
        </Row>
        <Modal
          title={selectedItem?.title}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Close
            </Button>,
          ]}
        >
          <p>{selectedItem?.content}</p>
        </Modal>
        <Modal
          title="Full List"
          visible={isModalVisible2ndCard}
          onOk={handleOk2ndCard}
          onCancel={handleCancel2ndCard}
          width={800}
        >
          <Table dataSource={[]} columns={columns} />
        </Modal>
      </div>
    </>

    // <Skeleton loading={loading} paragraph={{ rows: 12 }}>
    //   {report ? (
    //     <div className="t-space-y-4">
    //       <div className="t-flex t-items-center t-justify-between">
    //         <p>Goal Amount</p>
    //         {formatAmount(report.goalAmount)}
    //       </div>
    //       <div className="t-flex t-items-center t-justify-between">
    //         <p>Donated Amount</p>
    //         {formatAmount(report.total?.Donations)}
    //       </div>
    //       {isEvent && (
    //         <div className="t-flex t-items-center t-justify-between">
    //           <p>Pledged Amount</p>
    //           {formatAmount(report.total?.Pledges)}
    //         </div>
    //       )}
    //       <div className="t-flex t-items-center t-justify-between">
    //         <p>Number of Attendees</p>
    //         {report.noOfAttendies}
    //       </div>
    //       <div className="t-flex t-items-center t-justify-between">
    //         <p>Event Cost</p>
    //         {formatAmount(report.total?.Donations * 0.02)}
    //       </div>
    //     </div>
    //   ) : (
    //     <div>No Data Found</div>
    //   )}
    // </Skeleton>
  )
}

export default TodayDonation

/* 

remove above options

const options = {
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
    },
    stroke: {
      width: [3, 3, 0],
      curve: 'smooth',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: '55%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 5,
    },
    title: {
      text: 'Donations Chart',
      align: 'left',
    },
    xaxis: {
      type: 'category',
      categories: [
        'Day 1',
        'Day 2',
        'Day 3',
        'Day 4',
        'Day 5',
        'Day 6',
        'Day 7',
      ],
    },
    yaxis: {
      labels: {
        formatter: (value) => `$${value.toFixed(2)}`,
      },
      title: {
        text: 'Amount (USD)',
      },
    },
    tooltip: {
      y: {
        formatter: (value) => `$${value.toFixed(2)}`,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: undefined, // optional, if you want to define a second color
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [0, 100, 100, 100],
      },
    },
    colors: ['#008FFB', '#00E396', '#FEB019'], // Colors for the lines and bars
  }

*/

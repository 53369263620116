import * as Yup from 'yup'

const zipCodeRegex = '^[0-9]{5}(?:-[0-9]{4})?$'

const schema = Yup.object().shape({
  Title: Yup.string().required('Event title is required'),
  ZipCode: Yup.string()
    .required('Zip code is required')
    .matches(zipCodeRegex, 'Invalid Zip code e.g 12345'),
  ShowOnPage: Yup.string().required('Show on landing page is required'),
  Details: Yup.string()
    .required('Event Description is required')
    .min(10, 'Description should be atleast 10 character'),
  OrganizationId: Yup.string().required('Organization id is required'),
  Flyer: Yup.mixed().typeError('Flyer is not a file'),
  StartDate: Yup.date()
    .typeError('Invalid Date')
    .required('StartDate is required'),
  EndDate: Yup.date().typeError('Invalid Date').required('EndDate is required'),
})

const auctionValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { auctionValidation }

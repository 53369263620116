import React, { useState } from 'react'
import { ComponentView } from 'components/common/componentView'
import {
  // FavouriteList,
  InactiveList,
  // AuctionList,
  MyAuction,
} from './components/AuctionLists'

const Auction = () => {
  const [searchText, setSearchText] = useState(null)
  const [sortSwitchValue, setSortSwitchValue] = useState(false)
  const [sortKey, setSortKey] = useState('CreatedDate')

  const heading = 'Auctions'
  const optionList = {
    // all: <AuctionList searchText={searchText} sortKey={sortKey} />,
    my: <MyAuction searchText={searchText} sortKey={sortKey} />,
    inactive: <InactiveList searchText={searchText} sortKey={sortKey} />,
    // favorite: <FavouriteList searchText={searchText} sortKey={sortKey} />,
  }
  const createButtonLink = '/settings/create-auction'

  const sortFilterData = [
    {
      label: 'By Created Date',
      value: 'CreatedDate',
      showOn: ['all', 'my', 'inactive', 'favorite'],
    },
    {
      label: 'By Start Date',
      value: 'StartDate',
      showOn: ['all', 'my', 'inactive', 'favorite'],
    },
    {
      label: 'By Favorite Date',
      value: 'favDate',
      showOn: ['favorite'],
    },
  ]

  return (
    <ComponentView
      heading={heading}
      optionList={optionList}
      createButtonLink={createButtonLink}
      setSearchText={setSearchText}
      sortFilterData={sortFilterData}
      sortKey={sortKey}
      setSortKey={setSortKey}
      sortSwitchValue={sortSwitchValue}
      setSortSwitchValue={setSortSwitchValue}
    />
  )
}

export { Auction }

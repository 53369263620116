import * as Yup from 'yup'

const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
const schema = Yup.object().shape({
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
  email: Yup.string()
    .required('Email is required')
    .matches(emailRegex, 'Invalid Email'),
})

const messageFormValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { messageFormValidation }

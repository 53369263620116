/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import {
  Card,
  Row,
  Typography,
  Skeleton,
  Divider,
  Button,
  message,
  Progress,
  Carousel,
  Menu,
  Dropdown,
  Tooltip,
  Tag,
} from 'antd'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  formatAmount,
  getWebAppFundraiserPledgeUrl,
  getWebAppFundraiserUrl,
  isMobile,
  mobileShareNavigator,
} from 'utils'
import { useDonations } from 'screens/main/fundraiser/components/useDonations'
import { awsconfig, config } from 'configs'
import { ShareModal } from 'components'
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
  BsShareFill,
  BsThreeDotsVertical,
} from 'react-icons/bs'
import { Donations } from '.'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { IoIosArrowBack } from 'react-icons/io'
import ReactPlayer from 'react-player'
import sample from 'assets/images/sample/fundraiser-default-image.png'
import { AuctionItemViewModal, MyBids, Won } from 'components/common/auction'
import {
  fundraiserActions,
  useFundraiserSelector,
} from 'store/fundraiserSlice/fundraiserReducer'
import {
  getFundraiserAuctionItems,
  getFundraiserControlScreenDetails,
  getFundraiserDetails,
  addToAttendy,
} from 'store/fundraiserSlice/fundraiserActions'

const { Paragraph } = Typography

const FundraiserDetail = () => {
  const dispatch = useDispatch()
  const { user, location } = useAuthSelector()
  const history = useHistory()
  const params = useParams()
  const { state, search } = useLocation()
  const slider = useRef(null)
  const [isShare, setShare] = useState(false)
  const [auctionItemViewModal, setAuctionItemViewModal] = useState(false)
  const [auctionItemsLoading, setAuctionItemsLoading] = useState(true)
  const [auctionItemDetails, setAuctionItemDetails] = useState()
  const [auctionItemsView, setAuctionItemsView] = useState(false)
  const [activeKey, setActiveKey] = useState('1')
  const { loading, fundraiser, fundraiserDetail, fundraiserAuctionItems } =
    useFundraiserSelector()

  const filterAuctionItems = fundraiserAuctionItems?.filter(
    (item) => item.status === 'Live',
  )
  useDonations(params?.id)

  useEffect(() => {
    if (search) {
      const urlSearchParams = new URLSearchParams(search)
      const { key } = Object.fromEntries(urlSearchParams.entries())
      if (key) setActiveKey(key)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (fundraiser) {
      const view = fundraiser.AuctionItemsView
      setAuctionItemsView(view || false)
    }
    //  // To sent private campaign to donation
    // if (fundraiser && fundraiser?.IsPrivate) {
    //   history.push(`/donation/${params?.id}`)
    // }
  }, [fundraiser])

  useEffect(() => {
    if (params?.id) {
      dispatch(getFundraiserDetails(params?.id))
      dispatch(getFundraiserControlScreenDetails(params?.id))
    } else {
      message.error('Invalid URL')
      history.push('/fundraisers')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id])

  useEffect(() => {
    if (params?.id) {
      // call the make attendies api here
      let payload = {
        email: user.email,
        organizationId: fundraiser?.organizationId,
        organizationName: fundraiser?.organizationName,
        donorName: `${user?.firstname} ${user?.lastname}`,
        donorPhoneNumber: user.phone,
        donorZipCode: location?.zipcode,
        createdBy: user.email,
      }
      const addToAttendyFunction = async () => {
        await addToAttendy(params?.id, payload)
      }
      addToAttendyFunction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id])

  const fetchAuctionItems = () => {
    if (params?.id) {
      dispatch(getFundraiserAuctionItems(params?.id, setAuctionItemsLoading))
    }
  }

  useEffect(() => {
    fetchAuctionItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id])

  const shareFundraiser = async () => {
    if (isMobile) {
      await mobileShareNavigator(
        fundraiser?.title,
        getWebAppFundraiserUrl(fundraiser.iD, fundraiser?.organization.domain),
      )
    } else {
      setShare(true)
    }
  }

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    function setScreenDimensions() {
      setScreenWidth(window.innerWidth)
    }

    let timeout
    setScreenDimensions()
    const handleResize = () => {
      clearTimeout(timeout)

      timeout = setTimeout(() => {
        setScreenDimensions()
      }, 400)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isFundraisingNotStarted = fundraiser?.fundraiserStatus === 'Not-Started'
  const isFundraisingStarted = fundraiser?.fundraiserStatus === 'Started'
  const isFundraisingStopped = fundraiser?.fundraiserStatus === 'Stopped'

  const percentage =
    (fundraiserDetail?.status?.Total * 100) / fundraiser?.targetAmount || 0

  const goBackFromJoinScreen = () => {
    if (state && state.urlData) {
      history.push({
        pathname: state.urlData.pathname,
        state: { key: state.urlData.activeKey },
      })
    } else {
      dispatch(fundraiserActions.setJoinedFundraiserDetails(null))
    }
  }

  const isCampaign = true

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: <div onClick={() => setActiveKey('1')}>Donations</div>,
        },
        fundraiserDetail?.isAuction && {
          key: '2',
          label: <div onClick={() => setActiveKey('2')}>My Bids</div>,
        },
        fundraiserDetail?.isAuction && {
          key: '3',
          label: <div onClick={() => setActiveKey('3')}>Won</div>,
        },
      ]}
    />
  )

  return (
    <Card className="header-solid md:h-auto" bordered={false}>
      <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
        <Row className="t-relative">
          <div
            onClick={goBackFromJoinScreen}
            className="t-rounded-full glass_bg t-cursor-pointer t-fixed t-top-20 md:t-top-24 t-z-50 t-text-secondary-100 t-p-1"
          >
            <IoIosArrowBack fontSize="1.5rem" />
          </div>
        </Row>
        <Row span={24} lg={24} xl={24} className="col-gallery mb-10 md:mb-24">
          {!auctionItemsLoading &&
          filterAuctionItems?.length > 0 &&
          auctionItemsView ? (
            <div className="t-relative t-w-full t-h-44 md:t-h-96 t-py-2 t-px-2">
              <BsFillArrowLeftCircleFill
                fontSize="1.2rem"
                onClick={() => slider.current.prev()}
                className="t-text-secondary-100 t-cursor-pointer t-absolute t-top-[35%] -t-left-[6px] t-hidden md:t-block"
              />
              <Carousel ref={slider} slidesPerRow={screenWidth < 768 ? 1 : 3}>
                {filterAuctionItems.map((item) => (
                  <div className="t-h-44 md:t-h-96 t-px-2">
                    {/* <div className="t-h-44 md:t-h-96 t-flex t-justify-center t-items-center"> */}
                    <Tooltip title="View Item">
                      <Card
                        bordered
                        hoverable
                        cover={
                          <img
                            alt="example"
                            className="t-mt-2 t-w-full t-h-16 md:t-h-48 t-object-contain t-rounded-br-none t-rounded-bl-none t-rounded-tr-md t-rounded-tl-md md:t-rounded-md t-cursor-pointer"
                            src={
                              item?.itemImage?.fileName
                                ? awsconfig.bucketUrl +
                                  item?.itemImage?.fileName
                                : sample
                            }
                          />
                        }
                        // className="t-w-[30%]"
                        onClick={() => {
                          setAuctionItemDetails(item)
                          setAuctionItemViewModal(true)
                        }}
                      >
                        <div className="t-flex t-justify-between t-items-center">
                          <h5 className="t-text-base md:t-text-2xl t-text-secondary-100 t-font-bold t-truncate">
                            {item.title}
                          </h5>
                        </div>

                        <div className="t-flex t-justify-between">
                          <div className="card-tag t-font-thin t-text-secondary-100">
                            {item.currentBid && formatAmount(item.currentBid)}
                          </div>
                        </div>
                        <p className="t-line-clamp-1 t-text-sm">
                          {item.description}
                        </p>
                        {/* <div className="t-flex t-justify-end t-mt-2 t-space-x-1 t-h-8">
                        <Button
                          type="primary"
                          size="small"
                          className="t-rounded-full"
                          onClick={() => {
                            setAuctionItemDetails(item)
                            setAuctionItemViewModal(true)
                          }}
                        >
                          Bid
                        </Button>
                      </div> */}
                      </Card>
                    </Tooltip>
                  </div>
                ))}
              </Carousel>
              <BsFillArrowRightCircleFill
                fontSize="1.2rem"
                onClick={() => slider.current.next()}
                className="t-text-secondary-100 t-cursor-pointer t-absolute t-top-[35%] -t-right-[6px] t-hidden md:t-block"
              />
            </div>
          ) : (
            <>
              {fundraiser?.youtubeLink && fundraiser?.youtubeLink !== '' ? (
                <ReactPlayer
                  playing
                  controls
                  width="100%"
                  url={fundraiser?.youtubeLink}
                  className="t-h-44 md:t-h-96"
                />
              ) : (
                <img
                  src={
                    fundraiser?.flyer?.length
                      ? awsconfig.bucketUrl + fundraiser?.flyer[0]?.fileName
                      : fundraiser?.organization?.backgroundImage
                      ? fundraiser?.organization?.backgroundImage
                      : sample
                  }
                  alt="card"
                  className="shadow-3 t-h-40 md:t-h-96 mb-24 t-w-full t-bg-cover t-bg-no-repeat t-object-cover"
                />
              )}
            </>
          )}
        </Row>
        <Row>
          <Tooltip title="Title" className="t-cursor-pointer">
            <p className="t-text-lg md:t-text-3xl t-font-bold t-text-secondary-100">
              {fundraiser?.title}
            </p>
          </Tooltip>
        </Row>
        <Row>
          <Tooltip title="Details" className="t-cursor-pointer">
            <Paragraph className="t-text-justify">
              {fundraiser?.description}
            </Paragraph>
          </Tooltip>
        </Row>
        <Row className="t-w-full t-flex t-justify-between t-items-center">
          <Row className="t-flex t-items-center t-space-x-2">
            <p className="t-text-sm md:t-text-base t-font-bold t-text-secondary-100">
              By:
            </p>
            <h1>{fundraiser?.organizationName}</h1>
          </Row>
          <BsShareFill
            onClick={shareFundraiser}
            style={{ fontSize: '1.4rem' }}
            className="t-text-secondary-100 t-text-center t-cursor-pointer"
          />
        </Row>
        <Divider />
        {(isFundraisingStarted || isFundraisingStopped) && (
          <>
            <Row className="t-mt-1 t-p-1 t-hidden md:t-flex">
              <Row className="t-flex t-justify-between t-w-full">
                <div className="t-text-center">
                  <h6 className="t-text-xs md:t-text-2xl t-font-bold t-text-secondary-100">
                    {fundraiser?.showDonatedAmount
                      ? `${formatAmount(
                          fundraiserDetail?.status?.Total,
                        )} raised`
                      : ''}
                  </h6>
                </div>
                <div className="t-text-center">
                  <h6 className="t-text-xs md:t-text-2xl t-font-bold t-text-green-500">
                    {`${formatAmount(fundraiser?.targetAmount)} goal`}
                  </h6>
                </div>
              </Row>
              <Row className="t-w-full t-mb-6">
                {fundraiser?.showProgressBar && (
                  <Progress
                    percent={percentage}
                    showInfo={false}
                    strokeColor={config.colors.primary}
                  />
                )}
              </Row>
              <Row className="t-w-full t-flex t-justify-between t-items-center">
                <Button
                  size="small"
                  id="pledgeModalButton"
                  type="primary"
                  target="_blank"
                  href={getWebAppFundraiserPledgeUrl(
                    fundraiser.iD,
                    fundraiser?.organization.domain,
                  )}
                >
                  Pledge
                </Button>
                <Button
                  size="small"
                  type="primary"
                  id="donateModalButton"
                  target="_blank"
                  href={getWebAppFundraiserUrl(
                    fundraiser.iD,
                    fundraiser?.organization.domain,
                  )}
                >
                  Donate
                </Button>
              </Row>
            </Row>
            {/* <Row className="t-mt-1 md:t-my-10 md:t-py-10 t-p-1 md:t-p-4 t-hidden md:t-flex">
              <Col
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
                className="t-flex t-flex-row md:t-flex-col t-justify-between"
              >
                <Row>
                  <div className="t-text-center">
                    <h6 className="t-text-base md:t-text-2xl t-font-bold t-text-green-500">
                      {formatAmount(eventDetail?.status?.Total)}
                    </h6>
                    <h6 className="t-text-xs md:t-text-md t-font-bold">
                      Raised
                    </h6>
                  </div>
                </Row>
                <Row>
                  <Row>
                    {!isFundraisingStopped && (
                      <Button
                        size="small"
                        type="primary"
                        className="t-w-full"
                      >
                        Donate
                      </Button>
                    )}
                  </Row>
                </Row>
              </Col>
              <Col
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
                className="t-flex t-justify-center t-items-center"
              >
                <Row className="t-flex t-justify-center t-items-center">
                  <Progress
                    type="circle"
                    width={200}
                    strokeColor={config.colors.primary}
                    percent={percentage}
                    format={() => (
                      <div>
                        <h6 className="t-font-bold t-text-sm md:t-text-2xl t-text-green-500">
                          {formatAmount(event?.TargetAmount)}
                        </h6>
                        <h6 className="t-font-semibold t-text-xs md:t-text-md t-text-gray-500 ">
                          Target Amount
                        </h6>
                      </div>
                    )}
                  />
                </Row>
              </Col>
              <Col
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
                className="t-flex t-flex-row md:t-flex-col t-justify-between"
              >
                <Row justify="end">
                  <div className="t-text-center">
                    <h6 className="t-text-base md:t-text-2xl t-font-bold t-text-red-500">
                      {`${
                        event?.TargetAmount - eventDetail?.status?.Total < 0
                          ? formatAmount(0)
                          : formatAmount(
                              event?.TargetAmount - eventDetail?.status?.Total,
                            )
                      }`}
                    </h6>
                    <h6 className="t-text-xs md:t-text-md t-font-bold">
                      Remaining
                    </h6>
                  </div>
                </Row>
                <Row justify="end">
                  <Row>
                    {!isFundraisingStopped && (
                      <Button
                        size="small"
                        type="primary"
                        className="t-w-full"
                        onClick={() => setPledgeModal(true)}
                      >
                        Pledge
                      </Button>
                    )}
                  </Row>
                </Row>
              </Col>
            </Row> */}
            <Row className="t-visible md:t-hidden t-flex t-justify-between t-w-full">
              <Row className="t-flex t-justify-between t-w-full">
                <div className="t-text-center">
                  <h6 className="t-text-xs md:t-text-2xl t-font-bold t-text-secondary-100">
                    {fundraiser?.showDonatedAmount
                      ? `${formatAmount(
                          fundraiserDetail?.status?.Total,
                        )} raised`
                      : ''}
                  </h6>
                </div>
                <div className="t-text-center">
                  <h6 className="t-text-xs md:t-text-2xl t-font-bold t-text-green-500">
                    {`${formatAmount(fundraiser?.targetAmount)} goal`}
                  </h6>
                </div>
              </Row>
              <Row className="t-w-full">
                {fundraiser?.showProgressBar && (
                  <Progress
                    percent={percentage}
                    showInfo={false}
                    strokeColor={config.colors.primary}
                  />
                )}
              </Row>
            </Row>
            <Divider />
            {/* <Row>
              <p className="t-text-md md:t-text-3xl t-font-bold t-text-secondary-100">
                Donations
              </p>
            </Row>
            <Row className="t-w-full t-mb-16">
              <Donations />
            </Row> */}
            {fundraiserDetail?.isAuction && (
              <Row
                className="t-flex t-justify-center t-items-center"
                animated={false}
              >
                <Tag colors="green">
                  Note: Select auction options from dropdown
                </Tag>
              </Row>
            )}
            <Row className="t-w-full" animated={false}>
              <div className="t-w-full t-flex t-justify-between t-m-2 t-items-center">
                <h1 className="t-font-bold t-text-secondary-100 t-text-xl">
                  {activeKey === '1' && 'Donations'}
                  {activeKey === '2' && 'My Bids'}
                  {activeKey === '3' && 'Won'}
                </h1>
                <Dropdown overlay={menu} placement="bottomLeft" arrow>
                  <BsThreeDotsVertical
                    fontSize="1.5rem"
                    id="control-screen-dropdown"
                    className="t-cursor-pointer t-mx-1"
                  />
                </Dropdown>
              </div>
            </Row>
            <Row className="t-w-full" animated={false}>
              {activeKey === '1' && <Donations />}
              {activeKey === '2' && (
                <MyBids
                  onBidClick={(item) => {
                    setAuctionItemDetails(item)
                    setAuctionItemViewModal(true)
                  }}
                  type="fundraiser"
                  parentId={params?.id}
                />
              )}
              {activeKey === '3' && (
                <Won type="fundraiser" parentId={params?.id} />
              )}
            </Row>
          </>
        )}
        {isFundraisingNotStarted && (
          <div className="t-h-80 t-w-full t-flex t-justify-center t-items-center t-text-center t-text-secondary-100 t-text-lg t-font-bold">
            The organizer has not yet started fundraising for this fundraiser.
          </div>
        )}
        {isFundraisingStarted && fundraiser?.startFundraiser && (
          <div
            className={`t-flex md:t-hidden t-fixed t-h-16 t-w-full t-px-6 t-py-1 t-bg-primary-100 t-shadow-2xl t-left-0 t-bottom-0 t-justify-between t-items-center  t-rounded-t-md ${
              isCampaign ? 't-space-x-0' : 't-space-x-4'
            }`}
          >
            <Button
              size="small"
              className="t-w-full t-bg-white"
              onClick={() => history.push(`/pledge/event/${fundraiser?.iD}`)}
            >
              Pledge
            </Button>
            <Button
              size="small"
              className="t-w-full t-bg-white"
              href={getWebAppFundraiserUrl(
                fundraiser.iD,
                fundraiser?.organization?.domain,
              )}
              target="_blank"
            >
              Donate
            </Button>
          </div>
        )}
        <AuctionItemViewModal
          visible={auctionItemViewModal}
          item={auctionItemDetails}
          close={() => {
            setAuctionItemDetails()
            setAuctionItemViewModal(false)
          }}
          fetchData={() => fetchAuctionItems()}
          type="fundraiser"
          parentId={params?.id}
        />
        <ShareModal
          // url={`${window.location.origin}/donation/${params?.id}`}
          url={`${awsconfig.API.endpoints[0].api_url}/html/share-fundraiser/${params?.id}`}
          visible={isShare}
          onClose={() => setShare(false)}
        />
      </Skeleton>
    </Card>
  )
}

export { FundraiserDetail }

import React, { useEffect } from 'react'
import {
  Button,
  Divider,
  List,
  Modal,
  Popconfirm,
  Skeleton,
  Tooltip,
} from 'antd'
import { useOrganizationProfileSelector } from 'store/organizationProfilesSlice/OrganizationProfilesReducer'
import { useDispatch } from 'react-redux'
import {
  deleteProfile,
  getInUseList,
} from 'store/organizationProfilesSlice/OrganizationProfilesActions'

const UsedInModal = ({ isVisible, onClose, getData }) => {
  const dispatch = useDispatch()
  const { profile, loading, inUseList } = useOrganizationProfileSelector()

  useEffect(() => {
    if (profile) {
      dispatch(
        getInUseList({
          organizationId: profile?.OrganizationId,
          profileId: profile?.ProfileId,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const actions = () => {
    getData()
    onClose()
  }
  const deleteOrganizationProfile = () => {
    dispatch(
      deleteProfile(profile?.OrganizationId, profile?.ProfileId, actions),
    )
  }

  const isUsed = inUseList?.length > 0
  return (
    <Modal
      centered
      title="Currently In Use By"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Skeleton
        loading={loading?.fetchInUseList}
        active
        paragraph={{ rows: 12 }}
      >
        <>
          {isUsed ? (
            <p className="t-mb-2">
              <b>Note:</b> The following programs/services use &#39;
              {profile?.ProfileTitle}&#39; as a alternate organization profile.
              If you delete, it will be removed from these programs/services.
            </p>
          ) : (
            <p className="t-mb-2">
              &#39;{profile?.ProfileTitle}&#39; is not used anywhere. You can
              delete this
            </p>
          )}
          <Popconfirm
            title="Are you sure you want to delete this profile？"
            placement="top"
            loading={loading?.deleting}
            onConfirm={() => deleteOrganizationProfile()}
            okText="Delete"
            cancelText="Cancel"
          >
            <Button type="primary" className="t-w-full t-p-x-4">
              Delete Profile
            </Button>
          </Popconfirm>
          {isUsed && (
            <>
              <Divider />
              <p>Click on the item title to view the details of each item.</p>
              <List
                itemLayout="horizontal"
                dataSource={inUseList || []}
                split={false}
                className="conversations-list"
                renderItem={(item) => (
                  <List.Item key={item.Id}>
                    <List.Item.Meta
                      key={item.Id}
                      title={
                        <div
                          className="t-cursor-pointer t-text-secondary-100"
                          onClick={() => {
                            const url = item.ProgramId
                              ? `/settings/create-program/${item.ProgramId}`
                              : `/settings/create-service/${item.ServiceId}`
                            window.open(`${url}`, '_blank')
                          }}
                        >
                          <Tooltip title="Item Name">{item.Title}</Tooltip>
                        </div>
                      }
                      description={
                        <Tooltip title="Type">
                          {item.ProgramId ? 'Progam' : 'Service'}
                        </Tooltip>
                      }
                    />
                  </List.Item>
                )}
              />
            </>
          )}
        </>
      </Skeleton>
    </Modal>
  )
}

export { UsedInModal }

import React, { useEffect, useState } from 'react'
import {
  Card,
  Skeleton,
  List,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
} from 'antd'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useDispatch } from 'react-redux'
import {
  addDoc,
  getGovDocs,
  updateDocStatus,
} from 'store/authSlice/authActions'
import { AiFillIdcard } from 'react-icons/ai'
import { documentValidation } from 'validations'

const initialValues = { name: '' }

const ManageGovDocs = () => {
  const { loading, govdocs } = useAuthSelector()
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    dispatch(getGovDocs())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinish = (values) => {
    const payload = {
      CODE_TYPE_ID: '100001',
      CODE_VALUE: values.name,
    }
    dispatch(addDoc(payload))
    setShowModal(false)
  }

  const updateStatus = (codeId, isActivate) => {
    dispatch(updateDocStatus(codeId, isActivate))
  }

  return (
    <>
      {false ? (
        <Card
          className="t-flex t-justify-center t-items-center t-w-full"
          style={{ height: '550px' }}
        >
          Manage Gov Docs Coming Soon
        </Card>
      ) : (
        <Card
          className="header-solid"
          bordered={false}
          title={[
            <div className="t-flex t-justify-between t-items-center">
              <h1 className="t-text-2xl">Government Documents</h1>
              <Button type="primary" onClick={() => setShowModal(true)}>
                Add Document
              </Button>
            </div>,
          ]}
        >
          <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
            <List
              itemLayout="horizontal"
              dataSource={govdocs}
              split={false}
              className="conversations-list"
              renderItem={(item) => (
                <List.Item
                  key={item.Id}
                  actions={[
                    <Button
                      type="primary"
                      size="small"
                      className={`t-w-20 t-border-0 ${
                        item.ActiveInd === 1 ? 't-bg-red-500' : 't-bg-green-500'
                      }`}
                      onClick={() =>
                        updateStatus(item.Id, item.ActiveInd !== 1)
                      }
                    >
                      {item.ActiveInd === 1 ? 'Disable' : 'Enable'}
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    key={item.Id}
                    avatar={
                      <AiFillIdcard
                        fontSize="3rem"
                        className="t-text-secondary-100 t-cursor-pointer"
                      />
                    }
                    title={item.CODE_VALUE}
                    description={`${item.Id}`}
                  />
                </List.Item>
              )}
            />
          </Skeleton>
          <Modal
            title="Add Document"
            open={showModal}
            onCancel={() => setShowModal(false)}
            footer={null}
          >
            <Form
              form={form}
              layout="vertical"
              key={0}
              onFinish={onFinish}
              name="gov-doc"
              initialValues={initialValues}
              scrollToFirstError
            >
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <Form.Item
                    name="name"
                    label="Document Name"
                    colon={false}
                    rules={[documentValidation]}
                  >
                    <Input placeholder="Enter Document Name" />
                  </Form.Item>
                </Col>
                <Col
                  span={24}
                  className="text-left t-my-4 t-flex t-justify-end"
                >
                  <Button
                    loading={loading}
                    type="primary"
                    className="px-25"
                    htmlType="submit"
                  >
                    Add Document
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal>
        </Card>
      )}
    </>
  )
}

export { ManageGovDocs }

import React, { useEffect, useState } from 'react'
import {
  message,
  Skeleton,
  Form,
  Row,
  Col,
  Input,
  Button,
  Card,
  Typography,
  Table,
  Tooltip,
} from 'antd'
import Api, { makeRequest } from 'api'
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min'
import { IoIosArrowBack } from 'react-icons/io'
import { useHardwareSelector } from 'store/hardwareSlice/hardwareReducer'
import { getHardwareList } from 'store/hardwareSlice/hardwareActions'
import { useDispatch } from 'react-redux'
import { orgMarkupValidation } from 'validations/OrgMarkUp'
import { config } from 'configs'
import { HiInformationCircle } from 'react-icons/hi'
const { Title } = Typography

function UpdateMarkup() {
  const [form] = Form.useForm()
  const [name, setName] = useState('')
  const [markUploading, setMarkUpLoading] = useState()
  const [btnLoading, setBtnLoading] = useState(false)
  const [orgHardwareList, setOrgHardWareList] = useState([])
  const [changedCosts, setChangedCosts] = useState({})
  const history = useHistory()
  const dispatch = useDispatch()
  const { hardwareList } = useHardwareSelector()
  const { id } = useParams()

  const getData = () => {
    dispatch(getHardwareList())
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const orgHardware = () => {
    const filterOrgHardware = hardwareList.filter(
      (x) => x.OrganizationId === id,
    )
    setOrgHardWareList(filterOrgHardware)
  }

  useEffect(() => {
    orgHardware()
    // eslint-disable-next-line
  }, [hardwareList])

  useEffect(() => {
    setMarkUpLoading(true)
    Api.get(`/admins/get-organization-markups/${id}`)
      .then((res) => {
        const data = res?.results
        form.setFieldsValue({
          SMSMarkup: data.SMSMarkup && data.SMSMarkup,
          EmailMarkup: data.EmailMarkup && data.EmailMarkup,
          PaymentMarkup: data.PaymentMarkup && data.PaymentMarkup,
          FreeSMSQuota: data.FreeSMSQuota && data.FreeSMSQuota,
          FreeEmailQuota: data.FreeEmailQuota && data.FreeEmailQuota,
          PlatformSubscriptionCharges:
            data.PlatformSubscriptionCharges &&
            data.PlatformSubscriptionCharges,
        })

        setMarkUpLoading(false)
      })
      .catch(() => {
        setMarkUpLoading(false)
        message.error('Something went wrong')
      })
    // eslint-disable-next-line
  }, [id, form, hardwareList])

  const handleSubmit = (payload, updateHardwareData) => {
    const invalidCosts = updateHardwareData.some((item) => item.MonthlyCost < 0)
    if (invalidCosts) {
      message.error('Monthly Cost cannot be less than 0')
      return
    }
    setBtnLoading(true)
    Api.put('/admins/update-organization-markups', payload)
      .then(() => {
        message.success('Markup Updated Successfully')
        setBtnLoading(false)
        getData()
        history.push('/organizations')
      })
      .catch(() => {
        message.error('Something went wrong')
        setBtnLoading(false)
      })
  }

  const handleHardwareUpdate = async (updateHardwareData) => {
    if (updateHardwareData.length <= 0) {
      return
    }
    const invalidCosts = updateHardwareData.some((item) => item.MonthlyCost < 0)
    if (invalidCosts) {
      message.error('Monthly Cost cannot be less than 0')
      return
    }
    setBtnLoading(true)
    try {
      const promises = updateHardwareData.map((hardware) =>
        makeRequest(
          'put',
          `/admins/hardware/monthlyCost/${id}/${hardware.HardwareId}`,
          { organization: id },
          { MonthlyCost: hardware.MonthlyCost },
        ),
      )

      const responses = await Promise.all(promises)
      const hasError = responses.some((response) => response instanceof Error)
      if (hasError) {
        message.error(
          config.defaultErrorMessage || 'Some hardware updates failed',
        )
        setBtnLoading(false)
        history.push('/organizations')
      } else {
        message.success('Hardware updated successfully')
        setBtnLoading(false)
        form.resetFields()
        history.push('/organizations')
      }
    } catch (error) {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      setBtnLoading(false)
    }
  }

  const onFinish = (values) => {
    const selecteObj = orgHardwareList.filter((item) => changedCosts[item.Id])
    const updateHardwareData = selecteObj.map((item) => ({
      HardwareId: item.Id,
      MonthlyCost: item.MonthlyCost,
    }))
    const payload = {
      organizationId: id,
      smsMarkup: values.SMSMarkup,
      emailMarkup: values.EmailMarkup,
      paymentMarkup: values.PaymentMarkup,
      FreeSMSQuota: values.FreeSMSQuota,
      FreeEmailQuota: values.FreeEmailQuota,
      PlatformSubscriptionCharges: values.PlatformSubscriptionCharges,
    }

    handleSubmit(payload, updateHardwareData)
    handleHardwareUpdate(updateHardwareData)
  }

  const handleBack = () => {
    history.goBack()
  }

  useEffect(() => {
    const url = window.location.href
    setName(getNameFromURL(url))
    // eslint-disable-next-line
  }, [name])

  const getNameFromURL = (url) => {
    const urlObj = new URL(url)
    const pathSegments = urlObj.pathname.split('/')
    const encodedName = pathSegments[pathSegments.length - 1]
    const decodedName = decodeURIComponent(encodedName)
    return decodedName
  }

  const handleCostChange = (Id, value) => {
    setChangedCosts((prev) => ({ ...prev, [Id]: true }))
    const updatedOrgHardwareList = orgHardwareList.map((item) =>
      item.Id === Id ? { ...item, MonthlyCost: value } : item,
    )
    setOrgHardWareList(updatedOrgHardwareList)
  }

  const columns = [
    {
      title: 'Device Serial Name',
      dataIndex: 'DeviceSerialNumber',
      className: 'ant-table-row-cell-break-word t-w-[33.33%]',
    },
    {
      title: 'Device MDM Name',
      dataIndex: 'MDMDeviceName',
      className: 'ant-table-row-cell-break-word t-w-[33.33%]',
    },
    {
      title: 'Monthly Cost',
      dataIndex: 'MonthlyCost',
      className: 'ant-table-row-cell-break-word t-w-[33.33%]',
      render: (text, record) => (
        <Col xl={16} md={16} xs={24} sm={24}>
          <Form.Item
            name={`MonthlyCost-${record.Id}`}
            colon={false}
            // rules={[orgMarkupValidation]}
          >
            <div className="t-flex t-flex-row">
              <Input
                inputMode="numeric"
                type="number"
                defaultValue={record.isGlobal ? 0 : record.MonthlyCost}
                onChange={(e) => handleCostChange(record.Id, e.target.value)}
                suffix={
                  record?.isGlobal && (
                    <Tooltip title="Update Hardware Monthly Cost">
                      <HiInformationCircle
                        fontSize="1.5rem"
                        className="t-text-secondary-100 t-cursor-pointer"
                      />
                    </Tooltip>
                  )
                }
              />
            </div>
          </Form.Item>
        </Col>
      ),
    },
  ]

  return (
    <Card>
      <div>
        <Button
          type="primary"
          className="t-rounded-full t-flex t-items-center"
          onClick={handleBack}
          size="small"
        >
          <IoIosArrowBack />
          Back
        </Button>
        <Title level={4} className="t-mt-2">
          Cost Overrides for {name}
        </Title>
      </div>
      <Skeleton loading={markUploading} paragraph={{ rows: 12 }}>
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="organization"
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="SMSMarkup"
                label="SMS Cost ($)"
                colon={false}
                rules={[orgMarkupValidation]}
              >
                <Input placeholder="Enter SMS Markup" />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="FreeSMSQuota"
                label="SMS Quota"
                colon={false}
                rules={[orgMarkupValidation]}
              >
                <Input placeholder="Enter free sms quota" />
              </Form.Item>
            </Col>

            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="EmailMarkup"
                label="Email Charges ($)"
                colon={false}
                rules={[orgMarkupValidation]}
              >
                <Input placeholder="Enter Email Markup" />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="FreeEmailQuota"
                label="Email Quota"
                colon={false}
                rules={[orgMarkupValidation]}
              >
                <Input placeholder="Enter free email quota" />
              </Form.Item>
            </Col>

            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="PaymentMarkup"
                label="Payment Markup ($)"
                colon={false}
                rules={[orgMarkupValidation]}
              >
                <Input placeholder="Enter Stripe Markup" />
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="PlatformSubscriptionCharges"
                label="Platform Subscription Charges ($)"
                colon={false}
              >
                <Input placeholder="Enter platform subscription charges" />
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Table
                columns={columns}
                dataSource={orgHardwareList || []}
                tableLayout="auto"
                scroll={{ x: 500 }}
                bordered={false}
                pagination={false}
              />
            </Col>
            <Col span={24} className="text-right">
              <Button
                loading={markUploading}
                type="primary"
                className="px-25"
                htmlType="submit"
              >
                {btnLoading ? 'Submit...' : 'Submit'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </Card>
  )
}

export { UpdateMarkup }

import { message, Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Api from 'api'
// import { BusinessProfile } from 'screens/main/business/donor/components/BusinessView/components/BusinessProfile'
import { MyBusinessServices } from 'screens/main/business/donor/components/BusinessView/components/MyBusinessServices'

const UnauthenticatedBusinessProfile = () => {
  const { id } = useParams()
  const [loaded, setLoaded] = useState(true)
  const [business, setBusiness] = useState(true)

  const getBusinessByID = (businessId) => {
    setLoaded(true)
    Api.get(`/publicBusiness/getBusiness/${businessId}`)
      .then((res) => {
        console.log('Business', res.results)
        setBusiness(res.results)
        setLoaded(false)
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || 'Something went wrong')
        setLoaded(false)
      })
  }
  useEffect(() => {
    if (id) {
      getBusinessByID(id)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Skeleton loading={loaded} active paragraph={{ rows: 18 }}>
      <MyBusinessServices business={business} unauthenticated />
    </Skeleton>
  )
}

export { UnauthenticatedBusinessProfile }

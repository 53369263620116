import * as Yup from 'yup'

const zipCodeRegex = '^[0-9]{5}(?:-[0-9]{4})?$'
const numberValidation = new RegExp('^[0-9]+$')

const schema = Yup.object().shape({
  Title: Yup.string().required('Title is required'),
  Description: Yup.string()
    .required('Description is required')
    .min(10, 'Description should be atleast 10 character'),
  Schedule: Yup.string(),
  ZipCode: Yup.string()
    .matches(zipCodeRegex, 'Invalid Zipcode e.g 12345')
    .required('Zipcode is required'),
  City: Yup.string().required('City is required'),
  LocationState: Yup.string().required('LocationState is required'),
  Address: Yup.string().required('Address is required'),
  Notes: Yup.string()
    .min(20, 'Notes should be atleast 20 character')
    .required('Notes is required'),
  AllowedRegistration: Yup.string()
    .required('Allowed Registration is required')
    .matches(numberValidation, 'Invalid number'),
  ShowOnPage: Yup.string().required('Show on landing page is required'),
})

const volunteerValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { volunteerValidation }

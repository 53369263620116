import React, { useEffect, useRef, useState } from 'react'
import { Spin } from 'antd'
import { fetchMoreData } from 'utils'
import InfiniteScroll from 'react-infinite-scroll-component'
import { AiFillDownCircle, AiFillUpCircle } from 'react-icons/ai'
import { config } from 'configs'

const InfiniteScrollContainer = ({
  data,
  current,
  setCurrent,
  height,
  itemsFirstLoad,
  children,
}) => {
  const scrollableDivRef = useRef(null)
  const [isAtTop, setIsTop] = useState(true)
  const [isAtEnd, setIsAtEnd] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableDivRef.current) {
        const { scrollHeight, scrollTop, clientHeight } =
          scrollableDivRef.current
        // To check if scroll is at end
        if (scrollHeight - scrollTop === clientHeight) {
          setIsAtEnd(true)
        } else {
          // To check if scroll is at top
          if (scrollTop === 0) {
            setIsTop(true)
          } else {
            setIsTop(false)
          }
          setIsAtEnd(false)
        }
      }
    }

    scrollableDivRef.current.addEventListener('scroll', handleScroll)
    return () => {
      if (scrollableDivRef && scrollableDivRef.current)
        // eslint-disable-next-line
        scrollableDivRef.current.removeEventListener('scroll', handleScroll)
    }
  }, [current])

  // To find if is mobile or desktop
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  useEffect(() => {
    function setScreenDimensions() {
      setScreenWidth(window.innerWidth)
    }

    let timeout
    setScreenDimensions()
    const handleResize = () => {
      clearTimeout(timeout)

      timeout = setTimeout(() => {
        setScreenDimensions()
      }, 400)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isMobile = screenWidth < 768
  const isShowMore = itemsFirstLoad
    ? data.length > itemsFirstLoad
    : data.length > config.reportItemFirstLoad
  return (
    <div className="t-relative t-py-8">
      {!isAtTop && (
        <div className="t-absolute t-top-0 t-w-full t-flex t-justify-center t-items-center">
          <AiFillUpCircle
            fontSize="1.5rem"
            className="t-text-secondary-100 t-cursor-pointer"
            onClick={() =>
              scrollableDivRef.current.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }
          />
        </div>
      )}
      <div
        id="scrollableDiv"
        className="t-overflow-auto t-flex t-flex-col no-scrollbar"
        style={{
          maxHeight: height
            ? `${height}`
            : isMobile
            ? 'calc(100vh - 258px)'
            : 'calc(100vh - 300px)',
        }}
        ref={scrollableDivRef}
      >
        <InfiniteScroll
          dataLength={current.length}
          next={() => fetchMoreData(current, setCurrent, data)}
          hasMore={current.length < data.length}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              <Spin />
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          {children}
        </InfiniteScroll>
      </div>
      {isShowMore && (current.length < data.length || !isAtEnd) && (
        <div className="t-absolute t-bottom-0 t-w-full t-flex t-justify-center t-items-center">
          <AiFillDownCircle
            fontSize="1.5rem"
            className="t-text-secondary-100 t-cursor-pointer"
            onClick={() =>
              scrollableDivRef.current.scrollTo({
                top: scrollableDivRef.current.scrollHeight,
                behavior: 'smooth',
              })
            }
          />
        </div>
      )}
    </div>
  )
}

export { InfiniteScrollContainer }

import * as Yup from 'yup'

const schema = Yup.object().shape({
  KioskShowDonationButtons: Yup.string()
    .oneOf(['Yes', 'No'], 'Invalid value')
    .required('This field is required'),
  KioskDonationTitle: Yup.string().required('This field is required'),
  KioskRequireEmailAddress: Yup.string()
    .oneOf(['Yes', 'No'], 'Invalid value')
    .required('This field is required'),
  KioskRequireStreetAddress: Yup.string()
    .oneOf(['Yes', 'No'], 'Invalid value')
    .required('This field is required'),
  KioskRequireName: Yup.string()
    .oneOf(['Yes', 'No'], 'Invalid value')
    .required('This field is required'),
  KioskIncludeProcessFeesByDefault: Yup.string()
    .oneOf(['Yes', 'No'], 'Invalid value')
    .required('This field is required'),
  KioskShowQuickPathFundraiser: Yup.string()
    .oneOf(['Yes', 'No'], 'Invalid value')
    .required('This field is required'),
  KioskShowFundraisers: Yup.string()
    .oneOf(['Yes', 'No'], 'Invalid value')
    .required('This field is required'),
  donationValue0: Yup.string()
    .min(0, 'Donation value cannot be negative')
    .required('This field is required'),
  donationValue1: Yup.string()
    .min(0, 'Donation value cannot be negative')
    .required('This field is required'),
  donationValue2: Yup.string()
    .min(0, 'Donation value cannot be negative')
    .required('This field is required'),
  donationValue3: Yup.string()
    .min(0, 'Donation value cannot be negative')
    .required('This field is required'),
  PaymentCategories: Yup.array().of(Yup.string()),
})

const organizationDeviceValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { organizationDeviceValidation }

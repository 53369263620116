import Api, { makeRequest } from 'api'
import { uploadFile } from 'store/authSlice/authActions'
import { message } from 'antd'
import { config } from 'configs'
import { organizationActions } from './organizationReducer'

export const getCodeList = async () => {
  const codeId = 100001
  const response = await Api.get(`/admin/codeList?id=${codeId}`)
  const activeArray = response.results.filter((item) => item.ActiveInd === 1)
  const codeList = activeArray.map((item) => item.CODE_VALUE)
  return codeList
}

export const registerOrganization = async (data) => {
  let updatedData = data
  if (updatedData.W9Form) {
    const w9FormFile = await uploadFile(updatedData.W9Form)
    updatedData = {
      ...updatedData,
      W9Form: {
        fileName: w9FormFile.fileName,
        fileMimeType: w9FormFile.fileMimeType,
      },
    }
  }

  const backgroundImageFile = await uploadFile(updatedData.BackgroundImage)
  const backgroundLogoFile = await uploadFile(updatedData.Logo)

  // for identity file as it can 1 or more than 1
  if (updatedData.IdentityFile && updatedData.IdentityFile.length) {
    const IdentityFiles = []
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < updatedData.IdentityFile.length; i += 1) {
      const file = updatedData.IdentityFile[i]
      if (!file.fileName) {
        const identityFile = await uploadFile(file)
        IdentityFiles.push({
          fileName: identityFile.fileName,
          fileMimeType: identityFile.fileMimeType,
        })
      }
    }
    /* eslint-disable no-await-in-loop */
    updatedData = {
      ...updatedData,
      IdentityFile: IdentityFiles,
    }
  }

  updatedData = {
    ...updatedData,
    BackgroundImage: {
      fileName: backgroundImageFile.fileName,
      fileMimeType: backgroundImageFile.fileMimeType,
    },
    Logo: {
      fileName: backgroundLogoFile.fileName,
      fileMimeType: backgroundLogoFile.fileMimeType,
    },
  }
  const resopnse = await Api.put('/org', updatedData)
  return resopnse
}

export const updateOrganizatoin = async (data) => {
  let updatedData = data

  // for identity file as it can 1 or more than 1
  if (updatedData.IdentityFile && updatedData.IdentityFile.length) {
    const IdentityFiles = []
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < updatedData.IdentityFile.length; i += 1) {
      const file = updatedData.IdentityFile[i]
      if (!file.fileName) {
        const identityFile = await uploadFile(file, 'Identity')
        IdentityFiles.push({
          fileName: identityFile.fileName,
          fileMimeType: identityFile.fileMimeType,
        })
      } else {
        IdentityFiles.push(file)
      }
    }
    /* eslint-disable no-await-in-loop */
    updatedData = {
      ...updatedData,
      IdentityFile: IdentityFiles,
    }
  }

  if (!updatedData.BackgroundImage.fileName) {
    const backgroundImageFile = await uploadFile(updatedData.BackgroundImage)
    updatedData = {
      ...updatedData,
      BackgroundImage: {
        fileName: backgroundImageFile.fileName,
        fileMimeType: backgroundImageFile.fileMimeType,
      },
    }
  }
  if (!updatedData.Logo.fileName) {
    const logoFile = await uploadFile(updatedData.Logo)
    updatedData = {
      ...updatedData,
      Logo: {
        fileName: logoFile.fileName,
        fileMimeType: logoFile.fileMimeType,
      },
    }
  }
  if (updatedData.W9Form && !updatedData.W9Form.fileName) {
    const w9FormFile = await uploadFile(updatedData.W9Form)
    updatedData = {
      ...updatedData,
      W9Form: {
        fileName: w9FormFile.fileName,
        fileMimeType: w9FormFile.fileMimeType,
      },
    }
  }
  const response = await Api.put('/org/updateOrg', updatedData)
  return response
}

export const updateOrganizatoinV2 = async (data) => {
  let updatedData = data

  // for identity file as it can 1 or more than 1
  if (updatedData.IdentityFile && updatedData.IdentityFile.length) {
    const IdentityFiles = []
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < updatedData.IdentityFile.length; i += 1) {
      const file = updatedData.IdentityFile[i]
      if (!file.fileName) {
        const identityFile = await uploadFile(file, 'Identity')
        IdentityFiles.push({
          fileName: identityFile.fileName,
          fileMimeType: identityFile.fileMimeType,
        })
      } else {
        IdentityFiles.push(file)
      }
    }
    /* eslint-disable no-await-in-loop */
    updatedData = {
      ...updatedData,
      IdentityFile: IdentityFiles,
    }
  }

  if (!updatedData.BackgroundImage.fileName) {
    const backgroundImageFile = await uploadFile(updatedData.BackgroundImage)
    updatedData = {
      ...updatedData,
      BackgroundImage: {
        fileName: backgroundImageFile.fileName,
        fileMimeType: backgroundImageFile.fileMimeType,
      },
    }
  }
  if (!updatedData.Logo.fileName) {
    const logoFile = await uploadFile(updatedData.Logo)
    updatedData = {
      ...updatedData,
      Logo: {
        fileName: logoFile.fileName,
        fileMimeType: logoFile.fileMimeType,
      },
    }
  }
  if (updatedData.W9Form && !updatedData.W9Form.fileName) {
    const w9FormFile = await uploadFile(updatedData.W9Form)
    updatedData = {
      ...updatedData,
      W9Form: {
        fileName: w9FormFile.fileName,
        fileMimeType: w9FormFile.fileMimeType,
      },
    }
  }

  const response = await makeRequest(
    'patch',
    '/webapp/organization/about',
    { organizationId: updatedData?.organizationId },
    updatedData,
  )
  return response
}

export const getUserOrganization = async (email) => {
  const response = await Api.get('/org/all')
  const orgs = response.results.filter(
    (x) => x.CreatedBy === email && !x.deleted,
  )
  return orgs[0]
}

export const getOrganization = async (id, userId) => {
  const response = await Api.get(`/org?id=${id}&userId=${userId}`)
  return response.results
}

export const getOrganizationDetails = (id, userId) => (dispatch) => {
  dispatch(organizationActions.setLoading(true))
  Api.get(`/org?id=${id}&userId=${userId}`)
    .then((res) => {
      dispatch(organizationActions.setOrganization(res.results))
      dispatch(organizationActions.setLoading(false))
    })
    .catch(() => {
      dispatch(organizationActions.setOrganization(null))
      dispatch(organizationActions.setLoading(false))
    })
}

export const associateOrganizationRequest =
  (id, organizationId, userId) => (dispatch) => {
    // dispatch(organizationActions.setLoading(true))
    Api.post('/org/associate-organization-request', { organizationId, userId })
      .then(() => {
        message.success('Request has been sent successfully')
        dispatch(getOrganizationDetails(id, userId))
      })
      .catch(() => {
        message.error('Somthing went wrong !')
        // dispatch(organizationActions.setLoading(false))
      })
  }

export const getOrganizations = () => (dispatch) => {
  dispatch(organizationActions.setLoading(true))
  Api.get('/orgList')
    .then((res) => {
      dispatch(organizationActions.setOrganizations(res?.results))
      dispatch(organizationActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(organizationActions.setLoading(false))
    })
}

export const getAllOrganizations =
  (allLastEvaluatedKey = null) =>
  (dispatch) => {
    const isFirstLoad = allLastEvaluatedKey == null
    // if (isFirstLoad) dispatch(organizationActions.setLoading(true))
    Api.put('/orgList/all', {
      limit: 100,
      lastEvaluatedKey: allLastEvaluatedKey,
    })
      .then((res) => {
        const data = res?.results?.data
        const key = res?.results?.LastEvaluatedKey
          ? res.results.LastEvaluatedKey
          : null
        dispatch(
          organizationActions.setAllOrganizations({
            isFirstLoad,
            data,
            key,
          }),
        )
        // dispatch(organizationActions.setLoading(false))
      })
      .catch((error) => {
        console.log('error', error)
        // dispatch(organizationActions.setLoading(false))
      })
  }

export const getNearbyOrganizationsByZip =
  (zipCode, lastEvaluatedKey = null) =>
  (dispatch) => {
    const isFirstLoad = lastEvaluatedKey == null
    if (isFirstLoad) dispatch(organizationActions.setLoading(true))
    Api.put('/orgList/nearByOrgs', {
      zipCode,
      limit: 50,
      lastEvaluatedKey,
    })
      .then((res) => {
        const data = res?.results?.data
        const key = res?.results?.LastEvaluatedKey
          ? res.results.LastEvaluatedKey
          : null
        dispatch(
          organizationActions.setOrganizations({ isFirstLoad, data, key }),
        )
        dispatch(organizationActions.setLoading(false))
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(organizationActions.setLoading(false))
      })
  }

export const getAllOrganizationsForAdmin = () => (dispatch) => {
  dispatch(organizationActions.setLoading(true))
  Api.get('/org/all')
    .then((res) => {
      dispatch(organizationActions.setOrganizationsForAdmin(res?.results))
      dispatch(organizationActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(organizationActions.setLoading(false))
    })
}

export const updateOrganizationByAdmin = (payload) => (dispatch) => {
  Api.put('/admin/update-org', payload)
    .then(() => {
      message.success('Updated Organization Successfully')
      dispatch(getAllOrganizationsForAdmin())
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(getAllOrganizationsForAdmin())
      message.success('Updated Organization Successfully')
      // message.error("Something went wrong");
    })
}

export const getSmsCost = (id) => () => {
  Api.get(`admin/subsrciberSMSCosting/${id}`)
    .then(() => {})
    .catch((error) => {
      console.log('error', error)
    })
}

export const getEmailCost = (id) => () => {
  Api.get(`admin/subsrciberEmailCosting/${id}`)
    .then(() => {})
    .catch((error) => {
      console.log('error', error)
    })
}

export const isMachineReadable = async (blob) => {
  const image = new File([blob], 'image.png', { type: 'image/png' })
  const readableFile = await uploadFile(image)
  const response = await Api.post('/org/isMachineReadable', {
    image: {
      name: readableFile.fileName,
    },
  })
  return response.results
}

export const updateOrganizationMembershipProgram = ({
  organizationId,
  status,
  setLoading,
  action,
}) => {
  setLoading(true)
  makeRequest(
    'put',
    '/component/organization/update-membership-program',
    { organization: organizationId },
    {
      organizationId,
      status,
    },
  )
    .then((res) => {
      if (action) action()
      message.destroy()
      message.success(
        res.message || 'Updated membership program status successfully',
      )
      setLoading(false)
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      setLoading(false)
    })
}

export const updateOrganizationMembershipProgramDescription = ({
  organizationId,
  description,
  setLoading,
  action,
}) => {
  setLoading(true)
  makeRequest(
    'put',
    '/component/organization/update-membership-program-decription',
    { organization: organizationId },
    {
      organizationId,
      description,
    },
  )
    .then((res) => {
      if (action) action()
      message.success(
        res.message || 'Updated membership program description successfully',
      )
      setLoading(false)
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      setLoading(false)
    })
}

export const getOrganizationTiers = async (organizationId) => {
  const response = await makeRequest(
    'get',
    '/component/organization/get-membership-program-tiers',
    { organization: organizationId },
  )
  return response
}

export const getOrganizationInfo = async (organizationId) => {
  const response = await makeRequest(
    'get',
    `/component/organization/get-organization/${organizationId}`,
    { organization: organizationId },
  )
  return response
}

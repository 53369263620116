import React, { useEffect, useState } from 'react'
import { Donations } from 'components/common'
import Api from 'api'
import { Skeleton, message } from 'antd'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { config } from 'configs'

const PledgeConversion = () => {
  const { id } = useParams()
  const [item, setItem] = useState(null)
  const [isFetching, setFetching] = useState(true)

  const getData = async () => {
    try {
      setFetching(true)
      // get item details
      const itemResponse = await Api.get(`/pledge/${id}`)
      if (itemResponse.results) {
        setItem(itemResponse.results)
      }
      setFetching(false)
    } catch (error) {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      setFetching(false)
    }
  }

  useEffect(() => {
    if (id) {
      getData()
    }
    // eslint-disable-next-line
  }, [id])

  return (
    <Skeleton
      loading={isFetching}
      active
      paragraph={{ rows: 18 }}
      className="t-w-full t-h-full"
    >
      <Donations donationType="pledge-conversion" item={item} />
    </Skeleton>
  )
}

export { PledgeConversion }

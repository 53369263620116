import { makeRequest } from 'api'
import { message } from 'antd'
import { config } from 'configs'
import { uploadFile } from 'store/authSlice/authActions'
import { organizationProfilesActions } from './OrganizationProfilesReducer'

export const addProfile = (payload, actions) => async (dispatch) => {
  dispatch(organizationProfilesActions.setLoading({ addOrUpdate: true }))

  const updatedPayload = payload
  if (payload.Logo) {
    try {
      const logo = await uploadFile(payload.Logo)
      updatedPayload.Logo = {
        fileName: logo.fileName,
        fileMimeType: logo.fileMimeType,
      }
    } catch (error) {
      message.error('Unable to upload file')
    }
  }

  makeRequest(
    'post',
    `/webapp/organization/profiles/`,
    {
      organizationid: payload.OrganizationId,
    },
    updatedPayload,
  )
    .then((res) => {
      message.success(res.message || 'Profile added successfully')
      dispatch(organizationProfilesActions.setLoading({ addOrUpdate: false }))
      if (actions) {
        actions()
      }
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(organizationProfilesActions.setLoading({ addOrUpdate: false }))
    })
}

export const updateProfile = (payload, actions) => async (dispatch) => {
  dispatch(organizationProfilesActions.setLoading({ addOrUpdate: true }))

  const updatedPayload = payload
  if (payload.Logo && !payload?.Logo?.fileName) {
    try {
      const logo = await uploadFile(payload.Logo)
      updatedPayload.Logo = {
        fileName: logo.fileName,
        fileMimeType: logo.fileMimeType,
      }
    } catch (error) {
      message.error('Unable to upload file')
    }
  }

  makeRequest(
    'put',
    `/webapp/organization/profiles/${payload?.OrganizationId}/${payload?.ProfileId}`,
    {
      organizationid: payload.OrganizationId,
    },
    payload,
  )
    .then((res) => {
      message.success(res.message || 'Profile updated successfully')
      dispatch(organizationProfilesActions.setLoading({ addOrUpdate: false }))
      if (actions) {
        actions()
      }
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(organizationProfilesActions.setLoading({ addOrUpdate: false }))
    })
}

export const deleteProfile =
  (organizationId, profileId, actions) => (dispatch) => {
    dispatch(organizationProfilesActions.setLoading({ deleting: true }))
    makeRequest(
      'delete',
      `/webapp/organization/profiles/${organizationId}/${profileId}`,
      {
        organizationid: organizationId,
      },
    )
      .then((res) => {
        message.success(res.message || 'Profile deleted successfully')
        dispatch(organizationProfilesActions.setLoading({ deleting: false }))
        dispatch(organizationProfilesActions.setProfile(null))
        if (actions) {
          actions()
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
        dispatch(organizationProfilesActions.setLoading({ deleting: false }))
        dispatch(organizationProfilesActions.setProfile(null))
      })
  }

export const getProfile = (organizationId, profileId, form) => (dispatch) => {
  dispatch(organizationProfilesActions.setLoading({ fetchProfile: true }))
  makeRequest(
    'get',
    `/webapp/organization/profiles/${organizationId}/${profileId}`,
    {
      organizationid: organizationId,
    },
  )
    .then((res) => {
      const item = res.results
      if (item) {
        if (form) {
          form.setFieldsValue({
            Logo: item?.Logo,
            ProfileTitle: item?.ProfileTitle,
            OrganizationName: item?.OrganizationName,
            OrganizationEmail: item?.OrganizationEmail,
            OrganizationWebsite: item?.OrganizationWebsite,
            Description: item?.Description,
          })
        } else {
          dispatch(organizationProfilesActions.setProfile(item))
        }
      } else {
        dispatch(organizationProfilesActions.setProfile(null))
      }
      dispatch(organizationProfilesActions.setLoading({ fetchProfile: false }))
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(organizationProfilesActions.setProfile(null))
      dispatch(organizationProfilesActions.setLoading({ fetchProfile: false }))
    })
}

export const getProfileList =
  ({ organizationId } = {}) =>
  (dispatch) => {
    dispatch(organizationProfilesActions.reset())
    dispatch(organizationProfilesActions.setLoading({ fetchProfileList: true }))
    makeRequest('get', `/webapp/organization/profiles/`, {
      organizationid: organizationId,
    })
      .then((res) => {
        const items = res?.results
        if (items) {
          dispatch(organizationProfilesActions.setProfileList(items))
        } else {
          dispatch(organizationProfilesActions.setProfileList([]))
        }
        dispatch(
          organizationProfilesActions.setLoading({ fetchProfileList: false }),
        )
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
        dispatch(organizationProfilesActions.setProfileList([]))
        dispatch(
          organizationProfilesActions.setLoading({ fetchProfileList: false }),
        )
      })
  }

export const getInUseList =
  ({ organizationId, profileId } = {}) =>
  (dispatch) => {
    dispatch(organizationProfilesActions.setLoading({ fetchInUseList: true }))
    makeRequest(
      'get',
      `/webapp/organization/profiles/in-use/${organizationId}/${profileId}`,
      {
        organizationid: organizationId,
      },
    )
      .then((res) => {
        const items = res?.results
        if (items) {
          dispatch(organizationProfilesActions.setInUseList(items))
        } else {
          dispatch(organizationProfilesActions.setInUseList([]))
        }
        dispatch(
          organizationProfilesActions.setLoading({ fetchInUseList: false }),
        )
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
        dispatch(organizationProfilesActions.setInUseList([]))
        dispatch(
          organizationProfilesActions.setLoading({ fetchInUseList: false }),
        )
      })
  }

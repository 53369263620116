import React, { useState, useEffect } from 'react'
import {
  Card,
  Col,
  Input,
  Form,
  Row,
  Typography,
  Button,
  // Switch,
  Skeleton,
  // Divider,
  Checkbox,
} from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useRoomSelector } from 'store/roomSlice/roomReducer'
import { createRoom, getRoom, updateRoom } from 'store/roomSlice/roomActions'
import { sortScheduleByDays } from 'utils'
import { AmountInput } from 'components/elements'
import { AutoCompleteAddressV2 } from 'components/common'
import { FaRegEdit } from 'react-icons/fa'
// import { MdDeleteForever } from 'react-icons/md'
import { RxTrash } from 'react-icons/rx'

import { CloseCircleFilled, PlusCircleOutlined } from '@ant-design/icons'
import { MultiImageUploader } from 'components/elements/MultiImageUploader'
import { DayDurations } from './DayDurations'

const { Title, Paragraph, Text } = Typography

const schema = Yup.object().shape({
  Name: Yup.string().required('Room name is required'),
  Instructions: Yup.string(),
  Breadth: Yup.string().required().typeError('Room breadth is required'),
  Width: Yup.string().required().typeError('Room width is required'),
  Capacity: Yup.string().required().typeError('Capacity is required'),
  MaximumReservationHours: Yup.string()
    .required()
    .typeError('Maximum reservation hours is required')
    .test(
      'max-hours',
      'Maximum reservation hours cannot be greater than 24',
      (value) => {
        if (value) {
          const hours = parseInt(value, 10)
          return hours <= 24
        }
        return true
      },
    ),
  Available: Yup.boolean(),
  NeedApproval: Yup.boolean(),
  Image: Yup.mixed().typeError('Image is invalid'),
  PerHourRate: Yup.string().required().typeError('Per hour rate is required'),
  ShowOnPage: Yup.string().required('Show on landing page is required'),
})

const roomValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  Name: '',
  Instructions: '',
  Breadth: null,
  Width: null,
  Capacity: null,
  MaximumReservationHours: null,
  Available: false,
  NeedApproval: false,
  Image: [],
  ShowOnPage: false,
}

const roomScheduleInitialState = {
  monday: { dayName: 'monday', durations: [], open: false, nonstop: false },
  tuesday: { dayName: 'tuesday', durations: [], open: false, nonstop: false },
  wednesday: {
    dayName: 'wednesday',
    durations: [],
    open: false,
    nonstop: false,
  },
  thursday: { dayName: 'thursday', durations: [], open: false, nonstop: false },
  friday: { dayName: 'friday', durations: [], open: false, nonstop: false },
  saturday: {
    dayName: 'saturday',
    durations: [],
    open: false,
    nonstop: false,
  },
  sunday: { dayName: 'sunday', durations: [], open: false, nonstop: false },
}

const isValidSchedule = (schedule) => {
  const filteredSchedule = []
  Object.keys(schedule).forEach((key) => {
    const value = schedule[key]
    if (value.open) {
      filteredSchedule.push({
        day: key,
        ...value,
      })
    }
  })
  const filteredValues = filteredSchedule.filter(
    ({ open, nonstop, durations }) =>
      !(open && (nonstop || durations.length > 0)),
  )
  return filteredValues.length > 0 || filteredSchedule.length === 0
}

const CreateRoom = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { user, organization } = useAuthSelector()
  const { loading } = useRoomSelector()
  const { id } = useParams()
  const [schedule, setSchedule] = useState({ ...roomScheduleInitialState })
  const [isLoading, setLoading] = useState(false)

  const [isQuestionCollapsed, setIsQuestionCollapsed] = useState(true)
  const [questions, setQuestions] = useState([])
  const [newQuestion, setNewQuestion] = useState('')
  const [isRequired, setIsRequired] = useState(false)
  const [editingQuestionId, setEditingQuestionId] = useState(null)
  const [editedQuestion, setEditedQuestion] = useState('')
  const [editedQuestionRequired, setEditedQuestionRequired] = useState(false)
  const [questionError, setQuestionError] = useState('')

  const [sameAsOrg, setSameAsOrg] = useState(true)

  // For storing default files
  const [defaultFyler, setDefaultFyler] = useState([])

  Form.useWatch('Image', form)
  Form.useWatch('Available', form)
  Form.useWatch('NeedApproval', form)
  Form.useWatch('ShowOnPage', form)

  useEffect(() => {
    if (id) {
      fetchData()
    }
    // eslint-disable-next-line
  }, [id])

  const handleOrganizationAddresss = () => {
    form.setFieldsValue({
      mailingAddress: organization.Address1 && organization.Address1,
    })
    form.setFieldsValue({
      suite: `${organization.Address2}`,
    })
    form.setFieldsValue({ zipCode: organization.ZipCode })
    form.setFieldsValue({ city: organization.City })
    form.setFieldsValue({ state: organization.State })
    form.setFieldsValue({ country: organization.Country })
  }

  useEffect(() => {
    if (organization && organization?.ZipCode) {
      handleOrganizationAddresss()
    }
    // eslint-disable-next-line
  }, [organization, id])

  const fetchData = () => {
    const getRoomDetail = async () => {
      setLoading(true)
      try {
        const roomDetail = await getRoom(id)
        const dummyDefaultFlyer = roomDetail.Image
          ? roomDetail.Image.length
            ? roomDetail.Image
            : [roomDetail.Image]
          : []

        setDefaultFyler(dummyDefaultFlyer)
        form.setFieldsValue({
          Name: roomDetail?.Name,
          PerHourRate: roomDetail?.PerHourRate,
          Width: roomDetail?.Width,
          Breadth: roomDetail?.Breadth,
          Capacity: roomDetail?.Capacity,
          Instructions: roomDetail?.Instructions,
          Available: roomDetail?.Available,
          MaximumReservationHours: roomDetail?.MaximumReservationHours,
          NeedApproval: roomDetail?.NeedApproval,
          Image: roomDetail.Image
            ? roomDetail.Image.length
              ? roomDetail.Image
              : [roomDetail.Image]
            : [],
          ShowOnPage: roomDetail.ShowOnPage ? roomDetail.ShowOnPage : false,
          Questions: questions,
          mailingAddress: roomDetail?.Address1
            ? roomDetail?.Address1
            : `${roomDetail?.Address2 && `${roomDetail?.Address2},`} ${
                roomDetail.City
              }, ${roomDetail.LocationState}`,
          zipCode: roomDetail?.ZipCode,
          state: roomDetail?.LocationState,
        })
        setQuestions(roomDetail.Question)
        setSameAsOrg(roomDetail?.SameAsOrg ?? true)
        if (roomDetail?.SameAsOrg) handleOrganizationAddresss()
        const updatedSchedule = sortScheduleByDays(roomDetail?.Schedule)
        setSchedule(updatedSchedule)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    getRoomDetail()
  }

  const onFinish = (values) => {
    let formValues = {
      ...values,
      Schedule: schedule,
      CreatorId: user.id,
      CreatorEmail: user.email,
      OrganizationId: organization.id,
      Address1: values.mailingAddress,
      Address2: values.mailingAddress,
      ZipCode: values.zipCode,
      City: values.city,
      LocationState: values.state,
      Country: values.country,
      Address: values.mailingAddress,
      Questions: questions,
      SameAsOrg: sameAsOrg,
    }
    const action = () => {
      clearForm()
    }
    if (id) {
      formValues = { ...formValues, Id: id }
      dispatch(updateRoom(formValues, action, defaultFyler))
    } else {
      dispatch(createRoom(formValues, action, defaultFyler))
    }
  }

  const clearForm = () => {
    form.resetFields()
    onClearAllClick()
    history.push('/rentals')
  }

  const onChangeSchedule = (value) => {
    const scheduleClone = schedule
    scheduleClone[value.dayName] = value
    setSchedule({ ...scheduleClone })
  }

  const onClearAllClick = () => {
    setSchedule({ ...roomScheduleInitialState })
  }

  const onApplyToAll = (value) => {
    const scheduleClone = { ...schedule }
    Object.keys(scheduleClone).map((dayName) => {
      scheduleClone[dayName] = { ...value, dayName }
      return dayName
    })
    setSchedule({ ...scheduleClone })
  }

  const handleAddQuestion = () => {
    if (newQuestion.trim() === '') {
      setQuestionError('Question is empty')
      return
    }

    // Ensure that questions is an array before spreading
    const updatedQuestions = questions ?? []

    const newQuestionObj = {
      id: updatedQuestions.length
        ? updatedQuestions[updatedQuestions.length - 1].id + 1
        : 1, // Generate unique id
      question: newQuestion,
      required: isRequired,
      answer: '',
    }

    setQuestions([...updatedQuestions, newQuestionObj])
    setNewQuestion('')
    setIsRequired(false)
  }

  const handleEditClick = (questionId, currentQuestion, currentRequired) => {
    setEditingQuestionId(questionId)
    setEditedQuestion(currentQuestion)
    setEditedQuestionRequired(currentRequired)
  }

  const handleUpdateQuestion = (questionId) => {
    const updatedQuestions = questions.map((item) =>
      item.id === questionId
        ? {
            ...item,
            question: editedQuestion,
            required: editedQuestionRequired,
          }
        : item,
    )
    setQuestions(updatedQuestions)
    setEditingQuestionId(null)
    setEditedQuestion('')
    setEditedQuestionRequired(false)
  }

  const handleDeleteQuestion = (questionToDelete) => {
    setQuestions(
      questions.filter((question) => question.id !== questionToDelete.id),
    )
  }

  // const actions = () => {
  //   setSameAsOrg(false)
  // }

  const handleSameAsOrgChange = () => {
    if (sameAsOrg) {
      form.setFieldsValue({ mailingAddress: '' })
      form.setFieldsValue({ suite: '' })
      form.setFieldsValue({ zipCode: '' })
      form.setFieldsValue({ city: '' })
      form.setFieldsValue({ state: '' })
      form.setFieldsValue({ country: '' })
    } else {
      handleOrganizationAddresss()
    }
    setSameAsOrg(!sameAsOrg)
  }

  return (
    <Card
      className="header-solid t-mx-8"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0">{id ? 'Update Room' : 'Create Room'}</h6>
          {/* <p className="font-regular">Enter Information</p> */}
        </>,
      ]}
    >
      <Skeleton loading={isLoading} active paragraph={{ rows: 18 }}>
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="room"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Name"
                label="Room Name"
                colon={false}
                rules={[roomValidation]}
              >
                <Input placeholder="Room Name" />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Capacity"
                label="Capacity"
                colon={false}
                rules={[roomValidation]}
              >
                <Input
                  placeholder="Room Capacity"
                  inputMode="numeric"
                  suffix="persons"
                  onChange={(e) =>
                    form.setFieldsValue({
                      Capacity: e.target.value.replace(/\D/g, ''),
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Breadth"
                label="Length"
                colon={false}
                rules={[roomValidation]}
              >
                <Input
                  placeholder="Room Length"
                  inputMode="numeric"
                  suffix="feet"
                  onChange={(e) =>
                    form.setFieldsValue({
                      Breadth: e.target.value.replace(/\D/g, ''),
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Width"
                label="Width"
                colon={false}
                rules={[roomValidation]}
              >
                <Input
                  placeholder="Room Width"
                  inputMode="numeric"
                  suffix="feet"
                  onChange={(e) =>
                    form.setFieldsValue({
                      Width: e.target.value.replace(/\D/g, ''),
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="MaximumReservationHours"
                label="Max Reservation Hours"
                colon={false}
                rules={[roomValidation]}
              >
                <Input
                  placeholder="Max reservation hours"
                  inputMode="numeric"
                  suffix="hours"
                  onChange={(e) =>
                    form.setFieldsValue({
                      MaximumReservationHours: e.target.value.replace(
                        /\D/g,
                        '',
                      ),
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="PerHourRate"
                label="Hourly Rate"
                colon={false}
                rules={[roomValidation]}
              >
                <AmountInput
                  placeholder="Per hour rate"
                  value={form.getFieldValue('PerHourRate')}
                  onChange={(value) =>
                    form.setFieldsValue({ PerHourRate: value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="address"
                // label="
                colon={false}
              >
                <AutoCompleteAddressV2
                  form={form}
                  validation
                  nonEditable={sameAsOrg}
                  isInitiallyValidated={id}
                  setSameAsOrg={setSameAsOrg}
                />
              </Form.Item>
            </Col>
            <Row className="t-mt-[-30px]">
              <Form.Item
                name="sameAsOrg"
                valuePropName="checked"
                // label="Room is available for reservation?"
                colon={false}
                className="t-mx-6"
              >
                <div className="t-flex t-flex-row">
                  <Checkbox
                    checked={sameAsOrg}
                    onChange={() => handleSameAsOrgChange()}
                  />
                  <Typography.Title className="t-text-[13px] t-font-semibold t-mx-2">
                    Same as Organization
                  </Typography.Title>
                </div>
              </Form.Item>
            </Row>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="Instructions"
                label="Instructions"
                colon={false}
                rules={[roomValidation]}
              >
                <Input.TextArea
                  placeholder="Room instructions"
                  autoSize={{ minRows: 3, maxRows: 3 }}
                />
              </Form.Item>
            </Col>
            {/* <AutoCompleteAddress
              form={form}
              validation
              // nonEditable={sameAsOrg}
              isInitiallyValidated={id}
            /> */}
            {/* <Row>
              <Col span={24}>
                <Form.Item name="allowedPaymentMethods" colon={false}>
                  <Checkbox.Group options={paymentMethods} />
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Form.Item
                name="Available"
                valuePropName="checked"
                // label="Room is available for reservation?"
                colon={false}
                rules={[roomValidation]}
                className="t-mx-6"
              >
                <div className="t-flex t-flex-row">
                  <Checkbox checked={form.getFieldValue('Available')} />
                  <Typography.Title className="t-text-[13px] t-font-semibold t-mx-2">
                    Room is available for reservation?
                  </Typography.Title>
                </div>
              </Form.Item>

              <Form.Item
                name="NeedApproval"
                valuePropName="checked"
                // label="Room needs approval?"
                colon={false}
                rules={[roomValidation]}
                className="t-mx-6"
              >
                <div className="t-flex t-flex-row">
                  <Checkbox checked={form.getFieldValue('NeedApproval')} />
                  <Typography.Title className="t-text-[13px] t-font-semibold t-mx-2">
                    Room needs approval?
                  </Typography.Title>
                </div>
                {/* <Switch checked={form.getFieldValue('NeedApproval')} /> */}
              </Form.Item>

              <Form.Item
                name="ShowOnPage"
                valuePropName="checked"
                // label="Show on Landing Page"
                colon={false}
                rules={[roomValidation]}
                className="t-mx-6"
              >
                <div className="t-flex t-flex-row">
                  <Checkbox checked={form.getFieldValue('ShowOnPage')} />
                  <Typography.Title className="t-text-[13px] t-font-semibold t-mx-2">
                    Show on Landing Page
                  </Typography.Title>
                </div>
                {/* <Switch checked={form.getFieldValue('ShowOnPage')} /> */}
              </Form.Item>
            </Row>
            <Col span={24}>
              <div className="t-flex t-justify-between">
                <div>
                  <Title level={4} className="t-text-black">
                    Room Availability Duration
                  </Title>
                  <Paragraph>
                    You need to define room availability for each day of week
                    for this room
                  </Paragraph>
                </div>
                <Button onClick={onClearAllClick}>Clear All</Button>
              </div>

              <div className="t-space-y-2 t-w-full t-pt-4 t-overflow-scroll">
                {/* <div className="t-flex t-space-x-2 t-items-center t-px-2">
                  <div
                    className="t-flex t-space-x-2 t-items-center t-min-w-36"
                    style={{
                      minWidth: '9rem',
                    }}
                  >
                    <Title
                      level={5}
                      className="t-font-bold t-text-secondary-100"
                    >
                      Day
                    </Title>
                  </div>
                  <Title level={5} className="t-font-bold t-text-secondary-100">
                    Available Time slots
                  </Title>
                </div> */}
                {/* <Divider /> */}
                {Object.keys(schedule).map((day, index) => (
                  <DayDurations
                    key={index}
                    value={schedule[day]}
                    onChange={onChangeSchedule}
                    onApplyAll={() => onApplyToAll(schedule[day])}
                    updateSchedule={schedule}
                  />
                ))}
              </div>
            </Col>

            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item name="Image" colon={false} className="t-mt-8">
                <div className="t-flex t-flex-col t-mb-4">
                  <Title level={5}>Room Image</Title>
                  <Text>Upload 5 images max. (jeg, jpeg, png)</Text>
                </div>
                <MultiImageUploader
                  ratio={2 / 1}
                  defaultFiles={
                    form.getFieldValue('Image') && form.getFieldValue('Image')
                  }
                  onFileChange={(files) =>
                    form.setFieldsValue({
                      Image: files,
                    })
                  }
                />
              </Form.Item>
            </Col>

            <Col xl={24} md={24} xs={24} sm={24}>
              <Card
                title="Add Questions"
                extra={
                  !isQuestionCollapsed ? (
                    <PlusCircleOutlined
                      onClick={() => setIsQuestionCollapsed((prev) => !prev)}
                      style={{ fontSize: '24px', cursor: 'pointer' }}
                    />
                  ) : (
                    <CloseCircleFilled
                      onClick={() => setIsQuestionCollapsed((prev) => !prev)}
                      style={{ fontSize: '24px', cursor: 'pointer' }}
                    />
                  )
                }
              >
                {isQuestionCollapsed && (
                  <Row>
                    <Col span={24}>
                      {questions?.map((item, index) => (
                        <div key={item.id} className="question-itemt t-mb-2">
                          <div className="t-flex t-justify-between">
                            <div className="t-flex t-flex-col t-w-[100%]">
                              <Typography.Title level={5}>
                                {`${index + 1}. Question `}
                              </Typography.Title>
                              {editingQuestionId === item.id ? (
                                <>
                                  <div className="t-flex t-items-center t-space-x-3 t-w-full">
                                    <Input
                                      value={editedQuestion}
                                      onChange={(e) =>
                                        setEditedQuestion(e.target.value)
                                      }
                                      className="t-mt-2 t-text-base t-font-normal t-flex-1"
                                    />
                                    <Button
                                      type="primary"
                                      className="t-mt-2"
                                      onClick={() =>
                                        handleUpdateQuestion(item.id)
                                      }
                                    >
                                      Save
                                    </Button>
                                  </div>
                                  <div className="t-w-full t-text-right t-mt-2">
                                    <Checkbox
                                      checked={editedQuestionRequired}
                                      onChange={(e) =>
                                        setEditedQuestionRequired(
                                          e.target.checked,
                                        )
                                      }
                                    >
                                      Required
                                    </Checkbox>
                                  </div>
                                </>
                              ) : (
                                <Typography.Title className="-t-mt-2 t-ml-3 t-text-base t-font-normal">
                                  {`${item.question}${
                                    item.question.trim().endsWith('?')
                                      ? ''
                                      : '?'
                                  }${item.required ? '*' : ''}`}
                                </Typography.Title>
                              )}
                            </div>
                            <div className="t-flex t-flex-row">
                              {editingQuestionId === item.id ? null : (
                                <>
                                  <FaRegEdit
                                    className="t-text-2xl t-text-gray-400 t-cursor-pointer t-mx-2"
                                    onClick={() =>
                                      handleEditClick(
                                        item.id,
                                        item.question,
                                        item.required,
                                      )
                                    }
                                  />
                                  <RxTrash
                                    className="t-text-2xl t-text-gray-400 t-cursor-pointer t-mx-2"
                                    onClick={() => handleDeleteQuestion(item)}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="t-bg-gray-50 t-p-4 t-rounded-md t-mt-2">
                        <Form.Item className="t-bg-gray-50">
                          <Typography.Title level={5}>
                            {`${(questions?.length ?? 0) + 1}. Question`}
                          </Typography.Title>
                          <Input
                            placeholder="Enter a Question Here"
                            value={newQuestion}
                            onChange={(e) => {
                              setNewQuestion(e.target.value)
                              if (e.target.value.trim() !== '') {
                                setQuestionError('')
                              }
                            }}
                            className={`t-mt-4 ${
                              questionError ? 't-border-red-500' : ''
                            }`}
                          />
                          {questionError && (
                            <p className="t-text-red-500 t-text-sm t-mt-1">
                              {questionError}
                            </p>
                          )}
                          <Col
                            span={24}
                            className="text-right t-space-x-2 t-mt-2"
                          >
                            <Checkbox
                              checked={isRequired}
                              onChange={(e) => setIsRequired(e.target.checked)}
                            >
                              Required
                            </Checkbox>
                          </Col>
                        </Form.Item>
                        <Button
                          type="primary"
                          className="t-mt-4"
                          onClick={handleAddQuestion}
                        >
                          Add Question
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}
              </Card>
            </Col>

            <Col span={24}>
              <div className="text-right t-my-4 t-space-x-2">
                <Button
                  type="default"
                  classname="t-w-full md:t-w-auto"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  classname="t-w-full md:t-w-auto"
                  htmlType="submit"
                  loading={loading}
                  disabled={isValidSchedule(schedule)}
                >
                  {id ? 'Update Rental Facility' : 'Add Rental Facility'}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </Card>
  )
}

export { CreateRoom }

import React, { useEffect, useRef, useState } from 'react'
import {
  Input,
  Form,
  Col,
  DatePicker,
  // Tooltip,
  Divider,
  Tooltip,
  Checkbox,
} from 'antd'
import { marriageValidation } from 'validations'
import { HiInformationCircle } from 'react-icons/hi'
import { compareDateTimeWithNow, disabledTime } from 'utils'
import moment from 'moment'
import { LabelWithHelpText } from 'components/elements'
import { awsconfig } from 'configs'
import { usePlacesWidget } from 'react-google-autocomplete'

const MarriageForm = ({ form, service }) => {
  const [isValidated, setIsValidated] = useState(false)
  const antInputRef = useRef(null)
  Form.useWatch('agreeToTerms', form)

  const clearAddress = () => {
    setIsValidated(false)
    form.setFieldValue('CeremonyLocation', '')
  }

  const { ref: antRef } = usePlacesWidget({
    apiKey: awsconfig.google_api_key,
    onPlaceSelected: (place) => {
      setIsValidated(true)
      form.setFieldValue('CeremonyLocation', place?.formatted_address)
    },
    options: {
      componentRestrictions: { country: ['us', 'ca'] },
      fields: ['address_components', 'formatted_address', 'geometry'],
      types: ['address'],
    },
  })

  // To hanlde auto sumbiting of form on place selection
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault() // Prevent the default Enter key behavior
      }
    }

    if (antRef.current) {
      antRef.current.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      if (antRef.current) {
        antRef.current.removeEventListener('keydown', handleKeyDown)
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Col span={24}>
        <Form.Item
          name="Date"
          label="Date"
          colon={false}
          rules={[marriageValidation]}
        >
          <DatePicker
            placeholder="MM-DD-YYYY hh:mm A"
            format="MM-DD-YYYY hh:mm A"
            showTime
            minuteStep={30}
            className="t-w-full"
            disabledDate={(d) => !d || d.isBefore(moment().subtract(1, 'day'))}
            disabledTime={disabledTime}
            onChange={(selectedDate) =>
              !compareDateTimeWithNow(selectedDate, 'before') &&
              form.setFieldsValue({
                Date: moment(),
              })
            }
            getPopupContainer={(trigger) => trigger.parentElement}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="RequestedTime"
          label={
            <LabelWithHelpText
              label="Requested Time"
              helpText={service?.RequestedTimeHelpText}
            />
          }
          colon={false}
          rules={[marriageValidation]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="MarriageLicenseNumber"
          label="Marriage License Number"
          colon={false}
          rules={[marriageValidation]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="CourtMarriageLocation"
          label="Court Marriage Location"
          colon={false}
          rules={[marriageValidation]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="CeremonyLocation"
          label={
            <LabelWithHelpText
              label="Ceremony Location"
              helpText={service?.CeremonyLocationHelpText}
            />
          }
          colon={false}
          rules={[marriageValidation]}
        >
          <Input
            placeholder="Search Address"
            ref={(c) => {
              antInputRef.current = c
              if (c) antRef.current = c.input
            }}
            onFocus={() => {
              if (isValidated) {
                clearAddress()
              }
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          name="BrideName"
          label="Bride's Name"
          colon={false}
          rules={[marriageValidation]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="BrideReligion"
          label={
            <LabelWithHelpText
              label="Bride's Religion"
              helpText={service?.BrideReligionHelpText}
            />
          }
          colon={false}
          rules={[marriageValidation]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="GroomName"
          label="Groom's Name"
          colon={false}
          rules={[marriageValidation]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="GroomReligion"
          label={
            <LabelWithHelpText
              label="Groom's Religion"
              helpText={service?.GroomReligionHelpText}
            />
          }
          colon={false}
          rules={[marriageValidation]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="NumberOfAttendees"
          label={
            <LabelWithHelpText
              label="Expected Number of Attendees"
              helpText={service?.NumberOfAttendeesHelpText}
            />
          }
          colon={false}
          rules={[marriageValidation]}
        >
          <Input type="number" inputMode="numeric" pattern="[0-9]*" />
        </Form.Item>
      </Col>
      <Divider />
      <Col span={24} className="t-flex t-justify-between t-items-center t-mb-4">
        <h6>Requirements</h6>
        <Tooltip title={service?.Requirements}>
          <HiInformationCircle
            fontSize="1.5rem"
            className="t-text-secondary-100 t-cursor-pointer"
          />
        </Tooltip>
      </Col>
      <Col span={24}>
        <Form.Item
          name="WaliWakeelName"
          label="Wali/Wakeel's Name"
          colon={false}
          rules={[marriageValidation]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="WaliWakeelRelationship"
          label="Wali/Wakeel's Relationship To Bride"
          colon={false}
          rules={[marriageValidation]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Divider />
      <Col span={24} className="t-flex t-justify-between t-items-center t-mb-4">
        <h6>Enter Witness Detail</h6>
      </Col>
      <Col span={24}>
        <Form.Item
          name="Witness1Name"
          label="Witness 1's Name"
          colon={false}
          rules={[marriageValidation]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="Witness2Name"
          label="Witness 2's Name"
          colon={false}
          rules={[marriageValidation]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Divider />
      <Col span={24} className="t-flex t-justify-between t-items-center t-mb-4">
        <h6>Describe Dowry</h6>
      </Col>
      <Col span={24}>
        <Form.Item
          name="MahrMuqaddam"
          label="Mahr (Dowry) to be given immediately (Mahr Muqaddam)"
          colon={false}
          rules={[marriageValidation]}
        >
          <Input.TextArea
            // placeholder="Enter detail of Mahr Muqaddam"
            autoSize={{ minRows: 2, maxRows: 3 }}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="MahrMuakhar"
          label="Mahr to be deferred (Mahr Muakhar)"
          colon={false}
          rules={[marriageValidation]}
        >
          <Input.TextArea
            // placeholder="Enter detail of Mahr Muakhar"
            autoSize={{ minRows: 2, maxRows: 3 }}
          />
        </Form.Item>
      </Col>
      <Col xl={24} md={24} xs={24} sm={24}>
        <Form.Item
          name="agreeToTerms"
          colon={false}
          rules={[marriageValidation]}
        >
          <Checkbox
            onChange={(e) =>
              form.setFieldsValue({
                agreeToTerms: e.target.checked,
              })
            }
          >
            I agree to terms and conditions
          </Checkbox>
        </Form.Item>
      </Col>
    </>
  )
}

export { MarriageForm }

import * as Yup from 'yup'
import { REGEXS } from 'utils'

const numberValidation = REGEXS.numberRegex
const amountValidation = REGEXS.amountRegex
const zipCodeRegex = REGEXS.zipcodeRegex
const phoneNumberValidation = '^[0-9-() ]{14,15}$'

const schema = Yup.object().shape({
  Title: Yup.string().required('Title is required'),
  Description: Yup.string()
    .required('Description is required')
    .min(10, 'Description should be atleast 10 character'),
  Requirements: Yup.string().required('Requirements is required'),
  ProgramFees: Yup.string()
    .required('Program Dues is required')
    .matches(amountValidation, 'Invalid cost'),
  DeliveryLocation: Yup.string().required('Delivery Location is required'),
  ZipCode: Yup.string()
    .matches(zipCodeRegex, 'Invalid Zip code e.g 12345')
    .required('Zip Code is required'),
  City: Yup.string().required('City is required'),
  LocationState: Yup.string().required('State is required'),
  Address: Yup.string().required('Address is required'),
  Notes: Yup.string(),
  MaximumNumberOfRegistrations: Yup.string()
    .required('Allowed Registration is required')
    .matches(numberValidation, 'Invalid number'),
  AddAgeRange: Yup.string().required('Add age range is required'),
  MinimumAge: Yup.string()
    .required('Minimum age is required')
    .matches(numberValidation, 'Invalid age'),
  MaximumAge: Yup.string()
    .required('Maximum age is required')
    .matches(numberValidation, 'Invalid age'),
  Flyer: Yup.mixed().typeError('Flyer is not a file'),
  ShowOnLandingPage: Yup.string().required('Show on landing page is required'),
  ProgramType: Yup.string().required('Program type is required'),
  AllowToEditFee: Yup.boolean().required('Allow Edit Fee is required'),
  AllowExtraPayment: Yup.boolean().required('Allow Extra Payment is required'),
  IncludeProcessingFee: Yup.boolean().required(
    'Include processing fee is required',
  ),
  AllowSplitPayments: Yup.boolean().required(
    'Allow split payments is required',
  ),
  NumberOfDefaultSplit: Yup.string().required(
    'Duration of split payment is required',
  ),
  PaymentCategoryAdded: Yup.string().required(
    'Payment category added is required',
  ),
  MaleAllowed: Yup.boolean().required('Male is required'),
  FemaleAllowed: Yup.boolean().required('Male is required'),
  StartDate: Yup.date()
    .typeError('Invalid Date')
    .required('StartDate is required'),
  EndDate: Yup.date().typeError('Invalid Date').required('EndDate is required'),
  InvitedTeacher: Yup.string()
    .required('Invite teacher email is required')
    .email('Email is invalid'),
  AddTeacher: Yup.string().required('Add teacher is required'),
  TeacherFirstName: Yup.string().required('Teacher first name is required'),
  TeacherLastName: Yup.string().required('Teacher last name is required'),
  TeacherPhoneCode: Yup.string().required('Teacher phone code is required'),
  TeacherMobileNumber: Yup.string()
    .required('Teacher phone number is required')
    .matches(phoneNumberValidation, 'Invalid Phone number e.g 1234567891'),
  TeacherEmail: Yup.string()
    .required('Teacher email is required')
    .email('Email is invalid'),
})

const programValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { programValidation }

import React, { useState } from 'react'
import { Row, Col, Button, Modal, Input, Form, message } from 'antd'
import * as Yup from 'yup'
import { makeRequest } from 'api'
import { useAuthSelector } from 'store/authSlice/authReducer'

const emailRegex = /^([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)?$/gi
const phoneNumberValidation = /^(\+[0-9]{11,12})?$/gi
const schema = Yup.object().shape({
  BusinessName: Yup.string().required('Business Name is required'),
  BusinessOwnerName: Yup.string().required('Business Owner Name is required'),
  BusinessEmail: Yup.string().matches(emailRegex, 'Invalid Email'),
  BusinessPhoneNumber: Yup.string().matches(
    phoneNumberValidation,
    'Invalid Phone number e.g +1234567891',
  ),
})

const businessAssociationValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  BusinessName: '',
  BusinessOwnerName: '',
  BusinessEmail: '',
  BusinessPhoneNumber: '',
}

const AddBusinessEntityModal = ({ isVisible, onClose, getData }) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { organization } = useAuthSelector()

  const onFinish = (values) => {
    const { BusinessEmail, BusinessPhoneNumber } = values
    if (!BusinessEmail && !BusinessPhoneNumber) {
      message.error('Business Email or Business Phone Number is required')
      return false
    }
    setLoading(true)
    const data = {
      ...values,
      OrganizationId: organization.id,
      OrganizationName: organization.Name,
      OrganizationEmail: organization.Email,
    }
    makeRequest(
      'post',
      '/organization-business/association',
      {
        organization: organization.id,
      },
      data,
    )
      .then((res) => {
        message.success(res.message || 'Added successfully')
        form.resetFields()
        setLoading(false)
        getData()
        onClose()
      })
      .catch((error) => {
        message.error(error?.response?.data?.error || 'An error occurred')
        console.log('error', error)
        setLoading(false)
      })
    return true
  }
  return (
    <Modal
      centered
      title="Add B2B Business Entity"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="Edit Donor"
        initialValues={initialValues}
        scrollToFirstError
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item
              name="BusinessName"
              label="Business Name"
              colon={false}
              rules={[businessAssociationValidation]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="BusinessOwnerName"
              label="Business Owner Name"
              colon={false}
              rules={[businessAssociationValidation]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="BusinessEmail"
              label="Business Email"
              colon={false}
              rules={[businessAssociationValidation]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="BusinessPhoneNumber"
              label="Business Phone Number"
              colon={false}
              rules={[businessAssociationValidation]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                style={{ width: '100%' }}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Add
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export { AddBusinessEntityModal }

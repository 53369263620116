import React, { useEffect, useState } from 'react'
import {
  message,
  Card,
  Table,
  Button,
  Select,
  Space,
  Modal,
  List,
  Tooltip,
  Typography,
  Spin,
} from 'antd'

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { MdOutlineSimCardDownload } from 'react-icons/md'

import moment from 'moment'
import Api from 'api'
import { useAuthSelector } from 'store/authSlice/authReducer'
import TableSkeleton from '../../../../../../../components/common/skeleton/tableSkeleton'

const {
  formatDisplayName,
  downloadCSV,
  formatDate,
  formatAmount,
  formateDateTimeToLocal,
} = require('../../../../../../../utils')

// const { Panel } = Collapse
const { Option } = Select
const { Title, Text } = Typography

const MonthlyRevenueForcast = () => {
  const history = useHistory()
  const { organization } = useAuthSelector()
  const [loading, setLoading] = useState(true)

  const [selectedMonth, setSelectedMonth] = useState(moment().format('YYYY-MM'))
  const [donationType, setDonationType] = useState('orgPledges')
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [apiDataTable, setApiDataTable] = useState({})
  const [filteredData, setFilteredData] = useState([])
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [grandTotal, setGrandTotal] = useState(0)
  const [showEventTitle, setShowEventTitle] = useState(false)

  const detailFields =
    donationType === 'orgDonations' || donationType === 'fundraiserDonations'
      ? [
          'DonorPhoneNumber',
          'remainingPayments',
          'NumberOfRecurring',
          'Frequency',
          'Amount',
          'NumberOfPayments',
        ]
      : donationType === 'eventDonations' ||
        donationType === 'campaignDonations'
      ? ['DonorName', 'DonorEmail', 'DonorPhone', 'amount']
      : donationType === 'programSplitPayment'
      ? [
          'UserName',
          'UserEmail',
          'UserPhoneNumber',
          'remainingPayments',
          'NumberOfSplit',
          'Frequency',
          'Amount',
          'NumberOfPayments',
        ]
      : donationType === 'serviceRecurringPayment'
      ? [
          'UserName',
          'UserEmail',
          'UserPhoneNumber',
          'remainingPayments',
          'NumberOfRecurring',
          'Frequency',
          'Amount',
          'NumberOfPayments',
        ]
      : [
          'DonorName',
          'DonorEmail',
          'donatedAmount',
          'DonorPhone',
          // 'ScheduleDate',
        ]

  const csvColumns =
    donationType === 'orgPledges'
      ? [
          'DonorName',
          'DonorEmail',
          'donatedAmount',
          'DonorPhone',
          'ScheduleDate',
          'PaymentStatus',
        ]
      : donationType === 'orgDonations' ||
        donationType === 'fundraiserDonations'
      ? [
          'DonorPhoneNumber',
          'remainingPayments',
          'NumberOfRecurring',
          'Frequency',
          'Amount',
          'NumberOfPayments',
        ]
      : donationType === 'eventDonations' ||
        donationType === 'campaignDonations'
      ? ['DonorName', 'DonorEmail', 'DonorPhone', 'amount']
      : donationType === 'fundraiserPledges'
      ? [
          'EventTitle',
          'DonorName',
          'DonorEmail',
          'donatedAmount',
          'DonorPhone',
          'ScheduleDate',
          'PaymentStatus',
        ]
      : donationType === 'programSplitPayment'
      ? [
          'UserName',
          'UserEmail',
          'UserPhoneNumber',
          'remainingPayments',
          'NumberOfSplit',
          'Frequency',
          'Amount',
          'NumberOfPayments',
          'NextFormatedSpitPaymentDate',
          'NextFormatedSpitPaymentTime',
        ]
      : donationType === 'serviceRecurringPayment'
      ? [
          'UserName',
          'UserEmail',
          'UserPhoneNumber',
          'remainingPayments',
          'NumberOfRecurring',
          'Frequency',
          'Amount',
          'NumberOfPayments',
          'NextFormatedRecurringPaymentDate',
          'NextFormatedRecurringPaymentTime',
        ]
      : [
          'EventTitle',
          'DonorName',
          'DonorEmail',
          'donatedAmount',
          'DonorPhone',
          'ScheduleDate',
          'PaymentStatus',
        ]
  const csvHeaderMapping =
    donationType === 'orgPledges'
      ? {
          DonorName: 'Donor Name',
          DonorEmail: 'Donor Email',
          donatedAmount: 'Pledge Amount',
          DonorPhone: 'Donor Phone',
          ScheduleDate: 'Schedule Date',
          PaymentStatus: 'Payment Status',
        }
      : donationType === 'orgDonations' ||
        donationType === 'fundraiserDonations'
      ? {
          DonorPhoneNumber: 'Donor Phone Number',
          remainingPayments: 'Remaining Payments',
          NumberOfRecurring: 'Number of Split Payment',
          Frequency: 'Frequency',
          Amount: 'Amount',
          NumberOfPayments: 'Number of Payment',
        }
      : donationType === 'eventDonations' ||
        donationType === 'campaignDonations'
      ? {
          DonorName: 'Donor Name',
          DonorEmail: 'Donor Email',
          DonorPhone: 'Donor Phone Number',
          amount: 'Amount',
        }
      : donationType === 'fundraiserPledges'
      ? {
          EventTitle: 'Fundraiser Title',
          DonorName: 'Donor Name',
          DonorEmail: 'Donor Email',
          donatedAmount: 'Pledge Amount',
          DonorPhone: 'Donor Phone',
          ScheduleDate: 'Schedule Date',
          PaymentStatus: 'Payment Status',
        }
      : donationType === 'programSplitPayment'
      ? {
          UserName: 'Donor Name',
          UserEmail: 'Donor Emai',
          UserPhoneNumber: 'Donor Phone Number',
          remainingPayments: 'Remaining Payments',
          NumberOfSplit: 'Number of Split Payment',
          Frequency: 'Frequency',
          Amount: 'Amount',
          NumberOfPayments: 'Number of Payment',
          NextFormatedSpitPaymentDate: 'Next Payment Date',
          NextFormatedSpitPaymentTime: 'Next Payment Time',
        }
      : donationType === 'serviceRecurringPayment'
      ? {
          UserName: 'Donor Name',
          UserEmail: 'Donor Emai',
          UserPhoneNumber: 'Donor Phone Number',
          remainingPayments: 'Remaining Payments',
          NumberOfRecurring: 'Number of Recurring Payment',
          Frequency: 'Frequency',
          Amount: 'Amount',
          NumberOfPayments: 'Number of Payment',
          NextFormatedRecurringPaymentDate: 'Next Payment Date',
          NextFormatedRecurringPaymentTime: 'Next Payment Time',
        }
      : {
          EventTitle: 'Event Title',
          DonorName: 'Donor Name',
          DonorEmail: 'Donor Email',
          donatedAmount: 'Pledge Amount',
          DonorPhone: 'Donor Phone',
          ScheduleDate: 'Schedule Date',
          PaymentStatus: 'Payment Status',
        }

  const formatField = (field, value) => {
    const fieldsWithDollar = [
      'donatedAmount',
      'costAmountPerMonth',
      'TotalAmount',
      'amount',
      'Amount',
    ]
    if (fieldsWithDollar.includes(field)) {
      const formattedAmount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value)
      return formattedAmount
    }
    if (field === 'ScheduleDate') {
      return formatDate(value)
    }
    return value
  }

  const handleDonationTypeChange = (value) => {
    setDonationType(value)
    setShowEventTitle(!showEventTitle)
  }

  const getData = () => {
    setGrandTotal(0)
    setLoading(true)

    Api.get(`reports/monthly-revenue-forcast/${organization.id}`)
      .then((result) => {
        setApiDataTable(result.response)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        message.error(
          error?.exceptionMessage || 'An error occured in fetching report',
        )
      })
  }

  const formatDays = (day) => {
    if (day > 1) {
      return `${Math.abs(day)} days`
    }
    return `Overdue`
  }

  const columns =
    donationType === 'orgPledges'
      ? [
          { title: 'Donor Name', dataIndex: 'DonorName', key: 'DonorName' },
          { title: 'Donor Email', dataIndex: 'DonorEmail', key: 'DonorEmail' },
          // { title: 'Event Title', dataIndex: 'EventTitle', key: 'EventTitle' },
          {
            title: 'Due In',
            dataIndex: 'dueDays',
            key: 'dueDays',
            render: (dueDays) => formatDays(dueDays),
          },
          { title: 'Donor Phone', dataIndex: 'DonorPhone', key: 'DonorPhone' },
          {
            title: 'Pledge Amount',
            dataIndex: 'donatedAmount',
            key: 'donatedAmount',
            className: 'align-right',
            align: 'center',
            render: (text) => {
              const formattedAmount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(text)
              return formattedAmount
            },
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Button onClick={() => showDetails(record)}>Details</Button>
            ),
          },
        ]
      : donationType === 'orgDonations'
      ? [
          // { title: 'Event Title', dataIndex: 'EventTitle', key: 'EventTitle' },
          {
            title: 'Donor Phone',
            dataIndex: 'DonorPhoneNumber',
            key: 'DonorPhoneNumber',
          },
          {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            className: 'align-right',
            align: 'center',
            render: (text) => {
              const formattedAmount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(text)
              return formattedAmount
            },
          },
          {
            title: 'Remaining Payments',
            dataIndex: 'remainingPayments',
            key: 'remainingPayments',
          },
          {
            title: 'No of Recurring Payment',
            dataIndex: 'NumberOfRecurring',
            key: 'NumberOfRecurring',
          },
          {
            title: 'Next Payment Date',
            dataIndex: 'NextPaymentDate',
            key: 'NextPaymentDate',
            render: (time) => formateDateTimeToLocal(time, 'datetime'),
            sorter: (a, b) => {
              const dateA = new Date(a.NextPaymentDate)
              const dateB = new Date(b.NextPaymentDate)
              return dateB - dateA
            },
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Button onClick={() => showDetails(record)}>Details</Button>
            ),
          },
        ]
      : donationType === 'fundraiserPledges'
      ? [
          { title: 'Donor Name', dataIndex: 'DonorName', key: 'DonorName' },
          { title: 'Donor Email', dataIndex: 'DonorEmail', key: 'DonorEmail' },
          {
            title: 'Fundraiser Title',
            dataIndex: 'EventTitle',
            key: 'EventTitle',
          },
          {
            title: 'Due In',
            dataIndex: 'dueDays',
            key: 'dueDays',
            render: (dueDays) => formatDays(dueDays),
          },
          { title: 'Donor Phone', dataIndex: 'DonorPhone', key: 'DonorPhone' },
          {
            title: 'Pledge Amount',
            dataIndex: 'donatedAmount',
            key: 'donatedAmount',
            className: 'align-right',
            align: 'center',
            render: (text) => {
              const formattedAmount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(text)
              return formattedAmount
            },
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Button onClick={() => showDetails(record)}>Details</Button>
            ),
          },
        ]
      : donationType === 'fundraiserDonations'
      ? [
          {
            title: 'Fundraiser Title',
            dataIndex: 'FundraiserTitle',
            key: 'FundraiserTitle',
          },
          {
            title: 'Donor Phone',
            dataIndex: 'DonorPhoneNumber',
            key: 'DonorPhoneNumber',
          },
          {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            className: 'align-right',
            align: 'center',
            render: (text) => {
              const formattedAmount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(text)
              return formattedAmount
            },
          },
          {
            title: 'Remaining Payments',
            dataIndex: 'remainingPayments',
            key: 'remainingPayments',
          },
          {
            title: 'No of Recurring Payment',
            dataIndex: 'NumberOfRecurring',
            key: 'NumberOfRecurring',
          },
          {
            title: 'Next Payment Date',
            dataIndex: 'NextPaymentDate',
            key: 'NextPaymentDate',
            render: (time) => formateDateTimeToLocal(time, 'datetime'),
            sorter: (a, b) => {
              const dateA = new Date(a.NextPaymentDate)
              const dateB = new Date(b.NextPaymentDate)
              return dateB - dateA
            },
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Button onClick={() => showDetails(record)}>Details</Button>
            ),
          },
        ]
      : donationType === 'eventPledges'
      ? [
          { title: 'Donor Name', dataIndex: 'DonorName', key: 'DonorName' },
          { title: 'Donor Email', dataIndex: 'DonorEmail', key: 'DonorEmail' },
          { title: 'Event Title', dataIndex: 'EventTitle', key: 'EventTitle' },
          {
            title: 'Due In',
            dataIndex: 'dueDays',
            key: 'dueDays',
            render: (dueDays) => formatDays(dueDays),
          },
          { title: 'Donor Phone', dataIndex: 'DonorPhone', key: 'DonorPhone' },
          {
            title: 'Pledge Amount',
            dataIndex: 'donatedAmount',
            key: 'donatedAmount',
            className: 'align-right',
            align: 'center',
            render: (text) => {
              const formattedAmount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(text)
              return formattedAmount
            },
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Button onClick={() => showDetails(record)}>Details</Button>
            ),
          },
        ]
      : donationType === 'eventDonations'
      ? [
          { title: 'Donor Name', dataIndex: 'DonorName', key: 'DonorName' },
          { title: 'Donor Email', dataIndex: 'DonorEmail', key: 'DonorEmail' },
          { title: 'Event Title', dataIndex: 'EventTitle', key: 'EventTitle' },
          {
            title: 'Due In',
            dataIndex: 'dueDays',
            key: 'dueDays',
            render: (dueDays) => formatDays(dueDays),
          },
          { title: 'Donor Phone', dataIndex: 'DonorPhone', key: 'DonorPhone' },
          {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            className: 'align-right',
            align: 'center',
            render: (text) => {
              const formattedAmount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(text)
              return formattedAmount
            },
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Button onClick={() => showDetails(record)}>Details</Button>
            ),
          },
        ]
      : donationType === 'campaignPledges'
      ? [
          { title: 'Donor Name', dataIndex: 'DonorName', key: 'DonorName' },
          { title: 'Donor Email', dataIndex: 'DonorEmail', key: 'DonorEmail' },
          { title: 'Event Title', dataIndex: 'EventTitle', key: 'EventTitle' },
          {
            title: 'Due In',
            dataIndex: 'dueDays',
            key: 'dueDays',
            render: (dueDays) => formatDays(dueDays),
          },
          { title: 'Donor Phone', dataIndex: 'DonorPhone', key: 'DonorPhone' },
          {
            title: 'Pledge Amount',
            dataIndex: 'donatedAmount',
            key: 'donatedAmount',
            className: 'align-right',
            align: 'center',
            render: (text) => {
              const formattedAmount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(text)
              return formattedAmount
            },
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Button onClick={() => showDetails(record)}>Details</Button>
            ),
          },
        ]
      : donationType === 'campaignDonations'
      ? [
          { title: 'Donor Name', dataIndex: 'DonorName', key: 'DonorName' },
          { title: 'Donor Email', dataIndex: 'DonorEmail', key: 'DonorEmail' },
          { title: 'Event Title', dataIndex: 'EventTitle', key: 'EventTitle' },
          {
            title: 'Due In',
            dataIndex: 'dueDays',
            key: 'dueDays',
            render: (dueDays) => formatDays(dueDays),
          },
          { title: 'Donor Phone', dataIndex: 'DonorPhone', key: 'DonorPhone' },
          {
            title: 'Pledge Amount',
            dataIndex: 'donatedAmount',
            key: 'donatedAmount',
            className: 'align-right',
            align: 'center',
            render: (text) => {
              const formattedAmount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(text)
              return formattedAmount
            },
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Button onClick={() => showDetails(record)}>Details</Button>
            ),
          },
        ]
      : donationType === 'programSplitPayment'
      ? [
          { title: 'Donor Name', dataIndex: 'UserName', key: 'UserName' },
          { title: 'Donor Email', dataIndex: 'UserEmail', key: 'UserEmail' },
          // { title: 'Event Title', dataIndex: 'EventTitle', key: 'EventTitle' },

          {
            title: 'Donor Phone',
            dataIndex: 'UserPhoneNumber',
            key: 'UserPhoneNumber',
          },
          {
            title: 'Amount',
            dataIndex: 'Amount',
            key: 'Amount',
            className: 'align-right',
            align: 'center',
            render: (text) => {
              const formattedAmount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(text)
              return formattedAmount
            },
          },
          {
            title: 'Remaining Payments',
            dataIndex: 'remainingPayments',
            key: 'remainingPayments',
          },
          {
            title: 'No of Split Payment',
            dataIndex: 'NumberOfSplit',
            key: 'NumberOfSplit',
          },
          {
            title: 'Next Payment Date',
            dataIndex: 'NextPaymentDate',
            key: 'NextPaymentDate',
            render: (time) => formateDateTimeToLocal(time, 'datetime'),
            sorter: (a, b) => {
              const dateA = new Date(a.NextPaymentDate)
              const dateB = new Date(b.NextPaymentDate)
              return dateB - dateA
            },
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Button onClick={() => showDetails(record)}>Details</Button>
            ),
          },
        ]
      : donationType === 'serviceRecurringPayment'
      ? [
          { title: 'Donor Name', dataIndex: 'UserName', key: 'UserName' },
          { title: 'Donor Email', dataIndex: 'UserEmail', key: 'UserEmail' },
          // { title: 'Event Title', dataIndex: 'EventTitle', key: 'EventTitle' },

          {
            title: 'Donor Phone',
            dataIndex: 'UserPhoneNumber',
            key: 'UserPhoneNumber',
          },
          {
            title: 'Amount',
            dataIndex: 'Amount',
            key: 'Amount',
            className: 'align-right',
            align: 'center',
            render: (text) => {
              const formattedAmount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(text)
              return formattedAmount
            },
          },
          {
            title: 'Remaining Payments',
            dataIndex: 'remainingPayments',
            key: 'remainingPayments',
          },
          {
            title: 'No of Recurring Payment',
            dataIndex: 'NumberOfRecurring',
            key: 'NumberOfRecurring',
          },
          {
            title: 'Next Payment Date',
            dataIndex: 'NextPaymentDate',
            key: 'NextPaymentDate',
            render: (time) => formateDateTimeToLocal(time, 'datetime'),
            sorter: (a, b) => {
              const dateA = new Date(a.NextPaymentDate)
              const dateB = new Date(b.NextPaymentDate)
              return dateB - dateA
            },
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Button onClick={() => showDetails(record)}>Details</Button>
            ),
          },
        ]
      : [
          { title: 'Donor Name', dataIndex: 'DonorName', key: 'DonorName' },
          { title: 'Donor Email', dataIndex: 'DonorEmail', key: 'DonorEmail' },
          { title: 'Event Title', dataIndex: 'EventTitle', key: 'EventTitle' },
          {
            title: 'Due In',
            dataIndex: 'dueDays',
            key: 'dueDays',
            render: (dueDays) => formatDays(dueDays),
          },
          { title: 'Donor Phone', dataIndex: 'DonorPhone', key: 'DonorPhone' },
          {
            title: 'Pledge Amount',
            dataIndex: 'donatedAmount',
            key: 'donatedAmount',
            className: 'align-right',
            align: 'center',
            render: (text) => {
              const formattedAmount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(text)
              return formattedAmount
            },
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Button onClick={() => showDetails(record)}>Details</Button>
            ),
          },
        ]

  // Handle dropdown changes
  useEffect(() => {
    setGrandTotal(0)
    setLoading(true)
    const orgPledgesData =
      apiDataTable['Org Pledges']?.[selectedMonth]?.data || []

    const orgDonationsData =
      apiDataTable['Org Donations']?.[selectedMonth]?.data || []

    const fundraiserPledgesData =
      apiDataTable['Fundraiser Pledges']?.[selectedMonth]?.data || []

    const fundraiserDonationsData =
      apiDataTable['Fundraiser Donations']?.[selectedMonth]?.data || []

    const eventPledgesData =
      apiDataTable['Event Pledges']?.[selectedMonth]?.data || []

    const eventDonationsData =
      apiDataTable['Event Donations']?.[selectedMonth]?.data || []

    const campaignPledgesData =
      apiDataTable['Campaign Pledges']?.[selectedMonth]?.data || []

    const campaignDonationsData =
      apiDataTable['Campaign Donations']?.[selectedMonth]?.data || []

    const programSplitPaymentData =
      apiDataTable['Program Split Patyment']?.[selectedMonth]?.data || []

    const serviceRecurringPaymentData =
      apiDataTable['Service Recurring Patyment']?.[selectedMonth]?.data || []

    let newData = []
    // Filter data based on the selected donation type
    if (donationType === 'orgPledges') {
      newData = orgPledgesData
    } else if (donationType === 'orgDonations') {
      newData = orgDonationsData
    } else if (donationType === 'fundraiserPledges') {
      newData = fundraiserPledgesData
    } else if (donationType === 'fundraiserDonations') {
      newData = fundraiserDonationsData
    } else if (donationType === 'eventPledges') {
      newData = eventPledgesData
    } else if (donationType === 'eventDonations') {
      newData = eventDonationsData
    } else if (donationType === 'campaignPledges') {
      newData = campaignPledgesData
    } else if (donationType === 'campaignDonations') {
      newData = campaignDonationsData
    } else if (donationType === 'programSplitPayment') {
      newData = programSplitPaymentData.map((element) => ({
        ...element,
        NextFormatedSpitPaymentDate: formateDateTimeToLocal(
          element.NextPaymentDate,
          'date',
        ),
        NextFormatedSpitPaymentTime: formateDateTimeToLocal(
          element.NextPaymentDate,
          'time',
        ),
      }))
    } else if (donationType === 'serviceRecurringPayment') {
      newData = serviceRecurringPaymentData.map((element) => ({
        ...element,
        NextFormatedRecurringPaymentDate: formateDateTimeToLocal(
          element.NextPaymentDate,
          'date',
        ),
        NextFormatedRecurringPaymentTime: formateDateTimeToLocal(
          element.NextPaymentDate,
          'time',
        ),
      }))
    }

    // Add dueDays property to each item
    newData = newData.map((item) => {
      const dueDays = item.ScheduleDate
        ? moment(item.ScheduleDate).diff(moment(), 'days')
        : undefined
      return { ...item, dueDays }
    })

    // Calculate grand total
    const total = newData.reduce((acc, item) => {
      const amount = parseFloat(item.donatedAmount || item.amount || 0)
      return acc + amount
    }, 0)

    setGrandTotal(formatAmount(total))
    setFilteredData(newData)
    setLoading(false)
    // eslint-disable-next-line
  }, [apiDataTable, selectedMonth, donationType])

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  // Show modal with details
  const showDetails = (record) => {
    setSelectedRecord(record)
    setIsModalVisible(true)
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      style={{ marginTop: '20px' }}
      title={
        <div className="t-flex t-flex-wrap t-justify-between">
          <div className="t-flex t-space-x-4">
            <IoMdArrowRoundBack
              fontSize="1.5rem"
              className="t-cursor-pointer t-min-w-[1.5rem]"
              id="back"
              onClick={() => history.push('/reports')}
            />
            <h5 className="font-semibold">Monthly Revenue Forecast</h5>
          </div>
          <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
            <Tooltip title="Download CSV">
              <MdOutlineSimCardDownload
                fontSize="1.7rem"
                id="download"
                className="t-text-secondary-100 t-cursor-pointer"
                onClick={() =>
                  downloadCSV(
                    filteredData,
                    csvColumns,
                    csvHeaderMapping,
                    'Monthly Revenue Forecast Graph',
                  )
                }
              />
            </Tooltip>
          </div>
        </div>
      }
    >
      <div>
        <Space direction="vertical">
          <Title level={4}>
            Total Forecast:{' '}
            <span style={{ marginLeft: 10 }}>
              {' '}
              {loading ? (
                <Spin />
              ) : (
                <Text type="success">
                  {grandTotal.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
              )}
            </span>
          </Title>
        </Space>
      </div>
      <div style={{ padding: 24 }}>
        <div style={{ marginBottom: 22 }}>
          <Select defaultValue={selectedMonth} onChange={setSelectedMonth}>
            {Array.from({ length: 6 }, (_, i) =>
              moment().add(i, 'months').format('YYYY-MM'),
            ).map((month) => (
              <Option key={month} value={month}>
                {moment(month).format('MMMM YYYY')}
              </Option>
            ))}
          </Select>
          <Select
            defaultValue={donationType}
            onChange={handleDonationTypeChange}
            style={{ marginLeft: 15, width: '18ll0px' }}
          >
            <Option value="orgPledges">Org Pledges</Option>
            <Option value="orgDonations">Org Donations</Option>
            <Option value="fundraiserPledges">Fundraiser Pledges</Option>
            <Option value="fundraiserDonations">Fundraiser Donations</Option>
            <Option value="eventPledges">Event Pledges</Option>
            <Option value="eventDonations">Event Donations</Option>
            <Option value="campaignPledges">Campaign Pledges</Option>
            <Option value="campaignDonations">Campaign Donations</Option>
            <Option value="programSplitPayment">Programs Split Payment</Option>
            <Option value="serviceRecurringPayment">
              Service Recurring Payment
            </Option>
          </Select>
          {/* {showCheckbox && (
                  <Checkbox
                    onChange={handleIncludePledgesChange}
                    style={{ marginLeft: 15 }}
                  >
                    Include Pledges
                  </Checkbox>
                )} */}
        </div>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          {loading ? (
            <TableSkeleton />
          ) : (
            <Table
              tableLayout="auto"
              dataSource={filteredData}
              columns={columns}
              rowKey="id"
              bordered={false}
              pagination={false}
              className="td-right"
              scroll={{
                x: 'max-content',
              }}
            />
          )}
        </Space>
        <Modal
          title="Details"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          {selectedRecord && (
            <List
              size="small"
              bordered
              dataSource={detailFields.map((field) => ({
                title: formatDisplayName(field),

                value: formatField(field, selectedRecord[field]),
              }))}
              renderItem={(item) => (
                <List.Item>
                  <strong>{item.title}:</strong> {item.value}
                </List.Item>
              )}
            />
          )}
        </Modal>
      </div>
    </Card>
  )
}

export { MonthlyRevenueForcast }

import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Skeleton, Spin, Tooltip, Badge } from 'antd'
import { CarryOutFilled } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { fetchMoreData, searchFundraisersRows } from 'utils'
import { config } from 'configs'

import sample from 'assets/images/sample/fundraiser-default-image.png'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory } from 'react-router-dom'
import {
  fundraiserActions,
  useFundraiserSelector,
} from 'store/fundraiserSlice/fundraiserReducer'

function InactiveList({ searchText }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { loading, inactiveFundraisers } = useFundraiserSelector()

  const [current, setCurrent] = useState([])

  const searchedData = searchFundraisersRows(inactiveFundraisers, searchText)

  useEffect(() => {
    setCurrent(searchedData.slice(0, config.itemFirstLoad))
    // eslint-disable-next-line
  }, [inactiveFundraisers, searchText])

  const handleSelected = (item) => {
    if (item.type === 'ClickTap') {
      history.push(`settings/create-tap-fundraiser/${item.iD}`)
    } else {
      dispatch(fundraiserActions.setJoinedFundraiserDetails(null))
      dispatch(fundraiserActions.setFundraiser(null))
      history.push(`fundraisers/${item.iD}`)
    }
  }
  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div className="t-flex t-flex-col t-justify-between t-h-full">
        <InfiniteScroll
          dataLength={current.length}
          next={() => fetchMoreData(current, setCurrent, searchedData)}
          hasMore={current.length < searchedData.length}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              <Spin />
            </div>
          }
        >
          <Row gutter={[24, 24]} className="t-h-full">
            {current.length === 0 && (
              <div
                className="t-h-96 t-flex t-justify-center t-items-center t-w-full"
                style={{ minHeight: 'calc(100vh - 300px)' }}
              >
                <div className="t-flex t-flex-col t-items-center t-space-y-2">
                  <CarryOutFilled
                    style={{ fontSize: '2rem' }}
                    className="t-text-secondary-100"
                  />
                  <p>No records found</p>
                </div>
              </div>
            )}
            {current.map((item, index) => (
              <Col span={24} md={8} xl={8} key={index}>
                <Badge.Ribbon
                  color={item.type === 'ClickTap' ? 'green' : 'blue'}
                  text={item.type}
                >
                  <Card
                    bordered
                    hoverable
                    cover={
                      <img
                        alt="example"
                        className="t-w-full t-h-48 t-object-cover t-rounded-md"
                        src={
                          item?.flyer?.length
                            ? item?.flyer[0]
                            : item?.organizationBackgroundImage
                            ? item?.organizationBackgroundImage
                            : sample
                        }
                      />
                    }
                    onClick={() => handleSelected(item)}
                  >
                    <div className="t-flex t-justify-between t-items-center">
                      <h5 className="t-text-2xl t-text-secondary-100 t-font-bold t-truncate">
                        <Tooltip title={item?.title}>{item?.title}</Tooltip>
                      </h5>
                    </div>

                    <div className="t-flex t-justify-between">
                      <div className="card-tag t-font-thin t-text-secondary-100">
                        {item?.organizationName}
                      </div>
                    </div>
                    <p className="t-line-clamp-1 t-text-sm">
                      {item?.description}
                    </p>
                  </Card>
                </Badge.Ribbon>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    </Skeleton>
  )
}

export { InactiveList }

import { makeRequest } from 'api'
import { message } from 'antd'
import { config } from 'configs'
import { platformSubscriptionActions } from './platformSubscriptionReducer'

export const getOrganizatinPlatformSubscription =
  ({ organizationId }) =>
  (dispatch) => {
    dispatch(
      platformSubscriptionActions.setGetPlatformSubscriptionLoading(true),
    )
    makeRequest(
      'get',
      `/component/platform-subscription/get-organization-platform-subscription`,
      {
        organization: organizationId,
      },
    )
      .then((res) => {
        if (res?.results) {
          dispatch(
            platformSubscriptionActions.setPlatformSubscription(res?.results),
          )
        } else {
          dispatch(platformSubscriptionActions.setPlatformSubscription(null))
        }
        dispatch(
          platformSubscriptionActions.setGetPlatformSubscriptionLoading(false),
        )
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
        dispatch(platformSubscriptionActions.setPlatformSubscription(null))
        dispatch(
          platformSubscriptionActions.setGetPlatformSubscriptionLoading(false),
        )
      })
  }

export const getOrganizatinUsageCharges =
  ({ organizationId }) =>
  (dispatch) => {
    dispatch(platformSubscriptionActions.setGetUsageCharges(true))
    makeRequest(
      'get',
      `/component/platform-subscription/get-organization-usage-charges`,
      {
        organization: organizationId,
      },
    )
      .then((res) => {
        if (res?.results) {
          dispatch(platformSubscriptionActions.setUsageCharges(res?.results))
        } else {
          dispatch(platformSubscriptionActions.setUsageCharges(null))
        }
        dispatch(platformSubscriptionActions.setGetUsageCharges(false))
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
        dispatch(platformSubscriptionActions.setPlatformSubscription(null))
        dispatch(platformSubscriptionActions.setGetUsageCharges(false))
      })
  }

export const getGlobalSubscriptionCharges =
  ({ organizationId }) =>
  (dispatch) => {
    dispatch(platformSubscriptionActions.setGetGlobalSubscriptionCharges(true))
    makeRequest(
      'get',
      `/component/platform-subscription/get-global-platform-subscription-charges`,
      {
        organization: organizationId,
      },
    )
      .then((res) => {
        if (res?.results) {
          dispatch(
            platformSubscriptionActions.setGlobalSubscriptionCharges(
              res?.results,
            ),
          )
        } else {
          dispatch(
            platformSubscriptionActions.setGlobalSubscriptionCharges(null),
          )
        }
        dispatch(
          platformSubscriptionActions.setGetGlobalSubscriptionCharges(false),
        )
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
        dispatch(platformSubscriptionActions.setGlobalSubscriptionCharges(null))
        dispatch(
          platformSubscriptionActions.setGetGlobalSubscriptionCharges(false),
        )
      })
  }

export const setupPlaformSubscription = async (payload) => {
  const response = await makeRequest(
    'post',
    `component/platform-subscription/setup-subscription`,
    {},
    payload,
  )
  return response
}

export const updatePlaformSubscriptionCard = async (
  organizationId,
  cardToken,
) => {
  const response = await makeRequest(
    'post',
    `component/platform-subscription/update-subscription-card`,
    {
      organization: organizationId,
    },
    { cardToken },
  )
  return response
}

export const cancelPlaformSubscription =
  ({ organizationId }) =>
  (dispatch) => {
    dispatch(
      platformSubscriptionActions.setCancellingPlatformSubscriptionLoading(
        true,
      ),
    )
    makeRequest('get', `component/platform-subscription/cancel-subscription`, {
      organization: organizationId,
    })
      .then((res) => {
        if (res?.message) {
          message.success(res?.message || 'Success')
        }
        dispatch(getOrganizatinPlatformSubscription({ organizationId }))
        dispatch(
          platformSubscriptionActions.setCancellingPlatformSubscriptionLoading(
            false,
          ),
        )
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.error || config.defaultErrorMessage,
        )
        dispatch(
          platformSubscriptionActions.setCancellingPlatformSubscriptionLoading(
            false,
          ),
        )
      })
  }

import React, { useReducer, useState } from 'react'
import { message, Spin, Tooltip, Upload } from 'antd'
import Api from 'api'
import { useAuthSelector } from 'store/authSlice/authReducer'
import {
  MdOutlineSimCardDownload,
  MdOutlineUploadFile,
  MdPersonAddAlt,
} from 'react-icons/md'
import { UploadStatisticModal } from './uploadStatisticModal'
import { AddBusinessEntityModal } from './AddBusinessEntityModal'

const initialState = {
  get: false,
  download: false,
  downloadDonorList: false,
  upload: false,
  custom: false,
  list: false,
}
// The reducer function
function reducer(state, action) {
  switch (action) {
    case 'get':
      return {
        ...state,
        get: !state.get,
      }
    case 'download':
      return {
        ...state,
        download: !state.download,
      }
    case 'downloadDonorList':
      return {
        ...state,
        downloadDonorList: !state.downloadDonorList,
      }
    case 'upload':
      return {
        ...state,
        upload: !state.upload,
      }
    case 'custom':
      return {
        ...state,
        custom: !state.custom,
      }
    case 'list':
      return {
        ...state,
        list: !state.list,
      }
    default:
      return state
  }
}

const BusinessInvitationList = ({ getAssociation }) => {
  const { organization } = useAuthSelector()
  const [loading, setLoading] = useReducer(reducer, initialState)
  const [statisticModal, setStatisticModal] = useState(false)
  const [statisticData, setStatisticData] = useState()
  const [addShowModal, setAddShowModal] = useState(false)
  const downloadFile = (data, type, filename) => {
    const blob = new Blob([data], { type: type.toString() })
    const blobURL = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.download = filename
    anchor.href = blobURL
    anchor.click()
  }

  const getFile = () => {
    setLoading('download')
    Api.get('component/organization/download-business-invitation-sample-file')
      .then((res) => {
        downloadFile(
          res,
          'application/ms-excel',
          'Sample-BusinessInvitationList.csv',
        )
        setLoading('download')
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || 'Something went wrong')
        setLoading('download')
      })
  }

  const onFileUpload = (file) => {
    if (file.size < 2 * 1048576) {
      onFileSumbit(file)
    } else {
      message.error('File size should be less than 20 MB to upload')
    }
  }

  const onFileSumbit = (file) => {
    if (
      organization &&
      organization.id &&
      organization.Name &&
      organization.Email
    ) {
      const data = new FormData()
      data.append('uploadfile', file)
      data.append('OrganizationId', organization.id)
      data.append('OrganizationName', organization.Name)
      data.append('OrganizationEmail', organization.Email)
      setLoading('upload')
      Api.put('component/organization/upload-business-invitation-list', data)
        .then((res) => {
          message.success(res.message)
          setLoading('upload')
          setStatisticData(res?.statusData)
          setStatisticModal(true)
          // getBusinessInvitaionList(org.id)
          getAssociation()
        })
        .catch((error) => {
          console.log('error', error)
          message.error(
            error?.response?.data?.message ||
              'File cannot be processed due to an error',
          )
          setLoading('upload')
        })
    } else {
      message.error('Cound not get Organization data')
    }
  }

  return (
    <>
      <div className="t-flex t-justify-end t-items-center t-space-x-2">
        <Tooltip title="Add New B2B Business Entity">
          <MdPersonAddAlt
            fontSize="1.7rem"
            className="t-text-secondary-100 t-cursor-pointer"
            onClick={() => setAddShowModal(true)}
          />
        </Tooltip>
        <Tooltip title="Download Business Invitation Template">
          {loading.download ? (
            <Spin />
          ) : (
            <MdOutlineSimCardDownload
              fontSize="1.7rem"
              id="download"
              className="t-text-secondary-100 t-cursor-pointer"
              onClick={() => getFile()}
            />
          )}
        </Tooltip>
        <Upload
          id="upload"
          customRequest={(e) => onFileUpload(e.file)}
          maxCount={1}
          showUploadList={false}
          accept=".csv"
        >
          <Tooltip title="Upload Business Invitation List">
            {loading.upload ? (
              <Spin />
            ) : (
              <MdOutlineUploadFile
                fontSize="1.7rem"
                className="t-text-secondary-100 t-cursor-pointer t-mt-1"
              />
            )}
          </Tooltip>
        </Upload>
      </div>
      <UploadStatisticModal
        isVisible={statisticModal}
        onClose={() => {
          setStatisticModal(false)
        }}
        statisticData={statisticData}
        downloadFile={downloadFile}
      />
      {addShowModal && (
        <AddBusinessEntityModal
          isVisible={addShowModal}
          onClose={() => setAddShowModal(false)}
          getData={() => getAssociation()}
        />
      )}
    </>
  )
}

export { BusinessInvitationList }

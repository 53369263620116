/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Card, Button, Skeleton } from 'antd'
import { ArrowRightOutlined, CarryOutFilled } from '@ant-design/icons'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { GrAchievement } from 'react-icons/gr'
import { MdPendingActions, MdCancelPresentation } from 'react-icons/md'
import Api from 'api'
import { formatAmount } from 'utils'
import { ShareReport } from 'components/elements'
import { useHistory } from 'react-router-dom'

const PledgeConversionReports = () => {
  const history = useHistory()
  const [selected, setSelected] = useState()
  const [pdfData, setPdfData] = useState()

  const options = [
    'FY Pledge Conversions',
    'Last Year Pledge Conversions',
    'Historic Trending - (With Comparison of Last Year)',
  ]

  return (
    <Card
      id="PledgeConversionReports"
      className="mb-24 header-solid list-settings-sessions"
      bordered={false}
      title={[
        <React.Fragment key={0}>
          {selected === undefined ? (
            <div className="t-flex t-space-x-4">
              <IoMdArrowRoundBack
                fontSize="1.5rem"
                className="t-cursor-pointer t-min-w-[1.5rem]"
                id="back"
                onClick={() =>
                  history.push('/settings/manage-organization/my-reports')
                }
              />
              <h5 className="font-semibold">Pledge Conversion Reports</h5>
            </div>
          ) : (
            <div className="t-flex t-justify-between t-items-center">
              <div className="t-flex t-space-x-4">
                <IoMdArrowRoundBack
                  fontSize="1.5rem"
                  id="back"
                  className="t-cursor-pointer t-min-w-[1.5rem]"
                  onClick={() => {
                    setPdfData()
                    setSelected()
                  }}
                />
                <h5 className="font-semibold t-whitespace-normal">
                  {options[selected]}
                </h5>
              </div>
              {pdfData != null && (
                <ShareReport
                  reportName={
                    selected === 0
                      ? 'FY Pledge Conversions Report'
                      : selected === 1
                      ? 'Last Years Pledge Conversions Report'
                      : 'Pledge Conversions Historic Trending'
                  }
                  isSummary
                  report={pdfData}
                />
              )}
            </div>
          )}
        </React.Fragment>,
      ]}
    >
      {selected === undefined ? (
        <>
          {options.map((item, index) => (
            <>
              {index !== 0 && <hr className="gradient-line my-10" />}
              <div className="t-flex t-justify-between t-items-center">
                <div className="t-flex t-items-center t-space-x-2">
                  <div className="" style={{ minWidth: '40px' }}>
                    <CarryOutFilled
                      className="text-gray-6"
                      style={{ fontSize: '18px' }}
                    />
                  </div>
                  <p className="mb-0">{item}</p>
                </div>
                <div className="">
                  <Button
                    type="link"
                    id={`pledge_conversion_${index}_see_details`}
                    onClick={() => {
                      setPdfData()
                      setSelected(index)
                    }}
                  >
                    See Details <ArrowRightOutlined />
                  </Button>
                </div>
              </div>
            </>
          ))}
        </>
      ) : (
        <Single selected={selected} setPdfData={setPdfData} />
      )}
    </Card>
  )
}

function Single({ selected, setPdfData }) {
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()

  const getPledgeConversion = (type) => {
    Api.put('adminReports/pledgeConversion', { type })
      .then((res) => {
        setReport(res.results)
        setPdfData(res.results)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
  }

  const getFYPledgeConversions = () => {
    Api.get('adminReports/pledgeConversionHistory')
      .then((res) => {
        setReport(res.results)
        setPdfData(res.results)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    if (selected === 2) {
      getFYPledgeConversions()
    } else {
      if (selected === 0) {
        getPledgeConversion('current')
      }
      if (selected === 1) {
        getPledgeConversion('last')
      }
    }

    // eslint-disable-next-line
  }, [])

  const getTrend = (fy, ly) => {
    if (fy < ly) {
      return <p className="text-error">Downward</p>
    }
    if (fy > ly) {
      return <p className="text-success">Upward</p>
    }
    return <p className="text-warning">No Change</p>
  }

  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      {report ? (
        <div className="t-space-y-4">
          <div className="t-flex t-items-center t-justify-between">
            <div className="t-flex t-items-center t-space-x-2">
              <GrAchievement
                fontSize="1.2rem"
                className="t-text-secondary-100"
              />
              <p>Pledges Converted To Donations</p>
            </div>
            {selected === 2
              ? getTrend(
                  report?.convertToDonationAmountFy,
                  report?.convertToDonationAmountLy,
                )
              : `${formatAmount(report.convertToDonationAmount)} (${
                  report.convertToDonationPercentage
                })`}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <div className="t-flex t-items-center t-space-x-2">
              <MdPendingActions
                fontSize="1.5rem"
                className="t-text-secondary-100"
              />
              <p>Pledges Delayed</p>
            </div>
            {selected === 2
              ? getTrend(
                  report?.pledgesDelayedAmountFy,
                  report?.pledgesDelayedAmountLy,
                )
              : `${formatAmount(report.pledgesDelayedAmount)} (${
                  report.delayedPercentage
                })`}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <div className="t-flex t-items-center t-space-x-2">
              <MdCancelPresentation
                fontSize="1.2rem"
                className="t-text-secondary-100"
              />
              <p>Pledges Canceled</p>
            </div>
            {selected === 2
              ? getTrend(report?.cancelPledgesFy, report?.cancelPledgesLy)
              : `${formatAmount(report.cancelPledges)} (${
                  report.cancelPercentage
                })`}
          </div>
        </div>
      ) : (
        <div>No Data Found</div>
      )}
    </Skeleton>
  )
}

export { PledgeConversionReports }

import React, { useEffect, useRef, useState } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import { Col, Form, Input, Select } from 'antd'
import { awsconfig } from 'configs'
import { REGEXS } from 'utils'
import Countries from 'utils/countries.json'
import * as Yup from 'yup'

const zipCodeRegex = REGEXS.zipcodeRegex

const schema = Yup.object().shape({
  mailingAddress: Yup.string().required('Steet Address is required'),
  suite: Yup.string(),
  zipCode: Yup.string()
    .matches(zipCodeRegex, 'Invalid Zipcode e.g 12345')
    .required('Zipcode is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  country: Yup.string().required('Country is required'),
})

const adddressValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const AutoCompleteAddress = ({
  form,
  validation = false,
  isInitiallyValidated = false,
  nonEditable = false,
}) => {
  const disabled = true
  const [isValidated, setIsValidated] = useState(isInitiallyValidated)
  const antInputRef = useRef(null)

  const clearAddress = () => {
    setIsValidated(false)
    form.setFieldValue('zipCode', '')
    form.setFieldValue('city', '')
    form.setFieldValue('state', '')
    form.setFieldValue('country', '')
  }

  const formatAddress = (address) => {
    const addressInfo = {}

    address.map((component) => {
      if (component.types.includes('country')) {
        addressInfo.country = component.short_name
      } else if (component.types.includes('locality')) {
        addressInfo.city = component.long_name
      } else if (component.types.includes('administrative_area_level_1')) {
        addressInfo.state = component.long_name
      } else if (component.types.includes('street_address')) {
        addressInfo.suite = component.long_name
      } else if (component.types.includes('postal_code')) {
        addressInfo.zipCode = component.long_name
      }
      return null // Returning null as map requires a return value for each iteration
    })
    return addressInfo
  }

  const { ref: antRef } = usePlacesWidget({
    apiKey: awsconfig.google_api_key,
    onPlaceSelected: (place) => {
      setIsValidated(true)
      form.setFieldValue('searchAddress', place?.formatted_address)
      form.setFieldValue('mailingAddress', place?.formatted_address)
      const address = formatAddress(place?.address_components)
      form.setFieldsValue(address)
    },
    options: {
      componentRestrictions: { country: ['us', 'ca'] },
      fields: ['address_components', 'formatted_address', 'geometry'],
      types: ['address'],
    },
  })

  // To hanlde auto sumbiting of form on place selection
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault() // Prevent the default Enter key behavior
      }
    }

    if (antRef.current) {
      antRef.current.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      if (antRef.current) {
        antRef.current.removeEventListener('keydown', handleKeyDown)
      }
    }
    // eslint-disable-next-line
  }, [])

  const conditionalStar = validation ? '*' : ''
  return (
    <>
      <Col xl={24} md={24} xs={24} sm={24}>
        <Form.Item
          name="mailingAddress"
          label={validation ? 'Street Address*' : 'Mailing Address'}
          colon={false}
          rules={validation ? [adddressValidation] : null}
        >
          <Input
            placeholder="Search Address"
            ref={(c) => {
              antInputRef.current = c
              if (c) antRef.current = c.input
            }}
            onFocus={() => {
              if (isValidated) {
                clearAddress()
              }
            }}
          />
        </Form.Item>
      </Col>
      <Col xl={8} md={8} xs={24} sm={24}>
        <Form.Item
          name="suite"
          label="APT / Suite #"
          colon={false}
          rules={validation ? [adddressValidation] : null}
        >
          <Input placeholder="Enter Address" disabled={nonEditable} />
        </Form.Item>
      </Col>
      <Col xl={8} md={8} xs={24} sm={24}>
        <Form.Item
          name="zipCode"
          label={`Zip${conditionalStar}`}
          colon={false}
          rules={validation ? [adddressValidation] : null}
        >
          <Input placeholder="Enter Zip" disabled={disabled} />
        </Form.Item>
      </Col>
      <Col xl={8} md={8} xs={24} sm={24}>
        <Form.Item
          name="city"
          label={`City${conditionalStar}`}
          colon={false}
          rules={validation ? [adddressValidation] : null}
        >
          <Input placeholder="Enter City Name" disabled={disabled} />
        </Form.Item>
      </Col>
      <Col xl={8} md={8} xs={24} sm={24}>
        <Form.Item
          name="state"
          label={`State${conditionalStar}`}
          colon={false}
          rules={validation ? [adddressValidation] : null}
        >
          <Input placeholder="Enter State Name" disabled={disabled} />
        </Form.Item>
      </Col>
      <Col xl={8} md={8} xs={24} sm={24}>
        <Form.Item
          name="country"
          label={`Country${conditionalStar}`}
          colon={false}
          rules={validation ? [adddressValidation] : null}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select a country"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={disabled}
          >
            {Countries.map((country) => (
              <Select.Option key={country.code} value={country.code}>
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  )
}

export { AutoCompleteAddress }

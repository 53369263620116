export const FIRST_PANEL_STYLES = {
  borderRadius: '12px 12px 0 0',
}

export const LAST_PANEL_STYLES = {
  borderRadius: '0 0 12px 12px',
}

export const CHILD_PANEL_STYLES = {
  borderRadius: 0,
}

import * as Yup from 'yup'

const schema = Yup.object().shape({
  SMSCost: Yup.string().required('SMS Cost is required'),
  FreeSMSQuota: Yup.string().required('Free SMS Quota is required'),
  EmailCost: Yup.string().required('Email Cost is required'),
  FreeEmailQuota: Yup.string().required('Free Email Quota is required'),
  PaymentMarkup: Yup.string().required('Payment Markup is required'),
  PlatformSubscriptionCharges: Yup.string().required(
    'Platform Subscription Charges is required',
  ),
  KioskMonthlyDues: Yup.string().required('Kiosk Monthly Dues is required'),
})

const organizationMarkupValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { organizationMarkupValidation }

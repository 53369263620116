import Api from 'api'

export const LinkPaypalAccount = async () => {
  try {
    const response = await Api.get(`/payments/paypal/connect`)
    const result = response?.result?.links[1]?.href || null
    return result
  } catch (error) {
    console.log('error', error)
    return null
  }
}

export const LinkPaymentCategoryPaypalAccount = async (add, id) => {
  try {
    const data = { addCategory: add, id }
    const response = await Api.put(
      `/payments/paypal/payment-category-connect`,
      data,
    )
    const result = response?.result?.links[1]?.href || null
    return result
  } catch (error) {
    console.log('error', error)
    return null
  }
}

export const LinkPaymentCategoryStripeAccount = async (add, id) => {
  const data = { addCategory: add, id }
  const response = await Api.put(
    `/payments/stripe/payment-category-connect`,
    data,
  )
  return response
}

export const UnlinkPaypalAccount = async (id) => {
  const data = { id }
  const response = await Api.put('/payments/paypal/delete-id', data)
  return response
}

export const UnlinkPaymentCategoryPaypalAccount = async (id) => {
  const data = { id }
  const response = await Api.put(
    '/payments/paypal/payment-category-delete-id',
    data,
  )
  return response
}

export const UnlinkStripeAccount = async (id, email) => {
  const data = {
    CreatedBy: email,
    Id: id,
  }
  const response = await Api.put('/payments/stripe/delete-id', data)
  return response
}

export const UnlinkPaymentCategoryStripeAccount = async (id) => {
  const data = {
    Id: id,
  }
  const response = await Api.put(
    '/payments/stripe/payment-category-delete-id',
    data,
  )
  return response
}

export const savePaypalID = async (data) => {
  const response = await Api.put('/connect/paypal/save-id', data)
  return response
}

export const savePaymentCategoryPaypalID = async (paypalId, Id) => {
  const data = {
    paypalID: paypalId,
    Id,
  }
  const response = await Api.put(
    '/payments/paypal/payment-category-save-id',
    data,
  )
  return response
}

export const saveStripeID = async (data) => {
  const response = await Api.put('/connect/stripe/save-id', data)
  return response
}

export const savePaymentCategoryStripeID = async (stripeId, Id) => {
  const data = {
    stripeID: stripeId,
    Id,
  }
  const response = await Api.put(
    '/payments/stripe/payment-category-save-id',
    data,
  )
  return response
}

export const donateByStripe = async (data) => {
  // we are not using this now and this endpoint was removed from backend
  const response = await Api.post('/payments/stripe/donate', data)
  return response
}

export const seedDonateByStripe = async (data) => {
  // we are not using this now and this endpoint was removed from backend
  const response = await Api.post('/payments/stripe/seed-donate', data)
  return response
}

export const generateBillingAggrementApprovalLink = async (merchantId) => {
  const response = await Api.post(
    `/connect/paypal/billing-agreement/approve-link`,
    { merchantId },
  )
  return response
}

export const createBillingAggrementAndPay = async (data) => {
  const response = await Api.post(`/connect/paypal/billing-aggrement`, data)
  return response
}

export const payWithBillingAggrement = async (data) => {
  const response = await Api.post(`/donation/paypal/recurring`, data)
  return response
}

export const registerProgramByStripe = async (data) => {
  // we are not using this now and this endpoint was removed from backend
  const response = await Api.post('/payments/stripe/registerProgram', data)
  return response
}

export const getUserCards = async (user) => {
  const response = await Api.get(`/payments/stripe/cards?email=${user.email}`)
  return response
}

export const deleteUserCard = async (user, cardId) => {
  const response = await Api.delete(
    `/payments/stripe/card?email=${user.email}&cardId=${cardId}`,
  )
  return response
}

export const deleteUserBank = async (stripeAccount, bankId) => {
  const response = await Api.delete(
    `/connect/stripe/delete-bank-account?stripeAccount=${stripeAccount}&bankId=${bankId}`,
  )
  return response
}

export const getUserBanks = async (user, stripeAccount) => {
  const response = await Api.post(`/connect/stripe/get-customer-banks`, {
    email: user?.email,
    phone: user?.phone,
    stripeAccount,
  })
  return response
}

export const getPlaidToken = async (data) => {
  const response = await Api.post('/connect/stripe/get-plaid-token', data)
  return response
}

export const getExchangeToken = async (payload) => {
  const response = await Api.post(
    '/connect/stripe/exchange-plaid-token',
    payload,
  )
  return response
}

export const getBankAccount = async (payload) => {
  const response = await Api.post('/connect/stripe/add-bank-account', payload)
  return response
}

export const addCashDonation = async (data) => {
  // const response = await Api.post('/events/cashDonate', data)
  const response = await Api.post(`/event/donate-from-cash`, data)
  return response
}

export const addPaypalDonation = async (data) => {
  // This route is not used anymore and deleted from backend
  const response = await Api.post('/payments/paypal/donate', data)
  return response
}

export const subscriberMarkupInfo = async (orgId) => {
  const response = await Api.get(`/admin/subsrciberMarkUpsByOrg/${orgId}`)
  return response
}

export const payFromSeedDonate = async (data) => {
  const response = await Api.post(`/event/donate-from-seed`, data)
  // const response = await Api.put(`/events/donate-from-seed`, data)
  return response
}

export const getPaymentCategoryStripeAccountDetails = async (stripeId) => {
  const response = await Api.get(
    `/payments/stripe/payment-category-account?stripeId=${stripeId}`,
  )
  return response
}

export const savePaypalPaymentLog = async (payload) => {
  const response = await Api.post(`/payments/paypal/addLog`, payload)
  return response
}

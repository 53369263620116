import React, { useEffect, useState } from 'react'
import {
  Input,
  Button,
  Card,
  Form,
  Row,
  Col,
  DatePicker,
  Switch,
  message,
  Skeleton,
} from 'antd'
import { volunteerValidation } from 'validations'
import { useVolunteerSelector } from 'store/volunteerSlice/volunteerReducer'
import { useDispatch } from 'react-redux'
import {
  addVolunteer,
  getVolunteerDetail,
  updateVolunteer,
} from 'store/volunteerSlice/volunteerActions'
import { useHistory, useParams } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import moment from 'moment'
import { compareDateTimeWithNow, disabledTime } from 'utils'
import { ImageUploader } from 'components/elements'
import { AutoCompleteAddress } from 'components/common'
const { TextArea } = Input

const initialValues = {
  Title: '',
  Description: '',
  Schedule: '',
  AllowedRegistration: '',
  Flyer: null,
  ShowOnPage: false,
}

const CreateVolunteer = () => {
  const { organization } = useAuthSelector()
  const { loading } = useVolunteerSelector()
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const { id } = useParams()
  const [isLoading, setLoading] = useState(false)
  const [sameAsOrg, setSameAsOrg] = useState(true)

  Form.useWatch('Flyer', form)
  Form.useWatch('Schedule', form)
  Form.useWatch('ShowOnPage', form)

  const handleOrganizationAddresss = () => {
    form.setFieldsValue({
      mailingAddress: organization.Address1 && organization.Address1,
    })
    form.setFieldsValue({
      suite: `${organization.Address2}`,
    })
    form.setFieldsValue({ zipCode: organization.ZipCode })
    form.setFieldsValue({ city: organization.City })
    form.setFieldsValue({ state: organization.State })
    form.setFieldsValue({ country: organization.Country })
  }

  useEffect(() => {
    if (organization && organization?.ZipCode) {
      handleOrganizationAddresss()
    }
    // eslint-disable-next-line
  }, [organization, id])

  const actions = () => {
    setSameAsOrg(false)
  }

  useEffect(() => {
    if (id) {
      dispatch(getVolunteerDetail(id, form, actions))
    }
    // eslint-disable-next-line
  }, [dispatch, id])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (Object.entries(params).length > 0 && params.duplicate && params.id) {
      dispatch(getVolunteerDetail(params.id, form, actions))
      history.replace('/settings/create-volunteer')
    }
    // eslint-disable-next-line
  }, [])

  const resetForm = () => {
    form.resetFields()
  }

  const navigation = () => {
    resetForm()
    history.push('/volunteer')
  }

  const onFinish = (values) => {
    const payload = {
      Title: values.Title,
      Schedule: values.Schedule,
      AllowedRegistration: values.AllowedRegistration,
      Address1: values.mailingAddress,
      Address2: values.suite,
      ZipCode: values.zipCode,
      City: values.city,
      LocationState: values.state,
      Country: values.country,
      Address: values.suite,
      Description: values.Description,
      ShowOnPage: values.ShowOnPage,
      Flyer: values.Flyer,
    }

    // eslint-disable-next-line
    if (parseInt(payload.AllowedRegistration) >= 1) {
      if (id) {
        dispatch(
          updateVolunteer(
            { id, ...payload, OrganizationId: organization.id },
            setLoading,
            navigation,
          ),
        )
      } else {
        dispatch(
          addVolunteer(
            { ...payload, OrganizationId: organization.id },
            setLoading,
            navigation,
          ),
        )
      }
    } else {
      message.error('Maximum registrations must not be zero')
    }
  }

  const handleInputChange = (value) => {
    const val = value.replace(/\D/g, '')
    form.setFieldsValue({ AllowedRegistration: val })
  }

  const handleSameAsOrgChange = () => {
    if (sameAsOrg) {
      form.setFieldsValue({ mailingAddress: '' })
      form.setFieldsValue({ suite: '' })
      form.setFieldsValue({ zipCode: '' })
      form.setFieldsValue({ city: '' })
      form.setFieldsValue({ state: '' })
      form.setFieldsValue({ country: '' })
    } else {
      handleOrganizationAddresss()
    }
    setSameAsOrg(!sameAsOrg)
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0">{id ? 'Update Volunteer' : 'Add Volunteer'}</h6>
          <p className="font-regular">Enter Information</p>
        </>,
      ]}
    >
      <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
        <div style={{ minHeight: 'calc(100vh - 220px)' }}>
          <Form
            form={form}
            layout="vertical"
            key={0}
            onFinish={onFinish}
            name="organization"
            initialValues={initialValues}
            scrollToFirstError
          >
            <Row gutter={[24, 0]}>
              <Col xl={24} md={24} xs={24} sm={24}>
                <Form.Item
                  name="Title"
                  label="Title"
                  colon={false}
                  rules={[volunteerValidation]}
                >
                  <Input placeholder="Enter Title" />
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="Schedule"
                  label="Schedule"
                  colon={false}
                  rules={[volunteerValidation]}
                >
                  <DatePicker
                    format="MM-DD-YYYY hh:mm A"
                    showTime
                    minuteStep={15}
                    className="t-w-full"
                    disabledDate={(d) =>
                      !d || d.isBefore(moment().subtract(1, 'day'))
                    }
                    disabledTime={disabledTime}
                    onChange={(selectedDate) =>
                      !compareDateTimeWithNow(selectedDate, 'before') &&
                      form.setFieldsValue({
                        Schedule: moment(),
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="AllowedRegistration"
                  label="Maximum # of Registrations"
                  colon={false}
                  rules={[volunteerValidation]}
                >
                  <Input
                    inputMode="numeric"
                    placeholder="Max. # of Enrollments"
                    // type="number"
                    onChange={(e) => handleInputChange(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Same as my org address">
                  <Switch
                    checked={sameAsOrg}
                    onChange={() => handleSameAsOrgChange()}
                  />
                </Form.Item>
              </Col>

              <AutoCompleteAddress
                form={form}
                validation
                nonEditable={sameAsOrg}
                isInitiallyValidated={id}
              />

              <Col xl={24} md={24} xs={24} sm={24}>
                <Form.Item
                  name="Description"
                  label="Description"
                  colon={false}
                  rules={[volunteerValidation]}
                >
                  <TextArea
                    placeholder="Enter Description"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="ShowOnPage"
                  label="Show on Landing Page"
                  colon={false}
                  rules={[volunteerValidation]}
                >
                  <Switch checked={form.getFieldValue('ShowOnPage')} />
                </Form.Item>
              </Col>

              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item name="Flyer" label="Banner Image" colon={false}>
                  <ImageUploader
                    ratio={2 / 1}
                    defaultFile={
                      form.getFieldValue('Flyer') && form.getFieldValue('Flyer')
                    }
                    onFileChange={(file) =>
                      form.setFieldsValue({
                        Flyer: file?.originFileObj,
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={24} className="text-left t-space-x-2">
                <Button
                  loading={isLoading}
                  type="primary"
                  className="px-25"
                  htmlType="submit"
                >
                  {id ? 'Update Volunteer Opp' : 'Add Volunteer Opp'}
                </Button>
                <Button
                  type="primary"
                  className="px-25"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Skeleton>
    </Card>
  )
}

export { CreateVolunteer }

import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Rate,
  Row,
  Skeleton,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { feedbackValidation } from 'validations'
import Api from 'api'
import { useAuthSelector } from 'store/authSlice/authReducer'

const BusinessRateForm = ({
  isModalVisible,
  setIsModalVisible,
  businessEmail,
  submit,
}) => {
  const [messageForm] = Form.useForm()
  const [loading, setLoading] = useState(true)
  const { user } = useAuthSelector()

  const getMyRating = async () => {
    Api.post(`business/get-my-rating`, { userId: user?.id, businessEmail })
      .then((res) => {
        const ratingObj = res?.results
        if (ratingObj) {
          messageForm.setFieldsValue({
            Rating: ratingObj.Rating,
            RatingComment: ratingObj.Comment,
          })
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        message.error(error?.message)
        setLoading(false)
      })
  }
  useEffect(() => {
    if (businessEmail) {
      getMyRating()
    }
    // eslint-disable-next-line
  }, [businessEmail])

  const onFinish = (values) => {
    messageForm.resetFields()
    submit(values)
  }

  return (
    <Modal
      title="Business Rating Form"
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={null}
    >
      <Skeleton loading={loading} active paragraph={{ rows: 6 }}>
        <Form
          form={messageForm}
          layout="vertical"
          onFinish={onFinish}
          name="businessRating"
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Form.Item
                name="Rating"
                label="Rating:"
                colon={false}
                // rules={[feedbackValidation]}
              >
                <Rate defaultValue={3} />
              </Form.Item>
              <Form.Item
                name="RatingComment"
                label="Comment:"
                colon={false}
                rules={[feedbackValidation]}
              >
                <Input placeholder="Enter your comment" />
              </Form.Item>
            </Col>
            <Divider />
            <Col span={24} className="text-right">
              <Button type="primary" className="px-25" htmlType="submit">
                Send
              </Button>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </Modal>
  )
}

export { BusinessRateForm }

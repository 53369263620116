import React, { useEffect, useState } from 'react'
import { Button, Input, Skeleton, Table, Tooltip, message, Form } from 'antd'
import Api from 'api'
import { useAuthSelector } from 'store/authSlice/authReducer'
import {
  CloseCircleOutlined,
  EditOutlined,
  SendOutlined,
} from '@ant-design/icons'
import { DefaultStripeButton } from './components/DefaultStripeButton'
import { CatgegoryStripeButton } from './components/CatgegoryStripeButton'
import { DefaultPaypalButton } from './components/DefaultPaypalButton'
import { CategoryPaypalButton } from './components/CategoryPaypalButton'
import { ActionButton } from './components/ActionButton'

const PaymentCategories = () => {
  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const { loading: paymentLoading, organization } = useAuthSelector()
  const [editingIndex, setEditingIndex] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [form] = Form.useForm()

  const getData = () => {
    Api.get(
      `paymentCategories/getOrganizationPaymentCategories/${organization?.id}`,
    )
      .then((res) => {
        const fetchedCategories = res.results
        setCategories(fetchedCategories)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        message.error('An Error Occurred in fetching list')
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          id: editingIndex,
          name: values.Name,
        }
        Api.put('paymentCategories/updatePaymentCategoriesName', data)
          .then(() => {
            message.success('Item Status Changed')
            setInputValue('')
            setEditingIndex(null)
            getData()
          })
          .catch((error) => {
            console.log('error', error)
            message.error(
              error?.response?.data?.message || 'Something went wrong',
            )
          })
      })
      .catch((errorInfo) => {
        console.log('Validation Failed:', errorInfo)
      })
  }

  const handleEdit = (index, name) => {
    setEditingIndex(index)
    setInputValue(name)
    form.setFieldsValue({ Name: name })
  }

  const columns = [
    {
      title: 'Payment Category',
      dataIndex: 'Name',
      className: 'ant-table-row-cell-break-word',
      width: '35%',
      render: (text, record) => {
        const index = record?.Id
        if (editingIndex === index) {
          return (
            <Form form={form} component={false}>
              <Form.Item
                name="Name"
                rules={[
                  { required: true, message: 'Category name is required' },
                  {
                    max: 12,
                    message: 'Category name should be at most 12 characters',
                  },
                ]}
              >
                <Input
                  value={inputValue}
                  suffix={
                    <div className="t-flex t-space-x-1">
                      <Tooltip title="Close">
                        <CloseCircleOutlined
                          className="t-cursor-pointer"
                          onClick={() => {
                            setEditingIndex(null)
                            setInputValue('')
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Update">
                        <SendOutlined
                          className="t-cursor-pointer"
                          onClick={() => handleSave()}
                        />
                      </Tooltip>
                    </div>
                  }
                  onChange={(e) => setInputValue(e.target.value)}
                />
              </Form.Item>
            </Form>
          )
        }

        return (
          <div className="t-flex t-items-center">
            {text}
            {record?.Id && (
              <Button type="link" onClick={() => handleEdit(index, text)}>
                <EditOutlined style={{ fontSize: '1.2rem' }} />
              </Button>
            )}
          </div>
        )
      },
    },
    {
      title: 'Stripe',
      dataIndex: 'StripeId',
      className: 'ant-table-row-cell-break-word',
      width: '25%',
      render: (
        _,
        {
          DefaultCategory,
          OrganizationId,
          Id,
          Name,
          StripeId,
          StripeOnBoarded,
        },
      ) => (
        <>
          {DefaultCategory ? (
            <DefaultStripeButton
              refreshPayment={getData}
              OrganizationId={OrganizationId}
              Id={Id}
              Name={Name}
              Type="Stripe"
              StripeId={StripeId}
              StripeOnBoarded={StripeOnBoarded}
            />
          ) : (
            <CatgegoryStripeButton
              refreshPayment={getData}
              OrganizationId={OrganizationId}
              Id={Id}
              Name={Name}
              Type="Stripe"
              StripeId={StripeId}
              StripeOnBoarded={StripeOnBoarded}
            />
          )}
        </>
      ),
    },
    {
      title: 'Paypal',
      dataIndex: 'PaypalId',
      className: 'ant-table-row-cell-break-word',
      render: (_, { DefaultCategory, OrganizationId, Id, Name, PaypalId }) => (
        <>
          {DefaultCategory ? (
            <DefaultPaypalButton
              refreshPayment={getData}
              OrganizationId={OrganizationId}
              Id={Id}
              Name={Name}
              Type="Paypal"
              PaypalId={PaypalId}
            />
          ) : (
            <CategoryPaypalButton
              refreshPayment={getData}
              OrganizationId={OrganizationId}
              Id={Id}
              Name={Name}
              Type="Paypal"
              PaypalId={PaypalId}
            />
          )}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'Id',
      className: 'ant-table-row-cell-break-word',
      render: (_, { Id, Name, DefaultCategory, ActiveInd }) => (
        <ActionButton
          Id={Id}
          Name={Name}
          DefaultCategory={DefaultCategory}
          ActiveInd={ActiveInd}
          refreshPayment={getData}
        />
      ),
    },
  ]

  return (
    <Skeleton
      loading={paymentLoading || loading}
      active
      paragraph={{ rows: 8 }}
    >
      <Table
        className="mobile-zero-padding"
        tableLayout="auto"
        scroll={{ x: 500 }}
        columns={columns}
        dataSource={categories}
        bordered={false}
        pagination={false}
      />
    </Skeleton>
  )
}

export { PaymentCategories }

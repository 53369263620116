import React, { useEffect, useState } from 'react'
import {
  Form,
  Button,
  Col,
  message,
  Select,
  Modal,
  Row,
  Popconfirm,
  Skeleton,
} from 'antd'
import { usePlaidLink } from 'react-plaid-link'
import {
  deleteUserBank,
  getBankAccount,
  getExchangeToken,
  getPlaidToken,
  getUserBanks,
} from 'store/paymentSlice/paymentActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { AiFillDelete } from 'react-icons/ai'

const BankDebit = ({ banks, setBanks, form, stripeAccount }) => {
  const [token, setToken] = useState(null)
  const [errorToken, setTokenError] = useState(null)
  const [tokenLoading, setTokenLoading] = useState(false)
  const [bankLoading, setBankLoading] = useState(false)
  const [manageCardModalVisible, setManageCardModalVisible] = useState(false)

  const { user } = useAuthSelector()
  Form.useWatch('bank', form)
  const bank = form.getFieldValue('bank')
  const isNewBankSelected = bank === 'new-bank-selected'

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeAccount, user])

  const onSuccess = async (PUBLIC_TOKEN, metadata) => {
    try {
      setBankLoading(true)
      let addedBank = null
      for (let i = 0; i < metadata?.accounts?.length; i += 1) {
        const singleAccount = metadata?.accounts[i]
        const exchangeTokenPayload = {
          publicToken: PUBLIC_TOKEN,
          account: singleAccount,
          user: user?.email,
        }
        // eslint-disable-next-line no-await-in-loop
        const exchangeTokenResponse = await getExchangeToken(
          exchangeTokenPayload,
        )

        let getBankAccountPayload = {}
        if (user) {
          getBankAccountPayload = {
            firstname: user?.firstname,
            lastname: user?.lastname,
            email: user?.email,
            phone: user?.phone,
            stripeAccount,
            access_token: exchangeTokenResponse,
            account_id: singleAccount?.id,
          }
        } else {
          try {
            // eslint-disable-next-line no-await-in-loop
            const data = await form.validateFields()
            let phone = null
            const { countryCode, phoneNumber } = data
            if (countryCode && phoneNumber) {
              const formatedPhoneNumber = phoneNumber?.replace(/\D/g, '')
              const trimPhoneNumber = formatedPhoneNumber.replace(/^0+/, '')
              phone = countryCode + trimPhoneNumber
            }
            getBankAccountPayload = {
              firstname: data?.firstName,
              lastname: data?.lastName,
              email: data?.email,
              phone,
              stripeAccount,
              access_token: exchangeTokenResponse,
              account_id: singleAccount?.id,
            }
          } catch (error) {
            console.log('Error:', error)
          }
        }
        // eslint-disable-next-line no-await-in-loop
        addedBank = await getBankAccount(getBankAccountPayload)
        if (!user) {
          setBanks([...banks, addedBank])
        }
      }
      if (user) {
        getCustomerBanks()
      }
      if (addedBank && addedBank?.id) {
        form.setFieldValue('bank', addedBank?.id)
      }
      setBankLoading(false)
    } catch (error) {
      message.error(error?.response?.data?.error || 'Something Went Wrong')
      setBankLoading(false)
    }
  }

  const onExit = async (error) => {
    message.error(error?.message || 'Error Connecting Bank Account')
  }

  const config = {
    token,
    onSuccess,
    onExit,
  }

  const { open } = usePlaidLink(config)

  const getCustomerBanks = async () => {
    setBankLoading(true)
    try {
      const cards = await getUserBanks(user, stripeAccount)
      setBanks(cards)
      setBankLoading(false)
    } catch (error) {
      console.log('error', error)
      setBankLoading(false)
    }
  }

  const getToken = async () => {
    setTokenLoading(true)
    try {
      const payload = {
        id: user?.id,
      }
      const response = await getPlaidToken(payload)
      setToken(response)
      setTokenLoading(false)
    } catch (error) {
      const errorResponse = error?.response?.data?.error
      setTokenError(errorResponse || 'Error occured while fetching plaid token')
      setTokenLoading(false)
    }
  }

  const fetchData = async () => {
    await getToken()
    if (user) {
      await getCustomerBanks()
    }
  }

  const removeBankFromList = (id) => {
    const updatedBanks = banks?.filter((item) => item.id !== id)
    setBanks(updatedBanks)
  }

  const onDeleteBank = async (bankId) => {
    try {
      setBankLoading(true)
      await deleteUserBank(stripeAccount, bankId)
      message.success('Bank Deleted Successfully')
      form.setFieldsValue({ bank: null })
      if (user) {
        getCustomerBanks()
      } else {
        removeBankFromList(bankId)
      }
      setManageCardModalVisible(false)
      setBankLoading(false)
    } catch (error) {
      message.error(
        error.resopnse.data.error ||
          'Error occured while deleting the bank account',
      )
      setBankLoading(false)
    }
  }

  const handleOpen = async () => {
    if (!user) {
      try {
        await form.validateFields()
        open()
      } catch (error) {
        console.log('Error:', error)
      }
    } else {
      open()
    }
  }

  const loading = tokenLoading || bankLoading
  return (
    <>
      <Skeleton loading={loading} active paragraph={{ rows: 2 }}>
        {!errorToken ? (
          <>
            {banks.length > 0 ? (
              <>
                {/* <Col xl={24} md={24} xs={24} sm={24}>
                  <p className="t-mb-4">
                    You have connected following bank accounts to your account.
                    Your bank account will be charged with amount mentioned
                    below.
                  </p>
                  <p className="t-mb-4 t-font-bold t-text-red-600">
                    Bank debit payments can take up to 7 business days.
                  </p>
                </Col> */}
                {banks.length > 0 && (
                  <Col xl={24} md={24} xs={24} sm={24} className="t-p-0">
                    <Form.Item
                      name="bank"
                      label="Select Bank"
                      colon={false}
                      // rules={[bankDebitValidation]}
                    >
                      <Select
                        placeholder="Select Bank From Dropdown"
                        style={{ width: '100%' }}
                        getPopupContainer={(trigger) => trigger.parentElement}
                      >
                        <Select.Option value="new-bank-selected">
                          Add a new bank
                        </Select.Option>
                        {banks.map((item, index) => (
                          <Select.Option
                            value={item.id}
                            key={index}
                            className="t-capitalize"
                          >
                            {`${item.bank_name} - **** **** **** ${item.last4}`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {banks.length > 0 && (
                  <Col span={24}>
                    <Row className="t-flex t-justify-end ">
                      <h1
                        onClick={() => setManageCardModalVisible(true)}
                        className="t-text-secondary-100 t-underline t-cursor-pointer"
                      >
                        Manage Banks
                      </h1>
                    </Row>
                  </Col>
                )}
                {isNewBankSelected && (
                  <Col
                    xl={24}
                    md={24}
                    xs={24}
                    sm={24}
                    className="t-text-right t-p-0"
                  >
                    <Button
                      loading={loading}
                      type="primary"
                      className="t-w-full"
                      onClick={() => handleOpen()}
                    >
                      Link Bank Account
                    </Button>
                  </Col>
                )}
              </>
            ) : (
              <>
                {/* <Col xl={24} md={24} xs={24} sm={24}>
                  <Paragraph className="t-text-justify">
                    We use Plaid’s authentication service to ensure privacy of
                    your banking information.
                  </Paragraph>
                </Col>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <Paragraph className="t-text-justify">
                    You will now be forwarded to Plaid to authenticate with your
                    bank and process your payment.
                  </Paragraph>
                </Col> */}
                <Col
                  xl={24}
                  md={24}
                  xs={24}
                  sm={24}
                  className="t-text-right t-p-0"
                >
                  <Button
                    loading={loading}
                    type="primary"
                    className="t-w-full"
                    onClick={() => handleOpen()}
                  >
                    Link Bank Account
                  </Button>
                </Col>
              </>
            )}
          </>
        ) : (
          <div className="t-flex t-items-center t-justify-center t-h-full t-w-full">
            <div className="ant-result ant-result-404">
              <div className="ant-result-title">Plaid Connection Failed</div>
              <div className="ant-result-subtitle">{errorToken}</div>
            </div>
          </div>
        )}
      </Skeleton>
      <Modal
        centered
        title="Manage Banks"
        open={manageCardModalVisible}
        onCancel={() => setManageCardModalVisible(false)}
        footer={null}
      >
        <Col span={24} className="t-p-4">
          <div className="t-flex t-justify-between">
            <h1>Following are the banks attached to your account</h1>
            <h1>Total Banks : {banks.length}</h1>
          </div>
          <div>
            {banks.map((item, index) => (
              <div
                key={index}
                className="t-flex t-justify-between t-capitalize t-border-2 t-rounded-2xl t-shadow-lg t-p-4 t-my-4"
              >
                <h1>{`${item.bank_name} - **** **** **** ${item.last4}`}</h1>
                <Popconfirm
                  title="Are you sure you want to delete this card？"
                  placement="top"
                  loading={loading}
                  onConfirm={() => onDeleteBank(item?.id)}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <AiFillDelete
                    fontSize="1.2rem"
                    className="t-text-red-500 t-cursor-pointer"
                  />
                </Popconfirm>
              </div>
            ))}
          </div>
        </Col>
      </Modal>
    </>
  )
}

export { BankDebit }

import React, { useEffect, useState } from 'react'
import {
  Col,
  message,
  Row,
  Spin,
  Tag,
  Select,
  DatePicker,
  Typography,
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { checkRoomAvailability } from 'store/roomSlice/roomActions'
import moment from 'moment'
import { DayDurations } from './DayDuration'

const { Title } = Typography

const customHeaderCalendar = ({ value, onChange }) => {
  // eslint-disable-next-line
  const yearNow = parseInt(moment().format('YYYY'))
  const year = value.year()
  const month = value.month()
  const options = []

  for (let i = year - 10; i < year + 10; i += 1) {
    if (i >= yearNow) {
      options.push(
        <Select.Option key={i} value={i} className="year-item">
          {i}
        </Select.Option>,
      )
    }
  }

  // eslint-disable-next-line
  const start = year == yearNow ? parseInt(moment().format('M')) - 1 : 0
  const end = 12
  const monthOptions = []
  const current = value.clone()
  const localeData = value.localeData()
  const months = []

  // eslint-disable-next-line
  for (let i = 0; i < 12; i++) {
    current.month(i)
    months.push(localeData.monthsShort(current))
  }

  // eslint-disable-next-line
  for (let i = start; i < end; i++) {
    monthOptions.push(
      <Select.Option key={i} value={i} className="month-item">
        {months[i]}
      </Select.Option>,
    )
  }

  return (
    <div
      style={{
        padding: 8,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <span style={{ fontSize: '16px' }}>Select date</span>
      <Row gutter={8}>
        <Col>
          <Select
            size="small"
            dropdownMatchSelectWidth={false}
            className="my-year-select"
            value={year}
            onChange={(newYear) => {
              const now = value.clone().year(newYear)
              onChange(now)
            }}
          >
            {options}
          </Select>
        </Col>
        <Col>
          <Select
            size="small"
            dropdownMatchSelectWidth={false}
            value={month}
            onChange={(newMonth) => {
              const now = value.clone().month(newMonth)
              onChange(now)
            }}
          >
            {monthOptions}
          </Select>
        </Col>
      </Row>
    </div>
  )
}

const antIcon = <LoadingOutlined spin />

const disabledDates = (dateValue, roomSchedule) => {
  const date = moment(dateValue).startOf('day')
  const today = moment().startOf('day')

  if (!date || date.isBefore(today)) {
    return true
  }

  const dayName = date.format('dddd').toLowerCase()
  if (!roomSchedule[dayName]?.open) {
    return true
  }

  return false
}

const SelectableCalender = ({
  room,
  roomSchedule,
  onDateChange,
  day,
  customDurations,
  onCustomDurationChange,
  selectedDate,
  setSelectedDate,
  isHoursInNegative,
  reservationId,
  setAlreadyReservedDurations,
  alreadyReservedDurations,
}) => {
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    fetchRoomAvailability(selectedDate)
    // eslint-disable-next-line
  }, [])

  const isDayOpen = day?.open
  const isDayNonStop = day?.nonstop
  const isDayHasNoDurations = day?.durations.length === 0

  const isRoomClosed = !isDayOpen || (isDayHasNoDurations && !isDayNonStop)

  const onSelect = async (newValue) => {
    await callOnChange(newValue)
  }

  const onPanelChange = async (newValue) => {
    await callOnChange(newValue)
  }

  const callOnChange = async (newValue) => {
    setSelectedDate(newValue)
    onDateChange(newValue)
    await fetchRoomAvailability(newValue)
  }

  const fetchRoomAvailability = async (date) => {
    try {
      setLoading(true)
      const payload = { room, selectedDate: new Date(date), reservationId }
      const response = await checkRoomAvailability(payload)
      setAlreadyReservedDurations([...response])
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error('Error fetching the available slots')
    }
  }

  return (
    <>
      <Col xl={24} md={24} xs={24} sm={24}>
        <Title level={5}> Select Date</Title>
        <DatePicker
          headerRender={customHeaderCalendar}
          value={selectedDate}
          fullscreen={false}
          onSelect={onSelect}
          mode="date"
          onPanelChange={onPanelChange}
          disabledDate={(date) => disabledDates(date, roomSchedule)}
          className="t-w-full t-mb-5"
        />
      </Col>
      <Col xl={24} md={24} xs={24} sm={24}>
        {isLoading ? (
          <div className="t-w-full t-h-full t-flex t-justify-center t-items-center">
            <div className="t-flex t-flex-col t-space-y-1">
              <Spin indicator={antIcon} />
              <h1 className="t-text-sm t-text-secondary-100">
                Fetching available durations
              </h1>
            </div>
          </div>
        ) : (
          <div className="t-p-3">
            {!selectedDate ||
            moment(selectedDate)
              .startOf('day')
              .isBefore(moment().startOf('day')) ? (
              <div className="t-my-3 t-text-red-600">
                You cannot make reservation for past dates.
              </div>
            ) : (
              <>
                {!isRoomClosed && (
                  <>
                    {isDayNonStop ? (
                      <>
                        <div className="t-my-3">
                          <h1 className="t-font-medium t-text-sm">
                            Day is available for whole day. Select your desired
                            durations
                          </h1>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="t-my-3">
                          <h1 className="t-font-medium t-text-sm">
                            Available Durations for this day
                          </h1>
                        </div>
                        <div className="t-flex t-space-x-2 t-ml-[22px] t-flex-wrap">
                          {day?.durations?.map((item) => (
                            <Tag className="t-text-purple-600 t-font-semibold t-bg-purple-100 t-px-2 t-py-1 t-rounded t-ml-[20px] t-mr-2 t-border-none">{`${item.from} : ${item.to}`}</Tag>
                          ))}
                        </div>
                      </>
                    )}
                    {alreadyReservedDurations.length > 0 && (
                      <>
                        <div className="t-my-3">
                          <h1 className="t-font-medium t-text-sm">
                            Following durations are reserved for this day
                          </h1>
                        </div>
                        <div className="t-flex t-space-x-2 t-ml-[22px] t-flex-wrap">
                          {alreadyReservedDurations?.map((item) => (
                            <Tag className="t-text-red-600 t-font-semibold t-bg-red-100 t-px-2 t-py-1 t-rounded t-ml-[-20px] t-mr-2 t-border-none">{`${item.from} : ${item.to}`}</Tag>
                          ))}
                        </div>
                      </>
                    )}
                    <div>
                      <div className="t-flex">
                        <DayDurations
                          isAlwaysOpen={isDayNonStop}
                          durations={customDurations}
                          availableDurations={day?.durations}
                          onChange={onCustomDurationChange}
                          alreadyReservedDurations={alreadyReservedDurations}
                        />
                      </div>
                    </div>
                  </>
                )}
                {isRoomClosed && (
                  <div className="t-my-3 t-text-red-600">
                    Room is not available to book for this day
                  </div>
                )}
                {isHoursInNegative && (
                  <Col span={24} className="t-my-3">
                    <div className="t-my-3 t-text-red-600">
                      Start time cannot exceed end time
                    </div>
                  </Col>
                )}
              </>
            )}
          </div>
        )}
      </Col>
    </>
  )
}

export { SelectableCalender }

import React, { useState } from 'react'
import { Modal, Button, DatePicker } from 'antd'
import { formateDate } from 'utils'
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillEdit,
} from 'react-icons/ai'
import moment from 'moment'

const disabledDates = (dateValue, roomSchedule, dates) => {
  const date = moment(dateValue)
  if (!date || date.isBefore(moment())) {
    return true
  }
  const dayName = date.format('dddd').toLocaleLowerCase()
  if (!roomSchedule[dayName].open) {
    return true
  }
  const alreadySelectedDates = dates.map((item) =>
    moment(item.date).format('DD-MM-YYYY'),
  )
  if (alreadySelectedDates.includes(moment(dateValue).format('DD-MM-YYYY'))) {
    return true
  }
  return false
}

const Resolver = ({ dates, setDates, roomSchedule, visible, onClose }) => {
  const [isModal, setModal] = useState(false)
  const [currentSelected, setCurrentSelected] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(null)

  const onDateChange = () => {
    if (selectedIndex !== null) {
      const updatedDates = dates.map((item, index) => {
        if (index === selectedIndex) {
          return { date: moment(currentSelected), isAvailable: true }
        }
        return item
      })
      setDates(updatedDates)
      setSelectedIndex(null)
      setModal(!isModal)
    }
  }

  const onClickUpdate = (item, index) => {
    setModal(!isModal)
    setSelectedIndex(index)
    setCurrentSelected(item.date)
  }

  return (
    <div>
      <Modal
        centered
        title="Resolve Dates"
        open={visible}
        onCancel={onClose}
        width={450}
        footer={null}
      >
        <div>
          <p>
            Following are the recurring dates that you selected. Kindly update
            the conflicting dates to complete the reservation.
          </p>
          <div className="t-py-2 t-max-h-80 t-overflow-scroll t-shadow-md">
            <div className="t-space-y-2 t-p-2">
              {dates.map((item, index) => (
                <div className="t-flex t-space-x-2 t-items-center">
                  <h1 className="t-px-3 t-py-1 t-border-1 t-bg-yellow-200 t-rounded-lg">
                    {formateDate(item?.date)}
                  </h1>
                  {item?.isAvailable ? (
                    <AiFillCheckCircle
                      fontSize="1.5rem"
                      className="t-text-green-600"
                    />
                  ) : (
                    <div className="t-flex t-items-center t-space-x-2">
                      <AiFillCloseCircle
                        fontSize="1.5rem"
                        className="t-text-red-600"
                      />
                      <AiFillEdit
                        fontSize="1.5rem"
                        className="t-text-gray-600 t-cursor-pointer"
                        onClick={() => onClickUpdate(item, index)}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="t-flex t-justify-end t-mt-2">
          <Button type="primary" size="small" onClick={onClose}>
            Finish Resolving
          </Button>
        </div>
      </Modal>
      <Modal
        centered
        title="Change Date"
        open={isModal}
        onCancel={() => setModal(!isModal)}
        width={450}
        footer={null}
      >
        <DatePicker
          format="MM-DD-YYYY"
          showTime
          allowClear={false}
          className="t-w-full"
          value={moment(currentSelected)}
          disabledDate={(date) => disabledDates(date, roomSchedule, dates)}
          onChange={(selectedDate) => setCurrentSelected(selectedDate)}
        />
        <div className="t-flex t-justify-end t-mt-2">
          <Button type="primary" size="small" onClick={onDateChange}>
            Update
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export { Resolver }

import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: false,
  organizations: [],
  businesses: [],
  events: [],
  fundraisers: [],
  campaigns: [],
  programs: [],
  services: [],
  volunteers: [],
  auctions: [],
}

export const favouriteSlice = createSlice({
  name: 'favourite',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = { ...state, loading: payload }
      return alteredState
    },
    setOrganizations: (state, { payload }) => {
      const alteredState = { ...state, organizations: payload }
      return alteredState
    },
    setBusinesses: (state, { payload }) => {
      const alteredState = { ...state, businesses: payload }
      return alteredState
    },
    setEvents: (state, { payload }) => {
      const filteredEvent = payload.filter(
        (item) => item.EventType === 'Fundraiser',
      )
      const filteredCampaign = payload.filter(
        (item) => item.EventType === 'Campaign',
      )
      const alteredState = {
        ...state,
        events: filteredEvent,
        campaigns: filteredCampaign,
      }
      return alteredState
    },
    setFundraisers: (state, { payload }) => {
      const alteredState = {
        ...state,
        fundraisers: payload,
      }
      return alteredState
    },
    setPrograms: (state, { payload }) => {
      const alteredState = { ...state, programs: payload }
      return alteredState
    },
    setServices: (state, { payload }) => {
      const alteredState = { ...state, services: payload }
      return alteredState
    },
    setVolunteers: (state, { payload }) => {
      const alteredState = { ...state, volunteers: payload }
      return alteredState
    },
    setAuctions: (state, { payload }) => {
      const alteredState = { ...state, auctions: payload }
      return alteredState
    },
    reset: () => initialState,
  },
})

export const useFavouriteSelector = () =>
  useSelector((state) => state.favourite)
export const favouriteActions = favouriteSlice.actions
export const favouriteReducer = favouriteSlice.reducer

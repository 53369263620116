import React, { useState } from 'react'
import {
  Button,
  Typography,
  Form,
  Checkbox,
  Input,
  Row,
  Col,
  InputNumber,
  Select,
} from 'antd'
import Countries from 'utils/countries.json'

const { Title } = Typography

const OrganizationRegister = () => {
  const [register, setRegister] = useState('')
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [steps, setSteps] = useState(1)
  const handleCountrySelect = (value) => {
    setSelectedCountry(value) // Update the state with the selected country code
  }
  const onFinish = (values) => {
    console.log('Success:', values)
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const handleClick = () => {
    if (selectedCountry === 'US') {
      setRegister(1)
      setSteps(2)
    } else {
      setRegister(2)
    }
  }
  const handleSearch = () => {
    setRegister(2)
  }

  const handleBack = () => {
    if (selectedCountry === 'US' && register === 2) {
      setRegister(1)
    } else {
      setRegister('')
    }
  }

  const selectBefore = (
    <Select
      defaultValue="add"
      style={{
        width: 60,
      }}
    >
      <Select.Option value="add">+92</Select.Option>
    </Select>
  )
  return (
    <>
      <div className="t-flex t-flex-row">
        <div className="t-w-3/5 t-min-h-screen">
          <div className="t-flex t-justify-center t-mt-80">
            <Title level={2}>Extensia</Title>
          </div>
        </div>
        <div className="t-w-2/5 t-bg-white">
          {/* First Div */}
          {register === '' && (
            <div className="t-mt-[25%] t-ml-16">
              <div className="t-flex t-flex-col">
                <Title level={4}>Country of Registeration</Title>
                <Select
                  showSearch
                  style={{ width: 250, marginTop: '1rem' }}
                  placeholder="Select a country"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handleCountrySelect} // Capture the selected value
                  value={selectedCountry} // Set the value of the select input
                >
                  {Countries.map((country) => (
                    <Select.Option key={country.code} value={country.code}>
                      {country.name}
                    </Select.Option>
                  ))}
                </Select>
                <div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="t-w-[39%] t-mt-8 "
                    onClick={handleClick}
                  >
                    Get Started
                  </Button>
                </div>
              </div>
            </div>
          )}
          {/* For EIN Number */}
          {register === 1 && (
            <>
              <div className="t-mt-[25%] t-ml-16">
                <h1 className="t-text-lg t-font-medium t-text-cyan-600">
                  Steps {register} / {steps}
                </h1>
              </div>
              <div className="t-mt-8 t-ml-16">
                <div className="t-flex t-flex-col t-w-[500px]">
                  <Title level={4}>EIN Number</Title>
                  <Input
                    placeholder="Search by EIN Number"
                    className="t-w-[350px]"
                  />
                  <div className="t-flex t-flex-row">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="t-w-[26%] t-mt-8 "
                      onClick={handleBack}
                    >
                      Go Back
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="t-w-[39%] t-mt-8 t-ml-4"
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Second Div */}
          {register === 2 && (
            <>
              <div className="t-mt-[15%] t-ml-16">
                <h1 className="t-text-lg t-font-medium t-text-cyan-600">
                  Steps{' '}
                  {selectedCountry === 'US'
                    ? register
                    : register > 1
                    ? register - 1
                    : register}{' '}
                  /{' '}
                  {selectedCountry === 'US'
                    ? steps
                    : steps > 1
                    ? steps - 1
                    : steps}
                </h1>
              </div>
              <div className="t-mt-8 t-ml-16">
                <Form
                  name="basic"
                  layout="vertical"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  style={{
                    maxWidth: 500,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Organization Name"
                    name="organizationName"
                    rules={[
                      {
                        min: 3,
                        message: 'Please input your Orgnaization Name!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Row>
                    <Col xl={12} md={12} xs={24} sm={24}>
                      <Form.Item
                        label="First Name"
                        name="firstName"
                        className="t-mr-4"
                        rules={[
                          {
                            min: 3,
                            message: 'Please input your First Name!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xl={12} md={12} xs={24} sm={24}>
                      <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[
                          {
                            min: 3,
                            message: 'Please input your Last Name!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    label="Phone Number"
                    name="phoneNumber"
                    rules={[
                      {
                        min: 11,
                        message: 'Please input your proper number!',
                      },
                    ]}
                  >
                    <InputNumber
                      addonBefore={selectBefore}
                      defaultValue={100}
                      className="t-rounded t-w-full"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item label="Organization Type" name="organizationType">
                    <Select>
                      <Select.Option>Select</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="remember" valuePropName="checked">
                    <Checkbox>
                      I have read and agree to the terms & conditions and
                      privacy policy
                    </Checkbox>
                  </Form.Item>
                  <Row>
                    <Col span={8}>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="t-w-[90%]"
                          onClick={handleBack}
                        >
                          Go Back
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="t-w-full"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export { OrganizationRegister }

import React, { useState, useEffect, useRef } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { MdLocationOn } from 'react-icons/md'
import { TbTarget } from 'react-icons/tb'
import {
  formatAmount,
  formateDateTime,
  isMobile,
  mobileShareNavigator,
} from 'utils'
import { getEventDetails } from 'store/eventSlice/eventActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
// import {
//   addFavourite,
//   removeFavourite,
// } from 'store/favouriteSlice/favouriteActions'
import { useDispatch } from 'react-redux'
import { awsconfig } from 'configs'
import {
  Row,
  Col,
  Card,
  Descriptions,
  Button,
  Typography,
  Tooltip,
  message,
  Skeleton,
  Carousel,
} from 'antd'
import { ShareModal, SVGIcon } from 'components'
import sample from 'assets/images/sample/sample1.png'
import { sendEmail } from 'store/authSlice/authActions'
import { useFavouriteSelector } from 'store/favouriteSlice/favouriteReducer'
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
  BsFillCalendarCheckFill,
  BsFillCalendarXFill,
} from 'react-icons/bs'

const { Title, Paragraph } = Typography

const CampaignView = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const { state } = useLocation()

  const slider = useRef(null)
  const [isShare, setShare] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const { eventLoading: loading, event } = useEventSelector()
  const { user, organization } = useAuthSelector()
  const { loading: favLoading } = useFavouriteSelector()

  const getEventById = () => {
    dispatch(getEventDetails(params?.id))
  }

  useEffect(() => {
    if (user?.email) {
      getEventById()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email, params?.id])

  // const handleDuplicate = () => {
  //   history.push(`/settings/create-event?id=${params?.id}&duplicate=true`)
  // }
  // const updateEvent = () => {
  //   dispatch(updateEventStatus(params?.id, event?.ActiveInd))
  //   history.push('/campaigns')
  // }

  const startEventControlling = () => {
    history.push(`/campaigns/${params?.id}/control-screen`)
  }

  // const joinEvent = () => {
  //   history.push(`/campaigns/${params?.id}/join-event`)
  // }

  // const handleAddFavourite = async (id) => {
  //   const payload = {
  //     ReferenceId: id,
  //     Username: user?.id,
  //     Type: 'event',
  //   }
  //   dispatch(addFavourite(payload, getEventById))
  // }

  // const handleRemoveFavourite = async (id) => {
  //   dispatch(removeFavourite(id, getEventById))
  // }

  const shareCampaign = async () => {
    if (isMobile) {
      await mobileShareNavigator(
        event?.Title,
        `${window.location.origin}/campaigns/${params?.id}/join-event`,
      )
    } else {
      setShare(true)
    }
  }

  const sendVerificationEmail = async () => {
    setLoading(true)
    try {
      await sendEmail(user?.email, `${user.firstname} ${user.lastname}`)
      message.success('Email Sent Successfully')
      setLoading(false)
    } catch (error) {
      message.error('Error sending verification email')
      setLoading(false)
    }
  }

  const isCreator = event?.OrganizationId === organization?.id
  const isPresenter = event?.PresenterEmail === user?.email
  const isPresenterAccepted = event?.AcceptanceStatus === 'Accepted'
  const isEventStarted = event?.startEvent
  const isPresenterEmailValidated = user?.email_verified
  const isSeedDonor = event?.SeedDonors?.includes(user?.email)
  const isPrivateCampaign = event?.IsPrivate
  // const isStartDateApproched =
  //   event?.StartDate && compareDateTimeWithNow(event.StartDate, 'after')

  const eventLoading = loading || isLoading || favLoading

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            title={[
              <div className="t-flex t-justify-between">
                <div className="t-flex t-items-center t-space-x-4">
                  <Button
                    type="primary"
                    className="t-rounded-full t-flex t-items-center"
                    onClick={() =>
                      state && state.urlData
                        ? history.push({
                            pathname: state.urlData.pathname,
                            state: { key: state.urlData.activeKey },
                          })
                        : history.goBack()
                    }
                    size="small"
                  >
                    <IoIosArrowBack />
                    Back
                  </Button>
                </div>
                <div className="t-flex t-items-center t-space-x-2 t-cursor-pointer">
                  {/* <div
                    className={`${
                      eventLoading && 't-pointer-events-none t-opacity-50'
                    } ${!event && 't-pointer-events-none t-opacity-0'}`}
                  >
                    {event?.isFav ? (
                      <Tooltip title="Remove Favorite">
                        <AiFillHeart
                          fontSize="1.8rem"
                          className="t-cursor-pointer t-text-red-500"
                          onClick={() => handleRemoveFavourite(event?.favId)}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Make Favorite">
                        <AiOutlineHeart
                          fontSize="1.8rem"
                          className="t-cursor-pointer t-text-secondary-100"
                          onClick={() => handleAddFavourite(params?.id)}
                        />
                      </Tooltip>
                    )}
                  </div> */}
                  {/* {isCreator && (
                    <>
                      <MdEdit
                        fontSize="1.5rem"
                        className="t-text-secondary-100"
                        onClick={() =>
                          history.push(`/settings/create-event/${event?.Id}`)
                        }
                      />
                      <HiDuplicate
                        fontSize="1.5rem"
                        className="t-text-secondary-100"
                        onClick={handleDuplicate}
                      />
                      {!(
                        event?.startEvent === undefined ||
                        event?.startEvent === true
                      ) && (
                        <>
                          {event?.ActiveInd === 1 ? (
                            <AiFillEyeInvisible
                              fontSize="1.5rem"
                              className="t-text-secondary-100"
                              onClick={updateEvent}
                            />
                          ) : (
                            <AiFillEye
                              fontSize="1.5rem"
                              className="t-text-secondary-100"
                              onClick={updateEvent}
                            />
                          )}
                        </>
                      )}
                    </>
                  )} */}
                </div>
              </div>,
            ]}
            bordered={false}
            className="header-solid card-product mb-24"
          >
            <Skeleton loading={eventLoading} active paragraph={{ rows: 18 }}>
              {event ? (
                <>
                  <Row
                    gutter={[24, 0]}
                    style={{ minHeight: 'calc(100vh - 220px)' }}
                  >
                    <Col
                      span={24}
                      lg={12}
                      xl={10}
                      className="col-gallery mb-24"
                    >
                      {event?.Flyer.length ? (
                        <div className="t-relative">
                          {event?.Flyer.length > 1 && (
                            <BsFillArrowLeftCircleFill
                              fontSize="1.2rem"
                              onClick={() => slider.current.prev()}
                              className="t-text-secondary-100 t-cursor-pointer t-absolute t-top-[50%] t-left-[10px] t-z-10 t-hidden md:t-block"
                            />
                          )}
                          <Carousel ref={slider} slidesPerRow={1}>
                            {event?.Flyer.map((flyerItem) => (
                              <img
                                src={
                                  flyerItem?.fileName
                                    ? awsconfig.bucketUrl + flyerItem?.fileName
                                    : sample
                                }
                                className="t-h-40 md:t-h-96 t-w-full t-rounded-lg shadow-3 t-object-cover"
                                alt="card-2"
                              />
                            ))}
                          </Carousel>
                          {event?.Flyer.length > 1 && (
                            <BsFillArrowRightCircleFill
                              fontSize="1.2rem"
                              onClick={() => slider.current.next()}
                              className="t-text-secondary-100 t-cursor-pointer t-absolute t-top-[50%] t-right-[10px] t-z-10 t-hidden md:t-block"
                            />
                          )}
                        </div>
                      ) : (
                        <img
                          src={
                            event?.Flyer?.fileName
                              ? awsconfig.bucketUrl + event?.Flyer?.fileName
                              : event?.organization?.BackgroundImage
                              ? awsconfig.bucketUrl +
                                event?.organization?.BackgroundImage?.fileName
                              : sample
                          }
                          className="t-h-96 t-w-full gallery-img shadow-3 img-contain"
                          alt="card-2"
                        />
                      )}
                    </Col>
                    <Col span={24} xl={12} lg={{ offset: 1 }} className="t-p-4">
                      <div className="t-mb-4">
                        <Title level={1} className="t-text-secondary-100">
                          {event?.Title}
                        </Title>
                        <Title level={4} className="t-text-secondary-100">
                          {event?.OrganizationName}
                        </Title>
                        <Descriptions colon={false} className="t-my-4">
                          <Descriptions.Item
                            label={
                              <Tooltip title="Start Date">
                                <BsFillCalendarCheckFill className="t-text-secondary-100 t-ml-1" />
                              </Tooltip>
                            }
                            span={3}
                          >
                            <Tooltip title="MM-DD-YYYY hh:mm A">
                              {formateDateTime(event?.StartDate)}
                            </Tooltip>
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <Tooltip title="End Date">
                                <BsFillCalendarXFill className="t-text-secondary-100 t-ml-1" />
                              </Tooltip>
                            }
                            span={3}
                          >
                            <Tooltip title="MM-DD-YYYY hh:mm A">
                              {formateDateTime(event?.EndDate)}
                            </Tooltip>
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <Tooltip title="Zip code">
                                <MdLocationOn
                                  fontSize="1.2rem"
                                  className="t-text-secondary-100"
                                />
                              </Tooltip>
                            }
                            span={3}
                          >
                            {event?.zipCode}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <Tooltip title="Goal">
                                <TbTarget
                                  fontSize="1.2rem"
                                  className="t-text-secondary-100"
                                />
                              </Tooltip>
                            }
                            span={3}
                          >
                            {formatAmount(event?.TargetAmount)}
                          </Descriptions.Item>
                        </Descriptions>
                        <Title level={4} className="t-text-secondary-100">
                          Description
                        </Title>
                        <Paragraph className="t-text-justify">
                          {event?.Details}
                        </Paragraph>
                        <div className="t-flex t-space-x-2 t-flex-wrap">
                          {((isPresenter &&
                            isPresenterAccepted &&
                            isPresenterEmailValidated) ||
                            isCreator) && (
                            <Button
                              type="primary"
                              className="t-my-1"
                              onClick={() => startEventControlling()}
                            >
                              Control
                            </Button>
                          )}
                          {/* {isEventStarted && isStartDateApproched && (
                            <Button
                              type="primary"
                              className="t-my-1"
                              onClick={joinEvent}
                            >
                              Join
                            </Button>
                          )} */}
                          {!isPrivateCampaign && (
                            <Button
                              type="primary"
                              className="t-my-1"
                              onClick={shareCampaign}
                            >
                              Share
                            </Button>
                          )}
                        </div>
                        <div className="t-my-2">
                          {isPresenter &&
                            isPresenterAccepted &&
                            !isPresenterEmailValidated && (
                              <div className="t-flex t-space-x-2 t-items-center">
                                <h1 className="t-text-red-500 t-font-bold">
                                  Kindly Validate Your Email Before Presenting
                                  The Event
                                </h1>
                                <Button
                                  type="primary"
                                  size="small"
                                  loading={isLoading}
                                  onClick={sendVerificationEmail}
                                >
                                  Verify Your Email
                                </Button>
                              </div>
                            )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {!isEventStarted && isSeedDonor && (
                    <Row className="t-flex t-justify-end t-items-center t-space-x-6">
                      <Paragraph>
                        Event is not yet started. But you can make donations
                        before the start of event.
                      </Paragraph>
                      <Button
                        type="primary"
                        onClick={() =>
                          history.push(`/seed-donation/${event?.Id}`)
                        }
                      >
                        Click To Donate
                      </Button>
                    </Row>
                  )}
                </>
              ) : (
                <div className="ant-result ant-result-404">
                  <SVGIcon />
                  <div className="ant-result-title">Invalid URL</div>
                  <div className="ant-result-subtitle">
                    Sorry, the page you visited does not exist.
                  </div>
                  <div className="ant-result-extra">
                    <Button
                      onClick={() => history.push('/campaign')}
                      type="primary"
                      size="small"
                    >
                      Goto Campaigns
                    </Button>
                  </div>
                </div>
              )}
            </Skeleton>
          </Card>
        </Col>
        <ShareModal
          // url={`${window.location.origin}/donation/${params?.id}`}
          url={`${awsconfig.API.endpoints[0].api_url}/html/share-event/${params?.id}`}
          visible={isShare}
          onClose={() => setShare(false)}
        />
      </Row>
    </>
  )
}

export { CampaignView }

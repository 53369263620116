import React, { useEffect, useState } from 'react'
import { Card, Tabs } from 'antd'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { config } from 'configs'
import { PageNotFound } from 'screens/main/settings/page-not-found'
import { MyBusinessEntities } from './MyBusinessEntities'
import { OrganizationSubscriptionProgram } from './OrganizationSubscriptionProgram'

const MyBusinessesConfiguration = () => {
  const { user } = useAuthSelector()
  const isSubscriber = user?.roles.includes(config?.roles?.subscriber)
  const [activeKey, setActiveKey] = useState('1')
  const { search } = useLocation()

  useEffect(() => {
    if (search) {
      const urlSearchParams = new URLSearchParams(search)
      const { key } = Object.fromEntries(urlSearchParams.entries())
      if (key) setActiveKey(key)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {isSubscriber ? (
        <Card className="header-solid" bordered={false}>
          <Tabs
            destroyInactiveTabPane
            animated={false}
            className="sliding-tab"
            activeKey={activeKey}
            onChange={(active) => {
              setActiveKey(active)
            }}
          >
            <Tabs.TabPane tab="My Business Entities" key="1">
              <MyBusinessEntities />
            </Tabs.TabPane>
            <Tabs.TabPane tab="My Subscription Program" key="2">
              <OrganizationSubscriptionProgram />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      ) : (
        <PageNotFound />
      )}
    </>
  )
}

export { MyBusinessesConfiguration }

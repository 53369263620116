import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Row,
  Skeleton,
  Avatar,
  Checkbox,
  Affix,
  message,
} from 'antd'
import { AreaChartOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { searchProgramServiceRows } from 'utils'
import { useHistory } from 'react-router-dom'
import {
  serviceActions,
  useServiceSelector,
} from 'store/serviceSlice/serviceReducer'
import { getOrganizationServices } from 'store/serviceSlice/serviceActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { downloadQRCodePDF } from 'store/authSlice/authActions'
// import { convertFile } from 'store/reportSlice/reportActions'
import { awsconfig, config } from 'configs'

const MyServices = ({ searchText }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { organization } = useAuthSelector()
  const { loading, organizationServices } = useServiceSelector()
  const [selectedIds, setSelectedIds] = useState([])

  useEffect(() => {
    dispatch(getOrganizationServices(organization?.id))
  }, [dispatch, organization])

  const handleServiceSelected = (e, service) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(serviceActions.setService(null))
    history.push(`services/${service.ServiceId}`)
  }

  const goToEdit = (e, service) => {
    e.preventDefault()
    e.stopPropagation()
    history.push(`/settings/create-service/${service?.ServiceId}`)
  }

  const onCheckboxChange = (eventId) => {
    const isAlreadyPresent = selectedIds.find((id) => id === eventId)
    if (isAlreadyPresent) {
      const updatedIds = selectedIds.filter((id) => id !== eventId)
      setSelectedIds(updatedIds)
    } else {
      setSelectedIds([...selectedIds, eventId])
    }
  }

  const type = 'services'
  const onPrintClick = async () => {
    message.loading('Generating QR code')
    const eventsForPrint = selectedIds.map((item) => ({
      id: item,
      name: organizationServices.find((element) => element?.id === item)?.Title,
      description: organizationServices.find((element) => element?.id === item)
        ?.Description,
      url: `${window.location.origin}/services/${item}`,
    }))
    const payload = {
      data: eventsForPrint,
      component: 'Services',
      Organization: organization.Name,
      logo: awsconfig.bucketUrl + organization.Logo?.fileName,
    }
    try {
      await downloadQRCodePDF(payload, dispatch)
      message.success('QR code generated!')
      // history.push(`/download/${type}`)
      window.open(`${window.location.origin}/download/${type}`, '_blank')
    } catch (error) {
      console.error('Error generating QR code:', error)
      message.error('Failed to generate QR code. Please try again.')
    }
  }

  const myServices = organizationServices.filter(
    (item) => item?.OrganizationId === organization?.id,
  )

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div className="t-flex t-flex-col t-justify-between t-h-full">
        {selectedIds.length > 0 && (
          <Row className="t-w-full t-flex t-justify-end t-p-0 md:t-p-1 -t-mt-6 md:-t-mt-8 t-mb-2 md:t-mb-2">
            <Affix offsetTop={75}>
              <Button
                type="primary"
                shape="round"
                onClick={onPrintClick}
                size="medium"
                className="glass_bg t-text-secondary-100 hover:t-text-white"
              >
                Print QR Codes
              </Button>
            </Affix>
          </Row>
        )}

        <Row gutter={[24, 24]}>
          {searchProgramServiceRows(myServices, searchText).length === 0 && (
            <div className="t-h-96 t-flex t-justify-center t-items-center t-w-full">
              <div className="t-flex t-flex-col t-items-center t-space-y-2">
                <AreaChartOutlined
                  style={{ fontSize: '2rem' }}
                  className="t-text-secondary-100"
                />
                <p>No records found</p>
              </div>
            </div>
          )}
          {searchProgramServiceRows(myServices, searchText).map(
            (item, index) => (
              <Col span={24} lg={8} className="mb-24" key={index}>
                <Card
                  bodyStyle={{ paddingTop: '14px' }}
                  bordered={false}
                  className="card-project-2 header-solid t-cursor-pointer"
                  title={[
                    <Row gutter={[24, 24]} key={0}>
                      <Col>
                        <Avatar
                          src={<AreaChartOutlined />}
                          style={{
                            width: '25px',
                            height: '25px',
                            lineHeight: '74px',
                            borderRadius: '8px',
                            padding: '16px',
                            backgroundColor: config.colors.primary,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        />
                      </Col>
                      <Col>
                        <h6 className="font-semibold mb-10 t-w-64 t-truncate">
                          {item.Title ? item.Title : 'No Title Found'}
                        </h6>
                      </Col>
                    </Row>,
                  ]}
                  onClick={(e) => handleServiceSelected(e, item)}
                >
                  <p className="t-truncate">
                    {item.Description
                      ? item.Description
                      : 'No Description Found'}
                  </p>
                  <hr />
                  <div
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      onCheckboxChange(item?.id)
                    }}
                  >
                    <Checkbox
                      checked={selectedIds.includes(item?.id)}
                      className="t-font-bold t-text-secondary-100"
                    >
                      Add to QR code sheet
                    </Checkbox>
                  </div>
                  <div className="t-flex t-justify-end t-mt-1">
                    <Button
                      type="link"
                      size="small"
                      className="t-rounded-full"
                      onClick={(e) => goToEdit(e, item)}
                    >
                      Edit
                    </Button>
                    <Button
                      type="link"
                      size="small"
                      className="t-rounded-full"
                      onClick={(e) => handleServiceSelected(e, item)}
                    >
                      See More
                    </Button>
                  </div>
                </Card>
              </Col>
            ),
          )}
        </Row>
      </div>
    </Skeleton>
  )
}

export { MyServices }

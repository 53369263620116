import React, { useEffect, useRef, useState } from 'react'
import { Skeleton } from 'antd'
import { useParams } from 'react-router-dom'
import Api from 'api'
import { AiFillDownCircle } from 'react-icons/ai'
import {
  ComponentInformation,
  InvalidURL,
  Confirmation,
} from 'components/common/unAuthenticatedPayments'
import { RegisterationForm } from './RegisterationForm'

const BusinessServicePayment = () => {
  const { id } = useParams()
  const [item, setItem] = useState()
  const [subscriberPaymentInfo, setSubscriberPaymentInfo] = useState({
    stripe: {
      isConnected: false,
      stripeId: null,
    },
    paypal: {
      isConnected: false,
      paypalId: null,
    },
    markup: 1,
  })
  const [isFetchingInformation, setFetchingInformation] = useState(true)
  const [isRegistered, setIsRegister] = useState(false)

  const getData = async () => {
    try {
      setFetchingInformation(true)
      // get business service request detail
      const itemDetail = await Api.get(
        `/component/business-service/request/${id}`,
      )
      if (itemDetail.results) {
        setItem(itemDetail.results)

        const businessId = itemDetail?.results?.BusinessId
        // get subscriber payment information
        const response = await Api.get(
          `/business-payment-account/get-account/${businessId}`,
        )

        const paymentInformation = response.result
        if (paymentInformation) {
          setSubscriberPaymentInfo({
            ...paymentInformation,
            markup: 1,
          })
        }
      }
      setFetchingInformation(false)
    } catch (error) {
      console.log('error', error)
      setFetchingInformation(false)
    }
  }

  useEffect(() => {
    if (id) {
      getData()
    }
    // eslint-disable-next-line
  }, [id])

  //  Scroll
  const scrollableDivRef = useRef(null)
  const [isAtEnd, setIsAtEnd] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableDivRef.current) {
        const { scrollHeight, scrollTop, clientHeight } =
          scrollableDivRef.current
        // To check if scroll is at end
        if (scrollHeight - scrollTop === clientHeight) {
          setIsAtEnd(true)
        } else {
          setIsAtEnd(false)
        }
      }
    }

    scrollableDivRef.current.addEventListener('scroll', handleScroll)
    return () => {
      if (scrollableDivRef && scrollableDivRef.current)
        // eslint-disable-next-line
        scrollableDivRef.current.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div
      className="t-relative t-h-screen t-overflow-auto no-scrollbar t-p-2"
      ref={scrollableDivRef}
    >
      <Skeleton
        loading={isFetchingInformation}
        active
        paragraph={{ rows: 18 }}
        className="t-w-full t-h-full"
      >
        {item ? (
          <>
            {item?.PaymentStatus === 'Pending' ||
            item?.PaymentStatus === 'Completed' ? (
              <InvalidURL
                title="Already Paid"
                decscription="This service request payment has been already made"
              />
            ) : (
              <>
                <>
                  {!isRegistered ? (
                    <>
                      <ComponentInformation
                        item={item}
                        // itemType="BusinessService"s
                      />
                      <RegisterationForm
                        item={item}
                        subscriberPaymentInfo={subscriberPaymentInfo}
                        setIsRegister={setIsRegister}
                      />
                    </>
                  ) : (
                    <>
                      <Confirmation
                      // orgWebsite={item?.organization?.Website}
                      // orgDomain={item?.organization?.OrgDomain}
                      />
                    </>
                  )}
                </>
                <div className="t-flex t-justify-end t-w-full t-text-xs t-italic t-text-gray-500 t-mt-12">
                  Powered by{' '}
                  <a
                    class="t-ml-1 font-bold t-text-[#14B1E0] t-cursor-pointer"
                    href="/"
                    target="_blank"
                  >
                    Extensia
                  </a>
                </div>
              </>
            )}
          </>
        ) : (
          <InvalidURL />
        )}
      </Skeleton>
      {!isFetchingInformation && !isAtEnd && (
        <div className="t-fixed t-bottom-6 t-right-6">
          <AiFillDownCircle
            fontSize="1.5rem"
            className="t-text-secondary-100 t-cursor-pointer"
            onClick={() =>
              scrollableDivRef.current.scrollTo({
                top: scrollableDivRef.current.scrollHeight,
                behavior: 'smooth',
              })
            }
          />
        </div>
      )}
    </div>
  )
}

export { BusinessServicePayment }

import React from 'react'
import { Avatar, Col, Tooltip } from 'antd'
import sample from 'assets/images/sample/sample1.png'
import { awsconfig } from 'configs'

const OrgInformation = ({ organization }) => (
  <>
    <Col span={24}>
      <div className="t-flex t-flex-col t-items-center">
        <img
          src={
            organization?.BackgroundImage
              ? awsconfig.bucketUrl + organization?.BackgroundImage?.fileName
              : sample
          }
          alt="card-2"
          className="t-h-48 md:t-h-80 t-w-full t-rounded-lg shadow-3 t-bg-cover t-bg-no-repeat t-opacity-80"
        />
        <div className="t-w-[90%] t-relative -t-top-12 glass_bg t-p-2 md:t-p-3">
          <div className="t-w-full t-flex t-justify-between">
            <Avatar.Group>
              <Avatar
                size={70}
                shape="square"
                src={
                  organization?.Logo
                    ? awsconfig.bucketUrl + organization?.Logo?.fileName
                    : sample
                }
              />
              <div className="avatar-info t-ml-4">
                <h4 className="font-semibold m-0 t-text-secondary-100 t-text-base md:t-text-2xl">
                  <Tooltip title="Organization Name">
                    {organization?.Name}
                  </Tooltip>
                </h4>
                <p className="t-text-secondary-100">
                  <Tooltip title="Organization Address">
                    {`${organization?.City}, ${organization?.State}`}
                  </Tooltip>
                </p>
              </div>
            </Avatar.Group>
          </div>
        </div>
      </div>
    </Col>
  </>
)

export { OrgInformation }

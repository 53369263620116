import React, { useEffect, useReducer, useState } from 'react'
import { message, Skeleton, Spin, Tooltip, Upload } from 'antd'
import Api, { makeRequest } from 'api'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { getUserOrganization } from 'store/organizationSlice/organizationActions'
import {
  MdInfoOutline,
  MdOutlineSimCardDownload,
  MdOutlineUploadFile,
} from 'react-icons/md'
import { searchDonnorRows } from 'utils'
import { AddCommunityMemberModal } from 'components/common'
import { RecurringDonorList } from './RecurringDonorList'
import { MessageForm } from './MessageForm'
import { UploadStatisticModal } from './uploadStatisticModal'
// import { SelectCampaignModal } from './SelectCampaignModal'
// import { DownloadInstructionModal } from './DownloadInstructionModal'

const initialState = {
  get: false,
  download: false,
  downloadDonorList: false,
  upload: false,
  custom: false,
  list: false,
}
// The reducer function
function reducer(state, action) {
  switch (action) {
    case 'get':
      return {
        ...state,
        get: !state.get,
      }
    case 'download':
      return {
        ...state,
        download: !state.download,
      }
    case 'downloadDonorList':
      return {
        ...state,
        downloadDonorList: !state.downloadDonorList,
      }
    case 'upload':
      return {
        ...state,
        upload: !state.upload,
      }
    case 'custom':
      return {
        ...state,
        custom: !state.custom,
      }
    case 'list':
      return {
        ...state,
        list: !state.list,
      }
    default:
      return state
  }
}

const RecurringDonors = ({ searchText }) => {
  const { user } = useAuthSelector()
  const [loading, setLoading] = useReducer(reducer, initialState)
  const [donors, setDonors] = useState()
  const [org, setOrganization] = useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [statisticModal, setStatisticModal] = useState(false)
  const [statisticData, setStatisticData] = useState()
  // const [showInstructionModal, setShowInstructionModal] = useState(false)
  const [addShowModal, setAddShowModal] = useState(false)
  // const [selectModal, setSelectModal] = useState(false)
  // const [uploadFile, setUploadFile] = useState()

  useEffect(() => {
    const getData = async () => {
      setLoading('get')
      try {
        const response = await getUserOrganization(user?.email)
        setOrganization(response)
        getDonors(response.id)
        setLoading('get')
      } catch (error) {
        console.log('error', error)
        setLoading('get')
      }
    }
    getData()
  }, [user])

  const getDonors = (id) => {
    setLoading('list')
    Api.get(`recurringDonors/donorList/${id}`)
      .then((res) => {
        setDonors(res.result)
        setLoading('list')
      })
      .catch((error) => {
        console.log('error', error)
        message.error('An Error Occured in fetching list')
        setLoading('list')
      })
  }

  const downloadFile = (data, type, filename) => {
    const blob = new Blob([data], { type: type.toString() })
    const blobURL = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.download = filename
    anchor.href = blobURL
    anchor.click()
  }

  const getFile = () => {
    setLoading('download')
    Api.get('recurringDonors/downloadSampleFile')
      .then((res) => {
        downloadFile(res, 'application/ms-excel', 'Sample-DonorList.csv')
        // setShowInstructionModal(true)
        setLoading('download')
      })
      .catch((error) => {
        console.log('error', error)
        setLoading('download')
      })
  }

  const sendCustom = (data) => {
    setIsModalVisible(false)
    const list = donors
      ?.filter((item) => selectedRowKeys.includes(item.Id))
      .map((item) => ({
        Id: item.Id,
        DonorName: item.DonorName,
        DonorEmail: item.DonorEmail,
        DonorPhoneNumber: item.DonorPhoneNumber,
        DonorZipCode: item.DonorZipCode,
        RecurringStatus: item.RecurringStatus,
        OutreachCount: item.OutreachCount,
      }))
    const isListPresent = list.length > 0
    if (org && org.id && org.Email) {
      const payload = {
        isListPresent,
        list,
        orgId: org.id,
        email: org.Email,
        ...data,
      }
      setLoading('custom')
      makeRequest(
        'post',
        'recurringDonors/sendCustomMessage',
        {
          organization: org?.id,
        },
        { payload },
      )
        .then((res) => {
          message.success(res.message)
          setSelectedRowKeys([])
          setLoading('custom')
          getDonors(org.id)
        })
        .catch((error) => {
          console.log('error', error)
          message.error(error?.message)
          setLoading('custom')
        })
    } else {
      message.error('Cound not get Organization data')
    }
  }

  const onFileUpload = (file) => {
    if (file.size < 2 * 1048576) {
      onFileSumbit(file)
      // setUploadFile(file)
      // setSelectModal(true)
    } else {
      message.error('File size should be less than 20 MB to upload')
    }
  }

  const onFileSumbit = (file) => {
    if (org && org.id && org.Name && org.Email) {
      const data = new FormData()
      data.append('uploadfile', file)
      data.append('OrganizationId', org.id)
      data.append('OrganizationName', org.Name)
      data.append('OrganizationEmail', org.Email)
      // data.append('CampaignId', values.campaign)
      setLoading('upload')
      Api.put('recurringDonors/uploadDonorList', data)
        .then((res) => {
          message.success(res.message)
          setLoading('upload')
          setStatisticData(res?.statusData)
          // setUploadFile()
          // setSelectModal(false)
          setStatisticModal(true)
          getDonors(org.id)
        })
        .catch((error) => {
          console.log('error', error)
          message.error(
            error?.response?.data?.error ||
              'File cannot be processed due to an error',
          )
          setLoading('upload')
          // setUploadFile()
          // setSelectModal(false)
        })
    } else {
      message.error('Cound not get Organization data')
    }
  }

  return (
    <Skeleton loading={loading.get} active paragraph={{ rows: 12 }}>
      <div className="t-flex t-justify-end t-items-center t-space-x-2">
        {/* <Tooltip title="Add New Community Member">
          <MdPersonAddAlt
            fontSize="1.7rem"
            className="t-text-secondary-100 t-cursor-pointer"
            onClick={() => setAddShowModal(true)}
          />
        </Tooltip> */}
        <Tooltip title="Download Recurring Donors Template">
          {loading.download ? (
            <Spin />
          ) : (
            <MdOutlineSimCardDownload
              fontSize="1.7rem"
              id="download"
              className="t-text-secondary-100 t-cursor-pointer"
              onClick={() => getFile()}
            />
          )}
        </Tooltip>
        <Upload
          id="upload"
          customRequest={(e) => onFileUpload(e.file)}
          maxCount={1}
          showUploadList={false}
          accept=".csv"
        >
          <Tooltip title="Upload Recurring Donors List">
            {loading.upload ? (
              <Spin />
            ) : (
              <MdOutlineUploadFile
                fontSize="1.7rem"
                className="t-text-secondary-100 t-cursor-pointer t-mt-1"
              />
            )}
          </Tooltip>
        </Upload>
        <Tooltip title="Send Reminder">
          {loading.custom ? (
            <Spin />
          ) : (
            <MdInfoOutline
              fontSize="1.7rem"
              id="send-reminder"
              className="t-text-secondary-100 t-cursor-pointer"
              onClick={() => setIsModalVisible(true)}
            />
          )}
        </Tooltip>
        {isModalVisible && (
          <MessageForm
            show={isModalVisible}
            setShow={setIsModalVisible}
            submit={(data) => sendCustom(data)}
          />
        )}
      </div>
      <div>
        <RecurringDonorList
          loading={loading.list}
          data={searchDonnorRows(donors, searchText) || []}
          getData={() => getDonors(org.id)}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      </div>
      <UploadStatisticModal
        isVisible={statisticModal}
        onClose={() => {
          setStatisticModal(false)
        }}
        statisticData={statisticData}
        downloadFile={downloadFile}
      />
      {/* {showInstructionModal && (
        <DownloadInstructionModal
          isVisible={showInstructionModal}
          onClose={() => setShowInstructionModal(false)}
        />
      )} */}
      {addShowModal && (
        <AddCommunityMemberModal
          isVisible={addShowModal}
          onClose={() => setAddShowModal(false)}
          getData={() => getDonors(org.id)}
        />
      )}
      {/* {selectModal && (
        <SelectCampaignModal
          show={selectModal}
          setShow={setSelectModal}
          orgId={org.id}
          submit={(data) => onFileSumbit(data)}
        />
      )} */}
    </Skeleton>
  )
}

export { RecurringDonors }

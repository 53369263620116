import React, { useEffect, useState } from 'react'
import { Card, Tabs } from 'antd'
import { useLocation } from 'react-router-dom'
import { AdminOrganizationsView } from './components/AdminOrganizationsView'
import { AdminBusinessView } from './components/AdminBusinessView'

const Admin = () => {
  const [activeKey, setActiveKey] = useState('1')
  const { search } = useLocation()

  useEffect(() => {
    if (search) {
      const urlSearchParams = new URLSearchParams(search)
      const { key } = Object.fromEntries(urlSearchParams.entries())
      if (key) setActiveKey(key)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Card className="header-solid" bordered={false}>
      <Tabs
        destroyInactiveTabPane
        animated={false}
        className="sliding-tab"
        activeKey={activeKey}
        onChange={(active) => {
          setActiveKey(active)
        }}
      >
        <Tabs.TabPane tab="OrganizationsView" key="1">
          <AdminOrganizationsView />
        </Tabs.TabPane>
        <Tabs.TabPane tab="BusinessView" key="2">
          <AdminBusinessView />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  )
}

export { Admin }

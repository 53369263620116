import { formatAmount } from 'utils'

export const getHardwareTotal = (hardwareList) => {
  if (!hardwareList || hardwareList?.length < 1) return []
  const totalChargesDue = hardwareList?.reduce(
    (acc, { MonthlyCost }) => Number(acc) + Number(MonthlyCost || 0),
    0,
  )
  return totalChargesDue
}

export const getBillDetails = ({
  globalSubscriptionCharges,
  usageCharges,
  hardwareTotal,
}) => {
  const subscriptionCharge = globalSubscriptionCharges
  const usageCharge =
    usageCharges && usageCharges.length > 2 ? usageCharges[2].ChargesDue : 0
  const billingTotal =
    Number(subscriptionCharge) + Number(usageCharge) + Number(hardwareTotal)

  const billingDetails = [
    {
      title: `Current Cycle Subscription Charges`,
      value: formatAmount(subscriptionCharge),
    },
    {
      title: `Current Cycle Hardware Charges`,
      value: formatAmount(hardwareTotal),
    },
    {
      title: `Previous Cycle Usage Charges`,
      value: formatAmount(usageCharge),
    },
    {
      title: `Total Due`,
      value: formatAmount(billingTotal),
    },
  ]
  return { billingTotal, billingDetails }
}

import React from 'react'
import { Card, Table } from 'antd'

const BillingDetails = ({ billingDetails }) => {
  const columns = [
    {
      title: 'Item',
      dataIndex: 'title',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Charge',
      dataIndex: 'value',
      className: 'ant-table-row-cell-break-word',
    },
  ]

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0">Bill Details</h6>
        </>,
      ]}
    >
      <Table
        tableLayout="auto"
        scroll={{ x: 500 }}
        columns={columns}
        dataSource={billingDetails}
        bordered={false}
        pagination={false}
      />
    </Card>
  )
}

export { BillingDetails }

import { message } from 'antd'
import Api, { makeRequest } from 'api'
import { uploadFile } from 'store/authSlice/authActions'
import { sortByDate, sortByZipcode } from 'utils'
import { config } from 'configs'
import { serviceActions } from './serviceReducer'

export const addService = (payload, actions) => async (dispatch) => {
  dispatch(serviceActions.setLoading(true))

  let updatedData = payload
  const flyerFile = await uploadFile(updatedData.Flyer)
  updatedData = {
    ...updatedData,
    Flyer: {
      fileName: flyerFile.fileName,
      fileMimeType: flyerFile.fileMimeType,
    },
  }

  makeRequest(
    'post',
    `/component/service/`,
    {
      organization: payload?.OrganizationId,
    },
    updatedData,
  )
    .then((res) => {
      dispatch(serviceActions.setLoading(false))
      message.success(res.message || 'Service Added Successfully')
      if (actions) {
        actions()
      }
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(serviceActions.setLoading(false))
    })
}

export const updateService = (payload, actions) => async (dispatch) => {
  dispatch(serviceActions.setLoading(true))

  let updatedData = payload

  if (!updatedData?.Flyer?.fileName) {
    const flyerFile = await uploadFile(updatedData.Flyer)
    updatedData = {
      ...updatedData,
      Flyer: {
        fileName: flyerFile.fileName,
        fileMimeType: flyerFile.fileMimeType,
      },
    }
  }

  const serviceType = payload?.ServiceType
  let url
  if (serviceType === 'Funeral') {
    url = '/component/service/funeral'
  } else if (serviceType === 'Marriage') {
    url = '/component/service/marriage'
  } else if (serviceType === 'Zakaat') {
    url = '/component/service/zakaat'
  } else {
    url = '/component/service/custom'
  }

  makeRequest(
    'put',
    url,
    {
      organization: payload?.OrganizationId,
    },
    updatedData,
  )
    .then((res) => {
      dispatch(serviceActions.setLoading(false))
      message.success(res.message || 'Service Updated Successfully')
      if (actions) {
        actions()
      }
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(serviceActions.setLoading(false))
    })
}

export const updateServiceStatus = (id, status, actions) => (dispatch) => {
  dispatch(serviceActions.setLoading(true))
  let value = 1
  if (status === 1) {
    value = 0
  }
  makeRequest('patch', `/component/service/${id}/status/${value}`)
    .then(() => {
      message.success(
        value === 0
          ? 'Service Deactivated Successfully'
          : 'Service Activated Successfully',
      )
      dispatch(serviceActions.setLoading(true))
      if (actions) {
        actions()
      }
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(serviceActions.setLoading(true))
    })
}

export const getService = (id, userId) => (dispatch) => {
  dispatch(serviceActions.setLoading(true))
  makeRequest('get', `/component/service/${id}?user=${userId}`)
    .then((res) => {
      if (res.results) {
        dispatch(serviceActions.setService(res.results))
      }
      dispatch(serviceActions.setLoading(false))
    })
    .catch((error) => {
      dispatch(serviceActions.setService(null))
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(serviceActions.setLoading(false))
    })
}

export const getServiceDetail = (id, form, actions) => (dispatch) => {
  dispatch(serviceActions.setLoading(true))
  makeRequest('get', `/component/service/${id}`)
    .then((res) => {
      if (res.results) {
        const service = res.results
        if (form) {
          form.setFieldsValue({
            Title: service.Title,
            ServiceType: service.ServiceType,
            DeliveryLocation: service.DeliveryLocation || '',
            ProgramFees: service.ProgramFees,
            Description: service.Description,
            Flyer: service.Flyer,
            ShowOnLandingPage: service.ShowOnLandingPage
              ? service.ShowOnLandingPage
              : false,
            IncludeProcessingFee: service?.IncludeProcessingFee ?? false,
            AllowRecurringPayments: service?.AllowRecurringPayments ?? false,
            NumberOfDefaultRecurring: service?.NumberOfDefaultRecurring ?? '',
            PaymentCategoryAdded: service?.PaymentCategoryAdded ?? false,
            PaymentCategories: service?.PaymentCategories
              ? service?.PaymentCategories
              : [],
            OrganizationProfile:
              service.OrganizationProfile && service.OrganizationProfile,
            Requirements: service.Requirements || '',
          })
          if (service?.ServiceType === 'Marriage') {
            form.setFieldsValue({
              NoteHeading: service.NoteHeading || '',
              NoteDescription: service.NoteDescription || '',
              RequestedTimeHelpText: service.RequestedTimeHelpText || '',
              CeremonyLocationHelpText: service.CeremonyLocationHelpText || '',
              BrideReligionHelpText: service.BrideReligionHelpText || '',
              GroomReligionHelpText: service.GroomReligionHelpText || '',
              NumberOfAttendeesHelpText:
                service.NumberOfAttendeesHelpText || '',
            })
          }
          if (service?.DeliveryLocation === 'Hybrid') {
            form.setFieldsValue({
              mailingAddress: service?.Address,
              suite: service?.Suite,
              zipCode: service?.ZipCode,
              city: service?.City,
              state: service?.State,
              country: service?.Country,
            })
          }
          if (actions) actions(service.ServiceType)
        }
      }
      dispatch(serviceActions.setLoading(false))
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(serviceActions.setLoading(false))
    })
}

export const getAllServices = (loadingOff, zipCode) => (dispatch) => {
  Api.get('/component/service/?type=all')
    .then((res) => {
      const sortedData = sortByZipcode(res?.results, 'OrgZipCode', zipCode)
      dispatch(serviceActions.setAllServices(sortedData))
      loadingOff()
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(serviceActions.setAllServices([]))
      loadingOff()
    })
}

export const getNearByService = (positionData) => (dispatch) => {
  dispatch(serviceActions.setLoading(true))
  Api.get(
    `/component/service/?type=nearby&zipCode=${positionData?.zipCode}&lat=${positionData?.lat}&lon=${positionData?.lon}`,
  )
    .then((res) => {
      const sortedData = sortByDate(res?.results, 'CreatedDate')
      dispatch(serviceActions.setServices(sortedData))
      dispatch(serviceActions.setLoading(false))
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(serviceActions.setServices([]))
      dispatch(serviceActions.setLoading(false))
    })
}

export const getOrganizationServices = (id) => (dispatch) => {
  dispatch(serviceActions.setLoading(true))
  Api.get(`/component/service/?type=organization&organizationId=${id}`)
    .then((res) => {
      const sortedData = sortByDate(res?.results, 'CreatedDate')
      dispatch(serviceActions.setOrganizationServices(sortedData))
      dispatch(serviceActions.setLoading(false))
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(serviceActions.setOrganizationServices([]))
      dispatch(serviceActions.setLoading(false))
    })
}

export const getSubscriberPaymentInformation =
  (organizationId) => (dispatch) => {
    dispatch(serviceActions.setLoading(true))
    Api.get(`/event/account-info?organizationId=${organizationId}`)
      .then((paymentInformation) => {
        if (paymentInformation) {
          dispatch(
            serviceActions.setSubscriberStripeInfo({
              isConnected:
                paymentInformation.defaultCategory?.stripeID &&
                paymentInformation.defaultCategory?.stripeOnBoarded,
              stripeId: paymentInformation.defaultCategory?.stripeID,
            }),
          )

          dispatch(
            serviceActions.setSubscriberPaypalInfo({
              isConnected: !!paymentInformation.defaultCategory?.paypalID,
              paypalId: paymentInformation.defaultCategory?.paypalID,
            }),
          )
        }
        dispatch(serviceActions.setLoading(false))
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(serviceActions.setLoading(false))
      })
  }

export const getSubscriberMarkupInfo = (orgId) => (dispatch) => {
  dispatch(serviceActions.setLoading(true))
  Api.get(`/admin/subsrciberMarkUpsByOrg/${orgId}`)
    .then((res) => {
      dispatch(
        serviceActions.setSubscriberMarkupInfo(
          Number(res?.results?.StripeMarkup) || 1,
        ),
      )
      dispatch(serviceActions.setLoading(false))
    })
    .catch(() => {
      dispatch(serviceActions.setLoading(false))
    })
}

export const registerForService = (payload, setLoading, actions) => () => {
  setLoading(true)
  Api.post('/component/service/register-with-stripe', payload)
    .then(() => {
      setLoading(false)
      if (actions) {
        actions()
      }
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || 'An Error Occured')
      setLoading(false)
    })
}

export const registerWithPaypalForService =
  (payload, setLoading, actions) => () => {
    setLoading(true)
    Api.post('/component/service/register-with-paypal', payload)
      .then(() => {
        setLoading(false)
        if (actions) {
          actions()
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.error || 'An Error Occured')
        setLoading(false)
      })
  }

export const getRegisterUsers = (id, setLoading) => (dispatch) => {
  setLoading(true)
  makeRequest('get', `/component/service/registered-users/${id}`)
    .then((res) => {
      if (res.results) {
        dispatch(serviceActions.setRegisteredUsers(res.results))
      } else {
        dispatch(serviceActions.setRegisteredUsers([]))
      }
      setLoading(false)
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(serviceActions.setRegisteredUsers([]))
      setLoading(false)
    })
}

// Disabaling this api as we have to decide which service will allow multiple registration and which will not
export const isUserAlreadyRegisteredInService = () => false
// export const isUserAlreadyRegisteredInService = async (id, email) => {
//   const response = await Api.get(`programServices/registeredList/${id}`)
//   const isExist = response.results.find((item) => item.DonorEmail === email)
//   return isExist
// }
